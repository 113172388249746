import styled from '@emotion/styled';
import ButtonBase from '@/components/ButtonBase';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

type GroupSelectorGroupProps = {
  selected: boolean;
};

const GroupSelectorGroup = styled(ButtonBase)<GroupSelectorGroupProps>(
  ({ theme }) => ({
    padding: '10px 15px',
    width: '100%',
    textAlign: 'left',
    transition: theme.transitions.create('all'),
    ':hover, :focus': {
      backgroundColor: theme.palette.action.hover,
    },
    ':active': {
      backgroundColor: theme.palette.action.selected,
    },
  }),
  ({ theme, selected }) => {
    if (selected) {
      return {
        fontWeight: 500,
        backgroundColor: theme.palette.action.selected,
      };
    }
  },
  addHighContrastStyles(({ selected }) => ({
    backgroundColor: selected ? 'transparent' : null,
    textDecoration: selected ? 'underline' : null,
    ':hover, :focus': {
      textDecoration: 'underline',
    },
  })),
);

export default GroupSelectorGroup;
