import React, { createContext, useState } from 'react';
import moment, { Moment } from 'moment';
import useResponsiveVisibleWeeksCountCalculation from './useResponsiveVisibleWeeksCountCalculation';
import { ReactContextWithAutomatedProvider } from '@/lib/types';

type VisibleWeekRangeState = {
  visibleWeekRangeStartDate: Moment;
  visibleWeekRangeEndDate: Moment;
  visibleWeeksCount: number;
  shiftVisibleWeekRangeToLeft: () => void;
  shiftVisibleWeekRangeToRight: () => void;
  isZoomedInOnWeek: boolean;
  zoomInOnWeek: (weekStartDate: Moment) => void;
  cancelWeekZoom: () => void;
};

const VisibleWeekRangeContext = createContext<VisibleWeekRangeState>(
  ({} as unknown) as VisibleWeekRangeState,
) as ReactContextWithAutomatedProvider<VisibleWeekRangeState>;

const ONLY_TWO_WEEKS_VISIBLE_WINDOW_WIDTH_THRESHOLD = 1000;

type AutomatedVisibleWeekRangeContextProviderProps = {
  children: React.ReactNode;
};

const AutomatedVisibleWeekRangeContextProvider = ({
  children,
}: AutomatedVisibleWeekRangeContextProviderProps) => {
  const currentIsoWeekStartDate = moment().startOf('isoWeek');
  const previousIsoWeekStartDate = currentIsoWeekStartDate
    .clone()
    .subtract(1, 'weeks');

  const startWithCurrentWeek =
    window.innerWidth <= ONLY_TWO_WEEKS_VISIBLE_WINDOW_WIDTH_THRESHOLD;

  const [visibleWeekRangeStartDate, setVisibleWeekRangeStartDate] = useState(
    startWithCurrentWeek ? currentIsoWeekStartDate : previousIsoWeekStartDate,
  );

  const [isZoomedInOnWeek, setIsZoomedInOnWeek] = useState(false);
  const [
    visibleWeekRangeStartDateBeforeZoom,
    setVisibleWeekRangeStartDateBeforeZoom,
  ] = useState<Moment | null>(null);

  const zoomInOnWeek = (weekStartDate: Moment): void => {
    setIsZoomedInOnWeek(true);
    setVisibleWeekRangeStartDateBeforeZoom(visibleWeekRangeStartDate.clone());
    setVisibleWeekRangeStartDate(weekStartDate.clone());
  };

  const cancelWeekZoom = (): void => {
    setIsZoomedInOnWeek(false);
    // TODO !
    setVisibleWeekRangeStartDate(visibleWeekRangeStartDateBeforeZoom!);
    setVisibleWeekRangeStartDateBeforeZoom(null);
  };

  const originalVisibleWeeksCount = useResponsiveVisibleWeeksCountCalculation();
  const visibleWeeksCount = isZoomedInOnWeek ? 1 : originalVisibleWeeksCount;

  const visibleWeekRangeEndDate = visibleWeekRangeStartDate
    .clone()
    .add(visibleWeeksCount, 'weeks');

  const shiftWeekAmount = Math.ceil(visibleWeeksCount / 2);

  const shiftVisibleWeekRangeToLeft = (): void => {
    setVisibleWeekRangeStartDate(
      visibleWeekRangeStartDate
        .clone()
        .subtract(shiftWeekAmount, 'weeks')
        .startOf('isoWeek'),
    );
  };

  const shiftVisibleWeekRangeToRight = (): void => {
    setVisibleWeekRangeStartDate(
      visibleWeekRangeStartDate
        .clone()
        .add(shiftWeekAmount, 'weeks')
        .startOf('isoWeek'),
    );
  };

  return (
    <VisibleWeekRangeContext.Provider
      value={{
        visibleWeekRangeStartDate,
        visibleWeekRangeEndDate,
        visibleWeeksCount,
        shiftVisibleWeekRangeToLeft,
        shiftVisibleWeekRangeToRight,
        isZoomedInOnWeek,
        zoomInOnWeek,
        cancelWeekZoom,
      }}
    >
      {children}
    </VisibleWeekRangeContext.Provider>
  );
};

VisibleWeekRangeContext.AutomatedProvider = AutomatedVisibleWeekRangeContextProvider;

export default VisibleWeekRangeContext;
