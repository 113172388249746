import { useContext, useState } from 'react';
import PortfolioIsReadOnlyContext from '@/lib/contexts/PortfolioIsReadOnlyContext';
import RubricsSlider from './RubricsSlider';
import useSaveProgress from './useSaveProgress';
import { RubricProgressObject } from '@/lib/types';

type SliderProps = {
  partId: number;
  levelCount: number;
  progress: RubricProgressObject;
  setProgress: (progress: number) => void;
};

// TODO rename readOnly prop to disabled?
const Slider = ({ partId, levelCount, progress, setProgress }: SliderProps) => {
  const portfolioIsReadOnly = useContext(PortfolioIsReadOnlyContext);
  const [saving, setSaving] = useState(false);

  const saveProgress = useSaveProgress(partId);

  const handleSlide = (newProgress: number): void => {
    if (!portfolioIsReadOnly) {
      setProgress(newProgress);
    }
  };

  const handleSlideStop = async (progress: number): Promise<void> => {
    if (portfolioIsReadOnly) {
      return;
    }

    handleSlide(progress);

    setSaving(true);

    await saveProgress(progress);

    setSaving(false);
  };

  return (
    <RubricsSlider
      studentProgress={progress.student ?? 0}
      coachProgress={progress.coach ?? 0}
      levelCount={levelCount}
      onSlide={handleSlide}
      onSlideStop={handleSlideStop}
      readOnly={portfolioIsReadOnly}
      disabled={saving}
    />
  );
};

export default Slider;
