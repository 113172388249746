import { useContext } from 'react';
import SelectedPlatformContext from '@/lib/contexts/SelectedPlatformContext';
import PortfolioUserContext from '@/lib/contexts/PortfolioUserContext';
import NoPageTitle from './NoPageTitle';
import PlatformType from '@/lib/constants/PlatformType.enum';
import ChallengePortfolio from '@/components/ChallengePortfolio';
import RubricsPortfolio from '@/components/RubricsPortfolio';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import ShowPerspectiveCategorizationViewContext from '@/lib/contexts/ShowPerspectiveCategorizationViewContext';
import PerspectiveCategorizationView from '@/components/PerspectiveCategorizationView';

const PortfolioPage = () => {
  const [selectedPlatform] = useContext(SelectedPlatformContext);
  const [showPerspectiveCategorizationView] = useContext(
    ShowPerspectiveCategorizationViewContext,
  );

  const authenticatedUser = useAuthenticatedUser();

  return (
    <PortfolioUserContext.Provider value={authenticatedUser}>
      <NoPageTitle />
      {showPerspectiveCategorizationView && <PerspectiveCategorizationView />}
      {!showPerspectiveCategorizationView &&
        selectedPlatform === PlatformType.CHALLENGEMONITOR && (
          <ChallengePortfolio />
        )}
      {!showPerspectiveCategorizationView &&
        selectedPlatform === PlatformType.PROGRESSMONITOR && (
          <RubricsPortfolio />
        )}
    </PortfolioUserContext.Provider>
  );
};

export default PortfolioPage;
