import { Button, ButtonProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type DoneButtonProps = Omit<ButtonProps, 'variant' | 'children'>;

const DoneButton = (props: DoneButtonProps) => (
  <Button variant="outlined" {...props}>
    <FormattedMessage id="support.dialog.actions.done" />
  </Button>
);

export default DoneButton;
