import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import { HTMLDivProps } from '@/lib/types';

type FeedbackListWrapperProps = HTMLDivProps & {
  mobileVariant: boolean;
};

const FeedbackListWrapper = styled('aside')<FeedbackListWrapperProps>(
  {
    marginLeft: 24,
    width: 232,
    [mediaQuery(1250)]: {
      display: 'none',
    },
  },
  ({ mobileVariant }) => {
    if (mobileVariant) {
      return {
        [mediaQuery(1250)]: {
          display: 'initial',
        },
      };
    }
  },
);

export default FeedbackListWrapper;
