import styled from '@emotion/styled';

type StyledUserListProps = {
  collapsed: boolean;
};

const StyledUserList = styled('ul')<StyledUserListProps>(
  ({ theme, collapsed }) => ({
    margin: '0 auto',
    padding: 0,
    maxHeight: 420,
    textAlign: 'left',
    listStyle: 'none',
    borderTop: `2px solid ${theme.palette.divider}`,
    display: collapsed ? 'none' : 'block',
    overflowY: 'auto',
  }),
);

export default StyledUserList;
