import styled from '@emotion/styled';
import { Select, SelectProps } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';

export type StyledSelectProps = Omit<SelectProps<number>, 'MenuProps'>;

const ResponsiveSelect = styled(Select)({
  width: 168,
  [mediaQuery(420)]: {
    width: '100%',
  },
}) as unknown as typeof Select<number>;

const StyledSelect = (props: StyledSelectProps) => (
  <ResponsiveSelect
    MenuProps={{
      MenuListProps: {
        style: {
          minWidth: 256,
        },
      },
    }}
    {...props}
  />
);

export default StyledSelect;
