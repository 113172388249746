import styled from '@emotion/styled';
import { Fab } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';

const GapminderButton = styled(Fab)(({ theme }) => ({
  marginTop: -14,
  marginBottom: -14,
  transition: theme.transitions.create('all'),
  boxShadow: `${theme.shadows[2]} !important`,
  zIndex: 1,
  [mediaQuery(600)]: {
    marginTop: 24,
    marginBottom: 0,
    maxWidth: '100%',
  },
}));

export default GapminderButton;
