import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import { HTMLDivProps } from '@/lib/types';

export type DialogActionsWrapperProps = HTMLDivProps & {
  addTopMargin?: boolean;
};

const DialogActionsWrapper = styled('footer')<DialogActionsWrapperProps>(
  ({ addTopMargin }) => ({
    paddingTop: 8,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [mediaQuery(640)]: {
      paddingTop: addTopMargin ? 32 : 8,
      paddingBottom: 16,
      paddingLeft: 24,
      paddingRight: 24,
      flexDirection: 'column',
    },
  }),
);

export default DialogActionsWrapper;
