import { Moment } from 'moment';

type ChallengePositioning = {
  challengeDistanceFromLeft: number;
  challengeWidth: number;
  contentVisibilityCompensationDistance: number;
  contentMaxVisibleWidth: number;
};

// All variables represent distances as percentages
const getChallengePositioning = (
  plannedStartDate: Moment,
  plannedDuration: number,
  visibleWeekRangeStartDate: Moment,
  visibleWeeksCount: number,
): ChallengePositioning => {
  const daysFromVisibleWeekRangeStartDate = plannedStartDate.diff(
    visibleWeekRangeStartDate,
    'days',
  );

  const daysInVisibleWeekRange = visibleWeeksCount * 7;

  const challengeDistanceFromLeft =
    (daysFromVisibleWeekRangeStartDate / daysInVisibleWeekRange) * 100;
  const challengeWidth = (plannedDuration / daysInVisibleWeekRange) * 100;

  const contentVisibilityCompensationDistance =
    challengeDistanceFromLeft < 0
      ? -challengeDistanceFromLeft * (100 / challengeWidth)
      : 0;

  const challengeOverflowsOnRight =
    challengeDistanceFromLeft + challengeWidth > 100;
  const distanceBeforeRightWrapperEnd = challengeOverflowsOnRight
    ? (100 - challengeDistanceFromLeft) * (100 / challengeWidth)
    : 100;
  const contentMaxVisibleWidth =
    distanceBeforeRightWrapperEnd - contentVisibilityCompensationDistance;

  return {
    challengeDistanceFromLeft,
    challengeWidth,
    contentVisibilityCompensationDistance,
    contentMaxVisibleWidth,
  };
};

export default getChallengePositioning;
