import Dialog from '@/components/Dialog';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

const CreationDialogTitle = () => (
  <Dialog.Title>
    <FormattedMessageWithChallengeNaming id="portfolio.challenge_creation_flow.title" />
  </Dialog.Title>
);

export default CreationDialogTitle;
