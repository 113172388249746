import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';

const ConfirmationFormControlLabel = styled(FormControlLabel)({
  marginTop: 18,
  alignItems: 'flex-start',
  '.MuiCheckbox-root': {
    paddingTop: 2,
  },
  ':first-of-type': {
    marginTop: 24,
  },
  em: {
    fontStyle: 'normal',
    fontWeight: 500,
    textDecoration: 'underline',
  },
});

export default ConfirmationFormControlLabel;
