import { ForwardedRef, forwardRef, useContext } from 'react';
import ContentWrapper from './ContentWrapper';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import PickerBoxArrow from './PickerBoxArrow';
import ColorBoxes from './ColorBoxes';
import PhaseContentGroupContext from '../../../../Phase/PhaseContentGroupContext';

type PickerContentProps = {
  selectedColor: string;
  setSelectedColor: (color: string) => void;
};

const PickerContent = forwardRef(
  (
    { selectedColor, setSelectedColor }: PickerContentProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const phaseContentGroup = useContext(PhaseContentGroupContext);
    const inRightPhaseContentGroup =
      phaseContentGroup === ContentGroupType.RIGHT;

    return (
      <ContentWrapper
        inRightPhaseContentGroup={inRightPhaseContentGroup}
        ref={ref}
      >
        <PickerBoxArrow inRightPhaseContentGroup={inRightPhaseContentGroup} />
        <ColorBoxes
          selectedColor={selectedColor}
          onSelectColor={setSelectedColor}
        />
      </ContentWrapper>
    );
  },
);

PickerContent.displayName = 'PickerContent';

export default PickerContent;
