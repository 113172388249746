import styled from '@emotion/styled';
import LoadingSpinnerWithText from '../../LoadingSpinnerWithText';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import { FormattedMessage } from 'react-intl';

const StyledLoadingSpinnerWithText = styled(LoadingSpinnerWithText)(
  ({ theme }) => ({
    paddingTop: 80,
    paddingBottom: 80,
    '.MuiCircularProgress-root': {
      color: theme.palette.text.secondary,
      opacity: 0.7,
    },
  }),
  addHighContrastStyles({
    '.MuiCircularProgress-root': {
      opacity: 1,
    },
  }),
);

const LoadingView = () => (
  <StyledLoadingSpinnerWithText>
    <FormattedMessage id="perspectives.attached_tasks.loading_message" />
  </StyledLoadingSpinnerWithText>
);

export default LoadingView;
