import Task from '@/components/ChallengePortfolio/NormalView/Task';
import ChallengeContext from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeContext';
import { useContext } from 'react';
import PerspectivePortfolioContext from '../PerspectivePortfolioContext';
import PerspectiveSublevelPortfolioContext from '../PerspectiveSublevelPortfolioContext';
import {
  Challenge,
  SetComplexImmerStateAction,
  TaskSummaryWithChallengeUsers,
} from '@/lib/types';

type AttachedTaskProps = {
  task: TaskSummaryWithChallengeUsers;
  setTasks: SetComplexImmerStateAction<
    Record<number, TaskSummaryWithChallengeUsers>
  >;
  inSublevelTree: boolean;
};

const AttachedTask = ({
  task,
  setTasks,
  inSublevelTree,
}: AttachedTaskProps) => {
  const [, setPerspectivePortfolio] = useContext(PerspectivePortfolioContext);
  const [, setPerspectiveSublevelPortfolio] = useContext(
    PerspectiveSublevelPortfolioContext,
  );

  const setChallenge = (
    updateFunction: (oldState: Challenge) => void,
  ): void => {
    const fakeChallenge = {
      tasks: {
        [task.id]: task,
      },
    } as unknown as Challenge;

    // Note: this directly mutates `fakeChallenge`
    updateFunction(fakeChallenge);

    const updatedTask = fakeChallenge.tasks[
      task.id
    ] as TaskSummaryWithChallengeUsers;

    const taskWasDeleted = typeof updatedTask === 'undefined';

    if (!taskWasDeleted) {
      setTasks(tasks => {
        tasks[task.id] = updatedTask;
      });

      return;
    }

    setPerspectivePortfolio(perspectivePortfolio => {
      for (const perspectiveId in perspectivePortfolio) {
        const directlyAttachedTasks =
          perspectivePortfolio[perspectiveId].directlyAttachedTasks;
        const taskIndex = directlyAttachedTasks.findIndex(
          ({ id }) => id === task.id,
        );

        if (taskIndex == -1) {
          continue;
        }

        directlyAttachedTasks.splice(taskIndex, 1);
      }

      for (const perspectiveId in perspectivePortfolio) {
        const indirectlyAttachedTasks =
          perspectivePortfolio[perspectiveId].indirectlyAttachedTasks;
        const taskIndex = indirectlyAttachedTasks.findIndex(
          ({ id }) => id === task.id,
        );

        if (taskIndex == -1) {
          continue;
        }

        indirectlyAttachedTasks.splice(taskIndex, 1);
      }
    });

    if (inSublevelTree) {
      setPerspectiveSublevelPortfolio(perspectiveSublevelPortfolio => {
        for (const sublevelId in perspectiveSublevelPortfolio) {
          const attachedTasks =
            perspectiveSublevelPortfolio[sublevelId].attachedTasks;
          const taskIndex = attachedTasks.findIndex(({ id }) => id === task.id);

          if (taskIndex == -1) {
            continue;
          }

          attachedTasks.splice(taskIndex, 1);
        }
      });
    }

    setTasks(tasks => {
      delete tasks[task.id];
    });
  };

  return (
    <ChallengeContext.Provider
      value={[task.challenge as Challenge, setChallenge]}
    >
      <Task task={task} disableDrag />
    </ChallengeContext.Provider>
  );
};

export default AttachedTask;
