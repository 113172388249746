import { AllowedEmail } from '@/lib/types';

const convertAllowedEmailToText = ([
  allowedEmailPrefix,
  allowedEmailDomain,
]: AllowedEmail): string => {
  if (!allowedEmailPrefix) {
    return allowedEmailDomain;
  }

  return `${allowedEmailPrefix}*****${allowedEmailDomain}`;
};

const allowedEmailsText = (allowedEmails: AllowedEmail[]): string => {
  if (allowedEmails.length === 0) {
    return '-';
  }

  if (allowedEmails.length === 1) {
    return convertAllowedEmailToText(allowedEmails[0]);
  }

  const texts = allowedEmails.map(allowedEmail =>
    convertAllowedEmailToText(allowedEmail),
  );

  const firstAllowedEmailsText = texts.slice(0, texts.length - 1).join(', ');
  const lastAllowedEmailText = texts[texts.length - 1];

  return `${firstAllowedEmailsText} & ${lastAllowedEmailText}`;
};

export default allowedEmailsText;
