export const WHITE = '#FFFFFF';

const defaultColors = [
  WHITE,
  '#FF691F',
  '#FAB81E',
  '#7FDBB6',
  '#19CF86',
  '#91D2FA',
  '#1B95E0',
  '#E81C4F',
  '#F58EA8',
  '#981CEB',
];

export default defaultColors;
