import usePerspectivesEnabled from '@/lib/utils/usePerspectivesEnabled';
import React, { useContext } from 'react';
import TaskContext from '../TaskContext';
import PerspectiveIndicators from '../../../perspectives/PerspectiveIndicators';
import PerspectiveIndicatorWrapper from '../../../perspectives/PerspectiveIndicators/PerspectiveIndicatorWrapper';
import ManagePerspectivesButton from '../../../perspectives/PerspectiveIndicators/ManagePerspectivesButton';
import { FormattedMessage } from 'react-intl';
import useIsPortfolioReadOnly from '../../../useIsPortfolioReadOnly';

type TaskPerspectiveIndicatorsProps = {
  onManage?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
};

const TaskPerspectiveIndicators = ({
  onManage,
  loading,
}: TaskPerspectiveIndicatorsProps) => {
  const perspectivesEnabled = usePerspectivesEnabled();
  const portfolioIsReadOnly = useIsPortfolioReadOnly();

  const [task] = useContext(TaskContext);
  const attachedPerspectives = Object.values({
    ...task.directlyAttachedPerspectives,
    ...task.indirectlyAttachedPerspectives,
  });

  if (!perspectivesEnabled) {
    return null;
  }

  if (!onManage && attachedPerspectives.length === 0) {
    return null;
  }

  if (attachedPerspectives.length === 0 && portfolioIsReadOnly) {
    return null;
  }

  if (attachedPerspectives.length === 0) {
    return (
      <PerspectiveIndicatorWrapper>
        <ManagePerspectivesButton
          variant="contained"
          onClick={onManage}
          loading={loading ?? false}
          disableElevation
        >
          <FormattedMessage id="perspectives.attachment.attach_button" />
        </ManagePerspectivesButton>
      </PerspectiveIndicatorWrapper>
    );
  }

  return (
    <PerspectiveIndicators
      perspectives={attachedPerspectives}
      onManage={onManage}
      loading={loading}
    />
  );
};

export default TaskPerspectiveIndicators;
