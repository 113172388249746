import { useState } from 'react';
import PillButtons from '../../PillButtons';
import { Add as AddIcon } from '@mui/icons-material';
import ProofPrompt from './ProofPrompt';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import useProofApiEndpoint from '../useProofApiEndpoint';
import { ProofLink, ProofObject } from '@/lib/types';

type AddProofProps = {
  levelId: number;
  setProof: (proofUpdateFunction: (proof: ProofObject) => void) => void;
};

const AddProof = ({ levelId, setProof }: AddProofProps) => {
  const api = useApi();
  const proofApiEndpoint = useProofApiEndpoint();

  const [addPromptOpen, setAddPromptOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveNewProof = async (newProof: string) => {
    setAddPromptOpen(false);

    setSaving(true);

    const proofLink = await api.post<ProofLink>(
      `${proofApiEndpoint}/${levelId}`,
      {
        link: newProof,
      },
    );

    setProof(proof => {
      proof[proofLink.id] = proofLink.link;
    });

    setSaving(false);
  };

  return (
    <>
      <PillButtons.Button
        onClick={() => setAddPromptOpen(true)}
        disabled={saving}
        standAlone
      >
        <AddIcon />
      </PillButtons.Button>
      <ProofPrompt
        open={addPromptOpen}
        onOk={saveNewProof}
        onCancel={() => setAddPromptOpen(false)}
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.level.proof.add.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio.level.proof.add.text" />
          </Dialog.ContentText>
        </Dialog.Content>
      </ProofPrompt>
    </>
  );
};

export default AddProof;
