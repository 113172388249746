import { useRef, useEffect } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import device from 'current-device';
import { FormattedMessage } from 'react-intl';

type PromptTextFieldProps = Omit<
  TextFieldProps,
  'variant' | 'helperText' | 'error' | 'inputRef' | 'autoFocus' | 'fullWidth'
> & {
  hasEmptyError: boolean;
};

const PromptTextField = ({ hasEmptyError, ...props }: PromptTextFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (hasEmptyError && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [hasEmptyError]);

  return (
    <TextField
      variant="filled"
      helperText={
        hasEmptyError && <FormattedMessage id="prompt.errors.name_empty" />
      }
      error={hasEmptyError}
      inputRef={inputRef}
      autoFocus={device.desktop()}
      fullWidth
      {...props}
    />
  );
};

export default PromptTextField;
