import { MessageDescriptor, useIntl } from 'react-intl';
import App from '@/lib/App';

const useFormatMessageWithChallengeNaming = () => {
  const intl = useIntl();

  const {
    challengeNameSingular,
    challengeNameSingularCapitalized,
    challengeNamePlural,
  } = App.instanceConfig.useChallengeNaming();

  const formatMessageWithChallengeNaming = (
    options: MessageDescriptor,
    values: Record<string, unknown> = {},
  ): string =>
    intl.formatMessage(options, {
      challengeNameSingular,
      challengeNameSingularCapitalized,
      challengeNamePlural,
      ...values,
    });

  return formatMessageWithChallengeNaming;
};

export default useFormatMessageWithChallengeNaming;
