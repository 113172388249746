import { useContext, useState } from 'react';
import Dialog from '@/components/Dialog';
import DeleteButton from '../PortfolioTemplatesEditorActionButtons/DeleteButton';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import useEditorPartsApiEndpoint from './useEditorPartsApiEndpoint';
import { EditorPart } from '@/lib/types';
import EditorPortfolioTemplatesContext from '../../EditorPortfolioTemplatesContext';

type DeletePartProps = {
  part: EditorPart;
};

const DeletePart = ({ part }: DeletePartProps) => {
  const api = useApi();
  const editorPartsApiEndpoint = useEditorPartsApiEndpoint();

  const [, setPortfolioTemplates] = useContext(EditorPortfolioTemplatesContext);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deletePart = async (): Promise<void> => {
    setConfirmationDialogOpen(false);
    setDeleting(true);

    await api.delete(`${editorPartsApiEndpoint}/${part.id}`);

    setPortfolioTemplates(portfolioTemplates => {
      delete portfolioTemplates.parts[part.id];
    });

    setDeleting(false);
  };

  return (
    <>
      <DeleteButton
        onClick={() => setConfirmationDialogOpen(true)}
        disabled={deleting}
      />
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio_template_editor.part.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio_template_editor.part.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deletePart} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeletePart;
