import StyledListItemButton from './StyledListItemButton';
import { ListItemButtonProps, ListItemText } from '@mui/material';
import { Row } from '@/lib/types';

type PickableRowProps = Omit<ListItemButtonProps, 'selected' | 'children'> & {
  row: Row;
  selected: boolean;
};

const PickableRow = ({ row, ...props }: PickableRowProps) => {
  return (
    <StyledListItemButton {...props}>
      <ListItemText primary={row.name} />
    </StyledListItemButton>
  );
};

export default PickableRow;
