import StyledDeleteButton from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/MobileChallengeActions/ActionButtons/DeleteButton/StyledDeleteButton';
import StyledDeleteIcon from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/MobileChallengeActions/ActionButtons/DeleteButton/StyledDeleteIcon';
import { ActionButtonProps } from './ActionButton';

type DeleteButtonProps = Omit<
  ActionButtonProps,
  'icon' | 'labelId' | 'disabled'
> & {
  disabled?: boolean;
};

const DeleteButton = ({ disabled, ...props }: DeleteButtonProps) => (
  <StyledDeleteButton
    icon={<StyledDeleteIcon disabled={disabled} />}
    labelId="portfolio.challenge.drawer.delete.button.as_task"
    disabled={disabled}
    {...props}
  />
);

export default DeleteButton;
