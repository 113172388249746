import { Tooltip } from '@mui/material';
import FloatingUserSwitchArrow from './FloatingUserSwitchArrow';
import NormalUserSwitchArrow from './NormalUserSwitchArrow';
import NormalUserSwitchArrowLabel from './NormalUserSwitchArrow/NormalUserSwitchArrowLabel';
import React from 'react';
import { HTMLButtonProps } from '@/lib/types';

type UserSwitchArrowProps = Omit<HTMLButtonProps, 'color' | 'ref'> & {
  label: React.ReactNode;
  floating: boolean;
  disabled: boolean;
  right?: boolean;
  children: React.ReactNode;
};

const UserSwitchArrow = ({
  label,
  floating,
  disabled,
  children,
  ...props
}: UserSwitchArrowProps) => {
  if (floating && disabled) {
    return null;
  }

  if (floating) {
    return (
      <Tooltip title={label}>
        <FloatingUserSwitchArrow color="primary" {...props}>
          {children}
        </FloatingUserSwitchArrow>
      </Tooltip>
    );
  }

  return (
    <NormalUserSwitchArrow disabled={disabled} {...props}>
      {children}
      <NormalUserSwitchArrowLabel>{label}</NormalUserSwitchArrowLabel>
    </NormalUserSwitchArrow>
  );
};

export default UserSwitchArrow;
