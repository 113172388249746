import styled from '@emotion/styled';
import PossiblyDangerousIconButton from '@/components/PossiblyDangerousIconButton';

const FeedbackItemActionButton = styled(PossiblyDangerousIconButton)({
  marginTop: -6,
  marginBottom: -6,
  padding: 6,
});

export default FeedbackItemActionButton;
