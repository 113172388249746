import { useContext, useState } from 'react';
import ActionButton from '../PortfolioTemplatesEditorActionButtons/ActionButton';
import { ControlPointDuplicate as CopyIcon } from '@mui/icons-material';
import useApi from '@/lib/api/useApi';
import useAddCopySuffix from '@/lib/utils/useAddCopySuffix';
import { EditorLevelWithoutRelations, EditorPart } from '@/lib/types';
import EditorPortfolioTemplatesContext from '../../EditorPortfolioTemplatesContext';

type CopyPartProps = {
  part: EditorPart;
  highestPosition: number;
};

type EditorPartCopy = Omit<EditorPart, 'levels'> & {
  levels: EditorLevelWithoutRelations[];
};

const CopyPart = ({ part, highestPosition }: CopyPartProps) => {
  const api = useApi();
  const addCopySuffix = useAddCopySuffix();

  const [, setPortfolioTemplates] = useContext(EditorPortfolioTemplatesContext);
  const [saving, setSaving] = useState(false);

  const copyPart = async (): Promise<void> => {
    setSaving(true);

    const partCopyPosition = Math.ceil(highestPosition) + 1;

    const partCopy = await api.post<EditorPartCopy>(
      `editor/portfolio-templates/parts/${part.id}/copy`,
      {
        name: addCopySuffix(part.name),
        position: partCopyPosition,
      },
    );

    setPortfolioTemplates(portfolioTemplates => {
      for (const level of partCopy.levels) {
        portfolioTemplates.levels[level.id] = level;
      }

      portfolioTemplates.parts[partCopy.id] = {
        ...partCopy,
        levels: partCopy.levels.map(level => ({
          id: level.id,
          position: level.position,
        })),
      };

      portfolioTemplates.subtopics[part.subtopicId].parts.push({
        id: partCopy.id,
        position: partCopy.position,
      });
    });

    setSaving(false);
  };

  return (
    <ActionButton onClick={copyPart} disabled={saving}>
      <CopyIcon />
    </ActionButton>
  );
};

export default CopyPart;
