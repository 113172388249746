import LoginButton from '../LoginButton';
import { FormattedMessage } from 'react-intl';
import useLoginWithGoogle from './useLoginWithGoogle';
import GoogleLogo from './GoogleLogo';

type GoogleLoginProps = {
  onShowError: (error: unknown) => void;
  disabled: boolean;
};

const GoogleLogin = ({ onShowError, disabled }: GoogleLoginProps) => {
  const [loginWithGoogle, loading] = useLoginWithGoogle(onShowError);

  return (
    <LoginButton
      onClick={loginWithGoogle}
      startIcon={<GoogleLogo />}
      loading={loading}
      loadingPosition="start"
      disabled={disabled}
    >
      <FormattedMessage id="login.sign_in_button.google.label" />
    </LoginButton>
  );
};

export default GoogleLogin;
