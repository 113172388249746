import { useTheme } from '@emotion/react';
import PlatformType from '@/lib/constants/PlatformType.enum';
import challengemonitorIconLightMode from './icons/challengemonitor-light-mode.svg';
import challengemonitorIconDarkMode from './icons/challengemonitor-dark-mode.svg';
import progressmonitorIconLightMode from './icons/progressmonitor-light-mode.svg';
import progressmonitorIconDarkMode from './icons/progressmonitor-dark-mode.svg';

const useActivePlatformIcons = () => {
  const theme = useTheme();

  return {
    [PlatformType.CHALLENGEMONITOR]: theme.isDark
      ? challengemonitorIconDarkMode
      : challengemonitorIconLightMode,
    [PlatformType.PROGRESSMONITOR]: theme.isDark
      ? progressmonitorIconDarkMode
      : progressmonitorIconLightMode,
  } as const;
};

export default useActivePlatformIcons;
