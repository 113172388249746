import styled from '@emotion/styled';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import React from 'react';

type DayMarkerProps = {
  isToday: boolean;
  children: React.ReactNode;
};

const DayMarker = styled('div')<DayMarkerProps>(
  ({ theme, isToday }) => ({
    paddingTop: 2,
    paddingBottom: 2,
    flex: 1,
    color: isToday ? theme.palette.primary.main : undefined,
    fontWeight: isToday ? 500 : 400,
    borderRight: `2px solid ${theme.palette.background.border}`,
    transition: theme.transitions.create('all'),
    boxSizing: 'border-box',
    ':last-child': {
      borderColor: 'transparent',
    },
  }),
  addHighContrastStyles(({ isToday }) => {
    if (isToday) {
      return {
        textDecoration: 'underline',
      };
    }
  }),
);

export default DayMarker;
