import { useTheme } from '@emotion/react';
import StyledAvatar from './StyledAvatar';
import useProfilePicture from './useProfilePicture';
import useRandomColor from '../../lib/styling/useRandomColor';
import { AvatarProps } from '@mui/material';
import { UserSummary } from '@/lib/types';

type UserAvatarProps = Omit<AvatarProps, 'src' | 'children'> & {
  user: UserSummary;
  marginRight?: number;
  size?: number;
};

const UserAvatar = ({ user, size, ...props }: UserAvatarProps) => {
  const profilePicture = useProfilePicture(user);

  const avatarColor = useRandomColor();

  const theme = useTheme();
  const avatarColorShade = theme.isDark ? 200 : 600;

  return (
    <StyledAvatar
      src={profilePicture ?? undefined}
      size={size ?? 40}
      backgroundColor={
        theme.palette.isHighContrast
          ? theme.palette.divider
          : avatarColor[avatarColorShade]
      }
      {...props}
    >
      {user.name.charAt(0)}
    </StyledAvatar>
  );
};

export default UserAvatar;
