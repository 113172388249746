import useWindowWidth from './useWindowWidth';

const useResponsiveVisibleWeeksCountCalculation = (): number => {
  const windowWidth = useWindowWidth();

  if (windowWidth <= 700) {
    return 1;
  }

  const visibleWeeksCount = Math.ceil(windowWidth / 500);

  return visibleWeeksCount;
};

export default useResponsiveVisibleWeeksCountCalculation;
