import api from '.';
import HttpMethod from '../constants/HttpMethod.enum';
import ApiAuthLocalStorage from './ApiAuthLocalStorage';
import ApiError from './ApiHandlingContext/ApiErrors/ApiError';
import * as Sentry from '@sentry/react';

type RefreshedAccessTokenResponse = {
  // TODO why is this nullable?
  accessToken: string | null;
};

type RefreshedAccessTokenListener = (accessToken: string | null) => void;

class AccessTokenRefresher {
  private isRefreshing: boolean = false;
  private refreshedAccessTokenListeners: RefreshedAccessTokenListener[] = [];

  refreshAccessToken(): Promise<string | null> {
    if (!this.isRefreshing) {
      this.requestRefreshedAccessToken();
    }

    return new Promise(resolve => {
      this.refreshedAccessTokenListeners.push(
        (accessToken: string | null): void => {
          resolve(accessToken);
        },
      );
    });
  }

  private async requestRefreshedAccessToken(): Promise<void> {
    if (this.isRefreshing) {
      return;
    }

    this.isRefreshing = true;

    const { refreshToken } = ApiAuthLocalStorage.getTokens();

    if (refreshToken === null) {
      this.emitRefreshedAccessToken(null);

      return;
    }

    try {
      const response = await api.callWithoutAuthorizationHandling<
        RefreshedAccessTokenResponse
      >('auth/access-tokens/refresh', {
        method: HttpMethod.POST,
        headers: {
          'Refresh-Token': refreshToken,
        },
      });

      this.emitRefreshedAccessToken(response.accessToken ?? null);
    } catch (error) {
      if (
        typeof error !== 'object' ||
        error === null ||
        !('prototype' in error) ||
        !(error instanceof ApiError)
      ) {
        Sentry.captureException(error);
      }

      this.emitRefreshedAccessToken(null);
    }
  }

  private emitRefreshedAccessToken(accessToken: string | null): void {
    for (const listener of this.refreshedAccessTokenListeners) {
      listener(accessToken);
    }

    this.refreshedAccessTokenListeners = [];
    this.isRefreshing = false;
  }
}

const accessTokenRefresher = new AccessTokenRefresher();

export default accessTokenRefresher;
