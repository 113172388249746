import styled from '@emotion/styled';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import { HTMLDivProps } from '@/lib/types';

type HeaderWrapperProps = HTMLDivProps & {
  shadeBackground?: boolean;
};

const HeaderWrapper = styled('header')<HeaderWrapperProps>(
  ({ theme, shadeBackground }) => ({
    margin: -12,
    marginBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    height: 48,
    backgroundColor: shadeBackground
      ? theme.palette.action.disabledBackground
      : theme.palette.action.selected,
    transition: theme.transitions.create('all'),
    display: 'flex',
    alignItems: 'center',
  }),
  addHighContrastStyles(({ theme, shadeBackground }) => ({
    backgroundColor: shadeBackground
      ? theme.palette.action.hover
      : 'transparent',
    borderBottom: `2px solid ${theme.palette.divider}`,
  })),
);

export default HeaderWrapper;
