import { useState } from 'react';
import AddLevelWrapper from './AddLevelWrapper';
import LevelForm from './LevelForm';
import AddLevelButton from './AddLevelButton';

type AddLevelProps = {
  partId: number;
  highestLevelPosition: number;
};

const AddLevel = ({ partId, highestLevelPosition }: AddLevelProps) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <AddLevelWrapper
      collapsed={collapsed}
      onClick={() => collapsed && setCollapsed(false)}
    >
      {collapsed ? (
        <AddLevelButton onClick={() => setCollapsed(false)} />
      ) : (
        <LevelForm
          partId={partId}
          highestLevelPosition={highestLevelPosition}
          onClose={() => setCollapsed(true)}
        />
      )}
    </AddLevelWrapper>
  );
};

export default AddLevel;
