import styled from '@emotion/styled';

const AvatarsWrapper = styled('div')({
  marginTop: 'auto',
  maxHeight: 24,
  display: 'flex',
  justifyContent: 'flex-end',
});

export default AvatarsWrapper;
