import { ForwardedRef, forwardRef } from 'react';
import DangerColorThemeProvider from '@/components/DangerColorThemeProvider';
import { IconButton, IconButtonProps } from '@mui/material';

const DangerousIconButton = forwardRef(
  (props: IconButtonProps, ref: ForwardedRef<HTMLButtonElement>) => (
    <DangerColorThemeProvider>
      <IconButton color="primary" ref={ref} {...props} />
    </DangerColorThemeProvider>
  ),
);

DangerousIconButton.displayName = 'DangerousIconButton';

export default DangerousIconButton;
