import IndicatorWrapper from './IndicatorWrapper';
import CardPerspectiveIndicator from './CardPerspectiveIndicator';
import HiddenIndicatorsCount from './HiddenIndicatorsCount';
import { PerspectiveSummary } from '@/lib/types';

type CardPerspectiveIndicatorsProps = {
  perspectives: PerspectiveSummary[];
};

const CardPerspectiveIndicators = ({
  perspectives,
}: CardPerspectiveIndicatorsProps) => (
  <IndicatorWrapper>
    {perspectives.length > 4 ? (
      <>
        {perspectives.slice(0, 3).map(perspective => (
          <CardPerspectiveIndicator
            perspective={perspective}
            key={perspective.id}
          />
        ))}
        <HiddenIndicatorsCount count={perspectives.length - 3} />
      </>
    ) : (
      perspectives.map(perspective => (
        <CardPerspectiveIndicator
          perspective={perspective}
          key={perspective.id}
        />
      ))
    )}
  </IndicatorWrapper>
);

export default CardPerspectiveIndicators;
