import { useState } from 'react';
import { AlertTitle } from '@mui/material';
import ErrorAlert from './ErrorAlert';
import { AnimatePresence } from 'framer-motion';
import useTimeout from 'use-timeout';
import { FormattedMessage } from 'react-intl';
import HttpMethod from '@/lib/constants/HttpMethod.enum';

const DISMISSAL_TIMEOUT = 6_000;

type ApiErrorProps = {
  error: unknown;
  hidden: boolean;
};

const ApiError = ({ error, hidden, ...props }: ApiErrorProps) => {
  const [dismissed, setDismissed] = useState(false);

  useTimeout(() => setDismissed(true), DISMISSAL_TIMEOUT);

  // TODO maybe there's a nicer way to do this
  const isSaveError =
    error !== null &&
    typeof error === 'object' &&
    'httpMethod' in error &&
    !!error.httpMethod &&
    [HttpMethod.POST, HttpMethod.PUT, HttpMethod.DELETE].includes(
      error.httpMethod as HttpMethod,
    );

  return (
    <AnimatePresence>
      {dismissed || hidden ? null : (
        <ErrorAlert onDismiss={() => setDismissed(true)} key="alert" {...props}>
          <AlertTitle>
            <FormattedMessage
              id={`api_errors.error.title.${
                isSaveError ? 'save_failed' : 'unknown_error'
              }`}
            />
          </AlertTitle>
          <FormattedMessage id="api_errors.error.message" />
        </ErrorAlert>
      )}
    </AnimatePresence>
  );
};

export default ApiError;
