import AttachmentButton from './AttachmentButton';
import { useState, useEffect } from 'react';
import Dialog from '@/components/Dialog';
import AttachedTasksView from '../../AttachedTasksView';
import AttachedTasksDialog from './AttachedTasksDialog';
import { AttachmentInfo, Perspective } from '@/lib/types';

type PerspectiveTaskAttachmentProps = {
  attachmentInfo: AttachmentInfo;
  perspective: Perspective;
};

const PerspectiveTaskAttachment = ({
  attachmentInfo,
  perspective,
}: PerspectiveTaskAttachmentProps) => {
  const [attachedTasksViewOpen, setAttachedTasksViewOpen] = useState(false);

  useEffect(() => {
    if (attachmentInfo.attachedTasksCount === 0) {
      setAttachedTasksViewOpen(false);
    }
  }, [attachmentInfo.attachedTasksCount]);

  return (
    <>
      <AttachmentButton
        attachedTasksCount={attachmentInfo.attachedTasksCount}
        onClick={() => setAttachedTasksViewOpen(true)}
        disabled={attachmentInfo.attachedTasksCount === 0}
      />
      <AttachedTasksDialog
        perspectiveColor={perspective.color}
        open={attachedTasksViewOpen}
        onClose={() => setAttachedTasksViewOpen(false)}
      >
        <AttachedTasksView
          attachmentInfo={attachmentInfo}
          perspective={perspective}
        />
        <Dialog.Actions>
          <Dialog.Actions.DoneButton
            onClick={() => setAttachedTasksViewOpen(false)}
          />
        </Dialog.Actions>
      </AttachedTasksDialog>
    </>
  );
};

export default PerspectiveTaskAttachment;
