import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import App from '@/lib/App';

const usePerspectivesEnabled = (): boolean => {
  const perspectiveModuleEnabled = App.instanceConfig.useSetting(
    'enablePerspectiveModule',
  );
  const authenticatedUser = useAuthenticatedUser();

  return (
    perspectiveModuleEnabled && authenticatedUser?.perspectiveCohort !== null
  );
};

export default usePerspectivesEnabled;
