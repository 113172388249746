import { useContext } from 'react';
import useFieldMoving from './useFieldMoving';
import usePossibleMovesState from './usePossibleMovesState';
import MoveModeContext from '../../../../../MoveModeContext';
import MoveButtons from '../../../PositionMoveButtons';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';

type FieldPositionMovingProps = {
  allHighestPositions: Record<ContentGroupType, number>;
  lowestFieldPositionInPhaseBottomContent: number;
};

const FieldPositionMoving = ({
  allHighestPositions,
  lowestFieldPositionInPhaseBottomContent,
}: FieldPositionMovingProps) => {
  const [moveUp, moveDown] = useFieldMoving(
    allHighestPositions,
    lowestFieldPositionInPhaseBottomContent,
  );
  const [canMoveUp, canMoveDown] = usePossibleMovesState();

  const inMoveMode = useContext(MoveModeContext);
  if (!inMoveMode) {
    return null;
  }

  return (
    <MoveButtons.Wrapper>
      {canMoveUp && <MoveButtons.Up onClick={moveUp} />}
      {canMoveDown && <MoveButtons.Down onClick={moveDown} />}
    </MoveButtons.Wrapper>
  );
};

export default FieldPositionMoving;
