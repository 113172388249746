import { useContext } from 'react';
import SettingsFieldTitle from '../../SettingsFieldTitle';
import { RadioGroup, RadioGroupProps } from '@mui/material';
import StyledRadioWrapper from './StyledRadioWrapper';
import RadioWithoutPadding from './RadioWithoutPadding';
import IdentityConnectionProvider from '@/lib/constants/IdentityConnectionProvider.enum';
import { FormattedMessage } from 'react-intl';
import SchoolContext from '../..//SchoolContext';

const AuthProviderPicker = (props: RadioGroupProps) => {
  const [loading] = useContext(SchoolContext);

  return (
    <>
      <SettingsFieldTitle>
        <FormattedMessage id="platform_settings.login_settings.change_auth_provider" />
      </SettingsFieldTitle>
      <RadioGroup name="auth-provider" {...props}>
        <StyledRadioWrapper
          value={IdentityConnectionProvider.GOOGLE}
          control={<RadioWithoutPadding disabled={loading} />}
          label="Google"
        />
        <StyledRadioWrapper
          value={IdentityConnectionProvider.MICROSOFT}
          control={<RadioWithoutPadding disabled={loading} />}
          label="Microsoft"
        />
      </RadioGroup>
    </>
  );
};

export default AuthProviderPicker;
