import { FormControlLabel, Checkbox, CheckboxProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type SettingsCheckboxProps = CheckboxProps & {
  label: string;
};

const SettingsCheckbox = ({ label, ...props }: SettingsCheckboxProps) => (
  <>
    <FormControlLabel
      control={<Checkbox {...props} />}
      label={<FormattedMessage id={label} />}
    />
    <br />
  </>
);

export default SettingsCheckbox;
