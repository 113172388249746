import styled from '@emotion/styled';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';

type LoginButtonProps = Omit<
  LoadingButtonProps,
  'variant' | 'disableElevation'
>;

const StyledButton = styled(LoadingButton)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.selected,
    overflow: 'hidden',
    ':hover': {
      backgroundColor: theme.palette.action.disabled,
    },
  }),
  addHighContrastStyles({
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  }),
  addBorderInHighContrastMode,
);

const LoginButton = (props: LoginButtonProps) => (
  <StyledButton variant="contained" disableElevation {...props} />
);

export default LoginButton;
