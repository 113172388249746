import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

type AdminControlsWrapperProps = {
  inCoachMode: boolean;
  wideContent: boolean;
};

const AdminControlsWrapper = styled('section')<AdminControlsWrapperProps>(
  ({ theme }) => ({
    position: 'relative',
    height: 72,
    flex: 1,
    transition: theme.transitions.create('all'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 1,
  }),
  ({ inCoachMode, wideContent }) => {
    if (inCoachMode && !wideContent) {
      return {
        [mediaQuery(1400)]: {
          position: 'absolute',
          top: 72,
          right: 148,
          width: 'calc(100% - 296px)',
        },
        [mediaQuery(1024)]: {
          right: 72,
          width: 'calc(100% - 144px)',
        },
        [mediaQuery(850)]: {
          right: 24,
          width: 'calc(100% - 48px)',
        },
      };
    }

    if (inCoachMode && wideContent) {
      return {
        [mediaQuery(1400)]: {
          position: 'absolute',
          top: 72,
          right: 24,
          width: 'calc(100% - 48px)',
        },
      };
    }
  },
  ({ theme }) => ({
    [mediaQuery(700)]: {
      position: 'relative',
      top: 0,
      left: 0,
      marginTop: 24,
      marginLeft: -24,
      marginRight: -24,
      marginBottom: -24,
      padding: 24,
      width: 'calc(100% + 48px)',
      height: 'auto',
      backgroundColor: theme.palette.action.hover,
      boxSizing: 'border-box',
      display: 'block',
    },
  }),
);

export default AdminControlsWrapper;
