import styled from '@emotion/styled';

const SettingsBoxIcon = styled('figure')(({ theme }) => ({
  margin: 0,
  marginRight: 12,
  color: theme.palette.text.primary,
  display: 'flex',
}));

export default SettingsBoxIcon;
