import { useContext } from 'react';
import { List } from '@mui/material';
import PickableRow from './PickableRow';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import styled from '@emotion/styled';

type PickableRowsProps = {
  selectedRow: number | null;
  onSelectRow: (rowId: number) => void;
};

const StyledList = styled(List)({
  overflowY: 'auto',
});

const PickableRows = ({ selectedRow, onSelectRow }: PickableRowsProps) => {
  const [challengePortfolio] = useContext(ChallengePortfolioContext);

  return (
    <StyledList>
      {Object.values(challengePortfolio.rows).map(row => (
        <PickableRow
          row={row}
          selected={row.id === selectedRow}
          onClick={() => onSelectRow(row.id)}
          key={row.id}
        />
      ))}
    </StyledList>
  );
};

export default PickableRows;
