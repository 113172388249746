import { Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React from 'react';

type ToggleButtonTooltipProps = {
  isUsingPlanningView: boolean;
  children: React.ReactElement;
};

const ToggleButtonTooltip = ({
  isUsingPlanningView,
  children,
}: ToggleButtonTooltipProps) => (
  <Tooltip
    title={
      <FormattedMessage
        id={`portfolio.challenge_planning_view.toggle_button.${
          isUsingPlanningView ? 'normal_view' : 'planning_view'
        }`}
      />
    }
  >
    {children}
  </Tooltip>
);

export default ToggleButtonTooltip;
