import { ForwardedRef, forwardRef } from 'react';
import CollapsibleActionMenu from '@/components/CollapsibleActionMenu';
import { ListItemIcon } from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { ActionMenuButtonProps } from '@/components/CollapsibleActionMenu/ActionMenuButton';

type RowCollapseButtonProps = Omit<
  ActionMenuButtonProps,
  'children' | 'ref'
> & {
  rowCollapsed: boolean;
};

const RowCollapseButton = forwardRef(
  (
    { rowCollapsed, ...props }: RowCollapseButtonProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => (
    <CollapsibleActionMenu.Button {...props} ref={ref}>
      <ListItemIcon>
        {rowCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </ListItemIcon>
      <FormattedMessage
        id={`portfolio.row.mobile_row_actions.collapse_toggle.${
          rowCollapsed ? 'uncollapse' : 'collapse'
        }`}
      />
    </CollapsibleActionMenu.Button>
  ),
);

RowCollapseButton.displayName = 'RowCollapseButton';

export default RowCollapseButton;
