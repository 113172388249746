import ActionButton, { ActionButtonProps } from './ActionButton';
import { FileCopyOutlined as CopyIcon } from '@mui/icons-material';

type CopyButtonProps = Omit<ActionButtonProps, 'icon' | 'labelId'>;

const CopyButton = (props: CopyButtonProps) => (
  <ActionButton
    icon={<CopyIcon />}
    labelId="portfolio.challenge.drawer.copy.button"
    {...props}
  />
);

export default CopyButton;
