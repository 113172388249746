import ActionButton, { ActionButtonProps } from './ActionButton';
import { Edit as EditIcon } from '@mui/icons-material';

type EditButtonProps = Omit<ActionButtonProps, 'children'>;

const EditButton = (props: EditButtonProps) => (
  <ActionButton {...props}>
    <EditIcon />
  </ActionButton>
);

export default EditButton;
