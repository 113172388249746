import { FormattedMessage } from 'react-intl';

type GroupCountTextProps = {
  groupCount: number;
  asManagedGroups: boolean;
};

const GroupCountText = ({
  groupCount,
  asManagedGroups,
}: GroupCountTextProps) => {
  const singularTranslationId = asManagedGroups
    ? 'user_manager.user_list.user.group_select.values.managed_groups_singular'
    : 'user_manager.user_list.user.group_select.values.groups_singular';
  const pluralTranslationId = asManagedGroups
    ? 'user_manager.user_list.user.group_select.values.managed_groups_plural'
    : 'user_manager.user_list.user.group_select.values.groups_plural';

  if (groupCount === 1) {
    return <FormattedMessage id={singularTranslationId} />;
  }

  return (
    <FormattedMessage
      id={pluralTranslationId}
      values={{ groupCount: groupCount }}
    />
  );
};

export default GroupCountText;
