import styled from '@emotion/styled';
import Dialog from '@/components/Dialog';
import mediaQuery from '@/lib/styling/mediaQuery';
import { FormattedMessage } from 'react-intl';

const MobileOnlyDialogTitle = styled(Dialog.Title)({
  marginBottom: -8,
  paddingBottom: 0,
  display: 'none',
  [mediaQuery(900)]: {
    display: 'block',
  },
});

const MobileDialogTitle = () => (
  <MobileOnlyDialogTitle>
    <FormattedMessage id="perspectives.attachment.title.mobile" />
  </MobileOnlyDialogTitle>
);

export default MobileDialogTitle;
