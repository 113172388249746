import ErrorText from './ErrorText';
import { FormattedMessage } from 'react-intl';
import FormattedHtmlMessage from '@/components/FormattedHtmlMessage';

type GenericErrorScreenContentProps = {
  errorCode: string;
};

const GenericErrorScreenContent = ({
  errorCode,
}: GenericErrorScreenContentProps) => (
  <>
    <h2>
      <FormattedMessage id="login.error_screen.generic.title" />
    </h2>
    <ErrorText>
      <FormattedHtmlMessage
        id="login.error_screen.generic.text"
        values={{
          errorCode,
        }}
      />
    </ErrorText>
  </>
);

export default GenericErrorScreenContent;
