import DangerousButton from '@/components/DangerousButton';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import { ButtonProps } from '@mui/material';

type DialogLeaveButtonProps = Omit<ButtonProps, 'children'>;

const DialogLeaveButton = (props: DialogLeaveButtonProps) => (
  <DangerousButton inDialog {...props}>
    <FormattedMessageWithChallengeNaming id="portfolio.challenge.sharing.leave.dialog_leave_button" />
  </DangerousButton>
);

export default DialogLeaveButton;
