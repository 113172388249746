import InputWithEscListener, {
  InputWithEscListenerProps,
} from '@/components/InputWithEscListener';
import { TextField, TextFieldProps } from '@mui/material';
import { useIntl } from 'react-intl';

type OptionNameTextFieldProps = Omit<
  InputWithEscListenerProps<TextFieldProps>,
  | 'InputComponent'
  | 'variant'
  | 'size'
  | 'placeholder'
  | 'style'
  | 'autoFocus'
  | 'fullWidth'
>;

const OptionNameTextField = (props: OptionNameTextFieldProps) => {
  const intl = useIntl();

  return (
    <InputWithEscListener<TextFieldProps>
      InputComponent={TextField}
      variant="standard"
      size="small"
      placeholder={intl.formatMessage({
        id:
          'editor.model_editor.field_editor.select_editor.opion.edit_name.input_placeholder',
      })}
      style={{
        marginRight: 6,
        marginBottom: -4,
      }}
      autoFocus
      fullWidth
      {...props}
    />
  );
};

export default OptionNameTextField;
