import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const ReportTitle = styled('h2')({
  marginTop: 36,
  marginBottom: 12,
  [mediaQuery(400)]: {
    marginTop: 28,
    fontSize: 20,
  },
});

export default ReportTitle;
