import { useContext } from 'react';
import ManagerWrapper from './ManagerWrapper';
import Assignee from './Assignee';
import AddAssignees from './AddAssignees';
import TaskContext from '@/components/ChallengePortfolio/NormalView/Task/TaskView/TaskContext';
import sortByName from '@/lib/utils/sorting/sortByName';
import immerCompatibleArraySort from '@/lib/utils/immerCompatibleArraySort';

const AssigneesManager = () => {
  const [task] = useContext(TaskContext);

  return (
    <ManagerWrapper>
      {immerCompatibleArraySort(task.assignees, sortByName).map(user => (
        <Assignee user={user} key={user.email} />
      ))}
      <AddAssignees />
    </ManagerWrapper>
  );
};

export default AssigneesManager;
