import { InstanceConfig } from '@/lib/types';
import useSettings from './useSettings';

function useSetting<K extends keyof InstanceConfig>(
  settingKey: K,
): InstanceConfig[K] {
  const instanceConfig = useSettings();

  return instanceConfig?.[settingKey];
}

export default useSetting;
