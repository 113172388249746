import FieldContext from '@/pages/EditorPage/ChallengeModelEditor/ModelEditorForModelType/ModelPhaseEditor/ModelFieldEditor/FieldContext';
import { useContext } from 'react';

// This is used to make EditorPart work with RubricsEditor in the challenge
// model editor
const useEditorPartsApiEndpoint = (): string => {
  const { field } = useContext(FieldContext);

  if (field !== null) {
    return `editor/challenge-model/fields/${field.id}/parts`;
  }

  return 'editor/portfolio-templates/parts';
};

export default useEditorPartsApiEndpoint;
