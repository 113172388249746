import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const StyledLogo = styled('img')({
  height: 40,
  display: 'block',
  [mediaQuery(768)]: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  [mediaQuery(700)]: {
    marginTop: -4,
    marginBottom: -4,
    padding: 0,
    height: 35,
    display: 'block',
  },
});

export default StyledLogo;
