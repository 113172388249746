import useSelectedSchool from '@/components/App/AppSetup/SelectedSchool/useSelectedSchool';
import useApi from '@/lib/api/useApi';

const useLogoUrl = (): string | null => {
  const api = useApi();
  const selectedSchool = useSelectedSchool();

  if (!selectedSchool?.logoFilename) {
    return null;
  }

  const logoUrl = api.url(`/logos/${selectedSchool.logoFilename}`);

  return logoUrl;
};

export default useLogoUrl;
