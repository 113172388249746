import styled from '@emotion/styled';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';

const StyledFeedbackItem = styled('article')(
  ({ theme }) => ({
    marginBottom: 12,
    padding: 12,
    fontSize: 14,
    backgroundColor: theme.palette.action.hover,
    borderRadius: 4,
    overflow: 'hidden',
    ':last-of-type': {
      marginBottom: 0,
    },
  }),
  addBorderInHighContrastMode,
);

export default StyledFeedbackItem;
