import styled from '@emotion/styled';
import { lighten } from '@mui/material';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import { PerspectiveSummary, HTMLSpanProps } from '@/lib/types';

type IconFallbackProps = {
  perspective: PerspectiveSummary;
};

type StyledLetterProps = HTMLSpanProps & {
  color: string;
};

const StyledLetter = styled('strong')<StyledLetterProps>(
  ({ theme, color }) => ({
    color: theme.isDark ? lighten(color, 0.6) : color,
    fontWeight: 500,
  }),
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.text.primary,
  })),
);

const IconFallback = ({ perspective }: IconFallbackProps) => (
  <StyledLetter color={perspective.color}>
    {perspective.name.charAt(0)}
  </StyledLetter>
);

export default IconFallback;
