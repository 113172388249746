import useProof from './useProof';
import PillButtons from './PillButtons';
import { Link as LinkIcon } from '@mui/icons-material';

const PillButtonAsLink = PillButtons.Button.withComponent('a');

type LevelProofReadOnlyProps = {
  levelId: number;
};

const LevelProofReadOnly = ({ levelId }: LevelProofReadOnlyProps) => {
  const [proof] = useProof(levelId);

  return Object.entries(proof).map(([proofLinkId, proofLinkUrl]) => (
    <PillButtonAsLink
      href={proofLinkUrl}
      target="_blank"
      standAlone
      key={proofLinkId}
    >
      <LinkIcon />
    </PillButtonAsLink>
  ));
};

export default LevelProofReadOnly;
