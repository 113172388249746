import modeInfo from '@/lib/mode';
import ModeLink from './ModeLink';
import { FormattedMessage } from 'react-intl';
import AppMode from '@/lib/constants/AppMode.enum';
import { ListItemButtonProps } from '@mui/material';

type ModeProps = ListItemButtonProps & {
  mode: AppMode;
  selected: boolean;
  modeSwitchOpen: boolean;
  onClick: () => void;
};

const Mode = ({ mode, selected, ...props }: ModeProps) => {
  const modeUrl = selected ? null : modeInfo(mode).url;

  return (
    <ModeLink to={modeUrl} selected={selected} {...props}>
      <FormattedMessage id={`mode_switch.modes.${mode}`} />
    </ModeLink>
  );
};

export default Mode;
