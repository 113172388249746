import styled from '@emotion/styled';
import PossiblyDangerousIconButton from '@/components/PossiblyDangerousIconButton';
import React from 'react';
import { IconButtonProps } from '@mui/material';

export type ActionButtonProps = Omit<IconButtonProps, 'children'> & {
  dangerous?: boolean;
  children: React.ReactNode;
};

const ActionButton = styled(PossiblyDangerousIconButton)<ActionButtonProps>({
  marginTop: -6,
  marginBottom: -6,
  padding: 6,
  ':first-of-type': {
    marginLeft: 6,
  },
});

export default ActionButton;
