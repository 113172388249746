import { useContext } from 'react';
import AccessLevelContext from '@/pages/OpenChallengePage/AccessLevelContext';
import AccessLevel from '@/lib/constants/AccessLevel.enum';
import { SetComplexImmerStateAction, Task, TaskSummary } from '@/lib/types';
import dummyFunction from '@/lib/utils/dummyFunction';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

const useTask = (
  taskSummary: TaskSummary,
):
  | [true, null, () => void]
  | [false, Task, SetComplexImmerStateAction<Task>] => {
  const accessLevel = useContext(AccessLevelContext);

  const shouldUsePublicEndpoint = ![
    AccessLevel.MEMBER,
    AccessLevel.COACH,
  ].includes(accessLevel);

  const taskEndpoint = shouldUsePublicEndpoint
    ? `challenge-portfolio/tasks/public/${taskSummary.uuid}`
    : `challenge-portfolio/tasks/${taskSummary.id}`;

  const [loading, task, setTask] =
    usePolledSwrAsComplexState<Task>(taskEndpoint);

  if (loading) {
    return [true, null, dummyFunction];
  }

  return [loading, task, setTask];
};

export default useTask;
