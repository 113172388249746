import { useContext, useState } from 'react';
import defaultColors from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/FieldRenderer/TypeSpecificFieldRenderers/ColorPicker/PickerContent/defaultColors';
import Space from '@/components/Space';
import { Button } from '@mui/material';
import ColorsManagerDialog from './ColorsManagerDialog';
import Dialog from '@/components/Dialog';
import ColorsManager from './ColorsManager';
import { FormattedMessage } from 'react-intl';
import AdditionalConfigStateContext from '../../AdditionalConfigStateContext';

const colorsAreDefaultColors = (colors: string[]): boolean => {
  return JSON.stringify(colors) === JSON.stringify(defaultColors);
};

const ColorsEditor = () => {
  const [additionalConfig, setAdditionalConfig] = useContext(
    AdditionalConfigStateContext,
  );
  const [colorsEditorDialogOpen, setColorsEditorDialogOpen] = useState(false);

  const colors = additionalConfig.colors ?? defaultColors;

  const setColors = (
    computeColorsFunction: (oldColors: string[]) => string[],
  ): void => {
    setAdditionalConfig(additionalConfig => {
      const newColors = computeColorsFunction(colors);

      additionalConfig.colors = colorsAreDefaultColors(newColors)
        ? undefined
        : newColors;
    });
  };

  return (
    <>
      <Space height={12} />
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        onClick={() => setColorsEditorDialogOpen(true)}
        fullWidth
      >
        <FormattedMessage id="editor.model_editor.field_editor.colors_editor.button" />
      </Button>
      <ColorsManagerDialog
        open={colorsEditorDialogOpen}
        onClose={() => setColorsEditorDialogOpen(false)}
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.field_editor.colors_editor.manager.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="editor.model_editor.field_editor.colors_editor.manager.text" />
          </Dialog.ContentText>
          <ColorsManager colors={colors} setColors={setColors} />
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setColorsEditorDialogOpen(false)}
          />
          {!colorsAreDefaultColors(colors) && (
            <Dialog.Actions.Button
              onClick={() => setColors(() => defaultColors)}
            >
              <FormattedMessage id="editor.model_editor.field_editor.colors_editor.manager.actions.reset_to_default_colors" />
            </Dialog.Actions.Button>
          )}
          <Dialog.Actions.DoneButton
            onClick={() => setColorsEditorDialogOpen(false)}
          />
        </Dialog.Actions>
      </ColorsManagerDialog>
    </>
  );
};

export default ColorsEditor;
