import SelectWrapper from './SelectWrapper';
import PrimaryIconButton from '../PrimaryIconButton';
import {
  ChevronLeft as LeftIcon,
  ChevronRight as RightIcon,
} from '@mui/icons-material';

type YearSelectProps = {
  years: number[];
  selectedYear: number;
  setSelectedYear: (year: number) => void;
  disabled: boolean;
};

const YearSelect = ({
  years,
  selectedYear,
  setSelectedYear,
  disabled,
}: YearSelectProps) => {
  const selectedYearIndex = years.indexOf(selectedYear);

  const previousYear = years[selectedYearIndex - 1];
  const nextYear = years[selectedYearIndex + 1];

  const moveToPreviousYear = () => setSelectedYear(previousYear);
  const moveToNextYear = () => setSelectedYear(nextYear);

  return (
    <SelectWrapper>
      <PrimaryIconButton
        onClick={moveToPreviousYear}
        disabled={!previousYear || disabled}
      >
        <LeftIcon />
      </PrimaryIconButton>
      {selectedYear}
      <PrimaryIconButton
        onClick={moveToNextYear}
        disabled={!nextYear || disabled}
      >
        <RightIcon />
      </PrimaryIconButton>
    </SelectWrapper>
  );
};

export default YearSelect;
