import RowAttachmentTypeOption from '../RowAttachmentTypeOption';
import RowTextField from './RowTextField';
import { FormattedMessage } from 'react-intl';
import RowAttachmentType from '../../../RowAttachmentType.enum';

type NewRowOptionProps = {
  value: RowAttachmentType;
  selected: boolean;
  newRowName: string;
  onChangeNewRowName: (newRowName: string) => void;
  hasError: boolean;
  challengeNameHasError: boolean;
};

const NewRowOption = ({
  value,
  selected,
  newRowName,
  onChangeNewRowName,
  hasError,
  challengeNameHasError,
}: NewRowOptionProps) => (
  <RowAttachmentTypeOption value={value} selected={selected}>
    <RowAttachmentTypeOption.LabelText>
      <FormattedMessage id="portfolio.challenge_creation_flow.row_attachment.type_options.new_row.label" />
    </RowAttachmentTypeOption.LabelText>
    <RowTextField
      value={newRowName}
      onChange={e => onChangeNewRowName(e.target.value)}
      disabled={!selected}
      hasError={hasError}
      challengeNameHasError={challengeNameHasError}
    />
  </RowAttachmentTypeOption>
);

export default NewRowOption;
