import { TextField, InputAdornment, TextFieldProps } from '@mui/material';

type ColorTextFieldProps = Omit<
  TextFieldProps,
  'placeholder' | 'variant' | 'InputProps' | 'fullWidth'
>;

const ColorTextField = (props: ColorTextFieldProps) => (
  <TextField
    placeholder="ABABAB"
    variant="standard"
    InputProps={{
      startAdornment: <InputAdornment position="start">#</InputAdornment>,
      style: {
        fontFamily: 'Inter',
      },
    }}
    fullWidth
    {...props}
  />
);

export default ColorTextField;
