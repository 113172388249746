import styled from '@emotion/styled';
import InputWithEscListener, {
  InputWithEscListenerProps,
} from '@/components/InputWithEscListener';

// TODO fix this omitting stuff
type StyledInputProps = Omit<InputWithEscListenerProps, 'onFocus'> & {
  value: string;
  visible: boolean;
};

const StyledInput = styled(InputWithEscListener)<StyledInputProps>(
  ({ theme }) => ({
    height: 48,
    transition: theme.transitions.create('all'),
  }),
  ({ value, visible }) => ({
    width: visible ? (value ? 168 : 204) : 0,
    opacity: visible ? 1 : 0,
  }),
  ({ value, visible }) => {
    if (visible) {
      return {
        marginRight: value ? 0 : -36,
      };
    }
  },
);

export default StyledInput;
