import { useContext } from 'react';
import VisibleWeekRangeContext from '../../VisibleWeekRangeContext';
import { useMediaQuery } from '@mui/material';
import MarkerWrapper from './MarkerWrapper';
import ContentWrapper from './ContentWrapper';
import WeekLabel from './WeekLabel';
import WeekDateRange from './WeekDateRange';
import DayMarkers from './DayMarkers';
import { Moment } from 'moment';

type WeekMarkerProps = {
  weekStartDate: Moment;
};

const WeekMarker = ({ weekStartDate }: WeekMarkerProps) => {
  const { isZoomedInOnWeek, zoomInOnWeek } = useContext(
    VisibleWeekRangeContext,
  );

  const disableZoom = useMediaQuery('(max-width: 700px)');

  return (
    <MarkerWrapper
      hasZoom={isZoomedInOnWeek}
      onClick={() => zoomInOnWeek(weekStartDate)}
      disabled={isZoomedInOnWeek || disableZoom}
    >
      <ContentWrapper className="content-wrapper">
        <WeekLabel weekStartDate={weekStartDate} />
        <WeekDateRange weekStartDate={weekStartDate} />
      </ContentWrapper>
      {isZoomedInOnWeek && <DayMarkers />}
    </MarkerWrapper>
  );
};

export default WeekMarker;
