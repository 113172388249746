import EditField, { EditFieldProps } from './EditField';
import { useIntl } from 'react-intl';

type TitleFieldProps = Omit<EditFieldProps, 'placeholder'>;

const TitleField = (props: TitleFieldProps) => {
  const intl = useIntl();

  return (
    <EditField
      placeholder={intl.formatMessage({
        id: 'portfolio_template_editor.level.edit.title_input_placeholder',
      })}
      {...props}
    />
  );
};

export default TitleField;
