import { useContext, useState } from 'react';
import TreeWrapperScrollContext from '../TreeWrapperScrollContext';
import { PerspectiveSublevelWithChildren } from '@/lib/types';

export type Column = {
  sublevel: PerspectiveSublevelWithChildren;
  parentCardIndex: number;
  previousColumnCardCount: number;
};

const useColumns = (): [
  Column[],
  (
    sublevel: PerspectiveSublevelWithChildren,
    index: number,
    nestingIndex: number,
    siblingCardCount: number,
  ) => void,
  (nestingIndex: number) => void,
] => {
  const scrollWrapperToRight = useContext(TreeWrapperScrollContext);

  const [columns, setColumns] = useState<Column[]>([]);

  const openColumn = (
    sublevel: PerspectiveSublevelWithChildren,
    index: number,
    nestingIndex: number,
    siblingCardCount: number,
  ): void => {
    setColumns(columns =>
      columns.slice(0, nestingIndex + 1).concat({
        sublevel,
        parentCardIndex: index,
        previousColumnCardCount: siblingCardCount,
      }),
    );

    setTimeout(() => {
      scrollWrapperToRight();
    }, 10);
  };

  const closeColumn = (nestingIndex: number): void => {
    setColumns(columns => columns.slice(0, nestingIndex + 1));
  };

  return [columns, openColumn, closeColumn];
};

export default useColumns;
