import useActivePlatformIcons from '@/lib/utils/useActivePlatformIcons';
import { useIntl } from 'react-intl';
import PlatformType from '@/lib/constants/PlatformType.enum';
import styled from '@emotion/styled';

type PlatformIconProps = {
  platformType: PlatformType;
};

const StyledIcon = styled('img')({
  width: 24,
  height: 24,
});

const PlatformIcon = ({ platformType }: PlatformIconProps) => {
  const activePlatformIcons = useActivePlatformIcons();
  const desiredPlatformIcon = activePlatformIcons[platformType];

  const intl = useIntl();

  return (
    <StyledIcon
      src={desiredPlatformIcon}
      alt={intl.formatMessage({
        id: 'header.platform_switch.button.icon.alt',
      })}
    />
  );
};

export default PlatformIcon;
