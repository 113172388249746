import styled from '@emotion/styled';

type RadioLabelProps = {
  selected: boolean;
};

const RadioLabel = styled('div')<RadioLabelProps>(
  ({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    transition: theme.transitions.create('all'),
  }),
  ({ selected }) => {
    if (!selected) {
      return {
        opacity: 0.5,
      };
    }
  },
);

export default RadioLabel;
