import PortfolioUserContext from '@/lib/contexts/PortfolioUserContext';
import { useContext } from 'react';
import { ObjectWithGroups } from '@/lib/types';

// TODO
const useItemIsVisibleToPortfolioUser = () => {
  const portfolioUser = useContext(PortfolioUserContext);
  const allUserGroups =
    portfolioUser === null
      ? []
      : [...portfolioUser.groups, ...portfolioUser.managedGroups];

  const itemIsVisibleToPortfolioUser = (item: ObjectWithGroups): boolean => {
    if (item.groups.length === 0) {
      return true;
    }

    const itemGroups = item.groups.map(group => group.id);

    for (const group of allUserGroups) {
      if (itemGroups.includes(group)) {
        return true;
      }
    }

    return false;
  };

  return itemIsVisibleToPortfolioUser;
};

export default useItemIsVisibleToPortfolioUser;
