/* eslint-disable react-hooks/exhaustive-deps */
import App from '@/lib/App';
import useShouldDisplayAdminControls from './useShouldDisplayAdminControls';
import useIsDisplayingLoginScreen from './useIsDisplayingLoginScreen';
import AppMode from '@/lib/constants/AppMode.enum';
import StyledHeader from './StyledHeader';
import HeaderLogo from './HeaderLogo';
import HeaderPageTitle from './HeaderPageTitle';
import HeaderAdminControls from './HeaderAdminControls';
import UserSwitcher from './UserSwitcher';
import CoachModeButton from './CoachModeButton';
import ModeSwitch from './ModeSwitch';
import HeaderActions from './HeaderActions';
import EnsureChallengePortfolioControlsDesired from './EnsureChallengePortfolioControlsDesired';
import ChallengeSearch from './ChallengeSearch';
import PlanningViewToggleButton from './PlanningViewToggleButton';
import PlatformSwitch from './PlatformSwitch';
import OptionsMenu from './OptionsMenu';
import ResponsivityGreyBar from './ResponsivityGreyBar';
import PerspectiveCategorizationViewButton from './PerspectiveCategorizationViewButton';
import useContentIsWide from '@/lib/utils/useContentIsWide';
import { User } from '@/lib/types';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import { useEffect } from 'react';

type AppHeaderProps = {
  setChallengeSearchQuery: (searchQuery: string) => void;
  selectedUser: User | null;
  setSelectedUser: (user: User | null) => void;
};

const AppHeader = ({
  setChallengeSearchQuery,
  selectedUser,
  setSelectedUser,
}: AppHeaderProps) => {
  const selectedMode = App.navigation.useSelectedMode();
  const shouldDisplayAdminControls = useShouldDisplayAdminControls();
  const contentIsWide = useContentIsWide();

  const authenticatedUser = useAuthenticatedUser();

  useEffect(() => {
    if (authenticatedUser === null && selectedUser !== null) {
      setSelectedUser(null);
    }
  }, [authenticatedUser]);

  const isDisplayingLoginScreen = useIsDisplayingLoginScreen();
  if (isDisplayingLoginScreen) {
    return null;
  }

  const inCoachMode = selectedMode === AppMode.COACH;

  return (
    <StyledHeader inCoachMode={inCoachMode} wideContent={contentIsWide}>
      <HeaderLogo />
      <HeaderPageTitle />
      {shouldDisplayAdminControls && (
        <HeaderAdminControls
          inCoachMode={inCoachMode}
          wideContent={contentIsWide}
        >
          {inCoachMode && (
            <UserSwitcher
              selectedUser={selectedUser}
              onSelectUser={user => setSelectedUser(user)}
            />
          )}
          <CoachModeButton />
          <ModeSwitch />
        </HeaderAdminControls>
      )}
      <HeaderActions>
        <PerspectiveCategorizationViewButton />
        <EnsureChallengePortfolioControlsDesired>
          <ChallengeSearch
            onSearch={searchQuery => setChallengeSearchQuery(searchQuery)}
          />
          <PlanningViewToggleButton />
        </EnsureChallengePortfolioControlsDesired>
        <PlatformSwitch />
        <OptionsMenu />
      </HeaderActions>
      {shouldDisplayAdminControls && (
        <ResponsivityGreyBar inCoachMode={inCoachMode} />
      )}
    </StyledHeader>
  );
};

export default AppHeader;
