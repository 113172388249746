import StyledText from './StyledText';
import DateMessage from './DateMessage';
import LoadingMessage from './LoadingMessage';
import { RubricsPortfolioSnapshot } from '@/lib/types';

type DateTextProps = {
  selectedPortfolioSnapshot: RubricsPortfolioSnapshot | null;
  loading: boolean;
};

const DateText = ({ selectedPortfolioSnapshot, loading }: DateTextProps) => (
  <StyledText>
    {loading ? (
      <LoadingMessage />
    ) : (
      <DateMessage selectedPortfolioSnapshot={selectedPortfolioSnapshot} />
    )}
  </StyledText>
);

export default DateText;
