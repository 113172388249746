import { IconButtonProps } from '@mui/material';
import MobileCoachFeedbackButton from './MobileCoachFeedbackButton';
import { Comment as FeedbackIcon } from '@mui/icons-material';

type OpenFeedbackButtonProps = Omit<IconButtonProps, 'color' | 'children'>;

const OpenFeedbackButton = (props: OpenFeedbackButtonProps) => (
  <MobileCoachFeedbackButton color="primary" {...props}>
    <FeedbackIcon />
  </MobileCoachFeedbackButton>
);

export default OpenFeedbackButton;
