import StyledFeedbackItem from './StyledFeedbackItem';
import FeedbackItemAuthor from './FeedbackItemAuthor';
import FormattedText from '@/components/FormattedText';
import FeedbackItemFooter from './FeedbackItemFooter';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import IsNewIndicator from './IsNewIndicator';
import { CoachComment } from '@/lib/types';

type FeedbackItemProps = {
  feedbackItem: CoachComment;
};

const FeedbackItem = ({ feedbackItem }: FeedbackItemProps) => (
  <StyledFeedbackItem>
    <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
      {!feedbackItem.isRead && <IsNewIndicator />}
    </RestrictedToPortfolioMode>
    {feedbackItem.author !== null && (
      <FeedbackItemAuthor user={feedbackItem.author} />
    )}
    <FormattedText>{feedbackItem.content}</FormattedText>
    <FeedbackItemFooter feedbackItem={feedbackItem} />
  </StyledFeedbackItem>
);

export default FeedbackItem;
