import { RubricsPortfolioSnapshot } from '@/lib/types';
import { FormattedMessage, useIntl } from 'react-intl';

const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
] as const;

type DateMessageProps = {
  selectedPortfolioSnapshot: RubricsPortfolioSnapshot | null;
};

const DateMessage = ({ selectedPortfolioSnapshot }: DateMessageProps) => {
  const intl = useIntl();

  if (selectedPortfolioSnapshot === null) {
    return <FormattedMessage id="portfolio.gapminder.now" />;
  }

  const createdAt = new Date(selectedPortfolioSnapshot.createdAt);
  const month = intl.formatMessage({
    id: `months.${months[createdAt.getMonth()]}`,
  });

  return (
    <FormattedMessage
      id="portfolio.gapminder.date"
      values={{ date: createdAt.getDate(), month }}
    />
  );
};

export default DateMessage;
