import HeaderContentWrapper from './HeaderContentWrapper';
import BackButton from './BackButton';
import Space from '@/components/Space';
import { FormattedMessage } from 'react-intl';
import SubmenuType from '../SubmenuType.enum';
import React from 'react';

type SubmenuHeaderProps = {
  selectedSubmenu: SubmenuType | null;
  onGoBack: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const SubmenuHeader = ({ selectedSubmenu, onGoBack }: SubmenuHeaderProps) => {
  if (selectedSubmenu === null) {
    return null;
  }

  return (
    <HeaderContentWrapper>
      <BackButton onClick={onGoBack} />
      <Space width={6} />
      <h3>
        <FormattedMessage
          id={`header.options_menu.options.${selectedSubmenu.toLowerCase()}`}
        />
      </h3>
    </HeaderContentWrapper>
  );
};

export default SubmenuHeader;
