import styled from '@emotion/styled';
import PhaseContentGroupContext from './PhaseContentGroupContext';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import React from 'react';
import { HTMLDivProps } from '@/lib/types';

type PhaseBottomContentProps = HTMLDivProps & {
  children: React.ReactNode;
};

const StyledBottomContent = styled('footer')({
  marginTop: 24,
});

const PhaseBottomContent = (props: PhaseBottomContentProps) => (
  <PhaseContentGroupContext.Provider value={ContentGroupType.BOTTOM}>
    <StyledBottomContent {...props} />
  </PhaseContentGroupContext.Provider>
);

export default PhaseBottomContent;
