import styled from '@emotion/styled';

type PerspectiveCardTitle = {
  children: string;
};

type StyledTitleProps = {
  fontSize: number;
};

const StyledTitle = styled('h4')<StyledTitleProps>(({ fontSize }) => ({
  fontSize,
}));

const computeTitleFontSize = (titleLength: number): number => {
  if (titleLength <= 50) {
    return 16;
  }

  return Math.max(12, 16 - (titleLength - 50) / 20);
};

const SublevelCardTitle = ({ children }: PerspectiveCardTitle) => {
  const titleFontSize = computeTitleFontSize(children.length);

  return <StyledTitle fontSize={titleFontSize}>{children}</StyledTitle>;
};

export default SublevelCardTitle;
