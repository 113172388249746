import Group from '../constants/Group.enum';
import { safeKeys } from '@/lib/utils/safeObjectFunctions';
import { ObjectWithGroups } from '../types';

// TODO fix this cuz it's broken
// TODO this code looks quite legacy
const itemMatchesVisibleGroup =
  (visibleGroup: Group, isTopic: boolean = false) =>
  ({ groups }: ObjectWithGroups): boolean => {
    const itemGroups = safeKeys<number>(groups);

    switch (visibleGroup) {
      case Group.STUDENTS:
      case Group.COACHES: {
        return itemGroups.includes(visibleGroup as unknown as number);
      }
      case Group.DRAFTS: {
        if (isTopic) {
          return (
            itemGroups.length === 0 ||
            (itemGroups.length === 1 &&
              itemGroups[0] === (Group.EDITORS as unknown as number))
          );
        }

        return true;
      }
      case Group.ALL: {
        return true;
      }
      default: {
        return false;
      }
    }
  };

export default itemMatchesVisibleGroup;
