import { keyframes } from '@emotion/react';

const bounceAnimation = keyframes({
  '50%': {
    transform: 'scale(1.2)',
  },
  '100%': {
    transform: 'scale(1)',
  },
});

export default bounceAnimation;
