import ApiError, { UNKNOWN_ERROR_ERROR_CODE } from './ApiError';

const UNKNOWN_AUTHENTICATION_ERROR_ERROR_CODE = 'auth/unauthorized';

export default class ApiAuthenticationError extends ApiError {
  getErrorCode(): string {
    const errorCode = super.getErrorCode();

    if (errorCode === UNKNOWN_ERROR_ERROR_CODE) {
      return UNKNOWN_AUTHENTICATION_ERROR_ERROR_CODE;
    }

    return errorCode;
  }
}
