import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import moment from 'moment';
import firstChildSelector from '@/lib/styling/firstChildSelector';

type LogEntryCreatedAtProps = {
  createdAt: string;
};

const StyledDate = styled('span')(({ theme }) => ({
  marginBottom: 0,
  marginLeft: 12,
  color: theme.palette.text.secondary,
  fontWeight: 400,
  fontSize: 15,
  ':first-letter': {
    textTransform: 'uppercase',
  },
  [firstChildSelector]: {
    marginLeft: 0,
  },
  [mediaQuery(1024)]: {
    marginLeft: 0,
    fontSize: 14,
  },
}));

const LogEntryCreatedAt = ({ createdAt }: LogEntryCreatedAtProps) => (
  <StyledDate>{moment(createdAt).calendar({ lastWeek: 'L' })}</StyledDate>
);

export default LogEntryCreatedAt;
