import styled from '@emotion/styled';

const OptionWrapper = styled('section')({
  marginTop: -8,
  marginBottom: -8,
  display: 'flex',
  alignItems: 'center',
});

export default OptionWrapper;
