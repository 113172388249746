import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import { HTMLDivProps } from '@/lib/types';

export type IconBaseProps = HTMLDivProps & {
  size: number;
};

const DivWithClassName = ({ className, ...props }: HTMLDivProps) => (
  <div className={`perspective-card__icon ${className}`} {...props} />
);

const IconBase = styled(DivWithClassName)<IconBaseProps>(
  ({ theme, size }) => ({
    width: size,
    height: size,
    boxSizing: 'border-box',
    backgroundColor: theme.isDark ? theme.palette.background.paper : grey[200],
    border: `4px solid ${
      theme.isDark ? theme.palette.background.paper : grey[200]
    }`,
    borderRadius: '50%',
  }),
  addHighContrastStyles(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
  })),
);

export default IconBase;
