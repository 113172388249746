import { useContext } from 'react';
import SelectedPlatformContext from '@/lib/contexts/SelectedPlatformContext';
import useSelectedMode from './useSelectedMode';
import PlatformType from '@/lib/constants/PlatformType.enum';
import AppMode from '@/lib/constants/AppMode.enum';

const useIsDisplayingChallengePortfolio = (): boolean => {
  const [selectedPlatform] = useContext(SelectedPlatformContext);
  const selectedMode = useSelectedMode();

  if (selectedMode === null) {
    return false;
  }

  return (
    selectedPlatform === PlatformType.CHALLENGEMONITOR &&
    [AppMode.PORTFOLIO, AppMode.COACH].includes(selectedMode)
  );
};

export default useIsDisplayingChallengePortfolio;
