import styled from '@emotion/styled';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

const StyledTitle = styled('h3')({
  marginTop: 6,
  marginBottom: 8,
});

const SectionTitle = () => (
  <StyledTitle>
    <FormattedMessageWithChallengeNaming id="portfolio.challenge_creation_flow.row_attachment.title" />
  </StyledTitle>
);

export default SectionTitle;
