import { useContext } from 'react';
import PortfolioItemFields from '../../PortfolioItemFields';
import ChallengeContext from '../../Challenge/ChallengeView/ChallengeContext';
import TaskContext from './TaskContext';
import ModelType from '@/lib/constants/challengeModel/ModelType.enum';
import { TaskSummary } from '@/lib/types';
import { DraftFunction } from 'use-immer';

const TaskFields = () => {
  const [, setChallenge] = useContext(ChallengeContext);
  const [task, setTask] = useContext(TaskContext);

  const setTaskSummary = (
    stateUpdateFunction: DraftFunction<TaskSummary>,
  ): void => {
    setChallenge(challenge => {
      stateUpdateFunction(challenge.tasks[task.id]);
    });
  };

  const taskApiEndpoint = `challenge-portfolio/tasks/${task.id}`;

  return (
    <PortfolioItemFields
      modelType={ModelType.TASK}
      portfolioItem={task}
      setPortfolioItem={setTask}
      setPortfolioItemSummary={setTaskSummary}
      portfolioItemApiEndpoint={taskApiEndpoint}
    />
  );
};

export default TaskFields;
