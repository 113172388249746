import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import { HTMLDivProps } from '@/lib/types';

type RowWrapperProps = HTMLDivProps & {
  hidden: boolean;
};

const RowWrapper = styled('section')<RowWrapperProps>(({ theme, hidden }) => ({
  height: hidden ? 0 : undefined,
  borderBottom: hidden
    ? 'none'
    : `2px solid ${theme.palette.background.border}`,
  transition: theme.transitions.create(['border-bottom']),
  opacity: hidden ? 0 : undefined,
  display: 'flex',
  [mediaQuery(700)]: {
    flexDirection: 'column',
    overflow: 'hidden',
  },
}));

export default RowWrapper;
