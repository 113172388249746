import { IconButton, IconButtonProps } from '@mui/material';
import { Close as CancelIcon } from '@mui/icons-material';

type CancelDeletionButtonProps = Omit<IconButtonProps, 'edge' | 'children'>;

const CancelDeletionButton = (props: CancelDeletionButtonProps) => (
  <IconButton edge="end" {...props}>
    <CancelIcon />
  </IconButton>
);

export default CancelDeletionButton;
