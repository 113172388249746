import StyledNewlySharedInfo from './StyledNewlySharedInfo';
import MobileRowPicker from './MobileRowPicker';
import device from 'current-device';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

const NewlySharedInfo = () => (
  <>
    <StyledNewlySharedInfo>
      <FormattedMessageWithChallengeNaming
        id={
          device.desktop()
            ? 'portfolio.challenge.newly_shared_info'
            : 'portfolio.challenge.newly_shared_info.mobile'
        }
      />
    </StyledNewlySharedInfo>
    <MobileRowPicker />
  </>
);

export default NewlySharedInfo;
