import { StyledChallengeNameInput } from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/ChallengeNameEditor/ChallengeNameInput';
import { InputWithEscListenerProps } from '@/components/InputWithEscListener';
import { useIntl } from 'react-intl';

type TaskNameInputProps = Omit<
  InputWithEscListenerProps,
  'placeholder' | 'ref'
>;

const TaskNameInput = (props: TaskNameInputProps) => {
  const intl = useIntl();

  return (
    <StyledChallengeNameInput
      placeholder={intl.formatMessage({
        id: 'portfolio.task.name.edit.input_placeholder',
      })}
      {...props}
    />
  );
};

export default TaskNameInput;
