import React, { ForwardedRef, forwardRef, useContext } from 'react';
import StyledPhaseColumn from './StyledPhaseColumn';
import PhaseContext from '../../../PhaseContext';
import VisiblePhaseContext from '@/components/ChallengePortfolio/VisiblePhaseContext';

// TODO review optionality of props
type PhaseColumnProps = {
  hasItems: boolean;
  inTaskBoard?: boolean;
  collapsed?: boolean;
  children: React.ReactNode;
};

// TODO work out degree of re-using between these challenge/task ish
// components; maybe also move to some `shared` folder
const PhaseColumn = forwardRef(
  (
    { hasItems, inTaskBoard, collapsed, children }: PhaseColumnProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const phase = useContext(PhaseContext);
    const { visibleChallengePhase, visibleTaskPhase } = useContext(
      VisiblePhaseContext,
    );
    const visiblePhase = inTaskBoard ? visibleTaskPhase : visibleChallengePhase;

    return (
      <StyledPhaseColumn
        hasItems={hasItems}
        inTaskBoard={inTaskBoard ?? false}
        collapsed={collapsed ?? false}
        invisible={phase !== visiblePhase}
        ref={ref}
      >
        {children}
      </StyledPhaseColumn>
    );
  },
);

PhaseColumn.displayName = 'PhaseColumn';

export default PhaseColumn;
