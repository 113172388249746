import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

type FocusIndicatorProps = {
  hasFocus: boolean;
};

const FocusIndicator = styled('div')<FocusIndicatorProps>(
  ({ theme }) => ({
    marginRight: 12,
    display: 'inline-flex',
    alignItems: 'center',
    transition: theme.transitions.create('all'),
    [mediaQuery(768)]: {
      marginRight: 0,
    },
  }),
  ({ theme, hasFocus }) => {
    if (hasFocus) {
      return {
        marginLeft: -6,
        padding: 6,
        backgroundColor: theme.palette.partFocusColor,
        borderRadius: 4,
      };
    }
  },
);

export default FocusIndicator;
