import React, { useContext, useState } from 'react';
import FieldContext from '../../../FieldContext';
import Dialog from '@/components/Dialog';
import Prompt from '@/components/Prompt';
import AddFeedbackButton from './AddFeedbackButton';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import FieldRendererContext from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/FieldRendererContext';
import { CoachComment } from '@/lib/types';

type AddFeedbackProps = {
  ButtonComponent?: React.ElementType;
  belowFeedbackList?: boolean;
};

const AddFeedback = ({
  ButtonComponent: ButtonComponentOrUndefined,
  belowFeedbackList,
}: AddFeedbackProps) => {
  const api = useApi();

  const {
    setPortfolioItem,
    setPortfolioItemSummary,
    portfolioItemApiEndpoint,
  } = useContext(FieldRendererContext);
  const field = useContext(FieldContext);

  const [addPromptOpen, setAddPromptOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveNewFeedback = async (content: string): Promise<void> => {
    setAddPromptOpen(false);

    setSaving(true);

    const feedbackItem = await api.post<CoachComment>(
      `${portfolioItemApiEndpoint}/coach-comments?fieldId=${field.id}`,
      {
        content,
      },
    );

    setPortfolioItem(portfolioItem => {
      portfolioItem.coachComments[feedbackItem.id] = feedbackItem;
    });

    setPortfolioItemSummary(portfolioItemSummary => {
      portfolioItemSummary._count.coachComments++;
    });

    setSaving(false);
  };

  const ButtonComponent = ButtonComponentOrUndefined ?? AddFeedbackButton;

  return (
    <>
      <ButtonComponent
        onClick={() => setAddPromptOpen(true)}
        disabled={saving}
        belowFeedbackList={belowFeedbackList}
      />
      <Prompt
        open={addPromptOpen}
        onCancel={() => setAddPromptOpen(false)}
        onOk={saveNewFeedback}
        emptyError="portfolio.challenge.coach_feedback.feedback_empty_error"
        multiline
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.challenge.coach_feedback.add.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio.challenge.coach_feedback.add.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Prompt.TextField
          label={
            <FormattedMessage id="portfolio.challenge.coach_feedback.add.input_label" />
          }
        />
      </Prompt>
    </>
  );
};

export default AddFeedback;
