import SelectFieldPlaceholder from '@/components/SelectFieldPlaceholder';
import UserRole from '@/lib/constants/UserRole.enum';
import { SelectProps } from '@/lib/types';
import { Select } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const RoleSelectWithPlaceholder = (props: SelectProps<UserRole>) => {
  const renderPlaceholderOrRole = (value: UserRole | ''): React.ReactNode => {
    if (!value) {
      return (
        <SelectFieldPlaceholder>
          <FormattedMessage id="user_manager.user_list.toolbar.role_select.label" />
        </SelectFieldPlaceholder>
      );
    }

    return <FormattedMessage id={`user_roles.${value.toLowerCase()}`} />;
  };

  return (
    <Select
      variant="standard"
      renderValue={renderPlaceholderOrRole}
      style={{ width: 132 }}
      displayEmpty
      {...props}
    />
  );
};

export default RoleSelectWithPlaceholder;
