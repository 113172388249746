import styled from '@emotion/styled';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

const TaskCount = styled('span')(
  ({ theme }) => ({
    marginLeft: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    opacity: 0.7,
  }),
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.text.primary,
    opacity: 1,
    '::before': {
      content: '"("',
    },
    '::after': {
      content: '")"',
    },
  })),
);

export default TaskCount;
