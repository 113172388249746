import { useContext } from 'react';
import PhaseContext from '../../PhaseContext';
import GroupsManager from '../../GroupsManager';
import ModelTypeContext from '../../../ModelTypeContext';
import ModelsContext from '../../../../ModelsContext';
import useApi from '@/lib/api/useApi';
import PortfolioItemWithGroupsPromptVariant from '@/pages/EditorPage/RubricsPortfolioTemplatesEditor/PortfolioTemplatesEditor/PortfolioItemWithGroupsPrompt/PortfolioItemWithGroupsPromptVariant.enum';
import { EditorSectionWithoutRelations } from '@/lib/types';

const PhaseGroups = () => {
  const api = useApi();

  const modelType = useContext(ModelTypeContext);
  const [, setModels] = useContext(ModelsContext);
  const { phase } = useContext(PhaseContext);

  const saveGroups = async (groups: number[]): Promise<void> => {
    const updatedPhase = await api.put<EditorSectionWithoutRelations>(
      `editor/challenge-model/sections/${phase.id}/groups`,
      {
        groups,
      },
    );

    setModels(
      models => {
        models[modelType].sections[phase.id] = {
          ...models[modelType].sections[phase.id],
          ...updatedPhase,
          groups: groups.map(id => ({ id })),
        }
      }
    )
  };

  return (
    <GroupsManager
      variant={PortfolioItemWithGroupsPromptVariant.PHASE}
      selectedGroups={phase.groups.map(({ id }) => id)}
      onSave={saveGroups}
    />
  );
};

export default PhaseGroups;
