/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import ApiError from '@/lib/api/ApiError';
import * as Sentry from '@sentry/react';

const extractErrorCode = (error: unknown): string => {
  if (error instanceof ApiError) {
    const errorCode = error.getErrorCode();

    return errorCode ?? 'auth/api-issue';
  }

  return 'auth/unknown';
};

const useProcessError = (error: unknown): string => {
  const errorCode = extractErrorCode(error);

  useEffect(() => {
    if (error !== null && errorCode !== 'auth/email-disallowed') {
      Sentry.captureException(error);
    }
  }, [error]);

  return errorCode;
};

export default useProcessError;
