import Prompt, { PromptProps } from '@/components/Prompt';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import { MinimumRole } from '@/lib/types';

type PartPromptWithoutGroupsProps = Omit<
  PromptProps,
  'emptyError' | 'OkButton' | 'children'
> & {
  selectedMinimumRole: MinimumRole;
};

const PartPromptWithoutGroups = ({
  selectedMinimumRole,
  ...props
}: PartPromptWithoutGroupsProps) => (
  <Prompt
    emptyError="prompt.errors.name_empty"
    OkButton={Dialog.Actions.CreateButton}
    {...props}
  >
    <Dialog.Title>
      <FormattedMessage id="portfolio_template_editor.add_part.title" />
    </Dialog.Title>
    <Dialog.Content>
      <Dialog.ContentText>
        <FormattedMessage id="portfolio_template_editor.add_part.text_no_groups" />
      </Dialog.ContentText>
    </Dialog.Content>
    <Prompt.TextField
      label={
        <FormattedMessage id="portfolio_template_editor.add_part.input_label" />
      }
    />
  </Prompt>
);

export default PartPromptWithoutGroups;
