import DialogContentWrapper from '../../DialogContentWrapper';
import Dialog from '@/components/Dialog';
import EmailButton from './EmailButton';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';

const StyledDialogContent = styled(Dialog.Content)({
  overflowX: 'hidden',
});

const ContactInfo = () => (
  <DialogContentWrapper>
    <Dialog.Title>
      <FormattedMessage id="support.dialog.contact_info.title" />
    </Dialog.Title>
    <StyledDialogContent>
      <EmailButton href="mailto:contact@egodact.com">
        contact@egodact.com
      </EmailButton>
    </StyledDialogContent>
  </DialogContentWrapper>
);

export default ContactInfo;
