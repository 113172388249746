import useApi from '@/lib/api/useApi';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import PortfolioUserContext from '@/lib/contexts/PortfolioUserContext';
import { useContext } from 'react';
import useProgressApiEndpoint from './useProgressApiEndpoint';

const useSaveProgress = (
  partId: number,
): ((progress: number) => Promise<void>) => {
  const api = useApi();
  const progressApiEndpoint = useProgressApiEndpoint();

  const portfolioMode = useContext(PortfolioModeContext);
  const portfolioUser = useContext(PortfolioUserContext);

  const saveStudentProgress = async (progress: number): Promise<void> => {
    await api.post(`${progressApiEndpoint}/${partId}`, {
      progress,
    });
  };

  const saveCoachProgress = async (progress: number): Promise<void> => {
    await api.post(
      `${progressApiEndpoint}/${partId}/coach?userEmail=${
        portfolioUser!.email
      }`,
      {
        progress,
      },
    );
  };

  const saveProgress = async (progress: number): Promise<void> => {
    if (portfolioMode === PortfolioMode.COACH) {
      await saveCoachProgress(progress);

      return;
    }

    await saveStudentProgress(progress);
  };

  return saveProgress;
};

export default useSaveProgress;
