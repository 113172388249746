import styled from '@emotion/styled';
import DangerousButton from '@/components/DangerousButton';
import DangerousIconButton from '@/components/DangerousIconButton';
import {
  ButtonProps,
  IconButtonProps,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { ExitToApp as LeaveIcon } from '@mui/icons-material';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

type LeaveButtonProps = Omit<
  IconButtonProps | ButtonProps,
  'color' | 'children'
>;

const StyledLeaveButton = styled(DangerousButton)({
  marginLeft: 6,
  height: 36,
});

const LeaveButton = (props: LeaveButtonProps) => {
  const shouldUseIconButton = useMediaQuery('(max-width: 1024px)');

  if (shouldUseIconButton) {
    return (
      <Tooltip
        title={
          <FormattedMessageWithChallengeNaming id="portfolio.challenge.sharing.leave.button" />
        }
      >
        <DangerousIconButton {...props}>
          <LeaveIcon />
        </DangerousIconButton>
      </Tooltip>
    );
  }

  return (
    <StyledLeaveButton startIcon={<LeaveIcon />} {...props}>
      <FormattedMessageWithChallengeNaming id="portfolio.challenge.sharing.leave.button" />
    </StyledLeaveButton>
  );
};

export default LeaveButton;
