import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';

const TasksIconButton = styled(IconButton)({
  [mediaQuery(640)]: {
    marginRight: -12,
  },
});

export default TasksIconButton;
