import CollapsibleActionMenu from '@/components/CollapsibleActionMenu';
import EditFieldNameButton from './EditFieldNameButton';
import ClearFieldName from './ClearFieldName';
import FieldGroups from './FieldGroups';
import EditFieldInfo from './EditFieldInfo';
import DeleteField from './DeleteField';
import React from 'react';

type FieldActionsProps = {
  onNameEdit: (event: React.MouseEvent<HTMLLIElement>) => void;
};

const FieldActions = ({ onNameEdit }: FieldActionsProps) => (
  <CollapsibleActionMenu>
    <EditFieldNameButton onClick={onNameEdit} />
    <ClearFieldName />
    <FieldGroups />
    <EditFieldInfo />
    <DeleteField />
  </CollapsibleActionMenu>
);

export default FieldActions;
