import useSelectableGroups from './useSelectableGroups';
import StyledGroupSelectorGroupList from './StyledGroupSelectorGroupList';
import ClearSelectedGroupButton from './ClearSelectedGroupButton';
import GroupSelectorGroup from './GroupSelectorGroup';
import { Group } from '@/lib/types';

type GroupSelectorGroupListProps = {
  groups: Record<number, Group>;
  selectedGroupId: number | null;
  onGroupChange: (groupId: number | null) => void;
  open: boolean;
};

const GroupSelectorGroupList = ({
  groups,
  selectedGroupId,
  onGroupChange,
  open,
}: GroupSelectorGroupListProps) => {
  const selectableGroups = useSelectableGroups(groups);

  return (
    <StyledGroupSelectorGroupList open={open}>
      {selectedGroupId !== null && (
        <ClearSelectedGroupButton onClick={() => onGroupChange(null)} />
      )}
      {selectableGroups.map(group => (
        <li key={group.id}>
          <GroupSelectorGroup
            selected={selectedGroupId === group.id}
            onClick={() => onGroupChange(group.id)}
          >
            {group.name}
          </GroupSelectorGroup>
        </li>
      ))}
    </StyledGroupSelectorGroupList>
  );
};

export default GroupSelectorGroupList;
