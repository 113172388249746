import { createContext } from 'react';

// TODO are all fields on this context even used?
type PerspectiveAttachmentState = {
  attachmentActive: boolean;
  attachedPerspectives: Record<number, boolean>;
  attachedSublevels: Record<number, boolean>;
  attachPerspective: (id: number) => void;
  detachPerspective: (id: number) => void;
  attachSublevel: (id: number) => void;
  detachSublevel: (id: number) => void;
  anySublevelAttached: (sublevelIds: number[]) => boolean;
};

const PerspectiveAttachmentContext = createContext<PerspectiveAttachmentState>({
  attachmentActive: false,
  attachedPerspectives: {},
  attachedSublevels: {},
  attachPerspective: (_: number) => {},
  detachPerspective: (_: number) => {},
  attachSublevel: (_: number) => {},
  detachSublevel: (_: number) => {},
  anySublevelAttached: (_: number[]) => false,
});

export default PerspectiveAttachmentContext;
