import { useContext, useState } from 'react';
import FormWrapper from './FormWrapper';
import ChallengeNameInput from './ChallengeNameInput';
import HideOnLowWidth from '@/components/HideOnLowWidth';
import CancelButton from '@/components/CancelButton';
import { IconButton } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import ChallengeContext from '../../ChallengeContext';
import useApi from '@/lib/api/useApi';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import { ChallengeWithoutRelations } from '@/lib/types';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

type ChallengeNameEditorProps = {
  onClose: () => void;
};

const ChallengeNameEditor = ({ onClose }: ChallengeNameEditorProps) => {
  const api = useApi();

  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const [challenge, setChallenge] = useContext(ChallengeContext);

  const [
    newChallengeName,
    setNewChallengeName,
    ,
    reenableNewChallengeNameSync,
  ] = useComplexSwrState(challenge.name);
  const [saving, setSaving] = useState(false);

  const saveNameChange = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (newChallengeName.trim().length === 0) {
      return;
    }

    setSaving(true);

    const updatedChallenge = await api.put<ChallengeWithoutRelations>(
      `challenge-portfolio/challenges/${challenge.id}/name`,
      {
        name: newChallengeName.trim(),
      },
    );

    setChallenge(challenge => {
      challenge.name = updatedChallenge.name;
    });

    setChallengePortfolio(challengePortfolio => {
      challengePortfolio.challenges[challenge.id].name = updatedChallenge.name;
    });

    setSaving(false);

    onClose();

    reenableNewChallengeNameSync();
  };

  const cancelNameChange = () => {
    onClose();

    setNewChallengeName(challenge.name);
    reenableNewChallengeNameSync();
  };

  return (
    <FormWrapper onSubmit={saveNameChange}>
      <ChallengeNameInput
        value={newChallengeName}
        onChange={e => setNewChallengeName(e.target.value)}
        onEscPress={cancelNameChange}
        autoFocus
      />
      <HideOnLowWidth width={700}>
        <CancelButton
          visible={newChallengeName !== challenge.name}
          onClick={cancelNameChange}
        />
      </HideOnLowWidth>
      <IconButton
        type="submit"
        disabled={newChallengeName.trim().length === 0 || saving}
      >
        <CheckIcon />
      </IconButton>
    </FormWrapper>
  );
};

export default ChallengeNameEditor;
