import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

type PickerBoxArrowProps = {
  inRightPhaseContentGroup: boolean;
};

const belowButtonStyles = {
  top: 0,
  right: '50%',
  transform: 'translateX(50%)',
};

const PickerBoxArrow = styled('div')<PickerBoxArrowProps>(
  ({ theme }) => ({
    position: 'absolute',
    backgroundColor: theme.palette.increaseContrastWithBackground(
      theme.palette.background.paper,
      0.1,
    ),
    ...belowButtonStyles,
    '&::after, &::before': {
      content: '" "',
      position: 'absolute',
      left: '50%',
      bottom: '100%',
      width: 0,
      height: 0,
      border: 'solid transparent',
      pointerEvents: 'none',
    },
    '&::before': {
      marginLeft: -9,
      borderWidth: 9,
      borderBottomColor: theme.palette.increaseContrastWithBackground(
        theme.palette.background.paper,
        0.1,
      ),
    },
    '&::after': {
      marginLeft: -8,
      borderWidth: 8,
      borderBottomColor: theme.palette.increaseContrastWithBackground(
        theme.palette.background.paper,
        0.1,
      ),
    },
  }),
  ({ inRightPhaseContentGroup }) => {
    if (inRightPhaseContentGroup) {
      return {
        top: 18,
        right: 0,
        transform: 'rotate(90deg)',
        [mediaQuery(640)]: belowButtonStyles,
      };
    }
  },
  addHighContrastStyles({
    display: 'none',
  }),
);

export default PickerBoxArrow;
