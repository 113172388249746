import ActionButton, { ActionButtonProps } from './ActionButton';
import { Delete as DeleteIcon } from '@mui/icons-material';

type DeleteButtonProps = Omit<ActionButtonProps, 'dangerous' | 'children'>;

const DeleteButton = (props: DeleteButtonProps) => (
  <ActionButton dangerous {...props}>
    <DeleteIcon />
  </ActionButton>
);

export default DeleteButton;
