import AttachmentButton from '../PerspectiveCard/PerspectiveTaskAttachment/AttachmentButton';
import AttachedTasksDialog from '../PerspectiveCard/PerspectiveTaskAttachment/AttachedTasksDialog';
import AttachedTasksView from '../AttachedTasksView';
import Dialog from '@/components/Dialog';
import { useEffect, useState } from 'react';
import { AttachmentInfo, Perspective, PerspectiveSublevel } from '@/lib/types';

type PerspectiveSublevelTaskAttachmentProps = {
  attachmentInfo: AttachmentInfo;
  sublevel: PerspectiveSublevel;
  perspective: Perspective;
};

const PerspectiveSublevelTaskAttachment = ({
  attachmentInfo,
  sublevel,
  perspective,
}: PerspectiveSublevelTaskAttachmentProps) => {
  const [attachedTasksViewOpen, setAttachedTasksViewOpen] = useState(false);

  useEffect(() => {
    if (attachmentInfo.attachedTasksCount === 0) {
      setAttachedTasksViewOpen(false);
    }
  }, [attachmentInfo.attachedTasksCount]);

  return (
    <>
      <AttachmentButton
        attachedTasksCount={attachmentInfo.attachedTasksCount}
        onClick={() => setAttachedTasksViewOpen(true)}
        disabled={attachmentInfo.attachedTasksCount === 0}
      />
      <AttachedTasksDialog
        perspectiveColor={perspective.color}
        open={attachedTasksViewOpen}
        onClose={() => setAttachedTasksViewOpen(false)}
      >
        <AttachedTasksView
          attachmentInfo={attachmentInfo}
          perspective={perspective}
          sublevel={sublevel}
        />
        <Dialog.Actions>
          <Dialog.Actions.DoneButton
            onClick={() => setAttachedTasksViewOpen(false)}
          />
        </Dialog.Actions>
      </AttachedTasksDialog>
    </>
  );
};

export default PerspectiveSublevelTaskAttachment;
