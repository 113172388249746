import styled from '@emotion/styled';

type StepsWrapperProps = {
  hasMovedToSecondStep: boolean;
};

const StepsWrapper = styled('section')<StepsWrapperProps>(
  ({ theme, hasMovedToSecondStep }) => ({
    position: 'relative',
    left: hasMovedToSecondStep ? '-100%' : 0,
    width: '100%',
    display: 'flex',
    transition: theme.transitions.create('all'),
  }),
);

export default StepsWrapper;
