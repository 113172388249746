import SettingsFieldTitle from '../../SettingsFieldTitle';
import AllowedEmailForm from './AllowedEmailForm';
import AddAllowedEmail from './AddAllowedEmail';
import Space from '@/components/Space';
import { FormattedMessage } from 'react-intl';
import { AllowedEmailWithId } from '../useAllowedEmailsState';
import { AllowedEmail } from '@/lib/types';

type AllowedEmailManagerProps = {
  allowedEmails: AllowedEmailWithId[];
  addAllowedEmail: (allowedEmail: AllowedEmail) => void;
  editAllowedEmail: (id: number, allowedEmail: AllowedEmail) => void;
  removeAllowedEmail: (id: number) => void;
};

const AllowedEmailManager = ({
  allowedEmails,
  addAllowedEmail,
  editAllowedEmail,
  removeAllowedEmail,
}: AllowedEmailManagerProps) => (
  <>
    <SettingsFieldTitle>
      <FormattedMessage id="platform_settings.login_settings.manage_allowed_emails" />
    </SettingsFieldTitle>
    {allowedEmails.map(({ id, allowedEmail }) => (
      <AllowedEmailForm
        allowedEmail={allowedEmail}
        onEdit={newAllowedEmail => editAllowedEmail(id, newAllowedEmail)}
        onRemove={() => removeAllowedEmail(id)}
        key={id}
      />
    ))}
    <AddAllowedEmail allowedEmails={allowedEmails} onAdd={addAllowedEmail} />
    <Space height={24} />
  </>
);

export default AllowedEmailManager;
