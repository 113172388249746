import { useState } from 'react';

const useRowsVisibilityState = (): [
  boolean,
  (rowId: number, visible: boolean) => void,
] => {
  const [rowsVisibilityState, setRowsVisibilityState] = useState<
    Record<number, boolean>
  >({});

  const registerRowVisibility = (rowId: number, visible: boolean): void => {
    setRowsVisibilityState(rowsVisibilityState => ({
      ...rowsVisibilityState,
      [rowId]: visible,
    }));
  };

  const noRowsVisible = !Object.values(rowsVisibilityState).includes(true);

  return [noRowsVisible, registerRowVisibility];
};

export default useRowsVisibilityState;
