import ChallengeCreationError from './ChallengeCreationError.enum';
import RowAttachmentType from './RowAttachmentType.enum';

const getErrorsFromState = (
  challengeName: string,
  selectedRowAttachmentType: RowAttachmentType,
  selectedExistingRow: number | null,
  newRowName: string,
): ChallengeCreationError[] => {
  const errors = [] as ChallengeCreationError[];

  if (challengeName.trim().length === 0) {
    errors.push(ChallengeCreationError.CHALLENGE_NAME_EMPTY);
  }

  if (
    selectedRowAttachmentType === RowAttachmentType.EXISTING_ROW &&
    selectedExistingRow === null
  ) {
    errors.push(ChallengeCreationError.NO_ROW_SELECTED);
  }

  if (
    selectedRowAttachmentType === RowAttachmentType.NEW_ROW &&
    newRowName.trim().length === 0
  ) {
    errors.push(ChallengeCreationError.ROW_NAME_EMPTY);
  }

  return errors;
};

export default getErrorsFromState;
