import DialogWithEnterClose from './DialogWithEnterClose';
import ResponsiveDialogPaper from './ResponsiveDialogPaper';
import { DialogProps } from '@mui/material';
import dummyFunction from '@/lib/utils/dummyFunction';

type ResponsiveDialogProps = DialogProps & {
  open: boolean;
  onEnterClose?: (event: KeyboardEvent) => void;
  disableEnterClose?: boolean;
  dangerous?: boolean;
};

const ResponsiveDialog = ({
  onEnterClose,
  disableEnterClose,
  ...props
}: ResponsiveDialogProps) => (
  <DialogWithEnterClose
    PaperComponent={ResponsiveDialogPaper}
    onEnterClose={onEnterClose ?? dummyFunction}
    disableEnterClose={disableEnterClose ?? false}
    fullWidth
    {...props}
  />
);

export default ResponsiveDialog;
