import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import IconBase, { IconBaseProps } from './IconBase';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

const StyledCircularProgress = styled(CircularProgress)(
  ({ theme }) => ({
    padding: 2,
    color: theme.palette.action.selected,
    // We need !important to override Material-UI styles
    width: `100% !important`,
    height: `100% !important`,
    boxSizing: 'border-box',
  }),
  addHighContrastStyles(({ theme }) => ({
    padding: 5,
    color: theme.palette.text.primary,
    opacity: 0.5,
  })),
);

const LoadingIndicator = (props: IconBaseProps) => (
  <IconBase {...props}>
    <StyledCircularProgress />
  </IconBase>
);

export default LoadingIndicator;
