import React, { Component, ErrorInfo } from 'react';
import ErrorScreen from './ErrorScreen';
import * as Sentry from '@sentry/react';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    error: null,
    errorInfo: null,
  };

  // TODO are there inbuilt types for this?
  componentDidCatch = (error: Error, errorInfo: ErrorInfo): void => {
    Sentry.captureException(error);

    this.setState({ error, errorInfo });
  };

  render = () => {
    const { error, errorInfo } = this.state;

    if (!error) {
      return this.props.children;
    }

    const errorComponentStack = errorInfo?.componentStack;

    return (
      <ErrorScreen
        error={error.toString()}
        errorComponentStack={errorComponentStack ?? '(missing stack trace)'}
      />
    );
  };
}
