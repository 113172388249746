import styled from '@emotion/styled';
import RowName from './RowName';
import InputWithEscListener from '@/components/InputWithEscListener';
import mediaQuery from '@/lib/styling/mediaQuery';

const RowNameAsInput = RowName.withComponent(InputWithEscListener);

const RowNameInput = styled(RowNameAsInput)({
  marginTop: -5,
  marginBottom: -5,
  flex: 1,
  lineHeight: 'unset',
  fontFamily: 'CircularStd',
  [mediaQuery(560)]: {
    marginTop: 0,
    marginBottom: 0,
  },
}) as typeof InputWithEscListener;

export default RowNameInput;
