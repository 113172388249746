import { Button } from '@mui/material';
import PerspectiveIcon from '../PerspectiveIcon';
import { FormattedMessage } from 'react-intl';
import { ArrowBack as BackIcon } from '@mui/icons-material';
import styled from '@emotion/styled';
import { Perspective } from '@/lib/types';
import React from 'react';

type SmallScreenPerspectiveHeaderProps = {
  perspective: Perspective;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const StyledHeader = styled('header')({
  padding: 24,
  paddingBottom: 0,
});

const HeaderContentWrapper = styled('section')({
  marginTop: 12,
  display: 'flex',
  alignItems: 'center',
  h2: {
    marginLeft: 12,
  },
});

const SmallScreenPerspectiveHeader = ({
  perspective,
  onClose,
}: SmallScreenPerspectiveHeaderProps) => {
  return (
    <StyledHeader className="sublevel-tree__small-screen-header">
      <Button startIcon={<BackIcon />} color="secondary" onClick={onClose}>
        <FormattedMessage id="perspectives.sublevel_tree.back_button" />
      </Button>
      <HeaderContentWrapper>
        {perspective.icon && (
          <PerspectiveIcon src={perspective.icon} size={40} />
        )}
        <h2>{perspective.name}</h2>
      </HeaderContentWrapper>
    </StyledHeader>
  );
};

export default SmallScreenPerspectiveHeader;
