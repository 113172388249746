import styled from '@emotion/styled';
import ButtonBase from '@/components/ButtonBase';
import mediaQuery from '@/lib/styling/mediaQuery';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';

const SupportTile = styled(ButtonBase)(
  ({ theme }) => ({
    marginLeft: 9,
    marginRight: 9,
    padding: 12,
    flex: 1,
    backgroundColor: theme.palette.action.hover,
    borderRadius: 4,
    transition: theme.transitions.create('all'),
    ':hover, :focus': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.action.selected,
      transform: 'scale(1.05)',
    },
    [mediaQuery(460)]: {
      marginBottom: 18,
      ':last-child': {
        marginBottom: 0,
      },
    },
  }),
  addBorderInHighContrastMode,
);

export default SupportTile;
