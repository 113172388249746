import React, { useState } from 'react';
import LinkButton from './LinkButton';
import LinkSharingDialog from './LinkSharingDialog';
import selectLinkInputContent from './selectLinkInputContent';

type ChallengeLinkSharingProps = {
  ButtonComponent?: React.ElementType;
};

const ChallengeLinkSharing = ({
  ButtonComponent: ButtonComponentOrUndefined,
}: ChallengeLinkSharingProps) => {
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);

  const ButtonComponent = ButtonComponentOrUndefined ?? LinkButton;

  return (
    <>
      <ButtonComponent
        onClick={() => {
          selectLinkInputContent();
          setLinkDialogOpen(true);
        }}
      />
      <LinkSharingDialog
        open={linkDialogOpen}
        onClose={() => setLinkDialogOpen(false)}
      />
    </>
  );
};

export default ChallengeLinkSharing;
