/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { DialogProps } from '@mui/material';
import DialogWithDangerousHandling from './DialogWithDangerousHandling';

type DialogWithEnterCloseProps = DialogProps & {
  open: boolean;
  onEnterClose: (event: KeyboardEvent) => void;
  disableEnterClose: boolean;
  dangerous?: boolean;
};

const DialogWithEnterClose = ({
  open,
  onEnterClose,
  disableEnterClose,
  ...props
}: DialogWithEnterCloseProps) => {
  const closeOnEnterPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onEnterClose(event);
    }
  };

  useEffect(() => {
    if (!open || !onEnterClose || disableEnterClose) {
      return;
    }

    document.addEventListener('keypress', closeOnEnterPress);

    return () => document.removeEventListener('keypress', closeOnEnterPress);
  }, [open, onEnterClose, disableEnterClose]);

  return <DialogWithDangerousHandling open={open} {...props} />;
};

export default DialogWithEnterClose;
