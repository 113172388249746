import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import mediaQuery from '@/lib/styling/mediaQuery';
import device from 'current-device';
import { OmitFramerProps, HTMLDivProps } from '@/lib/types';

type LoginContentWrapperProps = OmitFramerProps<HTMLDivProps> & {
  visible: boolean;
};

const StyledContentWrapper = styled(motion.section)<LoginContentWrapperProps>(
  ({ visible }) => ({
    paddingRight: 36,
    height: visible ? 'auto' : 0,
    flex: 1,
    maxWidth: '50%',
    boxSizing: 'border-box',
    [mediaQuery(768)]: {
      paddingRight: 24,
    },
    [mediaQuery(450)]: {
      paddingRight: 16,
    },
  }),
);

const LoginContentWrapper = (props: LoginContentWrapperProps) => (
  <StyledContentWrapper
    initial={{
      opacity: 0,
    }}
    animate={{ opacity: 1 }}
    transition={{
      delay: device.desktop() ? 0 : 0.2,
    }}
    {...props}
  />
);

export default LoginContentWrapper;
