import { Dialog } from '@mui/material';
import TitleWithSubtopicIcon from './TitleWithSubtopicIcon';
import LevelDialogContent from './LevelDialogContent';
import InnerContentWrapper from './InnerContentWrapper';
import LevelWrapper from './LevelWrapper';
import React from 'react';

export type LevelDialogProps = {
  open: boolean;
  onClose: (event: null, reason: 'backdropClick' | 'escapeKeyDown') => void;
  editor?: boolean;
  children: React.ReactNode;
};

const LevelDialog = ({
  open,
  onClose,
  editor,
  children,
  ...props
}: LevelDialogProps) => (
  <Dialog
    open={open}
    onClose={(_, reason) => {
      // TODO event is always empty so perhaps we can simplify onClose() type
      // signature
      if (editor && reason === 'backdropClick') {
        return;
      }

      onClose(null, reason);
    }}
    PaperProps={{
      style: {
        marginLeft: 15,
        marginRight: 15,
        width: '100%',
        maxWidth: '100%',
      },
    }}
    disableEscapeKeyDown={editor}
    maxWidth={false}
    fullWidth
    {...props}
  >
    {children}
  </Dialog>
);

LevelDialog.Title = TitleWithSubtopicIcon;
LevelDialog.Content = LevelDialogContent;
LevelDialog.InnerContentWrapper = InnerContentWrapper;
LevelDialog.LevelWrapper = LevelWrapper;

export default LevelDialog;
