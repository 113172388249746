import styled from '@emotion/styled';

const ErrorsWrapper = styled('aside')({
  position: 'fixed',
  bottom: 0,
  right: 12,
  width: 400,
  maxWidth: 'calc(100vw - 24px)',
  zIndex: 10_000_000_000,
  overflow: 'hidden',
});

export default ErrorsWrapper;
