import { useState } from 'react';
import { FabProps, Tooltip } from '@mui/material';
import StyledGapminderButton from './StyledGapminderButton';
import GapminderButtonLabel from './GapminderButtonLabel';
import { History as HistoryIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

type GapminderButtonProps = FabProps & {
  disabled: boolean;
};

const GapminderButton = ({ disabled, ...props }: GapminderButtonProps) => {
  // We manage the tooltip state manually to prevent it from giving errors when
  // the gapminder button is disabled
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Tooltip
      title={
        <FormattedMessage id="portfolio.color_legend.gapminder_button_tooltip" />
      }
      placement="bottom-end"
      open={tooltipOpen}
      onOpen={() => setTooltipOpen(true)}
      onClose={() => setTooltipOpen(false)}
    >
      <StyledGapminderButton
        variant="extended"
        color="primary"
        disabled={disabled}
        {...props}
      >
        <GapminderButtonLabel>
          <FormattedMessage id="portfolio.color_legend.gapminder_button" />
        </GapminderButtonLabel>
        <HistoryIcon />
      </StyledGapminderButton>
    </Tooltip>
  );
};

export default GapminderButton;
