import { createContext } from 'react';

type RowsVisibilityState = {
  registerRowVisibility: (id: number, visible: boolean) => void;
};

const RowsVisibilityStateContext = createContext<RowsVisibilityState>({
  registerRowVisibility: () => {},
});

export default RowsVisibilityStateContext;
