import styled from '@emotion/styled';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

type EditorWrapperProps = {
  hasParts: boolean;
};

const EditorWrapper = styled('div')<EditorWrapperProps>(
  ({ theme, hasParts }) => ({
    marginTop: 12,
    padding: 24,
    paddingTop: hasParts ? 8 : 11,
    paddingBottom: 11,
    border: `2px solid ${theme.palette.action.selected}`,
    borderRadius: 4,
    boxSizing: 'border-box',
  }),
  addHighContrastStyles(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.divider,
  })),
);

export default EditorWrapper;
