import { createContext } from 'react';
import {
  EditorPortfolioTemplates,
  SetComplexImmerStateAction,
} from '@/lib/types';

const EditorPortfolioTemplatesContext = createContext<
  [
    EditorPortfolioTemplates,
    SetComplexImmerStateAction<EditorPortfolioTemplates>,
  ]
>([(null as unknown) as EditorPortfolioTemplates, () => {}]);

export default EditorPortfolioTemplatesContext;
