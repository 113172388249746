import { useMemo } from 'react';
import getRandomColor from './getRandomColor';

const useRandomColor = () => {
  const randomColor = useMemo(() => getRandomColor(), []);

  return randomColor;
};

export default useRandomColor;
