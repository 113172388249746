import styled from '@emotion/styled';

type TopicTitleWrapperProps = {
  editor?: boolean;
};

const TopicTitleWrapper = styled('header')<TopicTitleWrapperProps>(
  ({ theme, editor }) => ({
    position: 'relative',
    paddingTop: 24,
    paddingBottom: 24,
    transition: theme.transitions.create('all'),
    cursor: editor ? 'move' : 'pointer',
    display: 'flex',
    alignItems: 'center',
  }),
);

export default TopicTitleWrapper;
