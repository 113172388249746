import { useContext } from 'react';
import App from '@/lib/App';
import SelectedUserContext from '@/lib/contexts/SelectedUserContext';
import AppMode from '@/lib/constants/AppMode.enum';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import { User } from '@/lib/types';

const usePortfolioUser = (): User | null => {
  const selectedMode = App.navigation.useSelectedMode();
  const authenticatedUser = useAuthenticatedUser();
  const selectedUser = useContext(SelectedUserContext);

  if (selectedMode === AppMode.PORTFOLIO) {
    return authenticatedUser;
  }

  if (selectedMode === AppMode.COACH) {
    return selectedUser;
  }

  return null;
};

export default usePortfolioUser;
