import React, { useContext } from 'react';
import FieldContext from '../../../../FieldContext';
import StyledAddButton from './StyledAddButton';
import { FormattedMessage } from 'react-intl';
import useIsPortfolioReadOnly from '@/components/ChallengePortfolio/NormalView/useIsPortfolioReadOnly';

type AddButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const AddButton = ({ onClick }: AddButtonProps) => {
  const field = useContext(FieldContext);
  const portfolioIsReadOnly = useIsPortfolioReadOnly();

  return (
    <StyledAddButton
      onClick={!portfolioIsReadOnly ? onClick : undefined}
      disabled={portfolioIsReadOnly}
    >
      <FormattedMessage
        id="portfolio.challenge.field.add_button"
        values={{
          fieldName: field.name,
        }}
      />
    </StyledAddButton>
  );
};

export default AddButton;
