import { Helmet } from 'react-helmet';
import useProductName from '@/lib/utils/useProductName';

const NoPageTitle = () => {
  const productName = useProductName();

  return <Helmet titleTemplate="%s" title={productName} />;
};

export default NoPageTitle;
