import styled from '@emotion/styled';
import { IconButton, IconButtonProps } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import isPropValid from '@emotion/is-prop-valid';
import stripProps from '@/lib/utils/stripProps';

type StyledFocusButtonProps = IconButtonProps & {
  faded: boolean;
};

const StyledFocusButton = styled(stripProps(IconButton, 'faded'), {
  shouldForwardProp: isPropValid,
})<StyledFocusButtonProps>(
  ({ theme, faded }) => ({
    margin: -8,
    marginRight: 16,
    padding: 8,
    width: 36,
    height: 36,
    opacity: faded ? 0.7 : 1,
    transition: theme.transitions.create('all'),
    [mediaQuery(768)]: {
      marginRight: 8,
    },
  }),
  addHighContrastStyles({
    ':not(:disabled):hover, :focus': {
      transform: 'scale(1.1)',
    },
  }),
);

export default StyledFocusButton;
