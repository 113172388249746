import { useContext } from 'react';
import LinkTextField from './LinkTextField';
import selectLinkInputContent from '../../selectLinkInputContent';
import ChallengeContext from '../../../../ChallengeContext';

const LinkField = () => {
  const [challenge] = useContext(ChallengeContext);

  const baseUrl = window.location.origin;
  const link = `${baseUrl}/challenge/${challenge.uuid}`;

  return <LinkTextField link={link} onClick={selectLinkInputContent} />;
};

export default LinkField;
