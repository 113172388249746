import styled from '@emotion/styled';
import PerspectiveWithSublevelsTree from '@/components/perspectives/PerspectiveWithSublevelsTree';
import mediaQuery from '@/lib/styling/mediaQuery';

const FullscreenPerspectiveWithSublevelsTree = styled(
  PerspectiveWithSublevelsTree,
)({
  marginLeft: -148,
  paddingLeft: 148,
  paddingRight: 148,
  width: 'calc(100% + 296px)',
  flex: 1,
  [mediaQuery(1024)]: {
    marginLeft: -72,
    paddingLeft: 72,
    paddingRight: 72,
    width: 'calc(100% + 144px)',
  },
  [mediaQuery(850)]: {
    marginLeft: -24,
    paddingLeft: 24,
    paddingRight: 24,
    width: 'calc(100% + 48px)',
    flex: 'none',
  },
});

export default FullscreenPerspectiveWithSublevelsTree;
