import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const LoginScreenPageTitle = () => {
  const intl = useIntl();

  return (
    <Helmet>
      <title>{intl.formatMessage({ id: 'login.page_title' })}</title>
    </Helmet>
  );
};

export default LoginScreenPageTitle;
