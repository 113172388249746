import React, { forwardRef } from 'react';

const applyPropStripping = (
  originalProps: Record<string, unknown>,
  strippedProps: string[],
) => {
  const filteredProps = { ...originalProps };

  for (const strippedProp of strippedProps) {
    delete filteredProps[strippedProp];
  }

  return filteredProps;
};

const stripProps = (
  Component: React.ElementType,
  ...strippedProps: string[]
) => {
  const StrippedPropsComponent = forwardRef((props, ref) => {
    const filteredProps = applyPropStripping(props, strippedProps);

    return <Component {...filteredProps} ref={ref} />;
  });

  StrippedPropsComponent.displayName = `stripProps(${
    // TODO figure this out
    // @ts-expect-error In all cases these are guaranteed to be present
    Component.displayName ?? Component.name
  })`;

  return StrippedPropsComponent;
};

export default stripProps;
