import { useContext } from 'react';
import VisibleWeekRangeContext from '../../../VisibleWeekRangeContext';
import { Moment } from 'moment';

const useVisibleWeekDays = (): Moment[] => {
  // The visibleWeekRangeStartDate is the start date of this week since
  // we're "zoomed in" on this week; also, no other weeks are visible at this
  // point
  const { visibleWeekRangeStartDate } = useContext(VisibleWeekRangeContext);
  const weekStartDate = visibleWeekRangeStartDate;

  const weekdays = [1, 2, 3, 4, 5, 6, 7];
  const datesInCurrentWeek = weekdays.map(weekday =>
    weekStartDate.clone().isoWeekday(weekday),
  );

  return datesInCurrentWeek;
};

export default useVisibleWeekDays;
