import styled from '@emotion/styled';
import { Button } from '@mui/material';

const PickColorButton = styled(Button)({
  marginLeft: -8,
  marginRight: -8,
  width: 'calc(100% + 16px)',
  justifyContent: 'left',
});

export default PickColorButton;
