import React from 'react';
import StyledContentWrapper from './StyledContentWrapper';
import useContentIsWide from '@/lib/utils/useContentIsWide';

type AppContentWrapperProps = {
  children: React.ReactNode;
};

const AppContentWrapper = ({ children }: AppContentWrapperProps) => {
  const contentIsWide = useContentIsWide();

  return (
    <StyledContentWrapper wideContent={contentIsWide}>
      {children}
    </StyledContentWrapper>
  );
};

export default AppContentWrapper;
