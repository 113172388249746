import DialogButton, { DialogButtonProps } from './DialogButton';
import { FormattedMessage } from 'react-intl';

const DialogCancelButton = ({ children, ...props }: DialogButtonProps) => (
  <DialogButton variant="outlined" color="secondary" {...props}>
    {children ?? <FormattedMessage id="dialogs.actions.cancel" />}
  </DialogButton>
);

export default DialogCancelButton;
