import { useState } from 'react';
import shuffle from 'shuffle-array';
import useInterval from 'use-interval';
import images from './images';

const getShuffledImages = (): string[] => {
  return shuffle(images, { copy: true });
};

const useSlideshowImage = (): string => {
  const [shuffledImages, setShuffledImages] = useState(getShuffledImages());
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useInterval(() => {
    if (currentImageIndex === images.length - 1) {
      setShuffledImages(getShuffledImages());
      setCurrentImageIndex(0);
      return;
    }

    setCurrentImageIndex(currentImageIndex + 1);
  }, 10_000);

  return shuffledImages[currentImageIndex];
};

export default useSlideshowImage;
