import StyledGroupSelect from './StyledGroupSelect';
import { MenuItem } from '@mui/material';
import renderValueOrPlaceholder from './renderValueOrPlaceholder';
import { Group, SelectProps } from '@/lib/types';

type GroupSelectFieldProps = Omit<SelectProps<number[]>, 'children'> & {
  groups: Group[];
  asManagedGroups: boolean;
};

const GroupSelectField = ({
  groups,
  asManagedGroups,
  ...props
}: GroupSelectFieldProps) => (
  <StyledGroupSelect
    variant="standard"
    renderValue={renderValueOrPlaceholder(asManagedGroups)}
    multiple
    displayEmpty
    {...props}
  >
    {groups.map(group => (
      <MenuItem value={group.id} key={group.id}>
        {group.name}
      </MenuItem>
    ))}
  </StyledGroupSelect>
);

export default GroupSelectField;
