import { useContext } from 'react';
import VisibleWeekRangeContext from '../../VisibleWeekRangeContext';
import StyledButton from './StyledButton';
import { ArrowBack as BackIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { ButtonProps } from '@mui/material';

type CancelWeekZoomButtonProps = Omit<
  ButtonProps,
  'onClick' | 'startIcon' | 'children'
>;

const CancelWeekZoomButton = (props: CancelWeekZoomButtonProps) => {
  const { cancelWeekZoom } = useContext(VisibleWeekRangeContext);

  return (
    <StyledButton onClick={cancelWeekZoom} startIcon={<BackIcon />} {...props}>
      <FormattedMessage id="portfolio.challenge_planning_view.header.week_marker.cancel_zoom_button" />
    </StyledButton>
  );
};

export default CancelWeekZoomButton;
