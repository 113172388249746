import { useContext, useState } from 'react';
import FieldContext from '../../../FieldContext';
import Prompt from '@/components/Prompt';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import ModelTypeContext from '../../../../../ModelTypeContext';
import ModelsContext from '../../../../../../ModelsContext';
import useApi from '@/lib/api/useApi';
import { EditorFieldWithoutRelations } from '@/lib/types';

type InfoEditorProps = {
  open: boolean;
  onClose: () => void;
};

// TODO polling in editor modes?
const InfoEditor = ({ open, onClose }: InfoEditorProps) => {
  const api = useApi();

  const modelType = useContext(ModelTypeContext);
  const [, setModels] = useContext(ModelsContext);
  const { field } = useContext(FieldContext);

  const [saving, setSaving] = useState(false);

  const saveInfo = async (newInfo: string): Promise<void> => {
    onClose();

    if (newInfo === (field!.info ?? '') || saving) {
      return;
    }

    setSaving(true);

    const updatedField = await api.put<EditorFieldWithoutRelations>(
      `editor/challenge-model/fields/${field!.id}/info`,
      {
        info: newInfo,
      },
    );

    setModels(
      models => {
        models[modelType].fields[field!.id] = {
          ...models[modelType].fields[field!.id],
          ...updatedField,
        };
      }
    )

    setSaving(false);
  };

  return (
    <Prompt
      defaultValue={field!.info ?? ''}
      open={open}
      onOk={saveInfo}
      onCancel={onClose}
      multiline
      persistInput
      allowEmptyInput
    >
      <Dialog.Title>
        <FormattedMessage id="editor.model_editor.field_editor.info_editor.title" />
      </Dialog.Title>
      <Prompt.TextField
        label={
          <FormattedMessage id="editor.model_editor.field_editor.info_editor.input_label" />
        }
        disabled={saving}
      />
    </Prompt>
  );
};

export default InfoEditor;
