import appInstanceConfig from './instanceConfig';
import appNavigation from './navigation';

// TODO this should be reworked & reconciled with new backend-based structure
const App = {
  instanceConfig: appInstanceConfig,
  navigation: appNavigation,
} as const;

export default App;
