import { useContext } from 'react';
import SelectedPlatformContext from '@/lib/contexts/SelectedPlatformContext';
import PlatformType from '@/lib/constants/PlatformType.enum';
import UserSwitchArrow from './UserSwitchArrow';
import UserSwitchArrowIcon from './UserSwitchArrowIcon';
import { FormattedMessage } from 'react-intl';
import useArrowKeyListeners from './useArrowKeyListeners';

type UserSwitcherArrowsProps = {
  onPreviousUser: () => void;
  onNextUser: () => void;
  previousArrowDisabled: boolean;
  nextArrowDisabled: boolean;
};

const UserSwitchArrows = ({
  onPreviousUser,
  onNextUser,
  previousArrowDisabled,
  nextArrowDisabled,
}: UserSwitcherArrowsProps) => {
  const [selectedPlatform] = useContext(SelectedPlatformContext);
  const floatingArrows = selectedPlatform === PlatformType.CHALLENGEMONITOR;

  useArrowKeyListeners({
    onLeftArrowPress: () => {
      if (!previousArrowDisabled) {
        onPreviousUser();
      }
    },
    onRightArrowPress: () => {
      if (!nextArrowDisabled) {
        onNextUser();
      }
    },
  });

  return (
    <>
      <UserSwitchArrow
        label={<FormattedMessage id="user_switcher.arrows.previous" />}
        floating={floatingArrows}
        disabled={previousArrowDisabled}
        onClick={onPreviousUser}
      >
        <UserSwitchArrowIcon floating={floatingArrows} left />
      </UserSwitchArrow>
      <UserSwitchArrow
        label={<FormattedMessage id="user_switcher.arrows.next" />}
        floating={floatingArrows}
        disabled={nextArrowDisabled}
        onClick={onNextUser}
        right
      >
        <UserSwitchArrowIcon floating={floatingArrows} right />
      </UserSwitchArrow>
    </>
  );
};

export default UserSwitchArrows;
