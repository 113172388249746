import { FormattedMessage } from 'react-intl';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import { UserSummary } from '@/lib/types';

type CreatedAtWithUserInfoProps = {
  author: UserSummary;
  createdAt: string;
};

const CreatedAtWithUserInfo = ({
  author,
  createdAt,
}: CreatedAtWithUserInfoProps) => {
  const authenticatedUser = useAuthenticatedUser()!;

  if (author.email === authenticatedUser.email) {
    return (
      <FormattedMessage
        id="portfolio.challenge.info.created_at_by_you"
        values={{
          createdAt,
        }}
      />
    );
  }

  return (
    <FormattedMessage
      id="portfolio.challenge.info.created_at_by"
      values={{
        author: author.name,
        createdAt,
      }}
    />
  );
};

export default CreatedAtWithUserInfo;
