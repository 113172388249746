import { useContext } from 'react';
import UsersWrapper from './UsersWrapper';
import User from './User';
import ChallengeContext from '../../../../../Challenge/ChallengeView/ChallengeContext';

const ParticipatingUsers = () => {
  const [challenge] = useContext(ChallengeContext);
  const challengeUsers = [...challenge.users, ...challenge.invitees];

  return (
    <UsersWrapper>
      {challengeUsers.map(user => (
        <User
          user={user}
          isOriginalOwner={
            !!challenge.author && challenge.author.email === user.email
          }
          key={user.email}
        />
      ))}
    </UsersWrapper>
  );
};

export default ParticipatingUsers;
