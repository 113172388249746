import StyledIcon from './StyledIcon';
import React from 'react';

type SupportTileIconProps = {
  icon: React.ElementType;
};

const SupportTileIcon = ({ icon: Icon }: SupportTileIconProps) => (
  <StyledIcon>
    <Icon />
  </StyledIcon>
);

export default SupportTileIcon;
