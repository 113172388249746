import UserRole from '@/lib/constants/UserRole.enum';
import { User } from '@/lib/types';

const rolesAsNumber = {
  [UserRole.STUDENT]: 4,
  [UserRole.COACH]: 3,
  [UserRole.EDITOR]: 2,
  [UserRole.ADMIN]: 1,
  [UserRole.SUPER_ADMIN]: 0,
} as const;

// TODO  this safety isn't needed anymore since users can't accidentally be
// role-less anymore with new backend
const getSafeRoleNumber = (role: UserRole): number => {
  const roleNumber = rolesAsNumber[role];

  return roleNumber ?? rolesAsNumber[UserRole.STUDENT];
};

// TODO better name -> sortby role+name
const sortByRole = (a: User, b: User): number => {
  const aRoleNumber = getSafeRoleNumber(a.role);
  const bRoleNumber = getSafeRoleNumber(b.role);

  if (aRoleNumber < bRoleNumber) {
    return -1;
  }

  if (aRoleNumber > bRoleNumber) {
    return 1;
  }

  return a.name.localeCompare(b.name);
};

export default sortByRole;
