import { Tooltip } from '@mui/material';
import IndicatorIcon from './IndicatorIcon';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

const OriginalOwnerIndicator = () => (
  <Tooltip
    title={
      <FormattedMessageWithChallengeNaming id="portfolio.challenge.sharing.users.original_owner_tooltip" />
    }
  >
    <IndicatorIcon />
  </Tooltip>
);

export default OriginalOwnerIndicator;
