import { useContext, useState } from 'react';
import setBodyOverflow, { Overflow } from '@/lib/styling/setBodyOverflow';
import LeaveButton from './LeaveButton';
import Dialog from '@/components/Dialog';
import DialogLeaveButton from './DialogLeaveButton';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import useApi from '@/lib/api/useApi';
import ChallengeContext from '../../../../../../Challenge/ChallengeView/ChallengeContext';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import RowContext from '@/components/ChallengePortfolio/NormalView/Rows/Row/RowContext';
import NewlySharedContext from '@/components/ChallengePortfolio/NormalView/NewlySharedChallenges/NewlySharedContext';

const LeaveChallenge = () => {
  const api = useApi();

  const [isNewlyShared, , , removeChallengeFromNewlyShared] =
    useContext(NewlySharedContext);
  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const row = useContext(RowContext);
  const [challenge] = useContext(ChallengeContext);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [leaving, setLeaving] = useState(false);

  const leaveChallenge = async (): Promise<void> => {
    setConfirmationDialogOpen(false);

    setLeaving(true);

    await api.post(
      `challenge-portfolio/challenges/${challenge.id}/users/leave`,
    );

    setLeaving(false);
    setConfirmationDialogOpen(false);

    setChallengePortfolio(challengePortfolio => {
      delete challengePortfolio.challenges[challenge.id];

      if (isNewlyShared) {
        removeChallengeFromNewlyShared(challenge.id);

        return;
      }

      challengePortfolio.rows[row.id].challenges.splice(
        challengePortfolio.rows[row.id].challenges.findIndex(
          id => id === challenge.id,
        ),
        1,
      );
    });
  };

  const closeDialog = () => {
    setConfirmationDialogOpen(false);
    setBodyOverflow(Overflow.HIDDEN);
  };

  return (
    <>
      <LeaveButton onClick={() => setConfirmationDialogOpen(true)} />
      <Dialog open={confirmationDialogOpen} onClose={closeDialog} dangerous>
        <Dialog.Title>
          <FormattedMessage id="portfolio.challenge.sharing.leave.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessageWithChallengeNaming id="portfolio.challenge.sharing.leave.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={closeDialog}
            disabled={leaving}
          />
          <DialogLeaveButton onClick={leaveChallenge} disabled={leaving} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default LeaveChallenge;
