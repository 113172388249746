import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const HeaderContentWrapper = styled('header')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  width: '100%',
  fontFamily: 'CircularStd',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.default,
  borderBottom: `2px solid ${theme.palette.background.border}`,
  transition: theme.transitions.create('all'),
  display: 'flex',
  boxSizing: 'border-box',
  zIndex: 2,
  [mediaQuery(640)]: {
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

export default HeaderContentWrapper;
