import { MouseMoveEvent } from '../../SliderRail';

const getEventXOffset = (event: MouseMoveEvent): number | null => {
  if ('pageX' in event) {
    return event.pageX;
  }

  if (!('touches' in event) || event.touches.length === 0) {
    return null;
  }

  const firstTouch = event.touches[0];

  return firstTouch.clientX;
};

export default getEventXOffset;
