import { useContext } from 'react';
import styled from '@emotion/styled';
import TaskContext from '@/components/ChallengePortfolio/NormalView/Task/TaskView/TaskContext';
import mediaQuery from '@/lib/styling/mediaQuery';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

const StyledBar = styled('section')(
  ({ theme }) => ({
    margin: '-24px -48px',
    marginBottom: 24,
    padding: '12px 48px',
    fontFamily: 'CircularStd',
    backgroundColor: theme.isDark
      ? theme.palette.action.hover
      : theme.palette.background.shaded,
    [mediaQuery(560)]: {
      margin: -24,
      marginBottom: 24,
      padding: '12px 24px',
    },
    strong: {
      fontWeight: 500,
    },
  }),
  addHighContrastStyles(({ theme }) => ({
    borderBottom: `2px solid ${theme.palette.divider}`,
  })),
);

const ChallengeNameBar = () => {
  const [task] = useContext(TaskContext);

  return (
    <StyledBar>
      <FormattedMessageWithChallengeNaming
        id="portfolio.task.part_of_challenge"
        values={{
          challengeName: task.challenge.name!,
        }}
        hasHtml
      />
    </StyledBar>
  );
};

export default ChallengeNameBar;
