import styled from '@emotion/styled';
import { CONNECTOR_WRAPPER_WIDTH } from './styleConstants';

const CardConnectorsWrapper = styled('div')({
  position: 'relative',
  width: CONNECTOR_WRAPPER_WIDTH,
  minWidth: CONNECTOR_WRAPPER_WIDTH,
  maxWidth: CONNECTOR_WRAPPER_WIDTH,
});

export default CardConnectorsWrapper;
