import styled from '@emotion/styled';
import LoadingSpinnerWithText from '@/components/LoadingSpinnerWithText';
import { FormattedMessage } from 'react-intl';

const StyledLoadingSpinnerWithText = styled(LoadingSpinnerWithText)({
  paddingTop: 64,
  paddingBottom: 64 - 20,
});

const LoadingMessage = () => (
  <StyledLoadingSpinnerWithText>
    <FormattedMessage id="user_manager.perspective_cohort_manager.loading_message" />
  </StyledLoadingSpinnerWithText>
);

export default LoadingMessage;
