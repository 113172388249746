import Part from '../Part';
import getSubtopicIconFromTitle from './getSubtopicIconFromTitle';
import StyledSubtopic from '@/components/RubricsPortfolioComponents/StyledSubtopic';
import SubtopicNameWrapper from '@/components/RubricsPortfolioComponents/StyledSubtopic/SubtopicNameWrapper';
import SubtopicName from '@/components/RubricsPortfolioComponents/StyledSubtopic/SubtopicName';
import CollapseButton from '@/components/RubricsPortfolioComponents/CollapseButton';
import SubtopicPartWrapper from '@/components/RubricsPortfolioComponents/StyledSubtopic/SubtopicPartWrapper';
import SubtopicIcon from './SubtopicIcon';
import useCollapsedState from '../useCollapsedState';
import App from '@/lib/App';
import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import useUserCanViewItem from '@/lib/rubricsPortfolioUtils/useUserCanViewItem';
import { Subtopic as SubtopicType } from '@/lib/types';

type SubtopicProps = {
  subtopic: SubtopicType;
  belongsToFirstTopic: boolean;
};

const Subtopic = ({ subtopic, belongsToFirstTopic }: SubtopicProps) => {
  const userCanViewItem = useUserCanViewItem();

  const parts = subtopic.parts.filter(userCanViewItem).sort(sortByPosition);

  const subtopicIcon = getSubtopicIconFromTitle(subtopic.name);

  const [collapsed, toggleCollapsed] = useCollapsedState(
    subtopic.id,
    belongsToFirstTopic,
  );

  const aggressiveRubricCollapsingEnabled = App.instanceConfig.useSetting(
    'enableAggressiveRubricCollapsing',
  );

  return (
    <StyledSubtopic>
      <SubtopicNameWrapper
        onClick={toggleCollapsed}
        clickable={aggressiveRubricCollapsingEnabled}
      >
        {!!subtopicIcon && <SubtopicIcon src={subtopicIcon} alt="" />}
        <SubtopicName>{subtopic.name}</SubtopicName>
        {aggressiveRubricCollapsingEnabled && (
          <CollapseButton collapsed={collapsed} />
        )}
      </SubtopicNameWrapper>
      {(!aggressiveRubricCollapsingEnabled || !collapsed) && (
        <SubtopicPartWrapper>
          {parts.map(part => (
            <Part part={part} subtopicIcon={subtopicIcon} key={part.id} />
          ))}
        </SubtopicPartWrapper>
      )}
    </StyledSubtopic>
  );
};

export default Subtopic;
