import { useContext, useState } from 'react';
import PartPromptWithoutGroups from './PartPromptWithoutGroups';
import PartPromptWithGroups from './PartPromptWithGroups';
import EditButton from '../../PortfolioTemplatesEditorActionButtons/EditButton';
import useApi from '@/lib/api/useApi';
import UserRole from '@/lib/constants/UserRole.enum';
import useEditorPartsApiEndpoint from '../useEditorPartsApiEndpoint';
import {
  EditorPart,
  EditorPartWithoutRelations,
  MinimumRole,
} from '@/lib/types';
import EditorPortfolioTemplatesContext from '../../../EditorPortfolioTemplatesContext';

type EditPartProps = {
  part: EditorPart;
  noGroups: boolean;
};

const EditPart = ({ part, noGroups }: EditPartProps) => {
  const api = useApi();
  const editorPartsApiEndpoint = useEditorPartsApiEndpoint();

  const [, setPortfolioTemplates] = useContext(EditorPortfolioTemplatesContext);

  const [editPromptOpen, setEditPromptOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveEdits = async (
    name: string,
    minimumRole: MinimumRole = UserRole.STUDENT,
    groups: number[] = [],
  ): Promise<void> => {
    setEditPromptOpen(false);
    setSaving(true);

    const partData = {
      name,
      position: part.position,
      minimumRole,
      groups,
    };

    const updatedPart = await api.put<EditorPartWithoutRelations>(
      `${editorPartsApiEndpoint}/${part.id}`,
      partData,
    );

    setPortfolioTemplates(portfolioTemplates => {
      portfolioTemplates.parts[part.id] = {
        ...portfolioTemplates.parts[part.id],
        ...updatedPart,
        groups: groups.map(id => ({ id })),
      };
    });

    setSaving(false);
  };

  const PartPrompt = noGroups ? PartPromptWithoutGroups : PartPromptWithGroups;

  const partGroups = noGroups ? [] : part.groups.map(({ id }) => id);

  return (
    <>
      <EditButton onClick={() => setEditPromptOpen(true)} disabled={saving} />
      <PartPrompt
        open={editPromptOpen}
        defaultValue={part.name}
        selectedMinimumRole={part.minimumRole}
        selectedGroups={partGroups}
        onOk={saveEdits}
        onCancel={() => setEditPromptOpen(false)}
      />
    </>
  );
};

export default EditPart;
