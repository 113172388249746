import React, { useContext } from 'react';
import FieldContext from '../../../../FieldContext';
import SelectFieldPlaceholder from '@/components/SelectFieldPlaceholder';
import StyledIndividualValue from './StyledIndividualValue';
import { FormattedMessage } from 'react-intl';

const useRenderSelectValueOrPlaceholder = (): ((
  value: string | string[],
) => React.ReactNode) => {
  const field = useContext(FieldContext);
  const options = field.additionalConfig.options ?? {};

  const cleanValue = (value: string | string[]): string | string[] => {
    if (!Array.isArray(value) && !!options[value]) {
      return value;
    }

    if (!Array.isArray(value)) {
      return '';
    }

    const values = value;

    return values.filter(value => !!options[value]);
  };

  const renderSelectValueOrPlaceholder = (
    dirtyValue: string | string[],
  ): React.ReactNode => {
    const value = cleanValue(dirtyValue);

    if (!value || value.length === 0) {
      return (
        <SelectFieldPlaceholder>
          <FormattedMessage id="portfolio.challenge.select_field.label" />
        </SelectFieldPlaceholder>
      );
    }

    if (!Array.isArray(value)) {
      const selectedOption = options[value];

      return selectedOption?.name;
    }

    const values = value;

    return values.map(value => (
      <StyledIndividualValue key={value}>
        {options[value].name}
      </StyledIndividualValue>
    ));
  };

  return renderSelectValueOrPlaceholder;
};

export default useRenderSelectValueOrPlaceholder;
