import styled from '@emotion/styled';
import { Avatar } from '@mui/material';

const FlagWrapper = styled(Avatar)({
  width: 24,
  height: 24,
  display: 'block',
});

export default FlagWrapper;
