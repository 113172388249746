import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import PortfolioUserContext from '@/lib/contexts/PortfolioUserContext';
import { useContext, useMemo } from 'react';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import { User } from '@/lib/types';

type PerspectiveEndpoints = {
  perspectives: string;
  specificPerspective: (perspectiveId: number) => string;
  perspectiveCategories: string;
  perspectivePortfolio: string;
  perspectiveSpecificPortfolio: (perspectiveId: number) => string;
  attachedTasks: (ids: number[]) => string;
};

const getPerspectiveEndpointsWithCoachScoping = (
  portfolioMode: PortfolioMode,
  portfolioUser: User,
): PerspectiveEndpoints => {
  if (portfolioMode === PortfolioMode.COACH) {
    return {
      perspectives: `/perspectives/coach?userEmail=${portfolioUser.email}`,
      specificPerspective: (perspectiveId: number): string =>
        `/perspectives/${perspectiveId}/coach?userEmail=${portfolioUser.email}`,
      perspectiveCategories: `/perspectives/categories/coach?userEmail=${portfolioUser.email}`,
      perspectivePortfolio: `/perspectives/portfolio/coach?userEmail=${portfolioUser.email}`,
      perspectiveSpecificPortfolio: (perspectiveId: number): string =>
        `/perspectives/portfolio/${perspectiveId}/coach?userEmail=${portfolioUser.email}`,
      attachedTasks: (ids: number[]): string =>
        `/challenge-portfolio/tasks/summaries/coach?ids=${ids.join(
          ',',
        )}&userEmail=${portfolioUser.email}`,
    };
  }

  return {
    perspectives: '/perspectives',
    specificPerspective: (perspectiveId: number): string =>
      `/perspectives/${perspectiveId}`,
    perspectiveCategories: '/perspectives/categories',
    perspectivePortfolio: '/perspectives/portfolio',
    perspectiveSpecificPortfolio: (perspectiveId: number): string =>
      `/perspectives/portfolio/${perspectiveId}`,
    attachedTasks: (ids: number[]): string =>
      `/challenge-portfolio/tasks/summaries?ids=${ids.join(',')}`,
  };
};

const usePerspectiveEndpointsWithCoachScoping = (): PerspectiveEndpoints => {
  const portfolioMode = useContext(PortfolioModeContext);
  const portfolioUser = useContext(PortfolioUserContext)!;

  const perspectiveEndpointsWithCoachScoping = useMemo(
    () => getPerspectiveEndpointsWithCoachScoping(portfolioMode, portfolioUser),
    [portfolioMode, portfolioUser],
  );

  return perspectiveEndpointsWithCoachScoping;
};

export default usePerspectiveEndpointsWithCoachScoping;
