import { useState } from 'react';
import useApi from '@/lib/api/useApi';
import useIsPortfolioReadOnly from '../../useIsPortfolioReadOnly';
import { TaskSummary } from '@/lib/types';

const useMarkCoachCommentsAsReadOnLoad = (
  task: TaskSummary,
  loading: boolean,
): void => {
  const api = useApi();

  const portfolioIsReadOnly = useIsPortfolioReadOnly();
  const [markedAsRead, setMarkedAsRead] = useState(false);

  const markCoachCommentsAsRead = (): void => {
    setMarkedAsRead(true);

    api.post(`challenge-portfolio/tasks/${task.id}/coach-comments/read`);
  };

  if (!loading && !markedAsRead && !portfolioIsReadOnly) {
    markCoachCommentsAsRead();
  }
};

export default useMarkCoachCommentsAsReadOnLoad;
