import PerspectiveCategory from '@/lib/constants/PerspectiveCategory.enum';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

type CategoryNameProps = {
  category: PerspectiveCategory;
};

const StyledName = styled('h3')({
  marginBottom: 12,
  fontWeight: 500,
});

const CategoryName = ({ category }: CategoryNameProps) => (
  <StyledName>
    <FormattedMessage
      id={`perspectives.categorization.category_names.${category}`}
    />
  </StyledName>
);

export default CategoryName;
