import useChallengeAttachedPerspectives from './useChallengeAttachedPerspectives';
import CardPerspectiveIndicators from '../../../perspectives/CardPerspectiveIndicators';
import { ChallengeSummary } from '@/lib/types';

type ChallengeCardPerspectiveIndicatorsProps = {
  challenge: ChallengeSummary;
};

const ChallengeCardPerspectiveIndicators = ({
  challenge,
}: ChallengeCardPerspectiveIndicatorsProps) => {
  const attachedPerspectives = useChallengeAttachedPerspectives(challenge);

  if (attachedPerspectives.length === 0) {
    return null;
  }

  return <CardPerspectiveIndicators perspectives={attachedPerspectives} />;
};

export default ChallengeCardPerspectiveIndicators;
