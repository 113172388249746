import styled from '@emotion/styled';
import { ExpandLess as MuiCollapseIcon } from '@mui/icons-material';
import isPropValid from '@emotion/is-prop-valid';

type CollapseIconProps = {
  rotated: boolean;
};

const CollapseIcon = styled(MuiCollapseIcon, {
  shouldForwardProp: isPropValid,
})<CollapseIconProps>(({ theme, rotated }) => ({
  marginRight: 6,
  transition: theme.transitions.create('all'),
  transform: rotated ? 'rotate(180deg)' : undefined,
}));

export default CollapseIcon;
