import stripProps from '@/lib/utils/stripProps';
import { IconButton, IconButtonProps } from '@mui/material';
import styled from '@emotion/styled';
import { Close as CancelIcon } from '@mui/icons-material';

type CancelButtonProps = Omit<
  IconButtonProps,
  'type' | 'disabled' | 'visible' | 'children'
> & {
  visible: boolean;
  disabled?: boolean;
};

type StyledIconButtonProps = IconButtonProps & {
  visible: boolean;
};

const StyledIconButton = styled(stripProps(IconButton, 'visible'))<
  StyledIconButtonProps
>(({ theme, visible }) => ({
  transform: visible ? 'scale(1)' : 'scale(0)',
  transition: theme.transitions.create('transform'),
}));

const CancelButton = ({ visible, disabled, ...props }: CancelButtonProps) => (
  <StyledIconButton
    type="button"
    disabled={!visible || disabled}
    visible={visible}
    {...props}
  >
    <CancelIcon />
  </StyledIconButton>
);

export default CancelButton;
