import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import { CircularProgress } from '@mui/material';

const LoadingSpinnerWrapper = styled('div')({
  paddingTop: 36,
  paddingBottom: 36,
  display: 'flex',
  justifyContent: 'center',
  [mediaQuery(768)]: {
    paddingTop: 48,
    paddingBottom: 48,
  },
});

const LoginContentLoadingSpinner = () => (
  <LoadingSpinnerWrapper>
    <CircularProgress color="primary" />
  </LoadingSpinnerWrapper>
);

export default LoginContentLoadingSpinner;
