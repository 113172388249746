import {
  StyledActionButton as AlwaysVisibleRowActionButton,
  StyledActionButtonProps,
} from '../RowActionButton/StyledActionButton';
import { Check as CheckIcon } from '@mui/icons-material';

type SaveButtonWithEnterTriggerProps = Omit<
  StyledActionButtonProps,
  'type' | 'edge' | 'children'
>;

const SaveButtonWithEnterTrigger = (props: SaveButtonWithEnterTriggerProps) => (
  <AlwaysVisibleRowActionButton type="submit" edge="end" {...props}>
    <CheckIcon />
  </AlwaysVisibleRowActionButton>
);

export default SaveButtonWithEnterTrigger;
