import { useContext } from 'react';
import RubricsPortfolioContext from '@/lib/contexts/RubricsPortfolioContext';
import ensureLinkIsFullUrl from '@/lib/utils/ensureLinkIsFullUrl';
import { ProofObject } from '@/lib/types';

// TODO ensureLinkIsFull should be removed (handled by backend)
// TODO should be handled by migration tools for existing links
const correctProofUrls = (proof: ProofObject) => {
  const newProofObject = {} as ProofObject;

  for (const individualProofId in proof) {
    newProofObject[individualProofId] = ensureLinkIsFullUrl(
      proof[individualProofId],
    );
  }

  return newProofObject;
};

const useProof = (
  levelId: number,
): [
  ProofObject,
  (proofUpdateFunction: (proof: ProofObject) => void) => void,
] => {
  const [portfolio, setPortfolio] = useContext(RubricsPortfolioContext);
  const proof = correctProofUrls(portfolio.proofLinks[levelId] ?? {});

  const setProof = (
    proofUpdateFunction: (proof: ProofObject) => void,
  ): void => {
    setPortfolio(portfolio => {
      if (typeof portfolio.proofLinks[levelId] === 'undefined') {
        portfolio.proofLinks[levelId] = {};
      }

      proofUpdateFunction(portfolio.proofLinks[levelId]);
    });
  };

  return [proof, setProof];
};

export default useProof;
