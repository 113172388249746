import styled from '@emotion/styled';
import { ArrowDropDown as DropdownArrowIcon } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';

type DropdownButtonProps = IconButtonProps & {
  open: boolean;
};

const StyledDropdownButton = styled(IconButton)<DropdownButtonProps>(
  ({ theme, open }) => ({
    position: 'absolute',
    top: -2,
    right: 3,
    cursor: 'pointer',
    transition: theme.transitions.create('all'),
    transform: open ? 'rotate(180deg)' : 'none',
    zIndex: 1,
  }),
);

const DropdownButton = (props: DropdownButtonProps) => (
  <StyledDropdownButton size="large" {...props}>
    <DropdownArrowIcon />
  </StyledDropdownButton>
);

export default DropdownButton;
