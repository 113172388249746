import { useContext } from 'react';
import VisibleWeekRangeContext from '../../../../VisibleWeekRangeContext';
import moment from 'moment';
import StyledShade from './StyledShade';

const HistoryIndicatingShade = () => {
  const { visibleWeekRangeStartDate, visibleWeeksCount } = useContext(
    VisibleWeekRangeContext,
  );

  const now = moment();
  const minutesFromVisibleWeekRangeStartDate = now.diff(
    visibleWeekRangeStartDate,
    'minutes',
  );

  const minutesInVisibleWeekRange = visibleWeeksCount * 7 * 24 * 60;

  // The width variable is relative, ie it represents a percentage
  return (
    <StyledShade
      width={
        (minutesFromVisibleWeekRangeStartDate / minutesInVisibleWeekRange) * 100
      }
    />
  );
};

export default HistoryIndicatingShade;
