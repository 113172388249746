import Dialog from '@/components/Dialog';
import { TextField, TextFieldProps } from '@mui/material';
import device from 'current-device';

export type PromptTextFieldProps = Omit<
  TextFieldProps,
  'variant' | 'maxRows' | 'fullWidth'
> & {
  __TYPE?: string;
};

const PromptTextField = ({
  __TYPE,
  minRows,
  autoFocus,
  ...props
}: PromptTextFieldProps) => (
  <Dialog.Content style={{ marginTop: -12 }}>
    <TextField
      variant="filled"
      minRows={minRows ?? 4}
      maxRows={6}
      autoFocus={
        typeof autoFocus !== 'undefined' ? autoFocus : device.desktop()
      }
      fullWidth
      {...props}
    />
  </Dialog.Content>
);

PromptTextField.defaultProps = {
  __TYPE: 'PromptTextField',
};

export default PromptTextField;
