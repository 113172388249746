import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

type SubtopicNameWrapperProps = {
  clickable?: boolean;
  editorOnly?: boolean;
};

const SubtopicNameWrapper = styled('h3', {
  shouldForwardProp: isPropValid,
})<SubtopicNameWrapperProps>(({ theme, clickable, editorOnly }) => ({
  marginBottom: 12,
  color: editorOnly ? theme.palette.text.secondary : undefined,
  display: 'flex',
  alignItems: 'center',
  cursor: clickable ? 'pointer' : undefined,
}));

export default SubtopicNameWrapper;
