import { useContext } from 'react';
import InfoWrapper from '../../Challenge/ChallengeView/ChallengeInfo/InfoWrapper';
import CreatedAtWithUserInfo from '../../Challenge/ChallengeView/ChallengeInfo/CreatedAtWithUserInfo';
import useFormattedCreatedAt from '../../Challenge/ChallengeView/ChallengeInfo/useFormattedCreatedAt';
import { FormattedMessage } from 'react-intl';
import TaskContext from './TaskContext';

const TaskInfo = () => {
  const [task] = useContext(TaskContext);
  const formattedCreatedAt = useFormattedCreatedAt(task.createdAt);

  return (
    <InfoWrapper>
      {task.author ? (
        <CreatedAtWithUserInfo
          author={task.author}
          createdAt={formattedCreatedAt}
        />
      ) : (
        <FormattedMessage
          id="portfolio.challenge.info.created_at"
          values={{
            createdAt: formattedCreatedAt,
          }}
        />
      )}
    </InfoWrapper>
  );
};

export default TaskInfo;
