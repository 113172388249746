import Prompt, { PromptProps } from '@/components/Prompt';
import Dialog from '@/components/Dialog';
import DangerousDialogSaveButton from './DangerousDialogSaveButton';
import { FormattedMessage } from 'react-intl';
import FormattedHtmlMessage from '@/components/FormattedHtmlMessage';

const CONFIRMATION_TEXT = 'Egodact';

type ConfirmationPromptProps = Omit<PromptProps, 'onOk'> & {
  open: boolean;
  onConfirm: (input: string) => void;
  onCancel: () => void;
};

const ConfirmationPrompt = ({
  onConfirm,
  ...props
}: ConfirmationPromptProps) => (
  <Prompt
    emptyError="platform_settings.login_settings.save_confirmation_prompt.errors.confirmation_text_empty"
    showError={confirmationText =>
      confirmationText !== CONFIRMATION_TEXT && (
        <FormattedMessage id="platform_settings.login_settings.save_confirmation_prompt.errors.incorrect_confirmation_text" />
      )
    }
    onOk={onConfirm}
    OkButton={DangerousDialogSaveButton}
    disableEnterClose
    {...props}
  >
    <Dialog.Title>
      <FormattedMessage id="platform_settings.login_settings.save_confirmation_prompt.title" />
    </Dialog.Title>
    <Dialog.Content>
      <Dialog.ContentText>
        <FormattedHtmlMessage id="platform_settings.login_settings.save_confirmation_prompt.text" />
      </Dialog.ContentText>
    </Dialog.Content>
    <Prompt.TextField
      label={
        <FormattedMessage id="platform_settings.login_settings.save_confirmation_prompt.input_label" />
      }
    />
  </Prompt>
);

export default ConfirmationPrompt;
