import styled from '@emotion/styled';
import { TextField, TextFieldProps } from '@mui/material';

type StyledTextFieldProps = Omit<TextFieldProps, 'error'> & {
  error: boolean;
};

const StyledTextField = styled(TextField)<StyledTextFieldProps>(({ error }) => {
  if (error) {
    return {
      marginTop: 6,
    };
  }
});

export default StyledTextField;
