import styled from '@emotion/styled';
import { Button, ButtonProps } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';
import { useMediaQuery } from '@mui/material';
import { DescriptionOutlined as DraftsIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

type DraftsButtonProps = Pick<ButtonProps, 'onClick'> & {
  selected: boolean;
};

type StyledButtonProps = {
  selected: boolean;
};

const StyledButton = styled(Button)<StyledButtonProps>(
  ({ theme, selected }) => ({
    marginLeft: 'auto',
    color: selected ? `${theme.palette.primary.main} !important` : undefined,
    [mediaQuery(640)]: {
      marginTop: 9,
      marginLeft: 0,
      marginRight: 'auto',
    },
  }),
);

const DraftsButton = ({ selected, onClick }: DraftsButtonProps) => {
  const shouldUseOutlinedVariant = useMediaQuery('(max-width: 640px)');

  return (
    <StyledButton
      variant={shouldUseOutlinedVariant ? 'outlined' : 'text'}
      color="secondary"
      disabled={selected}
      selected={selected}
      startIcon={<DraftsIcon />}
      onClick={onClick}
    >
      <FormattedMessage id="group_names.drafts" />
    </StyledButton>
  );
};

export default DraftsButton;
