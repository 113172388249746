import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import Dialog from '@/components/Dialog';
import FormattedText from '@/components/FormattedText';
import PerspectiveColoredDialog from './PerspectiveColoredDialog';
import PerspectiveNameDialogTitle from './PerspectiveNameDialogTitle';
import { Perspective, PerspectiveSublevel } from '@/lib/types';
import { common } from '@mui/material/colors';

type PerspectiveExplanationProps = {
  perspectiveOrSublevel: Perspective | PerspectiveSublevel;
  perspectiveColor?: string;
};

const getPerspectiveColor = (
  perspectiveOrSublevel: Perspective | PerspectiveSublevel,
  perspectiveColor: string | undefined,
) => {
  if (typeof perspectiveColor !== 'undefined') {
    return perspectiveColor;
  }

  if (!('color' in perspectiveOrSublevel)) {
    console.warn(
      '[PerspectiveExplanation] either `perspectiveOrSublevel.color` or `perspectiveColor` should be set.',
    );

    return common.black;
  }

  return perspectiveOrSublevel.color;
};

const PerspectiveExplanation = ({
  perspectiveOrSublevel,
  perspectiveColor,
}: PerspectiveExplanationProps) => {
  const [explanationDialogOpen, setExplanationDialogOpen] = useState(false);

  if (perspectiveOrSublevel.explanation.text === null) {
    return null;
  }

  const dialogColor = getPerspectiveColor(
    perspectiveOrSublevel,
    perspectiveColor,
  );

  return (
    <>
      <IconButton
        className="perspective-card__action-button"
        onClick={() => setExplanationDialogOpen(true)}
        size="small"
      >
        <InfoIcon />
      </IconButton>
      <PerspectiveColoredDialog
        open={explanationDialogOpen}
        onClose={() => setExplanationDialogOpen(false)}
        perspectiveColor={dialogColor}
      >
        <PerspectiveNameDialogTitle
          perspectiveOrSublevel={perspectiveOrSublevel}
        />
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedText>
              {perspectiveOrSublevel.explanation.text}
            </FormattedText>
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.DoneButton
            onClick={() => setExplanationDialogOpen(false)}
          />
        </Dialog.Actions>
      </PerspectiveColoredDialog>
    </>
  );
};

export default PerspectiveExplanation;
