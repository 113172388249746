import styled from '@emotion/styled';
import WrapperWithWeekRangeShiftingAnimations from '../../../../WrapperWithWeekRangeShiftingAnimations';
import mediaQuery from '@/lib/styling/mediaQuery';

const LinesWrapper = styled(WrapperWithWeekRangeShiftingAnimations)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  zIndex: -1,
  [mediaQuery(700)]: {
    display: 'none',
  },
});

export default LinesWrapper;
