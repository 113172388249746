import { useContext } from 'react';
import FieldContext from '../../../FieldContext';
import ModelsContext from '../../../../../../ModelsContext';
import ModelTypeContext from '../../../../../ModelTypeContext';
import useApi from '@/lib/api/useApi';
import { EditorField } from '@/lib/types';

const useSwapPositionsWithField = (): ((
  fieldToSwapWith: EditorField,
) => Promise<void>) => {
  const api = useApi();

  const modelType = useContext(ModelTypeContext);
  const [, setModels] = useContext(ModelsContext);
  const { field } = useContext(FieldContext);

  const swapFieldPositions = async (
    fieldToSwapWith: EditorField,
  ): Promise<void> => {
    await api.post(
      `editor/challenge-model/fields/${field!.id}/position/swap/${
        fieldToSwapWith.id
      }`,
    );

    setModels(models => {
      models[modelType].fields[field!.id].position = fieldToSwapWith.position;
      models[modelType].fields[fieldToSwapWith.id].position = field!.position;
    });
  };

  return swapFieldPositions;
};

export default useSwapPositionsWithField;
