import { useContext, useState } from 'react';
import Space from '@/components/Space';
import FeedbackItemActionButton from './FeedbackItemActionButton';
import { Edit as EditIcon } from '@mui/icons-material';
import Prompt from '@/components/Prompt';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import FieldRendererContext from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/FieldRendererContext';
import useApi from '@/lib/api/useApi';
import { CoachComment } from '@/lib/types';

type EditFeedbackItemProps = {
  feedbackItem: CoachComment;
};

const EditFeedbackItem = ({ feedbackItem }: EditFeedbackItemProps) => {
  const api = useApi();

  const { setPortfolioItem, portfolioItemApiEndpoint } =
    useContext(FieldRendererContext);

  const [editPromptOpen, setEditPromptOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveChanges = async (newContent: string): Promise<void> => {
    setEditPromptOpen(false);

    setSaving(true);

    const updatedFeedbackItem = await api.put<CoachComment>(
      `${portfolioItemApiEndpoint}/coach-comments/${feedbackItem.id}`,
      {
        content: newContent,
      },
    );

    setPortfolioItem(portfolioItem => {
      portfolioItem.coachComments[feedbackItem.id] = {
        ...portfolioItem.coachComments[feedbackItem.id],
        ...updatedFeedbackItem,
      };
    });

    setSaving(false);
  };

  return (
    <>
      <Space width="auto" />
      <FeedbackItemActionButton
        onClick={() => setEditPromptOpen(true)}
        disabled={saving}
      >
        <EditIcon fontSize="small" />
      </FeedbackItemActionButton>
      <Prompt
        open={editPromptOpen}
        defaultValue={feedbackItem.content}
        onCancel={() => setEditPromptOpen(false)}
        onOk={saveChanges}
        emptyError="portfolio.challenge.coach_feedback.feedback_empty_error"
        multiline
        persistInput
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.challenge.coach_feedback.feedback_item.edit.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio.challenge.coach_feedback.feedback_item.edit.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Prompt.TextField
          label={
            <FormattedMessage id="portfolio.challenge.coach_feedback.feedback_item.edit.input_label" />
          }
        />
      </Prompt>
    </>
  );
};

export default EditFeedbackItem;
