import React, { useState } from 'react';
import CreateButton from './CreateButton';
import Prompt from '@/components/Prompt';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

type CreateItemProps = {
  tooltip: React.ReactNode;
  onSave: (name: string) => void;
};

const CreateItem = ({ tooltip, onSave }: CreateItemProps) => {
  const [createPromptOpen, setCreatePromptOpen] = useState(false);

  return (
    <>
      <CreateButton
        tooltip={tooltip}
        onClick={() => setCreatePromptOpen(true)}
      />
      <Prompt
        open={createPromptOpen}
        onOk={name => {
          setCreatePromptOpen(false);
          onSave(name);
        }}
        OkButton={Dialog.Actions.CreateButton}
        onCancel={() => setCreatePromptOpen(false)}
        emptyError="prompt.errors.name_empty"
      >
        <Dialog.Title>
          <FormattedMessageWithChallengeNaming id="portfolio.create_challenge.title" />
        </Dialog.Title>
        <Prompt.TextField
          label={
            <FormattedMessage id="portfolio.create_challenge.input_label" />
          }
        />
      </Prompt>
    </>
  );
};

export default CreateItem;
