/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import useApi from '@/lib/api/useApi';
import PortfolioUserContext from '@/lib/contexts/PortfolioUserContext';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import {
  RubricsPortfolioSnapshotsForWeek,
  RubricsPortfolioSnapshotsObject,
} from '@/lib/types';
import useComplexState from '@/lib/utils/useComplexState';

const usePortfolioSnapshots = (): [
  RubricsPortfolioSnapshotsObject,
  (year: number) => Promise<void>,
] => {
  const api = useApi();

  const portfolioMode = useContext(PortfolioModeContext);
  const portfolioUser = useContext(PortfolioUserContext);
  const [portfolioSnapshots, setPortfolioSnapshots] =
    useComplexState<RubricsPortfolioSnapshotsObject>({});

  useEffect(() => {
    setPortfolioSnapshots({});
  }, [portfolioUser?.email]);

  const loadPortfolioSnapshotsForYear = async (year: number): Promise<void> => {
    const portfolioSnapshotsUrl =
      portfolioMode === PortfolioMode.COACH
        ? `portfolio/snapshots/${year}/coach?userEmail=${portfolioUser!.email}`
        : `portfolio/snapshots/${year}`;

    const newPortfolioSnapshots =
      await api.getGlobalStatic<RubricsPortfolioSnapshotsForWeek>(
        portfolioSnapshotsUrl,
      );

    setPortfolioSnapshots(portfolioSnapshots => {
      portfolioSnapshots[year] = newPortfolioSnapshots;

      return portfolioSnapshots;
    });
  };

  return [portfolioSnapshots, loadPortfolioSnapshotsForYear];
};

export default usePortfolioSnapshots;
