import React, { createContext, useState } from 'react';
import * as Sentry from '@sentry/react';
import ApiErrors from './ApiErrors';
import ApiAuthenticationError from '../ApiAuthenticationError';
import HttpMethod from '@/lib/constants/HttpMethod.enum';
import dummyFunction from '@/lib/utils/dummyFunction';
import { ReactContextWithAutomatedProvider } from '@/lib/types';

const ApiHandlingContext = createContext<
  (error: unknown, httpMethod: HttpMethod | null) => void
>(dummyFunction) as ReactContextWithAutomatedProvider<
  (error: unknown, httpMethod: HttpMethod | null) => void
>;

const InnerApiHandlingContextProvider = ApiHandlingContext.Provider;

type ApiHandlingContextProviderProps = {
  refreshAuthenticationState: () => void;
  children: React.ReactNode;
};

const ApiHandlingContextProvider = ({
  refreshAuthenticationState,
  children,
  ...props
}: ApiHandlingContextProviderProps) => {
  const [errors, setErrors] = useState<unknown[]>([]);

  const handleError = (error: unknown, httpMethod: HttpMethod | null): void => {
    if (typeof error === 'object' && error !== null) {
      (error as Record<string, unknown>).httpMethod = httpMethod;
    }

    // TODO log error in dev env

    if (error instanceof ApiAuthenticationError) {
      refreshAuthenticationState();

      return;
    }

    setErrors(errors => [...errors, error]);

    Sentry.captureException(error);
  };

  return (
    <InnerApiHandlingContextProvider value={handleError} {...props}>
      {children}
      <ApiErrors errors={errors} />
    </InnerApiHandlingContextProvider>
  );
};

ApiHandlingContext.AutomatedProvider = ApiHandlingContextProvider;

export default ApiHandlingContext;
