import styled from '@emotion/styled';
import { Fab, FabProps } from '@mui/material';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';
import React from 'react';

type AddIconWrapperProps = {
  id: string;
  children: React.ReactNode;
};

const FabAsDiv = (props: FabProps<'div'>) => <Fab component="div" {...props} />;

const StyledFab = styled(FabAsDiv)<FabProps<'div'>>(
  ({ theme }) => ({
    margin: 0,
    padding: 8,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    display: 'inline-flex',
    ':hover, :focus': {
      backgroundColor: theme.palette.increaseContrastWithBackground(
        theme.palette.background.paper,
        0.1,
      ),
    },
  }),
  addBorderInHighContrastMode,
);

const AddIconWrapper = (props: AddIconWrapperProps) => (
  <StyledFab size="small" tabIndex={-1} {...props} />
);

export default AddIconWrapper;
