type Styles = Record<string, unknown>;

// TODO
const addHighContrastStyles = (
  stylesOrStyleFunction: Styles | ((options: any) => any),
) => (options: any): any => {
  const { theme } = options;

  if (!theme.palette.isHighContrast) {
    return;
  }

  if (typeof stylesOrStyleFunction === 'function') {
    return stylesOrStyleFunction(options as any);
  }

  return stylesOrStyleFunction;
};

export default addHighContrastStyles;
