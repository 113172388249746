import styled from '@emotion/styled';
import EgodactLogo from '@/components/EgodactLogo';

const StyledEgodactLogo = styled(EgodactLogo)({
  marginBottom: 36,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: 400,
  maxWidth: 'calc(100% - 48px)',
});

export default StyledEgodactLogo;
