import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import MobileCoachFeedback from './MobileCoachFeedback';
import FeedbackList from './FeedbackList';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import AddFeedback from './AddFeedback';
import FieldRendererContext from '../../../FieldRendererContext';

const CoachFeedback = () => {
  const { portfolioItem } = useContext(FieldRendererContext);
  const field = useContext(FieldContext);

  const feedback = Object.values(portfolioItem.coachComments).filter(
    coachComment => coachComment.fieldId === field.id,
  );

  return (
    <>
      <MobileCoachFeedback feedback={feedback} />
      {feedback.length > 0 ? (
        <FeedbackList feedback={feedback} />
      ) : (
        <RestrictedToPortfolioMode mode={PortfolioMode.COACH}>
          <AddFeedback />
        </RestrictedToPortfolioMode>
      )}
    </>
  );
};

export default CoachFeedback;
