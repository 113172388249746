import RowActionButton, { RowActionButtonProps } from '../RowActionButton';
import { Delete as DeleteIcon } from '@mui/icons-material';

type DeleteRowButtonProps = Omit<
  RowActionButtonProps,
  'dangerous' | 'children'
>;

const DeleteRowButton = (props: DeleteRowButtonProps) => (
  <RowActionButton dangerous {...props}>
    <DeleteIcon />
  </RowActionButton>
);

export default DeleteRowButton;
