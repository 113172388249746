import styled from '@emotion/styled';
import { ListItemButton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

type ModeLinkProps = {
  to: string | null;
  selected: boolean;
  modeSwitchOpen: boolean;
  onClick: () => void;
  children: React.ReactNode;
};

const LinkListItem = ({
  to,
  selected,
  modeSwitchOpen,
  ...props
}: ModeLinkProps) => {
  if (to) {
    return <ListItemButton component={Link} to={to} {...props} />;
  }

  return <ListItemButton tabIndex={-1} {...props} />;
};

const ModeLink = styled(LinkListItem)<ModeLinkProps>(
  ({ selected, modeSwitchOpen }) => ({
    padding: '10px 15px',
    display: selected || modeSwitchOpen ? 'block' : 'none',
  }),
);

export default ModeLink;
