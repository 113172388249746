import { useContext } from 'react';
import ChallengeSearchQueryContext from '@/components/ChallengeSearchQueryContext';
import matchesSearch from '@/lib/utils/matchesSearch';

const useChallengeSearchQueryMatching = (
  challengeName: string,
): [boolean, boolean] => {
  const searchQuery = useContext(ChallengeSearchQueryContext);

  // TODO is this check necessary? Seems impossible
  if (!challengeName) {
    return [false, false];
  }

  const challengeMatchesSearchQuery = matchesSearch(challengeName, searchQuery);

  const challengeFaded = !challengeMatchesSearchQuery;
  const challengeHighlighted = !!searchQuery && challengeMatchesSearchQuery;

  return [challengeFaded, challengeHighlighted];
};

export default useChallengeSearchQueryMatching;
