import React, { useContext, useState } from 'react';
import TaskContext from '../../TaskContext';
import ChallengeContext from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeContext';
import useApi from '@/lib/api/useApi';
import FormWrapper from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/ChallengeNameEditor/FormWrapper';
import TaskNameInput from './TaskNameInput';
import HideOnLowWidth from '@/components/HideOnLowWidth';
import CancelButton from '@/components/CancelButton';
import { IconButton } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { TaskWithoutRelations } from '@/lib/types';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

type TaskNameEditorProps = {
  onClose: () => void;
};

const TaskNameEditor = ({ onClose }: TaskNameEditorProps) => {
  const api = useApi();

  const [, setChallenge] = useContext(ChallengeContext);
  const [task, setTask] = useContext(TaskContext);

  const [newTaskName, setNewTaskName, , reenableTaskNameSync] =
    useComplexSwrState(task.name);
  const [saving, setSaving] = useState(false);

  const saveNameChange = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();

    if (newTaskName.trim().length === 0) {
      return;
    }

    setSaving(true);

    const updatedTask = await api.put<TaskWithoutRelations>(
      `challenge-portfolio/tasks/${task.id}/name`,
      {
        name: newTaskName.trim(),
      },
    );

    setTask(task => {
      task.name = updatedTask.name;
    });

    setChallenge(challenge => {
      challenge.tasks[task.id].name = updatedTask.name;
    });

    setSaving(false);

    onClose();

    reenableTaskNameSync();
  };

  const cancelNameChange = (): void => {
    onClose();

    setNewTaskName(task.name);
    reenableTaskNameSync();
  };

  return (
    <FormWrapper onSubmit={saveNameChange}>
      <TaskNameInput
        value={newTaskName}
        onChange={e => setNewTaskName(e.target.value)}
        onEscPress={cancelNameChange}
        autoFocus
      />
      <HideOnLowWidth width={700}>
        <CancelButton
          visible={newTaskName !== task.name}
          onClick={cancelNameChange}
        />
      </HideOnLowWidth>
      <IconButton
        type="submit"
        disabled={newTaskName.trim().length === 0 || saving}
      >
        <CheckIcon />
      </IconButton>
    </FormWrapper>
  );
};

export default TaskNameEditor;
