import { useContext } from 'react';
import ChallengePortfolioContext from './ChallengePortfolioContext';
import { IdPositionTuple, Row } from '@/lib/types';

const useUserRowIds = (): IdPositionTuple[] => {
  const [challengePortfolio] = useContext(ChallengePortfolioContext);

  if (Object.values(challengePortfolio.rows).length === 0) {
    return [];
  }

  const sortedRowIds = Object.values(challengePortfolio.rows)
    .sort((a: Row, b: Row) => a.position - b.position)
    .reverse()
    // TODO does this legacy firebase approach still make sense?
    .map((row: Row): IdPositionTuple => [row.id, row.position]);

  return sortedRowIds;
};

export default useUserRowIds;
