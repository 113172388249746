import FooterLogo from '@/components/App/AppFooter/FooterLogo';
import styled from '@emotion/styled';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import mediaQuery from '@/lib/styling/mediaQuery';

const StyledFooter = styled('footer')(
  ({ theme }) => ({
    paddingLeft: 148,
    paddingRight: 148,
    transition: theme.transitions.create('all'),
    height: 80,
    backgroundColor: theme.palette.background.shaded,
    display: 'flex',
    alignItems: 'center',
    '> a': {
      marginBottom: 0,
    },
    [mediaQuery(1024)]: {
      paddingLeft: 72,
      paddingRight: 72,
    },
    [mediaQuery(850)]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  }),
  addHighContrastStyles(({ theme }) => ({
    borderTop: `2px solid ${theme.palette.divider}`,
  })),
);

const ErrorScreenFooter = () => (
  <StyledFooter>
    <FooterLogo />
  </StyledFooter>
);

export default ErrorScreenFooter;
