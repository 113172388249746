import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

type EditorLabelProps = {
  id: string;
};

const StyledLabel = styled('h4')(({ theme }) => ({
  marginRight: 8,
  color: theme.palette.text.secondary,
  fontWeight: 400,
  flexShrink: 0,
}));

const EditorLabel = ({ id }: EditorLabelProps) => (
  <StyledLabel>
    <FormattedMessage id={id} />
  </StyledLabel>
);

export default EditorLabel;
