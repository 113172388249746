import styled from '@emotion/styled';
import ButtonBase from '@/components/ButtonBase';
import { HTMLButtonProps } from '@/lib/types';

type PartNameProps = HTMLButtonProps & {
  hasFocus?: boolean;
  editorOnly?: boolean;
};

const PartNameWrapper = styled('h4')({
  display: 'inline-block',
});

const StyledPartName = styled(ButtonBase)<PartNameProps>(
  ({ theme }) => ({
    fontWeight: 400,
    transition: theme.transitions.create('all'),
    ':hover': {
      opacity: 0.5,
    },
  }),
  ({ theme, hasFocus }) => {
    if (hasFocus) {
      return {
        color: theme.palette.partFocusTextColor,
        ':hover': {
          opacity: 0.7,
        },
      };
    }
  },
  ({ theme, editorOnly }) => {
    if (editorOnly) {
      return {
        color: theme.palette.text.disabled,
      };
    }
  },
);

const PartName = (props: PartNameProps) => (
  <PartNameWrapper>
    <StyledPartName {...props} />
  </PartNameWrapper>
);

export default PartName;
