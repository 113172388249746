import styled from '@emotion/styled';
import { Button, ButtonProps } from '@mui/material';
import { AddCircle as AddIcon } from '@mui/icons-material';

type AddItemButtonProps = Omit<ButtonProps, 'startIcon'>;

const StyledButton = styled(Button)({
  width: 'fit-content',
});

const AddItemButton = (props: AddItemButtonProps) => (
  <StyledButton startIcon={<AddIcon />} {...props} />
);

export default AddItemButton;
