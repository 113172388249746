import React, { createContext, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import SelectedUserContext from '@/lib/contexts/SelectedUserContext';
import SelectedPlatformContext from './SelectedPlatformContext';
import { ReactContextWithAutomatedProvider } from '../types';

const IsUsingPlanningViewContext = createContext<
  [boolean, React.Dispatch<React.SetStateAction<boolean>>]
>([false, () => {}]) as ReactContextWithAutomatedProvider<
  [boolean, React.Dispatch<React.SetStateAction<boolean>>]
>;

// TODO maybe some reusable ChildrenOnlyProps type?
type AutomatedIsUsingPlanningViewContextProviderProps = {
  children: React.ReactNode;
};

const AutomatedIsUsingPlanningViewContextProvider = ({
  children,
}: AutomatedIsUsingPlanningViewContextProviderProps) => {
  const [isUsingPlanningView, setIsUsingPlanningView] = useState(false);

  const location = useLocation();
  const selectedUser = useContext(SelectedUserContext);
  const [selectedPlatform] = useContext(SelectedPlatformContext);

  useEffect((): void => {
    setIsUsingPlanningView(false);
  }, [location, selectedUser?.email, selectedPlatform]);

  return (
    <IsUsingPlanningViewContext.Provider
      value={[isUsingPlanningView, setIsUsingPlanningView]}
    >
      {children}
    </IsUsingPlanningViewContext.Provider>
  );
};

IsUsingPlanningViewContext.AutomatedProvider = AutomatedIsUsingPlanningViewContextProvider;

export default IsUsingPlanningViewContext;
