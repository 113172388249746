import MoveButtonsWrapper from './MoveButtonsWrapper';
import MoveButton from './MoveButton';
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from '@mui/icons-material';
import { IconButtonProps } from '@mui/material';

type MoveButtonProps = Omit<IconButtonProps, 'children'>;

const MoveUpButton = (props: MoveButtonProps) => (
  <MoveButton {...props}>
    <ArrowUpwardIcon />
  </MoveButton>
);

const MoveDownButton = (props: MoveButtonProps) => (
  <MoveButton {...props}>
    <ArrowDownwardIcon />
  </MoveButton>
);

const MoveButtons = {
  Wrapper: MoveButtonsWrapper,
  Up: MoveUpButton,
  Down: MoveDownButton,
};

export default MoveButtons;
