import { FormattedMessage } from 'react-intl';
import { Props as FormattedMessageProps } from 'react-intl/src/components/message';
import sanitizeHtml from 'sanitize-html';

const escapeHtml = (dirtyHtml: string): string => {
  return sanitizeHtml(dirtyHtml, {
    allowedTags: ['br', 'strong', 'em', 'code'],
  });
};

// TODO types
const FormattedHtmlMessage = (props: FormattedMessageProps) => (
  <FormattedMessage {...props}>
    {chunks =>
      chunks.map((chunk, i) => (
        <span
          dangerouslySetInnerHTML={{ __html: escapeHtml(chunk as string) }}
          key={i}
        />
      )) as any
    }
  </FormattedMessage>
);

export default FormattedHtmlMessage;
