import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import worlds from '../worlds';
import InfoWrapper from './InfoWrapper';
import InfoField from './InfoField';
import CloseButton from './CloseButton';

type InfoDialogProps = {
  open: boolean;
  onClose: () => void;
};

const InfoDialog = ({ open, onClose }: InfoDialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      style: {
        marginLeft: 15,
        marginRight: 15,
      },
    }}
    maxWidth={false}
    fullWidth
  >
    <DialogTitle>
      <FormattedMessage id="portfolio.challenge.world_select.world_info.title" />
    </DialogTitle>
    <DialogContent style={{ paddingBottom: 0 }}>
      <InfoWrapper>
        {worlds.map(world => (
          <InfoField world={world} key={world} />
        ))}
      </InfoWrapper>
    </DialogContent>
    <CloseButton onClick={onClose} />
  </Dialog>
);

export default InfoDialog;
