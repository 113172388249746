import LoadingSpinnerWithText from '@/components/LoadingSpinnerWithText';
import { FormattedMessage } from 'react-intl';

const TaskContentLoadingSpinner = () => (
  <LoadingSpinnerWithText>
    <FormattedMessage id="portfolio.task.loading" />
  </LoadingSpinnerWithText>
);

export default TaskContentLoadingSpinner;
