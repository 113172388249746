import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const UserInfoWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(1024)]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

export default UserInfoWrapper;
