import {
  CharacterMetadata,
  ContentBlock,
  EditorState,
  SelectionState,
} from 'draft-js';

const selectionContainsAnyLinks = (
  editorState: EditorState,
  selection: SelectionState,
): boolean => {
  const content = editorState.getCurrentContent();
  const blocksArray = content.getBlocksAsArray();

  const startBlockKey = selection.getStartKey();
  const endBlockKey = selection.getEndKey();

  const blockKeys = blocksArray.map((block: ContentBlock): string =>
    block.getKey(),
  );
  const selectedBlockKeys = blockKeys.slice(
    blockKeys.indexOf(startBlockKey),
    blockKeys.indexOf(endBlockKey) + 1,
  );

  let hasAnyLinksSelected = false;

  blocksArray.forEach((block: ContentBlock): void => {
    if (hasAnyLinksSelected) {
      return;
    }

    if (!selectedBlockKeys.includes(block.getKey())) {
      return;
    }

    block.findEntityRanges(
      (character: CharacterMetadata): boolean => {
        if (hasAnyLinksSelected) {
          return false;
        }

        const entityKey = character.getEntity();

        if (entityKey === null) {
          return false;
        }

        const entity = content.getEntity(entityKey);

        if (entity.getType() !== 'LINK') {
          return false;
        }

        return true;
      },
      (startOffset: number, endOffset: number): void => {
        if (hasAnyLinksSelected) {
          return;
        }

        if (
          block.getKey() === startBlockKey &&
          endOffset < selection.getStartOffset()
        ) {
          return;
        }

        if (
          block.getKey() === endBlockKey &&
          startOffset > selection.getEndOffset()
        ) {
          return;
        }

        hasAnyLinksSelected = true;
      },
    );
  });

  return hasAnyLinksSelected;
};

export default selectionContainsAnyLinks;
