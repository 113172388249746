import ChallengeLoadingView from './ChallengeLoadingView';
import { useNavigate, useParams } from 'react-router-dom';
import AccessStateSetup from './AccessStateSetup';
import useApiResponse from '@/lib/api/useApiResponse';
import CustomChallengeView from './CustomChallengeView';
import ModelsContext from '@/lib/contexts/ModelsContext';
import VisiblePhaseContext from '@/components/ChallengePortfolio/VisiblePhaseContext';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import IsAnonymizedContext from './IsAnonymizedContext';
import PublicPortfolioItemPageTitle from './PublicPortfolioItemPageTitle';
import NotFoundView from './NotFoundView';
import {
  Challenge,
  ChallengeModels,
  SetComplexImmerStateAction,
} from '@/lib/types';
import AccessLevel from '@/lib/constants/AccessLevel.enum';
import dummyFunction from '@/lib/utils/dummyFunction';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

type PublicChallenge =
  | { not_found: true }
  | (Challenge & {
      not_found: false;
      accessLevel: AccessLevel;
    });

export type PublicChallengeModels =
  | { not_found: true }
  | (ChallengeModels & {
      not_found: false;
    });

const challengePortfolio = {
  rows: {},
  challenges: {},
};
const setChallengePortfolio = dummyFunction;

const OpenChallengePage = () => {
  const navigate = useNavigate();
  const { uuid: challengeUuid } = useParams();

  const [modelsLoading, models] = useApiResponse<PublicChallengeModels>(
    `challenge-models/public/for-challenge/${challengeUuid}`,
  );
  const [challengeLoading, challenge, setChallenge] =
    usePolledSwrAsComplexState<PublicChallenge>(
      `challenge-portfolio/challenges/public/${challengeUuid}`,
    );

  if (modelsLoading || challengeLoading) {
    return <ChallengeLoadingView />;
  }

  if (models.not_found || challenge.not_found) {
    return <NotFoundView />;
  }

  return (
    <>
      <PublicPortfolioItemPageTitle translationId="open_challenge.page_title.challenge" />
      <ModelsContext.Provider value={models}>
        <ChallengePortfolioContext.Provider
          value={[challengePortfolio, setChallengePortfolio]}
        >
          <VisiblePhaseContext.AutomatedProvider>
            <AccessStateSetup accessLevel={challenge.accessLevel}>
              <IsAnonymizedContext.Provider value={true}>
                <CustomChallengeView
                  challenge={challenge}
                  setChallenge={
                    setChallenge as unknown as SetComplexImmerStateAction<Challenge>
                  }
                  onClose={() => navigate('/')}
                />
              </IsAnonymizedContext.Provider>
            </AccessStateSetup>
          </VisiblePhaseContext.AutomatedProvider>
        </ChallengePortfolioContext.Provider>
      </ModelsContext.Provider>
    </>
  );
};

export default OpenChallengePage;
