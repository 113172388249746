import ownYourDevelopmentAndGrowthIcon from './subtopic-icons/own-your-development-and-growth.png';
import discoverAndOrientateIcon from './subtopic-icons/discover-and-orientate.png';
import connectIcon from './subtopic-icons/connect.png';
import communicateIcon from './subtopic-icons/communicate.png';
import reflectIcon from './subtopic-icons/reflect.png';
import researchIcon from './subtopic-icons/research.png';
import shareIcon from './subtopic-icons/share.png';

const subtopicIcons = {
  'own your development/growth': ownYourDevelopmentAndGrowthIcon,
  'discover & orientate': discoverAndOrientateIcon,
  connect: connectIcon,
  communicate: communicateIcon,
  reflect: reflectIcon,
  research: researchIcon,
  share: shareIcon,
} as Record<string, string>;

const getSubtopicIconFromTitle = (title: string): string | null => {
  const subtopicIcon = subtopicIcons[title.trim().toLowerCase()];

  return subtopicIcon ?? null;
};

export default getSubtopicIconFromTitle;
