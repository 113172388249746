import useLocalStorageState from 'use-local-storage-state';
import Theme from '@/lib/constants/Theme.enum';
import React from 'react';

const useCachedSelectedTheme = (): [
  Theme,
  React.Dispatch<React.SetStateAction<Theme>>,
] => {
  const [cachedSelectedTheme, setCachedSelectedTheme] = useLocalStorageState(
    'selected-theme',
    {
      defaultValue: Theme.LIGHT,
    },
  );

  return [cachedSelectedTheme, setCachedSelectedTheme];
};

export default useCachedSelectedTheme;
