import { useState } from 'react';
import Space from '@/components/Space';
import FieldNameWrapper from '../../utils/FieldName/FieldNameWrapper';
import StyledFieldName from '../../utils/FieldName/StyledFieldName';
import { FormattedMessage } from 'react-intl';
import TextEditor from '../../utils/TextEditor';

type NewLogEntryFormProps = {
  logEntryName: string;
  onSave: (value: string) => Promise<void>;
  onCancel: () => void;
};

const NewLogEntryForm = ({
  logEntryName,
  onSave,
  onCancel,
}: NewLogEntryFormProps) => {
  const [saving, setSaving] = useState(false);

  const handleSave = async (value: string | null): Promise<void> => {
    if (value === null) {
      onCancel();

      return;
    }

    setSaving(true);

    await onSave(value);

    setSaving(false);
  };

  return (
    <section>
      <Space height={18} />
      <FieldNameWrapper>
        <StyledFieldName>
          <FormattedMessage
            id="portfolio.challenge.log_entries.add.title"
            values={{ logEntryName }}
          />
        </StyledFieldName>
      </FieldNameWrapper>
      <TextEditor onSave={handleSave} disabled={saving} />
    </section>
  );
};

export default NewLogEntryForm;
