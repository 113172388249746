import AvatarList from '../../Challenge/ChallengeCard/ChallengeUserAvatars/AvatarList';
import sortByName from '@/lib/utils/sorting/sortByName';
import { TaskSummary } from '@/lib/types';
import immerCompatibleArraySort from '@/lib/utils/immerCompatibleArraySort';

type TaskUserAvatarsProps = {
  task: TaskSummary;
};

const TaskUserAvatars = ({ task }: TaskUserAvatarsProps) => (
  <AvatarList
    users={immerCompatibleArraySort(task.assignees, sortByName).slice(0, 4)}
  />
);

export default TaskUserAvatars;
