import styled from '@emotion/styled';
import { HTMLDivProps } from '@/lib/types';

type ViewWrapperProps = HTMLDivProps & {
  hidden: boolean;
};

const ViewWrapper = styled('section')<ViewWrapperProps>(({ hidden }) => ({
  display: hidden ? 'none' : 'initial',
}));

export default ViewWrapper;
