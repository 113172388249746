import Dialog from '@/components/Dialog';
import LinkField from './LinkField';
import IsPublicToggle from './IsPublicToggle';
import Space from '@/components/Space';
import { FormattedMessage } from 'react-intl';

type LinkSharingDialogProps = {
  open: boolean;
  onClose: () => void;
};

const LinkSharingDialog = ({ open, onClose }: LinkSharingDialogProps) => (
  <Dialog open={open} onClose={onClose} onEnterClose={onClose}>
    <Dialog.Title>
      <FormattedMessage id="portfolio.challenge.link_sharing.title.as_task" />
    </Dialog.Title>
    <Dialog.Content>
      <LinkField />
      <Space height={6} />
      <IsPublicToggle />
    </Dialog.Content>
    <Dialog.Actions>
      <Dialog.Actions.DoneButton onClick={onClose} />
    </Dialog.Actions>
  </Dialog>
);

export default LinkSharingDialog;
