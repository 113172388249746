import { useContext, useState } from 'react';
import Dialog from '@/components/Dialog';
import UserContext from '../UserContext';
import useApi from '@/lib/api/useApi';
import UsersContext from '../../UsersContext';
import PerspectiveCohortManagerDialogContent from './PerspectiveCohortManagerDialogContent';
import PerspectiveCohortManagerButton from './PerspectiveCohortManagerButton';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

const PerspectiveCohortManager = () => {
  const [, , setUsers] = useContext(UsersContext);
  const user = useContext(UserContext);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [
    selectedPerspectiveCohort,
    setSelectedPerspectiveCohort,
    ,
    reenableSelectedPerspectiveCohortSync,
  ] = useComplexSwrState<number | ''>(user.perspectiveCohortId ?? '');

  const api = useApi();
  const [saving, setSaving] = useState(false);

  const handleClose = (): void => {
    setDialogOpen(false);

    setSelectedPerspectiveCohort(user.perspectiveCohortId ?? '');
  };

  const handleSave = async (): Promise<void> => {
    setDialogOpen(false);
    setSaving(true);

    if (selectedPerspectiveCohort === '') {
      await api.delete(`/users/${user.id}/perspective-cohort`);
    } else {
      await api.put(`users/${user.id}/perspective-cohort`, {
        perspectiveCohortId: selectedPerspectiveCohort,
      });
    }

    setUsers(users => {
      const userIndex = users.findIndex(element => element.id === user.id);

      users[userIndex].perspectiveCohortId = selectedPerspectiveCohort || null;
    });

    setSaving(false);

    reenableSelectedPerspectiveCohortSync();
  };

  return (
    <>
      <PerspectiveCohortManagerButton
        onClick={() => setDialogOpen(true)}
        disabled={saving}
      />
      <Dialog open={dialogOpen} onClose={handleClose}>
        <PerspectiveCohortManagerDialogContent
          selectedPerspectiveCohort={selectedPerspectiveCohort}
          setSelectedPerspectiveCohort={setSelectedPerspectiveCohort}
          onCancel={handleClose}
          onSave={handleSave}
        />
      </Dialog>
    </>
  );
};

export default PerspectiveCohortManager;
