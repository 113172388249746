import { useState } from 'react';
import LoginErrorUnfoldingWrapper from './LoginErrorUnfoldingWrapper';
import LoginContentWrapper from './LoginContentWrapper';
import LoginWelcomeTitle from './LoginWelcomeTitle';
import LoginButtonWithHandlers from './LoginButtonWithHandlers';
import AllowedEmailText from './AllowedEmailText';
import AllowedEmailsMessage from './AllowedEmailsMessage';
import ErrorScreen from './ErrorScreen';
import useApiResponse from '@/lib/api/useApiResponse';
import SelectedSchoolContext from './SelectedSchoolContext';
import LoginContentLoadingSpinner from '../LoginContentLoadingSpinner';
import { School, SchoolSummary } from '@/lib/types';

type LoginContentProps = {
  selectedSchool: SchoolSummary;
};

const LoginContent = ({ selectedSchool }: LoginContentProps) => {
  const [loading, school] = useApiResponse<School>(
    `schools/${selectedSchool.reference}`,
  );
  const [error, setError] = useState<unknown | null>(null);

  return (
    <SelectedSchoolContext.AutomatedProvider selectedSchool={school}>
      <LoginErrorUnfoldingWrapper showError={error !== null}>
        <LoginContentWrapper
          visible={error === null}
          aria-hidden={error !== null}
        >
          {loading ? (
            <LoginContentLoadingSpinner />
          ) : (
            <>
              <LoginWelcomeTitle />
              <LoginButtonWithHandlers
                onShowError={error => setError(error)}
                disabled={error !== null}
              />
              <AllowedEmailText>
                <AllowedEmailsMessage />
              </AllowedEmailText>
            </>
          )}
        </LoginContentWrapper>
        {!loading && (
          <ErrorScreen
            error={error}
            onDismiss={() => setError(null)}
            visible={error !== null}
            aria-hidden={error === null}
          />
        )}
      </LoginErrorUnfoldingWrapper>
    </SelectedSchoolContext.AutomatedProvider>
  );
};

export default LoginContent;
