import RowActionButton, { RowActionButtonProps } from './RowActionButton';
import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowRight as ArrowRightIcon,
} from '@mui/icons-material';

type CollapseButtonProps = Omit<RowActionButtonProps, 'children'> & {
  collapsed: boolean;
};

const CollapseButton = ({ collapsed, ...props }: CollapseButtonProps) => (
  <RowActionButton {...props}>
    {collapsed ? <ArrowRightIcon /> : <ArrowDownIcon />}
  </RowActionButton>
);

export default CollapseButton;
