import { useEffect } from 'react';

function useKeydownListener(
  targetElement: HTMLElement | Document | null,
  keydownHandler: (event: KeyboardEvent) => void,
): void {
  useEffect(() => {
    // TODO turn into nullcheck?
    if (targetElement) {
      targetElement.addEventListener(
        'keydown',
        keydownHandler as EventListenerOrEventListenerObject,
      );

      return () =>
        targetElement.removeEventListener(
          'keydown',
          keydownHandler as EventListenerOrEventListenerObject,
        );
    }
  });
}

export default useKeydownListener;
