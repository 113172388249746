import mediaQuery from '@/lib/styling/mediaQuery';
import styled from '@emotion/styled';

const AutocompleteOptionsListbox = styled('ul')({
  // Additional specificity is necessary to override Material-UI styles
  '&&': {
    maxHeight: 200,
    [mediaQuery(450)]: {
      maxHeight: 300,
    },
  },
});

export default AutocompleteOptionsListbox;
