import styled from '@emotion/styled';
import PerspectiveColoredDialog from '../../PerspectiveColoredDialog';

const AttachedTasksDialog = styled(PerspectiveColoredDialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    width: 800,
    maxWidth: 'calc(100% - 64px)',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.shaded,
    display: 'block',
  },
}));

export default AttachedTasksDialog;
