import { Tooltip } from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import styled from '@emotion/styled';
import React from 'react';

type InfoMessageProps = {
  children: React.ReactNode;
};

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  marginLeft: 12,
  color: theme.palette.text.secondary,
}));

const InfoMessage = ({ children }: InfoMessageProps) => (
  <Tooltip title={children} arrow>
    <StyledInfoIcon />
  </Tooltip>
);

export default InfoMessage;
