import App from '@/lib/App';
import { FormattedMessage } from 'react-intl';
import FormattedHtmlMessage from './FormattedHtmlMessage';
import { Props as FormattedMessageProps } from 'react-intl/src/components/message';

type FormattedMessageWithChallengeNamingProps = FormattedMessageProps & {
  values?: Record<string, unknown>;
  hasHtml?: boolean;
};

const FormattedMessageWithChallengeNaming = ({
  values,
  hasHtml,
  ...props
}: FormattedMessageWithChallengeNamingProps) => {
  const FormattedMessageComponent = hasHtml
    ? FormattedHtmlMessage
    : FormattedMessage;

  const {
    challengeNameSingular,
    challengeNameSingularCapitalized,
    challengeNamePlural,
  } = App.instanceConfig.useChallengeNaming();

  return (
    <FormattedMessageComponent
      values={{
        challengeNameSingular,
        challengeNameSingularCapitalized,
        challengeNamePlural,
        ...values,
      }}
      {...props}
    />
  );
};

export default FormattedMessageWithChallengeNaming;
