import { ChallengeSummary, SetComplexImmerStateAction } from '@/lib/types';
import dummyFunction from '@/lib/utils/dummyFunction';
import { createContext } from 'react';

// TODO maybe this should be an object instead of array
type NewlySharedState = [
  boolean,
  Record<number, ChallengeSummary>,
  SetComplexImmerStateAction<ChallengeSummary[]>,
  (challengeId: number) => void,
];

const NewlySharedContext = createContext<NewlySharedState>([
  false,
  {},
  dummyFunction,
  dummyFunction,
]);

export default NewlySharedContext;
