import styled from '@emotion/styled';
import { DialogActions, Button, ButtonProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type CloseButtonProps = Omit<
  ButtonProps,
  'variant' | 'disableElevation' | 'children'
>;

const StyledDialogActions = styled(DialogActions)({
  padding: '16px 24px',
});

const CloseButton = (props: CloseButtonProps) => (
  <StyledDialogActions>
    <Button variant="contained" disableElevation {...props}>
      <FormattedMessage id="dialogs.actions.ok" />
    </Button>
  </StyledDialogActions>
);

export default CloseButton;
