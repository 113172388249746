import { useState } from 'react';
import UserSearchWrapper from './UserSearchWrapper';
import UserSearchTextField from './UserSearchTextField';

type UserSearchProps = {
  onSearch: (searchInput: string | null) => void;
};

const UserSearch = ({ onSearch }: UserSearchProps) => {
  const [searchInput, setSearchInput] = useState('');

  const search = (e: React.FormEvent): void => {
    e.preventDefault();
    onSearch(searchInput);
  };

  const clearSearch = (): void => {
    setSearchInput('');
    onSearch(null);
  };

  return (
    <UserSearchWrapper onSubmit={search}>
      <UserSearchTextField
        value={searchInput}
        onChange={e => {
          const value = e.target.value;
          setSearchInput(value);

          if (value.trim() === '') {
            clearSearch();
          }
        }}
        onClearSearch={clearSearch}
        onSearchClick={() => onSearch(searchInput)}
      />
    </UserSearchWrapper>
  );
};

export default UserSearch;
