import { useContext } from 'react';
import PhaseContext from './PhaseContext';
import PhaseWithFirstPhaseNameFormMarginCorrection from './PhaseWithFirstPhaseNameFormMarginCorrection';
import PhaseHeader from './PhaseHeader';
import PhaseContentGroup from './PhaseContentGroup';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import ModelFieldEditorList from './ModelFieldEditorList';
import AddModelField from './AddModelField';
import ModelsContext from '../../ModelsContext';
import ModelTypeContext from '../ModelTypeContext';
import getHighestFieldPosition from './getHighestFieldPosition';
import { EditorField, EditorSection } from '@/lib/types';
import PhaseContentWrapper from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/Phase/PhaseContentWrapper';

type ModelPhaseEditorProps = {
  phase: EditorSection;
  previousPhase: EditorSection | null;
  nextPhase: EditorSection | null;
};

// TODO refactor + clearer naming
const ModelPhaseEditor = ({
  phase,
  previousPhase,
  nextPhase,
}: ModelPhaseEditorProps) => {
  const modelType = useContext(ModelTypeContext);
  const [models] = useContext(ModelsContext);

  const allFields = models[modelType].fields;

  const phaseContentGroups = phase.fields
    .map(({ id }) => allFields[id])
    .filter(field => typeof field !== 'undefined')
    .reduce(
      (phaseContentGroupsSoFar, field) => {
        phaseContentGroupsSoFar[field.contentGroup].push(field);

        return phaseContentGroupsSoFar;
      },
      {
        [ContentGroupType.LEFT]: [] as EditorField[],
        [ContentGroupType.RIGHT]: [] as EditorField[],
        [ContentGroupType.BOTTOM]: [] as EditorField[],
      },
    );

  const highestFieldPositions = {
    [ContentGroupType.LEFT]: getHighestFieldPosition(
      phaseContentGroups[ContentGroupType.LEFT],
    ),
    [ContentGroupType.RIGHT]: getHighestFieldPosition(
      phaseContentGroups[ContentGroupType.RIGHT],
    ),
    [ContentGroupType.BOTTOM]: getHighestFieldPosition(
      phaseContentGroups[ContentGroupType.BOTTOM],
    ),
  };

  const lowestFieldPositionInBottomContent =
    phaseContentGroups[ContentGroupType.BOTTOM][0]?.position ?? 0;

  return (
    <PhaseContext.Provider value={{ phase, previousPhase, nextPhase }}>
      <PhaseWithFirstPhaseNameFormMarginCorrection>
        <PhaseHeader />
        <PhaseContentWrapper>
          <PhaseContentGroup which={ContentGroupType.LEFT}>
            <ModelFieldEditorList
              fields={phaseContentGroups[ContentGroupType.LEFT]}
              highestFieldPositions={highestFieldPositions}
              lowestFieldPositionInPhaseBottomContent={
                lowestFieldPositionInBottomContent
              }
            />
            <AddModelField
              highestFieldPosition={
                highestFieldPositions[ContentGroupType.LEFT]
              }
            />
          </PhaseContentGroup>
          <PhaseContentGroup which={ContentGroupType.RIGHT}>
            <ModelFieldEditorList
              fields={phaseContentGroups[ContentGroupType.RIGHT]}
              highestFieldPositions={highestFieldPositions}
              lowestFieldPositionInPhaseBottomContent={
                lowestFieldPositionInBottomContent
              }
            />
            <AddModelField
              highestFieldPosition={
                highestFieldPositions[ContentGroupType.RIGHT]
              }
            />
          </PhaseContentGroup>
        </PhaseContentWrapper>
        <PhaseContentGroup which={ContentGroupType.BOTTOM}>
          <ModelFieldEditorList
            fields={phaseContentGroups[ContentGroupType.BOTTOM]}
            highestFieldPositions={highestFieldPositions}
            lowestFieldPositionInPhaseBottomContent={
              lowestFieldPositionInBottomContent
            }
          />
          <AddModelField
            highestFieldPosition={
              highestFieldPositions[ContentGroupType.BOTTOM]
            }
          />
        </PhaseContentGroup>
      </PhaseWithFirstPhaseNameFormMarginCorrection>
    </PhaseContext.Provider>
  );
};

export default ModelPhaseEditor;
