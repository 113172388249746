import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const SchoolSelectorWrapper = styled('section')({
  padding: 36,
  [mediaQuery(768)]: {
    padding: 24,
  },
  [mediaQuery(450)]: {
    padding: '24px 16px',
  },
});

export default SchoolSelectorWrapper;
