import React, { useContext, forwardRef, ForwardedRef } from 'react';
import PhaseContext from '../../PhaseContext';
import UnreadCoachCommentsBadge from './UnreadCoachCommentsBadge';
import StyledChallengeCard from '../../Challenge/ChallengeCard/ChallengeCardWithSearchMatching/StyledChallengeCard';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import ContentWrapper from '../../Challenge/ChallengeCard/ContentWrapper';
import ChallengeName from '../../Challenge/ChallengeCard/ChallengeName';
import PlannedEndDatePreview from '../../Challenge/ChallengeCard/PlannedEndDatePreview';
import TaskUserAvatars from './TaskUserAvatars';
import usePerspectivesEnabled from '@/lib/utils/usePerspectivesEnabled';
import TaskCardPerspectiveIndicators from './TaskCardPerspectiveIndicators';
import { TaskSummary } from '@/lib/types';

type TaskCardProps = {
  task: TaskSummary;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  dragging: boolean;
};

const TaskCard = forwardRef(
  (
    { task, onClick, dragging }: TaskCardProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const perspectivesEnabled = usePerspectivesEnabled();
    const phase = useContext(PhaseContext);

    return (
      <UnreadCoachCommentsBadge task={task}>
        <StyledChallengeCard
          big={phase !== KanbanPhase.DONE}
          cardColor={task.color}
          onClick={onClick}
          dragging={dragging}
          ref={ref}
        >
          <ContentWrapper>
            {perspectivesEnabled && (
              <TaskCardPerspectiveIndicators task={task} />
            )}
            <ChallengeName challenge={task} />
            {phase !== KanbanPhase.DONE && task.plannedEndDate && (
              <PlannedEndDatePreview
                date={task.plannedEndDate}
                challengeColor={task.color}
              />
            )}
            <TaskUserAvatars task={task} />
          </ContentWrapper>
        </StyledChallengeCard>
      </UnreadCoachCommentsBadge>
    );
  },
);

TaskCard.displayName = 'TaskCard';

export default TaskCard;
