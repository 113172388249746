import PortfolioUserContext from '@/lib/contexts/PortfolioUserContext';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import PortfolioIsReadOnlyContext from '@/lib/contexts/PortfolioIsReadOnlyContext';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import AccessLevel from '@/lib/constants/AccessLevel.enum';
import AccessLevelContext from './AccessLevelContext';
import React from 'react';

type AccessStateSetupProps = {
  accessLevel: AccessLevel;
  children: React.ReactNode;
};

const AccessStateSetup = ({ accessLevel, children }: AccessStateSetupProps) => {
  const authenticatedUser = useAuthenticatedUser();

  return (
    <PortfolioUserContext.Provider value={authenticatedUser}>
      <PortfolioModeContext.Provider
        value={
          accessLevel === AccessLevel.COACH
            ? PortfolioMode.COACH
            : PortfolioMode.STUDENT
        }
      >
        <AccessLevelContext.Provider value={accessLevel}>
          <PortfolioIsReadOnlyContext.Provider
            value={
              ![AccessLevel.MEMBER, AccessLevel.COACH].includes(accessLevel)
            }
          >
            {children}
          </PortfolioIsReadOnlyContext.Provider>
        </AccessLevelContext.Provider>
      </PortfolioModeContext.Provider>
    </PortfolioUserContext.Provider>
  );
};

export default AccessStateSetup;
