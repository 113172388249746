import { ObjectWithId } from '../types';

const mapElementsById = <T extends ObjectWithId>(
  array: T[],
): Map<number, T> => {
  const map = new Map<number, T>();

  for (const element of array) {
    map.set(element.id, element);
  }

  return map;
};

export default mapElementsById;
