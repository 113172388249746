import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { common } from '@mui/material/colors';
import { alpha } from '@mui/material';
import { OmitFramerProps, HTMLDivProps } from '@/lib/types';

const StyledOverlay = styled(motion.div)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: alpha(common.black, 0.5),
  display: 'block',
  zIndex: 3,
});

const PageOverlay = (props: OmitFramerProps<HTMLDivProps>) => (
  <StyledOverlay
    initial={{ opacity: 0.5 }}
    animate={{ opacity: 1 }}
    {...props}
  />
);

export default PageOverlay;
