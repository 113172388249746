import isBrowserOutdated from '@/components/App/OutdatedBrowserDetection/isBrowserOutdated';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import ErrorText from './ErrorText';

const OutdatedBrowserMessage = styled(ErrorText)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const OutdatedBrowserDetection = () => {
  if (!isBrowserOutdated()) {
    return null;
  }

  return (
    <OutdatedBrowserMessage>
      <FormattedMessage id="outdated_browser_detection.warning" />
    </OutdatedBrowserMessage>
  );
};

export default OutdatedBrowserDetection;
