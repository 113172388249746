import { Button, ButtonProps } from '@mui/material';
import { MoreHoriz as MoreIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

type ViewOlderLogEntriesButtonProps = Omit<
  ButtonProps,
  'startIcon' | 'children'
>;

const ViewOlderLogEntriesButton = (props: ViewOlderLogEntriesButtonProps) => (
  <Button startIcon={<MoreIcon />} {...props}>
    <FormattedMessage id="portfolio.challenge.log_entries.view_older" />
  </Button>
);

export default ViewOlderLogEntriesButton;
