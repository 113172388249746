import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import { alpha } from '@mui/material';

type StyledShadeProps = {
  width: number;
};

const StyledShade = styled('section')<StyledShadeProps>(
  ({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    backgroundColor: alpha(theme.palette.text.primary, 0.024),
    borderRight: `1px solid ${alpha(theme.palette.text.primary, 0.036)}`,
    boxSizing: 'border-box',
    transition: theme.transitions.simple,
    zIndex: -1,
    [mediaQuery(700)]: {
      borderRight: `2px solid ${alpha(theme.palette.text.primary, 0.36)}`,
    },
  }),
  ({ width }) => {
    const actualWidth = Math.max(width, 0);

    return {
      width: `${actualWidth}%`,
      borderRight: actualWidth === 0 ? 'none !important' : undefined,
    };
  },
  addHighContrastStyles(({ theme }) => ({
    borderRightColor: theme.palette.divider,
  })),
);

export default StyledShade;
