import { useState } from 'react';
import ModelType from '@/lib/constants/challengeModel/ModelType.enum';
import NotResponsiveAlert from './NotResponsiveAlert';
import MoveModeContext from './MoveModeContext';
import TabsWrapper from './TabsWrapper';
import SelectedModelTypeTabs from './SelectedModelTypeTabs';
import MoveModeButton from './MoveModeButton';
import ModelEditorForModelType from './ModelEditorForModelType';
import Space from '@/components/Space';
import ModelsContext from './ModelsContext';
import LoadingSpinnerWithText from '@/components/LoadingSpinnerWithText';
import { FormattedMessage } from 'react-intl';
import { EditorChallengeModels } from '@/lib/types';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

// TODO unify section/phase naming
const ChallengeModelEditor = () => {
  const [selectedModelType, setSelectedModelType] = useState(
    ModelType.CHALLENGE,
  );
  const [inMoveMode, setInMoveMode] = useState(false);

  const [loading, models, setModels] =
    usePolledSwrAsComplexState<EditorChallengeModels>(
      'challenge-models/editor',
    );

  if (loading) {
    return (
      <LoadingSpinnerWithText>
        <FormattedMessage id="editor.challenge_model_editor.loading_message" />
      </LoadingSpinnerWithText>
    );
  }

  return (
    <ModelsContext.Provider value={[models, setModels]}>
      <NotResponsiveAlert />
      <MoveModeContext.Provider value={inMoveMode}>
        <TabsWrapper>
          <SelectedModelTypeTabs
            selectedModelType={selectedModelType}
            setSelectedModelType={setSelectedModelType}
          />
          <MoveModeButton onClick={() => setInMoveMode(!inMoveMode)} />
        </TabsWrapper>
        <ModelEditorForModelType selectedModelType={selectedModelType} />
      </MoveModeContext.Provider>
      <Space height={10} />
    </ModelsContext.Provider>
  );
};

export default ChallengeModelEditor;
