const getInitialCollapsedState = (
  localStorageValue: string | null,
): boolean => {
  if (localStorageValue === null) {
    return true;
  }

  return localStorageValue === 'true';
};

const getCollapsedPortfolioItemsCache = (
  localStorageKeyPrefix: string,
): Record<string, boolean> => {
  // TODO would be faster with a Map
  const collapsedPortfolioItemsCache: Record<string, boolean> = {};

  for (const key in localStorage) {
    const isIndicatingPortfolioItemCollapsedState = key.startsWith(
      localStorageKeyPrefix,
    );

    if (isIndicatingPortfolioItemCollapsedState) {
      const itemId = key.replace(localStorageKeyPrefix, '');
      collapsedPortfolioItemsCache[itemId] = getInitialCollapsedState(
        localStorage.getItem(key),
      );
    }
  }

  return collapsedPortfolioItemsCache;
};

export default getCollapsedPortfolioItemsCache;
