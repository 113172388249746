import { useTheme } from '@emotion/react';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles';
import useRandomColor from '@/lib/styling/useRandomColor/index';
import { PaletteOptions } from '@mui/material';
import React from 'react';

type RandomColorThemeProviderProps = {
  children: React.ReactNode;
};

const RandomColorThemeProvider = ({
  children,
}: RandomColorThemeProviderProps) => {
  const randomColor = useRandomColor();

  const theme = useTheme();
  const randomColorShade = theme.isDark ? 200 : 600;

  const randomColorTheme = createTheme({
    palette: {
      primary: {
        main: theme.palette.isHighContrast
          ? theme.palette.text.primary
          : randomColor[randomColorShade],
      },
    } as PaletteOptions,
    typography: {
      fontFamily: 'CircularStd',
    },
  });

  return (
    <MuiThemeProvider theme={randomColorTheme}>{children}</MuiThemeProvider>
  );
};

export default RandomColorThemeProvider;
