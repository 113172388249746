import TaskPerspectiveIndicators from './TaskPerspectiveIndicators';
import { useContext, useState } from 'react';
import PerspectiveAttachmentDialog from './PerspectiveAttachmentDialog';
import useApi from '@/lib/api/useApi';
import TaskContext from '../TaskContext';
import ChallengeContext from '../../../Challenge/ChallengeView/ChallengeContext';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import IsInTaskAttachmentViewContext from '@/components/perspectives/IsInTaskAttachmentViewContext';
import { PerspectiveAttachmentState, TaskSummary } from '@/lib/types';

const TaskPerspectives = () => {
  const isInTaskAttachmentView = useContext(IsInTaskAttachmentViewContext);

  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const [challenge, setChallenge] = useContext(ChallengeContext);
  const [task, setTask] = useContext(TaskContext);

  const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const api = useApi();

  const handleSave = async (
    attachedPerspectives: number[],
    attachedPerspectiveSublevels: number[],
  ): Promise<void> => {
    setAttachmentDialogOpen(false);
    setLoading(true);

    const updatedAttachmentState = await api.put<PerspectiveAttachmentState>(
      `/challenge-portfolio/tasks/${task.id}/attached-perspectives`,
      {
        attachedPerspectives,
        attachedPerspectiveSublevels,
      },
    );

    const updatedDirectlyAttachedPerspectives = Object.values(
      updatedAttachmentState.directlyAttachedPerspectives,
    );
    const updatedIndirectlyAttachedPerspectives = Object.values(
      updatedAttachmentState.indirectlyAttachedPerspectives,
    );
    const updatedAttachedPerspectiveSublevels = Object.values(
      updatedAttachmentState.attachedPerspectiveSublevels,
    );

    setTask(task => {
      task.directlyAttachedPerspectives =
        updatedAttachmentState.directlyAttachedPerspectives;
      task.indirectlyAttachedPerspectives =
        updatedAttachmentState.indirectlyAttachedPerspectives;
      task.attachedPerspectiveSublevels =
        updatedAttachmentState.attachedPerspectiveSublevels;
    });

    setChallenge(challenge => {
      challenge.tasks[task.id].directlyAttachedPerspectives =
        updatedDirectlyAttachedPerspectives;
      challenge.tasks[task.id].indirectlyAttachedPerspectives =
        updatedIndirectlyAttachedPerspectives;
      challenge.tasks[task.id].attachedPerspectiveSublevels =
        updatedAttachedPerspectiveSublevels;
    });

    setChallengePortfolio(challengePortfolio => {
      const tasks = challengePortfolio.challenges[challenge.id]
        .tasks as TaskSummary[];
      const taskIndex = tasks.findIndex(({ id }) => id === task.id);

      tasks[taskIndex].directlyAttachedPerspectives =
        updatedDirectlyAttachedPerspectives;
      tasks[taskIndex].indirectlyAttachedPerspectives =
        updatedIndirectlyAttachedPerspectives;
      tasks[taskIndex].attachedPerspectiveSublevels =
        updatedAttachedPerspectiveSublevels;
    });

    setLoading(false);
  };

  if (isInTaskAttachmentView) {
    return <TaskPerspectiveIndicators />;
  }

  return (
    <>
      <TaskPerspectiveIndicators
        onManage={() => setAttachmentDialogOpen(true)}
        loading={loading}
      />
      <PerspectiveAttachmentDialog
        open={attachmentDialogOpen}
        onClose={() => setAttachmentDialogOpen(false)}
        onSave={handleSave}
      />
    </>
  );
};

export default TaskPerspectives;
