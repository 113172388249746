import { IconButtonProps } from '@mui/material';
import MobileCoachFeedbackButton from './MobileCoachFeedbackButton';
import { AddCommentOutlined as AddFeedbackIcon } from '@mui/icons-material';

type AddFeedbackButtonProps = Omit<IconButtonProps, 'children'>;

const AddFeedbackButton = (props: AddFeedbackButtonProps) => (
  <MobileCoachFeedbackButton {...props}>
    <AddFeedbackIcon />
  </MobileCoachFeedbackButton>
);

export default AddFeedbackButton;
