import styled from '@emotion/styled';
import ButtonBase from '@/components/ButtonBase';
import { HTMLButtonProps } from '@/lib/types';
import mediaQuery from '@/lib/styling/mediaQuery';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';
import { alpha } from '@mui/material';

type NormalUserSwitchArrowProps = HTMLButtonProps & {
  disabled: boolean;
  right?: boolean;
};

const NormalUserSwitchArrow = styled(ButtonBase)<NormalUserSwitchArrowProps>(
  ({ theme, right }) => ({
    position: 'fixed',
    top: '50%',
    left: right ? 'unset' : 30,
    right: right ? 30 : 'unset',
    transform: 'translateY(-50%)',
    margin: 0,
    padding: '16px 20px',
    textAlign: 'center',
    color: 'transparent',
    borderRadius: 4,
    transition: theme.transitions.create('all'),
    ':not(:disabled):hover, :focus': {
      color: alpha(theme.palette.text.primary, 0.6),
      backgroundColor: theme.palette.background.shaded,
    },
    [mediaQuery(1024)]: {
      display: 'none',
    },
  }),
  ({ disabled }) => {
    if (disabled) {
      return {
        opacity: 0.5,
      };
    }
  },
  addHighContrastStyles(({ disabled }) => {
    if (disabled) {
      return {
        display: 'none',
      };
    }
  }),
  addBorderInHighContrastMode,
);

export default NormalUserSwitchArrow;
