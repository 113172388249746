import React, { useContext } from 'react';
import StyledButton from './StyledButton';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import PhaseContentGroupContext from '../../../../Phase/PhaseContentGroupContext';

type InnerLinkButtonProps = {
  href: string;
  children: React.ReactNode;
};

const InnerLinkButton = ({ href, children }: InnerLinkButtonProps) => {
  const phaseContentGroup = useContext(PhaseContentGroupContext);

  return (
    <StyledButton
      color="secondary"
      fullWidth={phaseContentGroup === ContentGroupType.RIGHT}
      component="a"
      target="_blank"
      href={href}
    >
      {children}
    </StyledButton>
  );
};

export default InnerLinkButton;
