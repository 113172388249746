import { useContext, useState } from 'react';
import HeaderWrapper from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/HeaderWrapper';
import InnerHeaderWrapper from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/InnerHeaderWrapper';
import ChallengeName from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/ChallengeName';
import EditButton from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/EditButton';
import TaskContext from '../TaskContext';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import TaskNameEditor from './TaskNameEditor';
import CloseTaskContext from './CloseTaskContext';
import CopyTask from './CopyTask';
import DeleteTask from './DeleteTask';
import TaskLinkSharing from './TaskLinkSharing';
import MobileTaskActions from './MobileTaskActions';

type TaskHeaderProps = {
  onCloseTask: () => void;
};

const TaskHeader = ({ onCloseTask }: TaskHeaderProps) => {
  const [task] = useContext(TaskContext);

  const [nameEditing, setNameEditing] = useState(false);

  if (nameEditing) {
    return <TaskNameEditor onClose={() => setNameEditing(false)} />;
  }

  return (
    <HeaderWrapper>
      <InnerHeaderWrapper>
        <ChallengeName>{task.name}</ChallengeName>
        <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
          <CloseTaskContext.Provider value={onCloseTask}>
            <MobileTaskActions onEdit={() => setNameEditing(true)} />
            <EditButton onClick={() => setNameEditing(true)} />
            <CopyTask />
            <DeleteTask />
            <TaskLinkSharing />
          </CloseTaskContext.Provider>
        </RestrictedToPortfolioMode>
      </InnerHeaderWrapper>
    </HeaderWrapper>
  );
};

export default TaskHeader;
