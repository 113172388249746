import { useContext } from 'react';
import App from '@/lib/App';
import SelectedPlatformContext from '@/lib/contexts/SelectedPlatformContext';
import { useIntl } from 'react-intl';
import modesWithPlatformDifferences from '@/lib/utils/modesWithPlatformDifferences';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';

const useProductName = (): string => {
  const authenticatedUser = useAuthenticatedUser();
  const selectedMode = App.navigation.useSelectedMode();
  const [selectedPlatform] = useContext(SelectedPlatformContext);

  const intl = useIntl();

  if (
    authenticatedUser === null ||
    selectedPlatform === null ||
    selectedMode === null ||
    !modesWithPlatformDifferences.includes(selectedMode)
  ) {
    return intl.formatMessage({
      id: 'software_name',
    });
  }

  return intl.formatMessage({
    id: `platform_names.${selectedPlatform.toLowerCase()}`,
  });
};

export default useProductName;
