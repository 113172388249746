import styled from '@emotion/styled';
import { HTMLDivProps } from '@/lib/types';

type UserSelectFieldWrapperProps = HTMLDivProps & {
  withMarginRight?: boolean;
};

const UserSelectFieldWrapper = styled('article')<UserSelectFieldWrapperProps>(
  ({ withMarginRight }) => ({
    marginRight: withMarginRight ? 24 : undefined,
    display: 'flex',
    alignItems: 'center',
  }),
);

export default UserSelectFieldWrapper;
