import styled from '@emotion/styled';
import Dialog from '@/components/Dialog';
import PerspectiveIcon from './PerspectiveIcon';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import { Perspective, PerspectiveSublevel } from '@/lib/types';

type PerspectiveNameDialogTitleProps = {
  perspectiveOrSublevel: Perspective | PerspectiveSublevel;
};

const StyledDialogTitle = styled(Dialog.Title)({
  display: 'flex',
  alignItems: 'center',
});

const StyledPerspectiveIcon = styled(PerspectiveIcon)(
  ({ theme }) => ({
    marginRight: 12,
    backgroundColor: theme.palette.action.hover,
    borderColor: 'transparent',
    overflow: 'hidden',
  }),
  addHighContrastStyles(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
  })),
);

const PerspectiveNameDialogTitle = ({
  perspectiveOrSublevel,
}: PerspectiveNameDialogTitleProps) => (
  <StyledDialogTitle>
    {'icon' in perspectiveOrSublevel && perspectiveOrSublevel.icon !== null && (
      <StyledPerspectiveIcon src={perspectiveOrSublevel.icon} size={40} />
    )}
    {perspectiveOrSublevel.name}
  </StyledDialogTitle>
);

export default PerspectiveNameDialogTitle;
