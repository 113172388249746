import { RadioGroup } from '@mui/material';
import ExistingRowOption from './ExistingRowOption';
import NewRowOption from './NewRowOption';
import RowAttachmentType from '../../RowAttachmentType.enum';
import React from 'react';

type RowAttachmentTypeOptionsProps = {
  selectedRowAttachmentType: RowAttachmentType;
  onChangeSelectedRowAttachmentType: (
    selectedRowAttachmentType: RowAttachmentType,
  ) => void;
  children: React.ReactNode;
};

const RowAttachmentTypeOptions = ({
  selectedRowAttachmentType,
  onChangeSelectedRowAttachmentType,
  children,
}: RowAttachmentTypeOptionsProps) => (
  <RadioGroup
    name="row-attachment-type"
    value={selectedRowAttachmentType}
    onChange={e =>
      onChangeSelectedRowAttachmentType(e.target.value as RowAttachmentType)
    }
  >
    {children}
  </RadioGroup>
);

RowAttachmentTypeOptions.ExistingRow = ExistingRowOption;
RowAttachmentTypeOptions.NewRow = NewRowOption;

export default RowAttachmentTypeOptions;
