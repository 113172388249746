import useMediaQuery from '@mui/material/useMediaQuery';

const useNumberOfVisibleLevels = (): number => {
  const numberOfVisibleLevelsEqualsOne = useMediaQuery('(max-width: 500px)');
  const numberOfVisibleLevelsEqualsTwo = useMediaQuery('(max-width: 768px)');

  if (numberOfVisibleLevelsEqualsOne) {
    return 1;
  }

  if (numberOfVisibleLevelsEqualsTwo) {
    return 2;
  }

  return 3;
};

export default useNumberOfVisibleLevels;
