import {
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export type ActionButtonProps = Omit<ListItemButtonProps, 'children'> & {
  icon: React.ReactNode;
  labelId: string;
};

const ActionButton = ({ icon, labelId, ...props }: ActionButtonProps) => (
  <ListItemButton {...props}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={<FormattedMessage id={labelId} />} />
  </ListItemButton>
);

export default ActionButton;
