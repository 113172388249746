import Dialog, { DialogProps } from '@/components/Dialog';

type ColorsManagerDialogProps = Omit<DialogProps, 'PaperProps'>;

const ColorsManagerDialog = (props: ColorsManagerDialogProps) => (
  <Dialog
    PaperProps={{
      style: {
        maxWidth: 480,
      },
    }}
    {...props}
  />
);

export default ColorsManagerDialog;
