import styled from '@emotion/styled';
import { Pagination, PaginationProps } from '@mui/material';

type UserPaginationProps = Omit<PaginationProps, 'onChange'> & {
  onChange: (page: number) => void;
};

const StyledPagination = styled(Pagination)({
  display: 'flex',
  justifyContent: 'center',
});

const UserPagination = ({ onChange, ...props }: UserPaginationProps) => (
  <StyledPagination onChange={(_, page: number) => onChange(page)} {...props} />
);

export default UserPagination;
