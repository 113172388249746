import { Button, ButtonProps } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

type SendButtonProps = Omit<
  ButtonProps,
  'variant' | 'startIcon' | 'disableElevation' | 'children'
>;

const SendButton = (props: SendButtonProps) => (
  <Button
    variant="contained"
    startIcon={<SendIcon />}
    disableElevation
    {...props}
  >
    <FormattedMessage id="support.dialog.feedback_form.send_button" />
  </Button>
);

export default SendButton;
