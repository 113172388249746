import { IconButtonProps } from '@mui/material';
import PrimaryIconButton from './PrimaryIconButton';
import { Close as CloseIcon } from '@mui/icons-material';

const CloseButton = (props: IconButtonProps) => (
  <PrimaryIconButton size="large" edge="start" {...props}>
    <CloseIcon />
  </PrimaryIconButton>
);

export default CloseButton;
