import { useContext } from 'react';
import InfoWrapper from './InfoWrapper';
import CreatedAtWithUserInfo from './CreatedAtWithUserInfo';
import { FormattedMessage } from 'react-intl';
import ChallengeContext from '../ChallengeContext';
import useFormattedCreatedAt from './useFormattedCreatedAt';

const ChallengeInfo = () => {
  // TODO can also be summary!
  const [challenge] = useContext(ChallengeContext);
  const formattedCreatedAt = useFormattedCreatedAt(challenge.createdAt);

  return (
    <InfoWrapper>
      {challenge.author ? (
        <CreatedAtWithUserInfo
          author={challenge.author}
          createdAt={formattedCreatedAt}
        />
      ) : (
        <FormattedMessage
          id="portfolio.challenge.info.created_at"
          values={{
            createdAt: formattedCreatedAt,
          }}
        />
      )}
    </InfoWrapper>
  );
};

export default ChallengeInfo;
