import React, { useState } from 'react';
import ManagerWrapper from './ManagerWrapper';
import ColorForm from './ColorForm';
import ColorPreview from '../ColorPreview';
import Space from '@/components/Space';
import { IconButton } from '@mui/material';
import { Edit as EditIcon, Close as DeleteIcon } from '@mui/icons-material';
import DangerousIconButton from '@/components/DangerousIconButton';

type ColorManagerProps = {
  color: string;
  onUpdate: (updatedColor: string) => void;
  onDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const ColorManager = ({ color, onUpdate, onDelete }: ColorManagerProps) => {
  const [editing, setEditing] = useState(false);

  return (
    <ManagerWrapper>
      {editing ? (
        <ColorForm
          color={color}
          onCancel={() => setEditing(false)}
          onSave={(updatedColor: string): void => {
            onUpdate(updatedColor);

            setEditing(false);
          }}
        />
      ) : (
        <>
          <ColorPreview color={color} />
          <span>{color}</span>
          <Space width="auto" />
          <IconButton onClick={() => setEditing(true)}>
            <EditIcon />
          </IconButton>
          <DangerousIconButton onClick={onDelete}>
            <DeleteIcon />
          </DangerousIconButton>
        </>
      )}
    </ManagerWrapper>
  );
};

export default ColorManager;
