import { useContext } from 'react';
import StyledTasksButton from './StyledTasksButton';
import TasksIconButton from './TasksIconButton';
import {
  ButtonProps,
  IconButtonProps,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { ViewColumn as TasksIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import ChallengeContext from '../../ChallengeContext';

type TasksButtonProps = Omit<
  ButtonProps | IconButtonProps,
  'color' | 'children'
>;

const TasksButton = (props: TasksButtonProps) => {
  const [challenge] = useContext(ChallengeContext);

  const shouldUseShortLabel = useMediaQuery('(max-width: 1024px)');
  const shouldUseIconButton = useMediaQuery('(max-width: 880px)');

  const taskCount = Object.keys(challenge.tasks).length;

  if (shouldUseIconButton) {
    return (
      <Tooltip
        title={
          <FormattedMessage
            id="portfolio.challenge.tasks_button"
            values={{
              taskCount,
            }}
          />
        }
      >
        <TasksIconButton {...props}>
          <TasksIcon />
        </TasksIconButton>
      </Tooltip>
    );
  }

  return (
    <StyledTasksButton color="secondary" {...props}>
      <FormattedMessage
        id={
          shouldUseShortLabel
            ? 'portfolio.challenge.tasks_button.short'
            : 'portfolio.challenge.tasks_button'
        }
        values={{
          taskCount,
        }}
      />
    </StyledTasksButton>
  );
};

export default TasksButton;
