import { useContext } from 'react';
import FieldName from '../utils/FieldName';
import NotSharedText from './NotSharedText';
import InviteUserForm from './InviteUserForm';
import ParticipatingUsers from './ParticipatingUsers';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import ChallengeContext from '../../../../Challenge/ChallengeView/ChallengeContext';
import IsAnonymizedContext from '@/pages/OpenChallengePage/IsAnonymizedContext';
import AnonymizedParticipatingUsers from './AnonymizedParticipatingUsers';

// TODO use FieldRendererContext? (also in taskassignees -- consistency!)
// TODO better NotSharedText translation (coach mode/public version)
const ChallengeSharing = () => {
  const [challenge] = useContext(ChallengeContext);
  const isAnonymized = useContext(IsAnonymizedContext);

  const challengeIsShared = challenge.usersCount > 1;

  return (
    <section>
      <FieldName />
      {challengeIsShared && !isAnonymized && <ParticipatingUsers />}
      {challengeIsShared && isAnonymized && <AnonymizedParticipatingUsers />}
      {!challengeIsShared && <NotSharedText />}
      <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
        <InviteUserForm />
      </RestrictedToPortfolioMode>
    </section>
  );
};

export default ChallengeSharing;
