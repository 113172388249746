import { useContext } from 'react';
import PhaseContentGroupContext from '../../../../PhaseContentGroupContext';
import FieldContext from '../../../FieldContext';
import useCurrentFieldPhaseMoving from './useCurrentFieldPhaseMoving';
import useMoveCurrentFieldToPhaseContentGroup from './useMoveCurrentFieldToPhaseContentGroup';
import useSwapPositionsWithField from './useSwapPositionsWithField';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';

const useFieldMoving = (
  allHighestPositions: Record<ContentGroupType, number>,
  lowestFieldPositionInPhaseBottomContent: number,
): [() => Promise<void>, () => Promise<void>] => {
  const currentPhaseContentGroup = useContext(PhaseContentGroupContext);
  const { previousField, nextField } = useContext(FieldContext);

  const [
    moveCurrentFieldToPreviousPhase,
    moveCurrentFieldToNextPhase,
  ] = useCurrentFieldPhaseMoving();

  const moveCurrentFieldToPhaseContentGroup = useMoveCurrentFieldToPhaseContentGroup(
    allHighestPositions,
    lowestFieldPositionInPhaseBottomContent,
  );

  const swapPositionsWithField = useSwapPositionsWithField();

  const moveUp = async (): Promise<void> => {
    if (
      !previousField &&
      currentPhaseContentGroup !== ContentGroupType.BOTTOM
    ) {
      await moveCurrentFieldToPreviousPhase();
      return;
    }

    if (!previousField) {
      await moveCurrentFieldToPhaseContentGroup(ContentGroupType.LEFT);
      return;
    }

    await swapPositionsWithField(previousField);
  };

  const moveDown = async (): Promise<void> => {
    if (!nextField && currentPhaseContentGroup === ContentGroupType.BOTTOM) {
      await moveCurrentFieldToNextPhase();
      return;
    }

    if (!nextField) {
      await moveCurrentFieldToPhaseContentGroup(ContentGroupType.BOTTOM);
      return;
    }

    await swapPositionsWithField(nextField);
  };

  return [moveUp, moveDown];
};

export default useFieldMoving;
