import { useContext } from 'react';
import FieldContext from '../../../../FieldContext';
import defaultColors from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/FieldRenderer/TypeSpecificFieldRenderers/ColorPicker/PickerContent/defaultColors';
import ColorBoxesWrapper from './ColorBoxesWrapper';
import ColorBox from './ColorBox';

type ColorBoxesProps = {
  selectedColor: string;
  onSelectColor: (color: string) => void;
};

type ColorPickerConfig = {
  colors?: string[];
};

const ColorBoxes = ({ selectedColor, onSelectColor }: ColorBoxesProps) => {
  const field = useContext(FieldContext);
  const colorOptions =
    (field.additionalConfig as ColorPickerConfig).colors ?? defaultColors;

  if (!Array.isArray(colorOptions)) {
    return null;
  }

  return (
    <ColorBoxesWrapper>
      {(colorOptions as string[]).map(color => (
        <ColorBox
          color={color}
          selected={color === selectedColor}
          onClick={() => onSelectColor(color)}
          key={color}
        />
      ))}
    </ColorBoxesWrapper>
  );
};

export default ColorBoxes;
