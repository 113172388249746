import React, { useState } from 'react';
import { DateCalendar } from '@mui/x-date-pickers';
import { Button, Popover } from '@mui/material';
import DateDisplay from '../DateDisplay';
import { FormattedMessage } from 'react-intl';
import ActionsWrapper from './ActionsWrapper';
import Space from '@/components/Space';
import moment, { Moment } from 'moment';
import { DatePickerProps } from '..';

type DesktopDatePickerProps = DatePickerProps & {
  onClear: () => void;
};

const DesktopDatePicker = ({
  value,
  onChange,
  onSave,
  onClear,
  shouldDisableDate,
  clearable,
  disabled,
}: DesktopDatePickerProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isEmpty = !value.isValid();
  const safeValue = isEmpty ? moment() : value;

  const handleClear = (): void => {
    onClear();
    setAnchorEl(null);
  };

  const handleSave = (): void => {
    onSave(value);
    setAnchorEl(null);
  };

  return (
    <div>
      <DateDisplay
        date={value}
        onOpen={(e: React.MouseEvent<HTMLButtonElement>) =>
          setAnchorEl(e.currentTarget)
        }
        disabled={disabled}
      />
      <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <DateCalendar
          value={safeValue}
          onChange={(value: Moment | null): void => {
            if (value !== null) {
              onChange(value);
            }
          }}
          shouldDisableDate={shouldDisableDate}
          disabled={disabled}
        />
        <ActionsWrapper>
          {clearable && !isEmpty && (
            <>
              <Button onClick={handleClear} disabled={disabled}>
                <FormattedMessage id="dialogs.actions.clear" />
              </Button>
              <Space width="auto" />
            </>
          )}
          <Button onClick={handleSave} disabled={disabled}>
            <FormattedMessage id="dialogs.actions.save" />
          </Button>
        </ActionsWrapper>
      </Popover>
    </div>
  );
};

export default DesktopDatePicker;
