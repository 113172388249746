import ContentWrapper from './ContentWrapper';
import SelectWrapper from './SelectWrapper';
import PromptGroupSelect, {
  PromptGroupSelectProps,
} from '@/pages/EditorPage/RubricsPortfolioTemplatesEditor/PortfolioTemplatesEditor/PortfolioItemWithGroupsPrompt/PromptGroupSelect';
import ClearButton from './ClearButton';
import React from 'react';

type PromptGroupSelectWithClearButtonProps = PromptGroupSelectProps & {
  onClear: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

// TODO verify this still works well with updated prompt
const PromptGroupSelectWithClearButton = ({
  onClear,
  ...props
}: PromptGroupSelectWithClearButtonProps) => {
  const showClearButton = props.value.length > 0;

  return (
    <ContentWrapper>
      <SelectWrapper>
        <PromptGroupSelect {...props} />
      </SelectWrapper>
      {showClearButton && <ClearButton onClick={onClear} />}
    </ContentWrapper>
  );
};

export default PromptGroupSelectWithClearButton;
