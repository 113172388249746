import TaskGroupWrapper from './TaskGroupWrapper';
import useAttachedTasks from './useAttachedTasks';
import PerspectiveNameDialogTitle from '../PerspectiveNameDialogTitle';
import TaskWrapper from './TaskWrapper';
import InfoMessage from './InfoMessage';
import ModelsContext from '@/lib/contexts/ModelsContext';
import useModels from '@/components/ChallengePortfolio/useModels';
import AttachedTask from './AttachedTask';
import IsInTaskAttachmentViewContext from '../IsInTaskAttachmentViewContext';
import TaskCount from './TaskCount';
import LoadingView from './LoadingView';
import { FormattedMessage } from 'react-intl';
import { AttachmentInfo, Perspective, PerspectiveSublevel } from '@/lib/types';

type AttachedTasksViewProps = {
  attachmentInfo: AttachmentInfo;
  perspective: Perspective;
  sublevel?: PerspectiveSublevel;
};

const AttachedTasksView = ({
  attachmentInfo,
  perspective,
  sublevel,
}: AttachedTasksViewProps) => {
  const {
    loading: tasksLoading,
    setTasks,
    directlyAttachedTasks,
    indirectlyAttachedTasks,
  } = useAttachedTasks(attachmentInfo);

  const [modelsLoading, models] = useModels();

  return (
    <IsInTaskAttachmentViewContext.Provider value={true}>
      <PerspectiveNameDialogTitle
        perspectiveOrSublevel={sublevel ?? perspective}
      />
      {tasksLoading || modelsLoading ? (
        <LoadingView />
      ) : (
        <ModelsContext.Provider value={models}>
          {directlyAttachedTasks.length > 0 && (
            <TaskGroupWrapper>
              <h3>
                <FormattedMessage id="perspectives.attached_tasks.directly_attached_tasks.title" />
                <TaskCount>{directlyAttachedTasks.length}</TaskCount>
              </h3>
              <TaskWrapper>
                {directlyAttachedTasks.map(task => (
                  <AttachedTask
                    task={task}
                    setTasks={setTasks}
                    inSublevelTree={!!sublevel}
                    key={task.id}
                  />
                ))}
              </TaskWrapper>
            </TaskGroupWrapper>
          )}
          {indirectlyAttachedTasks.length > 0 && (
            <TaskGroupWrapper>
              <h3>
                <FormattedMessage id="perspectives.attached_tasks.indirectly_attached_tasks.title" />
                <TaskCount>{indirectlyAttachedTasks.length}</TaskCount>
                <InfoMessage>
                  <FormattedMessage id="perspectives.attached_tasks.indirectly_attached_tasks.info_message" />
                </InfoMessage>
              </h3>
              <TaskWrapper>
                {indirectlyAttachedTasks.map(task => (
                  <AttachedTask
                    task={task}
                    setTasks={setTasks}
                    inSublevelTree={!!sublevel}
                    key={task.id}
                  />
                ))}
              </TaskWrapper>
            </TaskGroupWrapper>
          )}
        </ModelsContext.Provider>
      )}
    </IsInTaskAttachmentViewContext.Provider>
  );
};

export default AttachedTasksView;
