import { FormControl, InputLabel, Select } from '@mui/material';
import { useIntl } from 'react-intl';
import { SelectProps } from '@/lib/types';

type PhaseSelectFieldProps<T> = Omit<SelectProps<T>, 'id' | 'label'>;

function PhaseSelectField<T>(props: PhaseSelectFieldProps<T>) {
  const intl = useIntl();
  const label = intl.formatMessage({
    id: 'portfolio.challenge.phase_select.label',
  });

  return (
    <FormControl style={{ flex: 1 }}>
      <InputLabel htmlFor="phase-select">{label}</InputLabel>
      <Select id="phase-select" label={label} {...props} />
    </FormControl>
  );
}

export default PhaseSelectField;
