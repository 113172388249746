import { useContext } from 'react';
import VisibleWeekRangeContext from '../../../../VisibleWeekRangeContext';
import LinesWrapper from './LinesWrapper';
import BackgroundLineWithSpacing from './BackgroundLineWithSpacing';

const WeekMarkingBackgroundLines = () => {
  const { visibleWeekRangeStartDate, visibleWeeksCount } = useContext(
    VisibleWeekRangeContext,
  );

  return (
    <LinesWrapper key={visibleWeekRangeStartDate.toString()}>
      {new Array(visibleWeeksCount).fill(null).map((_, i) => (
        <BackgroundLineWithSpacing key={i} />
      ))}
    </LinesWrapper>
  );
};

export default WeekMarkingBackgroundLines;
