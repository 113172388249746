import { useState } from 'react';
import readImageAsDataUrl from './readImageAsDataUrl';
import useLogoUrl from '@/lib/utils/useLogoUrl';

type UploadLogoState =
  | [null, string, (uploadedFiles: File[]) => Promise<void>, false]
  | [File, string, (uploadedFiles: File[]) => Promise<void>, true];

const useUploadedLogoState = (): UploadLogoState => {
  const currentLogoUrl = useLogoUrl();

  const [logo, setLogo] = useState<File | null>(null);
  const [logoAsDataUrl, setLogoAsDataUrl] = useState<string | null>(null);

  const updateLogo = async (uploadedFiles: File[]) => {
    const logoFile = uploadedFiles[0];

    if (!logoFile) {
      return;
    }

    setLogo(logoFile);

    const logoAsDataUrl = await readImageAsDataUrl(logoFile);
    setLogoAsDataUrl(logoAsDataUrl);
  };

  const logoHasChanged = logo !== null;

  if (logoHasChanged) {
    return [logo, logoAsDataUrl ?? currentLogoUrl ?? '', updateLogo, true];
  }

  return [logo, logoAsDataUrl ?? currentLogoUrl ?? '', updateLogo, false];
};

export default useUploadedLogoState;
