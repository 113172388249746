import { useContext } from 'react';
import DialogManagerContext from '@/lib/contexts/DialogManagerContext';
import FocusLock from 'react-focus-lock';
import { ReactFocusLockProps } from 'react-focus-lock/interfaces';

type FocusLockWithoutDialogFocusFightingProps = Omit<
  ReactFocusLockProps,
  'disabled' | 'returnFocus'
>;

const FocusLockWithoutDialogFocusFighting = (
  props: FocusLockWithoutDialogFocusFightingProps,
) => {
  const { isAnyDialogOpen } = useContext(DialogManagerContext);

  // TODO FocusLock is giving too many issues with dialogs; re-enable when ready
  return (
    <FocusLock disabled={true || isAnyDialogOpen} returnFocus {...props} />
  );
};

export default FocusLockWithoutDialogFocusFighting;
