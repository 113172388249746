import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import useTaskPhaseDrop from './useTaskPhaseDrop';
import PhaseColumn from '../../ChallengeBoard/KanbanPhaseColumn/PhaseColumn';
import PhaseContext from '../../PhaseContext';
import CreateTask from './CreateTask';
import Task from '../../Task';
import ResponsiveMasonry from '../../ChallengeBoard/KanbanPhaseColumn/ResponsiveMasonry';
import { TaskSummary } from '@/lib/types';
import sortByCreatedAt from '@/lib/utils/sorting/sortByCreatedAt';

type KanbanPhaseColumnProps = {
  phase: KanbanPhase;
  tasks: TaskSummary[];
};

const KanbanPhaseColumn = ({ phase, tasks }: KanbanPhaseColumnProps) => {
  const attachDropHandlingRef = useTaskPhaseDrop(phase);

  return (
    <PhaseContext.Provider value={phase}>
      <PhaseColumn
        hasItems={tasks.length > 0}
        ref={attachDropHandlingRef}
        inTaskBoard
      >
        <ResponsiveMasonry widthDenominator={550}>
          {tasks.sort(sortByCreatedAt).map(task => (
            <Task task={task} key={task.id} />
          ))}
        </ResponsiveMasonry>
        {phase === KanbanPhase.TODO && <CreateTask />}
      </PhaseColumn>
    </PhaseContext.Provider>
  );
};

export default KanbanPhaseColumn;
