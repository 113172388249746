import ActionsWrapper from './ActionsWrapper';
import { IconButton } from '@mui/material';
import { Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';
import React from 'react';

type LevelEditorActionsProps = {
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSave: (event: React.MouseEvent<HTMLButtonElement>) => void;
  primaryButtons?: boolean;
  disabled: boolean;
};

const LevelEditorActions = ({
  onClose,
  onSave,
  primaryButtons,
  disabled,
}: LevelEditorActionsProps) => (
  <ActionsWrapper primaryButtons={primaryButtons ?? false} disabled={disabled}>
    <IconButton onClick={onClose} disabled={disabled}>
      <CloseIcon />
    </IconButton>
    <IconButton onClick={onSave} disabled={disabled}>
      <CheckIcon />
    </IconButton>
  </ActionsWrapper>
);

export default LevelEditorActions;
