/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import DialogManagerContext from '@/lib/contexts/DialogManagerContext';
import ResponsiveDialog from './ResponsiveDialog';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogProps as MuiDialogProps,
} from '@mui/material';
import DialogActions from './DialogActions';

export type DialogProps = MuiDialogProps & {
  open: boolean;
  onEnterClose?: (event: KeyboardEvent) => void;
  disableEnterClose?: boolean;
  dangerous?: boolean;
};

const Dialog = ({ open, ...props }: DialogProps) => {
  const [dialogKey] = useState(`dialog-${Math.random()}`);
  const { markDialogAsOpen, markDialogAsClosed } = useContext(
    DialogManagerContext,
  );

  useEffect(() => {
    if (open) {
      markDialogAsOpen(dialogKey);
      return;
    }

    markDialogAsClosed(dialogKey);
  }, [open]);

  return <ResponsiveDialog open={open} {...props} />;
};

Dialog.Title = DialogTitle;
Dialog.Content = DialogContent;
Dialog.ContentText = DialogContentText;
Dialog.Actions = DialogActions;

export default Dialog;
