import ActionButton, { ActionButtonProps } from './ActionButton';
import { Link as LinkIcon } from '@mui/icons-material';

type LinkSharingButtonProps = Omit<ActionButtonProps, 'icon' | 'labelId'>;

const LinkSharingButton = (props: LinkSharingButtonProps) => (
  <ActionButton
    icon={<LinkIcon />}
    labelId="portfolio.challenge.drawer.link_sharing.button"
    {...props}
  />
);

export default LinkSharingButton;
