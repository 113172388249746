import { common, grey, lightBlue, purple } from '@mui/material/colors';
import { PaletteOptions, lighten } from '@mui/material';

const darkHighContrastPalette = {
  isHighContrast: true,
  mode: 'dark',
  primary: {
    main: common.white,
  },
  secondary: {
    main: common.white,
  },
  error: {
    main: common.white,
  },
  warning: {
    main: common.white,
  },
  danger: common.white,
  background: {
    default: common.black,
    shaded: grey[900],
    border: common.white,
    paper: grey[800],
  },
  sliders: {
    student: lightBlue[100],
    coach: purple[400],
    both: common.white,
  },
  partFocusColor: common.white,
  partFocusTextColor: common.black,
  text: {
    primary: common.white,
    secondary: common.white,
    disabled: common.white,
  },
  divider: common.white,
  increaseContrastWithBackground: (
    color: string,
    coefficient: number,
  ): string => {
    return lighten(color, coefficient);
  },
} satisfies PaletteOptions;

export default darkHighContrastPalette;
