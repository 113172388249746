import React, { useContext } from 'react';
import FormWrapper from './FormWrapper';
import OptionNameTextField from './OptionNameTextField';
import { IconButton } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import AdditionalConfigStateContext from '../../../../AdditionalConfigStateContext';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

type OptionNameEditorProps = {
  option: {
    key: string;
    name: string;
  };
  onClose: () => void;
};

const OptionNameEditor = ({ option, onClose }: OptionNameEditorProps) => {
  const [, setAdditionalConfig] = useContext(AdditionalConfigStateContext);
  const [newOptionName, setNewOptionName, , reenableNewOptionNameSync] =
    useComplexSwrState(option.name);

  const saveNameChange = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (newOptionName.trim().length === 0) {
      return;
    }

    setAdditionalConfig(additionalConfig => {
      additionalConfig.options![option.key].name = newOptionName.trim();
    });

    onClose();

    reenableNewOptionNameSync();
  };

  const cancelNameChange = (): void => {
    onClose();
    setNewOptionName(option.name);

    reenableNewOptionNameSync();
  };

  return (
    <FormWrapper onSubmit={saveNameChange}>
      <OptionNameTextField
        value={newOptionName}
        onChange={e => setNewOptionName(e.target.value)}
        onEscPress={cancelNameChange}
      />
      <IconButton type="submit" disabled={!newOptionName.trim()}>
        <CheckIcon />
      </IconButton>
    </FormWrapper>
  );
};

export default OptionNameEditor;
