import { EditorState, RichUtils, SelectionState } from 'draft-js';
import {
  getSelectedBlock,
  getSelectionEntity,
  getEntityRange,
  getSelectionText,
} from 'draftjs-utils';
import selectionContainsAnyLinks from './selectionContainsAnyLinks';
import ToolbarButton from '../../ToolbarButton';
import { LinkOff as RemoveLinkIcon } from '@mui/icons-material';

type RemoveLinkProps = {
  editorState: EditorState;
  setEditorState: (newEditorState: EditorState) => void;
  focusOnEditor: () => void;
};

const RemoveLink = ({
  editorState,
  setEditorState,
  focusOnEditor,
}: RemoveLinkProps) => {
  const removeLinkFromSelection = (): void => {
    setEditorState(
      RichUtils.toggleLink(editorState, editorState.getSelection(), null),
    );

    focusOnEditor();
  };

  const removeLinkFromCurrentEntity = (): void => {
    const currentBlock = getSelectedBlock(editorState);
    const currentEntityKey = getSelectionEntity(editorState);
    const currentEntityRange = getEntityRange(editorState, currentEntityKey);

    const emptySelection = SelectionState.createEmpty(currentBlock.key);
    const currentEntitySelection = emptySelection.merge({
      anchorOffset: currentEntityRange.start,
      focusOffset: currentEntityRange.end,
    });

    setEditorState(
      RichUtils.toggleLink(editorState, currentEntitySelection, null),
    );

    focusOnEditor();
  };

  const removeLink = (): void => {
    const selectionText = getSelectionText(editorState);
    const hasSelection = selectionText.length > 0;

    if (hasSelection) {
      removeLinkFromSelection();

      return;
    }

    removeLinkFromCurrentEntity();
  };

  const hasAnyLinksSelected = selectionContainsAnyLinks(
    editorState,
    editorState.getSelection(),
  );

  if (!hasAnyLinksSelected) {
    return null;
  }

  return (
    <ToolbarButton
      label="remove_link"
      icon={RemoveLinkIcon}
      onClick={removeLink}
    />
  );
};

export default RemoveLink;
