import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { Paper } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';

const ResponsiveDialogPaper = styled(Paper, {
  shouldForwardProp: isPropValid,
})(({ theme }) => ({
  margin: 24,
  width: '100%',
  maxHeight: 'calc(100% - 48px)',
  overflowX: 'hidden',
  transition: theme.transitions.create('all'),
  [mediaQuery(420)]: {
    margin: 12,
    maxHeight: 'calc(100% - 24px)',
  },
}));

export default ResponsiveDialogPaper;
