import { useContext } from 'react';
import SelectedSchoolContext from '../SelectedSchoolContext';
import IdentityConnectionProvider from '@/lib/constants/IdentityConnectionProvider.enum';
import GoogleLogin from './GoogleLogin';
import MicrosoftLogin from './MicrosoftLogin';

type LoginButtonWithHandlersProps = {
  onShowError: (error: unknown) => void;
  disabled: boolean;
};

const LoginButtonWithHandlers = ({
  onShowError,
  disabled,
}: LoginButtonWithHandlersProps) => {
  const selectedSchool = useContext(SelectedSchoolContext);

  const authProvider = selectedSchool.instanceConfig.authProvider;

  if (authProvider === IdentityConnectionProvider.MICROSOFT) {
    return <MicrosoftLogin onShowError={onShowError} disabled={disabled} />;
  }

  return <GoogleLogin onShowError={onShowError} disabled={disabled} />;
};

export default LoginButtonWithHandlers;
