import styled from '@emotion/styled';

const StyledIcon = styled('img')({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  height: 24,
});

export default StyledIcon;
