import StyledFeedbackItemFooter from './StyledFeedbackItemFooter';
import FeedbackItemDate from './FeedbackItemDate';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import EditFeedbackItem from './EditFeedbackItem';
import DeleteFeedbackItem from './DeleteFeedbackItem';
import { CoachComment } from '@/lib/types';

// TODO FeedbackItem in code should be renamed to CoachComment
type FeedbackItemFooterProps = {
  feedbackItem: CoachComment;
};

const FeedbackItemFooter = ({ feedbackItem }: FeedbackItemFooterProps) => (
  <StyledFeedbackItemFooter>
    <FeedbackItemDate createdAt={feedbackItem.createdAt} />
    <RestrictedToPortfolioMode mode={PortfolioMode.COACH}>
      <EditFeedbackItem feedbackItem={feedbackItem} />
      <DeleteFeedbackItem feedbackItem={feedbackItem} />
    </RestrictedToPortfolioMode>
  </StyledFeedbackItemFooter>
);

export default FeedbackItemFooter;
