/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import useTask from './useTask';
import ChallengeViewCardWithPageOverlay from '../../Challenge/ChallengeView/ChallengeViewCardWithPageOverlay';
import ChallengeContentWrapper from '../../Challenge/ChallengeView/ChallengeContentWrapper';
import TaskContext from './TaskContext';
import TaskHeader from './TaskHeader';
import TaskInfo from './TaskInfo';
import MobilePhaseSelect from './MobilePhaseSelect';
import TaskFields from './TaskFields';
import TaskContentLoadingSpinner from './TaskContentLoadingSpinner';
import useMarkCoachCommentsAsReadOnLoad from './useMarkCoachCommentsAsReadOnLoad';
import TaskPerspectives from './TaskPerspectives';
import { Task, TaskSummary } from '@/lib/types';

type TaskViewProps = {
  task: TaskSummary;
  onClose: () => void;
};

// TODO put TaskInfo/ChallengeInfo in afterload block?
const TaskView = ({ task: taskSummary, onClose }: TaskViewProps) => {
  const [loading, task, setTask] = useTask(taskSummary);

  useMarkCoachCommentsAsReadOnLoad(taskSummary, loading);

  // TODO verify this works properly (for mobilephaseselect)
  useEffect(() => {
    if (loading) {
      return;
    }

    setTask(task => {
      task.phase = taskSummary.phase;
    });
  }, [loading, taskSummary.phase]);

  return (
    <ChallengeViewCardWithPageOverlay onClose={onClose}>
      <TaskContext.Provider
        value={[task ?? (taskSummary as unknown as Task), setTask]}
      >
        <ChallengeContentWrapper>
          <TaskHeader onCloseTask={onClose} />
          <TaskInfo />
          {loading ? (
            <TaskContentLoadingSpinner />
          ) : (
            <>
              <TaskPerspectives />
              <MobilePhaseSelect />
              <TaskFields />
            </>
          )}
        </ChallengeContentWrapper>
      </TaskContext.Provider>
    </ChallengeViewCardWithPageOverlay>
  );
};

export default TaskView;
