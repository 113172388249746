import { useContext } from 'react';
import SelectedSchoolContext from './SelectedSchoolContext';

const useSelectedSchool = () => {
  const [selectedSchool] = useContext(SelectedSchoolContext);

  return selectedSchool;
};

export default useSelectedSchool;
