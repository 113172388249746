import UserWrapper from './UserWrapper';
import UserAvatar from '@/components/UserAvatar';
import Name from './Name';
import OriginalOwnerIndicator from './OriginalOwnerIndicator';
import { UserSummary } from '@/lib/types';

type UserProps = {
  user: UserSummary;
  isOriginalOwner: boolean;
};

// TODO confirm username=>user migration successful
const User = ({ user, isOriginalOwner }: UserProps) => (
  <UserWrapper>
    <UserAvatar user={user} size={28} marginRight={8} />
    <Name>{user.name}</Name>
    {isOriginalOwner && <OriginalOwnerIndicator />}
  </UserWrapper>
);

export default User;
