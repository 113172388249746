import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import PhaseContentGroupContext from './PhaseContentGroupContext';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import React from 'react';
import { HTMLDivProps } from '@/lib/types';

type PhaseLeftContentProps = HTMLDivProps & {
  children: React.ReactNode;
};

const StyledLeftContent = styled('div')({
  marginRight: 96,
  flex: 1,
  [mediaQuery(720)]: {
    marginRight: 56,
  },
  [mediaQuery(640)]: {
    marginRight: 0,
  },
});

const PhaseLeftContent = (props: PhaseLeftContentProps) => (
  <PhaseContentGroupContext.Provider value={ContentGroupType.LEFT}>
    <StyledLeftContent {...props} />
  </PhaseContentGroupContext.Provider>
);

export default PhaseLeftContent;
