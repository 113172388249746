import React, { useContext } from 'react';
import FieldContext from '../../FieldContext';
import FieldNameWrapper from '../utils/FieldName/FieldNameWrapper';
import StyledFieldName from '../utils/FieldName/StyledFieldName';
import InfoButton from '../utils/FieldName/FieldInfo/InfoButton';

type CustomFieldNameProps = {
  onInfoButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const CustomFieldName = ({ onInfoButtonClick }: CustomFieldNameProps) => {
  const field = useContext(FieldContext);

  return (
    <FieldNameWrapper>
      <StyledFieldName>{field.name}</StyledFieldName>
      <InfoButton onClick={onInfoButtonClick} />
    </FieldNameWrapper>
  );
};

export default CustomFieldName;
