import { RefObject, useState } from 'react';
import App from '@/lib/App';
import getProgressFromMousePosition from './getProgressFromMousePosition';
import { MouseMoveEvent } from '../SliderRail';

type SliderState = {
  sliding: boolean;
  progress: number | null;
  handleMouseMove: (event: MouseMoveEvent) => void;
  handleMouseMoveActivate: (event: MouseMoveEvent) => void;
  handleMouseMoveDeactivate: (event: MouseMoveEvent) => void;
};

const useSliderState = (
  readOnly: boolean,
  sliderRef: RefObject<HTMLElement>,
): SliderState => {
  const reversed = App.instanceConfig.useSetting('useReversedSliders');

  const [sliding, setSliding] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);

  const recomputeProgress = (event: MouseMoveEvent): void => {
    if (readOnly) {
      return;
    }

    const newProgress = getProgressFromMousePosition(
      event,
      reversed,
      sliderRef,
    );

    if (newProgress !== null) {
      setProgress(newProgress);
    }
  };

  const handleMouseMoveActivate = (event: MouseMoveEvent): void => {
    const isLeftButton = !('button' in event) || event.button === 0;

    if (isLeftButton && !sliding) {
      setSliding(true);
    }
  };

  const handleMouseMoveDeactivate = (event: MouseMoveEvent): void => {
    if (sliding) {
      setSliding(false);
      recomputeProgress(event);
    }
  };

  const handleMouseMove = (event: MouseMoveEvent): void => {
    if (sliding) {
      recomputeProgress(event);
    }
  };

  return {
    sliding,
    progress,
    handleMouseMove,
    handleMouseMoveActivate,
    handleMouseMoveDeactivate,
  };
};

export default useSliderState;
