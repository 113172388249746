const randomLocalStorageJwts = {
  '5GZxOr6EreBjEhxZBMVlcacKpafa3rZp_CACHE_0':
    'eyJhbGciOiJIUzI1NiJ9.NUdaeE9yNkVyZUJqRWh4WkJNVmxjYWNLcGFmYTNyWnBfQ0FDSEVfMA.VdxYItMDU_NSrCgwTtGiUq9GQam4GnmHjphPqfxWNq0',
  aorUfHq0YJonp8mDpSWjRiAsO5Bk8qXU_CACHE_0:
    'NI+LhyVRsvtLJaZHXpn/0wSsvx5F9DkhwDd5V2dpTVWAWFLj2Kr0b0b3uXN25IJYqjK0ALwSi6AbRW9zplVmWY45OlJFxl5qlCSJXQfT6veRszOeJv/tGe/nYlLpHrwWRLpZlDtvXgjN/n1ASpnt1sEz9J4ro8xH6kspYErc8yA=',
  U47VTeHVNdL6sE1YEkR5x5ZsBDCkSgqB_CACHE_1:
    'eyJhbGciOiJIUzI1NiJ9.VTQ3VlRlSFZOZEw2c0UxWUVrUjV4NVpzQkRDa1NncUJfQ0FDSEVfMQ.zSR-s3Hpi-vNxE0masU8DjGt2J0hpErydR9GbhJjU14',
  pawoqPtk42O2DqCHGHiQAoRfouKVuCKq_CACHE_0:
    'eyJhbGciOiJIUzI1NiJ9.cGF3b3FQdGs0Mk8yRHFDSEdIaVFBb1Jmb3VLVnVDS3FfQ0FDSEVfMA.XT9LykVFhfNKsd8VvZgjEpnC4aR3y0XJDRAA1FuutAs',
  WoAmTfMypWxty4LXkAACeUjgAXOsCWZ4_CACHE_0:
    'eyJhbGciOiJIUzI1NiJ9.V29BbVRmTXlwV3h0eTRMWGtBQUNlVWpnQVhPc0NXWjRfQ0FDSEVfMA.kG7dsnqsuLut0-JBukVq5mBx77dCcD9xCSoTCtpY6-4',
  baJYE8ydTvxXBhaIxfgv2NgH3uslwaV2_CACHE_0:
    'eyJhbGciOiJIUzI1NiJ9.YmFKWUU4eWRUdnhYQmhhSXhmZ3YyTmdIM3VzbHdhVjJfQ0FDSEVfMA.u__enLmV28b73b9DuhfjUnCE_ifavq5ydBUKYI5bZbs',
  W9tFisTb6Oc4Cs3RlO5RRrbb03nZh9aR_CACHE_0:
    'xwNe0whvkk2FVRA4rbgnI5Czvqz2S+MR351qCLwc9JqqlfKqOGR6EnJ3h9U4ep8wn3gatKxUzjQxHYb7cSiiPmcqI94PapmbFwsUfb8hrJzZDSEmNGumRp9s/kfr9aQlyNY1uBEYW75NmkfBfY+iFr75KiXuc3LwTi/89/KG9a4=',
  w0iZBI1Gjlj89SKAgxbPUh1CFo3boJ8C_CACHE_1:
    'eyJhbGciOiJIUzI1NiJ9.dzBpWkJJMUdqbGo4OVNLQWd4YlBVaDFDRm8zYm9KOENfQ0FDSEVfMQ.VWGnjhL9qSRuwdEMWB46Ky5SVYXnmuzkdvZ5rvtACUI',
  vsbsqYQ4Sqow1462FirFNdjFhlihLYjN_CACHE_0:
    'eyJhbGciOiJIUzI1NiJ9.dnNic3FZUTRTcW93MTQ2MkZpckZOZGpGaGxpaExZak5fQ0FDSEVfMA.OXARIllmkaxV6yNuLvXrS2EZZ8H7b1ttFHqH3rG_WAY',
  Be1ZujeL3Ireq844R62mg3hVdNdvhABg_CACHE_0:
    'eyJhbGciOiJIUzI1NiJ9.QmUxWnVqZUwzSXJlcTg0NFI2Mm1nM2hWZE5kdmhBQmdfQ0FDSEVfMA.FJzQ892KBtXLeNfkpVFYnKQ7Dgjh2Xwz-9g-TkJy9e4',
  mxqKsGWRSS60qRGpHqsaH4MTg58Fab6o_CACHE_0:
    'eyJhbGciOiJIUzI1NiJ9.bXhxS3NHV1JTUzYwcVJHcEhxc2FINE1UZzU4RmFiNm9fQ0FDSEVfMA.6-nH71P-5EWqAI1ILjKTJd_ajz2Hd745NfYPyBTGCuE',
  kDsYXcXNzNtG1Cd0bygm54DDm9HYjCOY_CACHE_1:
    'eyJhbGciOiJIUzI1NiJ9.a0RzWVhjWE56TnRHMUNkMGJ5Z201NEREbTlIWWpDT1lfQ0FDSEVfMQ.wJubO3z4WCrNPrnxDznRy8ZbPVN9-g8zXX6hg4UGZKY',
  TvdYKJnsoffhcUTn024KKICmREy0AtXL_CACHE_0:
    'eyJhbGciOiJIUzI1NiJ9.VHZkWUtKbnNvZmZoY1VUbjAyNEtLSUNtUkV5MEF0WExfQ0FDSEVfMA.wUsS7s8sEBcz9uLZ-xH9awIZMeqcArWh5P6g05ZBVas',
  HYRSKavtq2qpVyh1e3gCpKdLe4NivTjB_CACHE_0:
    'sD8c+r9nchpY/IEmF4HBAsQPn/mDnYX775Xmwcd53CkL6DdePKp73yN/60q3IceZae//XcASMhhmgQgsd6hKSlwje5PdRu9d9walJmSBALG5uuq5xe2pRAZTEDYOMaW/M3p6uGH+7SQNQfbtu/BMuKFG809Q/pOZM3KsvGBeD8Q=',
  E7ZGQZSXHG3rTl6OSGM41watYNRZbgNI_CACHE_0:
    'eyJhbGciOiJIUzI1NiJ9.RTdaR1FaU1hIRzNyVGw2T1NHTTQxd2F0WU5SWmJnTklfQ0FDSEVfMA.B2nQpeSsCRtoaaLPSDAx_XkdFOsg5CRLRl_Bqmz_wUY',
  '9IWIfz1sanz3m0dJribGAtIOuptDbRu8_CACHE_0':
    'eyJhbGciOiJIUzI1NiJ9.OUlXSWZ6MXNhbnozbTBkSnJpYkdBdElPdXB0RGJSdThfQ0FDSEVfMA.OkTcGoq-ppj3DILEZ3cU0KVmf4trOhxpsaCnxYfrqeI',
  XxpoWLDgl6HYG1TBYbZOuZL0qL4xnT7e_CACHE_0:
    'eyJhbGciOiJIUzI1NiJ9.WHhwb1dMRGdsNkhZRzFUQlliWk91WkwwcUw0eG5UN2VfQ0FDSEVfMA.F-0esssexXFrvh7-jGrBidrTwRgBETtR86TRMI_KLE4',
  BxAOboVtbCxHZbOu3m5wEKyUGmR5z8zG_CACHE_0:
    'eyJhbGciOiJIUzI1NiJ9.QnhBT2JvVnRiQ3hIWmJPdTNtNXdFS3lVR21SNXo4ekdfQ0FDSEVfMA.MleNfXmOnyXROBhSDsJEyICcNHjsE03011ieZPEGams',
  hq7renScYfEHDcOM8D4svUdxrLW4JErM_CACHE_1:
    'oZIDPGsvzwIqTsj5T0ocvUhEoIhypPS2VwcS97ZcA9N6Or7wcK7mKiFLOPgTajG3yh9J70haqm4H7dJJznqux5pzimlNIhiOoxs6+HjpQi4CGNESABmByy0cZTfI41EHzxSwpGMSmG8BBaT3JdEJ1l/t6dQEixR7oaGSVOf8Noo=',
  VwY9JXVJ39pOIzPU9bAtopemzaiDiFoe_CACHE_1:
    '+Vommw83MfFmZNDNGyMH3fQgCgru7TZfeqX1tK9xKUQNeThClqkJBq/J1a1owcVBIf2R5J96bn19mWB9NFHa+MJGOgte+1YDwzDllQgmQZ9YKrA7C53dTCEbNS6wTf01vrS326ST//w7fzbAGTs2NztzuHj1zQsrpEQq4ypVbO0=',
} as const;

export default randomLocalStorageJwts;
