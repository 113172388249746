import styled from '@emotion/styled';

type StyledIconProps = {
  reversed: boolean;
  noTransition: boolean;
};

const StyledIcon = styled('img')<StyledIconProps>(
  ({ theme, reversed, noTransition }) => ({
    position: 'absolute',
    left: reversed ? 0 : 'unset',
    right: reversed ? 'unset' : 0,
    transform: reversed ? 'translateX(-50%)' : 'translateX(50%)',
    width: 50,
    height: 50,
    transition: noTransition ? 'none' : theme.transitions.create('all'),
    display: 'block',
    zIndex: 3,
    userDrag: 'none',
    userSelect: 'none',
  }),
);

export default StyledIcon;
