import { useState } from 'react';
import Space from '@/components/Space';
import { Button } from '@mui/material';
import { HelpOutline as SupportIcon } from '@mui/icons-material';
import SupportDialog from '@/components/App/SupportSystem/SupportDialog';
import { FormattedMessage } from 'react-intl';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';

const ReportViaSupportSystem = () => {
  const [supportDialogOpen, setSupportDialogOpen] = useState(false);

  const authenticatedUser = useAuthenticatedUser();

  const isLoggedIn = !!authenticatedUser;
  if (!isLoggedIn) {
    return null;
  }

  return (
    <>
      <Space height={12} />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setSupportDialogOpen(true)}
        startIcon={<SupportIcon />}
      >
        <FormattedMessage id="error_boundary.report.support_button" />
      </Button>
      <SupportDialog
        open={supportDialogOpen}
        onClose={() => setSupportDialogOpen(false)}
      />
    </>
  );
};

export default ReportViaSupportSystem;
