import { useContext } from 'react';
import WHITE from './PickerContent/defaultColors';
import FieldRendererContext from '../../../FieldRendererContext';

const usePortfolioItemColor = (): string => {
  const { portfolioItem } = useContext(FieldRendererContext);

  return (portfolioItem.color ?? WHITE).toString().toUpperCase();
};

export default usePortfolioItemColor;
