import { useNavigate, useParams } from 'react-router-dom';
import useApiResponse from '@/lib/api/useApiResponse';
import NotFoundView from '../OpenChallengePage/NotFoundView';
import PublicPortfolioItemPageTitle from '../OpenChallengePage/PublicPortfolioItemPageTitle';
import ModelsContext from '@/lib/contexts/ModelsContext';
import ChallengeContext from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeContext';
import VisiblePhaseContext from '@/components/ChallengePortfolio/VisiblePhaseContext';
import AccessStateSetup from '../OpenChallengePage/AccessStateSetup';
import IsAnonymizedContext from '../OpenChallengePage/IsAnonymizedContext';
import CustomTaskView from './CustomTaskView';
import TaskLoadingView from './TaskLoadingView';
import { Challenge, SetComplexImmerStateAction, Task } from '@/lib/types';
import AccessLevel from '@/lib/constants/AccessLevel.enum';
import dummyFunction from '@/lib/utils/dummyFunction';
import { PublicChallengeModels } from '../OpenChallengePage';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

type PublicTask =
  | { not_found: true }
  | (Task & {
      not_found: false;
      accessLevel: AccessLevel;
    });

const OpenTaskPage = () => {
  const navigate = useNavigate();
  const { uuid: taskUuid } = useParams();

  const [modelsLoading, models] = useApiResponse<PublicChallengeModels>(
    `challenge-models/public/for-task/${taskUuid}`,
  );
  const [taskLoading, task, setTask] = usePolledSwrAsComplexState<PublicTask>(
    `challenge-portfolio/tasks/public/${taskUuid}`,
  );

  if (modelsLoading || taskLoading) {
    return <TaskLoadingView />;
  }

  if (models.not_found || task.not_found) {
    return <NotFoundView forTask />;
  }

  const challenge = task.challenge;
  const setChallenge = dummyFunction;

  return (
    <>
      <PublicPortfolioItemPageTitle translationId="open_challenge.page_title.task" />
      <ModelsContext.Provider value={models}>
        <ChallengeContext.Provider
          value={[challenge as unknown as Challenge, setChallenge]}
        >
          <VisiblePhaseContext.AutomatedProvider>
            <AccessStateSetup accessLevel={task.accessLevel}>
              <IsAnonymizedContext.Provider value={true}>
                <CustomTaskView
                  task={task}
                  setTask={
                    setTask as unknown as SetComplexImmerStateAction<Task>
                  }
                  onClose={() => navigate('/')}
                />
              </IsAnonymizedContext.Provider>
            </AccessStateSetup>
          </VisiblePhaseContext.AutomatedProvider>
        </ChallengeContext.Provider>
      </ModelsContext.Provider>
    </>
  );
};

export default OpenTaskPage;
