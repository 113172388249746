import styled from '@emotion/styled';
import { alpha } from '@mui/material';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';

const StyledNewlySharedInfo = styled('div')(
  ({ theme }) => ({
    marginBottom: 12,
    padding: 12,
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    borderRadius: 4,
  }),
  addHighContrastStyles({
    backgroundColor: 'transparent',
  }),
  addBorderInHighContrastMode,
);

export default StyledNewlySharedInfo;
