const formatVisibleLevelCountString = (
  visibleLevelIndex: number,
  // TODO rename to visibleLevels?
  numberOfVisibleLevels: number,
): string => {
  const visibleLevelCount = visibleLevelIndex + 1;

  const shouldDisplayVisibleLevelCountAsRange = numberOfVisibleLevels > 1;

  if (!shouldDisplayVisibleLevelCountAsRange) {
    return visibleLevelCount.toString();
  }

  return `${visibleLevelCount}-${visibleLevelIndex + numberOfVisibleLevels}`;
};

export default formatVisibleLevelCountString;
