import mediaQuery from '@/lib/styling/mediaQuery';
import styled from '@emotion/styled';
import React from 'react';

type ChallengeWrapperProps = {
  children: NonNullable<React.ReactNode>;
};

const StyledChallengeWrapper = styled('div')({
  paddingTop: 12,
  paddingBottom: 18,
  paddingLeft: 16,
  paddingRight: 16,
  display: 'flex',
  flexWrap: 'wrap',
  '> *': {
    margin: 4,
    minWidth: 150,
    maxWidth: 150,
  },
  [mediaQuery(640)]: {
    paddingLeft: 4,
    paddingRight: 4,
  },
});

const ChallengeWrapper = ({ children }: ChallengeWrapperProps) => (
  <StyledChallengeWrapper>{children}</StyledChallengeWrapper>
);

export default ChallengeWrapper;
