import styled from '@emotion/styled';
import InputWithEscListener from '@/components/InputWithEscListener';

const FieldNameInput = (styled(InputWithEscListener)({
  flex: 1,
  fontFamily: 'CircularStd',
  fontWeight: 500,
}) as unknown) as typeof InputWithEscListener;

export default FieldNameInput;
