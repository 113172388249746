import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import stripProps from '@/lib/utils/stripProps';
import { IconButton, IconButtonProps, PaletteOptions } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import mediaQuery from '@/lib/styling/mediaQuery';
import { common } from '@mui/material/colors';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import { useTheme } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { OmitFramerProps } from '@/lib/types';

type CloseButtonProps = Omit<
  OmitFramerProps<IconButtonProps>,
  'color' | 'size' | 'children'
> & {
  taskBoardOpen: boolean;
};

type StyledCloseButtonProps = IconButtonProps & {
  taskBoardOpen: boolean;
};

const StyledCloseButton = styled(
  motion(stripProps(IconButton, 'taskBoardOpen')),
)<StyledCloseButtonProps>(
  ({ theme, taskBoardOpen }) => ({
    position: 'absolute',
    top: 0,
    left: 102,
    // This additional forced-white override is necessary for the high-contrast
    // version of the light theme
    color: `${common.white} !important`,
    transition: theme.transitions.create('all'),
    [mediaQuery(960)]: {
      left: 32,
    },
    [mediaQuery(768)]: {
      top: 8,
      left: taskBoardOpen ? 8 : undefined,
    },
    [mediaQuery(560)]: {
      left: 8,
    },
  }),
  addHighContrastStyles({
    backgroundColor: common.black,
    border: `2px solid ${common.white}`,
    ':hover, :focus': {
      backgroundColor: common.black,
      transform: 'scale(1.05) !important',
    },
  }),
);

const CloseButton = (props: CloseButtonProps) => {
  const theme = useTheme();
  const whiteTheme = theme.extend({
    palette: {
      primary: {
        main: common.white,
      },
    } as PaletteOptions,
  });

  return (
    <MuiThemeProvider theme={whiteTheme}>
      <StyledCloseButton
        color="primary"
        size="large"
        initial={{
          x: 10,
          opacity: 0,
        }}
        animate={{
          x: 0,
          opacity: 1,
          transition: {
            duration: 0.1,
            delay: 0.3,
          },
        }}
        {...props}
      >
        <CloseIcon />
      </StyledCloseButton>
    </MuiThemeProvider>
  );
};

export default CloseButton;
