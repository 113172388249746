/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useComplexState from './useComplexState';
import { DraftFunction, Updater } from 'use-immer';

type StateSyncOptions = {
  stringifyChangeCheck?: boolean;
};

// TODO lots of different custom state types, maybe come up with better naming
// TODO unify this with useSyncedState, with `disableSyncOnChange` option
// TODO rename to useSyncedState?
const useComplexSwrState = <T>(
  initialValue: T,
  options: StateSyncOptions = {},
): [T, Updater<T>, boolean, () => void] => {
  const [value, setValue] = useComplexState(initialValue);
  const [syncEnabled, setSyncEnabled] = useState(true);

  useEffect(() => {
    if (syncEnabled) {
      setValue(initialValue);
    }
  }, [
    options.stringifyChangeCheck ? JSON.stringify(initialValue) : initialValue,
  ]);

  const changeValue = (updatesOrUpdateFunction: T | DraftFunction<T>): void => {
    setSyncEnabled(false);

    setValue(updatesOrUpdateFunction);
  };

  // TODO: also call setValue(initialValue)?
  const reenableSync = () => {
    setSyncEnabled(true);
  };

  const hasChanged = !syncEnabled;

  return [value, changeValue, hasChanged, reenableSync];
};

export default useComplexSwrState;
