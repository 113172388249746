import useActivePlatformIcons from '@/lib/utils/useActivePlatformIcons';
import ContentWrapper from './ContentWrapper';
import PlatformIcon from './PlatformIcon';
import MessageWrapper from './MessageWrapper';
import LoadingSpinner from '@/components/LoadingSpinner';
import Space from '@/components/Space';
import PlatformType from '@/lib/constants/PlatformType.enum';
import React from 'react';

type PlatformLoadingViewProps = {
  platform: PlatformType;
  loadingMessage: React.ReactNode;
};

const PlatformLoadingView = ({
  platform,
  loadingMessage,
}: PlatformLoadingViewProps) => {
  const activePlatformIcons = useActivePlatformIcons();

  return (
    <ContentWrapper>
      <PlatformIcon src={activePlatformIcons[platform]} alt="" />
      <MessageWrapper>
        <LoadingSpinner />
        <Space width={12} />
        {loadingMessage}
      </MessageWrapper>
    </ContentWrapper>
  );
};

export default PlatformLoadingView;
