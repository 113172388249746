import styled from '@emotion/styled';
import { Popover, PopoverProps } from '@mui/material';

const PopoverWithWideMenu = styled(Popover)({
  '.MuiPopover-paper': {
    paddingTop: 8,
    paddingBottom: 8,
    width: 320,
  },
});

const WideMenu = (props: PopoverProps) => (
  <PopoverWithWideMenu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      horizontal: 'right',
      vertical: 'top',
    }}
    {...props}
  />
);

export default WideMenu;
