import getSortedVisibleUsers from './getSortedVisibleUsers';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import useApiResponse from '@/lib/api/useApiResponse';
import { User } from '@/lib/types';

type UsersState = {
  all: Record<number, User>;
  visible: User[];
  previous: User | null;
  next: User | null;
};

const useUsers = (
  selectedUser: User | null,
  selectedGroupId: number | null,
): [true, null] | [false, UsersState] => {
  const authenticatedUser = useAuthenticatedUser()!;
  const [loading, users] = useApiResponse<Record<number, User>>('users');

  if (loading) {
    return [true, null];
  }

  const visibleUsers = getSortedVisibleUsers(
    users,
    selectedGroupId,
    authenticatedUser,
  );

  const selectedUserIndex = selectedUser
    ? visibleUsers.findIndex(user => selectedUser.email === user.email)
    : null;

  const previousUser =
    selectedUserIndex === null ? null : visibleUsers[selectedUserIndex - 1];
  const nextUser =
    selectedUserIndex === null ? null : visibleUsers[selectedUserIndex + 1];

  return [
    false,
    {
      all: users,
      visible: visibleUsers,
      previous: previousUser,
      next: nextUser,
    },
  ];
};

export default useUsers;
