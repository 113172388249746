/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import PortfolioUserContext from '@/lib/contexts/PortfolioUserContext';
import { weekNumber } from 'weeknumber';

const useGapminderState = (
  weeksPerYear: Record<string, number[]>,
): [number, (year: number) => void, number, (week: number) => void] => {
  const currentYear = new Date().getFullYear();
  const currentWeek = weekNumber(new Date()) + 1;

  const [selectedYear, setSelectedYear] = useState(currentYear);

  const updateSelectedYear = (year: number): void => {
    const weeks = weeksPerYear[year];
    const firstWeek = weeks[0];

    setSelectedYear(year);
    setSelectedWeek(firstWeek);
  };

  const [selectedWeek, setSelectedWeek] = useState(currentWeek);

  const resetGapminder = () => {
    setSelectedYear(currentYear);
    setSelectedWeek(currentWeek);
  };

  const portfolioUser = useContext(PortfolioUserContext);

  useEffect(() => {
    resetGapminder();
  }, [portfolioUser?.email]);

  return [selectedYear, updateSelectedYear, selectedWeek, setSelectedWeek];
};

export default useGapminderState;
