import styled from '@emotion/styled';

const ProofWrapper = styled('section')({
  marginTop: 'auto',
  marginLeft: -6,
  marginRight: -6,
  marginBottom: -12,
  textAlign: 'center',
});

export default ProofWrapper;
