import { useContext } from 'react';
import ChallengeContext from '../../../../Challenge/ChallengeView/ChallengeContext';
import StyledText from './NotSharedText/StyledText';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

const AnonymizedParticipatingUsers = () => {
  const [challenge] = useContext(ChallengeContext);

  return (
    <StyledText>
      <FormattedMessageWithChallengeNaming
        id="portfolio.challenge.sharing.anonymized_users_count"
        values={{
          challengeUsersCount: challenge.usersCount,
        }}
      />
    </StyledText>
  );
};

export default AnonymizedParticipatingUsers;
