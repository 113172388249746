import React, { useContext } from 'react';
import FieldContext from './FieldContext';
import FieldType from '@/lib/constants/challengeModel/FieldType.enum';
import TextField from './TypeSpecificFieldRenderers/TextField';
import DateField from './TypeSpecificFieldRenderers/DateField';
import SelectField from './TypeSpecificFieldRenderers/SelectField';
import LogEntries from './TypeSpecificFieldRenderers/LogEntries';
import LinkButton from './TypeSpecificFieldRenderers/LinkButton';
import Rubrics from './TypeSpecificFieldRenderers/Rubrics';
import PlannedStartDate from './TypeSpecificFieldRenderers/PortfolioItemDates/PlannedStartDate';
import PlannedEndDate from './TypeSpecificFieldRenderers/PortfolioItemDates/PlannedEndDate';
import StartDate from './TypeSpecificFieldRenderers/PortfolioItemDates/StartDate';
import EndDate from './TypeSpecificFieldRenderers/PortfolioItemDates/EndDate';
import ChallengeSharing from './TypeSpecificFieldRenderers/ChallengeSharing';
import TaskAssignment from './TypeSpecificFieldRenderers/TaskAssignment';
import WorldSelect from './TypeSpecificFieldRenderers/WorldSelect';
import ColorPicker from './TypeSpecificFieldRenderers/ColorPicker';

const FieldRenderersForFieldType = {
  [FieldType.TEXT]: TextField,
  [FieldType.DATE]: DateField,
  [FieldType.SELECT]: SelectField,
  [FieldType.LOGS]: LogEntries,
  [FieldType.LINK_BUTTON]: LinkButton,
  [FieldType.RUBRICS]: Rubrics,
  [FieldType.MANUAL_PLANNED_START_DATE]: PlannedStartDate,
  [FieldType.MANUAL_PLANNED_END_DATE]: PlannedEndDate,
  [FieldType.MANUAL_ACTUAL_START_DATE]: StartDate,
  [FieldType.MANUAL_ACTUAL_END_DATE]: EndDate,
  [FieldType.MANUAL_CHALLENGE_SHARING]: ChallengeSharing,
  [FieldType.MANUAL_ASSIGNEES]: TaskAssignment,
  [FieldType.MANUAL_AGORA_WORLDS]: WorldSelect,
  [FieldType.MANUAL_COLOR_PICKER]: ColorPicker,
} as const;

const IndividualFieldRenderer = () => {
  const field = useContext(FieldContext);
  const FieldRenderer = ((FieldRenderersForFieldType as unknown) as Record<
    FieldType,
    React.ElementType
  >)[field.type];

  if (!FieldRenderer) {
    return null;
  }

  return <FieldRenderer />;
};

export default IndividualFieldRenderer;
