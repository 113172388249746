import { Button, ButtonProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const SaveButton = (props: ButtonProps) => (
  <Button variant="contained" disableElevation {...props}>
    <FormattedMessage id="platform_settings.save" />
  </Button>
);

export default SaveButton;
