import VisibleWeekRangeContext from './VisibleWeekRangeContext';
import LatestVisibleWeekRangeShiftDirectionContext from './LatestVisibleWeekRangeShiftDirectionContext';
import PlanningHeader from './PlanningHeader';
import RowsWithChallengePlanning from './RowsWithChallengePlanning';

// TODO given planning view's limited usage, can it be removed?
const PlanningView = () => (
  <VisibleWeekRangeContext.AutomatedProvider>
    <LatestVisibleWeekRangeShiftDirectionContext.AutomatedProvider>
      <PlanningHeader />
      <RowsWithChallengePlanning />
    </LatestVisibleWeekRangeShiftDirectionContext.AutomatedProvider>
  </VisibleWeekRangeContext.AutomatedProvider>
);

export default PlanningView;
