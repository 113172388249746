import styled from '@emotion/styled';
import { Add as UnstyledAddIcon } from '@mui/icons-material';
import computeLevelTitleColor from '@/components/RubricsPortfolioComponents/StyledLevel/computeLevelTitleColor';

const AddIcon = styled(UnstyledAddIcon)(({ theme }) => ({
  marginBottom: 12,
  padding: 6,
  color: theme.palette.getContrastText(computeLevelTitleColor(theme)),
  backgroundColor: computeLevelTitleColor(theme),
  borderRadius: '50%',
}));

export default AddIcon;
