import usePerspectivesEnabled from '@/lib/utils/usePerspectivesEnabled';
import { useContext } from 'react';
import ChallengeContext from './ChallengeContext';
import PerspectiveIndicators from '../../perspectives/PerspectiveIndicators';
import useChallengeAttachedPerspectives from '../ChallengeCard/ChallengeCardPerspectiveIndicators/useChallengeAttachedPerspectives';

const ChallengePerspectiveIndicators = () => {
  const perspectivesEnabled = usePerspectivesEnabled();
  const [challenge] = useContext(ChallengeContext);

  const attachedPerspectives = useChallengeAttachedPerspectives(challenge);

  if (!perspectivesEnabled || attachedPerspectives.length === 0) {
    return null;
  }

  return <PerspectiveIndicators perspectives={attachedPerspectives} />;
};

export default ChallengePerspectiveIndicators;
