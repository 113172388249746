import { useContext } from 'react';
import ModelTypeContext from '../../../ModelTypeContext';
import PhaseContext from '../../PhaseContext';
import ModelsContext from '../../../../ModelsContext';
import useApi from '@/lib/api/useApi';
import { EditorSection } from '@/lib/types';

const usePhaseMoving = (): [() => void, () => void] => {
  const api = useApi();

  const modelType = useContext(ModelTypeContext);
  const [, setModels] = useContext(ModelsContext);
  const { phase, previousPhase, nextPhase } = useContext(PhaseContext);

  const swapPositionsWithPhase = async (
    phaseToSwapWith: EditorSection,
  ): Promise<void> => {
    await api.post(
      `editor/challenge-model/sections/${phase.id}/position/swap/${phaseToSwapWith.id}`,
    );

    setModels(models => {
      models[modelType].sections[phase.id].position = phaseToSwapWith.position;
      models[modelType].sections[phaseToSwapWith.id].position = phase.position;
    });
  };

  const moveUp = (): void => {
    if (previousPhase !== null) {
      swapPositionsWithPhase(previousPhase);
    }
  };

  const moveDown = (): void => {
    if (nextPhase !== null) {
      swapPositionsWithPhase(nextPhase);
    }
  };

  return [moveUp, moveDown];
};

export default usePhaseMoving;
