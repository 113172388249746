import styled from '@emotion/styled';

type YearIndicatorProps = {
  year: number;
};

const YearLabel = styled('b')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
  transition: theme.transitions.create(['color']),
}));

const YearIndicator = ({ year }: YearIndicatorProps) => (
  <>
    {' '}
    &ndash; <YearLabel>{year}</YearLabel>
  </>
);

export default YearIndicator;
