import { useContext, useState } from 'react';
import CollapsibleActionMenu from '@/components/CollapsibleActionMenu';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import FieldContext from '../../FieldContext';
import ModelTypeContext from '../../../../ModelTypeContext';
import ModelsContext from '../../../../../ModelsContext';
import useApi from '@/lib/api/useApi';

const DeleteField = () => {
  const api = useApi();

  const modelType = useContext(ModelTypeContext);
  const [, setModels] = useContext(ModelsContext);
  const { field } = useContext(FieldContext);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteField = async (): Promise<void> => {
    setConfirmationDialogOpen(false);
    setDeleting(true);

    await api.delete(`editor/challenge-model/fields/${field!.id}`);

    setModels(models => {
      // TODO shouldn't this also remove reference from parent section?
      delete models[modelType].fields[field!.id];
    });

    setDeleting(false);
  };

  return (
    <>
      <CollapsibleActionMenu.DeleteButton
        onClick={() => setConfirmationDialogOpen(true)}
        disabled={deleting}
      >
        <FormattedMessage id="editor.model_editor.actions.delete" />
      </CollapsibleActionMenu.DeleteButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.field_editor.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="editor.model_editor.field_editor.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteField} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeleteField;
