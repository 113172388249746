import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const LevelSelectArrowsWrapper = styled('section')({
  marginTop: -12,
  marginBottom: 24,
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  [mediaQuery(900)]: {
    display: 'flex',
  },
  [mediaQuery(560)]: {
    display: 'flex',
  },
  [mediaQuery(500)]: {
    justifyContent: 'space-between',
  },
});

export default LevelSelectArrowsWrapper;
