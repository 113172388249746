import getLanguage from '@/lib/utils/getLanguage';
import {
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import FlagIcon from '../FlagIcon';
import { FormattedMessage } from 'react-intl';

const LanguagePickerButton = (props: Omit<ListItemButtonProps, 'children'>) => {
  const selectedLanguage = getLanguage();

  return (
    <ListItemButton {...props}>
      <ListItemIcon>
        <FlagIcon language={selectedLanguage} />
      </ListItemIcon>
      <ListItemText
        primary={
          <FormattedMessage id="header.options_menu.options.language_picker" />
        }
      />
    </ListItemButton>
  );
};

export default LanguagePickerButton;
