import React, { useRef, useEffect } from 'react';
import StyledForm from './StyledForm';
import StyledTextField from './StyledTextField';
import { IconButton, TextFieldProps } from '@mui/material';
import { PersonAdd as AddUserIcon } from '@mui/icons-material';
import validator from 'validator';

type InnerFormProps = Omit<
  TextFieldProps,
  'variant' | 'error' | 'helperText' | 'inputRef' | 'disabled' | 'fullWidth'
> & {
  value: string;
  error: React.ReactNode | null;
  onInvite: (event: React.FormEvent<HTMLFormElement>) => void;
  disabled: boolean;
};

const InnerForm = ({ error, onInvite, disabled, ...props }: InnerFormProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (error && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [error]);

  const valueIsValid =
    props.value.trim().length > 0 && validator.isEmail(props.value);

  return (
    <StyledForm onSubmit={onInvite}>
      <StyledTextField
        variant="standard"
        error={!!error}
        helperText={error}
        inputRef={inputRef}
        disabled={disabled}
        fullWidth
        {...props}
      />
      <IconButton type="submit" disabled={!valueIsValid || disabled}>
        <AddUserIcon />
      </IconButton>
    </StyledForm>
  );
};

export default InnerForm;
