import TitleWrapper from './TitleWrapper';
import DialogTitle from './DialogTitle';
import TitleSubtopicIcon from './TitleSubtopicIcon';
import CloseDialogButton from './CloseDialogButton';
import React from 'react';

type TitleSubtopicIconProps = {
  subtopicIcon?: string;
  onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: React.ReactNode;
};

const TitleWithSubtopicIcon = ({
  subtopicIcon,
  onClose,
  children,
}: TitleSubtopicIconProps) => (
  <TitleWrapper>
    {subtopicIcon && <TitleSubtopicIcon src={subtopicIcon} alt="" />}
    <DialogTitle>{children}</DialogTitle>
    <CloseDialogButton onClick={onClose} />
  </TitleWrapper>
);

export default TitleWithSubtopicIcon;
