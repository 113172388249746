import { createContext } from 'react';

type RowDeletionState = {
  deleteRow: () => Promise<void>;
  rowDeleting: boolean;
  startRowDeletion: () => void;
  cancelRowDeletion: () => void;
};

const RowDeletionContext = createContext<RowDeletionState>({
  deleteRow: async () => {},
  rowDeleting: false,
  startRowDeletion: () => {},
  cancelRowDeletion: () => {},
});

export default RowDeletionContext;
