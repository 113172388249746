import ScreenWrapper from './ScreenWrapper';
import ErrorReporting from './ErrorReporting';
import ErrorScreenFooter from './ErrorScreenFooter';
import ErrorTitle from './ErrorTitle';
import ErrorText from './ErrorText';
import { FormattedMessage } from 'react-intl';
import OutdatedBrowserDetection from './OutdatedBrowserDetection';
import RefreshButton from './RefreshButton';
import LogoutButton from './LogoutButton';

type ErrorScreenProps = {
  error: string;
  errorComponentStack: string;
};

const ErrorScreen = ({ error, errorComponentStack }: ErrorScreenProps) => (
  <>
    <ScreenWrapper>
      <ErrorTitle />
      <ErrorText>
        <FormattedMessage id="error_boundary.text" />
      </ErrorText>
      <OutdatedBrowserDetection />
      <RefreshButton />
      <LogoutButton />
      <ErrorReporting error={error} errorComponentStack={errorComponentStack} />
    </ScreenWrapper>
    <ErrorScreenFooter />
  </>
);

export default ErrorScreen;
