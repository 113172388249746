import SettingsBoxWrapper from './SettingsBoxWrapper';
import SettingsBoxTitle from './SettingsBoxTitle';
import SettingsBoxIcon from './SettingsBoxIcon';
import React from 'react';

type SettingsBoxProps = {
  title: React.ReactNode;
  icon: React.ReactNode;
  children: React.ReactNode;
};

const SettingsBox = ({ title, icon, children }: SettingsBoxProps) => (
  <SettingsBoxWrapper>
    <SettingsBoxTitle>
      <SettingsBoxIcon>{icon}</SettingsBoxIcon>
      {title}
    </SettingsBoxTitle>
    {children}
  </SettingsBoxWrapper>
);

export default SettingsBox;
