import styled from '@emotion/styled';

const SelectWrapper = styled('section')({
  flex: 1,
  '.MuiFormControl-root, .MuiSkeleton-root': {
    width: '100% !important',
  },
  '.MuiSkeleton-root': {
    marginTop: 16,
  },
});

export default SelectWrapper;
