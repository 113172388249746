import StyledDeleteButton from './StyledDeleteButton';
import { ListItemIcon } from '@mui/material';
import StyledDeleteIcon from './StyledDeleteIcon';
import { ActionMenuButtonProps } from '../ActionMenuButton';

const ActionMenuDeleteButton = ({
  children,
  ...props
}: ActionMenuButtonProps) => (
  <StyledDeleteButton {...props}>
    <ListItemIcon>
      <StyledDeleteIcon />
    </ListItemIcon>
    {children}
  </StyledDeleteButton>
);

export default ActionMenuDeleteButton;
