import { Helmet } from 'react-helmet';
import useProductName from '@/lib/utils/useProductName';

const PageTitleTemplate = () => {
  const productName = useProductName();

  return <Helmet titleTemplate={`%s | ${productName}`} />;
};

export default PageTitleTemplate;
