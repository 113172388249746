import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const UserWrapper = styled('article')(({ theme }) => ({
  marginBottom: 12,
  paddingBottom: 12,
  width: '100%',
  borderBottom: `2px solid ${theme.palette.background.border}`,
  transition: theme.transitions.create(['border-bottom']),
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  ':last-child': {
    marginBottom: 0,
  },
  [mediaQuery(700)]: {
    justifyContent: 'center',
  },
}));

export default UserWrapper;
