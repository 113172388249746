import { AnimatePresence } from 'framer-motion';
import SectionWrapper from './SectionWrapper';
import SectionTitle from './SectionTitle';
import ChallengeWrapper from './ChallengeWrapper';
import Challenge from '../Challenge';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import NewlySharedContext from './NewlySharedContext';
import { ChallengeSummary, SetComplexImmerStateAction } from '@/lib/types';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

const NewlySharedChallenges = () => {
  const [loading, newlySharedChallenges, setNewlySharedChallenges] =
    usePolledSwrAsComplexState<Record<number, ChallengeSummary>>(
      'challenge-portfolio/challenges/newly-shared',
    );
  const newlySharedChallengesArray = Object.values(newlySharedChallenges ?? {});

  const removeChallengeFromNewlyShared = (challengeId: number): void => {
    setNewlySharedChallenges(newlySharedChallenges => {
      delete newlySharedChallenges[challengeId];
    });
  };

  return (
    <NewlySharedContext.Provider
      value={[
        true,
        newlySharedChallenges as ChallengeSummary[],
        setNewlySharedChallenges as SetComplexImmerStateAction<
          ChallengeSummary[]
        >,
        removeChallengeFromNewlyShared,
      ]}
    >
      <AnimatePresence>
        {!loading && newlySharedChallengesArray.length > 0 ? (
          <SectionWrapper key="section-wrapper">
            <SectionTitle>
              {newlySharedChallengesArray.length === 1 ? (
                <FormattedMessageWithChallengeNaming id="newly_shared_challenge.title.singular" />
              ) : (
                <FormattedMessageWithChallengeNaming
                  id="newly_shared_challenge.title.plural"
                  values={{
                    challengeCount: newlySharedChallengesArray.length,
                  }}
                />
              )}
            </SectionTitle>
            <ChallengeWrapper>
              {newlySharedChallengesArray.map(challenge => (
                <Challenge challenge={challenge} key={challenge.id} />
              ))}
            </ChallengeWrapper>
          </SectionWrapper>
        ) : null}
      </AnimatePresence>
    </NewlySharedContext.Provider>
  );
};

export default NewlySharedChallenges;
