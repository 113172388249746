export enum Overflow {
  HIDDEN = 'hidden',
  AUTO = 'auto',
}

const setBodyOverflow = (overflow: Overflow): void => {
  setTimeout(() => (document.body.style.overflowY = overflow), 1);
};

export default setBodyOverflow;
