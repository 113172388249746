import { useState, useRef } from 'react';
import isValidUrl from '@/lib/utils/isValidUrl';
import Dialog from '@/components/Dialog';
import Prompt from '@/components/Prompt';
import WarningText from './WarningText';
import DialogAddButton from './DialogAddButton';
import { FormattedMessage } from 'react-intl';

type LinkPromptProps = {
  open: boolean;
  onAdd: (link: string) => void;
  onCancel: () => void;
};

const LinkPrompt = ({ open, onAdd, onCancel }: LinkPromptProps) => {
  const [input, setInput] = useState('');
  const [showInvalidUrlWarning, setShowInvalidUrlWarning] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState<boolean>(false);

  const handleCancel = (): void => {
    setShowInvalidUrlWarning(false);
    setShowEmptyError(false);
    onCancel();
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleAdd = (): void => {
    if (!input.trim()) {
      setShowEmptyError(true);
      inputRef.current?.focus?.();
      return;
    }

    onAdd(input.trim());

    setInput('');
    setShowInvalidUrlWarning(false);
    setShowEmptyError(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newInput = e.target.value;

    setInput(newInput);
    setShowEmptyError(false);

    const hasInput = !!newInput.trim();
    const urlIsValid = isValidUrl(newInput);
    setShowInvalidUrlWarning(hasInput && !urlIsValid);
  };

  return (
    <Dialog open={open} onClose={handleCancel} onEnterClose={handleAdd}>
      <Dialog.Title>
        <FormattedMessage id="portfolio.challenge.field.text_editor.link_prompt.title" />
      </Dialog.Title>
      <Dialog.Content>
        <Dialog.ContentText>
          <FormattedMessage id="portfolio.challenge.field.text_editor.link_prompt.text" />
        </Dialog.ContentText>
      </Dialog.Content>
      <Prompt.TextField
        label={
          <FormattedMessage id="portfolio.challenge.field.text_editor.link_prompt.input_label" />
        }
        value={input}
        onChange={handleInputChange}
        error={showEmptyError}
        helperText={
          (showInvalidUrlWarning && (
            <WarningText>
              <FormattedMessage id="portfolio.challenge.field.text_editor.link_prompt.invalid_url_warning" />
            </WarningText>
          )) ||
          (showEmptyError && (
            <FormattedMessage id="portfolio.challenge.field.text_editor.link_prompt.empty_error" />
          ))
        }
        inputRef={inputRef}
      />
      <Dialog.Actions addTopMargin>
        <Dialog.Actions.CancelButton onClick={handleCancel} />
        <DialogAddButton onClick={handleAdd} />
      </Dialog.Actions>
    </Dialog>
  );
};

export default LinkPrompt;
