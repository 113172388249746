import {
  AttachedTaskSummary,
  PerspectiveSublevelAttachment,
  PerspectiveSublevelPortfolio,
  PerspectiveSublevelWithChildren,
  PerspectiveWithSublevelTree,
  RawPerspectiveSublevelPortfolio,
} from '@/lib/types';
import { useMemo } from 'react';

const addAttachmentInfoToSublevelMap = (
  sublevel: PerspectiveSublevelWithChildren,
  sublevelMap: Map<number, PerspectiveSublevelAttachment>,
  perspectiveSublevelPortfolio: RawPerspectiveSublevelPortfolio,
): PerspectiveSublevelAttachment => {
  const sublevelAttachmentInfo: PerspectiveSublevelAttachment = {
    directlyAttachedTasks: [],
    indirectlyAttachedTasks: [],
  };

  sublevelAttachmentInfo.directlyAttachedTasks =
    perspectiveSublevelPortfolio[sublevel.id]?.attachedTasks ?? [];

  if (sublevel.children.length === 0) {
    sublevelMap.set(sublevel.id, sublevelAttachmentInfo);

    return sublevelAttachmentInfo;
  }

  // We use a map so we can add tasks to the attached tasks list by id; this
  // removes duplicates (ie tasks attached to both a sublevel and its children)
  const indirectlyAttachedTasks = new Map<number, AttachedTaskSummary>();

  for (const childSublevel of sublevel.children) {
    const childSublevelAttachmentInfo = addAttachmentInfoToSublevelMap(
      childSublevel,
      sublevelMap,
      perspectiveSublevelPortfolio,
    );

    for (const task of childSublevelAttachmentInfo.directlyAttachedTasks) {
      indirectlyAttachedTasks.set(task.id, task);
    }

    for (const task of childSublevelAttachmentInfo.indirectlyAttachedTasks) {
      indirectlyAttachedTasks.set(task.id, task);
    }
  }

  sublevelAttachmentInfo.indirectlyAttachedTasks = Array.from(
    indirectlyAttachedTasks.values(),
  );

  sublevelMap.set(sublevel.id, sublevelAttachmentInfo);

  return sublevelAttachmentInfo;
};

const buildPerspectiveSublevelAttachmentMap = (
  perspectiveWithSublevelTree: PerspectiveWithSublevelTree | null,
  perspectiveSublevelPortfolio: RawPerspectiveSublevelPortfolio | null,
): PerspectiveSublevelPortfolio => {
  if (
    perspectiveWithSublevelTree === null ||
    perspectiveSublevelPortfolio === null
  ) {
    return new Map<number, PerspectiveSublevelAttachment>();
  }

  const sublevelMap = new Map<number, PerspectiveSublevelAttachment>();

  for (const sublevel of perspectiveWithSublevelTree.sublevels) {
    addAttachmentInfoToSublevelMap(
      sublevel,
      sublevelMap,
      perspectiveSublevelPortfolio,
    );
  }

  return sublevelMap;
};

const useBuildPerspectiveSublevelAttachmentMap = (
  perspectiveWithSublevelTree: PerspectiveWithSublevelTree | null,
  perspectiveSublevelPortfolio: RawPerspectiveSublevelPortfolio | null,
): PerspectiveSublevelPortfolio => {
  const perspectiveSublevelAttachmentMap = useMemo(
    () =>
      buildPerspectiveSublevelAttachmentMap(
        perspectiveWithSublevelTree,
        perspectiveSublevelPortfolio,
      ),
    [perspectiveWithSublevelTree, perspectiveSublevelPortfolio],
  );

  return perspectiveSublevelAttachmentMap;
};

export default useBuildPerspectiveSublevelAttachmentMap;
