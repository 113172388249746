import Dialog from '@/components/Dialog';
import { ButtonProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const DialogAddButton = (props: ButtonProps) => (
  <Dialog.Actions.Button color="primary" {...props}>
    <FormattedMessage id="portfolio.level.proof.add.dialog_add_button" />
  </Dialog.Actions.Button>
);

export default DialogAddButton;
