import SublevelCard from '@/components/perspectives/SublevelCard';
import TreeColumnWithCardConnectors from './TreeColumnWithCardConnectors';
import useColumns, { Column } from './useColumns';
import useShouldUseSmallScreenVersion from '../useSmallScreenVersion';
import { PerspectiveWithSublevelTree } from '@/lib/types';

type SublevelsTreeProps = {
  perspective: PerspectiveWithSublevelTree;
};

const SublevelsTree = ({ perspective }: SublevelsTreeProps) => {
  const [columns, openColumn, closeColumn] = useColumns();
  const firstColumn: Column | null = columns[0] ?? null;

  const shouldUseSmallScreenVersion = useShouldUseSmallScreenVersion();

  return (
    <>
      <TreeColumnWithCardConnectors
        cardCount={perspective.sublevels.length}
        parentCardIndex={0}
        previousColumnCardCount={1}
        highlightedConnector={firstColumn?.parentCardIndex ?? null}
        hideCardConnectors={shouldUseSmallScreenVersion}
      >
        {perspective.sublevels.map((sublevel, index) => (
          <SublevelCard
            sublevel={sublevel}
            perspective={perspective}
            nestingIndex={0}
            onOpen={() =>
              openColumn(sublevel, index, -1, perspective.sublevels.length)
            }
            onClose={() => closeColumn(-1)}
            open={firstColumn?.sublevel === sublevel}
            faded={firstColumn !== null && firstColumn.sublevel !== sublevel}
            key={sublevel.id}
          />
        ))}
      </TreeColumnWithCardConnectors>
      {columns.map(
        (
          { sublevel, parentCardIndex, previousColumnCardCount },
          nestingIndex,
        ) => {
          const nextColumn = columns[nestingIndex + 1] ?? null;

          return (
            <TreeColumnWithCardConnectors
              cardCount={sublevel.children.length}
              parentCardIndex={parentCardIndex}
              previousColumnCardCount={previousColumnCardCount}
              highlightedConnector={nextColumn?.parentCardIndex ?? null}
              key={sublevel.id}
            >
              {sublevel.children.map((nestedSublevel, index) => (
                <SublevelCard
                  sublevel={nestedSublevel}
                  perspective={perspective}
                  nestingIndex={nestingIndex}
                  onOpen={() =>
                    openColumn(
                      nestedSublevel,
                      index,
                      nestingIndex,
                      sublevel.children.length,
                    )
                  }
                  onClose={() => closeColumn(nestingIndex)}
                  open={nextColumn?.sublevel === nestedSublevel}
                  faded={
                    nextColumn !== null &&
                    nextColumn.sublevel !== nestedSublevel
                  }
                  key={nestedSublevel.id}
                />
              ))}
            </TreeColumnWithCardConnectors>
          );
        },
      )}
    </>
  );
};

export default SublevelsTree;
