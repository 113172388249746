/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import RowsVisibilityStateContext from '../RowsVisibilityStateContext';
import useRowChallengesVisibilityState from './useRowChallengesVisibilityState';
import RowChallengesVisibilityStateContext from './RowChallengesVisibilityStateContext';
import RowWrapper from './RowWrapper';
import RowNameWrapper from './RowNameWrapper';
import RowName from './RowName';
import ChallengePlanningWrapper from './ChallengePlanningWrapper';
import ChallengePlanning from './ChallengePlanning';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';

type RowWithChallengePlanningProps = {
  rowId: number;
};

const RowWithChallengePlanning = ({ rowId }: RowWithChallengePlanningProps) => {
  const [challengePortfolio] = useContext(ChallengePortfolioContext);
  const row = challengePortfolio.rows[rowId];

  const [
    rowHasVisibleChallenges,
    registerChallengeVisibility,
  ] = useRowChallengesVisibilityState();

  const { registerRowVisibility } = useContext(RowsVisibilityStateContext);

  useEffect(() => {
    registerRowVisibility(rowId, rowHasVisibleChallenges);
  }, [rowId, rowHasVisibleChallenges]);

  if (!row) {
    return null;
  }

  return (
    <RowChallengesVisibilityStateContext.Provider
      value={{ registerChallengeVisibility }}
    >
      <RowWrapper hidden={!rowHasVisibleChallenges}>
        <RowNameWrapper>
          <RowName>{row.name}</RowName>
        </RowNameWrapper>
        <ChallengePlanningWrapper>
          <ChallengePlanning row={row} />
        </ChallengePlanningWrapper>
      </RowWrapper>
    </RowChallengesVisibilityStateContext.Provider>
  );
};

export default RowWithChallengePlanning;
