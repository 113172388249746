import DangerousIconButton from '@/components/DangerousIconButton';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { IconButtonProps } from '@mui/material';

type DeleteButtonProps = Omit<IconButtonProps, 'edge'>;

const DeleteButton = (props: DeleteButtonProps) => (
  <DangerousIconButton edge="end" {...props}>
    <DeleteIcon />
  </DangerousIconButton>
);

export default DeleteButton;
