import { Masonry } from '@mui/lab';
import useWindowWidth from '@/components/ChallengePortfolio/PlanningView/VisibleWeekRangeContext/useResponsiveVisibleWeeksCountCalculation/useWindowWidth';
import React from 'react';

type ResponsiveMasonryProps = {
  widthDenominator?: number;
  maxColumns?: number;
  collapsed?: boolean;
  children: NonNullable<React.ReactNode>;
};

const DEFAULT_WIDTH_DENOMINATOR = 450;
const DEFAULT_MAX_COLUMNS = Number.POSITIVE_INFINITY;

const computeColumnCount = (
  windowWidth: number,
  widthDenominator: number,
): number => {
  const columnCount = Math.floor(windowWidth / widthDenominator);

  if (widthDenominator > 300) {
    return Math.max(windowWidth <= 640 ? 2 : 1, columnCount);
  }

  return Math.max(1, columnCount);
};

const ResponsiveMasonry = ({
  widthDenominator,
  maxColumns,
  collapsed,
  children,
}: ResponsiveMasonryProps) => {
  const windowWidth = useWindowWidth();
  const columnCount = computeColumnCount(
    windowWidth,
    widthDenominator ?? DEFAULT_WIDTH_DENOMINATOR,
  );

  return (
    <Masonry
      columns={
        collapsed ? 0 : Math.min(maxColumns ?? DEFAULT_MAX_COLUMNS, columnCount)
      }
    >
      {children}
    </Masonry>
  );
};

export default ResponsiveMasonry;
