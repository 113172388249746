import {
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';
import UserRole from '@/lib/constants/UserRole.enum';
import StyledRadio from './StyledRadio';
import Space from '@/components/Space';
import { FormattedMessage } from 'react-intl';
import PortfolioItemWithGroupsPromptVariant from '../PortfolioItemWithGroupsPromptVariant.enum';
import { MinimumRole } from '@/lib/types';

type MinimumRolePickerProps = {
  variant: PortfolioItemWithGroupsPromptVariant;
  selectedMinimumRole: MinimumRole;
  setSelectedMinimumRole: (minimumRole: MinimumRole) => void;
};

const MinimumRolePicker = ({
  variant,
  selectedMinimumRole,
  setSelectedMinimumRole,
}: MinimumRolePickerProps) => (
  <FormControl component="fieldset">
    <FormLabel component="legend">
      <FormattedMessage
        id={`dialogs.checklist_prompt_with_group_select.minimum_role_picker.label.${variant}`}
      />
    </FormLabel>
    <Space height={8} />
    <RadioGroup
      name="minimum-role"
      value={selectedMinimumRole}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setSelectedMinimumRole(e.target.value as MinimumRole)
      }
    >
      {[UserRole.STUDENT, UserRole.COACH, UserRole.EDITOR].map(role => (
        <FormControlLabel
          value={role}
          control={<StyledRadio />}
          label={
            <FormattedMessage
              id={`dialogs.checklist_prompt_with_group_select.minimum_role_picker.options.${role}`}
            />
          }
          key={role}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

export default MinimumRolePicker;
