import { useContext } from 'react';
import PerspectiveAttachmentContext from '../../ChallengePortfolio/NormalView/perspectives/PerspectiveAttachmentContext';
import {
  AddLink as AttachIcon,
  LinkOff as DetachIcon,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { PerspectiveSublevel } from '@/lib/types';

type AttachmentControls = {
  sublevel: PerspectiveSublevel;
};

const AttachmentControls = ({ sublevel }: AttachmentControls) => {
  const { attachedSublevels, attachSublevel, detachSublevel } = useContext(
    PerspectiveAttachmentContext,
  );

  const isAttached = attachedSublevels[sublevel.id] ?? false;

  if (isAttached) {
    return (
      <Tooltip
        title={
          <FormattedMessage id="perspectives.attachment_controls.detach_button" />
        }
        arrow
      >
        <IconButton
          className="sublevel-card__detach-button"
          onClick={() => detachSublevel(sublevel.id)}
          size="small"
        >
          <DetachIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={
        <FormattedMessage id="perspectives.attachment_controls.attach_button" />
      }
      arrow
    >
      <IconButton
        className="perspective-card__action-button"
        onClick={() => attachSublevel(sublevel.id)}
        size="small"
      >
        <AttachIcon />
      </IconButton>
    </Tooltip>
  );
};

export default AttachmentControls;
