import { ClassNames } from '@emotion/react';
import { FormControlLabel } from '@mui/material';
import ResponsiveRadio from './ResponsiveRadio';
import RadioLabel from './RadioLabel';
import LabelText from './LabelText';
import mediaQuery from '@/lib/styling/mediaQuery';
import React from 'react';
import RowAttachmentType from '../../../RowAttachmentType.enum';

type RowAttachmentTypeOptionProps = {
  value: RowAttachmentType;
  selected: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

// TODO rework styling
const RowAttachmentTypeOption = ({
  value,
  selected,
  disabled,
  style,
  children,
}: RowAttachmentTypeOptionProps) => (
  <ClassNames>
    {({ css }) => (
      <FormControlLabel
        control={<ResponsiveRadio />}
        classes={{
          root: css({
            marginRight: 0,
            [mediaQuery(420)]: {
              marginBottom: 16,
              alignItems: 'flex-start',
            },
          }),
          label: css({ flex: 1 }),
        }}
        label={<RadioLabel selected={selected}>{children}</RadioLabel>}
        value={value}
        disabled={disabled}
        style={style}
      />
    )}
  </ClassNames>
);

RowAttachmentTypeOption.LabelText = LabelText;

export default RowAttachmentTypeOption;
