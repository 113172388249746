import styled from '@emotion/styled';
import stripProps from '@/lib/utils/stripProps';
import isPropValid from '@emotion/is-prop-valid';
import { HTMLDivProps } from '@/lib/types';

type ProgressBarProps = HTMLDivProps & {
  color: string;
  progress: number;
  progressEqual: boolean;
  reversed: boolean;
  noTransition: boolean;
  onTop: boolean;
};

const ProgressBar = styled(stripProps('div', 'onTop'), {
  shouldForwardProp: isPropValid,
})<ProgressBarProps>(
  ({
    theme,
    color,
    progress,
    progressEqual,
    reversed,
    noTransition,
    onTop,
  }) => ({
    position: 'absolute',
    top: 0,
    left: reversed ? 'auto' : 0,
    right: reversed ? 0 : 'auto',
    width: `${progress ?? 0}%`,
    height: '100%',
    backgroundColor: progressEqual ? theme.palette.sliders.both : color,
    zIndex: onTop ? 2 : 1,
    transition: noTransition ? 'none' : theme.transitions.create('all'),
  }),
);

export default ProgressBar;
