import styled from '@emotion/styled';
import ChallengeName from '../ChallengeName';
import InputWithEscListener, {
  InputWithEscListenerProps,
} from '@/components/InputWithEscListener';
import useFormatMessageWithChallengeNaming from '@/lib/utils/useFormatMessageWithChallengeNaming';

const ChallengeNameAsInput = ChallengeName.withComponent(InputWithEscListener);

type ChallengeNameInputProps = Omit<
  InputWithEscListenerProps,
  'placeholder' | 'ref'
>;

export const StyledChallengeNameInput = styled(ChallengeNameAsInput)<
  InputWithEscListenerProps
>({
  marginRight: 0,
  width: '100%',
  flex: 1,
  fontFamily: 'CircularStd',
});

const ChallengeNameInput = (props: ChallengeNameInputProps) => {
  const formatMessageWithChallengeNaming = useFormatMessageWithChallengeNaming();

  return (
    <StyledChallengeNameInput
      placeholder={formatMessageWithChallengeNaming({
        id: 'portfolio.challenge.name.edit.input_placeholder',
      })}
      {...props}
    />
  );
};

export default ChallengeNameInput;
