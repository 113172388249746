import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const ContentWrapper = styled('section')(({ theme }) => ({
  padding: 16,
  backgroundColor: theme.palette.background.default,
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [mediaQuery(640)]: {
    width: '100%',
    height: '100%',
  },
}));

export default ContentWrapper;
