import StyledAddButton from '../../TextField/ViewText/AddButton/StyledAddButton';
import { FormattedMessage } from 'react-intl';
import useIsPortfolioReadOnly from '@/components/ChallengePortfolio/NormalView/useIsPortfolioReadOnly';
import styled from '@emotion/styled';

type AddLogEntryButtonProps = {
  logEntryName: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const StyledAddLogEntryButton = styled(StyledAddButton)({
  marginTop: 18
})

const AddLogEntryButton = ({
  logEntryName,
  onClick,
}: AddLogEntryButtonProps) => {
  const portfolioIsReadOnly = useIsPortfolioReadOnly();

  return (
    <StyledAddLogEntryButton
      onClick={!portfolioIsReadOnly ? onClick : undefined}
      disabled={portfolioIsReadOnly}
    >
      <FormattedMessage
        id="portfolio.challenge.log_entries.add.button"
        values={{ logEntryName }}
      />
    </StyledAddLogEntryButton>
  );
};

export default AddLogEntryButton;
