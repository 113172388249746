import { EditorField } from '@/lib/types';
import { createContext } from 'react';

type FieldWithSiblings = {
  // `field` is nullable because we're also performing checks on FieldContext
  // in situations where we don't know whether it was set up
  field: EditorField | null;
  previousField: EditorField | null;
  nextField: EditorField | null;
};

const FieldContext = createContext<FieldWithSiblings>({
  field: null,
  previousField: null,
  nextField: null,
});

export default FieldContext;
