import Dialog from '@/components/Dialog';
import DangerousButton from '@/components/DangerousButton';
import { FormattedMessage } from 'react-intl';
import React from 'react';

type SettingsDialogActionsProps = {
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSave: (event: React.MouseEvent<HTMLButtonElement>) => void;
  settingsHaveChanged: boolean;
  actionsDisabled: boolean;
};

const SettingsDialogActions = ({
  onClose,
  onSave,
  settingsHaveChanged,
  actionsDisabled,
  ...props
}: SettingsDialogActionsProps) => (
  <Dialog.Actions {...props}>
    {settingsHaveChanged ? (
      <>
        <Dialog.Actions.CancelButton
          onClick={onClose}
          disabled={actionsDisabled}
        />
        <DangerousButton onClick={onSave} disabled={actionsDisabled} inDialog>
          <FormattedMessage id="dialogs.actions.save" />
        </DangerousButton>
      </>
    ) : (
      <Dialog.Actions.DoneButton onClick={onClose} />
    )}
  </Dialog.Actions>
);

export default SettingsDialogActions;
