import Dialog from '@/components/Dialog';
import PerspectiveAttachment from '../../../perspectives/PerspectiveAttachment';
import StyledDialog from '../../../TaskBoard/KanbanPhaseColumn/CreateTask/PerspectiveAttachmentDialog/StyledDialog';
import PerspectiveAttachmentContext from '@/components/ChallengePortfolio/NormalView/perspectives/PerspectiveAttachmentContext';
import useAttachmentState from '../../../perspectives/useAttachmentState';
import { useContext } from 'react';
import TaskContext from '../TaskContext';

type PerspectiveAttachmentDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (attachedPerspectives: number[], attachedSublevels: number[]) => void;
};

function getKeysWithTrueValue(object: Record<number, boolean>): number[] {
  return Object.entries(object)
    .filter(([, value]) => !!value)
    .map(([key]) => Number(key));
}

const PerspectiveAttachmentDialog = ({
  open,
  onClose,
  onSave,
}: PerspectiveAttachmentDialogProps) => {
  const [task] = useContext(TaskContext);

  const {
    attachedPerspectives,
    attachedSublevels,
    attachPerspective,
    detachPerspective,
    attachSublevel,
    detachSublevel,
    reenableAttachmentStateSync,
    anySublevelAttached,
  } = useAttachmentState(task);

  const handleSave = () => {
    onSave(
      getKeysWithTrueValue(attachedPerspectives),
      getKeysWithTrueValue(attachedSublevels),
    );

    reenableAttachmentStateSync();
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <PerspectiveAttachmentContext.Provider
        value={{
          attachmentActive: true,
          attachedPerspectives,
          attachedSublevels,
          attachPerspective,
          detachPerspective,
          attachSublevel,
          detachSublevel,
          anySublevelAttached,
        }}
      >
        <PerspectiveAttachment />
      </PerspectiveAttachmentContext.Provider>
      <Dialog.Actions>
        <Dialog.Actions.CancelButton onClick={onClose} />
        <Dialog.Actions.SaveButton onClick={handleSave} />
      </Dialog.Actions>
    </StyledDialog>
  );
};

export default PerspectiveAttachmentDialog;
