import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import HideOnLowWidth from '@/components/HideOnLowWidth';
import RowActionButton from './RowActionButton';
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from '@mui/icons-material';

type RowMoveButtonsProps = {
  onMoveUp: () => void;
  onMoveDown: () => void;
  canMoveUp: boolean;
  canMoveDown: boolean;
};

const RowMoveButtons = ({
  onMoveUp,
  onMoveDown,
  canMoveUp,
  canMoveDown,
}: RowMoveButtonsProps) => (
  <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
    <HideOnLowWidth width={640}>
      <RowActionButton
        onClick={() => {
          if (canMoveUp) {
            onMoveUp();
          }
        }}
        disabled={!canMoveUp}
        small
        right
      >
        <ArrowUpwardIcon />
      </RowActionButton>
      <RowActionButton
        onClick={() => {
          if (canMoveDown) {
            onMoveDown();
          }
        }}
        disabled={!canMoveDown}
        small
        correctRightMargin
      >
        <ArrowDownwardIcon />
      </RowActionButton>
    </HideOnLowWidth>
  </RestrictedToPortfolioMode>
);
export default RowMoveButtons;
