import { useContext } from 'react';
import FieldType from '@/lib/constants/challengeModel/FieldType.enum';
import FieldContext from '../FieldContext';

const fieldTypesWithoutFullWidthOnMobile = [
  FieldType.DATE,
  FieldType.MANUAL_PLANNED_START_DATE,
  FieldType.MANUAL_PLANNED_END_DATE,
  FieldType.MANUAL_ACTUAL_START_DATE,
  FieldType.MANUAL_ACTUAL_END_DATE,
];

const useIsFullWidthOnMobile = (): boolean => {
  const field = useContext(FieldContext);

  return !fieldTypesWithoutFullWidthOnMobile.includes(field.type);
};

export default useIsFullWidthOnMobile;
