import React, { useContext, useState } from 'react';
import Dialog from '@/components/Dialog';
import setBodyOverflow, { Overflow } from '@/lib/styling/setBodyOverflow';
import DeleteButton from './DeleteButton';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import ChallengeContext from '../../ChallengeContext';
import ChallengePortfolioContext from '../../../../../ChallengePortfolioContext';
import RowContext from '@/components/ChallengePortfolio/NormalView/Rows/Row/RowContext';
import useApi from '@/lib/api/useApi';
import NewlySharedContext from '@/components/ChallengePortfolio/NormalView/NewlySharedChallenges/NewlySharedContext';

type DeleteChallengeProps = {
  ButtonComponent?: React.ElementType;
};

const DeleteChallenge = ({
  ButtonComponent: ButtonComponentOrUndefined,
}: DeleteChallengeProps) => {
  const api = useApi();

  const [isNewlyShared, , , removeChallengeFromNewlyShared] =
    useContext(NewlySharedContext);
  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const row = useContext(RowContext);
  const [challenge] = useContext(ChallengeContext);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteChallenge = async (): Promise<void> => {
    setConfirmationDialogOpen(false);

    setDeleting(true);

    await api.delete(`challenge-portfolio/challenges/${challenge.id}`);

    setDeleting(false);
    setConfirmationDialogOpen(false);

    setChallengePortfolio(challengePortfolio => {
      delete challengePortfolio.challenges[challenge.id];

      if (isNewlyShared) {
        removeChallengeFromNewlyShared(challenge.id);

        return;
      }

      challengePortfolio.rows[row.id].challenges.splice(
        challengePortfolio.rows[row.id].challenges.findIndex(
          id => id === challenge.id,
        ),
        1,
      );
    });
  };

  const closeDialog = () => {
    setConfirmationDialogOpen(false);
    setBodyOverflow(Overflow.HIDDEN);
  };

  const ButtonComponent = ButtonComponentOrUndefined ?? DeleteButton;
  const challengeIsShared = challenge.users.length > 1;

  return (
    <>
      <ButtonComponent
        onClick={() => setConfirmationDialogOpen(true)}
        disabled={challengeIsShared}
      />
      <Dialog open={confirmationDialogOpen} onClose={closeDialog} dangerous>
        <Dialog.Title>
          <FormattedMessage id="portfolio.challenge.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessageWithChallengeNaming
              id={'portfolio.challenge.delete.text'}
            />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={closeDialog}
            disabled={deleting}
          />
          <Dialog.Actions.DeleteButton
            onClick={deleteChallenge}
            disabled={deleting}
          />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeleteChallenge;
