import { useContext } from 'react';
import UserContext from '../UserContext';
import SelectLoadingPlaceholder from './SelectLoadingPlaceholder';
import UserSelectFieldWrapper from '../UserSelectFieldWrapper';
import GroupSelectField from './GroupSelectField';
import GroupsContext from '../../../../GroupsContext';
import UsersContext from '../../UsersContext';
import useApi from '@/lib/api/useApi';

type GroupSelectProps = {
  asManagedGroups: boolean;
};

const GroupSelect = ({ asManagedGroups }: GroupSelectProps) => {
  const api = useApi();

  const [, , setUsers] = useContext(UsersContext);
  const user = useContext(UserContext);

  const [loading, groups] = useContext(GroupsContext);

  if (loading) {
    return <SelectLoadingPlaceholder />;
  }

  const noGroups = !groups || Object.keys(groups).length === 0;
  const selectedGroups = asManagedGroups ? user.managedGroups : user.groups;

  // TODO instead of sending an API request everytime, send request on select
  // close? -> implement save-loading state then as well
  const changeGroups = (newSelectedGroups: number[]): void => {
    // TODO maybe this could be rewritten?
    if (asManagedGroups) {
      api.put(`users/${user.id}/groups/managed`, {
        groupIds: newSelectedGroups,
      });

      setUsers(users => {
        const userIndex = users.findIndex(element => element.id === user.id);

        users[userIndex].managedGroups = newSelectedGroups;
      });

      return;
    }

    api.put(`users/${user.id}/groups`, {
      groupIds: newSelectedGroups,
    });

    setUsers(users => {
      const userIndex = users.findIndex(element => element.id === user.id);

      users[userIndex].groups = newSelectedGroups;
    });
  };

  return (
    <UserSelectFieldWrapper withMarginRight>
      <GroupSelectField
        value={selectedGroups}
        onChange={e => changeGroups(e.target.value as number[])}
        groups={groups}
        asManagedGroups={asManagedGroups}
        disabled={noGroups}
      />
    </UserSelectFieldWrapper>
  );
};

export default GroupSelect;
