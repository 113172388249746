import React, { createContext, useState } from 'react';
import getCollapsedRowsCache from './getCollapsedRowsCache';
import { ReactContextWithAutomatedProvider } from '@/lib/types';

type CollapsedRowsState = {
  isRowCollapsed: (rowId: number) => boolean;
  toggleRowCollapsed: (rowId: number) => void;
  collapseAllRows: () => void;
  uncollapseAllRows: () => void;
};

const CollapsedRowsContext = createContext<CollapsedRowsState>({
  isRowCollapsed: (_: number) => false,
  toggleRowCollapsed: (_: number) => {},
  collapseAllRows: () => {},
  uncollapseAllRows: () => {},
}) as ReactContextWithAutomatedProvider<CollapsedRowsState>;

type AutomatedCollapsedRowsContextProviderProps = {
  rowIds: number[];
  children: React.ReactNode;
};

const AutomatedCollapsedRowsContextProvider = ({
  rowIds,
  children,
}: AutomatedCollapsedRowsContextProviderProps) => {
  const [collapsedRows, setCollapsedRows] = useState<Record<number, boolean>>(
    getCollapsedRowsCache(),
  );

  const isRowCollapsed = (rowId: number): boolean => {
    return !!collapsedRows[rowId];
  };

  const updateRowCollapsedInCache = (
    rowId: number,
    collapsed: boolean,
  ): void => {
    localStorage.setItem(`row-collapsed:${rowId}`, collapsed.toString());
  };

  const toggleRowCollapsed = (rowId: number): void => {
    const alreadyCollapsed = isRowCollapsed(rowId);

    updateRowCollapsedInCache(rowId, !alreadyCollapsed);
    setCollapsedRows({
      ...collapsedRows,
      [rowId]: !alreadyCollapsed,
    });
  };

  const collapseAllRows = (): void => {
    rowIds.forEach(rowId => updateRowCollapsedInCache(rowId, true));
    setCollapsedRows(
      rowIds.reduce((acc, cur) => {
        acc[cur] = true;

        return acc;
      }, {} as Record<number, boolean>),
    );
  };

  const uncollapseAllRows = (): void => {
    rowIds.forEach(rowId => updateRowCollapsedInCache(rowId, false));
    setCollapsedRows({});
  };

  return (
    <CollapsedRowsContext.Provider
      value={{
        isRowCollapsed,
        toggleRowCollapsed,
        collapseAllRows,
        uncollapseAllRows,
      }}
    >
      {children}
    </CollapsedRowsContext.Provider>
  );
};

CollapsedRowsContext.AutomatedProvider = AutomatedCollapsedRowsContextProvider;

export default CollapsedRowsContext;
