import { useState } from 'react';
import { useMediaQuery, Drawer } from '@mui/material';
import OpenFeedbackButton from './OpenFeedbackButton';
import AddFeedbackButton from './AddFeedbackButton';
import StyledFeedbackList from './StyledFeedbackList';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import AddFeedback from '../AddFeedback';
import CustomAddFeedbackButton from './CustomAddFeedbackButton';
import setBodyOverflow, { Overflow } from '@/lib/styling/setBodyOverflow';
import { CoachComment } from '@/lib/types';

type MobileCoachFeedbackProps = {
  feedback: CoachComment[];
};

const MobileCoachFeedback = ({ feedback }: MobileCoachFeedbackProps) => {
  const [feedbackDrawerOpen, setFeedbackDrawerOpen] = useState(false);
  const usesMobileCoachFeedback = useMediaQuery('(max-width: 1250px)');

  const actuallyOpen = feedbackDrawerOpen && usesMobileCoachFeedback;

  return (
    <>
      {feedback.length > 0 ? (
        <OpenFeedbackButton onClick={() => setFeedbackDrawerOpen(true)} />
      ) : (
        <RestrictedToPortfolioMode mode={PortfolioMode.COACH}>
          <AddFeedbackButton onClick={() => setFeedbackDrawerOpen(true)} />
        </RestrictedToPortfolioMode>
      )}
      <Drawer
        anchor="right"
        open={actuallyOpen}
        onClose={() => {
          setFeedbackDrawerOpen(false);
          setBodyOverflow(Overflow.HIDDEN);
        }}
      >
        <StyledFeedbackList feedback={feedback} mobileVariant />
        <RestrictedToPortfolioMode mode={PortfolioMode.COACH}>
          <AddFeedback ButtonComponent={CustomAddFeedbackButton} />
        </RestrictedToPortfolioMode>
      </Drawer>
    </>
  );
};

export default MobileCoachFeedback;
