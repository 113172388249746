import { common, grey, lightBlue, purple, indigo } from '@mui/material/colors';
import { darken } from '@mui/material';
import { PaletteOptions } from '@mui/material/styles';

const lightHighContrastPalette = {
  isHighContrast: true,
  primary: {
    main: common.black,
  },
  secondary: {
    main: common.black,
  },
  error: {
    main: common.black,
  },
  warning: {
    main: common.black,
  },
  danger: common.black,
  background: {
    default: common.white,
    shaded: grey[300],
    border: common.black,
  },
  sliders: {
    student: lightBlue[300],
    coach: purple[500],
    both: indigo[800],
  },
  partFocusColor: common.black,
  partFocusTextColor: common.white,
  text: {
    primary: common.black,
    secondary: common.black,
    disabled: common.black,
  },
  divider: common.black,
  increaseContrastWithBackground: (
    color: string,
    coefficient: number,
  ): string => {
    return darken(color, coefficient);
  },
} satisfies PaletteOptions;

export default lightHighContrastPalette;
