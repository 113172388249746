import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';

const ButtonLabel = styled(Paper)(
  {
    marginRight: 12,
    padding: '4px 6px',
    fontFamily: 'CircularStd',
    fontSize: 14,
  },
  addBorderInHighContrastMode,
);

export default ButtonLabel;
