import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import RowActionButton, { RowActionButtonProps } from '../RowActionButton';
import { Edit as EditIcon } from '@mui/icons-material';

type EditButtonProps = Omit<RowActionButtonProps, 'children'>;

const EditButton = (props: EditButtonProps) => (
  <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
    <RowActionButton {...props}>
      <EditIcon />
    </RowActionButton>
  </RestrictedToPortfolioMode>
);

export default EditButton;
