import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const ControlsWrapper = styled('aside')(({ theme }) => ({
  padding: 12,
  flex: 'none',
  width: '20%',
  backgroundColor: theme.palette.background.default,
  borderRight: `2px solid ${theme.palette.background.border}`,
  transition: theme.transitions.create(['background-color', 'border']),
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 1,
  [mediaQuery(700)]: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    borderRight: 'none',
    zIndex: 1,
  },
}));

export default ControlsWrapper;
