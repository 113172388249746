import StyledSublevelCard from './StyledSublevelCard';
import SublevelCardContent from './SublevelCardContent';
import SublevelHeader from './SublevelHeader';
import OpenButton from '../PerspectiveCard/OpenButton';
import PerspectiveColorFill from '../PerspectiveCard/PerspectiveColorFill';
import CloseButton from './CloseButton';
import PerspectiveAttachmentContext from '../../ChallengePortfolio/NormalView/perspectives/PerspectiveAttachmentContext';
import AttachmentControls from './AttachmentControls';
import React, { useContext } from 'react';
import usePerspectiveSublevelAttachmentInfo from './usePerspectiveSublevelAttachmentInfo';
import PerspectiveSublevelTaskAttachment from './PerspectiveSublevelTaskAttachment';
import PerspectiveExplanation from '../PerspectiveExplanation';
import { Perspective, PerspectiveSublevelWithChildren } from '@/lib/types';
import SublevelCardTitle from './SublevelCardTitle';

type SublevelCardProps = {
  sublevel: PerspectiveSublevelWithChildren;
  perspective: Perspective;
  nestingIndex: number;
  onOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
  faded: boolean;
};

const SublevelCard = ({
  sublevel,
  perspective,
  nestingIndex,
  onOpen,
  onClose,
  open,
  faded,
}: SublevelCardProps) => {
  const { attachmentActive, attachedSublevels, anySublevelAttached } =
    useContext(PerspectiveAttachmentContext);
  const attachmentInfo = usePerspectiveSublevelAttachmentInfo(
    sublevel,
    nestingIndex,
  );

  const showAttachmentOutline =
    attachmentActive &&
    ((attachedSublevels[sublevel.id] ?? false) ||
      anySublevelAttached(sublevel.childIdsIncludingNested));

  return (
    <StyledSublevelCard
      showAttachmentOutline={showAttachmentOutline}
      perspectiveColor={perspective.color}
      faded={faded}
    >
      <SublevelCardContent
        className="perspective-card__content-wrapper"
        fillLevel={attachmentInfo.fillLevel}
      >
        <SublevelHeader>
          <SublevelCardTitle>{sublevel.name}</SublevelCardTitle>
          <PerspectiveSublevelTaskAttachment
            attachmentInfo={attachmentInfo}
            sublevel={sublevel}
            perspective={perspective}
          />
          {attachmentActive && <AttachmentControls sublevel={sublevel} />}
          <PerspectiveExplanation
            perspectiveOrSublevel={sublevel}
            perspectiveColor={perspective.color}
          />
        </SublevelHeader>
      </SublevelCardContent>
      {sublevel.children.length > 0 && !open && onOpen && (
        <OpenButton fillLevel={attachmentInfo.fillLevel} onClick={onOpen} />
      )}
      {open && onClose && (
        <CloseButton fillLevel={attachmentInfo.fillLevel} onClick={onClose} />
      )}
      <PerspectiveColorFill
        color={perspective.color}
        fillLevel={attachmentInfo.fillLevel}
        fillFade={attachmentInfo.fillFade}
      />
    </StyledSublevelCard>
  );
};

export default SublevelCard;
