import ViewWrapper from './ViewWrapper';
import CategoryWrapper from './CategoryWrapper';
import CategoryName from './CategoryName';
import CategoryEmptyMessage from './CategoryEmptyMessage';
import PerspectiveWrapper from './PerspectiveWrapper';
import DraggablePerspective from './DraggablePerspective';
import PerspectiveCategory from '@/lib/constants/PerspectiveCategory.enum';
import groupBy from 'lodash.groupby';
import { PerspectiveWithSublevelsInfo } from '@/lib/types';

type PerspectiveCategorizationProps = {
  perspectives: PerspectiveWithSublevelsInfo[];
  perspectiveCategories: Record<number, PerspectiveCategory>;
  onUpdatePerspectiveCategory: (
    perspectiveId: number,
    category: PerspectiveCategory,
  ) => void;
  onOpenPerspective: (perspectiveId: number) => Promise<void>;
};

const PerspectiveCategorization = ({
  perspectives,
  perspectiveCategories,
  onUpdatePerspectiveCategory,
  onOpenPerspective,
}: PerspectiveCategorizationProps) => {
  const perspectivesByCategory = groupBy(
    perspectives,
    (perspective: PerspectiveWithSublevelsInfo): PerspectiveCategory => {
      return (
        perspectiveCategories[perspective.id] ?? PerspectiveCategory.UNCLEAR
      );
    },
  );

  return (
    <ViewWrapper>
      {Object.values(PerspectiveCategory).map(perspectiveCategory => {
        const perspectives = perspectivesByCategory[perspectiveCategory] ?? [];

        return (
          <CategoryWrapper
            category={perspectiveCategory}
            key={perspectiveCategory}
          >
            <CategoryName category={perspectiveCategory} />
            {perspectives.length === 0 ? (
              <CategoryEmptyMessage />
            ) : (
              <PerspectiveWrapper>
                {perspectives.map(perspective => (
                  <DraggablePerspective
                    perspective={perspective}
                    category={perspectiveCategory}
                    onUpdateCategory={category =>
                      onUpdatePerspectiveCategory(perspective.id, category)
                    }
                    onOpen={
                      perspective.sublevels.length > 0
                        ? () => onOpenPerspective(perspective.id)
                        : undefined
                    }
                    key={perspective.id}
                  />
                ))}
              </PerspectiveWrapper>
            )}
          </CategoryWrapper>
        );
      })}
    </ViewWrapper>
  );
};

export default PerspectiveCategorization;
