import React, { useState } from 'react';
import { Theme, useTheme } from '@emotion/react';
import useInterval from 'use-interval';

type XssWarningLoggerProps = {
  children: React.ReactNode;
};

const logXssWarning = (theme: Theme): void => {
  console.log(
    '%cStop!',
    `color: ${theme.palette.danger}; font-family: Arial; font-size: 48px; font-weight: 700;`,
  );
  console.log(
    "%cUsing this console you can do all kinds of fun stuff. Really. But you can also get all of your data stolen, so don't paste pieces of code here that you don't understand, alright?",
    `color: ${theme.palette.text.primary}; font-family: Arial; font-size: 16px;`,
  );
  console.log(
    '%cFound an issue? bugs@egodact.com',
    `color: ${theme.palette.text.secondary}; font-family: Arial; font-size: 16px;`,
  );
};

const XssWarningLogger = ({ children }: XssWarningLoggerProps) => {
  const [hasLoggedWarning, setHasLoggedWarning] = useState(false);
  const theme = useTheme();

  // This is a hacky solution, but we need to wait until the ThemeProvider
  // has run its prefers-color-scheme media query
  useInterval(() => {
    if (!hasLoggedWarning) {
      setHasLoggedWarning(true);
      logXssWarning(theme);
    }
  }, 1000);

  return children;
};

export default XssWarningLogger;
