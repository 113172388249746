import { RefObject, useRef } from 'react';
import useResultListKeyboardNavigation from './useResultListKeyboardNavigation';
import StyledUserList from './StyledUserList';
import UserResult from './UserResult';
import NoUserResults from './NoUserResults';
import matchesSearch from '@/lib/utils/matchesSearch';
import UserSwitchArrows from './UserSwitchArrows';
import { FormattedMessage } from 'react-intl';
import useUsers from './useUsers';
import App from '@/lib/App';
import { User } from '@/lib/types';

type UserResultListProps = {
  selectedUser: User | null;
  onSelectUser: (user: User) => void;
  onKeyboardSelectUser: (email: string | null) => void;
  searchInput: string;
  selectedGroupId: number | null;
  collapsed: boolean;
  inputRef: RefObject<HTMLInputElement>;
};

const UserResultList = ({
  selectedUser,
  onSelectUser,
  onKeyboardSelectUser,
  searchInput,
  selectedGroupId,
  collapsed,
  inputRef,
}: UserResultListProps) => {
  const [loading, users] = useUsers(selectedUser, selectedGroupId);
  const listRef = useRef<HTMLUListElement>(null);

  const selectableUsers = !loading
    ? users.visible.filter(({ name }) => matchesSearch(name, searchInput))
    : [];

  const selectionCursor = useResultListKeyboardNavigation(
    inputRef,
    selectableUsers,
    onSelectUser,
    onKeyboardSelectUser,
  );

  const showUserSwitchingArrows = App.instanceConfig.useSetting(
    'enableUserSwitcherArrows',
  );

  if (loading) {
    return null;
  }

  // Note that we use onMouseDown instead of onClick in UserResult since
  // clicking also triggers onBlur on the UserSearchInput, thereby making the
  // user result list disappear causing user clicking to not work
  return (
    <>
      <StyledUserList
        id="user-result-list"
        role="listbox"
        collapsed={collapsed}
        ref={listRef}
      >
        {selectableUsers.length > 0 ? (
          selectableUsers.map((user, index) => (
            <UserResult
              user={user}
              onMouseDown={() => onSelectUser(user)}
              keyboardSelected={index === selectionCursor.index}
              listRef={listRef}
              key={user.email}
            />
          ))
        ) : (
          <NoUserResults>
            <FormattedMessage id="user_switcher.user_results.no_results" />
          </NoUserResults>
        )}
      </StyledUserList>
      {showUserSwitchingArrows && (
        <UserSwitchArrows
          previousArrowDisabled={!users.previous}
          nextArrowDisabled={!users.next}
          onPreviousUser={() => {
            if (users.previous !== null) {
              onSelectUser(users.previous);
            }
          }}
          onNextUser={() => {
            if (users.next !== null) {
              onSelectUser(users.next);
            }
          }}
        />
      )}
    </>
  );
};

export default UserResultList;
