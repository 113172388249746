import {
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import React from 'react';

export type ActionButtonProps = Omit<ListItemButtonProps, 'children'> & {
  icon: React.ReactNode;
  labelId: string;
};

// TODO move to ListItemButton, then upstream onEdit type could have event
// param
const ActionButton = ({ icon, labelId, ...props }: ActionButtonProps) => (
  <ListItemButton {...props}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText
      primary={<FormattedMessageWithChallengeNaming id={labelId} />}
    />
  </ListItemButton>
);

export default ActionButton;
