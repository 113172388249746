import {
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const ReleasesButton = (props: Omit<ListItemButtonProps, 'children'>) => (
  <ListItemButton {...props}>
    <ListItemIcon>
      <InfoIcon />
    </ListItemIcon>
    <ListItemText
      primary={<FormattedMessage id="header.options_menu.options.releases" />}
    />
  </ListItemButton>
);

export default ReleasesButton;
