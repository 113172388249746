import { EditorState, RichUtils } from 'draft-js';
import StyledToolbar from './StyledToolbar';
import ToolbarButton from './ToolbarButton';
import { inlineStyles, blockTypes } from './toolbarOptions';
import ToolbarItemsSeparator from './ToolbarItemsSeparator';
import LinkControls from './LinkControls';
import Space from '@/components/Space';
import CancelButton from './CancelButton';
import SaveButton from './SaveButton';
import React, { useContext } from 'react';
import DisabledContext from '../DisabledContext';

type EditorToolbarProps = {
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSave: (event: React.MouseEvent<HTMLButtonElement>) => void;
  focusOnEditor: () => void;
  saveDisabled: boolean;
};

const EditorToolbar = ({
  editorState,
  setEditorState,
  onCancel,
  onSave,
  focusOnEditor,
  saveDisabled,
}: EditorToolbarProps) => {
  const disabled = useContext(DisabledContext);

  const toggleInlineStyle = (inlineStyleIdentifier: string): void => {
    setEditorState(editorState =>
      RichUtils.toggleInlineStyle(editorState, inlineStyleIdentifier),
    );
  };

  const toggleBlockType = (blockTypeIdentifier: string): void => {
    setEditorState(editorState =>
      RichUtils.toggleBlockType(editorState, blockTypeIdentifier),
    );
  };

  const currentInlineStyles = editorState.getCurrentInlineStyle();

  const currentBlockType = editorState
    .getCurrentContent()
    .getBlockForKey(editorState.getSelection().getStartKey())
    .getType();

  return (
    <StyledToolbar>
      {inlineStyles.map(style => (
        <ToolbarButton
          label={style.label}
          icon={style.icon}
          selected={currentInlineStyles.has(style.identifier)}
          onMouseDown={e => {
            e.preventDefault();

            toggleInlineStyle(style.identifier);
          }}
          key={style.identifier}
        />
      ))}
      <ToolbarItemsSeparator />
      {blockTypes.map(type => (
        <ToolbarButton
          label={type.label}
          icon={type.icon}
          selected={currentBlockType === type.identifier}
          onMouseDown={e => {
            e.preventDefault();

            toggleBlockType(type.identifier);
          }}
          key={type.identifier}
        />
      ))}
      <ToolbarItemsSeparator />
      <LinkControls
        editorState={editorState}
        setEditorState={setEditorState}
        focusOnEditor={focusOnEditor}
      />
      <Space width="auto" />
      {onCancel && <CancelButton onClick={onCancel} disabled={disabled} />}
      <SaveButton onClick={onSave} disabled={disabled || saveDisabled} />
    </StyledToolbar>
  );
};

export default EditorToolbar;
