import useCachedSelectedTheme from './useCachedSelectedTheme';
import useDetermineDarkModeAutomatically from '@/lib/utils/useDetermineDarkModeAutomatically';
import { useMediaQuery } from '@mui/material';
import Theme from '@/lib/constants/Theme.enum';
import React from 'react';

const useSelectedTheme = (): [
  Theme,
  React.Dispatch<React.SetStateAction<Theme>>,
] => {
  const [
    cachedSelectedTheme,
    setCachedSelectedTheme,
  ] = useCachedSelectedTheme();
  const [determineDarkModeAutomatically] = useDetermineDarkModeAutomatically();

  const browserPrefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const browserThemePreference = browserPrefersDarkMode
    ? Theme.DARK
    : Theme.LIGHT;

  if (determineDarkModeAutomatically) {
    return [browserThemePreference, setCachedSelectedTheme];
  }

  return [cachedSelectedTheme, setCachedSelectedTheme];
};

export default useSelectedTheme;
