import { useContext } from 'react';
import VisibleWeekRangeContext from '../../VisibleWeekRangeContext';
import usePrevious from './usePrevious';
import ShiftDirection from '../ShiftDirection.enum';
import { Moment } from 'moment';

const useLatestVisibleWeekRangeShiftDirection = (): ShiftDirection | null => {
  const { visibleWeekRangeStartDate } = useContext(VisibleWeekRangeContext);
  const previousVisibleWeekRangeStartDate = usePrevious<Moment>(
    visibleWeekRangeStartDate,
  );

  const hasShifted =
    previousVisibleWeekRangeStartDate &&
    !previousVisibleWeekRangeStartDate.isSame(visibleWeekRangeStartDate);

  if (!hasShifted) {
    return null;
  }

  const shiftedToRight = previousVisibleWeekRangeStartDate.isBefore(
    visibleWeekRangeStartDate,
  );

  return shiftedToRight ? ShiftDirection.RIGHT : ShiftDirection.LEFT;
};

export default useLatestVisibleWeekRangeShiftDirection;
