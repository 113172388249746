import { Tooltip } from '@mui/material';
import StyledIndicator from './StyledIndicator';
import IconFallback from './IconFallback';
import StyledPerspectiveIcon from './StyledPerspectiveIcon';
import { PerspectiveSummary } from '@/lib/types';

type CardPerspectiveIndicatorProps = {
  perspective: PerspectiveSummary;
};

const CardPerspectiveIndicator = ({
  perspective,
}: CardPerspectiveIndicatorProps) => {
  return (
    <Tooltip title={perspective.name} arrow>
      <StyledIndicator>
        {perspective.icon ? (
          <StyledPerspectiveIcon src={perspective.icon} size={28} />
        ) : (
          <IconFallback perspective={perspective} />
        )}
      </StyledIndicator>
    </Tooltip>
  );
};

export default CardPerspectiveIndicator;
