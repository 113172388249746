import GroupListWrapper from './GroupListWrapper';
import Group from './Group';
import LoadingSpinner from '@/components/LoadingSpinner';
import NoSearchResults from '../../UserManager/NoSearchResults';
import { FormattedMessage } from 'react-intl';
import { useContext } from 'react';
import GroupsContext from '../../GroupsContext';

const GroupList = () => {
  const [loading, groups] = useContext(GroupsContext);

  if (loading) {
    return <LoadingSpinner margin={24} centered />;
  }

  return (
    <GroupListWrapper>
      {groups.length === 0 ? (
        <NoSearchResults style={{ marginLeft: 12 }}>
          <FormattedMessage id="group_manager.group_list.no_groups" />
        </NoSearchResults>
      ) : (
        groups.map(group => <Group group={group} key={group.id} />)
      )}
    </GroupListWrapper>
  );
};

export default GroupList;
