const MIN_FILL_LEVEL = 0.1;
const MAX_FILL_FADE = 0.7;

export const calculateFillPercentage = (fillLevel: number): number => {
  return Math.max(fillLevel, MIN_FILL_LEVEL) * 40;
};

export const calculateFade = (fillFade: number): number => {
  return fillFade * MAX_FILL_FADE;
};
