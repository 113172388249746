import styled from '@emotion/styled';
import { IconButton, IconButtonProps } from '@mui/material';
import { Close as CancelIcon } from '@mui/icons-material';

type CancelButtonProps = Omit<IconButtonProps, 'size' | 'children'>;

const StyledIconButton = styled(IconButton)({
  marginRight: 6,
  padding: 6,
});

// TODO move this to StyledIconButton styles?
const StyledCancelIcon = styled(CancelIcon)({
  width: 20,
  height: 20,
});

const CancelButton = (props: CancelButtonProps) => (
  <StyledIconButton size="small" {...props}>
    <StyledCancelIcon />
  </StyledIconButton>
);

export default CancelButton;
