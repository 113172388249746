import styled from '@emotion/styled';
import { alpha } from '@mui/material';

type CountdownOverlayProps = {
  deletionProgress: number;
};

const getBackgroundOpacity = (deletionProgress: number): number => {
  return 0.6 + (deletionProgress / 100) * 0.4;
};

const CountdownOverlay = styled('section')<CountdownOverlayProps>(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 'calc(100% - 2px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  ({ theme, deletionProgress }) => ({
    backgroundColor: alpha(
      theme.palette.background.default,
      getBackgroundOpacity(deletionProgress),
    ),
    backdropFilter: `saturate(50%) blur(${deletionProgress / 10}px)`,
  }),
);

export default CountdownOverlay;
