import useSyncDisplaySettingsToBody from './useSyncDisplaySettingsToBody';
import ThemeProvider from './ThemeProvider';
import XssWarningLogger from './XssWarningLogger';
import LanguageProvider from './LanguageProvider';
import LoadingScreen from './LoadingScreen';
import DialogManagerContext from '@/lib/contexts/DialogManagerContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SelectedPlatformContext from '@/lib/contexts/SelectedPlatformContext';
import ErrorBoundary from './ErrorBoundary';
import { BrowserRouter as Router } from 'react-router-dom';
import IsUsingPlanningViewContext from '@/lib/contexts/IsUsingPlanningViewContext';
import useAuthenticationState from './AuthenticationState/useAuthenticationState';
import SelectedSchoolContext from './SelectedSchool/SelectedSchoolContext';
import AuthenticationStateContext from './AuthenticationState/AuthenticationStateContext';
import useSelectedSchoolState from './SelectedSchool/useSelectedSchoolState';
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { GoogleOAuthProvider } from '@react-oauth/google';
import MicrosoftAuthConfigProvider from './MicrosoftAuthConfigProvider';
import ApiHandlingContext from '@/lib/api/ApiHandlingContext';
import getLanguage from '@/lib/utils/getLanguage';
import ShowPerspectiveCategorizationViewContext from '@/lib/contexts/ShowPerspectiveCategorizationViewContext';
import React from 'react';

type AppSetupProps = {
  children: React.ReactNode;
};

const AppSetup = ({ children }: AppSetupProps) => {
  const [
    authenticationStateLoading,
    authenticatedUser,
    refreshAuthenticationState,
  ] = useAuthenticationState();
  const [
    selectedSchoolLoading,
    selectedSchool,
    refreshSelectedSchoolState,
  ] = useSelectedSchoolState();

  useSyncDisplaySettingsToBody();

  return (
    <StylesProvider>
      <ThemeProvider>
        <XssWarningLogger>
          <LanguageProvider>
            <ApiHandlingContext.AutomatedProvider
              refreshAuthenticationState={refreshAuthenticationState}
            >
              <LoadingScreen
                active={authenticationStateLoading || selectedSchoolLoading}
              >
                <GoogleOAuthProvider
                  clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID}
                >
                  <MicrosoftAuthConfigProvider>
                    <SelectedSchoolContext.Provider
                      value={[selectedSchool, refreshSelectedSchoolState]}
                    >
                      <AuthenticationStateContext.Provider
                        value={[authenticatedUser, refreshAuthenticationState]}
                      >
                        <DialogManagerContext.AutomatedProvider>
                          <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale={getLanguage()}
                          >
                            <DndProvider backend={HTML5Backend}>
                              <SelectedPlatformContext.AutomatedProvider>
                                <ErrorBoundary>
                                  <Router>
                                    <ShowPerspectiveCategorizationViewContext.AutomatedProvider>
                                      <IsUsingPlanningViewContext.AutomatedProvider>
                                        {children}
                                      </IsUsingPlanningViewContext.AutomatedProvider>
                                    </ShowPerspectiveCategorizationViewContext.AutomatedProvider>
                                  </Router>
                                </ErrorBoundary>
                              </SelectedPlatformContext.AutomatedProvider>
                            </DndProvider>
                          </LocalizationProvider>
                        </DialogManagerContext.AutomatedProvider>
                      </AuthenticationStateContext.Provider>
                    </SelectedSchoolContext.Provider>
                  </MicrosoftAuthConfigProvider>
                </GoogleOAuthProvider>
              </LoadingScreen>
            </ApiHandlingContext.AutomatedProvider>
          </LanguageProvider>
        </XssWarningLogger>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default AppSetup;
