import InfoWrapper from './InfoWrapper';
import UserAvatar from '@/components/UserAvatar';
import UserName from './UserName';
import UserEmail from './UserEmail';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';

const CurrentUserInfo = () => {
  const authenticatedUser = useAuthenticatedUser()!;

  return (
    <InfoWrapper>
      <UserAvatar user={authenticatedUser} marginRight={16} />
      <div>
        <UserName>{authenticatedUser.name}</UserName>
        <UserEmail>{authenticatedUser.email}</UserEmail>
      </div>
    </InfoWrapper>
  );
};

export default CurrentUserInfo;
