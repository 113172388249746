import StyledPerspectiveCard, {
  StyledPerspectiveCardProps,
} from '../PerspectiveCard/StyledPerspectiveCard';
import styled from '@emotion/styled';
import stripProps from '@/lib/utils/stripProps';

type StyledSublevelCardProps = StyledPerspectiveCardProps & {
  faded: boolean;
};

const StyledSublevelCard = styled(stripProps(StyledPerspectiveCard, 'faded'))<
  StyledSublevelCardProps
>(({ theme, faded }) => ({
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  transition: theme.transitions.create('all'),
  opacity: faded ? 0.4 : undefined,
  ':hover, :focus-within': {
    opacity: 1,
  },
}));

export default StyledSublevelCard;
