import DialogButton, { DialogButtonProps } from './DialogButton';
import { FormattedMessage } from 'react-intl';

const DialogDoneButton = ({ children, ...props }: DialogButtonProps) => (
  <DialogButton color="primary" {...props}>
    {children ?? <FormattedMessage id="dialogs.actions.done" />}
  </DialogButton>
);

export default DialogDoneButton;
