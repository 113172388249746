import Dialog from '@/components/Dialog';
import { FormControlLabel, Checkbox, CheckboxProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const IncludeEmailCheckbox = (props: CheckboxProps) => (
  <Dialog.Content>
    <FormControlLabel
      control={<Checkbox {...props} />}
      label={
        <FormattedMessage id="support.dialog.feedback_form.include_email_checkbox.label" />
      }
    />
  </Dialog.Content>
);

export default IncludeEmailCheckbox;
