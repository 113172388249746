import styled from '@emotion/styled';
import { Paper, PaperProps } from '@mui/material';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import mediaQuery from '@/lib/styling/mediaQuery';
import isPropValid from '@emotion/is-prop-valid';

const HeaderPaper = (props: PaperProps<'header'>) => (
  <Paper component="header" {...props} />
);

type StyledHeaderProps = {
  inCoachMode: boolean;
  wideContent: boolean;
};

const StyledHeader = styled(HeaderPaper, {
  shouldForwardProp: isPropValid,
})<StyledHeaderProps>(
  ({ theme }) => ({
    position: 'relative',
    padding: '24px 148px',
    height: 72,
    borderRadius: 0,
    boxSizing: 'border-box',
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
    transition: theme.transitions.create('all'),
  }),
  addHighContrastStyles(({ theme }) => ({
    borderBottom: `2px solid ${theme.palette.divider}`,
  })),
  ({ inCoachMode }) => {
    if (inCoachMode) {
      return {
        [mediaQuery(1400)]: {
          paddingBottom: 96,
          height: 144,
        },
      };
    }
  },
  {
    [mediaQuery(1024)]: {
      paddingLeft: 72,
      paddingRight: 72,
    },
    [mediaQuery(850)]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    [mediaQuery(700)]: {
      paddingBottom: 24,
      height: 'auto',
      display: 'block',
    },
  },
  ({ wideContent }) => {
    if (wideContent) {
      return {
        paddingLeft: '24px !important',
        paddingRight: '24px !important',
      };
    }
  },
);

export default StyledHeader;
