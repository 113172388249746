import { useContext } from 'react';
import PhasesWrapper from './PhasesWrapper';
import KanbanPhaseColumn from './KanbanPhaseColumn';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import ChallengePortfolioContext from '../../ChallengePortfolioContext';
import RowContext from '../Rows/Row/RowContext';
import { ChallengeSummary } from '@/lib/types';
import sortByValue from '@/lib/utils/sorting/sortByValue';
import immerCompatibleArraySort from '@/lib/utils/immerCompatibleArraySort';

type ChallengeBoardProps = {
  collapsed: boolean;
};

const ChallengeBoard = ({ collapsed }: ChallengeBoardProps) => {
  const [challengePortfolio] = useContext(ChallengePortfolioContext);
  const row = useContext(RowContext);

  const challengesPerPhase = immerCompatibleArraySort(
    row.challenges,
    sortByValue,
  )
    .map(id => challengePortfolio.challenges[id])
    .reduce(
      (challengesPerPhase, challenge) => {
        challengesPerPhase[challenge.phase].push(challenge);

        return challengesPerPhase;
      },
      {
        [KanbanPhase.TODO]: [] as ChallengeSummary[],
        [KanbanPhase.IN_PROGRESS]: [] as ChallengeSummary[],
        [KanbanPhase.DONE]: [] as ChallengeSummary[],
      },
    );

  return (
    <PhasesWrapper>
      <KanbanPhaseColumn
        phase={KanbanPhase.TODO}
        challenges={challengesPerPhase[KanbanPhase.TODO]}
        collapsed={collapsed}
      />
      <KanbanPhaseColumn
        phase={KanbanPhase.IN_PROGRESS}
        challenges={challengesPerPhase[KanbanPhase.IN_PROGRESS]}
        collapsed={collapsed}
      />
      <KanbanPhaseColumn
        phase={KanbanPhase.DONE}
        challenges={challengesPerPhase[KanbanPhase.DONE]}
        collapsed={collapsed}
      />
    </PhasesWrapper>
  );
};

export default ChallengeBoard;
