import StyledHeader from './StyledHeader';
import EgodactIcon from './EgodactIcon';
import { FormattedMessage } from 'react-intl';

const LoginHeader = () => (
  <StyledHeader>
    <EgodactIcon />
    <h3>
      <FormattedMessage id="login.header.title" />
    </h3>
  </StyledHeader>
);

export default LoginHeader;
