import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import FieldName from '../utils/FieldName';
import InnerLinkButton from './InnerLinkButton';
import ButtonIcon from './ButtonIcon';
import Space from '@/components/Space';
import { FormattedMessage } from 'react-intl';

const LinkButton = () => {
  const field = useContext(FieldContext);
  const linkButtonConfig = field.additionalConfig.linkButton ?? {};

  return (
    <section>
      <FieldName />
      <InnerLinkButton href={linkButtonConfig.link ?? '#'}>
        {linkButtonConfig.iconUrl && (
          <ButtonIcon src={linkButtonConfig.iconUrl} />
        )}
        {linkButtonConfig.label ?? (
          <FormattedMessage id="portfolio.challenge.link_button.default_label" />
        )}
      </InnerLinkButton>
      <Space height={-4} />
    </section>
  );
};

export default LinkButton;
