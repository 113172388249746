import { useContext } from 'react';
import ChallengeContext from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeContext';
import TaskContext from '@/components/ChallengePortfolio/NormalView/Task/TaskView/TaskContext';

const useProgressApiEndpoint = (): string => {
  const [challenge] = useContext(ChallengeContext);
  const [task] = useContext(TaskContext);

  if (challenge === null) {
    return 'portfolio/rubric-progress';
  }

  if (task === null) {
    return `challenge-portfolio/challenges/${challenge.id}/rubric-progress`;
  }

  return `challenge-portfolio/tasks/${task.id}/rubric-progress`;
};

export default useProgressApiEndpoint;
