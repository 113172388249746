import useTaskAttachedPerspectives from './useTaskAttachedPerspectives';
import CardPerspectiveIndicators from '../../../perspectives/CardPerspectiveIndicators';
import { TaskSummary } from '@/lib/types';

type TaskCardPerspectiveIndicatorsProps = {
  task: TaskSummary;
};

const TaskCardPerspectiveIndicators = ({
  task,
}: TaskCardPerspectiveIndicatorsProps) => {
  const attachedPerspectives = useTaskAttachedPerspectives(task);

  if (attachedPerspectives.length === 0) {
    return null;
  }

  return <CardPerspectiveIndicators perspectives={attachedPerspectives} />;
};

export default TaskCardPerspectiveIndicators;
