import styled from '@emotion/styled';
import MenuContent from './MenuContent';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

const MenuContentAsHeader = MenuContent.withComponent('header');

const MenuHeader = styled(MenuContentAsHeader)(
  ({ theme }) => ({
    marginTop: -8,
    marginBottom: 8,
    padding: '12px 16px',
    fontFamily: 'CircularStd',
    backgroundColor: theme.palette.action.hover,
    boxSizing: 'border-box',
  }),
  addHighContrastStyles(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
  })),
);

export default MenuHeader;
