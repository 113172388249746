import moment from 'moment';
import MarkersWrapper from './MarkersWrapper';
import DayMarker from './DayMarker';
import useVisibleWeekDays from './useVisibleWeekDays';

const DayMarkers = () => {
  const now = moment();
  const visibleWeekDays = useVisibleWeekDays();

  return (
    <MarkersWrapper>
      {visibleWeekDays.map(visibleWeekDay => (
        <DayMarker
          isToday={visibleWeekDay.isSame(now, 'day')}
          key={visibleWeekDay.isoWeekday()}
        >
          {visibleWeekDay.format('ddd')}
        </DayMarker>
      ))}
    </MarkersWrapper>
  );
};

export default DayMarkers;
