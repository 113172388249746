import styled from '@emotion/styled';

const NotFoundTitle = styled('h1')({
  marginTop: 24,
  marginBottom: 14,
  fontSize: 28,
  lineHeight: '44px',
});

export default NotFoundTitle;
