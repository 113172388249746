import styled from '@emotion/styled';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import { HTMLSpanProps } from '@/lib/types';

type StyledPhaseTextProps = HTMLSpanProps & {
  challengeColor: string | null;
};

const StyledPhaseText = styled('span')<StyledPhaseTextProps>(
  ({ theme, challengeColor }) => ({
    marginBottom: 6,
    // #FFFFFF actually means (or should mean) "challenge is the default color"
    // hence that we want to use the secondary text color when the challenge
    // color is #FFFFFF
    color:
      challengeColor && challengeColor !== '#FFFFFF'
        ? theme.palette.getContrastText(challengeColor)
        : theme.palette.text.secondary,
  }),
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    outline: `2px solid ${theme.palette.background.paper}`,
  })),
);

export default StyledPhaseText;
