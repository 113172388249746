import {
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ExitToApp as ExitIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import { useContext } from 'react';
import AuthenticationStateContext from '@/components/App/AppSetup/AuthenticationState/AuthenticationStateContext';

const LogoutButton = (props: Omit<ListItemButtonProps, 'children'>) => {
  const api = useApi();
  const [, refreshAuthenticationState] = useContext(AuthenticationStateContext);

  const logout = () => {
    api.unauthenticate();

    refreshAuthenticationState();
  };

  return (
    <ListItemButton onClick={logout} {...props}>
      <ListItemIcon>
        <ExitIcon />
      </ListItemIcon>
      <ListItemText
        primary={<FormattedMessage id="header.options_menu.options.logout" />}
      />
    </ListItemButton>
  );
};

export default LogoutButton;
