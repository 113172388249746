import { useContext } from 'react';
import PortfolioIsReadOnlyContext from '@/lib/contexts/PortfolioIsReadOnlyContext';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';

// TODO all these mechanisms for determining readonly-ness are confusing
// should be simplified!
// TODO readonly (no capitalisation)?
const useIsPortfolioReadOnly = (): boolean => {
  const portfolioMode = useContext(PortfolioModeContext);
  const portfolioIsReadOnly = useContext(PortfolioIsReadOnlyContext);

  return portfolioIsReadOnly || portfolioMode === PortfolioMode.COACH;
};

export default useIsPortfolioReadOnly;
