import { IconButton } from '@mui/material';
import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

const AttachmentControlButton = styled(IconButton)(
  ({ theme }) => ({
    position: 'absolute',
    top: 54,
    left: '50%',
    padding: 12,
    width: 48,
    height: 48,
    boxSizing: 'border-box',
    zIndex: 4,
    color: theme.palette.getContrastText(theme.isDark ? grey[800] : grey[200]),
    backgroundColor: theme.isDark ? grey[800] : grey[200],
    transform: 'translateX(-50%)',
    borderRadius: 24,
    transition: theme.transitions.create('all'),
    ':hover, :focus': {
      color: theme.palette.getContrastText(
        theme.isDark ? grey[700] : grey[300],
      ),
      backgroundColor: theme.isDark ? grey[700] : grey[300],
    },
  }),
  addHighContrastStyles(({ theme }) => ({
    top: 52,
    border: `1px solid ${theme.palette.text.primary}`,
  })),
);

export default AttachmentControlButton;
