import { useContext } from 'react';
import useApi from '@/lib/api/useApi';
import FieldRendererContext from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/FieldRendererContext';
import { ChallengeWithoutRelations, TaskWithoutRelations } from '@/lib/types';

const timestampToDate = (timestamp: number | null): Date | null => {
  if (timestamp === null) {
    return null;
  }

  return new Date(timestamp);
};

const useSavePortfolioItemDate = (
  apiEndpoint: string,
): ((timestamp: number | null) => Promise<void>) => {
  const api = useApi();

  const { setPortfolioItem, setPortfolioItemSummary } =
    useContext(FieldRendererContext);

  const savePortfolioItemDate = async (
    timestamp: number | null,
  ): Promise<void> => {
    const date = timestampToDate(timestamp);

    const updatedPortfolioItem = await api.put<
      ChallengeWithoutRelations | TaskWithoutRelations
    >(apiEndpoint, {
      date,
    });

    setPortfolioItem(portfolioItem => {
      portfolioItem.phase = updatedPortfolioItem.phase;
      portfolioItem.plannedStartDate = updatedPortfolioItem.plannedStartDate;
      portfolioItem.plannedEndDate = updatedPortfolioItem.plannedEndDate;
      portfolioItem.startDate = updatedPortfolioItem.startDate;
      portfolioItem.endDate = updatedPortfolioItem.endDate;
    });

    setPortfolioItemSummary(portfolioItemSummary => {
      portfolioItemSummary.phase = updatedPortfolioItem.phase;
      portfolioItemSummary.plannedStartDate =
        updatedPortfolioItem.plannedStartDate;
      portfolioItemSummary.plannedEndDate = updatedPortfolioItem.plannedEndDate;
    });
  };

  return savePortfolioItemDate;
};

export default useSavePortfolioItemDate;
