import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import { HTMLDivProps } from '@/lib/types';

type RowHeaderProps = HTMLDivProps & {
  collapsed: boolean;
  hasDarkBackground: boolean;
};

const RowHeader = styled('header')<RowHeaderProps>(
  ({ theme, collapsed, hasDarkBackground }) => ({
    padding: collapsed ? '9px 24px' : '12px 24px',
    backgroundColor: hasDarkBackground
      ? theme.palette.background.shaded
      : undefined,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    transition: theme.transitions.create('all'),
    [mediaQuery(640)]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  }),
);

export default RowHeader;
