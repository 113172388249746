import { useContext } from 'react';
import FieldRendererContext from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/FieldRendererContext';
import useApi from '@/lib/api/useApi';
import {
  SetComplexImmerStateAction,
  Task,
  TaskSummary,
  UserSummary,
} from '@/lib/types';

const useAddAssignee = (): ((user: UserSummary) => Promise<void>) => {
  const api = useApi();

  const {
    setPortfolioItem: setTask,
    setPortfolioItemSummary: setTaskSummary,
    portfolioItemApiEndpoint: taskApiEndpoint,
  } = useContext(FieldRendererContext) as {
    setPortfolioItem: SetComplexImmerStateAction<Task>;
    setPortfolioItemSummary: SetComplexImmerStateAction<TaskSummary>;
    portfolioItemApiEndpoint: string;
  };

  const addAssignee = async (user: UserSummary): Promise<void> => {
    const addedAssignee = await api.post<UserSummary>(
      `${taskApiEndpoint}/assignees/add`,
      {
        email: user.email,
      },
    );

    setTask(task => {
      task.assigneesCount++;
      task.assignees.push(addedAssignee);
    });

    setTaskSummary(taskSummary => {
      taskSummary.assignees.push(addedAssignee);
    });
  };

  return addAssignee;
};

export default useAddAssignee;
