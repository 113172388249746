import useImageAsDataUrl from '@/lib/utils/useImageAsDataUrl';
import LoadingIndicator from './LoadingIndicator';
import Icon from './Icon';
import useGlobalStaticApiResponse from '@/lib/api/useGlobalStaticApiResponse';
import { IconBaseProps } from './IconBase';

type PerspectiveIconProps = Omit<IconBaseProps, 'size' | 'children'> & {
  src: string;
  size?: number;
};

const PerspectiveIcon = ({ src, size, ...props }: PerspectiveIconProps) => {
  const [loading, iconBlob] = useGlobalStaticApiResponse<Blob>(
    `/perspectives/icons/${src}`,
  );
  const icon = useImageAsDataUrl(src, iconBlob);

  if (loading || icon === null) {
    return <LoadingIndicator size={size ?? 48} {...props} />;
  }

  return <Icon src={icon} size={size ?? 48} {...props} />;
};

export default PerspectiveIcon;
