import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';
import React from 'react';

const width = (columnCount: number): React.CSSProperties => {
  const widthString = `calc(${100 / columnCount}% - 24px)`;

  return {
    minWidth: widthString,
    maxWidth: widthString,
  };
};

const StyledInfoField = styled('div')(
  ({ theme }) => ({
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 24,
    padding: 16,
    ...width(5),
    background: theme.palette.action.hover,
    borderRadius: 4,
    boxSizing: 'border-box',
    [mediaQuery(1024)]: width(3),
    [mediaQuery(768)]: width(2),
    [mediaQuery(560)]: width(1),
  }),
  addBorderInHighContrastMode,
);

export default StyledInfoField;
