import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

type SelectedUsersMessageProps = {
  selectedUsersCount: number;
};

const SelectedUsersText = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontFamily: 'CircularStd',
}));

const SelectedUsersMessage = ({
  selectedUsersCount,
}: SelectedUsersMessageProps) => (
  <SelectedUsersText>
    {selectedUsersCount === 1 ? (
      <FormattedMessage id="user_manager.user_list.toolbar.user_count_singular" />
    ) : (
      <FormattedMessage
        id="user_manager.user_list.toolbar.user_count_plural"
        values={{
          userCount: selectedUsersCount,
        }}
      />
    )}
  </SelectedUsersText>
);

export default SelectedUsersMessage;
