import FocusLockWithoutDialogFocusFighting from '@/components/FocusLockWithoutDialogFocusFighting';
import ClosablePageOverlay from './ClosablePageOverlay';
import ChallengeViewCard from './ChallengeViewCard';
import React from 'react';

type ChallengeViewCardWithPageOverlay = {
  taskBoardOpen?: boolean;
  onClose: (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>,
  ) => void;
  children: React.ReactNode;
};

const ChallengeViewCardWithPageOverlay = ({
  taskBoardOpen,
  onClose,
  children,
}: ChallengeViewCardWithPageOverlay) => (
  <FocusLockWithoutDialogFocusFighting>
    <ClosablePageOverlay
      taskBoardOpen={taskBoardOpen ?? false}
      onClose={onClose}
    />
    <ChallengeViewCard elevation={3} taskBoardOpen={taskBoardOpen ?? false}>
      {children}
    </ChallengeViewCard>
  </FocusLockWithoutDialogFocusFighting>
);

export default ChallengeViewCardWithPageOverlay;
