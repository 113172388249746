import sortByName from '@/lib/utils/sorting/sortByName';
import AvatarsWrapper from './AvatarsWrapper';
import UserAvatar from './UserAvatar';
import { UserSummary } from '@/lib/types';

type AvatarListProps = {
  users: UserSummary[];
};

const AvatarList = ({ users }: AvatarListProps) => (
  <AvatarsWrapper>
    {users.sort(sortByName).map((user, index) => (
      <UserAvatar user={user} key={index} />
    ))}
  </AvatarsWrapper>
);

export default AvatarList;
