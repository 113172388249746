const booleanStringToBoolean = (booleanString: string | null): boolean => {
  return booleanString === 'true';
};

const getCollapsedRowsCache = (): Record<number, boolean> => {
  const collapsedRowsCache = {} as Record<number, boolean>;

  for (const key in localStorage) {
    const isIndicatingRowCollapsedState = key.startsWith('row-collapsed:');

    if (isIndicatingRowCollapsedState) {
      const rowId = Number(key.replace('row-collapsed:', ''));

      collapsedRowsCache[rowId] = booleanStringToBoolean(
        localStorage.getItem(key),
      );
    }
  }

  return collapsedRowsCache;
};

export default getCollapsedRowsCache;
