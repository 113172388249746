import React, { forwardRef } from 'react';
import useFormatMessageWithChallengeNaming from '@/lib/utils/useFormatMessageWithChallengeNaming';
import StyledInput from './StyledInput';
import { HTMLInputProps } from '@/lib/types';

type SearchInputProps = Omit<HTMLInputProps, 'onChange' | 'onFocus'> & {
  value: string;
  onChange: (newValue: string) => void;
  onEmptied: () => void;
  onEscPress: (event: KeyboardEvent) => void;
  visible: boolean;
};

const SearchInput = forwardRef(
  (
    { onChange, onEmptied, onEscPress, ...props }: SearchInputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const formatMessageWithChallengeNaming = useFormatMessageWithChallengeNaming();
    return (
      <StyledInput
        placeholder={formatMessageWithChallengeNaming({
          id: 'challenge_search_bar.placeholder',
        })}
        type="text"
        onChange={e => {
          const newValue = e.target.value;
          onChange(newValue);

          if (newValue === '') {
            onEmptied();
          }
        }}
        onEscPress={(event: KeyboardEvent) => {
          onChange('');
          onEscPress(event);
        }}
        {...props}
        ref={ref}
      />
    );
  },
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
