import { useContext } from 'react';
import RubricsPortfolioContext from '@/lib/contexts/RubricsPortfolioContext';

const useHasFocus = (
  partId: number,
): [boolean, (hasFocus: boolean) => void] => {
  const [portfolio, setPortfolio] = useContext(RubricsPortfolioContext);
  const hasFocus = portfolio.focus[partId] ?? false;

  const setHasFocus = (newHasFocus: boolean): void => {
    setPortfolio(portfolio => {
      portfolio.focus[partId] = newHasFocus;
    });
  };

  return [hasFocus, setHasFocus];
};

export default useHasFocus;
