import styled from '@emotion/styled';

type ColorPreviewProps = {
  color: string;
};

const ColorPreview = styled('div')<ColorPreviewProps>(
  ({ theme }) => ({
    marginLeft: 0,
    marginRight: 10,
    width: 18,
    minWidth: 18,
    height: 18,
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: 4,
  }),
  ({ color }) => ({
    backgroundColor: color,
  }),
);

export default ColorPreview;
