import mediaQuery from '@/lib/styling/mediaQuery';
import styled from '@emotion/styled';

const ChallengeCardWrapper = styled('div')({
  marginTop: 16,
  width: 'calc(100% - 96px)',
  minHeight: 140,
  '.MuiBadge-root': {
    width: '100%',
    height: '100%',
  },
  [mediaQuery(1100)]: {
    width: 'calc(100% - 64px)',
  },
});

export default ChallengeCardWrapper;
