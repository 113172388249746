import { useContext } from 'react';
import CenterChallengeWrapper from './CenterChallengeWrapper';
import ChallengeContext from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeContext';
import UnopenableChallengeCard from './UnopenableChallengeCard';
import ChallengeCardWrapper from './ChallengeCardWrapper';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

const CenterChallenge = () => {
  const [challenge] = useContext(ChallengeContext);

  return (
    <CenterChallengeWrapper>
      <h4>
        <FormattedMessageWithChallengeNaming id="perspectives.attachment.title" />
      </h4>
      <ChallengeCardWrapper>
        <UnopenableChallengeCard challenge={challenge} />
      </ChallengeCardWrapper>
    </CenterChallengeWrapper>
  );
};

export default CenterChallenge;
