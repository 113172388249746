import { useState, useEffect } from 'react';
import api from '@/lib/api';
import ApiAuthLocalStorage from '@/lib/api/ApiAuthLocalStorage';
import SelectedSchoolLocalStorage from '../SelectedSchool/SelectedSchoolLocalStorage';
import { AuthenticatedUser } from '@/lib/types';

const useAuthenticationState = (): [
  boolean,
  AuthenticatedUser | null,
  () => Promise<void>,
] => {
  const [loading, setLoading] = useState(true);
  const [
    authenticatedUser,
    setAuthenticatedUser,
  ] = useState<AuthenticatedUser | null>(null);

  const refreshAuthenticationState = async (): Promise<void> => {
    const selectedSchool = SelectedSchoolLocalStorage.getSelectedSchool();

    if (!selectedSchool) {
      setAuthenticatedUser(null);
      setLoading(false);

      return;
    }

    const { accessToken } = ApiAuthLocalStorage.getTokens();

    if (!accessToken) {
      setAuthenticatedUser(null);
      setLoading(false);

      return;
    }

    try {
      const authenticatedUser = await api.get<AuthenticatedUser>('auth/me');

      setAuthenticatedUser(authenticatedUser);
    } catch {
      setAuthenticatedUser(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshAuthenticationState();
  }, []);

  return [loading, authenticatedUser, refreshAuthenticationState];
};

export default useAuthenticationState;
