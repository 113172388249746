import { Snackbar, SnackbarProps } from '@mui/material';
import { useIntl } from 'react-intl';
import Group from '@/lib/constants/Group.enum';

type GroupIndicatingSnackbarProps = Omit<SnackbarProps, 'message'> & {
  group: Group;
};

const GroupIndicatingSnackbar = ({
  group,
  ...props
}: GroupIndicatingSnackbarProps) => {
  const intl = useIntl();

  return (
    <Snackbar
      message={intl.formatMessage(
        {
          id: 'editor.portfolio_template_editor_with_group_switcher.snackbar',
        },
        {
          group: intl.formatMessage({ id: `group_names.${group}` }),
        },
      )}
      {...props}
    />
  );
};

export default GroupIndicatingSnackbar;
