import React from 'react';
import SelectPlaceholder from './SelectPlaceholder';
import GroupCountText from './GroupCountText';

const renderValue = (
  asManagedGroups: boolean,
): ((selectedGroups: number[]) => React.ReactNode) => {
  const SelectPlaceholderOrGroupCount = (
    selectedGroups: number[],
  ): React.ReactNode => {
    if (selectedGroups.length === 0) {
      return <SelectPlaceholder asManagedGroups={asManagedGroups} />;
    }

    return (
      <GroupCountText
        groupCount={selectedGroups.length}
        asManagedGroups={asManagedGroups}
      />
    );
  };

  return SelectPlaceholderOrGroupCount;
};

export default renderValue;
