import { useState, useContext } from 'react';
import RowDeletionContext from '../RowDeletionContext';
import CountdownOverlay from './CountdownOverlay';
import DeletionCountdownIndicator from './DeletionCountdownIndicator';
import ContentWrapper from './ContentWrapper';
import { Button, useMediaQuery } from '@mui/material';
import Space from '@/components/Space';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

const RowDeletionCountdownOverlay = () => {
  const [deletionProgress, setDeletionProgress] = useState(0);
  const { cancelRowDeletion } = useContext(RowDeletionContext);
  const shouldDisplayShortText = useMediaQuery('(max-width: 560px)');

  return (
    <CountdownOverlay deletionProgress={deletionProgress}>
      <ContentWrapper>
        <DeletionCountdownIndicator
          deletionProgress={deletionProgress}
          setDeletionProgress={setDeletionProgress}
        />
        <Space width={24} />
        <section>
          <h3>
            <FormattedMessageWithChallengeNaming
              id={
                shouldDisplayShortText
                  ? 'portfolio.row.delete.overlay.title.short'
                  : 'portfolio.row.delete.overlay.title'
              }
            />
          </h3>
          <Space height={9} />
          <Button
            variant="outlined"
            color="secondary"
            onClick={cancelRowDeletion}
          >
            <FormattedMessage id="portfolio.row.delete.overlay.cancel_button" />
          </Button>
        </section>
      </ContentWrapper>
    </CountdownOverlay>
  );
};

export default RowDeletionCountdownOverlay;
