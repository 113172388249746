import styled from '@emotion/styled';
import { common } from '@mui/material/colors';
import { alpha } from '@mui/material';

const PreviewOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: alpha(common.black, 0.5),
});

export default PreviewOverlay;
