import useUserRowIds from '../useUserRowIds';
import ViewWrapper from './ViewWrapper';
import CollapsedRowsContext from './CollapsedRowsContext';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import NewlySharedChallenges from './NewlySharedChallenges';
import KanbanPhasesHeader from './KanbanPhasesHeader';
import Rows from './Rows';
import CollapseAllRowsButton from './CollapseAllRowsButton';
import CreateChallengeWithRowSelection from './CreateChallengeWithRowSelection';

type NormalViewProps = {
  hidden: boolean;
};

const NormalView = ({ hidden }: NormalViewProps) => {
  const rowIds = useUserRowIds();

  const flattenedRowIds = rowIds.map(([rowId]) => rowId);

  return (
    <ViewWrapper hidden={hidden}>
      <CollapsedRowsContext.AutomatedProvider rowIds={flattenedRowIds}>
        <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
          <CreateChallengeWithRowSelection rowIds={flattenedRowIds} />
          <NewlySharedChallenges />
        </RestrictedToPortfolioMode>
        <KanbanPhasesHeader>
          <CollapseAllRowsButton rowIds={flattenedRowIds} />
        </KanbanPhasesHeader>
        <Rows rowIds={rowIds} />
      </CollapsedRowsContext.AutomatedProvider>
    </ViewWrapper>
  );
};

export default NormalView;
