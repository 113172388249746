import FlagWrapper from './FlagWrapper';
import StyledIcon from './StyledIcon';
import getFlagIconForLanguage from './getFlagIconForLanguage';
import { SupportedLanguage } from '@/lib/utils/getLanguage';

type FlagIconProps = {
  language: SupportedLanguage;
};

const FlagIcon = ({ language }: FlagIconProps) => (
  <FlagWrapper>
    <StyledIcon src={getFlagIconForLanguage(language)} alt="" />
  </FlagWrapper>
);

export default FlagIcon;
