import {
  PerspectiveSublevelPortfolio,
  RawPerspectiveSublevelPortfolio,
} from '@/lib/types';
import { createContext } from 'react';
import { Updater } from 'use-immer';

const PerspectiveSublevelPortfolioContext = createContext<
  [PerspectiveSublevelPortfolio, Updater<RawPerspectiveSublevelPortfolio>]
>([new Map(), () => {}]);

export default PerspectiveSublevelPortfolioContext;
