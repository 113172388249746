import { ConnectDropTarget, useDrop } from 'react-dnd';
import DragType from '../../DragType.enum';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import { TaskDragItem } from '../../Task/useTaskDrag';

export type TaskDropResult =
  | Readonly<{
      shouldDrop: false;
      newPhase: null;
    }>
  | Readonly<{
      shouldDrop: true;
      newPhase: KanbanPhase;
    }>;

const useTaskPhaseDrop = (phase: KanbanPhase): ConnectDropTarget => {
  const [, attachDropHandlingRef] = useDrop({
    accept: DragType.TASK,
    drop: (draggedItem: TaskDragItem): TaskDropResult => {
      const { phase: oldPhase } = draggedItem;

      if (phase === oldPhase) {
        return {
          shouldDrop: false,
          newPhase: null,
        };
      }

      return {
        shouldDrop: true,
        newPhase: phase,
      };
    },
  });

  return attachDropHandlingRef;
};

export default useTaskPhaseDrop;
