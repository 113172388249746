import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';

const NotResponsiveAlert = () => {
  const [open, setOpen] = useState(true);
  const shouldShow = useMediaQuery('(max-width: 1024px)');

  const actuallyOpen = open && shouldShow;

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Dialog
      open={actuallyOpen}
      onClose={handleClose}
      onEnterClose={handleClose}
    >
      <Dialog.Title>
        <FormattedMessage id="editor.not_responsive_notice.title" />
      </Dialog.Title>
      <Dialog.Content>
        <Dialog.ContentText>
          <FormattedMessage id="editor.not_responsive_notice.text" />
        </Dialog.ContentText>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Actions.OkButton onClick={handleClose} />
      </Dialog.Actions>
    </Dialog>
  );
};

export default NotResponsiveAlert;
