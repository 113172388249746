import styled from '@emotion/styled';

const PerspectiveCardContent = styled('div')({
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 2,
});

export default PerspectiveCardContent;
