import AddLink from './AddLink';
import RemoveLink from './RemoveLink';
import { EditorState } from 'draft-js';

type LinkControlsProps = {
  editorState: EditorState;
  setEditorState: (newEditorState: EditorState) => void;
  focusOnEditor: () => void;
};

const LinkControls = ({
  editorState,
  setEditorState,
  focusOnEditor,
}: LinkControlsProps) => (
  <>
    <AddLink
      editorState={editorState}
      setEditorState={setEditorState}
      focusOnEditor={focusOnEditor}
    />
    <RemoveLink
      editorState={editorState}
      setEditorState={setEditorState}
      focusOnEditor={focusOnEditor}
    />
  </>
);

export default LinkControls;
