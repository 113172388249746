import styled from '@emotion/styled';
import ButtonBase from '@/components/ButtonBase';
import { common } from '@mui/material/colors';
import bounceAnimation from './bounceAnimation';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import isPropValid from '@emotion/is-prop-valid';
import { ButtonBaseProps, alpha } from '@mui/material';

export type StyledChallengeCardProps = Omit<ButtonBaseProps, 'color'> & {
  cardColor: string | null;
  big: boolean;
  collapsed?: boolean;
  dragging?: boolean;
  highlighted?: boolean;
  faded?: boolean;
};

const StyledChallengeCard = styled(ButtonBase, {
  shouldForwardProp: isPropValid,
})<StyledChallengeCardProps>(
  ({ theme }) => ({
    position: 'relative',
    padding: 6,
    width: '100%',
    minHeight: 74,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    lineHeight: 1,
    fontSize: 14,
    boxSizing: 'border-box',
    wordBreak: 'break-word',
    overflow: 'hidden',
    display: 'inline-flex',
    flexDirection: 'column',
    boxShadow: `0 2px 1px -1px ${alpha(common.black, 0.2)}, 0 1px 1px 0 ${alpha(
      common.black,
      0.14,
    )}, 0 1px 3px 0 ${alpha(common.black, 0.12)}`,
    borderRadius: 4,
    transition: theme.transitions.create('all'),
  }),
  ({ theme, big, collapsed, dragging, highlighted, faded, cardColor }) => {
    const styles = [];

    if (big) {
      styles.push({
        minHeight: 99,
      });
    }

    if (collapsed) {
      styles.push({
        width: 6,
        minHeight: 6,
        height: 6,
        backgroundColor: theme.palette.action.disabled,
        boxShadow: 'none',
      });
    }

    if (!collapsed) {
      styles.push({
        ':not(:active):hover': {
          boxShadow: `0 3px 3px -2px ${alpha(
            common.black,
            0.2,
          )}, 0 3px 4px 0 ${alpha(common.black, 0.14)}, 0 1px 8px 0 ${alpha(
            common.black,
            0.12,
          )}`,
        },
        ':active': {
          transform: 'scale(0.975)',
        },
      });
    }

    if (dragging || faded) {
      styles.push({
        opacity: 0.5,
      });
    }

    if (highlighted) {
      styles.push({
        animation: `${bounceAnimation} 1s cubic-bezier(.23, 1, .32, 1)`,
        zIndex: 1,
      });
    }

    if (cardColor && cardColor !== '#FFFFFF') {
      styles.push({
        color: theme.palette.getContrastText(cardColor),
        backgroundColor: cardColor,
      });
    }

    return styles;
  },
  addHighContrastStyles(({ theme }) => ({
    border: `2px solid ${theme.palette.divider}`,
  })),
  addHighContrastStyles(({ theme, collapsed }) => {
    if (collapsed) {
      return {
        backgroundColor: theme.palette.divider,
      };
    }
  }),
);

export default StyledChallengeCard;
