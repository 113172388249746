import styled from '@emotion/styled';
import { IconButton, IconButtonProps } from '@mui/material';
import { darken } from '@mui/material';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import { Check as CheckIcon } from '@mui/icons-material';

type SaveButtonProps = Omit<IconButtonProps, 'size' | 'children'>;

const StyledIconButton = styled(IconButton)(
  ({ theme }) => ({
    padding: 6,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    ':hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.15),
    },
    ':disabled': {
      backgroundColor: theme.palette.divider,
    },
  }),
  addHighContrastStyles({
    ':disabled': {
      display: 'none',
    },
  }),
);

const StyledCheckIcon = styled(CheckIcon)({
  width: 20,
  height: 20,
});

const SaveButton = (props: SaveButtonProps) => (
  <StyledIconButton size="small" {...props}>
    <StyledCheckIcon />
  </StyledIconButton>
);

export default SaveButton;
