import PerspectiveCard from '@/components/perspectives/PerspectiveCard';
import PerspectiveWrapper from './PerspectiveWrapper';
import CenterChallenge from './CenterChallenge';
import { useState } from 'react';
import useApi from '@/lib/api/useApi';
import PerspectiveWithSublevelsTree from '@/components/perspectives/PerspectiveWithSublevelsTree';
import useBuildPerspectiveSublevelAttachmentMap from '@/components/perspectives/PerspectiveWithSublevelsTree/useBuildPerspectiveSublevelAttachmentMap';
import PerspectivePortfolioContext from '@/components/perspectives/PerspectivePortfolioContext';
import PerspectiveSublevelPortfolioContext from '@/components/perspectives/PerspectiveSublevelPortfolioContext';
import useGlobalStaticApiResponse from '@/lib/api/useGlobalStaticApiResponse';
import LoadingView from './LoadingView';
import usePerspectiveEndpointsWithCoachScoping from '@/components/perspectives/usePerspectiveEndpointsWithCoachScoping';
import MobileDialogTitle from './MobileDialogTitle';
import { Updater } from 'use-immer';
import {
  PerspectivePortfolio,
  PerspectiveWithSublevelTree,
  PerspectiveWithSublevelsInfo,
  RawPerspectiveSublevelPortfolio,
} from '@/lib/types';
import useComplexState from '@/lib/utils/useComplexState';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

const PerspectiveAttachment = () => {
  const perspectiveEndpoints = usePerspectiveEndpointsWithCoachScoping();

  const [perspectivesLoading, perspectives] = useGlobalStaticApiResponse<
    PerspectiveWithSublevelsInfo[]
  >(perspectiveEndpoints.perspectives);
  const [
    perspectivePortfolioLoading,
    perspectivePortfolio,
    setPerspectivePortfolio,
  ] = usePolledSwrAsComplexState<PerspectivePortfolio>(
    perspectiveEndpoints.perspectivePortfolio,
  );

  const api = useApi();
  const [openPerspective, setOpenPerspective] =
    useState<PerspectiveWithSublevelTree | null>(null);
  const [perspectiveSublevelPortfolio, setPerspectiveSublevelPortfolio] =
    useComplexState<RawPerspectiveSublevelPortfolio | null>(null);

  const perspectiveSublevelPortfolioAsMap =
    useBuildPerspectiveSublevelAttachmentMap(
      openPerspective,
      perspectiveSublevelPortfolio,
    );

  const handleOpenPerspective = async (
    perspectiveId: number,
  ): Promise<void> => {
    const [perspective, perspectiveSublevelPortfolio] = await Promise.all([
      api.getGlobalStatic<PerspectiveWithSublevelTree>(
        perspectiveEndpoints.specificPerspective(perspectiveId),
      ),
      api.get<RawPerspectiveSublevelPortfolio>(
        perspectiveEndpoints.perspectiveSpecificPortfolio(perspectiveId),
      ),
    ]);

    setPerspectiveSublevelPortfolio(perspectiveSublevelPortfolio);
    setOpenPerspective(perspective);
  };

  if (perspectivesLoading || perspectivePortfolioLoading) {
    return <LoadingView />;
  }

  return (
    <PerspectivePortfolioContext.Provider
      value={[perspectivePortfolio, setPerspectivePortfolio]}
    >
      {openPerspective !== null ? (
        <PerspectiveSublevelPortfolioContext.Provider
          value={[
            perspectiveSublevelPortfolioAsMap,
            setPerspectiveSublevelPortfolio as Updater<RawPerspectiveSublevelPortfolio>,
          ]}
        >
          <PerspectiveWithSublevelsTree
            perspective={openPerspective}
            onClose={() => setOpenPerspective(null)}
          />
        </PerspectiveSublevelPortfolioContext.Provider>
      ) : (
        <>
          <MobileDialogTitle />
          <PerspectiveWrapper>
            {perspectives.map(perspective => (
              <PerspectiveCard
                perspective={perspective}
                onOpen={
                  perspective.sublevels.length > 0
                    ? () => handleOpenPerspective(perspective.id)
                    : undefined
                }
                key={perspective.id}
              />
            ))}
            <CenterChallenge />
          </PerspectiveWrapper>
        </>
      )}
    </PerspectivePortfolioContext.Provider>
  );
};

export default PerspectiveAttachment;
