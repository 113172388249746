import React, { useState } from 'react';
import LevelDialog, {
  LevelDialogProps,
} from '@/components/RubricsPortfolioComponents/LevelDialog';
import LevelSelectArrows from './LevelSelectArrows';
import CustomInnerContentWrapper from './CustomInnerContentWrapper';
import { Part } from '@/lib/types';

type PreconfiguredLevelDialogProps = LevelDialogProps & {
  part: Omit<Part, 'groups'>;
  subtopicIcon: string | null;
  levelCount: number;
  onClose: () => void;
  children: React.ReactNode;
};

const PreconfiguredLevelDialog = ({
  part,
  subtopicIcon,
  levelCount,
  onClose,
  children,
  ...props
}: PreconfiguredLevelDialogProps) => {
  const [visibleLevelIndex, setVisibleLevelIndex] = useState(0);

  return (
    <LevelDialog onClose={onClose} {...props}>
      <LevelDialog.Title
        subtopicIcon={subtopicIcon ?? undefined}
        onClose={onClose}
      >
        {part.name}
      </LevelDialog.Title>
      <LevelDialog.Content>
        <LevelSelectArrows
          levelCount={levelCount}
          visibleLevelIndex={visibleLevelIndex}
          onChangeVisibleLevelIndex={index => setVisibleLevelIndex(index)}
        />
        <CustomInnerContentWrapper visibleLevelIndex={visibleLevelIndex}>
          {children}
        </CustomInnerContentWrapper>
      </LevelDialog.Content>
    </LevelDialog>
  );
};

export default PreconfiguredLevelDialog;
