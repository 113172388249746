import {
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Palette as ThemeIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const DisplaySettingsButton = (
  props: Omit<ListItemButtonProps, 'children'>,
) => (
  <ListItemButton {...props}>
    <ListItemIcon>
      <ThemeIcon />
    </ListItemIcon>
    <ListItemText
      primary={
        <FormattedMessage id="header.options_menu.options.display_settings" />
      }
    />
  </ListItemButton>
);

export default DisplaySettingsButton;
