const selectLinkInputContent = (): void => {
  setTimeout((): void => {
    const input = document.getElementById('shareable-link');

    if (input === null || !(input instanceof HTMLInputElement)) {
      return;
    }

    input.select();
  }, 100);
};

export default selectLinkInputContent;
