import styled from '@emotion/styled';
import InputWithEscListener from '@/components/InputWithEscListener';
import mediaQuery from '@/lib/styling/mediaQuery';
import PhaseName from '../PhaseName';

const PhaseNameAsInput = PhaseName.withComponent(InputWithEscListener);

const PhaseNameInput = (styled(PhaseNameAsInput)({
  marginBottom: 0,
  flex: 1,
  fontFamily: 'CircularStd',
  fontSize: 24,
  fontWeight: 500,
  [mediaQuery(560)]: {
    fontSize: 22,
  },
}) as unknown) as typeof InputWithEscListener;

export default PhaseNameInput;
