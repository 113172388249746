import styled from '@emotion/styled';

const Phase = styled('section')({
  marginBottom: 32,
  ':last-child': {
    marginBottom: 0,
  },
});

export default Phase;
