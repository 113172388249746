import getLanguage from '@/lib/utils/getLanguage';
import { Moment } from 'moment';

const sharedMonthDateRangeAsText = (
  startDate: Moment,
  endDate: Moment,
): string => {
  if (getLanguage() === 'en') {
    return `${startDate.format('MMM D')} – ${endDate.format('D')}`;
  }

  return `${startDate.format('D')} – ${endDate.format('D MMM')}`;
};

const dateAsText = (date: Moment): string => {
  if (getLanguage() === 'en') {
    return date.format('MMM D');
  }

  return date.format('D MMM');
};

const getDateRangeAsText = (startDate: Moment, endDate: Moment): string => {
  const isDateRangeInSameMonth = startDate.month() === endDate.month();

  if (isDateRangeInSameMonth) {
    return sharedMonthDateRangeAsText(startDate, endDate);
  }

  return `${dateAsText(startDate)} – ${dateAsText(endDate)}`;
};

export default getDateRangeAsText;
