import { useContext, useState } from 'react';
import ChallengePlanningBar from './ChallengePlanningBar';
import setBodyOverflow, { Overflow } from '@/lib/styling/setBodyOverflow';
import ChallengeName from './ChallengeName';
import ChallengePreviewCard from './ChallengePreviewCard';
import ChallengeView from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';

type ChallengeProps = {
  challengeId: number;
};

const Challenge = ({ challengeId }: ChallengeProps) => {
  const [challengePortfolio] = useContext(ChallengePortfolioContext);
  const challenge = challengePortfolio.challenges[challengeId];

  const [challengeOpen, setChallengeOpen] = useState(false);

  if (!challenge.plannedStartDate || !challenge.plannedEndDate) {
    return null;
  }

  return (
    <>
      <ChallengePlanningBar
        challenge={challenge}
        onClick={() => {
          setChallengeOpen(true);
          setBodyOverflow(Overflow.HIDDEN);
        }}
      >
        {(contentVisibilityCompensationDistance, contentMaxVisibleWidth) => (
          <>
            <ChallengeName
              contentVisibilityCompensationDistance={
                contentVisibilityCompensationDistance
              }
              contentMaxVisibleWidth={contentMaxVisibleWidth}
            >
              {challenge.name}
            </ChallengeName>
            <ChallengePreviewCard
              challenge={challenge}
              contentVisibilityCompensationDistance={
                contentVisibilityCompensationDistance
              }
            />
          </>
        )}
      </ChallengePlanningBar>
      {challengeOpen && (
        <ChallengeView
          challenge={challenge}
          onClose={() => {
            setChallengeOpen(false);
            setBodyOverflow(Overflow.AUTO);
          }}
        />
      )}
    </>
  );
};

export default Challenge;
