import device from 'current-device';
import ButtonBase from '@/components/ButtonBase';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import { ButtonBaseProps } from '@mui/material';
import { Moment } from 'moment';

type OpenButtonProps = Omit<ButtonBaseProps, 'children'> & {
  displayedDate: Moment;
};

const StyledOpenButton = styled(ButtonBase)({
  marginTop: -8,
  height: 32,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  ':disabled': {
    cursor: 'not-allowed',
  },
});

const OpenButton = ({ displayedDate, ...props }: OpenButtonProps) => {
  const isMobile = !device.desktop();
  const displayedDateIsEmpty = !displayedDate.isValid();

  return (
    <StyledOpenButton {...props}>
      {displayedDateIsEmpty ? (
        <FormattedMessage
          id={`portfolio.challenge.date_field.button.${
            isMobile ? 'tap' : 'click'
          }`}
        />
      ) : (
        displayedDate.format('L')
      )}
    </StyledOpenButton>
  );
};

export default OpenButton;
