import { FormattedMessage } from 'react-intl';
import FormattedHtmlMessage from '@/components/FormattedHtmlMessage';
import { Checkbox } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@/components/Dialog';
import UserContext from '../../UserContext';
import useGlobalStaticApiResponse from '@/lib/api/useGlobalStaticApiResponse';
import LoadingMessage from './LoadingMessage';
import ConfirmationFormControlLabel from './ConfirmationFormControlLabel';
import PerspectiveCohortSelect from './PerspectiveCohortSelect';
import { PerspectiveCohort } from '@/lib/types';

type PerspectiveCohortManagerDialogContentProps = {
  selectedPerspectiveCohort: number | '';
  setSelectedPerspectiveCohort: (perspectiveCohortId: number | '') => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSave: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const PerspectiveCohortManagerDialogContent = ({
  selectedPerspectiveCohort,
  setSelectedPerspectiveCohort,
  onCancel,
  onSave,
}: PerspectiveCohortManagerDialogContentProps) => {
  const user = useContext(UserContext);

  const [loading, perspectiveCohorts] = useGlobalStaticApiResponse<
    PerspectiveCohort[]
  >('/users/published-perspective-cohorts');

  const [actionConfirmed1, setActionConfirmed1] = useState(false);
  const [actionConfirmed2, setActionConfirmed2] = useState(false);
  const [actionConfirmed3, setActionConfirmed3] = useState(false);

  useEffect(() => {
    setActionConfirmed1(false);
    setActionConfirmed2(false);
    setActionConfirmed3(false);
  }, [selectedPerspectiveCohort]);

  const selectedPerspectiveCohortHasChanged =
    selectedPerspectiveCohort !== (user.perspectiveCohortId ?? '');

  return (
    <>
      <Dialog.Title>
        <FormattedMessage id="user_manager.perspective_cohort_manager.title" />
      </Dialog.Title>
      <Dialog.Content>
        <Dialog.ContentText>
          <FormattedHtmlMessage
            id="user_manager.perspective_cohort_manager.text"
            values={{
              userName: user.name,
            }}
          />
        </Dialog.ContentText>
        {loading ? (
          <LoadingMessage />
        ) : (
          <PerspectiveCohortSelect
            perspectiveCohorts={perspectiveCohorts}
            value={selectedPerspectiveCohort}
            onChange={e =>
              setSelectedPerspectiveCohort(e.target.value as number | '')
            }
          />
        )}
        {user.perspectiveCohortId !== null &&
          selectedPerspectiveCohortHasChanged && (
            <>
              <ConfirmationFormControlLabel
                label={
                  <FormattedMessage id="user_manager.perspective_cohort_manager.confirmations.1" />
                }
                control={<Checkbox />}
                checked={actionConfirmed1}
                onChange={(_, checked) => setActionConfirmed1(checked)}
              />
              <ConfirmationFormControlLabel
                label={
                  <FormattedMessage id="user_manager.perspective_cohort_manager.confirmations.2" />
                }
                control={<Checkbox />}
                checked={actionConfirmed2}
                onChange={(_, checked) => setActionConfirmed2(checked)}
              />
              <ConfirmationFormControlLabel
                label={
                  <FormattedHtmlMessage id="user_manager.perspective_cohort_manager.confirmations.3" />
                }
                control={<Checkbox />}
                checked={actionConfirmed3}
                onChange={(_, checked) => setActionConfirmed3(checked)}
              />
            </>
          )}
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Actions.CancelButton onClick={onCancel} />
        <Dialog.Actions.DeleteButton
          onClick={onSave}
          disabled={
            !selectedPerspectiveCohortHasChanged ||
            (user.perspectiveCohortId !== null &&
              (!actionConfirmed1 || !actionConfirmed2 || !actionConfirmed3))
          }
        >
          <FormattedMessage id="dialogs.actions.save" />
        </Dialog.Actions.DeleteButton>
      </Dialog.Actions>
    </>
  );
};

export default PerspectiveCohortManagerDialogContent;
