import useCollapsedState from './useCollapsedState';
import Subtopic from './Subtopic';
import TopicNameWrapper from '@/components/RubricsPortfolioComponents/StyledTopic/TopicNameWrapper';
import CollapseButton from '@/components/RubricsPortfolioComponents/CollapseButton';
import TopicContent from '@/components/RubricsPortfolioComponents/StyledTopic/TopicContent';
import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import useUserCanViewItem from '@/lib/rubricsPortfolioUtils/useUserCanViewItem';
import { Topic as TopicType } from '@/lib/types';

type TopicProps = {
  topic: TopicType;
  isFirstTopic: boolean;
};

const Topic = ({ topic, isFirstTopic }: TopicProps) => {
  const [collapsed, toggleCollapsed] = useCollapsedState(
    topic.id,
    isFirstTopic,
  );

  const userCanViewItem = useUserCanViewItem();

  const subtopics = topic.subtopics
    .filter(userCanViewItem)
    .sort(sortByPosition);

  return (
    <section>
      <TopicNameWrapper onClick={toggleCollapsed}>
        <h2>{topic.name}</h2>
        <CollapseButton collapsed={collapsed} />
      </TopicNameWrapper>
      <TopicContent collapsed={collapsed}>
        {subtopics.map(subtopic => (
          <Subtopic
            subtopic={subtopic}
            belongsToFirstTopic={isFirstTopic}
            key={subtopic.id}
          />
        ))}
      </TopicContent>
    </section>
  );
};

export default Topic;
