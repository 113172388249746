import { useContext, useState } from 'react';
import Dialog from '@/components/Dialog';
import PickableRows from './PickableRows';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import ChallengeContext from '../../../ChallengeContext';
import NewlySharedContext from '@/components/ChallengePortfolio/NormalView/NewlySharedChallenges/NewlySharedContext';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import useApi from '@/lib/api/useApi';
import { ChallengeWithoutRelations } from '@/lib/types';

type RowPickerProps = {
  open: boolean;
  onClose: () => void;
};

const RowPicker = ({ open, onClose }: RowPickerProps) => {
  const api = useApi();

  const [, newlySharedChallenges, , removeChallengeFromNewlyShared] =
    useContext(NewlySharedContext);
  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const [challenge] = useContext(ChallengeContext);

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [saving, setSaving] = useState(false);

  const saveRowChoice = async () => {
    onClose();

    if (!selectedRow) {
      return;
    }

    setSaving(true);

    const updatedChallenge = await api.post<ChallengeWithoutRelations>(
      `challenge-portfolio/challenges/${challenge.id}/drag`,
      {
        phase: challenge.phase,
        newRowId: selectedRow,
      },
    );

    setSaving(false);

    setChallengePortfolio(challengePortfolio => {
      challengePortfolio.challenges[challenge.id] = {
        ...newlySharedChallenges[challenge.id],
        ...updatedChallenge,
      };
      challengePortfolio.rows[selectedRow].challenges.push(updatedChallenge.id);
    });

    removeChallengeFromNewlyShared(challenge.id);
  };

  return (
    <Dialog open={open} onClose={onClose} onEnterClose={onClose}>
      <Dialog.Title>
        <FormattedMessage id="portfolio.challenge.newly_shared.row_picker.title" />
      </Dialog.Title>
      <Dialog.Content style={{ overflowY: 'visible' }}>
        <Dialog.ContentText>
          <FormattedMessageWithChallengeNaming id="portfolio.challenge.newly_shared.row_picker.text" />
        </Dialog.ContentText>
      </Dialog.Content>
      <PickableRows
        selectedRow={selectedRow}
        onSelectRow={row => setSelectedRow(row)}
      />
      <Dialog.Actions>
        <Dialog.Actions.CancelButton onClick={onClose} />
        <Dialog.Actions.SaveButton onClick={saveRowChoice} disabled={saving} />
      </Dialog.Actions>
    </Dialog>
  );
};

export default RowPicker;
