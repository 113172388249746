import App from '@/lib/App';
import { useMediaQuery } from '@mui/material';
import AppMode from '@/lib/constants/AppMode.enum';
import UserRole from '@/lib/constants/UserRole.enum';
import StyledButton from './StyledButton';
import { FormattedMessage } from 'react-intl';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';

const CoachModeButton = () => {
  const authenticatedUser = useAuthenticatedUser()!;
  const selectedMode = App.navigation.useSelectedMode();
  const useOutlinedButton = useMediaQuery('(max-width: 700px)');

  if (
    selectedMode !== AppMode.PORTFOLIO ||
    authenticatedUser.role === UserRole.STUDENT
  ) {
    return null;
  }

  const replacesModeSwitch = authenticatedUser.role === UserRole.COACH;

  return (
    <StyledButton
      variant={useOutlinedButton ? 'outlined' : 'text'}
      to="/coach"
      noResponsivityHiding={replacesModeSwitch}
    >
      <FormattedMessage id="coach_mode_button" />
    </StyledButton>
  );
};

export default CoachModeButton;
