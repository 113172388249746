import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

// TODO no inline styles
const ViewAllReleasesButton = () => (
  <Button
    variant="outlined"
    color="secondary"
    component="a"
    href="https://medium.com/egodact/updates/home"
    target="_blank"
    style={{
      fontSize: 13,
    }}
    fullWidth
  >
    <FormattedMessage id="header.options_menu.releases_overview.view_all_releases_button" />
  </Button>
);

export default ViewAllReleasesButton;
