import { Tooltip, IconButton, IconButtonProps } from '@mui/material';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import mediaQuery from '@/lib/styling/mediaQuery';
import PerspectivesIcon from '@/components/PerspectivesIcon';

const StyledTooltip = styled(Tooltip)({
  marginLeft: 'auto',
  marginRight: 12,
  [mediaQuery(1200)]: {
    marginRight: -12,
  },
  [mediaQuery(700)]: {
    display: 'none',
  },
});

const PerspectiveCohortManagerButton = (props: IconButtonProps) => (
  <StyledTooltip
    title={
      <FormattedMessage id="user_manager.perspective_cohort_manager.button_tooltip" />
    }
  >
    <IconButton {...props}>
      <PerspectivesIcon />
    </IconButton>
  </StyledTooltip>
);

export default PerspectiveCohortManagerButton;
