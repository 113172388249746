import { useContext } from 'react';
import CreateChallenge from './CreateChallenge';
import Challenge from '../../Challenge';
import PhaseContext from '../../PhaseContext';
import PhaseColumn from './PhaseColumn';
import useChallengePhaseDrop from './useChallengePhaseDrop';
import RowContext from '../../Rows/Row/RowContext';
import ResponsiveMasonry from './ResponsiveMasonry';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import { ChallengeSummary } from '@/lib/types';
import sortByCreatedAt from '@/lib/utils/sorting/sortByCreatedAt';

type KanbanPhaseColumnProps = {
  phase: KanbanPhase;
  challenges: ChallengeSummary[];
  collapsed: boolean;
};

// TODO memo should be applied on passed-in challenges, prevents a lot of
// unnecessary downstream computations and allow usage of downstream memo'ing
const KanbanPhaseColumn = ({
  phase,
  challenges,
  collapsed,
}: KanbanPhaseColumnProps) => {
  const row = useContext(RowContext);
  const attachDropHandlingRef = useChallengePhaseDrop(phase, row);

  return (
    <PhaseContext.Provider value={phase}>
      <PhaseColumn
        hasItems={challenges.length > 0}
        collapsed={collapsed}
        ref={attachDropHandlingRef}
      >
        <ResponsiveMasonry collapsed={collapsed}>
          {challenges.sort(sortByCreatedAt).map(challenge => (
            <Challenge
              challenge={challenge}
              collapsed={collapsed}
              key={challenge.id}
            />
          ))}
        </ResponsiveMasonry>
        {!collapsed && phase === KanbanPhase.TODO && <CreateChallenge />}
      </PhaseColumn>
    </PhaseContext.Provider>
  );
};

export default KanbanPhaseColumn;
