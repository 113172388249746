import { useContext } from 'react';
import UserContext from '../UserContext';
import StyledUserName from './StyledUserName';
import UserRole from '@/lib/constants/UserRole.enum';
import SuperAdminLabel from './SuperAdminLabel';
import { FormattedMessage } from 'react-intl';

const UserName = () => {
  const user = useContext(UserContext);

  return (
    <StyledUserName>
      {user.name}
      {user.role === UserRole.SUPER_ADMIN && (
        <SuperAdminLabel>
          <FormattedMessage id="user_roles.super_admin" />
        </SuperAdminLabel>
      )}
    </StyledUserName>
  );
};

export default UserName;
