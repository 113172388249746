import { useContext } from 'react';
import FieldName from '../utils/FieldName';
import ChallengeNotSharedText from './ChallengeNotSharedText';
import AssigneesManager from './AssigneesManager';
import ChallengeContext from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeContext';
import IsAnonymizedContext from '@/pages/OpenChallengePage/IsAnonymizedContext';
import AnonymizedAssigneesManager from './AnonymizedAssigneesManager';

const TaskAssignment = () => {
  const [challenge] = useContext(ChallengeContext);
  const isAnonymized = useContext(IsAnonymizedContext);

  const challengeUserCount = challenge.usersCount;
  const challengeIsShared = challengeUserCount > 1;

  return (
    <section>
      <FieldName />
      {challengeIsShared && !isAnonymized && <AssigneesManager />}
      {challengeIsShared && isAnonymized && <AnonymizedAssigneesManager />}
      {!challengeIsShared && <ChallengeNotSharedText />}
    </section>
  );
};

export default TaskAssignment;
