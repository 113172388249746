import SupportType from '../SupportType.enum';
import ContactInfo from './ContactInfo';
import FeedbackForm from './FeedbackForm';
import IssueReporting from './IssueReporting';
import React from 'react';

type SupportContentRendererProps = {
  selectedSupportType: SupportType | null;
  setDialogActions: (dialogActions: React.ReactNode) => void;
  onGoBack: () => void;
  onDialogClose: () => void;
};

const SupportContentRenderer = ({
  selectedSupportType,
  setDialogActions,
  onGoBack,
  onDialogClose,
}: SupportContentRendererProps) => {
  switch (selectedSupportType) {
    case SupportType.CONTACT: {
      return <ContactInfo />;
    }
    case SupportType.FEEDBACK: {
      return (
        <FeedbackForm
          setDialogActions={setDialogActions}
          onGoBack={onGoBack}
          onDialogClose={onDialogClose}
        />
      );
    }
    case SupportType.ISSUE_REPORTING: {
      return <IssueReporting />;
    }
    default: {
      return null;
    }
  }
};

export default SupportContentRenderer;
