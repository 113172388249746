import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';

const StyledLinkIconButton = styled(IconButton)({
  [mediaQuery(640)]: {
    display: 'none',
  },
});

export default StyledLinkIconButton;
