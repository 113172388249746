import { AddLink as AttachIcon } from '@mui/icons-material';
import { IconButtonProps, Tooltip } from '@mui/material';
import AttachmentControlButton from './AttachmentControlButton';
import { FormattedMessage } from 'react-intl';

type AttachButtonProps = Omit<IconButtonProps, 'className' | 'children'>;

const AttachButton = (props: AttachButtonProps) => (
  <Tooltip
    title={
      <FormattedMessage id="perspectives.attachment_controls.attach_button" />
    }
    arrow
  >
    <AttachmentControlButton
      className="perspective-card__attach-button"
      {...props}
    >
      <AttachIcon />
    </AttachmentControlButton>
  </Tooltip>
);

export default AttachButton;
