/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import useComplexState from '../utils/useComplexState';
import { DraftFunction } from 'use-immer';
import dummyFunction from '../utils/dummyFunction';
import { SetComplexImmerStateAction } from '../types';
import useSwr from './useSwr';
import { SWRConfiguration } from 'swr';

const useSwrAsComplexState = <T>(
  url: string | null,
  swrConfig: SWRConfiguration = {},
): [true, null, () => void] | [false, T, SetComplexImmerStateAction<T>] => {
  const [loading, apiResponse] = useSwr<T>(url, swrConfig);
  const [state, setInnerState] = useComplexState<T | null>(null);

  useEffect(() => {
    setInnerState(apiResponse);

    if (loading === false && apiResponse === null) {
      throw new Error('api must return a non-null response.');
    }
  }, [loading, apiResponse]);

  const setState = (stateUpdateFunction: DraftFunction<T>): void => {
    if (loading || state === null) {
      throw new Error('Cannot call setState() before API response is loaded.');
    }

    if (typeof stateUpdateFunction !== 'function') {
      throw new Error(
        'State can only be updated through a state update function.',
      );
    }

    // T is actually guaranteed to not be null at this point
    setInnerState(stateUpdateFunction as DraftFunction<T | null>);
  };

  if (loading || state === null) {
    return [true, null, dummyFunction];
  }

  return [false, state, setState];
};

export default useSwrAsComplexState;
