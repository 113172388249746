import { IconButton, IconButtonProps } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

type EditButtonProps = Omit<IconButtonProps, 'children'>;

const EditButton = (props: EditButtonProps) => (
  <IconButton {...props}>
    <EditIcon />
  </IconButton>
);

export default EditButton;
