import React from 'react';
import ContentWrapper from './PlatformLoadingView/ContentWrapper';
import MessageWrapper from './PlatformLoadingView/MessageWrapper';
import LoadingSpinner from './LoadingSpinner';
import Space from './Space';
import PerspectivesIcon from './PerspectivesIcon';
import { HTMLDivProps } from '@/lib/types';

type PerspectivesLoadingViewProps = Omit<HTMLDivProps, 'children'> & {
  loadingMessage: React.ReactNode;
};

const PerspectivesLoadingView = ({
  loadingMessage,
  ...props
}: PerspectivesLoadingViewProps) => {
  return (
    <ContentWrapper {...props}>
      <PerspectivesIcon size={74} />
      <Space height={24} />
      <MessageWrapper>
        <LoadingSpinner />
        <Space width={12} />
        {loadingMessage}
      </MessageWrapper>
    </ContentWrapper>
  );
};

export default PerspectivesLoadingView;
