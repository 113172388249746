import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

const StyledMessage = styled('p')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const CategoryEmptyMessage = () => (
  <StyledMessage>
    <FormattedMessage id="perspectives.categorization.category_empty_message" />
  </StyledMessage>
);

export default CategoryEmptyMessage;
