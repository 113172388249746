import { useState } from 'react';

const disallowedCharactersRegex = /[^0123456789ABCDEF]/g;

const sanitizeColor = (color: string): string => {
  return color
    .toUpperCase()
    .replaceAll(disallowedCharactersRegex, '')
    .slice(0, 6);
};

const useColorState = (
  defaultColor?: string,
): [string, (color: string) => void] => {
  const [color, setColor] = useState(sanitizeColor(defaultColor ?? ''));

  const changeColor = (newColor: string): void => {
    setColor(sanitizeColor(newColor));
  };

  return [color, changeColor];
};

export default useColorState;
