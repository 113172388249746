import LinkWithWhiteBackground from './LinkWithWhiteBackground';
import StyledLogo from './StyledLogo';
import { useIntl } from 'react-intl';
import useLogoUrl from '@/lib/utils/useLogoUrl';
import FallbackLogo from './FallbackLogo';

const HeaderLogo = () => {
  const logoUrl = useLogoUrl();

  const intl = useIntl();

  if (logoUrl === null) {
    return <FallbackLogo />;
  }

  return (
    <LinkWithWhiteBackground to="/">
      <StyledLogo
        src={logoUrl}
        alt={intl.formatMessage({ id: 'header.logo_alt' })}
      />
    </LinkWithWhiteBackground>
  );
};

export default HeaderLogo;
