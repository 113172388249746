import { AllowedEmail } from '@/lib/types';
import sortByValue from '@/lib/utils/sorting/sortByValue';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

export type AllowedEmailWithId = {
  id: number;
  allowedEmail: AllowedEmail;
};

type AllowedEmailsState = {
  allowedEmails: AllowedEmailWithId[];
  allowedEmailsAsObject: Record<number, AllowedEmail>;
  addAllowedEmail: (allowedEmail: AllowedEmail) => void;
  editAllowedEmail: (id: number, updatedAllowedEmail: AllowedEmail) => void;
  removeAllowedEmail: (id: number) => void;
};

const arrayToObject = <T>(array: T[]): Record<number, T> => {
  return Object.fromEntries(Object.entries(array));
};

// TODO refactor this
const useAllowedEmailsState = (
  initialAllowedEmails: AllowedEmail[],
): AllowedEmailsState => {
  // Note: we can ignore sync re-enabling, because the page refreshes when
  // saving
  const [allowedEmails, setAllowedEmails] = useComplexSwrState(
    arrayToObject(initialAllowedEmails ?? []),
    {
      stringifyChangeCheck: true,
    },
  );

  const allowedEmailsArray: AllowedEmailWithId[] = Object.entries(
    allowedEmails,
  ).map(([id, allowedEmail]) => ({
    id: Number(id),
    allowedEmail,
  }));

  const addAllowedEmail = (newAllowedEmail: AllowedEmail): void => {
    const allowedEmailIds = Object.keys(allowedEmails);
    const highestId = Number(
      allowedEmailIds.map(Number).sort(sortByValue).reverse()[0] ?? -1,
    );
    const newAllowedEmailId = highestId + 1;

    setAllowedEmails({
      ...allowedEmails,
      [newAllowedEmailId]: newAllowedEmail,
    });
  };

  const editAllowedEmail = (
    id: number,
    updatedAllowedEmail: AllowedEmail,
  ): void => {
    setAllowedEmails({
      ...allowedEmails,
      [id]: updatedAllowedEmail,
    });
  };

  const removeAllowedEmail = (id: number): void => {
    const newAllowedEmails = { ...allowedEmails };
    delete newAllowedEmails[id];

    const newAllowedEmailsWithoutIdGaps = {
      ...Object.values(newAllowedEmails),
    };

    setAllowedEmails(newAllowedEmailsWithoutIdGaps);
  };

  return {
    allowedEmails: allowedEmailsArray,
    allowedEmailsAsObject: allowedEmails,
    addAllowedEmail,
    editAllowedEmail,
    removeAllowedEmail,
  };
};

export default useAllowedEmailsState;
