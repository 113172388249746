import styled from '@emotion/styled';
import ButtonBase from '@/components/ButtonBase';
import { ButtonProps } from '@mui/material';

type StyledGroupSelectorButtonProps = ButtonProps & {
  hasSelectedGroup: boolean;
};

const StyledGroupSelectorButton = styled(ButtonBase)<
  StyledGroupSelectorButtonProps
>(
  ({ theme }) => ({
    padding: '10px 15px',
    width: '100%',
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    transition: theme.transitions.create('all'),
    ':hover, :focus': {
      backgroundColor: theme.palette.action.hover,
    },
  }),
  ({ theme, hasSelectedGroup }) => {
    if (!hasSelectedGroup) {
      return {
        color: theme.palette.text.secondary,
        fontStyle: 'italic',
      };
    }
  },
);

export default StyledGroupSelectorButton;
