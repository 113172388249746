import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SelectedUserContext from './SelectedUserContext';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import { ReactContextWithAutomatedProvider } from '../types';

const ShowPerspectiveCategorizationViewContext = createContext<
  [boolean, React.Dispatch<React.SetStateAction<boolean>>]
>([false, () => {}]) as ReactContextWithAutomatedProvider<
  [boolean, React.Dispatch<React.SetStateAction<boolean>>]
>;

const OriginalShowPerspectiveCategorizationViewContextProvider =
  ShowPerspectiveCategorizationViewContext.Provider;

type ShowPerspectiveCategorizationViewContextProviderProps = {
  children: React.ReactNode;
};

const ShowPerspectiveCategorizationViewContextProvider = ({
  children,
}: ShowPerspectiveCategorizationViewContextProviderProps) => {
  const [
    showPerspectiveCategorizationView,
    setShowPerspectiveCategorizationView,
  ] = useState(false);

  const authenticatedUser = useAuthenticatedUser();
  const location = useLocation();
  const selectedUser = useContext(SelectedUserContext);

  useEffect(() => {
    setShowPerspectiveCategorizationView(false);
  }, [authenticatedUser, location, selectedUser]);

  return (
    <OriginalShowPerspectiveCategorizationViewContextProvider
      value={[
        showPerspectiveCategorizationView,
        setShowPerspectiveCategorizationView,
      ]}
    >
      {children}
    </OriginalShowPerspectiveCategorizationViewContextProvider>
  );
};

ShowPerspectiveCategorizationViewContext.AutomatedProvider = ShowPerspectiveCategorizationViewContextProvider;

export default ShowPerspectiveCategorizationViewContext;
