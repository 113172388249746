import React from 'react';
import useLocalStorageState from 'use-local-storage-state';

const useHighContrastModeEnabled = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const [
    highContrastModeEnabled,
    setHighContrastModeEnabled,
  ] = useLocalStorageState('enable-high-contrast-mode', {
    defaultValue: false,
  });

  return [highContrastModeEnabled, setHighContrastModeEnabled];
};

export default useHighContrastModeEnabled;
