import CardWrapper from './CardWrapper';
import PhaseText from './PhaseText';
import getPhaseName from '@/lib/utils/getPhaseName';
import ChallengeUserAvatars from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeCard/ChallengeUserAvatars';
import { ChallengeSummary } from '@/lib/types';

type ChallengePreviewCardProps = {
  challenge: ChallengeSummary;
  contentVisibilityCompensationDistance: number;
};

const ChallengePreviewCard = ({
  challenge,
  contentVisibilityCompensationDistance,
}: ChallengePreviewCardProps) => (
  <CardWrapper
    className="challenge-preview-card"
    contentVisibilityCompensationDistance={
      contentVisibilityCompensationDistance
    }
  >
    <h3>{challenge.name}</h3>
    <PhaseText>{getPhaseName(challenge.phase)}</PhaseText>
    <ChallengeUserAvatars challenge={challenge} />
  </CardWrapper>
);

export default ChallengePreviewCard;
