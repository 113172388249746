import useCurrentFieldValue from '../utils/useCurrentFieldValue';
import useSetCurrentFieldValue from '../utils/useSetCurrentFieldValue';
import InnerDateField from './InnerDateField';

const DateField = () => {
  const currentFieldValue = useCurrentFieldValue<number | null>(
    null,
    value => value === null || typeof value === 'number',
  );
  const [setCurrentFieldValue] = useSetCurrentFieldValue<number | null>();

  const saveDateChange = async (timestamp: number | null): Promise<void> => {
    await setCurrentFieldValue(timestamp);
  };

  return (
    <InnerDateField
      initialValue={currentFieldValue}
      onSave={saveDateChange}
      clearable
    />
  );
};

export default DateField;
