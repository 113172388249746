import { useState } from 'react';
import Dialog from '@/components/Dialog';
import ChallengeNameField from './ChallengeNameField';
import CreationDialogTitle from './CreationDialogTitle';
import ChallengeRowAttachment from './ChallengeRowAttachment';
import RowAttachmentType from './RowAttachmentType.enum';
import useCreateRow from '../CreateRow/useCreateRow';
import getErrorsFromState from './getErrorsFromState';
import useCreateChallenge from '../../ChallengeBoard/KanbanPhaseColumn/CreateChallenge/useCreateChallenge';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import ChallengeCreationError from './ChallengeCreationError.enum';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

type ChallengeCreationDialogProps = {
  open: boolean;
  rowIds: number[];
  onClose: () => void;
};

const ChallengeCreationDialog = ({
  open,
  rowIds,
  onClose,
}: ChallengeCreationDialogProps) => {
  const [challengeName, setChallengeName] = useState('');

  const hasNoRows = rowIds.length === 0;
  const [
    selectedRowAttachmentType,
    setSelectedRowAttachmentType,
    ,
    reenableSelectedRowAttachmentTypeSync,
  ] = useComplexSwrState(
    hasNoRows ? RowAttachmentType.NEW_ROW : RowAttachmentType.EXISTING_ROW,
  );

  const [newRowName, setNewRowName] = useState('');
  const [selectedExistingRow, setSelectedExistingRow] = useState<number | null>(
    null,
  );

  const [errors, setErrors] = useState<ChallengeCreationError[]>([]);

  const resetState = () => {
    setChallengeName('');
    setSelectedRowAttachmentType(RowAttachmentType.EXISTING_ROW);
    reenableSelectedRowAttachmentTypeSync();
    setNewRowName('');
    setSelectedExistingRow(null);
  };

  const close = () => {
    setErrors([]);
    onClose();
  };

  const createRow = useCreateRow();
  const createChallenge = useCreateChallenge();

  const saveChallenge = async (): Promise<void> => {
    const errors = getErrorsFromState(
      challengeName,
      selectedRowAttachmentType,
      selectedExistingRow,
      newRowName,
    );

    setErrors(errors);

    if (errors.length !== 0) {
      return;
    }

    const rowId =
      selectedRowAttachmentType === RowAttachmentType.NEW_ROW
        ? (await createRow(newRowName.trim())).id
        : selectedExistingRow!;

    createChallenge(challengeName, KanbanPhase.TODO, rowId);
    resetState();
    close();

    reenableSelectedRowAttachmentTypeSync();
  };

  return (
    <Dialog open={open} onClose={close} onEnterClose={saveChallenge}>
      <CreationDialogTitle />
      <ChallengeNameField
        value={challengeName}
        onChange={e => setChallengeName(e.target.value)}
        hasError={errors.includes(ChallengeCreationError.CHALLENGE_NAME_EMPTY)}
      />
      <ChallengeRowAttachment>
        <ChallengeRowAttachment.Title />
        <ChallengeRowAttachment.TypeOptions
          selectedRowAttachmentType={selectedRowAttachmentType}
          onChangeSelectedRowAttachmentType={selectedRowAttachmentType =>
            setSelectedRowAttachmentType(selectedRowAttachmentType)
          }
        >
          <ChallengeRowAttachment.TypeOptions.ExistingRow
            value={RowAttachmentType.EXISTING_ROW}
            selected={
              selectedRowAttachmentType === RowAttachmentType.EXISTING_ROW
            }
            selectedRow={selectedExistingRow}
            onChangeSelectedRow={(newSelectedRow: number) =>
              setSelectedExistingRow(newSelectedRow)
            }
            disabled={hasNoRows}
            hasError={errors.includes(ChallengeCreationError.NO_ROW_SELECTED)}
          />
          <ChallengeRowAttachment.TypeOptions.NewRow
            value={RowAttachmentType.NEW_ROW}
            selected={selectedRowAttachmentType === RowAttachmentType.NEW_ROW}
            newRowName={newRowName}
            onChangeNewRowName={newRowName => setNewRowName(newRowName)}
            hasError={errors.includes(ChallengeCreationError.ROW_NAME_EMPTY)}
            challengeNameHasError={errors.includes(
              ChallengeCreationError.CHALLENGE_NAME_EMPTY,
            )}
          />
        </ChallengeRowAttachment.TypeOptions>
      </ChallengeRowAttachment>
      <Dialog.Actions>
        <Dialog.Actions.CancelButton onClick={close} />
        <Dialog.Actions.CreateButton onClick={saveChallenge} />
      </Dialog.Actions>
    </Dialog>
  );
};

export default ChallengeCreationDialog;
