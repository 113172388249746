import { useContext } from 'react';
import App from '@/lib/App';
import AppMode from '@/lib/constants/AppMode.enum';
import PortfolioUserContext from '@/lib/contexts/PortfolioUserContext';
import { ChallengePortfolio, SetComplexImmerStateAction } from '@/lib/types';
import dummyFunction from '@/lib/utils/dummyFunction';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

const useChallengePortfolio = ():
  | [true, null, () => void]
  | [
      false,
      ChallengePortfolio,
      SetComplexImmerStateAction<ChallengePortfolio>,
    ] => {
  const portfolioUser = useContext(PortfolioUserContext)!;
  // TODO use PortfolioModeContext
  const selectedMode = App.navigation.useSelectedMode();

  const challengePortfolioUrl =
    selectedMode === AppMode.COACH
      ? `challenge-portfolio/coach?userEmail=${portfolioUser.email}`
      : 'challenge-portfolio';

  const [challengePortfolioLoading, challengePortfolio, setChallengePortfolio] =
    usePolledSwrAsComplexState<ChallengePortfolio>(challengePortfolioUrl);

  if (challengePortfolioLoading) {
    return [true, null, dummyFunction];
  }

  return [challengePortfolioLoading, challengePortfolio, setChallengePortfolio];
};

export default useChallengePortfolio;
