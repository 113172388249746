import styled from '@emotion/styled';
import ButtonBase from '@/components/ButtonBase';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import { alpha } from '@mui/material';

type PillButtonProps = {
  leftHalf?: boolean;
  rightHalf?: boolean;
  standAlone?: boolean;
  noStandAloneMargin?: boolean;
  dangerous?: boolean;
};

const PillButton = styled(ButtonBase)<PillButtonProps>(
  ({ theme, dangerous }) => {
    const color = dangerous ? theme.palette.danger : theme.palette.primary.main;
    return {
      padding: 6,
      color: color,
      backgroundColor: alpha(color, 0.2),
      fontFamily: 'CircularStd',
      display: 'inline-flex',
      overflow: 'hidden',
      transition: theme.transitions.create('all'),
      ':not(:disabled):hover, :not(:disabled):focus': {
        color: color,
        backgroundColor: alpha(color, 0.3),
      },
      ':disabled': {
        opacity: 0.7,
      },
    };
  },
  ({ leftHalf, rightHalf, standAlone }) => {
    if (leftHalf) {
      return {
        borderRadius: '18px 0 0 18px',
      };
    }

    if (rightHalf) {
      return {
        borderRadius: '0 18px 18px 0',
      };
    }

    if (standAlone) {
      return {
        borderRadius: 18,
      };
    }
  },
  ({ standAlone, noStandAloneMargin }) => {
    if (standAlone && !noStandAloneMargin) {
      return {
        marginLeft: 6,
        marginRight: 6,
        marginBottom: 12,
      };
    }
  },
  addHighContrastStyles(({ theme }) => ({
    padding: 4,
    backgroundColor: 'transparent',
    border: `2px solid ${theme.palette.divider}`,
    boxSizing: 'border-box',
    ':not(:last-child)': {
      borderRight: 'none',
    },
    '> *': {
      transition: theme.transitions.create('all'),
    },
    ':hover, :focus': {
      backgroundColor: 'transparent',
      '> *': {
        transform: 'scale(1.1)',
      },
    },
  })),
);

export default PillButton;
