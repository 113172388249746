/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import useSelectedTheme from '@/lib/utils/useSelectedTheme';
import useDetermineDarkModeAutomatically from '@/lib/utils/useDetermineDarkModeAutomatically';
import { FormControl, FormLabel, RadioGroup } from '@mui/material';
import ThemeOption from './ThemeOption';
import Theme from '@/lib/constants/Theme.enum';
import { FormattedMessage } from 'react-intl';

export const APPEARANCE_DEVICE_THEME = 'APPEARANCE_DEVICE_THEME';

type ThemeOrDeviceTheme = Theme | typeof APPEARANCE_DEVICE_THEME;

const AppearanceSelector = () => {
  const [selectedTheme, setSelectedTheme] = useSelectedTheme();
  const [
    determineDarkModeAutomatically,
    setDetermineDarkModeAutomatically,
  ] = useDetermineDarkModeAutomatically();

  const initialThemeValue = determineDarkModeAutomatically
    ? APPEARANCE_DEVICE_THEME
    : selectedTheme;
  const [appearanceValue, setAppearanceValue] = useState<ThemeOrDeviceTheme>(
    initialThemeValue,
  );

  useEffect(() => {
    if (appearanceValue === APPEARANCE_DEVICE_THEME) {
      setDetermineDarkModeAutomatically(true);
      return;
    }

    setDetermineDarkModeAutomatically(false);
    setSelectedTheme(appearanceValue);
  }, [appearanceValue, selectedTheme, determineDarkModeAutomatically]);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <FormattedMessage id="header.options_menu.display_settings.appearance.label" />
      </FormLabel>
      <RadioGroup
        name="appearance"
        value={appearanceValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setAppearanceValue(e.target.value as ThemeOrDeviceTheme)
        }
      >
        <ThemeOption value={Theme.LIGHT} />
        <ThemeOption value={Theme.DARK} />
        <ThemeOption value={APPEARANCE_DEVICE_THEME} />
      </RadioGroup>
    </FormControl>
  );
};

export default AppearanceSelector;
