import styled from '@emotion/styled';
import { FormControl } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';

const RowSelectWrapper = styled(FormControl)({
  [mediaQuery(420)]: {
    width: '100%',
  },
});

export default RowSelectWrapper;
