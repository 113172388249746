import {
  AttachedTaskSummary,
  AttachmentInfo,
  SetComplexImmerStateAction,
  TaskSummaryWithChallengeUsers,
} from '@/lib/types';
import usePerspectiveEndpointsWithCoachScoping from '../usePerspectiveEndpointsWithCoachScoping';
import dummyFunction from '@/lib/utils/dummyFunction';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

type AttachedTasksState = {
  loading: boolean;
  tasks: Record<number, TaskSummaryWithChallengeUsers>;
  setTasks: SetComplexImmerStateAction<
    Record<number, TaskSummaryWithChallengeUsers>
  >;
  directlyAttachedTasks: TaskSummaryWithChallengeUsers[];
  indirectlyAttachedTasks: TaskSummaryWithChallengeUsers[];
};

const loadingAttachedTasksPlaceholder = {
  loading: true,
  tasks: {},
  setTasks: dummyFunction,
  directlyAttachedTasks: [],
  indirectlyAttachedTasks: [],
} satisfies AttachedTasksState;

const convertMapKeysToTasks = (
  taskMap: Map<number, AttachedTaskSummary>,
  tasks: Record<number, TaskSummaryWithChallengeUsers>,
): TaskSummaryWithChallengeUsers[] => {
  const result = [];

  for (const id of taskMap.keys()) {
    result.push(tasks[id]);
  }

  return result;
};

const useAttachedTasks = (
  attachmentInfo: AttachmentInfo,
): AttachedTasksState => {
  const perspectiveEndpoints = usePerspectiveEndpointsWithCoachScoping();

  const attachedTaskIds = Array.from(
    attachmentInfo.attachedTasks.all.values(),
  ).map(({ id }) => id);

  const [loading, tasks, setTasks] = usePolledSwrAsComplexState<
    Record<number, TaskSummaryWithChallengeUsers>
  >(
    attachedTaskIds.length === 0
      ? null
      : perspectiveEndpoints.attachedTasks(attachedTaskIds),
  );

  if (loading) {
    return loadingAttachedTasksPlaceholder;
  }

  const directlyAttachedTasks = convertMapKeysToTasks(
    attachmentInfo.attachedTasks.directlyAttached,
    tasks,
  );
  const indirectlyAttachedTasks = convertMapKeysToTasks(
    attachmentInfo.attachedTasks.indirectlyAttached,
    tasks,
  );

  return {
    loading,
    tasks,
    setTasks,
    directlyAttachedTasks,
    indirectlyAttachedTasks,
  };
};

export default useAttachedTasks;
