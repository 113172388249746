import WrapperForm from './WrapperForm';
import RowNameInput from './RowNameInput';
import RowName from './RowName';
import CancelChangeButton from './CancelChangeButton';
import SaveButtonWithEnterTrigger from './SaveButtonWithEnterTrigger';
import EditButton from './EditButton';
import { useIntl } from 'react-intl';
import { useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import ChallengePortfolioContext from '../../../../ChallengePortfolioContext';
import useApi from '@/lib/api/useApi';
import { Row, RowWithoutRelations } from '@/lib/types';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

type EditableRowNameProps = {
  row: Row;
  editing: boolean;
  setEditing: (editing: boolean) => void;
};

const EditableRowName = ({
  row,
  editing,
  setEditing,
}: EditableRowNameProps) => {
  const api = useApi();

  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const [newRowName, setNewRowName, , reenableNewRowNameSync] =
    useComplexSwrState(row.name);

  const saveNameChange = async (): Promise<void> => {
    if (newRowName.trim().length === 0) {
      return;
    }

    const updatedRow = await api.put<RowWithoutRelations>(
      `challenge-portfolio/rows/${row.id}/name`,
      {
        name: newRowName.trim(),
      },
    );

    setChallengePortfolio(challengePortfolio => {
      challengePortfolio.rows[row.id] = {
        ...challengePortfolio.rows[row.id],
        ...updatedRow,
      };
    });

    setEditing(false);

    reenableNewRowNameSync();
  };

  const cancelNameChange = () => {
    setNewRowName(row.name);
    reenableNewRowNameSync();

    setEditing(false);
  };

  const intl = useIntl();
  const editButtonIsInMenu = useMediaQuery('(max-width: 560px)');

  return (
    <WrapperForm onSubmit={e => e.preventDefault()} fullWidth={editing}>
      {editing ? (
        <>
          <RowNameInput
            placeholder={intl.formatMessage({
              id: 'portfolio.row.edit.input_placeholder',
            })}
            value={newRowName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewRowName(e.target.value)
            }
            onEscPress={cancelNameChange}
            autoFocus
          />
          <CancelChangeButton
            visible={newRowName !== row.name}
            onClick={cancelNameChange}
          />
          <SaveButtonWithEnterTrigger
            onClick={saveNameChange}
            disabled={!newRowName.trim()}
          />
        </>
      ) : (
        <>
          <RowName>{row.name}</RowName>
          {!editButtonIsInMenu && (
            <EditButton onClick={() => setEditing(true)} />
          )}
        </>
      )}
    </WrapperForm>
  );
};

export default EditableRowName;
