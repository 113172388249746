import { TextareaAutosizeProps } from '@mui/material';
import StyledTextareaWithAutosizing from './StyledTextareaWithAutosizing';

type FormattedTextProps = Omit<
  TextareaAutosizeProps,
  'value' | 'tabIndex' | 'readOnly' | 'children'
> & {
  children: string;
};

const FormattedText = ({ children, ...props }: FormattedTextProps) => (
  <StyledTextareaWithAutosizing
    defaultValue={children}
    tabIndex={-1}
    readOnly
    {...props}
  />
);

export default FormattedText;
