import styled from '@emotion/styled';
import ButtonBase from '@/components/ButtonBase';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

const StyledClearButton = styled(ButtonBase)(
  ({ theme }) => ({
    padding: '6px 15px',
    width: '100%',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
    transition: theme.transitions.create('all'),
    display: 'flex',
    alignItems: 'center',
    ':not(:active):hover, :not(:active):focus': {
      backgroundColor: theme.palette.action.selected,
    },
  }),
  addHighContrastStyles(({ theme }) => ({
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${theme.palette.divider}`,
    ':hover, :focus': {
      backgroundColor: theme.palette.background.default,
      textDecoration: 'underline',
    },
  })),
);

export default StyledClearButton;
