import { useContext } from 'react';
import useApi from '@/lib/api/useApi';
import ChallengePortfolioContext from '../../../ChallengePortfolioContext';
import { Row } from '@/lib/types';

const useRowMoveHandlers = (
  row: Row,
  previousRow: Row | null,
  nextRow: Row | null,
): [() => void, () => void] => {
  const api = useApi();

  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);

  const swapPositionWith = (rowToSwapWith: Row): void => {
    api.post(
      `challenge-portfolio/rows/${row.id}/position/swap/${rowToSwapWith.id}`,
    );

    setChallengePortfolio(challengePortfolio => {
      challengePortfolio.rows[rowToSwapWith.id].position = row.position;
      challengePortfolio.rows[row.id].position = rowToSwapWith.position;
    });
  };

  const moveUp = (): void => {
    if (previousRow !== null) {
      swapPositionWith(previousRow);
    }
  };

  const moveDown = (): void => {
    if (nextRow !== null) {
      swapPositionWith(nextRow);
    }
  };

  return [moveUp, moveDown];
};

export default useRowMoveHandlers;
