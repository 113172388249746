import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';

const StyledRadioWrapper = styled(FormControlLabel)({
  marginBottom: 6,
  ':last-child': {
    marginBottom: 12,
  },
});

export default StyledRadioWrapper;
