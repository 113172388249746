import useDefaultChallengeNaming from './useDefaultChallengeNaming';
import App from '@/lib/App';
import capitalize from 'capitalize';

export type ChallengeNaming = {
  readonly challengeNameSingular: string;
  readonly challengeNameSingularCapitalized: string;
  readonly challengeNamePlural: string;
};

const useChallengeNaming = (): ChallengeNaming => {
  const { defaultChallengeNameSingular, defaultChallengeNamePlural } =
    useDefaultChallengeNaming();

  const customChallengeNaming = App.instanceConfig.useSetting(
    'customChallengeNaming',
  );

  if (!customChallengeNaming.enabled) {
    return {
      challengeNameSingular: defaultChallengeNameSingular,
      challengeNameSingularCapitalized: capitalize(
        defaultChallengeNameSingular,
      ),
      challengeNamePlural: defaultChallengeNamePlural,
    } as const;
  }

  const { customChallengeNameSingular, customChallengeNamePlural } =
    customChallengeNaming;

  const challengeNameSingular =
    customChallengeNameSingular || defaultChallengeNameSingular;

  return {
    challengeNameSingular,
    challengeNameSingularCapitalized: capitalize(challengeNameSingular),
    challengeNamePlural:
      customChallengeNamePlural || defaultChallengeNamePlural,
  } as const;
};

export default useChallengeNaming;
