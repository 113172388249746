import { useTheme } from '@emotion/react';
import SliderRail from './SliderRail';
import ProgressBar from './ProgressBar';
import App from '@/lib/App';
import { RubricProgressObject, HTMLDivProps } from '@/lib/types';

type PartSliderProps = HTMLDivProps & {
  progress: RubricProgressObject;
};

const PartSlider = ({ progress, ...props }: PartSliderProps) => {
  const studentProgress = progress.student ?? 0;
  const coachProgress = progress.coach ?? 0;

  const progressEqual = studentProgress === coachProgress;

  const useReversedSliders =
    App.instanceConfig.useSetting('useReversedSliders');

  const theme = useTheme();

  return (
    <SliderRail {...props}>
      <ProgressBar
        progress={studentProgress}
        color={theme.palette.sliders.student}
        progressEqual={progressEqual}
        onTop={studentProgress <= coachProgress}
        reversed={useReversedSliders}
      />
      <ProgressBar
        progress={coachProgress}
        color={theme.palette.sliders.coach}
        progressEqual={progressEqual}
        onTop={coachProgress <= studentProgress}
        reversed={useReversedSliders}
      />
    </SliderRail>
  );
};

export default PartSlider;
