import PillButtons from '../PillButtons';
import useProof from '../useProof';
import AddProof from './AddProof';
import DeleteProof from './DeleteProof';
import { Link as LinkIcon } from '@mui/icons-material';

const PillButtonAsLink = PillButtons.Button.withComponent('a');

type LevelProofProps = {
  levelId: number;
};

// TODO fix for challenge/task rubrics (simply see if task or challenge context
// present --> if yes, save under challenge/task)
const LevelProof = ({ levelId }: LevelProofProps) => {
  const [proof, setProof] = useProof(levelId);

  // TODO PillButtonLink disabled state doesn't work properly (link becomes
  // unclickable but has no visual disabled state seemingly)
  return (
    <div>
      {Object.entries(proof).map(([proofLinkId, proofLinkUrl]) => (
        <PillButtons.Wrapper key={proofLinkId}>
          <PillButtonAsLink leftHalf href={proofLinkUrl} target="_blank">
            <LinkIcon />
          </PillButtonAsLink>
          <DeleteProof proofLinkId={Number(proofLinkId)} setProof={setProof} />
        </PillButtons.Wrapper>
      ))}
      <AddProof levelId={levelId} setProof={setProof} />
    </div>
  );
};

export default LevelProof;
