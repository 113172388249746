import { TextFieldProps } from '@mui/material';
import React, { Children, cloneElement } from 'react';

type ChildTextFieldProps = Omit<TextFieldProps, 'error' | 'helperText'> & {
  error: React.ReactNode;
};

const setupChildTextField = (
  children: React.ReactNode,
  { value, onChange, error, inputRef, multiline }: ChildTextFieldProps,
) =>
  Children.map(children, child => {
    if (
      !child ||
      typeof child !== 'object' ||
      !('props' in child) ||
      child.props.__TYPE !== 'PromptTextField'
    ) {
      return child;
    }

    return cloneElement(child, {
      value,
      onChange,
      helperText: error,
      error: !!error,
      inputRef,
      multiline,
    });
  });

export default setupChildTextField;
