import { useContext } from 'react';
import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import getHighestPositionFromSortedItems from '@/pages/EditorPage/getHighestPositionFromSortedItems';
import ModelEditorCard from './ModelEditorCard';
import ModelTypeContext from './ModelTypeContext';
import ModelPhaseEditor from './ModelPhaseEditor';
import AddModelPhase from './AddModelPhase';
import ModelType from '@/lib/constants/challengeModel/ModelType.enum';
import ModelsContext from '../ModelsContext';

type ModelEditorForModelTypeProps = {
  selectedModelType: ModelType;
};

// TODO phase stuff should be renamed to sections...
const ModelEditorForModelType = ({
  selectedModelType,
}: ModelEditorForModelTypeProps) => {
  const [models] = useContext(ModelsContext);
  const model = models[selectedModelType];

  const phases = Object.values(model.sections);
  const sortedPhases = phases.sort(sortByPosition);
  const highestPhasePosition = getHighestPositionFromSortedItems(sortedPhases);

  return (
    <ModelEditorCard variant="outlined">
      <ModelTypeContext.Provider value={selectedModelType}>
        {sortedPhases.map((phase, index) => (
          <ModelPhaseEditor
            phase={phase}
            previousPhase={sortedPhases[index - 1] ?? null}
            nextPhase={sortedPhases[index + 1] ?? null}
            key={phase.id}
          />
        ))}
        <AddModelPhase highestPhasePosition={highestPhasePosition} />
      </ModelTypeContext.Provider>
    </ModelEditorCard>
  );
};

export default ModelEditorForModelType;
