import StyledText from './StyledText';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import Space from '@/components/Space';
import { FormattedMessage } from 'react-intl';

const NotSharedText = () => (
  <StyledText>
    <FormattedMessage id="portfolio.challenge.sharing.not_shared" />
    <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
      <Space height={4} />
    </RestrictedToPortfolioMode>
  </StyledText>
);

export default NotSharedText;
