import { useState } from 'react';
import LoadingSpinnerWithText from '@/components/LoadingSpinnerWithText';
import PortfolioTemplatesEditor from './PortfolioTemplatesEditor';
import VisiblePortfolioTemplatesGroupContext from './VisiblePortfolioTemplatesGroupContext';
import VisibleGroupSwitcher from './VisibleGroupSwitcher';
import Space from '@/components/Space';
import Group from '@/lib/constants/Group.enum';
import GroupIndicatingSnackbar from './GroupIndicatingSnackbar';
import { FormattedMessage } from 'react-intl';
import { EditorPortfolioTemplates } from '@/lib/types';
import EditorPortfolioTemplatesContext from './EditorPortfolioTemplatesContext';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

const DEFAULT_VISIBLE_GROUP = Group.ALL;

const RubricsPortfolioTemplatesEditor = () => {
  const [visibleGroup, setVisibleGroup] = useState<Group>(
    DEFAULT_VISIBLE_GROUP,
  );
  const [loading, portfolioTemplates, setPortfolioTemplates] =
    usePolledSwrAsComplexState<EditorPortfolioTemplates>(
      'portfolio-templates/editor',
    );

  const [groupIndicatingSnackbarOpen, setGroupIndicatingSnackbarOpen] =
    useState(false);

  if (loading) {
    return (
      <LoadingSpinnerWithText>
        <FormattedMessage id="editor.portfolio_template_editor_with_group_switcher.loading_message" />
      </LoadingSpinnerWithText>
    );
  }

  const handleVisibleGroupChange = (newVisibleGroup: Group): void => {
    setVisibleGroup(newVisibleGroup);
    setGroupIndicatingSnackbarOpen(true);
  };

  return (
    <>
      <VisibleGroupSwitcher
        visibleGroup={visibleGroup}
        onChangeVisibleGroup={handleVisibleGroupChange}
      />
      <EditorPortfolioTemplatesContext.Provider
        value={[portfolioTemplates, setPortfolioTemplates]}
      >
        <VisiblePortfolioTemplatesGroupContext.Provider value={visibleGroup}>
          <PortfolioTemplatesEditor />
        </VisiblePortfolioTemplatesGroupContext.Provider>
      </EditorPortfolioTemplatesContext.Provider>
      <Space height={15} />
      <GroupIndicatingSnackbar
        group={visibleGroup}
        open={groupIndicatingSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setGroupIndicatingSnackbarOpen(false)}
      />
    </>
  );
};

export default RubricsPortfolioTemplatesEditor;
