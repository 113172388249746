import { IconButton, IconButtonProps } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

type EditButtonProps = Omit<IconButtonProps, 'size' | 'children'>;

const EditButton = (props: EditButtonProps) => (
  <IconButton size="large" {...props}>
    <EditIcon />
  </IconButton>
);

export default EditButton;
