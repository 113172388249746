import { Button, ButtonProps } from '@mui/material';
import { KeyboardArrowLeft as BackIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';

type BackButtonProps = Omit<
  ButtonProps,
  'variant' | 'color' | 'startIcon' | 'style' | 'children'
>;

const StyledButton = styled(Button)({
  marginRight: 'auto',
});

const BackButton = (props: BackButtonProps) => (
  <StyledButton
    variant="outlined"
    color="secondary"
    startIcon={<BackIcon />}
    {...props}
  >
    <FormattedMessage id="support.dialog.actions.back" />
  </StyledButton>
);

export default BackButton;
