import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import React from 'react';

type CloseDialogButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const CloseDialogButton = ({ onClick }: CloseDialogButtonProps) => (
  <IconButton onClick={onClick}>
    <CloseIcon />
  </IconButton>
);

export default CloseDialogButton;
