import { useContext } from 'react';
import LinkTextField from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/ChallengeLinkSharing/LinkSharingDialog/LinkField/LinkTextField';
import TaskContext from '../../../TaskContext';
import selectLinkInputContent from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/ChallengeLinkSharing/selectLinkInputContent';

const LinkField = () => {
  const [task] = useContext(TaskContext);

  const baseUrl = window.location.origin;
  const link = `${baseUrl}/task/${task.uuid}`;

  return <LinkTextField link={link} onClick={selectLinkInputContent} />;
};

export default LinkField;
