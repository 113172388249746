import moment from 'moment';
import { blue, orange, red } from '@mui/material/colors';

export type PlannedEndDateColor =
  | typeof orange
  | typeof red
  | typeof blue
  | null;

const getPlannedEndDateColor = (timestamp: number): PlannedEndDateColor => {
  const today = moment(new Date());
  const plannedEndDate = moment(timestamp);
  const difference = today.diff(plannedEndDate, 'days');
  const isToday =
    difference === 0 && new Date().getDate() === new Date(timestamp).getDate();

  if (isToday) {
    return orange;
  }

  if (difference >= 0 && Date.now() > timestamp) {
    return red;
  }

  if (difference <= 0 && difference >= -7) {
    return blue;
  }

  return null;
};

export default getPlannedEndDateColor;
