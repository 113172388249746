import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

const SliderRail = styled('section')(
  ({ theme }) => ({
    position: 'relative',
    marginLeft: 'auto',
    width: '50%',
    minWidth: '50%',
    height: 12,
    backgroundColor: theme.palette.action.selected,
    borderRadius: 6,
    overflow: 'hidden',
    cursor: 'pointer',
    [mediaQuery(1024)]: {
      width: '40%',
      minWidth: '40%',
    },
    [mediaQuery(768)]: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  }),
  addHighContrastStyles(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
  })),
);

export default SliderRail;
