import { useState, useContext } from 'react';
import Space from '@/components/Space';
import TextEditor from '../../utils/TextEditor';
import useApi from '@/lib/api/useApi';
import FieldRendererContext from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/FieldRendererContext';
import { LogEntry } from '@/lib/types';

type LogEntryEditorProps = {
  logEntry: LogEntry;
  onClose: () => void;
};

// TODO maybe texteditor can handle saving state itself by awaiting onSave()?
const LogEntryEditor = ({ logEntry, onClose }: LogEntryEditorProps) => {
  const api = useApi();

  const { setPortfolioItem, portfolioItemApiEndpoint } =
    useContext(FieldRendererContext);

  const [saving, setSaving] = useState(false);

  const handleSave = async (value: string): Promise<void> => {
    setSaving(true);

    const updatedLogEntry = await api.put<LogEntry>(
      `${portfolioItemApiEndpoint}/log-entries/${logEntry.id}`,
      {
        content: value,
      },
    );

    setPortfolioItem(portfolioItem => {
      portfolioItem.logEntries[logEntry.id] = {
        ...portfolioItem.logEntries[logEntry.id],
        ...updatedLogEntry,
      };
    });

    setSaving(false);
    onClose();
  };

  return (
    <>
      <Space height={18} />
      <TextEditor
        initialValue={logEntry.content}
        onCancel={onClose}
        onSave={handleSave}
        disabled={saving}
        disableSaveWhenEmpty
      />
    </>
  );
};

export default LogEntryEditor;
