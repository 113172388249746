import { useContext, useState } from 'react';
import { Save as SaveIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import FieldContext from '../FieldContext';
import ModelTypeContext from '../../../ModelTypeContext';
import ModelsContext from '../../../../ModelsContext';
import AdditionalConfigStateContext from '../AdditionalConfigStateContext';
import useApi from '@/lib/api/useApi';
import { EditorFieldWithoutRelations } from '@/lib/types';
import { LoadingButton } from '@mui/lab';

const SaveAdditionalConfigChangesButton = () => {
  const api = useApi();

  const modelType = useContext(ModelTypeContext);
  const [, setModels] = useContext(ModelsContext);

  const { field } = useContext(FieldContext);
  const [additionalConfig, , , reenableAdditionalConfigSync] = useContext(
    AdditionalConfigStateContext,
  );

  const [saving, setSaving] = useState(false);

  const saveAdditionalConfigChanges = async (): Promise<void> => {
    setSaving(true);

    const updatedField = await api.put<EditorFieldWithoutRelations>(
      `editor/challenge-model/fields/${field!.id}/additional-config`,
      {
        additionalConfig: JSON.stringify(additionalConfig),
      },
    );

    setSaving(false);

    setModels(models => {
      models[modelType].fields[field!.id] = {
        ...models[modelType].fields[field!.id],
        ...updatedField,
      };
    });

    reenableAdditionalConfigSync();
  };

  return (
    <LoadingButton
      variant="contained"
      size="small"
      onClick={saveAdditionalConfigChanges}
      startIcon={<SaveIcon />}
      loadingPosition="start"
      loading={saving}
      disableElevation
    >
      <FormattedMessage id="editor.challenge_model_editor.field_editor.save_button" />
    </LoadingButton>
  );
};

export default SaveAdditionalConfigChangesButton;
