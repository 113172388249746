import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import EgodactLogo from '@/components/EgodactLogo';

const LogoLink = styled('a')(({ theme }) => ({
  marginRight: 12,
  width: 100,
  height: 39.98,
  display: 'inline-block',
  transition: theme.transitions.create('all'),
  ':hover': {
    opacity: 0.7,
  },
  [mediaQuery(650)]: {
    marginRight: 0,
    marginBottom: 16,
    display: 'block',
  },
}));

const FooterLogo = () => (
  <LogoLink href="https://egodact.com" target="_blank">
    <EgodactLogo />
  </LogoLink>
);

export default FooterLogo;
