import styled from '@emotion/styled';

const TreeColumn = styled('ul')({
  marginTop: 0,
  marginLeft: -8,
  marginRight: -8,
  marginBottom: -16,
  padding: 0,
  minWidth: 250,
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export default TreeColumn;
