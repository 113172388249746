import { Fab, FabProps } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import Space from '@/components/Space';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

type CreateChallengeButtonProps = Omit<
  FabProps,
  'variant' | 'color' | 'children'
>;

const CreateChallengeButton = (props: CreateChallengeButtonProps) => (
  <Fab variant="extended" color="primary" {...props}>
    <AddIcon />
    <Space width={8} />
    <FormattedMessageWithChallengeNaming id="portfolio.challenge_creation_flow.create_button" />
  </Fab>
);

export default CreateChallengeButton;
