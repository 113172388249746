import ActionButton, { ActionButtonProps } from './ActionButton';
import { Edit as EditIcon } from '@mui/icons-material';

type EditButtonProps = Omit<ActionButtonProps, 'icon' | 'labelId'>;

const EditButton = (props: EditButtonProps) => (
  <ActionButton
    icon={<EditIcon />}
    labelId="portfolio.challenge.drawer.edit.button.as_task"
    {...props}
  />
);

export default EditButton;
