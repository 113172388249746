const stripTrailingSlash = (url: string): string => {
  if (!url.endsWith('/')) {
    return url;
  }

  return url.slice(0, url.length - 1);
};

const apiUrl = stripTrailingSlash(import.meta.env.VITE_API_URL);

const buildApiUrl = (path: string): string => {
  if (path.startsWith('/')) {
    return `${apiUrl}${path}`;
  }

  return `${apiUrl}/${path}`;
};

export default buildApiUrl;
