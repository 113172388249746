import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const BasicUserControlsWrapper = styled('section')({
  display: 'flex',
  [mediaQuery(1200)]: {
    marginLeft: 0,
  },
  [mediaQuery(700)]: {
    marginTop: 6,
    width: '100%',
    justifyContent: 'center',
  },
});

export default BasicUserControlsWrapper;
