import styled from '@emotion/styled';
import { Button } from '@mui/material';

const StyledHomeButton = styled(Button)({
  marginTop: -12,
  marginBottom: 24,
  width: 'fit-content',
}) as typeof Button;

export default StyledHomeButton;
