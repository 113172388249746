import StyledHeader from './StyledHeader';
import { Button } from '@mui/material';
import SelectedSchoolLabel from './SelectedSchoolLabel';
import { FormattedMessage } from 'react-intl';
import { SchoolSummary } from '@/lib/types';
import React from 'react';

type SelectedSchoolHeaderProps = {
  selectedSchool: SchoolSummary;
  onGoBackToSchoolSelection: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
};

const SelectedSchoolHeader = ({
  selectedSchool,
  onGoBackToSchoolSelection,
}: SelectedSchoolHeaderProps) => (
  <StyledHeader>
    <span>
      <FormattedMessage id="login.selected_school_header.label" />
      <SelectedSchoolLabel>{selectedSchool.displayName}</SelectedSchoolLabel>
    </span>
    <Button size="small" onClick={onGoBackToSchoolSelection}>
      <FormattedMessage id="login.selected_school_header.change_button" />
    </Button>
  </StyledHeader>
);

export default SelectedSchoolHeader;
