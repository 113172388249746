import { useContext } from 'react';
import FieldContext from '../../../FieldContext';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import FieldNameWrapper from './FieldNameWrapper';
import StyledFieldName from './StyledFieldName';
import FieldInfo from './FieldInfo';
import PhaseContentGroupContext from '../../../../Phase/PhaseContentGroupContext';

const FieldName = () => {
  const phaseContentGroup = useContext(PhaseContentGroupContext);
  const field = useContext(FieldContext);

  if (!field.name) {
    return null;
  }

  const isInRightPhaseContentGroup =
    phaseContentGroup === ContentGroupType.RIGHT;

  return (
    <FieldNameWrapper secondary={isInRightPhaseContentGroup}>
      <StyledFieldName bold={!isInRightPhaseContentGroup}>
        {field.name}
      </StyledFieldName>
      {field.info && <FieldInfo />}
    </FieldNameWrapper>
  );
};

export default FieldName;
