import React, { useContext } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import AdditionalConfigStateContext from '../../AdditionalConfigStateContext';

const MultipleOptionsSelectableCheckbox = () => {
  const [additionalConfig, setAdditionalConfig] = useContext(
    AdditionalConfigStateContext,
  );

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const multipleOptionsSelectable = checked;

    setAdditionalConfig(additionalConfig => {
      additionalConfig.multipleOptionsSelectable = multipleOptionsSelectable;
    });
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={additionalConfig.multipleOptionsSelectable ?? false}
          onChange={handleChange}
        />
      }
      label={
        <FormattedMessage id="editor.model_editor.field_editor.select_editor.multiple_options_selectable_checkbox.label" />
      }
    />
  );
};

export default MultipleOptionsSelectableCheckbox;
