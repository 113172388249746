import styled from '@emotion/styled';

type StyledGroupSelectorGroupListProps = {
  open: boolean;
};

const StyledGroupSelectorGroupList = styled('ul')<
  StyledGroupSelectorGroupListProps
>(({ theme, open }) => ({
  margin: '0 auto',
  padding: 0,
  maxHeight: 420,
  listStyle: 'none',
  borderTop: `2px solid ${theme.palette.divider}`,
  display: open ? 'block' : 'none',
  overflowY: 'auto',
}));

export default StyledGroupSelectorGroupList;
