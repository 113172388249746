import useComplexState from '@/lib/utils/useComplexState';

type SelectedUsersState = {
  selectedUsers: {
    [userId: number]: boolean;
  };
  setUserSelected: (id: number, selected: boolean) => void;
  clearSelectedUsers: () => void;
};

const useSelectedUsers = (): SelectedUsersState => {
  const [selectedUsers, setSelectedUsers] = useComplexState<
    Record<number, boolean>
  >({});

  const addUserToSelection = (id: number): void => {
    setSelectedUsers(selectedUsers => {
      selectedUsers[id] = true;
    });
  };

  const removeUserFromSelection = (id: number): void => {
    setSelectedUsers(selectedUsers => {
      delete selectedUsers[id];
    });
  };

  const setUserSelected = (id: number, selected: boolean): void => {
    if (selected) {
      addUserToSelection(id);
      return;
    }

    removeUserFromSelection(id);
  };

  const clearSelectedUsers = (): void => {
    setSelectedUsers({});
  };

  return {
    selectedUsers,
    setUserSelected,
    clearSelectedUsers,
  };
};

export default useSelectedUsers;
