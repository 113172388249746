import DialogButton, { DialogButtonProps } from './DialogButton';
import { FormattedMessage } from 'react-intl';

const DialogOkButton = ({ children, ...props }: DialogButtonProps) => (
  <DialogButton color="primary" {...props}>
    {children ?? <FormattedMessage id="dialogs.actions.ok" />}
  </DialogButton>
);

export default DialogOkButton;
