import perspectivesIconLightMode from './icons/perspectives-light-mode.svg';
import perspectivesIconDarkMode from './icons/perspectives-dark-mode.svg';
import { useTheme } from '@emotion/react';
import { HTMLImgProps } from '@/lib/types';
import styled from '@emotion/styled';

type PerspectivesIconProps = Omit<HTMLImgProps, 'src'> & {
  size?: number | string;
};

type StyledIconProps = HTMLImgProps & {
  size: number | string;
};

const StyledIcon = styled('img')<StyledIconProps>(({ size }) => ({
  width: size,
  height: size,
}));

const PerspectivesIcon = ({ size, ...props }: PerspectivesIconProps) => {
  const theme = useTheme();
  const icon = theme.isDark
    ? perspectivesIconDarkMode
    : perspectivesIconLightMode;

  return <StyledIcon src={icon} alt="" size={size ?? 24} {...props} />;
};

export default PerspectivesIcon;
