import styled from '@emotion/styled';
import { Paper, PaperProps } from '@mui/material';
import { CARD_HEIGHT, CARD_MARGIN_BOTTOM } from './styleConstants';
import { ForwardedRef, forwardRef } from 'react';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import stripProps from '@/lib/utils/stripProps';

export type StyledPerspectiveCardProps = PaperProps<'li'> & {
  draggable?: boolean;
  showAttachmentOutline?: boolean;
  perspectiveColor?: string;
};

const PaperAsLi = forwardRef(
  (props: PaperProps<'li'>, ref: ForwardedRef<HTMLLIElement>) => (
    <Paper component="li" {...props} ref={ref} />
  ),
);

PaperAsLi.displayName = 'PaperAsLi';

const StyledPerspectiveCard = styled(
  stripProps(PaperAsLi, 'showAttachmentOutline', 'perspectiveColor'),
)<StyledPerspectiveCardProps>(
  {
    position: 'relative',
    marginBottom: CARD_MARGIN_BOTTOM,
    marginLeft: 8,
    marginRight: 8,
    padding: '20px 12px',
    height: CARD_HEIGHT,
    minHeight: CARD_HEIGHT,
    maxHeight: CARD_HEIGHT,
    boxSizing: 'border-box',
    overflow: 'hidden',
    flex: 1,
    ':has(.perspective-card__open-button)': {
      paddingRight: 48,
      '.perspective-card__icon': {
        marginRight: -36,
      },
    },
    ':has(.perspective-card__open-button) .perspective-card__content-wrapper': {
      paddingRight: 48,
    },
  },
  ({ theme }) => {
    if (theme.isMobile) {
      return;
    }

    return {
      ':has(.perspective-card__open-button)': {
        paddingRight: 12,
        transition: theme.transitions.create('all'),
        '.perspective-card__icon': {
          marginRight: 0,
          transition: theme.transitions.create(['margin-right']),
        },
      },
      ':has(.perspective-card__open-button):hover': {
        paddingRight: 48,
        '.perspective-card__icon': {
          marginRight: -36,
        },
      },
      ':has(.perspective-card__open-button) .perspective-card__content-wrapper':
        {
          paddingRight: 12,
        },
      ':has(.perspective-card__open-button):hover .perspective-card__content-wrapper':
        {
          paddingRight: 48,
        },
      '.perspective-card__open-button:not(:focus)': {
        width: 0,
        opacity: 0,
      },
      ':hover .perspective-card__open-button': {
        width: 36,
        opacity: 1,
      },
      ':hover .perspective-card__color-fill': {
        opacity: 1,
      },
      '.perspective-card__attach-button:not(:focus)': {
        opacity: 0,
      },
      ':hover .perspective-card__attach-button': {
        opacity: 1,
      },
      '.perspective-card__action-button': {
        transition: theme.transitions.create('all'),
      },
      '.perspective-card__action-button:not(:focus)': {
        marginLeft: -10,
        width: 0,
        opacity: 0,
      },
      ':hover .perspective-card__action-button': {
        marginLeft: 0,
        width: 34,
        opacity: 1,
      },
      [`.perspective-card__action-button:focus,
       .perspective-card__action-button`]: {
        marginLeft: 0,
      },
    };
  },
  ({ theme, draggable }) => {
    if (!draggable) {
      return null;
    }

    return {
      transition: theme.transitions.create('all'),
      cursor: 'move',
      ':not(:active):hover': {
        transform: 'scale(1.025)',
      },
      ':active': {
        transform: 'scale(0.975)',
      },
    };
  },
  ({ theme, showAttachmentOutline, perspectiveColor }) => {
    if (typeof showAttachmentOutline === 'undefined') {
      return null;
    }

    const outlineColor = theme.palette.isHighContrast
      ? theme.palette.divider
      : perspectiveColor ?? theme.palette.divider;

    const outlineWidth = theme.palette.isHighContrast ? 4 : 2;

    return {
      transition: `${theme.transitions.create(
        'all',
      )}, ${theme.transitions.create('border', {
        duration: 0,
      })} !important`,
      border: showAttachmentOutline
        ? `${outlineWidth}px solid ${outlineColor}`
        : 'none',
      '.perspective-card__attach-button, .perspective-card__detach-button': {
        top: showAttachmentOutline ? 54 - outlineWidth : undefined,
      },
    };
  },
  addHighContrastStyles(({ theme }) => ({
    border: `2px solid ${theme.palette.divider}`,
  })),
);

export default StyledPerspectiveCard;
