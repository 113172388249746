import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import ModelFieldEditor from './ModelFieldEditor';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import { EditorField } from '@/lib/types';

type ModelFieldEditorListProps = {
  fields: EditorField[];
  highestFieldPositions: Record<ContentGroupType, number>;
  lowestFieldPositionInPhaseBottomContent: number;
};

const ModelFieldEditorList = ({
  fields,
  highestFieldPositions,
  lowestFieldPositionInPhaseBottomContent,
}: ModelFieldEditorListProps) => {
  const sortedFields = fields.sort(sortByPosition);

  return sortedFields.map((field, index) => (
    <ModelFieldEditor
      field={field}
      previousField={sortedFields[index - 1]}
      nextField={sortedFields[index + 1]}
      allHighestPositions={highestFieldPositions}
      lowestFieldPositionInPhaseBottomContent={
        lowestFieldPositionInPhaseBottomContent
      }
      key={field.id}
    />
  ));
};

export default ModelFieldEditorList;
