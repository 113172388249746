import { KeyboardArrowDown as ArrowDownIcon } from '@mui/icons-material';
import styled from '@emotion/styled';
import ActionButton, {
  ActionButtonProps,
} from '@/pages/EditorPage/RubricsPortfolioTemplatesEditor/PortfolioTemplatesEditor/PortfolioTemplatesEditorActionButtons/ActionButton';
import isPropValid from '@emotion/is-prop-valid';

type CollapseButtonProps = Omit<ActionButtonProps, 'children'> & {
  collapsed: boolean;
};

const StyledCollapseButton = styled(ActionButton, {
  shouldForwardProp: isPropValid,
})<CollapseButtonProps>(({ collapsed }) => ({
  transition: 'transform 450ms cubic-bezier(.23, 1, .32, 1) 0ms',
  transform: collapsed ? 'rotate(-90deg)' : 'none',
}));

const CollapseButton = (props: CollapseButtonProps) => (
  <StyledCollapseButton {...props}>
    <ArrowDownIcon />
  </StyledCollapseButton>
);

export default CollapseButton;
