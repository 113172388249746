import { useState } from 'react';
import ensureLinkIsFullUrl from '@/lib/utils/ensureLinkIsFullUrl';
import { EditorState, RichUtils } from 'draft-js';
import { getSelectionText } from 'draftjs-utils';
import ToolbarButton from '../../ToolbarButton';
import { InsertLink as AddLinkIcon } from '@mui/icons-material';
import LinkPrompt from './LinkPrompt';

type AddLinkProps = {
  editorState: EditorState;
  setEditorState: (updatedEditorState: EditorState) => void;
  focusOnEditor: () => void;
};

const AddLink = ({
  editorState,
  setEditorState,
  focusOnEditor,
}: AddLinkProps) => {
  const [linkPromptOpen, setLinkPromptOpen] = useState(false);

  const addLink = (potentiallyIncompleteLink: string): void => {
    const link = ensureLinkIsFullUrl(potentiallyIncompleteLink);

    const editorContent = editorState.getCurrentContent();
    const editorContentWithEntity = editorContent.createEntity(
      'LINK',
      'MUTABLE',
      { url: link },
    );
    const entityKey = editorContentWithEntity.getLastCreatedEntityKey();

    const newEditorState = EditorState.set(editorState, {
      currentContent: editorContentWithEntity,
    });

    setEditorState(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey,
      ),
    );

    setLinkPromptOpen(false);

    focusOnEditor();
  };

  const selectionText = getSelectionText(editorState);
  const hasSelection = selectionText.length > 0;

  return (
    <>
      <ToolbarButton
        label="add_link"
        icon={AddLinkIcon}
        onClick={() => setLinkPromptOpen(true)}
        disabled={!hasSelection}
      />
      <LinkPrompt
        open={linkPromptOpen}
        onAdd={addLink}
        onCancel={() => setLinkPromptOpen(false)}
      />
    </>
  );
};

export default AddLink;
