import {
  ThemeProvider as MuiThemeProvider,
  ThemeOptions,
  createTheme,
} from '@mui/material/styles';
import fontConfig from './fontConfig';
import getHighContrastModeThemeAdjustments from './getHighContrastModeThemeAdjustments';
import useActivePalette from './useActivePalette';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import device from 'current-device';
import { Palette, PaletteOptions } from '@mui/material';
import { Theme } from '@emotion/react';
import React from 'react';

type ThemeProviderProps = {
  children: React.ReactNode;
};

const buildTheme = (activePalette: PaletteOptions): Theme => {
  // TODO since this also applies to tablets, should be named something like
  // isTouchDevice
  const isMobile = !device.desktop();

  const theme = createTheme(
    {
      palette: activePalette,
      isDark: activePalette.mode === 'dark',
      // TODO use this everywhere
      isMobile,
      transitions: {
        simple: 'all 300ms ease-in-out',
      },
      ...fontConfig,
    },
    getHighContrastModeThemeAdjustments((activePalette as unknown) as Palette),
  );

  theme.extend = (themeExtensionObject: ThemeOptions) =>
    createTheme((theme as unknown) as ThemeOptions, themeExtensionObject);

  return theme;
};

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const activePalette = useActivePalette();
  const theme = buildTheme(activePalette);

  return (
    <MuiThemeProvider theme={theme}>
      <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
