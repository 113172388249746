import sanitizeHtml, { Attributes, Tag } from 'sanitize-html';

const escapeHtml = (dirtyHtml: string): string => {
  return sanitizeHtml(dirtyHtml, {
    allowedTags: ['br', 'p', 'ul', 'ol', 'li', 'strong', 'em', 'del', 'a'],
    allowedAttributes: {
      a: ['href', 'target'],
    },
    transformTags: {
      a: (tagName: string, attributes: Attributes): Tag => ({
        tagName,
        attribs: {
          ...attributes,
          target: '_blank',
        },
      }),
    },
  });
};

export default escapeHtml;
