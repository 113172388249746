import PortfolioItemWithGroupsPrompt, {
  PortfolioItemWithGroupsPromptProps,
} from '../../PortfolioItemWithGroupsPrompt';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import PortfolioItemWithGroupsPromptVariant from '../../PortfolioItemWithGroupsPrompt/PortfolioItemWithGroupsPromptVariant.enum';

type PartPromptWithGroupsProps = Omit<
  PortfolioItemWithGroupsPromptProps,
  'variant' | 'label' | 'children'
>;

const PartPromptWithGroups = (props: PartPromptWithGroupsProps) => (
  <PortfolioItemWithGroupsPrompt
    variant={PortfolioItemWithGroupsPromptVariant.PART}
    label={
      <FormattedMessage id="portfolio_template_editor.part.edit.input_label" />
    }
    {...props}
  >
    <Dialog.Title>
      <FormattedMessage id="portfolio_template_editor.part.edit.title" />
    </Dialog.Title>
    <Dialog.Content>
      <Dialog.ContentText>
        <FormattedMessage id="portfolio_template_editor.part.edit.text" />
      </Dialog.ContentText>
    </Dialog.Content>
  </PortfolioItemWithGroupsPrompt>
);

export default PartPromptWithGroups;
