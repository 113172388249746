import { ForwardedRef, forwardRef } from 'react';
import StyledListItemButton from './StyledListItemButton';
import UserAvatar from '@/components/UserAvatar';
import { ListItemButtonProps, ListItemText } from '@mui/material';
import { UserSummary } from '@/lib/types';

type UserListItemProps = Omit<ListItemButtonProps, 'children'> & {
  user: UserSummary;
};

const UserListItem = forwardRef(
  (
    { user, ...props }: UserListItemProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <StyledListItemButton {...props} ref={ref}>
      <UserAvatar user={user} marginRight={12} />
      <ListItemText primary={user.name} />
    </StyledListItemButton>
  ),
);

UserListItem.displayName = 'UserListItem';

export default UserListItem;
