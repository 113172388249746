import SelectLoadingPlaceholder from './SelectLoadingPlaceholder';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormLabel,
  SelectChangeEvent,
} from '@mui/material';
import Space from '@/components/Space';
import { FormattedMessage } from 'react-intl';
import useGlobalStaticApiResponse from '@/lib/api/useGlobalStaticApiResponse';
import PortfolioItemWithGroupsPromptVariant from '../PortfolioItemWithGroupsPromptVariant.enum';
import { Group } from '@/lib/types';

export type PromptGroupSelectProps = {
  variant: PortfolioItemWithGroupsPromptVariant;
  value: number[];
  onChange: (value: number[]) => void;
};

const PromptGroupSelect = ({
  variant,
  value,
  onChange,
}: PromptGroupSelectProps) => {
  // TODO add polling?
  const [loading, groups] = useGlobalStaticApiResponse<Group[]>('groups');

  if (loading) {
    return <SelectLoadingPlaceholder />;
  }

  // TODO reorganise translations, checklist prompt makes no sense (anymore)
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <FormattedMessage
          id={`dialogs.checklist_prompt_with_group_select.group_select.label.${variant}`}
        />
      </FormLabel>
      <Space height={12} />
      <FormControl variant="outlined" size="small" style={{ maxWidth: '100%' }}>
        <InputLabel id="prompt-group-select-label">
          <FormattedMessage id="dialogs.checklist_prompt_with_group_select.group_select.inner_label" />
        </InputLabel>
        <Select
          value={value}
          onChange={(e: SelectChangeEvent<number[]>) =>
            onChange(e.target.value as number[])
          }
          id="prompt-group-select"
          labelId="prompt-group-select-label"
          label={
            <FormattedMessage id="dialogs.checklist_prompt_with_group_select.group_select.inner_label" />
          }
          style={{ minWidth: 206 }}
          disabled={groups.length === 0}
          multiple
        >
          {groups.map(group => (
            <MenuItem value={group.id} key={group.id}>
              {group.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText>
        <FormattedMessage id="dialogs.checklist_prompt_with_group_select.group_select.text" />
      </FormHelperText>
      <Space height={16} />
    </FormControl>
  );
};

export default PromptGroupSelect;
