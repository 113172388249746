import styled from '@emotion/styled';

type MenuContentProps = {
  hasMovedToSubmenu: boolean;
};

const MenuContent = styled('section')<MenuContentProps>(
  ({ theme, hasMovedToSubmenu }) => ({
    width: '200%',
    transform: hasMovedToSubmenu ? 'translateX(-50%)' : undefined,
    transition: theme.transitions.create('all'),
    display: 'flex',
  }),
);

export default MenuContent;
