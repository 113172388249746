import styled from '@emotion/styled';

const SlidableZone = styled('div')({
  position: 'absolute',
  top: -50,
  bottom: -50,
  width: '100%',
  zIndex: 4,
});

export default SlidableZone;
