import styled from '@emotion/styled';
import WorldImage from '../../WorldImage';

const StyledWorldImage = styled(WorldImage)({
  marginRight: 12,
  width: 24,
  height: 24,
});

export default StyledWorldImage;
