import styled from '@emotion/styled';
import { RemoveRedEye as FocusIcon } from '@mui/icons-material';
import isPropValid from '@emotion/is-prop-valid';

type StyledFocusIconProps = {
  hasFocus: boolean;
};

const StyledFocusIcon = styled(FocusIcon, {
  shouldForwardProp: isPropValid,
})<StyledFocusIconProps>(({ theme, hasFocus }) => ({
  width: 20,
  height: 20,
  color: hasFocus
    ? theme.palette.partFocusTextColor
    : theme.palette.text.disabled,
}));

export default StyledFocusIcon;
