import SelectFieldPlaceholder from '@/components/SelectFieldPlaceholder';
import { FormattedMessage } from 'react-intl';

type SelectPlaceholderProps = {
  asManagedGroups: boolean;
};

const SelectPlaceholder = ({ asManagedGroups }: SelectPlaceholderProps) => (
  <SelectFieldPlaceholder>
    {asManagedGroups ? (
      <FormattedMessage id="user_manager.user_list.user.group_select.labels.managed_groups" />
    ) : (
      <FormattedMessage id="user_manager.user_list.user.group_select.labels.groups" />
    )}
  </SelectFieldPlaceholder>
);

export default SelectPlaceholder;
