import { useState } from 'react';
import GapminderAnimationWrapper from './GapminderAnimationWrapper';
import ColorLegendAnimationWrapper from './ColorLegendAnimationWrapper';
import Gapminder from './Gapminder';
import ColorLegend from './ColorLegend';
import usePortfolioSnapshots from './usePortfolioSnapshots';
import usePortfolioSnapshotsOverview from './usePortfolioSnapshotsOverview';
import { RubricsPortfolioSnapshot } from '@/lib/types';

type GapminderLegendProps = {
  selectedPortfolioSnapshot: RubricsPortfolioSnapshot | null;
  setSelectedPortfolioSnapshot: (
    portfolioSnapshot: RubricsPortfolioSnapshot | null,
  ) => void;
};

const GapminderLegend = ({
  selectedPortfolioSnapshot,
  setSelectedPortfolioSnapshot,
}: GapminderLegendProps) => {
  const [
    portfolioSnapshotsOverviewLoading,
    portfolioSnapshotsOverview,
  ] = usePortfolioSnapshotsOverview();
  const [
    portfolioSnapshots,
    loadPortfolioSnapshotsForYear,
  ] = usePortfolioSnapshots();

  const [gapminderOpen, setGapminderOpen] = useState(false);

  if (gapminderOpen && !portfolioSnapshotsOverviewLoading) {
    return (
      <GapminderAnimationWrapper key="gapminder-wrapper">
        <Gapminder
          portfolioSnapshotsOverview={portfolioSnapshotsOverview}
          portfolioSnapshots={portfolioSnapshots}
          loadPortfolioSnapshotsForYear={loadPortfolioSnapshotsForYear}
          selectedPortfolioSnapshot={selectedPortfolioSnapshot}
          setSelectedPortfolioSnapshot={setSelectedPortfolioSnapshot}
          onClose={() => setGapminderOpen(false)}
        />
      </GapminderAnimationWrapper>
    );
  }

  return (
    <ColorLegendAnimationWrapper key="color-legend-wrapper">
      <ColorLegend
        portfolioSnapshotsOverviewLoading={portfolioSnapshotsOverviewLoading}
        onOpenGapminder={() => setGapminderOpen(true)}
      />
    </ColorLegendAnimationWrapper>
  );
};

export default GapminderLegend;
