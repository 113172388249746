import ApiError from './ApiError';
import ApiAuthLocalStorage from './ApiAuthLocalStorage';
import buildApiUrl from './buildApiUrl';
import ApiAuthenticationError from './ApiAuthenticationError';
import SelectedSchoolLocalStorage from '@/components/App/AppSetup/SelectedSchool/SelectedSchoolLocalStorage';
import HttpMethod from '../constants/HttpMethod.enum';
import AccessTokenRefresher from './AccessTokenRefresher';

type AuthenticationStateListener = () => void;
type RequestBody = Record<string, unknown>;

export type TokenResponse = {
  accessToken: string;
  refreshToken: string;
};

export class Api {
  private readonly authenticationStateListeners: AuthenticationStateListener[] = [];
  private responseStore: Map<string, unknown> = new Map();

  constructor() {
    this.addAuthenticationStateListener(() => {
      this.responseStore.clear();
    });

    this.url = this.url.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.unauthenticate = this.unauthenticate.bind(this);
    this.addAuthenticationStateListener = this.addAuthenticationStateListener.bind(
      this,
    );
    this.removeAuthenticationStateListener = this.removeAuthenticationStateListener.bind(
      this,
    );
    this.get = this.get.bind(this);
    this.getGlobalStatic = this.getGlobalStatic.bind(this);
    this.getStoredGlobalStaticResponse = this.getStoredGlobalStaticResponse.bind(
      this,
    );
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
    this.call = this.call.bind(this);
    this.callWithoutAuthorizationHandling = this.callWithoutAuthorizationHandling.bind(
      this,
    );
  }

  url(path: string): string {
    return buildApiUrl(path);
  }

  isAuthenticated(): boolean {
    const { accessToken, refreshToken } = ApiAuthLocalStorage.getTokens();

    return accessToken !== null && refreshToken !== null;
  }

  authenticate(accessToken: string, refreshToken: string): void {
    ApiAuthLocalStorage.saveTokens(accessToken, refreshToken);

    this.triggerAuthenticationStateListeners();
  }

  reauthenticate(refreshedAccessToken: string): void {
    if (!this.isAuthenticated()) {
      return;
    }

    ApiAuthLocalStorage.refreshAccessToken(refreshedAccessToken);
  }

  unauthenticate = () => {
    ApiAuthLocalStorage.clearTokens();

    this.triggerAuthenticationStateListeners();
  };

  addAuthenticationStateListener(listener: AuthenticationStateListener): void {
    this.authenticationStateListeners.push(listener);
  }

  removeAuthenticationStateListener(
    listener: AuthenticationStateListener,
  ): void {
    if (!this.authenticationStateListeners.includes(listener)) {
      return;
    }

    this.authenticationStateListeners.splice(
      this.authenticationStateListeners.indexOf(listener),
      1,
    );
  }

  get<T>(url: string, options: RequestInit = {}): Promise<T> {
    return this.performRequestForMethod(url, HttpMethod.GET, options);
  }

  async getGlobalStatic<T>(url: string): Promise<T> {
    if (this.responseStore.has(url)) {
      return this.responseStore.get(url) as T;
    }

    const response = await this.get<T>(url);

    this.responseStore.set(url, response);

    return response;
  }

  getStoredGlobalStaticResponse<T>(url: string): T | null {
    const storedResponse = this.responseStore.get(url);

    if (typeof storedResponse === 'undefined') {
      return null;
    }

    return storedResponse as T;
  }

  post<T>(
    url: string,
    body: RequestBody = {},
    options: RequestInit = {},
  ): Promise<T> {
    return this.performRequestWithBody<T>(url, HttpMethod.POST, body, options);
  }

  put<T>(
    url: string,
    body: RequestBody,
    options: RequestInit = {},
  ): Promise<T> {
    return this.performRequestWithBody<T>(url, HttpMethod.PUT, body, options);
  }

  delete<T>(
    url: string,
    body: RequestBody = {},
    options: RequestInit = {},
  ): Promise<T> {
    return this.performRequestWithBody<T>(
      url,
      HttpMethod.DELETE,
      body,
      options,
    );
  }

  call<T>(url: string, options: RequestInit = {}): Promise<T> {
    return this.performRequest<T>(url, options);
  }

  callWithoutAuthorizationHandling<T>(
    url: string,
    options: RequestInit,
  ): Promise<T> {
    return this.performRequestWithoutAuthorizationHandling(url, options);
  }

  private performRequestForMethod<T>(
    url: string,
    method: HttpMethod,
    options: RequestInit,
  ): Promise<T> {
    return this.performRequest<T>(url, {
      ...options,
      method,
    });
  }

  private performRequestWithBody<T>(
    url: string,
    method: HttpMethod,
    body: RequestBody,
    options: RequestInit,
  ): Promise<T> {
    return this.performRequest<T>(url, {
      ...options,
      method,
      body: JSON.stringify(body),
    });
  }

  private async performRequest<T>(
    url: string,
    options: RequestInit,
  ): Promise<T> {
    try {
      const response = await this.performRequestWithoutAuthorizationHandling<T>(
        url,
        options,
      );

      return response;
    } catch (error) {
      if (!(error instanceof ApiAuthenticationError)) {
        throw error;
      }

      const refreshedAccessToken = await AccessTokenRefresher.refreshAccessToken();

      if (refreshedAccessToken === null) {
        this.unauthenticate();

        throw error;
      }

      this.reauthenticate(refreshedAccessToken);

      return this.performRequest<T>(url, options);
    }
  }

  private async performRequestWithoutAuthorizationHandling<T>(
    url: string,
    options: RequestInit,
  ): Promise<T> {
    const additionalHeaders: HeadersInit = {};

    const selectedSchool = SelectedSchoolLocalStorage.getSelectedSchool();

    if (selectedSchool) {
      additionalHeaders['School'] = selectedSchool;
    }

    const { accessToken } = ApiAuthLocalStorage.getTokens();

    if (accessToken) {
      additionalHeaders['Authorization'] = `Bearer ${accessToken}`;
    }

    if (options.body && typeof options.body === 'string') {
      additionalHeaders['Content-Type'] = 'application/json';
    }

    const response = await fetch(this.url(url), {
      ...options,
      headers: {
        ...additionalHeaders,
        ...options.headers,
      },
    });

    if (response.status === 204) {
      return null as T;
    }

    const responseContent = await this.getResponseContent<T>(response);

    if (response.status === 401) {
      throw new ApiAuthenticationError(responseContent);
    }

    if (![200, 201, 204].includes(response.status)) {
      throw new ApiError(responseContent);
    }

    return responseContent;
  }

  private getResponseContent<T>(response: Response): Promise<T> {
    const responseContentType = response.headers.get('Content-Type');

    if (
      responseContentType === null ||
      !responseContentType.includes('application/json')
    ) {
      return response.blob() as Promise<T>;
    }

    return response.json() as Promise<T>;
  }

  private triggerAuthenticationStateListeners(): void {
    for (const listener of this.authenticationStateListeners) {
      listener();
    }
  }
}

const api = new Api();

export default api;
