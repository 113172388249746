import styled from '@emotion/styled';
import { IconButton, IconButtonProps } from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';

type InfoButtonProps = Omit<IconButtonProps, 'children'>;

const StyledIconButton = styled(IconButton)({
  marginTop: -12,
  marginBottom: -12,
});

const InfoButton = (props: InfoButtonProps) => (
  <StyledIconButton {...props}>
    <InfoIcon />
  </StyledIconButton>
);

export default InfoButton;
