import { useContext, useState } from 'react';
import UserContext from './UserContext';
import UserSelectFieldWrapper from './UserSelectFieldWrapper';
import { Select, MenuItem, IconButton } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import UsersContext from '../UsersContext';
import useApi from '@/lib/api/useApi';
import UserRole from '@/lib/constants/UserRole.enum';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

type RoleSelectProps = {
  disabled: boolean;
};

// TODO: user manager should show verified checkmark for known egodact account
const RoleSelect = ({ disabled }: RoleSelectProps) => {
  const api = useApi();

  const [, , setUsers] = useContext(UsersContext);
  const user = useContext(UserContext);

  const [
    selectedRole,
    setSelectedRole,
    selectedRoleHasChanged,
    reenableSelectedRoleSync,
  ] = useComplexSwrState(user.role);
  const [saving, setSaving] = useState(false);

  const saveRoleChange = async (): Promise<void> => {
    setSaving(true);

    await api.put(`users/${user.id}/role`, {
      role: selectedRole,
    });

    setUsers(users => {
      const userIndex = users.findIndex(element => element.id === user.id);

      users[userIndex].role = selectedRole;
    });

    setSaving(false);

    reenableSelectedRoleSync();
  };

  // TODO super admins should be allowed to set other users to super admins
  return (
    <UserSelectFieldWrapper>
      <Select
        variant="standard"
        value={selectedRole}
        onChange={e => setSelectedRole(e.target.value as UserRole)}
        disabled={disabled}
        style={{ width: 132 }}
      >
        {Object.values(UserRole)
          .filter(
            role =>
              role !== UserRole.SUPER_ADMIN ||
              user.role === UserRole.SUPER_ADMIN,
          )
          .map(role => (
            <MenuItem value={role} key={role}>
              <FormattedMessage id={`user_roles.${role.toLowerCase()}`} />
            </MenuItem>
          ))}
      </Select>
      <IconButton
        size="large"
        disabled={!selectedRoleHasChanged || disabled || saving}
        onClick={saveRoleChange}
      >
        <CheckIcon />
      </IconButton>
    </UserSelectFieldWrapper>
  );
};

export default RoleSelect;
