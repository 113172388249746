import ColorManager from './ColorManager';
import AddColorForm from './AddColorForm';
import { SetComplexStateAction } from '@/lib/types';

type ColorsManagerProps = {
  colors: string[];
  setColors: SetComplexStateAction<string[]>;
};

const ColorsManager = ({ colors, setColors }: ColorsManagerProps) => (
  <>
    {colors.map((color, index) => (
      <ColorManager
        color={color}
        onUpdate={(updatedColor: string): void => {
          setColors(colors => {
            const updatedColors = [...colors];

            updatedColors[index] = updatedColor;

            return updatedColors;
          });
        }}
        onDelete={(): void => {
          setColors(colors => {
            const updatedColors = [...colors];

            updatedColors.splice(index, 1);

            return updatedColors;
          });
        }}
        key={color}
      />
    ))}
    <AddColorForm onAdd={color => setColors(colors => [...colors, color])} />
  </>
);

export default ColorsManager;
