import { IconButtonProps } from '@mui/material';
import HeaderActionIconButton from '../HeaderActionIconButton';
import { Search as SearchIcon } from '@mui/icons-material';

const SearchButton = (props: IconButtonProps) => (
  <HeaderActionIconButton size="large" type="submit" {...props}>
    <SearchIcon />
  </HeaderActionIconButton>
);

export default SearchButton;
