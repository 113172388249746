import DialogContentWrapper from '../DialogContentWrapper';
import Dialog from '@/components/Dialog';
import SupportTilesWrapper from '../SupportTypeSelector/SupportTilesWrapper';
import SupportTile from '../SupportTypeSelector/SupportTile';
import {
  EmailOutlined as EmailIcon,
  ErrorOutline as IssueIcon,
} from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import SupportTileIcon from '../SupportTypeSelector/SupportTile/SupportTileIcon';
import SupportTileTitle from '../SupportTypeSelector/SupportTile/SupportTileTitle';

const SupportTileAsLink = SupportTile.withComponent('a');

const IssueReporting = () => (
  <DialogContentWrapper>
    <Dialog.Title>
      <FormattedMessage id="support.dialog.issue_reporting.title" />
    </Dialog.Title>
    <Dialog.Content>
      <SupportTilesWrapper>
        <SupportTileAsLink href="mailto:bugs@egodact.com">
          <SupportTileIcon icon={EmailIcon} />
          <SupportTileTitle>
            <FormattedMessage id="support.dialog.issue_reporting.tiles.email_title" />
          </SupportTileTitle>
        </SupportTileAsLink>
        <SupportTileAsLink
          href="https://github.com/egodact/egodact-issues"
          target="_blank"
        >
          <SupportTileIcon icon={IssueIcon} />
          <SupportTileTitle>
            <FormattedMessage id="support.dialog.issue_reporting.tiles.github_issue_title" />
          </SupportTileTitle>
        </SupportTileAsLink>
      </SupportTilesWrapper>
    </Dialog.Content>
  </DialogContentWrapper>
);

export default IssueReporting;
