import { useContext } from 'react';
import PageTitle from '@/components/PageTitle';
import BoxWrapper from './BoxWrapper';
import LoginSettings from './LoginSettings';
import GeneralSettings from './GeneralSettings';
import SelectedSchoolContext from '@/components/App/AppSetup/SelectedSchool/SelectedSchoolContext';
import SchoolContext from './SchoolContext';
import { School } from '@/lib/types';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

const PlatformSettingsPage = () => {
  const [selectedSchool] = useContext(SelectedSchoolContext);

  const [loading, school] = usePolledSwrAsComplexState<School>(
    `schools/${selectedSchool!.reference}`,
  );

  const schoolWithFallback = school ?? selectedSchool!;

  return (
    <SchoolContext.Provider value={[loading, schoolWithFallback]}>
      <PageTitle id="platform_settings.page_title" />
      <BoxWrapper>
        <LoginSettings />
        <GeneralSettings />
      </BoxWrapper>
    </SchoolContext.Provider>
  );
};

export default PlatformSettingsPage;
