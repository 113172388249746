import { useContext } from 'react';
import ModelType from '@/lib/constants/challengeModel/ModelType.enum';
import Phase from './Phase';
import PhaseName from './Phase/PhaseName';
import PhaseContentWrapper from './Phase/PhaseContentWrapper';
import PhaseLeftContent from './Phase/PhaseLeftContent';
import PhaseRightContent from './Phase/PhaseRightContent';
import PhaseBottomContent from './Phase/PhaseBottomContent';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import FieldRenderer from './FieldRenderer';
import ModelsContext from '@/lib/contexts/ModelsContext';
import FieldRendererContext from './FieldRendererContext';
import useItemIsVisibleToPortfolioUser from './useItemIsVisibleToPortfolioUser';
import {
  Challenge,
  ChallengeSummary,
  SetComplexImmerStateAction,
  Task,
  TaskSummary,
} from '@/lib/types';

type PortfolioItemFieldsProps = {
  modelType: ModelType;
  portfolioItem: Challenge | Task;
  setPortfolioItem:
    | SetComplexImmerStateAction<Challenge>
    | SetComplexImmerStateAction<Task>;
  setPortfolioItemSummary:
    | SetComplexImmerStateAction<ChallengeSummary>
    | SetComplexImmerStateAction<TaskSummary>;
  portfolioItemApiEndpoint: string;
};

const PortfolioItemFields = ({
  modelType,
  portfolioItem,
  setPortfolioItem,
  setPortfolioItemSummary,
  portfolioItemApiEndpoint,
}: PortfolioItemFieldsProps) => {
  const itemIsVisibleToPortfolioUser = useItemIsVisibleToPortfolioUser();

  const models = useContext(ModelsContext);
  const model = models[modelType];

  // TODO make sure item visibility filtering works correctly
  // TODO maybe challenge model can pre-sort fields in content groups
  return (
    <FieldRendererContext.Provider
      value={{
        portfolioItem,
        setPortfolioItem: setPortfolioItem as SetComplexImmerStateAction<
          Challenge | Task
        >,
        setPortfolioItemSummary:
          setPortfolioItemSummary as SetComplexImmerStateAction<
            ChallengeSummary | TaskSummary
          >,
        portfolioItemApiEndpoint,
      }}
    >
      {Object.values(model?.sections ?? [])
        .filter(itemIsVisibleToPortfolioUser)
        .sort(sortByPosition)
        .map(phase => (
          <Phase key={phase.id}>
            <PhaseName>{phase.name}</PhaseName>
            <PhaseContentWrapper>
              <PhaseLeftContent>
                <FieldRenderer
                  fields={phase.fields.filter(
                    field => field.contentGroup === ContentGroupType.LEFT,
                  )}
                />
              </PhaseLeftContent>
              <PhaseRightContent>
                <FieldRenderer
                  fields={phase.fields.filter(
                    field => field.contentGroup === ContentGroupType.RIGHT,
                  )}
                  noCoachFeedback
                />
              </PhaseRightContent>
            </PhaseContentWrapper>
            {phase.fields.filter(
              field => field.contentGroup === ContentGroupType.BOTTOM,
            ).length > 0 && (
              <PhaseBottomContent>
                <FieldRenderer
                  fields={phase.fields.filter(
                    field => field.contentGroup === ContentGroupType.BOTTOM,
                  )}
                />
              </PhaseBottomContent>
            )}
          </Phase>
        ))}
    </FieldRendererContext.Provider>
  );
};

export default PortfolioItemFields;
