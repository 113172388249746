import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import FieldType from '@/lib/constants/challengeModel/FieldType.enum';

const useSelectableFieldTypesForPhaseContentGroups = (): Record<
  ContentGroupType,
  FieldType[]
> => {
  const selectableFieldTypesForPhaseContentGroups = {
    [ContentGroupType.LEFT]: [
      FieldType.TEXT,
      FieldType.SELECT,
      FieldType.LOGS,
      FieldType.RUBRICS,
      FieldType.MANUAL_CHALLENGE_SHARING,
      FieldType.MANUAL_ASSIGNEES,
      FieldType.MANUAL_AGORA_WORLDS,
    ],
    [ContentGroupType.RIGHT]: [
      FieldType.DATE,
      FieldType.SELECT,
      FieldType.LINK_BUTTON,
      FieldType.MANUAL_PLANNED_START_DATE,
      FieldType.MANUAL_PLANNED_END_DATE,
      FieldType.MANUAL_ACTUAL_START_DATE,
      FieldType.MANUAL_ACTUAL_END_DATE,
      FieldType.MANUAL_AGORA_WORLDS,
      FieldType.MANUAL_COLOR_PICKER,
    ],
  } as Record<ContentGroupType, FieldType[]>;

  selectableFieldTypesForPhaseContentGroups[ContentGroupType.BOTTOM] =
    selectableFieldTypesForPhaseContentGroups[ContentGroupType.LEFT];

  return selectableFieldTypesForPhaseContentGroups;
};

export default useSelectableFieldTypesForPhaseContentGroups;
