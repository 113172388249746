type DataUrlListener = (dataUrl: string) => void;

class DataUrlReader {
  private readonly cache: Map<string, string> = new Map<string, string>();
  private readonly processing: Map<string, boolean> = new Map<
    string,
    boolean
  >();
  private readonly dataUrlListeners: Map<string, DataUrlListener[]> = new Map<
    string,
    DataUrlListener[]
  >();

  async read(key: string, image: Blob): Promise<string> {
    if (this.cache.has(key)) {
      return this.cache.get(key)!;
    }

    if (!this.processing.has(key)) {
      this.queueProcessRead(key, image);
    }

    return new Promise(resolve => {
      // TODO listener is re-triggered even after promise has resolved, which
      // is unnecessary
      this.registerDataUrlListener(key, (dataUrl: string): void => {
        resolve(dataUrl);
      });
    });
  }

  private async queueProcessRead(key: string, image: Blob): Promise<void> {
    if (this.processing.has(key)) {
      return;
    }

    this.processing.set(key, true);

    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>): void => {
      const dataUrl = e.target?.result as string;

      this.cache.set(key, dataUrl);

      this.emitDataUrl(key, dataUrl);
    };

    reader.readAsDataURL(image);
  }

  private emitDataUrl(key: string, dataUrl: string): void {
    if (!this.dataUrlListeners.has(key)) {
      return;
    }

    const dataUrlListeners = this.dataUrlListeners.get(key)!;

    for (const listener of dataUrlListeners) {
      listener(dataUrl);
    }
  }

  private registerDataUrlListener(
    key: string,
    listener: DataUrlListener,
  ): void {
    if (!this.dataUrlListeners.has(key)) {
      this.dataUrlListeners.set(key, [listener]);

      return;
    }

    const existingListeners = this.dataUrlListeners.get(key)!;

    existingListeners.push(listener);
  }
}

const dataUrlReader = new DataUrlReader();

const readKeyedImageAsDataUrl = async (
  key: string,
  image: Blob,
): Promise<string> => {
  return dataUrlReader.read(key, image);
};

export default readKeyedImageAsDataUrl;
