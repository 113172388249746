import { useContext } from 'react';
import PortfolioUserContext from '../contexts/PortfolioUserContext';
import UserRole from '../constants/UserRole.enum';
import { ObjectWithAccessFields } from '../types';

const roleLevels = {
  [UserRole.STUDENT]: 0,
  [UserRole.COACH]: 1,
  [UserRole.EDITOR]: 2,
  [UserRole.ADMIN]: 3,
  [UserRole.SUPER_ADMIN]: 4,
} as const;

const roleMeetsMinimumRole = (
  role: UserRole,
  minimumRole: UserRole,
): boolean => {
  return roleLevels[role] >= roleLevels[minimumRole];
};

// TODO consistent naming with challenge portfolio's itemIsVisible function
const useUserCanViewItem = () => {
  const portfolioUser = useContext(PortfolioUserContext);

  const userCanViewItem = (item: ObjectWithAccessFields): boolean => {
    if (portfolioUser === null) {
      return false;
    }

    if (roleMeetsMinimumRole(portfolioUser.role, item.minimumRole)) {
      return true;
    }

    const itemGroups = item.groups.map(group => group.id);

    for (const group of portfolioUser.groups) {
      if (itemGroups.includes(group)) {
        return true;
      }
    }

    return false;
  };

  return userCanViewItem;
};

export default useUserCanViewItem;
