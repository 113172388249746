import { useContext, useState } from 'react';
import PortfolioItemWithGroupsPrompt from '../PortfolioItemWithGroupsPrompt';
import Dialog from '@/components/Dialog';
import EditButton from '../PortfolioTemplatesEditorActionButtons/EditButton';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import {
  EditorTopic,
  EditorTopicWithoutRelations,
  MinimumRole,
} from '@/lib/types';
import PortfolioItemWithGroupsPromptVariant from '../PortfolioItemWithGroupsPrompt/PortfolioItemWithGroupsPromptVariant.enum';
import EditorPortfolioTemplatesContext from '../../EditorPortfolioTemplatesContext';

type EditTopicProps = {
  topic: EditorTopic;
};

const EditTopic = ({ topic }: EditTopicProps) => {
  const api = useApi();

  const [, setPortfolioTemplates] = useContext(EditorPortfolioTemplatesContext);

  const [editPromptOpen, setEditPromptOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveEdits = async (
    name: string,
    minimumRole: MinimumRole,
    groups: number[],
  ): Promise<void> => {
    setEditPromptOpen(false);
    setSaving(true);

    const topicData = {
      name,
      position: topic.position,
      minimumRole,
      groups,
    };

    const updatedTopic = await api.put<EditorTopicWithoutRelations>(
      `editor/portfolio-templates/topics/${topic.id}`,
      topicData,
    );

    setPortfolioTemplates(portfolioTemplates => {
      portfolioTemplates.topics[topic.id] = {
        ...portfolioTemplates.topics[topic.id],
        ...updatedTopic,
        groups: groups.map(id => ({ id })),
      };
    });

    setSaving(false);
  };

  return (
    <>
      <EditButton onClick={() => setEditPromptOpen(true)} disabled={saving} />
      <PortfolioItemWithGroupsPrompt
        variant={PortfolioItemWithGroupsPromptVariant.TOPIC}
        label={
          <FormattedMessage id="portfolio_template_editor.topic.edit.input_label" />
        }
        open={editPromptOpen}
        defaultValue={topic.name}
        selectedMinimumRole={topic.minimumRole}
        selectedGroups={topic.groups.map(({ id }) => id)}
        onOk={saveEdits}
        onCancel={() => setEditPromptOpen(false)}
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio_template_editor.topic.edit.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio_template_editor.topic.edit.text" />
          </Dialog.ContentText>
        </Dialog.Content>
      </PortfolioItemWithGroupsPrompt>
    </>
  );
};

export default EditTopic;
