enum FieldType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  SELECT = 'SELECT',
  LOGS = 'LOGS',
  LINK_BUTTON = 'LINK_BUTTON',
  RUBRICS = 'RUBRICS',
  MANUAL_PLANNED_START_DATE = 'MANUAL_PLANNED_START_DATE',
  MANUAL_PLANNED_END_DATE = 'MANUAL_PLANNED_END_DATE',
  MANUAL_ACTUAL_START_DATE = 'MANUAL_ACTUAL_START_DATE',
  MANUAL_ACTUAL_END_DATE = 'MANUAL_ACTUAL_END_DATE',
  MANUAL_CHALLENGE_SHARING = 'MANUAL_CHALLENGE_SHARING',
  MANUAL_ASSIGNEES = 'MANUAL_ASSIGNEES',
  MANUAL_AGORA_WORLDS = 'MANUAL_AGORA_WORLDS',
  MANUAL_COLOR_PICKER = 'MANUAL_COLOR_PICKER',
  MANUAL_ATTACHED_SLOS = 'MANUAL_ATTACHED_SLOS',
}

export default FieldType;
