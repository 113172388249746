import { useContext, useState } from 'react';
import UserContext from './UserContext';
import DangerousIconButton from '@/components/DangerousIconButton';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import UsersContext from '../UsersContext';
import useApi from '@/lib/api/useApi';
import FormattedHtmlMessage from '@/components/FormattedHtmlMessage';

type DeleteUserProps = {
  disabled: boolean;
};

const DeleteUser = ({ disabled }: DeleteUserProps) => {
  const api = useApi();

  const [, , setUsers] = useContext(UsersContext);
  const user = useContext(UserContext);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteUser = async (): Promise<void> => {
    setConfirmationDialogOpen(false);

    setDeleting(true);

    await api.delete(`users/${user.id}`);

    setDeleting(false);

    setUsers(users => {
      const userIndex = users.findIndex(element => element.id === user.id);

      users.splice(userIndex, 1);
    });
  };

  return (
    <>
      <DangerousIconButton
        size="large"
        onClick={() => setConfirmationDialogOpen(true)}
        disabled={disabled || deleting}
      >
        <DeleteIcon />
      </DangerousIconButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="user_manager.user_list.user.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedHtmlMessage id="user_manager.user_list.user.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteUser} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeleteUser;
