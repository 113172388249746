import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Tooltip, Button, ButtonProps } from '@mui/material';
import React, { useContext } from 'react';
import DisabledContext from '../DisabledContext';

type ToolbarButtonProps = Omit<
  ButtonProps,
  'size' | 'color' | 'disabled' | 'children'
> & {
  label: string;
  icon: React.ElementType;
  selected?: boolean;
  disabled?: boolean;
};

const StyledButton = styled(Button)({
  padding: 4,
  minWidth: 0,
});

const ToolbarButton = ({
  label,
  icon: IconComponent,
  selected,
  disabled,
  ...props
}: ToolbarButtonProps) => {
  const editorDisabled = useContext(DisabledContext);
  const intl = useIntl();

  return (
    <Tooltip
      title={intl.formatMessage({
        id: `portfolio.challenge.field.text_editor.toolbar_buttons.${label}`,
      })}
    >
      <StyledButton
        size="small"
        color={selected ? 'primary' : 'secondary'}
        disabled={disabled || editorDisabled}
        {...props}
      >
        <IconComponent />
      </StyledButton>
    </Tooltip>
  );
};

export default ToolbarButton;
