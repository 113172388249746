import App from '@/lib/App';
import AppMode from '@/lib/constants/AppMode.enum';
import WelcomeMessage from './WelcomeMessage';
import StyledPageTitle from './StyledPageTitle';
import { FormattedMessage } from 'react-intl';
import { useContext } from 'react';
import ShowPerspectiveCategorizationViewContext from '@/lib/contexts/ShowPerspectiveCategorizationViewContext';

const HeaderPageTitle = () => {
  const selectedMode = App.navigation.useSelectedMode();
  const [showPerspectiveCategorizationView] = useContext(
    ShowPerspectiveCategorizationViewContext,
  );

  if (selectedMode === null) {
    return null;
  }

  if (showPerspectiveCategorizationView) {
    return (
      <StyledPageTitle>
        <FormattedMessage id="perspectives.categorization.header_title" />
      </StyledPageTitle>
    );
  }

  if (selectedMode === AppMode.PORTFOLIO) {
    return <WelcomeMessage />;
  }

  return (
    <StyledPageTitle>
      <FormattedMessage id={`header.page_titles.${selectedMode}`} />
    </StyledPageTitle>
  );
};

export default HeaderPageTitle;
