import { useState, useEffect } from 'react';
import PrimaryIconButton from './PrimaryIconButton';
import { PlayArrow as PlayIcon, Pause as PauseIcon } from '@mui/icons-material';
import useInterval from 'use-interval';

type PlayButtonProps = {
  weeks: number[];
  selectedWeek: number;
  setSelectedWeek: (week: number) => void;
  disabled: boolean;
};

const PlayButton = ({
  weeks,
  selectedWeek,
  setSelectedWeek,
  disabled,
}: PlayButtonProps) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const firstWeek = Number(weeks[0]);
  const lastWeek = Number(weeks[weeks.length - 1]);

  const play = () => {
    if (selectedWeek === lastWeek) {
      setSelectedWeek(firstWeek);
    }

    setIsPlaying(true);
  };

  const pause = () => setIsPlaying(false);

  useEffect(() => pause, []);

  const moveToNextWeek = () => {
    const nextWeek = weeks[weeks.indexOf(selectedWeek) + 1];

    if (typeof nextWeek !== 'undefined') {
      setSelectedWeek(nextWeek);
    }

    if (typeof nextWeek === 'undefined' || nextWeek === lastWeek) {
      pause();
    }
  };

  useInterval(() => {
    if (isPlaying) {
      moveToNextWeek();
    }
  }, 1000);

  const canPlay = weeks.length > 1;

  return (
    <PrimaryIconButton
      size="large"
      edge="end"
      onClick={isPlaying ? pause : play}
      disabled={!canPlay || disabled}
    >
      {isPlaying ? <PauseIcon /> : <PlayIcon />}
    </PrimaryIconButton>
  );
};

export default PlayButton;
