import App from '@/lib/App';
import StyledOutdatedBrowserWarning from './StyledOutdatedBrowserWarning';
import { FormattedMessage } from 'react-intl';

const OutdatedBrowserWarning = () => {
  const isDisplayingChallengePortfolio = App.navigation.useIsDisplayingChallengePortfolio();

  return (
    <StyledOutdatedBrowserWarning wideContent={isDisplayingChallengePortfolio}>
      <FormattedMessage id="outdated_browser_detection.warning" />
    </StyledOutdatedBrowserWarning>
  );
};

export default OutdatedBrowserWarning;
