import { useIntl, FormattedMessage } from 'react-intl';
import Space from '@/components/Space';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { SelectProps } from '@/lib/types';
import { PerspectiveCohort } from '@/lib/types';

type PerspectiveCohortSelectProps = SelectProps<number> & {
  perspectiveCohorts: PerspectiveCohort[];
};

const PerspectiveCohortSelect = ({
  perspectiveCohorts,
  ...props
}: PerspectiveCohortSelectProps) => {
  const intl = useIntl();

  return (
    <>
      <Space height={18} />
      <FormControl size="small" fullWidth>
        <InputLabel id="perspective-cohort-select-label">
          <FormattedMessage id="user_manager.perspective_cohort_manager.select.label" />
        </InputLabel>
        <Select
          labelId="perspective-cohort-select-label"
          label={intl.formatMessage({
            id: 'user_manager.perspective_cohort_manager.select.label',
          })}
          {...props}
        >
          <MenuItem value="">
            <FormattedMessage id="user_manager.perspective_cohort_manager.select.options.none" />
          </MenuItem>
          {perspectiveCohorts.map(perspectiveCohort => (
            <MenuItem value={perspectiveCohort.id} key={perspectiveCohort.id}>
              {perspectiveCohort.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default PerspectiveCohortSelect;
