import React, { useContext, useState } from 'react';
import CloseChallengeContext from '../CloseChallengeContext';
import CopyButton from './CopyButton';
import ChallengeContext from '../../ChallengeContext';
import RowContext from '@/components/ChallengePortfolio/NormalView/Rows/Row/RowContext';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import useApi from '@/lib/api/useApi';
import useAddCopySuffix from '@/lib/utils/useAddCopySuffix';
import { ChallengeSummary } from '@/lib/types';

type CopyChallengeProps = {
  ButtonComponent?: React.ElementType;
};

const CopyChallenge = ({
  ButtonComponent: ButtonComponentOrUndefined,
}: CopyChallengeProps) => {
  const api = useApi();

  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const row = useContext(RowContext);
  const [challenge] = useContext(ChallengeContext);

  const [copying, setCopying] = useState(false);

  const addCopySuffix = useAddCopySuffix();
  const closeChallenge = useContext(CloseChallengeContext);

  const copyChallenge = async (): Promise<void> => {
    setCopying(true);

    const challengeCopy = await api.post<ChallengeSummary>(
      `challenge-portfolio/challenges/${challenge.id}/copy`,
      {
        name: addCopySuffix(challenge.name),
      },
    );

    setChallengePortfolio(
      (challengePortfolio => {
        challengePortfolio.rows[row.id].challenges.push(challengeCopy.id);
        challengePortfolio.challenges[challengeCopy.id] = challengeCopy;
      }),
    );

    setCopying(false);
    closeChallenge();
  };

  const ButtonComponent = ButtonComponentOrUndefined ?? CopyButton;

  return <ButtonComponent onClick={copyChallenge} disabled={copying} />;
};

export default CopyChallenge;
