import StyledChallengeName from './StyledChallengeName';
import { ChallengeSummary, TaskSummary } from '@/lib/types';

type ChallengeNameProps = {
  challenge: ChallengeSummary | TaskSummary;
};

const ChallengeName = ({ challenge }: ChallengeNameProps) => {
  const hasCoachCommentsBadge = challenge._count.coachComments > 0;

  return (
    <StyledChallengeName accountForBadge={hasCoachCommentsBadge}>
      {challenge.name}
    </StyledChallengeName>
  );
};

export default ChallengeName;
