import getDateRangeAsText from './getDateRangeAsText';
import { Moment } from 'moment';

type WeekDateRangeProps = {
  weekStartDate: Moment;
};

const WeekDateRange = ({ weekStartDate }: WeekDateRangeProps) => {
  const weekEndDate = weekStartDate.clone().endOf('isoWeek');

  return <h2>{getDateRangeAsText(weekStartDate, weekEndDate)}</h2>;
};

export default WeekDateRange;
