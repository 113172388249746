import FieldName from '../../../../utils/FieldName';
import OpenButton from './OpenButton';
import { Moment } from 'moment';
import React from 'react';

type DateDisplayProps = {
  date: Moment;
  onOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
};

const DateDisplay = ({ date, onOpen, disabled }: DateDisplayProps) => (
  <div>
    <FieldName />
    <OpenButton displayedDate={date} onClick={onOpen} disabled={disabled} />
  </div>
);

export default DateDisplay;
