import { Theme } from '@emotion/react';
import { grey } from '@mui/material/colors';

export const CONNECTOR_WRAPPER_WIDTH = 60;

type ConnectorStyles = {
  thickness: number;
  color: string;
  highlightColor: string;
};

export const getConnectorStyles = (theme: Theme): ConnectorStyles => {
  const connectorStyles: ConnectorStyles = {
    thickness: 2,
    color: theme.isDark ? grey[800] : grey[300],
    highlightColor: grey[500],
  };

  if (theme.palette.isHighContrast) {
    connectorStyles.highlightColor = theme.palette.text.primary;
  }

  return connectorStyles;
};
