import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const StyledForm = styled('form')({
  marginRight: 6,
  maxWidth: '50%',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(1024)]: {
    marginRight: 0,
    maxWidth: 'none',
  },
});

export default StyledForm;
