import React, { ForwardedRef, forwardRef, useContext } from 'react';
import PhaseContext from '../../PhaseContext';
import UnreadCoachCommentsBadge from './UnreadCoachCommentsBadge';
import ChallengeCardWithSearchMatching from './ChallengeCardWithSearchMatching';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import ContentWrapper from './ContentWrapper';
import ChallengeName from './ChallengeName';
import PhaseText from './PhaseText';
import ChallengeUserAvatars from './ChallengeUserAvatars';
import PlannedEndDatePreview from './PlannedEndDatePreview';
import usePerspectivesEnabled from '@/lib/utils/usePerspectivesEnabled';
import ChallengeCardPerspectiveIndicators from './ChallengeCardPerspectiveIndicators';
import { ChallengeSummary } from '@/lib/types';

type ChallengeCardProps = {
  challenge: ChallengeSummary;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  withPhaseText: boolean;
  collapsed: boolean;
  dragging: boolean;
  disabled?: boolean;
};

// TODO whether other props are necessary (maybe for styling)
// TODO (in general) replace generic existence checks with null checks where
// possible
const ChallengeCard = forwardRef(
  (
    {
      challenge,
      onClick,
      withPhaseText,
      collapsed,
      dragging,
      disabled,
      ...props
    }: ChallengeCardProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const perspectivesEnabled = usePerspectivesEnabled();
    const phase = useContext(PhaseContext);

    return (
      <UnreadCoachCommentsBadge challenge={challenge} collapsed={collapsed}>
        <ChallengeCardWithSearchMatching
          challengeName={challenge.name}
          big={phase !== KanbanPhase.DONE || withPhaseText}
          collapsed={collapsed}
          cardColor={challenge.color}
          onClick={onClick}
          disabled={collapsed || disabled}
          dragging={dragging}
          ref={ref}
          {...props}
        >
          <ContentWrapper hidden={collapsed}>
            {perspectivesEnabled && (
              <ChallengeCardPerspectiveIndicators challenge={challenge} />
            )}
            <ChallengeName challenge={challenge} />
            {withPhaseText && <PhaseText challenge={challenge} />}
            {phase !== KanbanPhase.DONE &&
              challenge.plannedEndDate &&
              !withPhaseText && (
                <PlannedEndDatePreview
                  date={challenge.plannedEndDate}
                  challengeColor={challenge.color}
                />
              )}
            <ChallengeUserAvatars challenge={challenge} />
          </ContentWrapper>
        </ChallengeCardWithSearchMatching>
      </UnreadCoachCommentsBadge>
    );
  },
);

ChallengeCard.displayName = 'ChallengeCard';

export default ChallengeCard;
