import React, { useContext } from 'react';
import FieldContext from '../../FieldContext';
import StyledFieldName from './StyledFieldName';
import NoFieldName from './NoFieldName';
import { FormattedMessage } from 'react-intl';

type FieldNameProps = {
  onClick: (event: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => void;
};

const FieldName = ({ onClick }: FieldNameProps) => {
  const { field } = useContext(FieldContext);

  return (
    <StyledFieldName onClick={onClick}>
      {field!.name ?? (
        <NoFieldName>
          <FormattedMessage id="editor.model_editor.field_editor.no_name" />
        </NoFieldName>
      )}
    </StyledFieldName>
  );
};

export default FieldName;
