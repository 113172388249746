import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const PhasesWrapper = styled('section')({
  width: '100%',
  minHeight: 'calc(100% - 50px)',
  display: 'flex',
  flex: 1,
  [mediaQuery(640)]: {
    display: 'block',
  },
});

export default PhasesWrapper;
