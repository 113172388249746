import SelectFieldPlaceholder from '@/components/SelectFieldPlaceholder';
import { ChallengePortfolio } from '@/lib/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const showRowNameOrPlaceholder = (
  challengePortfolio: ChallengePortfolio,
): ((selectValue: number | '') => React.ReactNode) => {
  const getSelectPlaceholderOrRowName = (
    selectValue: number | '',
  ): React.ReactNode => {
    if (selectValue === '') {
      return (
        <SelectFieldPlaceholder>
          <FormattedMessage id="portfolio.challenge_creation_flow.row_attachment.type_options.existing_row.row_select.placeholder" />
        </SelectFieldPlaceholder>
      );
    }

    return challengePortfolio.rows[selectValue].name;
  };

  return getSelectPlaceholderOrRowName;
};

export default showRowNameOrPlaceholder;
