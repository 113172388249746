import useUserRowIds from '../../useUserRowIds';
import useRowsVisibilityState from './useRowsVisibilityState';
import RowsVisibilityStateContext from './RowsVisibilityStateContext';
import NoPlannedChallengesInVisibleWeekRangeMessage from './NoPlannedChallengesInVisibleWeekRangeMessage';
import RowWithChallengePlanning from './RowWithChallengePlanning';

const RowsWithChallengePlanning = () => {
  const rowIds = useUserRowIds();
  const flattenedRowIds = rowIds.map(([rowId]) => rowId);

  const [noRowsVisible, registerRowVisibility] = useRowsVisibilityState();

  return (
    <RowsVisibilityStateContext.Provider value={{ registerRowVisibility }}>
      {flattenedRowIds.map(rowId => (
        <RowWithChallengePlanning rowId={rowId} key={rowId} />
      ))}
      {noRowsVisible && <NoPlannedChallengesInVisibleWeekRangeMessage />}
    </RowsVisibilityStateContext.Provider>
  );
};

export default RowsWithChallengePlanning;
