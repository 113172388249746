import { useContext } from 'react';
import PhaseContext from '../PhaseContext';
import { ConnectDragSource, DragSourceMonitor, useDrag } from 'react-dnd';
import DragType from '../DragType.enum';
import useApi from '@/lib/api/useApi';
import ChallengeContext from '../Challenge/ChallengeView/ChallengeContext';
import useIsPortfolioReadOnly from '../useIsPortfolioReadOnly';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import { TaskDropResult } from '../TaskBoard/KanbanPhaseColumn/useTaskPhaseDrop';
import { TaskSummary } from '@/lib/types';

export type TaskDragItem = Readonly<{
  type: DragType.TASK;
  phase: KanbanPhase;
}>;

// TODO: instead of handling local state update + API request at drop, could
// also update local state as the user drags. Would work better and feel more
// responsive
const useTaskDrag = (
  task: TaskSummary,
  dragDisabled: boolean,
): [false, null] | [boolean, ConnectDragSource] => {
  const api = useApi();

  const portfolioIsReadOnly = useIsPortfolioReadOnly();
  const [, setChallenge] = useContext(ChallengeContext);
  const phase = useContext(PhaseContext);

  const [{ isDragging }, dragRef] = useDrag({
    type: DragType.TASK,
    item: {
      type: DragType.TASK,
      phase,
    },
    end: (_, monitor: DragSourceMonitor<TaskDragItem, TaskDropResult>) => {
      const dropResult = monitor.getDropResult();

      if (dropResult === null || !dropResult.shouldDrop) {
        return;
      }

      setChallenge(challenge => {
        challenge.tasks[task.id].phase = dropResult.newPhase;
      });

      api.post(`challenge-portfolio/tasks/${task.id}/drag`, {
        phase: dropResult.newPhase,
      });
    },
    collect: (monitor: DragSourceMonitor<TaskDragItem, TaskDropResult>) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (portfolioIsReadOnly || dragDisabled) {
    return [false, null];
  }

  return [isDragging, dragRef];
};

export default useTaskDrag;
