import styled from '@emotion/styled';
import { TextField, TextFieldProps } from '@mui/material';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

export type EditFieldProps = Omit<TextFieldProps, 'variant' | 'size'>;

const StyledEditField = styled(TextField)(
  ({ theme }) => ({
    marginBottom: 12,
    width: '100%',
    textAlign: 'left',
    ':last-child': {
      marginBottom: 0,
    },
    '.MuiInputBase-root': {
      backgroundColor: theme.palette.action.hover,
    },
  }),
  addHighContrastStyles(({ theme }) => ({
    '.MuiInputBase-root': {
      backgroundColor: theme.palette.background.default,
    },
  })),
);

const EditField = (props: EditFieldProps) => (
  <StyledEditField variant="outlined" size="small" {...props} />
);

export default EditField;
