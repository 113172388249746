import { ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import FlagIcon from '../../FlagIcon';
import { SupportedLanguage } from '@/lib/utils/getLanguage';
import React from 'react';
import styled from '@emotion/styled';

type LanguageItemProps = {
  language: SupportedLanguage;
  languageName: React.ReactNode;
  languageNameTranslated?: React.ReactNode;
  selectedLanguage: SupportedLanguage;
  setSelectedLanguage: (language: SupportedLanguage) => void;
};

const StyledListItemButton = styled(ListItemButton)({
  // This overrides transparency for disabled items
  '&&&': {
    opacity: 1,
  },
});

const LanguageItem = ({
  language,
  languageName,
  languageNameTranslated,
  selectedLanguage,
  setSelectedLanguage,
}: LanguageItemProps) => (
  <StyledListItemButton
    onClick={() => setSelectedLanguage(language)}
    selected={selectedLanguage === language}
    disabled={selectedLanguage === language}
  >
    <ListItemAvatar>
      <FlagIcon language={language} />
    </ListItemAvatar>
    <ListItemText primary={languageName} secondary={languageNameTranslated} />
  </StyledListItemButton>
);

export default LanguageItem;
