import styled from '@emotion/styled';

const PerspectiveHeader = styled('header')({
  marginBottom: 12,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  h4: {
    lineHeight: 1,
  },
});

export default PerspectiveHeader;
