import styled from '@emotion/styled';

const BoxWrapper = styled('section')({
  marginTop: 24,
  marginBottom: 24,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
});

export default BoxWrapper;
