import styled from '@emotion/styled';
import { blue, orange, red } from '@mui/material/colors';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import { PlannedEndDateColor } from './getPlannedEndDateColor';

type StyledDateTextProps = {
  dateColor: PlannedEndDateColor;
  challengeIsWhite: boolean;
};

const StyledDateText = styled('div')<StyledDateTextProps>(
  ({ theme }) => ({
    marginBottom: 6,
    transition: theme.transitions.create('all'),
  }),
  ({ theme, dateColor, challengeIsWhite }) => {
    if (dateColor === null) {
      return {
        color: 'inherit',
        opacity: 0.6,
      };
    }

    const asBoxStyles = {
      padding: 4,
      fontSize: 14,
      borderRadius: 4,
    };

    const shadedColor = dateColor[theme.isDark ? 200 : 500];

    if (dateColor === orange || dateColor === red) {
      return {
        ...asBoxStyles,
        color: theme.palette.getContrastText(shadedColor),
        backgroundColor: shadedColor,
      };
    }

    if (dateColor === blue && !challengeIsWhite) {
      return {
        ...asBoxStyles,
        color: shadedColor,
        backgroundColor: theme.palette.getContrastText(shadedColor),
      };
    }

    return {
      color: shadedColor,
    };
  },
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  })),
);

export default StyledDateText;
