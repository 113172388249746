import artisticWorldImage from './world-images/artistic.png';
import scientificWorldImage from './world-images/scientific.png';
import socialEthicWorldImage from './world-images/social_ethic.png';
import societalWorldImage from './world-images/societal.png';
import spiritualWorldImage from './world-images/spiritual.png';
import { AgoraWorld } from '../worlds';
import { HTMLImgProps } from '@/lib/types';

type WorldImageProps = Omit<HTMLImgProps, 'src' | 'alt'> & {
  world: AgoraWorld;
};

const worldImages = {
  artistic: artisticWorldImage,
  scientific: scientificWorldImage,
  social_ethic: socialEthicWorldImage,
  societal: societalWorldImage,
  spiritual: spiritualWorldImage,
};

const WorldImage = ({ world, ...props }: WorldImageProps) => {
  const worldImage = worldImages[world];

  return <img src={worldImage} alt="" {...props} />;
};

export default WorldImage;
