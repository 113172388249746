import UserSelectFieldWrapper from '../UserSelectFieldWrapper';
import HideOnLowWidth from '@/components/HideOnLowWidth';
import { Skeleton } from '@mui/material';

const SelectLoadingPlaceholder = () => (
  <UserSelectFieldWrapper withMarginRight>
    <HideOnLowWidth width={1200}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width={180}
        height={24}
      />
    </HideOnLowWidth>
  </UserSelectFieldWrapper>
);

export default SelectLoadingPlaceholder;
