import styled from '@emotion/styled';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

type StyledWeekLabelProps = {
  highlighted: boolean;
};

const StyledWeekLabel = styled('h4')<StyledWeekLabelProps>(
  ({ theme, highlighted }) => ({
    marginBottom: 4,
    color: highlighted
      ? theme.palette.primary.main
      : theme.palette.text.secondary,
    fontWeight: 400,
    transition: theme.transitions.create(['color']),
  }),
  addHighContrastStyles(({ highlighted }) => {
    if (highlighted) {
      return {
        textDecoration: 'underline',
      };
    }
  }),
);

export default StyledWeekLabel;
