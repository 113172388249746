import styled from '@emotion/styled';

type FeedbackListTitleProps = {
  mobileVariant: boolean;
};

const FeedbackListTitle = styled('h4')<FeedbackListTitleProps>(
  ({ theme }) => ({
    marginBottom: 6,
    fontWeight: 400,
    color: theme.palette.primary.main,
  }),
  ({ theme, mobileVariant }) => {
    if (mobileVariant) {
      return {
        marginBottom: 24,
        color: theme.palette.text.primary,
        fontSize: 24,
        ':last-child': {
          marginBottom: 0,
        },
      };
    }
  },
);

export default FeedbackListTitle;
