import styled from '@emotion/styled';
import { Avatar, AvatarProps } from '@mui/material';
import isPropValid from '@emotion/is-prop-valid';

type StyledAvatarProps = AvatarProps & {
  size: number;
  marginRight?: number;
  backgroundColor: string;
};

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: isPropValid,
})<StyledAvatarProps>(({ size, marginRight, backgroundColor }) => ({
  margin: 0,
  marginRight:
    typeof marginRight === 'number' ? marginRight : size / 2 + size / 10,
  backgroundColor: backgroundColor,
  fontWeight: 300,
  '&&&': {
    width: size,
    height: size,
    fontSize: size / 2,
  },
}));

export default StyledAvatar;
