import { useContext } from 'react';
import PhaseContentGroupContext from '../../../../PhaseContentGroupContext';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import { TextField, TextFieldProps } from '@mui/material';
import { useIntl } from 'react-intl';

type EditorTextFieldProps = Omit<
  TextFieldProps,
  'variant' | 'placeholder' | 'fullWidth'
> & {
  placeholderId: string;
};

const EditorTextField = ({ placeholderId, ...props }: EditorTextFieldProps) => {
  const intl = useIntl();

  const currentPhaseContentGroup = useContext(PhaseContentGroupContext);
  const useFullWidth = currentPhaseContentGroup === ContentGroupType.RIGHT;

  return (
    <TextField
      variant="standard"
      placeholder={intl.formatMessage({
        id: placeholderId,
      })}
      fullWidth={useFullWidth}
      {...props}
    />
  );
};

export default EditorTextField;
