import styled from '@emotion/styled';
import { Button, ButtonProps } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

type AddButtonProps = Pick<ButtonProps, 'onClick' | 'disabled'>;

const StyledAddButton = styled(Button)({
  marginTop: 4,
  width: 'calc(100% - 40px)',
});

const AddButton = (props: AddButtonProps) => (
  <StyledAddButton
    variant="outlined"
    color="secondary"
    startIcon={<AddIcon />}
    {...props}
  >
    <FormattedMessage id="platform_settings.login_settings.add_allowed_email" />
  </StyledAddButton>
);

export default AddButton;
