const SELECTED_SCHOOL_LOCAL_STORAGE_KEY = 'selectedSchool';

export default class SelectedSchoolLocalStorage {
  static getSelectedSchool(): string | null {
    return localStorage.getItem(SELECTED_SCHOOL_LOCAL_STORAGE_KEY);
  }

  static setSelectedSchool(selectedSchool: string): void {
    localStorage.setItem(SELECTED_SCHOOL_LOCAL_STORAGE_KEY, selectedSchool);
  }

  static removeSelectedSchool(): void {
    localStorage.removeItem(SELECTED_SCHOOL_LOCAL_STORAGE_KEY);
  }
}
