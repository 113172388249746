import ChallengeViewCardWithPageOverlay from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeViewCardWithPageOverlay';
import ChallengeContentWrapper from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeContentWrapper';
import TaskContext from '@/components/ChallengePortfolio/NormalView/Task/TaskView/TaskContext';
import TaskHeader from '@/components/ChallengePortfolio/NormalView/Task/TaskView/TaskHeader';
import TaskInfo from '@/components/ChallengePortfolio/NormalView/Task/TaskView/TaskInfo';
import MobilePhaseSelect from '@/components/ChallengePortfolio/NormalView/Task/TaskView/MobilePhaseSelect';
import TaskFields from '@/components/ChallengePortfolio/NormalView/Task/TaskView/TaskFields';
import ChallengeNameBar from './ChallengeNameBar';
import { SetComplexImmerStateAction, Task } from '@/lib/types';

type CustomTaskViewProps = {
  task: Task;
  setTask: SetComplexImmerStateAction<Task>;
  onClose: () => void;
};

const CustomTaskView = ({ task, setTask, onClose }: CustomTaskViewProps) => {
  return (
    <ChallengeViewCardWithPageOverlay onClose={onClose}>
      <TaskContext.Provider value={[task, setTask]}>
        <ChallengeContentWrapper>
          {task.challenge.name !== null && <ChallengeNameBar />}
          <TaskHeader onCloseTask={onClose} />
          <TaskInfo />
          <MobilePhaseSelect />
          <TaskFields />
        </ChallengeContentWrapper>
      </TaskContext.Provider>
    </ChallengeViewCardWithPageOverlay>
  );
};

export default CustomTaskView;
