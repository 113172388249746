import React, { createContext, useState } from 'react';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import { ReactContextWithAutomatedProvider } from '@/lib/types';
import dummyFunction from '@/lib/utils/dummyFunction';

type VisiblePhaseState = {
  visibleChallengePhase: KanbanPhase;
  setVisibleChallengePhase: React.Dispatch<React.SetStateAction<KanbanPhase>>;
  visibleTaskPhase: KanbanPhase;
  setVisibleTaskPhase: React.Dispatch<React.SetStateAction<KanbanPhase>>;
};

const VisiblePhaseContext = createContext<VisiblePhaseState>({
  visibleChallengePhase: KanbanPhase.TODO,
  setVisibleChallengePhase: dummyFunction,
  visibleTaskPhase: KanbanPhase.TODO,
  setVisibleTaskPhase: dummyFunction,
}) as ReactContextWithAutomatedProvider<VisiblePhaseState>;

type AutomatedVisiblePhaseContextProviderProps = {
  children: React.ReactNode;
};

const AutomatedVisiblePhaseContextProvider = ({
  children,
}: AutomatedVisiblePhaseContextProviderProps) => {
  const [visibleChallengePhase, setVisibleChallengePhase] = useState(
    KanbanPhase.TODO,
  );
  const [visibleTaskPhase, setVisibleTaskPhase] = useState(KanbanPhase.TODO);

  return (
    <VisiblePhaseContext.Provider
      value={{
        visibleChallengePhase,
        setVisibleChallengePhase,
        visibleTaskPhase,
        setVisibleTaskPhase,
      }}
    >
      {children}
    </VisiblePhaseContext.Provider>
  );
};

VisiblePhaseContext.AutomatedProvider = AutomatedVisiblePhaseContextProvider;

export default VisiblePhaseContext;
