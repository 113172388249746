import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const LegendItems = styled('ul')({
  margin: 0,
  marginBottom: -20,
  padding: 0,
  flex: 1,
  lineHeight: 1,
  listStyle: 'none',
  display: 'inline-block',
  [mediaQuery(600)]: {
    width: '100%',
  },
});

export default LegendItems;
