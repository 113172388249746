import styled from '@emotion/styled';
import { common, grey } from '@mui/material/colors';

const BackgroundSlideshowPlaceholder = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.isDark ? common.black : grey[900],
}));

export default BackgroundSlideshowPlaceholder;
