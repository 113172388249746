import ButtonWrapper from './ButtonWrapper';
import ButtonLabel from './ButtonLabel';
import AddIconWrapper from './AddIconWrapper';
import { Add as AddIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { ButtonBaseProps } from '@mui/material';

type CreateRowButtonProps = Omit<ButtonBaseProps, 'id' | 'children'>;

const CreateRowButton = (props: CreateRowButtonProps) => (
  <ButtonWrapper id="create-row-button" {...props}>
    <ButtonLabel id="button-label" elevation={3}>
      <FormattedMessage id="portfolio.create_row.button" />
    </ButtonLabel>
    <AddIconWrapper id="icon-wrapper">
      <AddIcon />
    </AddIconWrapper>
  </ButtonWrapper>
);

export default CreateRowButton;
