import { AttachFile as AttachmentIcon } from '@mui/icons-material';
import styled from '@emotion/styled';
import { ButtonBase, ButtonBaseProps } from '@mui/material';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

type AttachmentButtonProps = Omit<ButtonBaseProps, 'children'> & {
  attachedTasksCount: number;
};

const StyledButton = styled(ButtonBase)(
  ({ theme }) => ({
    marginLeft: 8,
    padding: 4,
    paddingLeft: 2,
    paddingRight: 6,
    fontSize: 16,
    fontFamily: 'CircularStd',
    color: theme.palette.text.secondary,
    borderRadius: 4,
    opacity: 0.8,
    transition: theme.transitions.create(['background-color', 'opacity']),
    '.MuiSvgIcon-root': {
      marginRight: 2,
      fontSize: 20,
    },
    ':hover': {
      opacity: 1,
      backgroundColor: theme.palette.action.hover,
    },
  }),
  addHighContrastStyles({
    opacity: 1,
  }),
);

const AttachmentButton = ({
  attachedTasksCount,
  ...props
}: AttachmentButtonProps) => (
  <StyledButton {...props}>
    <AttachmentIcon />
    {attachedTasksCount}
  </StyledButton>
);

export default AttachmentButton;
