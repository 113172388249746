/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, RefObject } from 'react';
import ensureResultVisibleInResultList from './ensureResultVisibleInResultList';
import StyledUserResult from './StyledUserResult';
import UserAvatar from '@/components/UserAvatar';
import UserName from './UserName';
import { User } from '@/lib/types';
import { HTMLButtonProps } from '@/lib/types';

type UserResultProps = HTMLButtonProps & {
  user: User;
  keyboardSelected: boolean;
  listRef: RefObject<HTMLUListElement>;
};

const UserResult = ({
  user,
  keyboardSelected,
  listRef,
  ...props
}: UserResultProps) => {
  const resultRef = useRef(null);

  useEffect(() => {
    if (!listRef.current || !resultRef.current || !keyboardSelected) {
      return;
    }

    ensureResultVisibleInResultList(resultRef.current, listRef.current);
  }, [keyboardSelected]);

  return (
    <li
      id={`user-result-${user.email}`}
      role="option"
      aria-selected={keyboardSelected}
      ref={resultRef}
    >
      <StyledUserResult
        tabIndex={-1}
        keyboardSelected={keyboardSelected}
        {...props}
      >
        <UserAvatar user={user} size={28} />
        <UserName>{user.name}</UserName>
      </StyledUserResult>
    </li>
  );
};

export default UserResult;
