import { useContext, useState } from 'react';
import RandomColorThemeProvider from './RandomColorThemeProvider';
import AutomatedAvatar from './AutomatedAvatar';
import StyledChip from './StyledChip';
import useRemoveAssignee from '../useRemoveAssignee';
import useIsPortfolioReadOnly from '@/components/ChallengePortfolio/NormalView/useIsPortfolioReadOnly';
import IsInTaskAttachmentViewContext from '@/components/perspectives/IsInTaskAttachmentViewContext';
import { UserSummary } from '@/lib/types';

type AssigneeProps = {
  user: UserSummary;
};

const Assignee = ({ user }: AssigneeProps) => {
  const portfolioIsReadOnly = useIsPortfolioReadOnly();
  const isInTaskAttachmentView = useContext(IsInTaskAttachmentViewContext);

  const [removing, setRemoving] = useState(false);
  const removeAssignee = useRemoveAssignee();

  const handleRemove = async (): Promise<void> => {
    setRemoving(true);

    await removeAssignee(user);

    setRemoving(false);
  };

  return (
    <RandomColorThemeProvider>
      <StyledChip
        avatar={<AutomatedAvatar user={user} />}
        label={user.name}
        onDelete={
          !portfolioIsReadOnly && !isInTaskAttachmentView
            ? handleRemove
            : undefined
        }
        disabled={removing}
      />
    </RandomColorThemeProvider>
  );
};

export default Assignee;
