import styled from '@emotion/styled';
import stripProps from '@/lib/utils/stripProps';
import isPropValid from '@emotion/is-prop-valid';

type ProgressBarProps = {
  progress: number;
  color: string;
  progressEqual: boolean;
  onTop: boolean;
  reversed: boolean;
};

const ProgressBar = styled(stripProps('div', 'onTop'), {
  shouldForwardProp: isPropValid,
})<ProgressBarProps>(
  ({ theme, progress, color, progressEqual, onTop, reversed }) => ({
    position: 'absolute',
    right: reversed ? 0 : 'unset',
    left: reversed ? 'unset' : 0,
    width: `${progress}%`,
    height: '100%',
    backgroundColor: progressEqual ? theme.palette.sliders.both : color,
    transition: theme.transitions.create('all'),
    zIndex: onTop ? 2 : 1,
  }),
);

export default ProgressBar;
