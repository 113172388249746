import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import FieldName from '../utils/FieldName';
import RubricsPortfolioContext from '@/lib/contexts/RubricsPortfolioContext';
import Part from '@/components/RubricsPortfolio/Portfolio/Part';
import useMimicRubricsPortfolio from './useMimicRubricsPortfolio';

const Rubrics = () => {
  const field = useContext(FieldContext);
  const [rubricsPortfolio, setRubricsPortfolio] = useMimicRubricsPortfolio();

  return (
    <section>
      <FieldName />
      <section>
        <RubricsPortfolioContext.Provider
          value={[rubricsPortfolio, setRubricsPortfolio]}
        >
          {field.parts.map(part => (
            <Part part={part} noFocusButton mobileOutlined key={part.id} />
          ))}
        </RubricsPortfolioContext.Provider>
      </section>
    </section>
  );
};

export default Rubrics;
