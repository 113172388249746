export type SupportedLanguage = 'nl' | 'en' | 'fr';

export const supportedLanguages = ['nl', 'en', 'fr'] as const;
const fallbackLanguage = 'en';

const getBrowserLanguage = (): SupportedLanguage => {
  for (const language of supportedLanguages) {
    if (navigator.language.startsWith(language)) {
      return language;
    }
  }

  return fallbackLanguage;
};

const getLanguage = (): SupportedLanguage => {
  const localLanguage = localStorage.getItem('language');

  if (
    localLanguage !== null &&
    ((supportedLanguages as unknown) as string[]).includes(localLanguage)
  ) {
    return localLanguage as SupportedLanguage;
  }

  return getBrowserLanguage();
};

export default getLanguage;
