import styled from '@emotion/styled';

type GapminderPlaceholderProps = {
  visible: boolean;
};

const GapminderPlaceholder = styled('section')<GapminderPlaceholderProps>(
  ({ theme, visible }) => ({
    height: 114,
    backgroundColor: theme.palette.background.shaded,
    borderRadius: 8,
    display: visible ? 'block' : 'none',
  }),
);

export default GapminderPlaceholder;
