import styled from '@emotion/styled';
import ButtonBase from '@/components/ButtonBase';
import { HTMLButtonProps } from '@/lib/types';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

type StyledUserResultProps = HTMLButtonProps & {
  keyboardSelected: boolean;
};

const StyledUserResult = styled(ButtonBase)<StyledUserResultProps>(
  ({ theme, keyboardSelected }) => ({
    padding: '10px 15px',
    width: '100%',
    backgroundColor: keyboardSelected
      ? theme.palette.action.selected
      : undefined,
    transition: theme.transitions.create('all'),
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }),
  addHighContrastStyles(({ keyboardSelected }) => ({
    h4: {
      textDecoration: keyboardSelected ? 'underline' : null,
    },
    ':hover h4': {
      textDecoration: 'underline',
    },
  })),
);

export default StyledUserResult;
