import { useContext } from 'react';
import FieldContext from '../../../FieldContext';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import ModelsContext from '../../../../../../ModelsContext';
import ModelTypeContext from '../../../../../ModelTypeContext';
import useApi from '@/lib/api/useApi';
import { EditorFieldWithoutRelations } from '@/lib/types';

const useMoveCurrentFieldToPhaseContentGroup = (
  allHighestPositions: Record<ContentGroupType, number>,
  lowestFieldPositionInPhaseBottomContent: number,
): ((contentGroup: ContentGroupType) => Promise<void>) => {
  const api = useApi();

  const modelType = useContext(ModelTypeContext);
  const [, setModels] = useContext(ModelsContext);

  const { field } = useContext(FieldContext);

  const newLowestPositionForBottomContent =
    lowestFieldPositionInPhaseBottomContent / 2 || 1;

  const moveCurrentFieldToPhaseContentGroup = async (
    newPhaseContentGroup: ContentGroupType,
  ): Promise<void> => {
    const highestPositionInNewPhaseContentGroup =
      allHighestPositions[newPhaseContentGroup];

    const newPosition =
      newPhaseContentGroup === ContentGroupType.BOTTOM
        ? newLowestPositionForBottomContent
        : highestPositionInNewPhaseContentGroup + 1;

    const updatedField = await api.post<EditorFieldWithoutRelations>(
      `editor/challenge-model/fields/${field!.id}/position/set-content-group`,
      {
        contentGroup: newPhaseContentGroup,
        position: newPosition,
      },
    );

    setModels(models => {
      models[modelType].fields[field!.id] = {
        ...models[modelType].fields[field!.id],
        ...updatedField,
      };
    });
  };

  return moveCurrentFieldToPhaseContentGroup;
};

export default useMoveCurrentFieldToPhaseContentGroup;
