import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import StyledFieldWrapper from './StyledFieldWrapper';
import FieldName from '../utils/FieldName';
import InnerSelect from './InnerSelect';
import useIsPortfolioReadOnly from '@/components/ChallengePortfolio/NormalView/useIsPortfolioReadOnly';

const SelectField = () => {
  const portfolioIsReadOnly = useIsPortfolioReadOnly();

  const field = useContext(FieldContext);
  const options = Object.entries(field.additionalConfig.options ?? {});

  if (!options || options.length === 0) {
    return null;
  }

  return (
    <StyledFieldWrapper>
      <FieldName />
      <InnerSelect disabled={portfolioIsReadOnly} />
    </StyledFieldWrapper>
  );
};

export default SelectField;
