import styled from '@emotion/styled';
import ButtonBase from '@/components/ButtonBase';
import mediaQuery from '@/lib/styling/mediaQuery';
import { ButtonBaseProps } from '@mui/material';

type MarkerWrapperProps = ButtonBaseProps & {
  hasZoom: boolean;
};

const MarkerWrapper = styled(ButtonBase)<MarkerWrapperProps>(
  ({ theme, hasZoom }) => ({
    position: 'relative',
    padding: hasZoom ? '18px 12px' : 12,
    flex: 1,
    textAlign: 'center',
    fontFamily: 'CircularStd',
    transition: theme.transitions.create('all'),
    borderRight: hasZoom
      ? 'none'
      : `2px solid ${theme.palette.background.border}`,
    boxSizing: 'border-box',
    ':last-child': {
      borderRightColor: 'transparent',
    },
    [mediaQuery(700)]: {
      padding: 12,
      borderRight: 'none',
    },
  }),
  ({ theme, hasZoom }) => {
    if (!hasZoom) {
      return {
        '@media (min-width: 701px)': {
          cursor: 'pointer',
          ':not(:active):hover': {
            backgroundColor: theme.palette.action.hover,
          },
          ':active .content-wrapper': {
            transform: 'scale(0.95)',
          },
        },
      };
    }
  },
);

export default MarkerWrapper;
