import styled from '@emotion/styled';

const GapminderButtonLabel = styled('span')({
  marginLeft: 6,
  marginRight: 18,
  lineHeight: '24px',
  fontSize: 14,
  fontWeight: 500,
  textTransform: 'uppercase',
});

export default GapminderButtonLabel;
