import StyledDateText from './StyledDateText';
import getPlannedEndDateColor from './getPlannedEndDateColor';
import formatPlannedEndDate from './formatPlannedEndDate';

type PlannedEndDatePreviewProps = {
  date: string;
  challengeColor: string | null;
};

const PlannedEndDatePreview = ({
  date,
  challengeColor,
}: PlannedEndDatePreviewProps) => {
  const timestamp = new Date(date).getTime();
  const challengeIsWhite = ['#FFF', '#FFFFFF'].includes(
    // Using common.white here won't work
    challengeColor ?? '#FFFFFF',
  );

  return (
    <StyledDateText
      dateColor={getPlannedEndDateColor(timestamp)}
      challengeIsWhite={challengeIsWhite}
    >
      {formatPlannedEndDate(timestamp)}
    </StyledDateText>
  );
};

export default PlannedEndDatePreview;
