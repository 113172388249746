import styled from '@emotion/styled';
import StyledLevel from '@/components/RubricsPortfolioComponents/StyledLevel';
import mediaQuery from '@/lib/styling/mediaQuery';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';

type AddLevelWrapperProps = {
  collapsed: boolean;
};

const AddLevelWrapper = styled(StyledLevel)<AddLevelWrapperProps>(
  ({ theme }) => ({
    maxWidth: 400,
    minHeight: 200,
    backgroundColor: theme.palette.action.selected,
    border: 'none',
    [mediaQuery(1024)]: {
      maxWidth: '100%',
    },
    [mediaQuery(850)]: {
      minWidth: 'calc(50% - 25px)',
    },
    [mediaQuery(600)]: {
      minWidth: 'calc(100% - 25px)',
    },
  }),
  ({ theme, collapsed }) => {
    if (collapsed) {
      return {
        backgroundColor: theme.palette.action.hover,
        justifyContent: 'center',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: theme.palette.action.selected,
        },
      };
    }
  },
  addHighContrastStyles(({ collapsed }) => {
    if (collapsed) {
      return {
        ':hover': {
          textDecoration: 'underline',
        },
      };
    }
  }),
  addBorderInHighContrastMode,
);

export default AddLevelWrapper;
