import styled from '@emotion/styled';
import IconBase, { IconBaseProps } from './IconBase';

type IconProps = IconBaseProps & {
  src: string;
};

const IconBaseAsFigure = IconBase.withComponent('figure');

// We need to manually add the className again because .withComponent erases it
const CustomIconBase = ({ className, ...props }: IconBaseProps) => (
  <IconBaseAsFigure
    className={`perspective-card__icon ${className}`}
    {...props}
  />
);

const Icon = styled(CustomIconBase)<IconProps>(({ src }) => ({
  margin: 0,
  backgroundImage: `url('${src}')`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
}));

export default Icon;
