import styled from '@emotion/styled';
import LoadingSpinner from '@/components/LoadingSpinner';
import Space from '@/components/Space';
import { FormattedMessage } from 'react-intl';

const LoadingMessageWrapper = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
});

const LoadingMessageText = styled('strong')({
  fontWeight: 500,
});

const LoadingMessage = () => (
  <LoadingMessageWrapper>
    <LoadingSpinner size={16} />
    <Space width={8} />
    <LoadingMessageText>
      <FormattedMessage id="portfolio.gapminder.loading" />
    </LoadingMessageText>
  </LoadingMessageWrapper>
);

export default LoadingMessage;
