import { useState } from 'react';
import MakeCreateRowVisibleOnHover from './MakeCreateRowVisibleOnHover';
import CreateRow from './CreateRow';
import CreateChallengeButton from './CreateChallengeButton';
import ChallengeCreationDialog from './ChallengeCreationDialog';

type CreateChallengeWithRowSelectionProps = {
  rowIds: number[];
};

const CreateChallengeWithRowSelection = ({
  rowIds,
}: CreateChallengeWithRowSelectionProps) => {
  const [
    challengeCreationDialogOpen,
    setChallengeCreationDialogOpen,
  ] = useState(false);

  return (
    <MakeCreateRowVisibleOnHover>
      <CreateRow />
      <CreateChallengeButton
        onClick={() => setChallengeCreationDialogOpen(true)}
      />
      <ChallengeCreationDialog
        open={challengeCreationDialogOpen}
        onClose={() => setChallengeCreationDialogOpen(false)}
        rowIds={rowIds}
      />
    </MakeCreateRowVisibleOnHover>
  );
};

export default CreateChallengeWithRowSelection;
