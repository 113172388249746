import React, { ForwardedRef, forwardRef, useRef } from 'react';
import useForceUpdate from 'use-force-update';
import useKeydownListener from '@/lib/utils/useKeydownListener';
import composeRefs from '@seznam/compose-react-refs';
import InputBase from '@/components/InputBase';
import { HTMLInputProps } from '../lib/types';

export type InputWithEscListenerProps<InputProps = HTMLInputProps> = Omit<
  InputProps,
  'onFocus'
> & {
  onEscPress: (event: KeyboardEvent) => void;
  InputComponent?: React.ElementType;
  onFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
};

const InputWithEscListener = forwardRef(function <InputProps = HTMLInputProps>(
  {
    onEscPress,
    InputComponent: InputComponentOrUndefined,
    onFocus,
    ...props
  }: InputWithEscListenerProps<InputProps>,
  externalRef: ForwardedRef<HTMLInputElement>,
) {
  const inputRef = useRef(null);
  const forceUpdate = useForceUpdate();

  useKeydownListener(inputRef.current, (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onEscPress(event);
    }
  });

  const InputComponent = InputComponentOrUndefined ?? InputBase;

  return (
    <InputComponent
      {...props}
      ref={composeRefs(inputRef, externalRef)}
      onFocus={(e: React.FocusEvent<HTMLInputElement, HTMLElement>) => {
        // We need to force-update here to force the keydown listener to
        // "refresh". If we don't do this, it won't work until the user has
        // changed the input value
        forceUpdate();

        // TODO type check can be removed?
        if (typeof onFocus === 'function') {
          onFocus(e);
        }
      }}
    />
  );
}) as <InputProps = HTMLInputProps>(
  props: InputWithEscListenerProps<InputProps>,
) => React.ReactNode;

export default InputWithEscListener;
