import useSelectedTheme from '@/lib/utils/useSelectedTheme';
import useHighContrastModeEnabled from '@/lib/utils/useHighContrastModeEnabled';
import Theme from '@/lib/constants/Theme.enum';
import palettes from './palettes';
import { PaletteOptions } from '@mui/material/styles';

const useActivePalette = (): PaletteOptions => {
  const [selectedTheme] = useSelectedTheme();
  const [highContrastModeEnabled] = useHighContrastModeEnabled();

  if (!highContrastModeEnabled && selectedTheme === Theme.LIGHT) {
    return palettes.light;
  }

  if (highContrastModeEnabled && selectedTheme === Theme.LIGHT) {
    return palettes.lightHighContrast;
  }

  if (!highContrastModeEnabled && selectedTheme === Theme.DARK) {
    return palettes.dark;
  }

  if (highContrastModeEnabled && selectedTheme === Theme.DARK) {
    return palettes.darkHighContrast;
  }

  return palettes.light;
};

export default useActivePalette;
