import { ThemeOptions } from '@mui/material';

const forceCircularFontStyles = {
  fontFamily: 'CircularStd',
};

const fontConfig = {
  typography: {
    fontFamily: 'Inter',
    h1: forceCircularFontStyles,
    h2: forceCircularFontStyles,
    h3: forceCircularFontStyles,
    h4: forceCircularFontStyles,
    h5: forceCircularFontStyles,
    h6: forceCircularFontStyles,
    subtitle1: forceCircularFontStyles,
    subtitle2: forceCircularFontStyles,
    button: forceCircularFontStyles,
    caption: forceCircularFontStyles,
  },
  components: {
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: forceCircularFontStyles,
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: forceCircularFontStyles,
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: forceCircularFontStyles,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: forceCircularFontStyles,
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: forceCircularFontStyles,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: forceCircularFontStyles,
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: forceCircularFontStyles,
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: forceCircularFontStyles,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: forceCircularFontStyles,
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: forceCircularFontStyles,
        secondary: forceCircularFontStyles,
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: forceCircularFontStyles,
      },
    },
  },
} satisfies ThemeOptions;

export default fontConfig;
