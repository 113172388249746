import { useContext, useState } from 'react';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import CloseChallengeContext from './CloseChallengeContext';
import MobileChallengeActions from './MobileChallengeActions';
import EditButton from './EditButton';
import ChallengeLinkSharing from './ChallengeLinkSharing';
import ChallengeNameEditor from './ChallengeNameEditor';
import DeleteChallenge from './DeleteChallenge';
import CopyChallenge from './CopyChallenge';
import HeaderWrapper from './HeaderWrapper';
import InnerHeaderWrapper from './InnerHeaderWrapper';
import ChallengeName from './ChallengeName';
import TasksButton from './TasksButton';
import ChallengeContext from '../ChallengeContext';
import RowContext from '@/components/ChallengePortfolio/NormalView/Rows/Row/RowContext';

type ChallengeHeaderProps = {
  onTasksOpen: () => void;
  onCloseChallenge: () => void;
  loading?: boolean;
};

// TODO when challenge is loading, disable this (locked prop)
const ChallengeHeader = ({
  onTasksOpen,
  onCloseChallenge,
  loading,
}: ChallengeHeaderProps) => {
  const [challenge] = useContext(ChallengeContext);
  const row = useContext(RowContext);

  const [nameEditing, setNameEditing] = useState(false);

  if (nameEditing) {
    return <ChallengeNameEditor onClose={() => setNameEditing(false)} />;
  }

  return (
    <HeaderWrapper>
      <InnerHeaderWrapper>
        <ChallengeName>{challenge.name}</ChallengeName>
        <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
          <CloseChallengeContext.Provider value={onCloseChallenge}>
            <MobileChallengeActions onEdit={() => setNameEditing(true)} />
            <EditButton onClick={() => setNameEditing(true)} />
            {row && <CopyChallenge />}
            <DeleteChallenge />
            <ChallengeLinkSharing />
          </CloseChallengeContext.Provider>
        </RestrictedToPortfolioMode>
      </InnerHeaderWrapper>
      {!loading && <TasksButton onClick={onTasksOpen} />}
    </HeaderWrapper>
  );
};

export default ChallengeHeader;
