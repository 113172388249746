import useSlideshowImage from './useSlideshowImage';
import { AnimatePresence } from 'framer-motion';
import SlideshowImage from './SlideshowImage';

const BackgroundSlideshow = () => {
  const displayedImage = useSlideshowImage();

  return (
    <AnimatePresence>
      <SlideshowImage src={displayedImage} key={displayedImage} />
    </AnimatePresence>
  );
};

export default BackgroundSlideshow;
