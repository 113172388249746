import { useContext } from 'react';
import LatestVisibleWeekRangeShiftDirectionContext from './LatestVisibleWeekRangeShiftDirectionContext';
import ShiftDirection from './LatestVisibleWeekRangeShiftDirectionContext/ShiftDirection.enum';
import { motion } from 'framer-motion';
import { OmitFramerProps, HTMLDivProps } from '@/lib/types';

type WrapperWithWeekRangeShiftingAnimationsProps = OmitFramerProps<
  HTMLDivProps
>;

const WrapperWithWeekRangeShiftingAnimations = (
  props: WrapperWithWeekRangeShiftingAnimationsProps,
) => {
  const latestVisibleWeekRangeShiftDirection = useContext(
    LatestVisibleWeekRangeShiftDirectionContext,
  );

  const startingPointsForShiftDirections = {
    [ShiftDirection.LEFT]: '-100%',
    [ShiftDirection.RIGHT]: '100%',
  };

  return (
    <motion.section
      initial={{
        x:
          latestVisibleWeekRangeShiftDirection === null
            ? 0
            : startingPointsForShiftDirections[
                latestVisibleWeekRangeShiftDirection
              ],
      }}
      animate={{
        x: 0,
      }}
      transition={{
        duration: 0.3,
        ease: 'easeInOut',
      }}
      {...props}
    />
  );
};

export default WrapperWithWeekRangeShiftingAnimations;
