import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const ChallengeName = styled('h1')({
  marginRight: 12,
  fontSize: 36,
  [mediaQuery(640)]: {
    marginRight: 0,
    fontSize: 32,
  },
  [mediaQuery(560)]: {
    fontSize: 28,
  },
});

export default ChallengeName;
