import { AnimatePresence } from 'framer-motion';
import LoadingScreenWrapper from './LoadingScreenWrapper';
import StyledEgodactLogo from './StyledEgodactLogo';
import LoadingSpinner from '@/components/LoadingSpinner';
import React from 'react';

type LoadingScreenProps = {
  active: boolean;
  children: React.ReactNode;
};

const LoadingScreen = ({ active, children }: LoadingScreenProps) => (
  <>
    <AnimatePresence>
      {active && (
        <LoadingScreenWrapper key="loading-screen">
          <StyledEgodactLogo />
          <LoadingSpinner centered />
        </LoadingScreenWrapper>
      )}
    </AnimatePresence>
    {!active && children}
  </>
);

export default LoadingScreen;
