import styled from '@emotion/styled';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';

const StyledLogEntry = styled('article')(
  ({ theme }) => ({
    padding: '16px 24px',
    paddingBottom: 0,
    backgroundColor: theme.palette.action.hover,
    overflow: 'hidden',
    transition: theme.transitions.create('all'),
    ':first-of-type': {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    ':last-of-type': {
      paddingBottom: 16,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
  }),
  addBorderInHighContrastMode,
);

export default StyledLogEntry;
