import { useContext } from 'react';
import { Button } from '@mui/material';
import { ExitToApp as ExitIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import AuthenticationStateContext from '@/components/App/AppSetup/AuthenticationState/AuthenticationStateContext';

const LogoutButton = () => {
  const api = useApi();

  const [authenticatedUser, refreshAuthenticationState] = useContext(
    AuthenticationStateContext,
  );

  const isLoggedIn = !!authenticatedUser;
  if (!isLoggedIn) {
    return null;
  }

  const logout = () => {
    api.unauthenticate();

    refreshAuthenticationState();

    window.location.reload();
  };

  return (
    <Button
      variant="contained"
      onClick={logout}
      startIcon={<ExitIcon />}
      disableElevation
    >
      <FormattedMessage id="error_boundary.fixes.logout_button" />
    </Button>
  );
};

export default LogoutButton;
