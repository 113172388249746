import StyledMessage from './StyledMessage';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import CreateFirstChallenge from './CreateFirstChallenge';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

const NoChallengesMessage = () => (
  <StyledMessage>
    <FormattedMessageWithChallengeNaming id="portfolio.challenge_portfolio.no_challenges" />
    <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
      <CreateFirstChallenge />
    </RestrictedToPortfolioMode>
  </StyledMessage>
);

export default NoChallengesMessage;
