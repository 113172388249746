import ErrorScreenWrapper from './ErrorScreenWrapper';
import DangerousButton from '@/components/DangerousButton';
import { Refresh as RetryIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import EmailNotAllowedErrorScreenContent from './EmailNotAllowedErrorScreenContent';
import GenericErrorScreenContent from './GenericErrorScreenContent';
import useProcessError from './useProcessError';
import React from 'react';
import { HTMLDivProps } from '@/lib/types';

type ErrorScreenProps = HTMLDivProps & {
  error: unknown;
  onDismiss: (event: React.MouseEvent<HTMLButtonElement>) => void;
  visible: boolean;
};

const ErrorScreen = ({
  error,
  onDismiss,
  visible,
  ...props
}: ErrorScreenProps) => {
  const errorCode = useProcessError(error);

  return (
    <ErrorScreenWrapper visible={visible} {...props}>
      {errorCode === 'auth/email-disallowed' ? (
        <EmailNotAllowedErrorScreenContent />
      ) : (
        <GenericErrorScreenContent errorCode={errorCode} />
      )}
      <DangerousButton
        variant="contained"
        onClick={onDismiss}
        startIcon={<RetryIcon />}
        disabled={!visible}
        disableElevation
      >
        <FormattedMessage id="login.error_screen.retry_button" />
      </DangerousButton>
    </ErrorScreenWrapper>
  );
};

export default ErrorScreen;
