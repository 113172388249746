import React, { useContext } from 'react';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import PortfolioIsReadOnlyContext from '../lib/contexts/PortfolioIsReadOnlyContext';
import PortfolioMode from '../lib/constants/PortfolioMode.enum';

type RestrictedToPortfolioModeProps = {
  mode: PortfolioMode;
  children: React.ReactNode;
};

const RestrictedToPortfolioMode = ({
  mode: allowedPortfolioMode,
  children,
}: RestrictedToPortfolioModeProps) => {
  const currentPortfolioMode = useContext(PortfolioModeContext);
  const portfolioIsReadOnly = useContext(PortfolioIsReadOnlyContext);

  if (currentPortfolioMode !== allowedPortfolioMode) {
    return null;
  }

  if (allowedPortfolioMode === PortfolioMode.STUDENT && portfolioIsReadOnly) {
    return null;
  }

  return children;
};

export default RestrictedToPortfolioMode;
