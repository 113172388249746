import UserRole from '@/lib/constants/UserRole.enum';
import UserContext from './UserContext';
import UserWrapper from './UserWrapper';
import UserSelectCheckbox from './UserSelectCheckbox';
import StyledUserAvatar from './StyledUserAvatar';
import UserInfoWrapper from './UserInfoWrapper';
import UserName from './UserName';
import UserEmail from './UserEmail';
import GroupSelect from './GroupSelect';
import BasicUserControlsWrapper from './BasicUserControlsWrapper';
import RoleSelect from './RoleSelect';
import DeleteUser from './DeleteUser';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import App from '@/lib/App';
import PerspectiveCohortManager from './PerspectiveCohortManager';
import { UserWithId } from '@/lib/types';
import Space from '@/components/Space';

type UserProps = {
  user: UserWithId;
  selected: boolean;
  onSelectUser: (checked: boolean) => void;
};

const User = ({ user, selected, onSelectUser }: UserProps) => {
  const perspectiveModuleEnabled = App.instanceConfig.useSetting(
    'enablePerspectiveModule',
  );
  // TODO these references should be updated to reworked App.authentication
  // stuff
  const authenticatedUser = useAuthenticatedUser()!;

  // If user is null, the user has been deleted
  if (user === null) {
    return null;
  }

  const isSelf = user.email === authenticatedUser.email;
  const mutationControlsDisabled = isSelf || user.role === UserRole.SUPER_ADMIN;

  const isCoach = [
    UserRole.COACH,
    UserRole.EDITOR,
    UserRole.ADMIN,
    UserRole.SUPER_ADMIN,
  ].includes(user.role);

  return (
    <UserContext.Provider value={user}>
      <UserWrapper>
        <UserSelectCheckbox
          checked={selected}
          onChange={(_, checked) => onSelectUser(checked)}
          disabled={mutationControlsDisabled}
        />
        <StyledUserAvatar user={user} />
        <UserInfoWrapper>
          <UserName />
          <UserEmail>{user.email}</UserEmail>
        </UserInfoWrapper>
        {perspectiveModuleEnabled ? (
          <PerspectiveCohortManager />
        ) : (
          <Space width="auto" />
        )}
        <GroupSelect asManagedGroups={isCoach} />
        <BasicUserControlsWrapper>
          <RoleSelect disabled={mutationControlsDisabled} />
          <DeleteUser disabled={mutationControlsDisabled} />
        </BasicUserControlsWrapper>
      </UserWrapper>
    </UserContext.Provider>
  );
};

export default User;
