import React, { useContext } from 'react';
import InnerUnreadCoachCommentsBadge from './InnerUnreadCoachCommentsBadge';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import NewlySharedContext from '@/components/ChallengePortfolio/NormalView/NewlySharedChallenges/NewlySharedContext';
import useIsPortfolioReadOnly from '../../../useIsPortfolioReadOnly';
import { ChallengeSummary } from '@/lib/types';

type UnreadCoachCommentsBadgeProps = {
  challenge: ChallengeSummary;
  collapsed: boolean;
  children: React.ReactNode;
};

const UnreadCoachCommentsBadge = ({
  challenge,
  collapsed,
  children,
}: UnreadCoachCommentsBadgeProps) => {
  const portfolioIsReadOnly = useIsPortfolioReadOnly();
  const [isNewlyShared, , setNewlySharedChallenges] =
    useContext(NewlySharedContext);
  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);

  const unreadCoachCommentsCount = challenge._count.coachComments;

  const markCoachCommentsAsRead = (): void => {
    if (collapsed || unreadCoachCommentsCount === 0 || portfolioIsReadOnly) {
      return;
    }

    if (isNewlyShared) {
      setNewlySharedChallenges(newlySharedChallenges => {
        newlySharedChallenges[challenge.id]._count.coachComments = 0;
      });

      return;
    }

    setChallengePortfolio(challengePortfolio => {
      challengePortfolio.challenges[challenge.id]._count.coachComments = 0;
    });
  };

  return (
    <InnerUnreadCoachCommentsBadge
      unreadCoachCommentsCount={unreadCoachCommentsCount}
      onClick={markCoachCommentsAsRead}
      collapsed={collapsed}
    >
      {children}
    </InnerUnreadCoachCommentsBadge>
  );
};

export default UnreadCoachCommentsBadge;
