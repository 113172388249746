import styled from '@emotion/styled';

const PhaseText = styled('h4')(({ theme }) => ({
  marginTop: 4,
  marginBottom: 4,
  color: theme.palette.text.secondary,
  fontWeight: 400,
}));

export default PhaseText;
