import React, { useContext } from 'react';
import useIsFullWidthOnMobile from './useIsFullWidthOnMobile';
import StyledFieldWrapper from './StyledFieldWrapper';
import PortfolioIsReadOnlyContext from '@/lib/contexts/PortfolioIsReadOnlyContext';
import CoachFeedback from './CoachFeedback';

type FieldWrapperProps = {
  noCoachFeedback: boolean;
  children: React.ReactNode;
};

const FieldWrapper = ({ noCoachFeedback, children }: FieldWrapperProps) => {
  const portfolioIsReadOnly = useContext(PortfolioIsReadOnlyContext);
  const isFullWidthOnMobile = useIsFullWidthOnMobile();

  return (
    <StyledFieldWrapper
      className={isFullWidthOnMobile ? 'mobile-full-width' : undefined}
    >
      {children}
      {!noCoachFeedback && !portfolioIsReadOnly && <CoachFeedback />}
    </StyledFieldWrapper>
  );
};

export default FieldWrapper;
