import { ClassNames, useTheme } from '@emotion/react';
import { Badge, BadgeProps } from '@mui/material';

type InnerUnreadCoachCommentsBadge = BadgeProps & {
  unreadCoachCommentsCount: number;
  collapsed?: boolean;
};

const InnerUnreadCoachCommentsBadge = ({
  unreadCoachCommentsCount,
  collapsed,
  ...props
}: InnerUnreadCoachCommentsBadge) => {
  const theme = useTheme();

  return (
    <ClassNames>
      {({ css }) => (
        <Badge
          badgeContent={unreadCoachCommentsCount}
          variant={collapsed ? 'dot' : 'standard'}
          color="primary"
          classes={{
            badge: css({
              transition: theme.transitions.create('all'),
              transform: 'translate(40%, -30%)',
              opacity: unreadCoachCommentsCount === 0 ? 0 : 1,
            }),
          }}
          {...props}
        />
      )}
    </ClassNames>
  );
};

export default InnerUnreadCoachCommentsBadge;
