import isBrowserOutdated from './isBrowserOutdated';
import OutdatedBrowserWarning from './OutdatedBrowserWarning';

const OutdatedBrowserDetection = () => {
  if (!isBrowserOutdated()) {
    return null;
  }

  return <OutdatedBrowserWarning />;
};

export default OutdatedBrowserDetection;
