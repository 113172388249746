import { useState } from 'react';
import OptionWrapper from './OptionWrapper';
import OptionNameEditor from './OptionNameEditor';
import OptionName from './OptionName';
import EditButton from './EditButton';
import DeleteOption from './DeleteOption';

type OptionEditorProps = {
  // TODO add type for this (FieldSelectOption?) and use everywhere
  option: {
    key: string;
    name: string;
  };
};

const OptionEditor = ({ option }: OptionEditorProps) => {
  const [nameEditing, setNameEditing] = useState(false);

  return (
    <OptionWrapper>
      {nameEditing ? (
        <OptionNameEditor
          option={option}
          onClose={() => setNameEditing(false)}
        />
      ) : (
        <>
          <OptionName>{option.name}</OptionName>
          <EditButton onClick={() => setNameEditing(true)} />
        </>
      )}
      <DeleteOption option={option} />
    </OptionWrapper>
  );
};

export default OptionEditor;
