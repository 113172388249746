import styled from '@emotion/styled';
import AddItemButton from '../AddItemButton';
import firstChildSelector from '@/lib/styling/firstChildSelector';

const AddPartButton = styled(AddItemButton)({
  marginTop: 12,
  [firstChildSelector]: {
    marginTop: 0,
  },
});

export default AddPartButton;
