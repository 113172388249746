import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import GroupsManager from '../../../GroupsManager';
import ModelTypeContext from '../../../../ModelTypeContext';
import ModelsContext from '../../../../../ModelsContext';
import useApi from '@/lib/api/useApi';
import PortfolioItemWithGroupsPromptVariant from '@/pages/EditorPage/RubricsPortfolioTemplatesEditor/PortfolioTemplatesEditor/PortfolioItemWithGroupsPrompt/PortfolioItemWithGroupsPromptVariant.enum';
import { EditorFieldWithoutRelations } from '@/lib/types';

const FieldGroups = () => {
  const api = useApi();

  const modelType = useContext(ModelTypeContext);
  const [, setModels] = useContext(ModelsContext);
  const { field } = useContext(FieldContext);

  const saveGroups = async (groups: number[]): Promise<void> => {
    const updatedField = await api.put<EditorFieldWithoutRelations>(
      `editor/challenge-model/fields/${field!.id}/groups`,
      {
        groups,
      },
    );

    setModels(models => {
      models[modelType].fields[field!.id] = {
        ...models[modelType].fields[field!.id],
        ...updatedField,
        groups: groups.map(id => ({ id })),
      };
    });
  };

  return (
    <GroupsManager
      variant={PortfolioItemWithGroupsPromptVariant.FIELD}
      selectedGroups={field!.groups.map(({ id }) => id)}
      onSave={saveGroups}
    />
  );
};

export default FieldGroups;
