import { useEffect } from 'react';
import useSelectedTheme from '@/lib/utils/useSelectedTheme';
import useHighContrastModeEnabled from '@/lib/utils/useHighContrastModeEnabled';
import Theme from '@/lib/constants/Theme.enum';

const useSyncDisplaySettingsToBody = (): void => {
  const [selectedTheme] = useSelectedTheme();

  useEffect(() => {
    if (selectedTheme === Theme.DARK) {
      document.body.classList.add('is-dark');
      return;
    }

    document.body.classList.remove('is-dark');
  }, [selectedTheme]);

  const [highContrastModeEnabled] = useHighContrastModeEnabled();

  useEffect(() => {
    if (highContrastModeEnabled) {
      document.body.classList.add('is-high-contrast');
      return;
    }

    document.body.classList.remove('is-high-contrast');
  }, [highContrastModeEnabled]);
};

export default useSyncDisplaySettingsToBody;
