import Dialog from '@/components/Dialog';
import { ButtonProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type DialogAddButtonProps = Omit<ButtonProps, 'color' | 'children'>;

const DialogAddButton = (props: DialogAddButtonProps) => (
  <Dialog.Actions.Button color="primary" {...props}>
    <FormattedMessage id="portfolio.challenge.field.text_editor.link_prompt.add_button" />
  </Dialog.Actions.Button>
);

export default DialogAddButton;
