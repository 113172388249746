import React, { useContext } from 'react';
import FormWrapper from './FormWrapper';
import AllowedEmailTextField from '../AllowedEmailTextField';
import DangerousIconButton from '@/components/DangerousIconButton';
import { Close as DeleteIcon } from '@mui/icons-material';
import Space from '@/components/Space';
import SchoolContext from '../../../SchoolContext';
import { AllowedEmail } from '@/lib/types';

type AllowedEmailFormProps = {
  allowedEmail: AllowedEmail;
  onEdit: (updatedAllowedEmail: AllowedEmail) => void;
  onRemove: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

// TODO refactor naming?
// TODO make @ for domain field non-optional
const AllowedEmailForm = ({
  allowedEmail,
  onEdit,
  onRemove,
}: AllowedEmailFormProps) => {
  const [loading] = useContext(SchoolContext);

  const onEditAllowedEmailPrefix = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    onEdit([e.target.value.trim(), allowedEmail[1]]);
  };

  const onEditAllowedEmailDomain = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    onEdit([allowedEmail[0], e.target.value.trim()]);
  };

  return (
    <FormWrapper>
      <AllowedEmailTextField
        value={allowedEmail[0]}
        onChange={onEditAllowedEmailPrefix}
        disabled={loading}
        asPrefixField
      />
      <Space width={8} />
      <AllowedEmailTextField
        value={allowedEmail[1]}
        onChange={onEditAllowedEmailDomain}
        disabled={loading}
      />
      <DangerousIconButton onClick={onRemove} disabled={loading}>
        <DeleteIcon />
      </DangerousIconButton>
    </FormWrapper>
  );
};

export default AllowedEmailForm;
