import { useRef, useEffect } from 'react';
import ResponsiveTextField, {
  ResponsiveTextFieldProps,
} from './ResponsiveTextField';
import { useIntl, FormattedMessage } from 'react-intl';

type RowTextFieldProps = Omit<
  ResponsiveTextFieldProps,
  'variant' | 'placeholder' | 'error' | 'helperText' | 'inputRef'
> & {
  hasError: boolean;
  challengeNameHasError: boolean;
};

const RowTextField = ({
  hasError,
  challengeNameHasError,
  ...props
}: RowTextFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (hasError && !challengeNameHasError && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [hasError, challengeNameHasError]);

  const intl = useIntl();
  return (
    <ResponsiveTextField
      variant="standard"
      placeholder={intl.formatMessage({
        id:
          'portfolio.challenge_creation_flow.row_attachment.type_options.new_row.name_field.placeholder',
      })}
      error={hasError}
      helperText={
        hasError && (
          <FormattedMessage id="portfolio.challenge_creation_flow.row_attachment.type_options.new_row.name_field.errors.empty" />
        )
      }
      inputRef={inputRef}
      {...props}
    />
  );
};

export default RowTextField;
