import StyledActionButton, {
  StyledActionButtonProps,
} from './StyledActionButton';
import React from 'react';

export type RowActionButtonProps = Omit<StyledActionButtonProps, 'onClick'> & {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const RowActionButton = ({ onClick, ...props }: RowActionButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    // TODO remove this typecheck?
    if (typeof onClick === 'function') {
      // We don't want row action buttons to also trigger the row collapsing on
      // row header clicks
      e.stopPropagation();
      onClick(e);
    }
  };

  return <StyledActionButton onClick={handleClick} {...props} />;
};

export default RowActionButton;
