import styled from '@emotion/styled';

const ErrorText = styled('p')(({ theme }) => ({
  marginTop: 24,
  marginBottom: 24,
  fontSize: 15,
  color: theme.palette.text.secondary,
  lineHeight: 1.5,
}));

export default ErrorText;
