import { useContext, useState } from 'react';
import FieldContext from '../../../../FieldContext';
import InfoButton from './InfoButton';
import Dialog from '@/components/Dialog';
import FormattedText from '@/components/FormattedText';

const FieldInfo = () => {
  const field = useContext(FieldContext);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const closeInfo = () => setInfoDialogOpen(false);

  return (
    <>
      <InfoButton onClick={() => setInfoDialogOpen(true)} />
      <Dialog
        open={infoDialogOpen}
        onClose={closeInfo}
        onEnterClose={closeInfo}
      >
        <Dialog.Title>{field.name}</Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedText>{field.info!}</FormattedText>
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.DoneButton onClick={closeInfo} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default FieldInfo;
