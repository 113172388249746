import useChallengeNaming from './useChallengeNaming';
import useSetting from './useSetting';
import useSettings from './useSettings';

const instanceConfig = {
  useChallengeNaming,
  useSetting,
  useSettings,
} as const;

export default instanceConfig;
