import { useContext } from 'react';
import StyledSelect, { StyledSelectProps } from './StyledSelect';
import { MenuItem } from '@mui/material';
import showRowNameOrPlaceholder from './showRowNameOrPlaceholder';
import ChallengePortfolioContext from '../../../../../../../ChallengePortfolioContext';

type RowSelectProps = Omit<
  StyledSelectProps,
  'variant' | 'value' | 'renderValue' | 'displayEmpty' | 'children'
> & {
  value: number | null;
};

const RowSelect = ({ value, ...props }: RowSelectProps) => {
  const [challengePortfolio] = useContext(ChallengePortfolioContext);

  return (
    <StyledSelect
      variant="standard"
      value={value ?? ''}
      renderValue={showRowNameOrPlaceholder(challengePortfolio)}
      displayEmpty
      {...props}
    >
      {Object.values(challengePortfolio.rows).map(row => (
        <MenuItem value={row.id} key={row.id}>
          {row.name}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default RowSelect;
