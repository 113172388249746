import { useTheme } from '@emotion/react';
import LegendOuterWrapper from './LegendOuterWrapper';
import LegendItems from './LegendItems';
import LegendItem from './LegendItem';
import ColorCircle from './ColorCircle';
import StyledFocusIcon from './StyledFocusIcon';
import GapminderButton from './GapminderButton';
import { FormattedMessage } from 'react-intl';

type ColorLegendProps = {
  portfolioSnapshotsOverviewLoading: boolean;
  onOpenGapminder: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
};

const ColorLegend = ({
  portfolioSnapshotsOverviewLoading,
  onOpenGapminder,
}: ColorLegendProps) => {
  const theme = useTheme();

  return (
    <LegendOuterWrapper>
      <LegendItems>
        <LegendItem>
          <ColorCircle color={theme.palette.sliders.student} />
          <FormattedMessage id="portfolio.color_legend.student" />
        </LegendItem>
        <LegendItem>
          <ColorCircle color={theme.palette.sliders.coach} />
          <FormattedMessage id="portfolio.color_legend.coach" />
        </LegendItem>
        <LegendItem>
          <ColorCircle color={theme.palette.sliders.both} />
          <FormattedMessage id="portfolio.color_legend.both" />
        </LegendItem>
        <LegendItem>
          <StyledFocusIcon />
          <FormattedMessage id="portfolio.color_legend.focus" />
        </LegendItem>
      </LegendItems>
      <GapminderButton
        onClick={onOpenGapminder}
        disabled={portfolioSnapshotsOverviewLoading}
      />
    </LegendOuterWrapper>
  );
};

export default ColorLegend;
