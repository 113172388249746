import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const LabelText = styled('div')({
  marginRight: 12,
  [mediaQuery(420)]: {
    marginRight: 0,
    marginBottom: 6,
  },
});

export default LabelText;
