import { useState, useContext } from 'react';
import ModelTypeContext from '../ModelTypeContext';
import Separator from './Separator';
import { Button } from '@mui/material';
import { AddCircle as AddIcon } from '@mui/icons-material';
import Prompt from '@/components/Prompt';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import ModelsContext from '../../ModelsContext';
import useApi from '@/lib/api/useApi';
import { EditorSectionWithoutRelations } from '@/lib/types';

type AddModelPhaseProps = {
  highestPhasePosition: number;
};

const AddModelPhase = ({ highestPhasePosition }: AddModelPhaseProps) => {
  const api = useApi();

  const [, setModels] = useContext(ModelsContext);
  const modelType = useContext(ModelTypeContext);

  const [addPromptOpen, setAddPromptOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveNewModelPhase = async (name: string): Promise<void> => {
    setAddPromptOpen(false);
    setSaving(true);

    const phaseData = {
      name,
      position: highestPhasePosition + 1,
    };

    const phase = await api.post<EditorSectionWithoutRelations>(
      `editor/challenge-model/sections?modelType=${modelType}`,
      phaseData,
    );

    setModels(models => {
      models[modelType].sections[phase.id] = {
        ...phase,
        groups: [],
        fields: [],
      };
    });

    setSaving(false);
  };

  return (
    <>
      <Separator />
      <Button
        color="secondary"
        onClick={() => setAddPromptOpen(true)}
        startIcon={<AddIcon />}
        disabled={saving}
        fullWidth
      >
        <FormattedMessage id="editor.model_editor.add_phase.button" />
      </Button>
      <Prompt
        open={addPromptOpen}
        onCancel={() => setAddPromptOpen(false)}
        onOk={saveNewModelPhase}
        OkButton={Dialog.Actions.CreateButton}
        emptyError="prompt.errors.name_empty"
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.add_phase.title" />
        </Dialog.Title>
        <Prompt.TextField
          label={<FormattedMessage id="editor.model_editor.add_phase.label" />}
        />
      </Prompt>
    </>
  );
};

export default AddModelPhase;
