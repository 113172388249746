import { useContext, useState } from 'react';
import FieldContext from '../../FieldContext';
import CollapsibleActionMenu from '@/components/CollapsibleActionMenu';
import { ListItemIcon } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import ModelTypeContext from '../../../../ModelTypeContext';
import ModelsContext from '../../../../../ModelsContext';
import useApi from '@/lib/api/useApi';
import { EditorFieldWithoutRelations } from '@/lib/types';

const ClearFieldName = () => {
  const api = useApi();

  const modelType = useContext(ModelTypeContext);
  const [, setModels] = useContext(ModelsContext);
  const { field } = useContext(FieldContext);

  const [saving, setSaving] = useState(false);

  const clearName = async (): Promise<void> => {
    setSaving(true);

    const updatedField = await api.delete<EditorFieldWithoutRelations>(
      `editor/challenge-model/fields/${field!.id}/name`,
    );

    setModels(models => {
      models[modelType].fields[field!.id] = {
        ...models[modelType].fields[field!.id],
        ...updatedField,
      };
    });

    setSaving(false);
  };

  const nameAlreadyCleared = !field!.name || field!.name.trim() === '';

  return (
    <CollapsibleActionMenu.Button
      onClick={clearName}
      disabled={nameAlreadyCleared || saving}
    >
      <ListItemIcon>
        <ClearIcon />
      </ListItemIcon>
      <FormattedMessage id="editor.model_editor.actions.clear_name" />
    </CollapsibleActionMenu.Button>
  );
};

export default ClearFieldName;
