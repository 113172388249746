import SwitcherWrapper from './SwitcherWrapper';
import StyledGroup from './StyledGroup';
import DraftsButton from './DraftsButton';
import { FormattedMessage } from 'react-intl';
import Group from '@/lib/constants/Group.enum';

type VisibleGroupSwitcherProps = {
  visibleGroup: Group;
  onChangeVisibleGroup: (newVisibleGroup: Group) => void;
};

const VisibleGroupSwitcher = ({
  visibleGroup,
  onChangeVisibleGroup,
}: VisibleGroupSwitcherProps) => {
  const updateVisibleGroup = (newVisibleGroup: Group): void => {
    if (newVisibleGroup !== visibleGroup) {
      onChangeVisibleGroup(newVisibleGroup);
    }
  };

  const shownGroups = [Group.ALL, Group.STUDENTS, Group.COACHES];

  return (
    <SwitcherWrapper>
      {shownGroups.map(group => (
        <StyledGroup
          selected={visibleGroup === group}
          onClick={() => updateVisibleGroup(group)}
          key={group}
        >
          <FormattedMessage id={`group_names.${group}`} />
        </StyledGroup>
      ))}
      <DraftsButton
        selected={visibleGroup === Group.DRAFTS}
        onClick={() => updateVisibleGroup(Group.DRAFTS)}
      />
    </SwitcherWrapper>
  );
};

export default VisibleGroupSwitcher;
