import styled from '@emotion/styled';
import DangerColorCircularProgress from './DangerColorCircularProgress';

type DeletionProgressIndicatorProps = {
  deletionProgress: number;
};

const StyledCircularProgress = styled(DangerColorCircularProgress)({
  position: 'absolute',
});

const DeletionProgressIndicator = ({
  deletionProgress,
}: DeletionProgressIndicatorProps) => (
  <StyledCircularProgress
    thickness={3}
    variant="determinate"
    value={Math.min(100, deletionProgress)}
  />
);

export default DeletionProgressIndicator;
