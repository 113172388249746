import DangerousButton from '@/components/DangerousButton';
import { FormattedMessage } from 'react-intl';
import { DialogButtonProps } from './DialogButton';

const DialogDeleteButton = ({ children, ...props }: DialogButtonProps) => (
  <DangerousButton inDialog {...props}>
    {children ?? <FormattedMessage id="dialogs.actions.delete" />}
  </DangerousButton>
);

export default DialogDeleteButton;
