import { LinkOff as DetachIcon } from '@mui/icons-material';
import { IconButtonProps, Tooltip } from '@mui/material';
import AttachmentControlButton from './AttachmentControlButton';
import { FormattedMessage } from 'react-intl';

type DetachButtonProps = Omit<IconButtonProps, 'children'>;

const DetachButton = (props: DetachButtonProps) => (
  <Tooltip
    title={
      <FormattedMessage id="perspectives.attachment_controls.detach_button" />
    }
    arrow
  >
    <AttachmentControlButton
      className="perspective-card__detach-button"
      {...props}
    >
      <DetachIcon />
    </AttachmentControlButton>
  </Tooltip>
);

export default DetachButton;
