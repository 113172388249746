import styled from '@emotion/styled';

const NormalUserSwitchArrowLabel = styled('h4')({
  marginTop: -4,
  marginBottom: 0,
  fontSize: 12,
  fontWeight: 400,
});

export default NormalUserSwitchArrowLabel;
