import styled from '@emotion/styled';

type IndicatorWrapperProps = {
  side: 'left' | 'right';
};

const IndicatorWrapper = styled('figure')<IndicatorWrapperProps>(
  ({ side }) => ({
    marginTop: 0,
    marginBottom: 0,
    marginLeft: side === 'left' ? -12 : 'auto',
    marginRight: side === 'right' ? -6 : 0,
    opacity: 0.8,
    // This somehow fixes focus outline being off-key
    display: 'inline-flex',
  }),
);

export default IndicatorWrapper;
