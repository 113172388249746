import React, { useContext, useState } from 'react';
import FormWrapper from './FormWrapper';
import GroupNameInput from './GroupNameInput';
import { IconButton } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import useApi from '@/lib/api/useApi';
import GroupsContext from '../../../../GroupsContext';
import { Group } from '@/lib/types';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

type GroupNameEditorProps = {
  group: Group;
  onClose: () => void;
};

const GroupNameEditor = ({ group, onClose }: GroupNameEditorProps) => {
  const api = useApi();

  const [, , setGroups] = useContext(GroupsContext);

  const [newGroupName, setNewGroupName, , reenableGroupNameSync] =
    useComplexSwrState(group.name);
  const [saving, setSaving] = useState(false);

  const saveNameChange = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (!newGroupName.trim()) {
      return;
    }

    setSaving(true);

    await api.put(`groups/${group.id}/name`, {
      name: newGroupName.trim(),
    });

    setGroups(groups => {
      const groupIndex = groups.findIndex(element => element.id === group.id);

      groups[groupIndex].name = newGroupName.trim();
    });

    setSaving(false);

    onClose();

    reenableGroupNameSync();
  };

  const cancelNameChange = () => {
    onClose();

    setNewGroupName(group.name);
    reenableGroupNameSync();
  };

  const intl = useIntl();
  return (
    <FormWrapper onSubmit={saveNameChange}>
      <GroupNameInput
        placeholder={intl.formatMessage({
          id: 'group_manager.group_form.input_label',
        })}
        value={newGroupName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setNewGroupName(e.target.value)
        }
        onEscPress={cancelNameChange}
        autoFocus
      />
      <IconButton type="submit" disabled={!newGroupName.trim() || saving}>
        <CheckIcon />
      </IconButton>
    </FormWrapper>
  );
};

export default GroupNameEditor;
