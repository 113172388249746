import { useState, useContext } from 'react';
import UserSelectFieldWrapper from '../User/UserSelectFieldWrapper';
import GroupSelectField from '../User/GroupSelect/GroupSelectField';
import GroupsContext from '@/pages/AdminPage/GroupsContext';
import UsersContext from '../UsersContext';
import useApi from '@/lib/api/useApi';
import Space from '@/components/Space';

type MultipleUsersGroupSelectProps = {
  selectedUsersIds: number[];
  asManagedGroups?: boolean;
};

const MultipleUsersGroupSelect = ({
  selectedUsersIds,
  asManagedGroups,
}: MultipleUsersGroupSelectProps) => {
  const api = useApi();

  const [, , setUsers] = useContext(UsersContext);
  const [groupsLoading, groups] = useContext(GroupsContext);

  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const [saving, setSaving] = useState(false);

  // TODO verify this works correctly
  const saveGroupChanges = async (): Promise<void> => {
    setSaving(true);

    // TODO maybe this could be rewritten?
    if (asManagedGroups) {
      await api.post('users/bulk/groups/managed', {
        userIds: selectedUsersIds,
        groupIds: selectedGroups,
      });

      setUsers(users => {
        for (const user of users) {
          if (selectedUsersIds.includes(user.id)) {
            user.managedGroups = selectedGroups;
          }
        }
      });

      setSaving(false);

      return;
    }

    await api.post('users/bulk/groups', {
      userIds: selectedUsersIds,
      groupIds: selectedGroups,
    });

    setUsers(users => {
      for (const user of users) {
        if (selectedUsersIds.includes(user.id)) {
          user.groups = selectedGroups;
        }
      }
    });

    setSaving(false);
  };

  if (groupsLoading) {
    return null;
  }

  // TODO double margin now occurs on small screens, figure this out
  return (
    <>
      <Space width={24} />
      <UserSelectFieldWrapper withMarginRight>
        <GroupSelectField
          value={selectedGroups}
          onChange={e => setSelectedGroups(e.target.value as number[])}
          groups={groups}
          asManagedGroups={asManagedGroups ?? false}
          disabled={saving}
          MenuProps={{
            TransitionProps: {
              onExit: saveGroupChanges,
            },
          }}
        />
      </UserSelectFieldWrapper>
    </>
  );
};

export default MultipleUsersGroupSelect;
