import LoginButton from '../LoginButton';
import { FormattedMessage } from 'react-intl';
import MicrosoftLogo from './microsoft-logo.svg?react';
import useLoginWithMicrosoft from './useLoginWithMicrosoft';

type MicrosoftLoginProps = {
  onShowError: (error: unknown) => void;
  disabled: boolean;
};

const MicrosoftLogin = ({ onShowError, disabled }: MicrosoftLoginProps) => {
  const [loginWithMicrosoft, loading] = useLoginWithMicrosoft(onShowError);

  return (
    <LoginButton
      onClick={loginWithMicrosoft}
      startIcon={<MicrosoftLogo />}
      loading={loading}
      loadingPosition="start"
      disabled={disabled}
    >
      <FormattedMessage id="login.sign_in_button.microsoft.label" />
    </LoginButton>
  );
};

export default MicrosoftLogin;
