import CardConnectors from './CardConnectors';
import TreeColumn from '../../TreeColumn';
import ColumnOuterWrapper from './ColumnOuterWrapper';
import React from 'react';

type TreeColumnWithCardConnectorsProps = {
  cardCount: number;
  parentCardIndex: number;
  previousColumnCardCount: number;
  highlightedConnector: number | null;
  hideCardConnectors?: boolean;
  children: React.ReactNode;
};

const TreeColumnWithCardConnectors = ({
  cardCount,
  parentCardIndex,
  previousColumnCardCount,
  highlightedConnector,
  hideCardConnectors,
  children,
}: TreeColumnWithCardConnectorsProps) => (
  <ColumnOuterWrapper>
    {!hideCardConnectors && (
      <CardConnectors
        cardCount={cardCount}
        parentCardIndex={parentCardIndex}
        previousColumnCardCount={previousColumnCardCount}
        highlightedConnector={highlightedConnector}
      />
    )}
    <TreeColumn>{children}</TreeColumn>
  </ColumnOuterWrapper>
);

export default TreeColumnWithCardConnectors;
