import { useState, useContext } from 'react';
import FieldContext from '../../FieldContext';
import useApi from '@/lib/api/useApi';
import FieldRendererContext from '../../../FieldRendererContext';

const useSetCurrentFieldValue = <T>(): [
  (value: T) => Promise<void>,
  boolean,
] => {
  const api = useApi();

  const { setPortfolioItem, portfolioItemApiEndpoint } =
    useContext(FieldRendererContext);
  const field = useContext(FieldContext);

  const [saving, setSaving] = useState(false);

  const setCurrentFieldValue = async (value: T): Promise<void> => {
    setSaving(true);

    const stringifiedValue = JSON.stringify(value);

    await api.post(`${portfolioItemApiEndpoint}/${field.id}`, {
      value: stringifiedValue,
    });

    setPortfolioItem(portfolioItem => {
      if (typeof portfolioItem.fieldValues[field.id] === 'undefined') {
        portfolioItem.fieldValues[field.id] = {
          typeSpecificValues: {},
        };
      }

      if (
        typeof portfolioItem.fieldValues[field.id].typeSpecificValues[
          field.type
        ] === 'undefined'
      ) {
        portfolioItem.fieldValues[field.id].typeSpecificValues[field.type] = {
          type: field.type,
          value: '',
        };
      }

      portfolioItem.fieldValues[field.id].typeSpecificValues[field.type].value =
        stringifiedValue;
    });

    setSaving(false);
  };

  return [setCurrentFieldValue, saving];
};

export default useSetCurrentFieldValue;
