import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { IconButton, IconButtonProps } from '@mui/material';
import { OmitFramerProps } from '@/lib/types';

type HeaderActionIconButtonProps = OmitFramerProps<IconButtonProps>;

const MotionIconButton = motion(IconButton);

const HeaderActionIconButton = forwardRef(
  (
    props: HeaderActionIconButtonProps,
    ref: React.ForwardedRef<HTMLElement | SVGElement>,
  ) => (
    <MotionIconButton
      initial={{
        scale: 0,
      }}
      animate={{
        scale: 1,
      }}
      size="large"
      {...props}
      ref={ref}
    />
  ),
);

HeaderActionIconButton.displayName = 'HeaderActionIconButton';

export default HeaderActionIconButton;
