import { AttachedTaskSummary, UnprocessedAttachmentInfo } from '@/lib/types';
import moment from 'moment';

const PERSPECTIVE_FILL_THRESHOLD = 10;
const FADE_THRESHOLD = 90;

const computeFillThreshold = (sublevelNestingIndex: number | null): number => {
  const isPerspective = sublevelNestingIndex === null;

  if (isPerspective) {
    return PERSPECTIVE_FILL_THRESHOLD;
  }

  switch (sublevelNestingIndex) {
    case 0: {
      return Math.round(0.75 * PERSPECTIVE_FILL_THRESHOLD);
    }
    case 1: {
      return Math.round(PERSPECTIVE_FILL_THRESHOLD / 2);
    }
    case 2: {
      return Math.round(0.375 * PERSPECTIVE_FILL_THRESHOLD);
    }
    default: {
      return PERSPECTIVE_FILL_THRESHOLD / 4;
    }
  }
};

const getMostRecentTaskCreatedAt = (
  tasks: AttachedTaskSummary[],
): string | null => {
  if (tasks.length === 0) {
    return null;
  }

  let mostRecentCreatedAt = tasks[0].createdAt;

  for (const task of tasks) {
    if (task.createdAt > mostRecentCreatedAt) {
      mostRecentCreatedAt = task.createdAt;
    }
  }

  return mostRecentCreatedAt;
};

const getMostRecentActivity = (
  attachmentInfo: UnprocessedAttachmentInfo,
): string | null => {
  const mostRecentDirectlyAttachedTaskCreatedAt = getMostRecentTaskCreatedAt(
    attachmentInfo.directlyAttachedTasks,
  );
  const mostRecentIndirectlyAttachedTaskCreatedAt = getMostRecentTaskCreatedAt(
    attachmentInfo.indirectlyAttachedTasks,
  );

  if (
    mostRecentDirectlyAttachedTaskCreatedAt === null &&
    mostRecentIndirectlyAttachedTaskCreatedAt === null
  ) {
    return null;
  }

  if (mostRecentDirectlyAttachedTaskCreatedAt === null) {
    return mostRecentIndirectlyAttachedTaskCreatedAt;
  }

  if (mostRecentIndirectlyAttachedTaskCreatedAt === null) {
    return mostRecentDirectlyAttachedTaskCreatedAt;
  }

  if (
    mostRecentDirectlyAttachedTaskCreatedAt >=
    mostRecentIndirectlyAttachedTaskCreatedAt
  ) {
    return mostRecentDirectlyAttachedTaskCreatedAt;
  }

  return mostRecentIndirectlyAttachedTaskCreatedAt;
};

export const computeFillLevel = (
  attachedTasksCount: number,
  sublevelNestingIndex: number | null = null,
): number => {
  return Math.min(
    1,
    attachedTasksCount / computeFillThreshold(sublevelNestingIndex),
  );
};

export const computeFillFade = (
  attachmentInfo: UnprocessedAttachmentInfo,
): number => {
  const mostRecentActivity = getMostRecentActivity(attachmentInfo);

  if (mostRecentActivity === null) {
    return 0;
  }

  const daysAgo = moment().diff(moment(mostRecentActivity), 'days');

  if (daysAgo <= 0) {
    return 0;
  }

  return Math.min(daysAgo / FADE_THRESHOLD, 1);
};
