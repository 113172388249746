import StyledText from './StyledText';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

const ChallengeNotSharedText = () => (
  <StyledText>
    <FormattedMessageWithChallengeNaming id="portfolio.task.assignment.challenge_not_shared" />
  </StyledText>
);

export default ChallengeNotSharedText;
