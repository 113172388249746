import { useContext, useState } from 'react';
import FieldContext from '../../FieldContext';
import FieldName from '../utils/FieldName';
import LogEntry from './LogEntry';
import AddLogEntry from './AddLogEntry';
import ViewOlderLogEntriesButton from './ViewOlderLogEntriesButton';
import FieldRendererContext from '../../../FieldRendererContext';

const LogEntries = () => {
  const { portfolioItem } = useContext(FieldRendererContext);
  const field = useContext(FieldContext);
  const logEntryName = field.additionalConfig.logEntryName ?? 'item';

  const [blocksShown, setBlocksShown] = useState(1);

  const BLOCK_SIZE = 5;
  const allLogEntries = Object.values(portfolioItem.logEntries).filter(
    logEntry => logEntry.fieldId === field.id,
  );
  const shownLogEntries = allLogEntries.slice(
    Math.max(allLogEntries.length - BLOCK_SIZE * blocksShown, 0),
    allLogEntries.length,
  );

  return (
    <section>
      <FieldName />
      {shownLogEntries.length !== allLogEntries.length && (
        <ViewOlderLogEntriesButton
          onClick={() => setBlocksShown(blocksShown + 1)}
        />
      )}
      {shownLogEntries.map((logEntry, index) => (
        <LogEntry
          logEntry={logEntry}
          isLast={shownLogEntries.length - 1 === index}
          key={logEntry.id}
        />
      ))}
      <AddLogEntry logEntryName={logEntryName} />
    </section>
  );
};

export default LogEntries;
