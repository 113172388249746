import { useContext, useState } from 'react';
import DangerousIconButton from '@/components/DangerousIconButton';
import Dialog from '@/components/Dialog';
import { Close as DeleteIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import AdditionalConfigStateContext from '../../../AdditionalConfigStateContext';

type DeleteOptionProps = {
  // TODO key should be separate optionKey prop because it does not actually
  // occur on the option object in the database
  option: {
    key: string;
  };
};

const DeleteOption = ({ option }: DeleteOptionProps) => {
  const [, setAdditionalConfig] = useContext(AdditionalConfigStateContext);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deleteOption = () => {
    setConfirmationDialogOpen(false);

    setAdditionalConfig(additionalConfig => {
      delete additionalConfig.options![option.key];
    });
  };

  return (
    <>
      <DangerousIconButton onClick={() => setConfirmationDialogOpen(true)}>
        <DeleteIcon />
      </DangerousIconButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.field_editor.select_editor.opion.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="editor.model_editor.field_editor.select_editor.opion.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteOption} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeleteOption;
