import { useState, useContext } from 'react';
import SelectedSchoolContext from '@/components/App/AppSetup/SelectedSchool/SelectedSchoolContext';
import SelectedSchoolLocalStorage from '../../AppSetup/SelectedSchool/SelectedSchoolLocalStorage';
import { SchoolSummary } from '@/lib/types';

const useSelectedSchool = (): [
  SchoolSummary | null,
  (school: SchoolSummary | null) => void,
] => {
  const [, refreshSelectedSchoolState] = useContext(SelectedSchoolContext);

  const [selectedSchool, setSelectedSchool] = useState<SchoolSummary | null>(
    null,
  );

  const updateSelectedSchool = (school: SchoolSummary | null): void => {
    setSelectedSchool(school);

    if (school !== null) {
      SelectedSchoolLocalStorage.setSelectedSchool(school.reference);
    } else {
      SelectedSchoolLocalStorage.removeSelectedSchool();
    }

    refreshSelectedSchoolState();
  };

  return [selectedSchool, updateSelectedSchool];
};

export default useSelectedSchool;
