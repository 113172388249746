import { useContext, useState } from 'react';
import DangerousIconButton from '@/components/DangerousIconButton';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import UsersContext from '../UsersContext';
import FormattedHtmlMessage from '@/components/FormattedHtmlMessage';

type DeleteUsersProps = {
  selectedUsersIds: number[];
  onSelectedUsersClear: () => void;
};

const DeleteUsers = ({
  selectedUsersIds,
  onSelectedUsersClear,
}: DeleteUsersProps) => {
  const api = useApi();

  const [, , setUsers] = useContext(UsersContext);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteUsers = async (): Promise<void> => {
    setConfirmationDialogOpen(false);

    setDeleting(true);

    await api.delete('users/bulk', {
      userIds: selectedUsersIds,
    });

    setUsers(users => {
      for (const user of users) {
        const userIndex = users.findIndex(element => element.id === user.id);

        if (selectedUsersIds.includes(user.id)) {
          users.splice(userIndex, 1);
        }
      }
    });

    setDeleting(false);

    onSelectedUsersClear();
  };

  return (
    <>
      <DangerousIconButton
        size="large"
        onClick={() => setConfirmationDialogOpen(true)}
        disabled={deleting}
      >
        <DeleteIcon />
      </DangerousIconButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="user_manager.user_list.toolbar.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedHtmlMessage id="user_manager.user_list.toolbar.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteUsers} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeleteUsers;
