import { useIntl } from 'react-intl';

const useFormattedCreatedAt = (createdAt: string): string => {
  const intl = useIntl();

  return intl.formatDate(new Date(createdAt), {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export default useFormattedCreatedAt;
