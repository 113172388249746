import styled from '@emotion/styled';
import { calculateFillPercentage, calculateFade } from './fillHelpers';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

type PerspectiveColorFillProps = {
  className?: string;
  color: string;
  fillLevel: number;
  fillFade: number;
};

type StyledFillProps = {
  color: string;
  fillLevel: number;
  fillFade: number;
};

const StyledFill = styled('footer')<StyledFillProps>(
  ({ theme, color, fillLevel, fillFade }) => ({
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: `${calculateFillPercentage(fillLevel)}%`,
    minHeight: `${calculateFillPercentage(fillLevel)}%`,
    maxHeight: `${calculateFillPercentage(fillLevel)}%`,
    boxSizing: 'border-box',
    backgroundColor: color,
    transition: theme.transitions.create(['all']),
    opacity: 1 - calculateFade(fillFade),
    zIndex: 0,
  }),
  addHighContrastStyles(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
  })),
);

const PerspectiveColorFill = ({
  className,
  ...props
}: PerspectiveColorFillProps) => (
  <StyledFill
    className={`${className} perspective-card__color-fill`}
    {...props}
  />
);

export default PerspectiveColorFill;
