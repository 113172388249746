import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import LoadingSpinnerWithText from '@/components/LoadingSpinnerWithText';

const ChallengeContentLoadingSpinner = () => (
  <LoadingSpinnerWithText>
    <FormattedMessageWithChallengeNaming id="portfolio.challenge.loading" />
  </LoadingSpinnerWithText>
);

export default ChallengeContentLoadingSpinner;
