import styled from '@emotion/styled';
import AddItemButton from '../AddItemButton';
import firstChildSelector from '@/lib/styling/firstChildSelector';

const AddSubtopicButton = styled(AddItemButton)({
  marginTop: 22,
  [firstChildSelector]: {
    marginTop: -8,
  },
});

export default AddSubtopicButton;
