import dutchFlag from './flag-icons/dutch-flag.svg';
import britishFlag from './flag-icons/british-flag.svg';
import frenchFlag from './flag-icons/french-flag.svg';
import { SupportedLanguage } from '@/lib/utils/getLanguage';

const getFlagIconForLanguage = (language: SupportedLanguage): string => {
  switch (language) {
    case 'en': {
      return britishFlag;
    }
    case 'fr': {
      return frenchFlag;
    }
    default: {
      return dutchFlag;
    }
  }
};

export default getFlagIconForLanguage;
