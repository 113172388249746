import NotFoundTitle from '@/components/NotFoundTitle';
import Space from '@/components/Space';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import PublicPortfolioItemPageTitle from './PublicPortfolioItemPageTitle';

type NotFoundViewProps = {
  forTask?: boolean;
};

const NotFoundView = ({ forTask }: NotFoundViewProps) => (
  <>
    <PublicPortfolioItemPageTitle
      translationId={`open_challenge.not_found.page_title.${
        forTask ? 'task' : 'challenge'
      }`}
    />
    <NotFoundTitle>
      <FormattedMessageWithChallengeNaming
        id={`open_challenge.not_found.title.${forTask ? 'task' : 'challenge'}`}
      />
      <br />
      <Space height={24} />
      <Button variant="contained" disableElevation component={Link} to="/">
        <FormattedMessage id="open_challenge.not_found.home_button" />
      </Button>
    </NotFoundTitle>
  </>
);

export default NotFoundView;
