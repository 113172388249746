import { useContext } from 'react';
import SelectedPlatformContext from '@/lib/contexts/SelectedPlatformContext';
import PageTitle from '@/components/PageTitle';
import Space from '@/components/Space';
import PlatformType from '@/lib/constants/PlatformType.enum';
import ChallengeModelEditor from './ChallengeModelEditor';
import RubricsPortfolioTemplatesEditor from './RubricsPortfolioTemplatesEditor';

const EditorPage = () => {
  const [selectedPlatform] = useContext(SelectedPlatformContext);

  return (
    <>
      <PageTitle id="editor.page_title" />
      <Space height={24} />
      {selectedPlatform === PlatformType.CHALLENGEMONITOR ? (
        <ChallengeModelEditor />
      ) : (
        <RubricsPortfolioTemplatesEditor />
      )}
    </>
  );
};

export default EditorPage;
