import { useState, useContext } from 'react';
import VisiblePortfolioTemplatesGroupContext from '../../VisiblePortfolioTemplatesGroupContext';
import UseAggressiveRubricCollapsingContext from '../UseAggressiveRubricCollapsingContext';
import EditorPart from '../EditorPart';
import AddPart from '../AddPart';
import itemMatchesVisibleGroup from '@/lib/rubricsPortfolioUtils/itemMatchesVisibleGroup';
import StyledSubtopic from '@/components/RubricsPortfolioComponents/StyledSubtopic';
import SubtopicNameWrapper from '@/components/RubricsPortfolioComponents/StyledSubtopic/SubtopicNameWrapper';
import SubtopicName from '@/components/RubricsPortfolioComponents/StyledSubtopic/SubtopicName';
import CollapseButton from '@/components/RubricsPortfolioComponents/CollapseButton';
import SubtopicPartWrapper from '@/components/RubricsPortfolioComponents/StyledSubtopic/SubtopicPartWrapper';
import EditSubtopic from './EditSubtopic';
import DeleteSubtopic from './DeleteSubtopic';
import useMakeDraggable from '../useMakeDraggable';
import getHighestPositionFromSortedItems from '@/pages/EditorPage/getHighestPositionFromSortedItems';
import useApi from '@/lib/api/useApi';
import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import UserRole from '@/lib/constants/UserRole.enum';
import { EditorSubtopic as EditorSubtopicType } from '@/lib/types';
import EditorPortfolioTemplatesContext from '../../EditorPortfolioTemplatesContext';
import PortfolioDragType from '../PortfolioDragType.enum';

type EditorSubtopicProps = {
  subtopic: EditorSubtopicType;
  previousSubtopic: EditorSubtopicType | null;
  nextSubtopic: EditorSubtopicType | null;
  highestPosition: number;
  topicPosition: number;
};

const EditorSubtopic = ({
  subtopic,
  previousSubtopic,
  nextSubtopic,
  highestPosition: highestSubtopicPosition,
  topicPosition,
}: EditorSubtopicProps) => {
  const api = useApi();

  const [portfolioTemplates, setPortfolioTemplates] = useContext(
    EditorPortfolioTemplatesContext,
  );

  const [isDragging, dragRef] = useMakeDraggable<HTMLDivElement>(
    PortfolioDragType.SUBTOPIC,
    subtopic,
    previousSubtopic,
    nextSubtopic,
    highestSubtopicPosition,
    (subtopicId: number, position: number): void => {
      setPortfolioTemplates(portfolioTemplates => {
        portfolioTemplates.subtopics[subtopicId].position = position;
        // TODO EITHER remove position field from topic.subtopics array, OR
        // handle it here
      });

      api.put(`editor/portfolio-templates/subtopics/${subtopicId}/position`, {
        position,
      });
    },
    subtopic.topicId,
    topicPosition,
    (oldTopicId: number, newTopicId: number): void => {
      setPortfolioTemplates(portfolioTemplates => {
        portfolioTemplates.subtopics[subtopic.id].topicId = newTopicId;

        const oldTopic = portfolioTemplates.topics[oldTopicId];

        oldTopic.subtopics.splice(
          oldTopic.subtopics.findIndex(({ id }) => id === subtopic.id),
          1,
        );

        portfolioTemplates.topics[newTopicId].subtopics.push({
          id: subtopic.id,
          position: subtopic.position,
        });
      });

      // TODO does this endpoint url really make sense?
      api.post(
        `editor/portfolio-templates/subtopics/${subtopic.id}/position/set-topic`,
        {
          topicId: newTopicId,
        },
      );
    },
  );

  const [collapsed, setCollapsed] = useState(true);

  // TODO this stuff needs to be refactored

  const allParts = portfolioTemplates.parts;
  const desiredPartsIds = subtopic.parts.map(({ id }) => id);
  const parts = desiredPartsIds
    .map(partId => allParts[partId])
    .filter(part => !!part)
    .sort(sortByPosition);

  const visibleGroup = useContext(VisiblePortfolioTemplatesGroupContext);
  const visibleParts = parts.filter(itemMatchesVisibleGroup(visibleGroup));

  const highestPartPosition = getHighestPositionFromSortedItems(parts);

  const editorOnly = subtopic.minimumRole === UserRole.EDITOR;

  const aggressiveRubricCollapsingEnabled = useContext(
    UseAggressiveRubricCollapsingContext,
  );

  return (
    <StyledSubtopic editor dragging={isDragging} ref={dragRef}>
      <SubtopicNameWrapper editorOnly={editorOnly}>
        <SubtopicName>{subtopic.name}</SubtopicName>
        <EditSubtopic subtopic={subtopic} />
        <DeleteSubtopic subtopic={subtopic} />
        {aggressiveRubricCollapsingEnabled && (
          <CollapseButton
            collapsed={collapsed}
            onClick={() => setCollapsed(!collapsed)}
          />
        )}
      </SubtopicNameWrapper>
      {(!aggressiveRubricCollapsingEnabled || !collapsed) && (
        <SubtopicPartWrapper>
          {visibleParts.map((part, i) => (
            <EditorPart
              part={part}
              previousPart={visibleParts[i - 1] ?? null}
              nextPart={visibleParts[i + 1] ?? null}
              highestPosition={highestPartPosition}
              subtopicPosition={subtopic.position}
              key={part.id}
            />
          ))}
          <AddPart
            subtopicId={subtopic.id}
            subtopicMinimumRole={subtopic.minimumRole}
            highestPartPosition={highestPartPosition}
          />
        </SubtopicPartWrapper>
      )}
    </StyledSubtopic>
  );
};

export default EditorSubtopic;
