import { IconButtonProps, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import HeaderActionIconButton from '../../HeaderActionIconButton';
import PlatformIcon from './PlatformIcon';
import PlatformType from '@/lib/constants/PlatformType.enum';

type PlatformSwitchButtonProps = IconButtonProps & {
  targetPlatformType: PlatformType;
};

const PlatformSwitchButton = ({
  targetPlatformType,
  ...props
}: PlatformSwitchButtonProps) => (
  <Tooltip
    title={
      <FormattedMessage
        id={`header.platform_switch.button.tooltip.${targetPlatformType}`}
      />
    }
  >
    <HeaderActionIconButton {...props}>
      <PlatformIcon platformType={targetPlatformType} />
    </HeaderActionIconButton>
  </Tooltip>
);

export default PlatformSwitchButton;
