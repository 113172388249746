import styled from '@emotion/styled';
import { TextareaAutosize } from '@mui/material';

const StyledTextareaWithAutosizing = styled(TextareaAutosize)({
  margin: 0,
  padding: 0,
  width: '100%',
  lineHeight: 1.6,
  color: 'inherit',
  backgroundColor: 'transparent',
  font: 'inherit',
  border: 'none',
  outlineWidth: 0,
  resize: 'none',
  overflowX: 'hidden',
});

export default StyledTextareaWithAutosizing;
