import React, { useState } from 'react';
import LinkSharingDialog from './LinkSharingDialog';
import LinkButton from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/ChallengeLinkSharing/LinkButton';
import selectLinkInputContent from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/ChallengeLinkSharing/selectLinkInputContent';

type TaskLinkSharingProps = {
  ButtonComponent?: React.ElementType;
};

const TaskLinkSharing = ({
  ButtonComponent: ButtonComponentOrUndefined,
}: TaskLinkSharingProps) => {
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);

  const ButtonComponent = ButtonComponentOrUndefined ?? LinkButton;

  return (
    <>
      <ButtonComponent
        onClick={() => {
          selectLinkInputContent();
          setLinkDialogOpen(true);
        }}
      />
      <LinkSharingDialog
        open={linkDialogOpen}
        onClose={() => setLinkDialogOpen(false)}
      />
    </>
  );
};

export default TaskLinkSharing;
