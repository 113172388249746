import ShowPerspectiveCategorizationViewContext from '@/lib/contexts/ShowPerspectiveCategorizationViewContext';
import usePerspectivesEnabled from '@/lib/utils/usePerspectivesEnabled';
import { Tooltip } from '@mui/material';
import { useContext } from 'react';
import HeaderActionIconButton from './HeaderActionIconButton';
import SelectedPlatformContext from '@/lib/contexts/SelectedPlatformContext';
import PlatformSwitchButton from './PlatformSwitch/PlatformSwitchButton';
import usePortfolioUser from './EnsureChallengePortfolioControlsDesired/usePortfolioUser';
import { FormattedMessage } from 'react-intl';
import PerspectivesIcon from '@/components/PerspectivesIcon';

const PerspectiveCategorizationViewButton = () => {
  const perspectivesEnabled = usePerspectivesEnabled();

  const portfolioUser = usePortfolioUser();
  const isDisplayingPortfolio = !!portfolioUser;

  const portfolioUserHasPerspectives = !!portfolioUser?.perspectiveCohortId;

  const [
    showPerspectiveCategorizationView,
    setShowPerspectiveCategorizationView,
  ] = useContext(ShowPerspectiveCategorizationViewContext);

  const [selectedPlatform] = useContext(SelectedPlatformContext);

  if (
    !perspectivesEnabled ||
    !isDisplayingPortfolio ||
    !portfolioUserHasPerspectives
  ) {
    return null;
  }

  if (showPerspectiveCategorizationView) {
    return (
      <PlatformSwitchButton
        targetPlatformType={selectedPlatform}
        onClick={() => setShowPerspectiveCategorizationView(false)}
      />
    );
  }

  return (
    <Tooltip
      title={<FormattedMessage id="perspectives.categorization.open_button" />}
      arrow
    >
      <HeaderActionIconButton
        onClick={() => setShowPerspectiveCategorizationView(true)}
      >
        <PerspectivesIcon />
      </HeaderActionIconButton>
    </Tooltip>
  );
};

export default PerspectiveCategorizationViewButton;
