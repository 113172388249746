import { FormattedMessage } from 'react-intl';
import Space from '@/components/Space';

const LoginWelcomeTitle = () => (
  <>
    <h1>
      <FormattedMessage id="login.welcome_text" />
    </h1>
    <Space height={24} />
  </>
);

export default LoginWelcomeTitle;
