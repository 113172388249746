import { ForwardedRef, forwardRef } from 'react';
import DangerousIconButton from '@/components/DangerousIconButton';
import { IconButton, IconButtonProps } from '@mui/material';

export type PossiblyDangerousIconButtonProps = IconButtonProps & {
  dangerous?: boolean;
};

const PossiblyDangerousIconButton = forwardRef(
  (
    { dangerous, ...props }: PossiblyDangerousIconButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const IconButtonComponent = dangerous ? DangerousIconButton : IconButton;
    return <IconButtonComponent {...props} ref={ref} />;
  },
);

PossiblyDangerousIconButton.displayName = 'PossiblyDangerousIconButton';

export default PossiblyDangerousIconButton;
