import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import isPropValid from '@emotion/is-prop-valid';
import mediaQuery from '@/lib/styling/mediaQuery';
import { common } from '@mui/material/colors';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';
import { alpha } from '@mui/material';

type GapminderCardProps = {
  fixed: boolean;
};

const GapminderCard = styled(Paper, {
  shouldForwardProp: isPropValid,
})<GapminderCardProps>(
  ({ theme }) => ({
    padding: '14px 48px',
    boxSizing: 'border-box',
    transition: theme.transitions.create('all'),
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    [mediaQuery(768)]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  }),
  ({ fixed }) => {
    if (fixed) {
      return {
        position: 'fixed',
        top: 0,
        left: 148,
        marginTop: 24,
        width: 'calc(100% - 296px)',
        boxShadow: `0 6px 54px ${alpha(common.black, 0.34)}`,
        zIndex: 3,
        [mediaQuery(1024)]: {
          left: 72,
          width: 'calc(100% - 144px)',
        },
        [mediaQuery(850)]: {
          left: 24,
          width: 'calc(100% - 48px)',
        },
        [mediaQuery(480)]: {
          marginTop: 0,
          left: 0,
          width: '100%',
          borderRadius: 0,
        },
      };
    }
  },
  addBorderInHighContrastMode,
);

export default GapminderCard;
