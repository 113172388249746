import { buildWidthString } from '@/components/RubricsPortfolioComponents/StyledLevel';
import mediaQuery from '@/lib/styling/mediaQuery';

// TODO better return type (figure out emotion typings)
const getResponsiveStyles = (levelCount: number) => ({
  [mediaQuery(900)]: {
    ...getResponsiveWidthStyleObject(3, levelCount),
    minWidth: levelCount === 0 ? '100%' : null,
  },
  [mediaQuery(768)]: getResponsiveWidthStyleObject(2, levelCount),
  [mediaQuery(500)]: getResponsiveWidthStyleObject(1, levelCount),
});

export const getResponsiveWidthStyleObject = (
  levelsVisibleWithoutScrolling: number,
  levelCount: number,
) => {
  const addedWidthForMarginCompensation = (levelCount - 1) * 25;

  return {
    width: `calc((${buildWidthString(
      levelsVisibleWithoutScrolling,
    )} * ${levelCount}) + ${addedWidthForMarginCompensation}px)`,
  };
};

export default getResponsiveStyles;
