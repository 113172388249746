import { ForwardedRef, forwardRef } from 'react';
import CollapsibleActionMenu from '@/components/CollapsibleActionMenu';
import { ListItemIcon } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { ActionMenuButtonProps } from '@/components/CollapsibleActionMenu/ActionMenuButton';

type EditFieldNameButtonProps = Omit<ActionMenuButtonProps, 'children'>;

const EditFieldNameButton = forwardRef(
  (props: EditFieldNameButtonProps, ref: ForwardedRef<HTMLLIElement>) => (
    <CollapsibleActionMenu.Button {...props} ref={ref}>
      <ListItemIcon>
        <EditIcon />
      </ListItemIcon>
      <FormattedMessage id="editor.model_editor.actions.edit_name" />
    </CollapsibleActionMenu.Button>
  ),
);

EditFieldNameButton.displayName = 'EditFieldNameButton';

export default EditFieldNameButton;
