import { useRef, useEffect } from 'react';
import Prompt from '@/components/Prompt';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import { PromptTextFieldProps } from '@/components/Prompt/PromptTextField';

type ChallengeNameFieldProps = Omit<
  PromptTextFieldProps,
  'label' | 'error' | 'helperText' | 'inputRef'
> & {
  hasError: boolean;
};

const ChallengeNameField = ({
  hasError,
  ...props
}: ChallengeNameFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (hasError && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [hasError]);

  return (
    <Prompt.TextField
      label={
        <FormattedMessageWithChallengeNaming id="portfolio.challenge_creation_flow.challenge_name_field.label" />
      }
      error={hasError}
      helperText={
        hasError && (
          <FormattedMessageWithChallengeNaming id="portfolio.challenge_creation_flow.challenge_name_field.errors.empty" />
        )
      }
      inputRef={inputRef}
      {...props}
    />
  );
};

export default ChallengeNameField;
