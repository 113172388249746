import { motion } from 'framer-motion';
import React from 'react';

type GapminderAnimationWrapperProps = {
  children: React.ReactNode;
};

const GapminderAnimationWrapper = ({
  children,
}: GapminderAnimationWrapperProps) => (
  <motion.div initial={{ y: 8 }} animate={{ y: 0 }}>
    {children}
  </motion.div>
);

export default GapminderAnimationWrapper;
