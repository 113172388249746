import FieldContext from './FieldContext';
import FieldWrapper from './FieldWrapper';
import FieldHeader from './FieldHeader';
import FieldTypeSelect from './FieldTypeSelect';
import getTypeSpecificEditor from './getTypeSpecificEditor';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import AdditionalConfigStateContext from './AdditionalConfigStateContext';
import { EditorField } from '@/lib/types';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

type ModelFieldEditorProps = {
  field: EditorField;
  previousField: EditorField | null;
  nextField: EditorField | null;
  allHighestPositions: Record<ContentGroupType, number>;
  lowestFieldPositionInPhaseBottomContent: number;
};

const ModelFieldEditor = ({
  field,
  previousField,
  nextField,
  allHighestPositions,
  lowestFieldPositionInPhaseBottomContent,
}: ModelFieldEditorProps) => {
  const [
    additionalConfig,
    setAdditionalConfig,
    additionalConfigHasChanged,
    reenableAdditionalConfigSync,
  ] = useComplexSwrState(field.additionalConfig);

  return (
    <FieldContext.Provider value={{ field, previousField, nextField }}>
      <AdditionalConfigStateContext.Provider
        value={[
          additionalConfig,
          setAdditionalConfig,
          additionalConfigHasChanged,
          reenableAdditionalConfigSync,
        ]}
      >
        <FieldWrapper>
          <FieldHeader
            allHighestPositions={allHighestPositions}
            lowestFieldPositionInPhaseBottomContent={
              lowestFieldPositionInPhaseBottomContent
            }
          />
          <FieldTypeSelect />
          {getTypeSpecificEditor(field.type)}
        </FieldWrapper>
      </AdditionalConfigStateContext.Provider>
    </FieldContext.Provider>
  );
};

export default ModelFieldEditor;
