import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

type PageTitleProps = {
  id: string;
};

const PageTitle = ({ id: pageTitleTranslationId }: PageTitleProps) => {
  const intl = useIntl();

  return <Helmet title={intl.formatMessage({ id: pageTitleTranslationId })} />;
};

export default PageTitle;
