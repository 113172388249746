import { motion } from 'framer-motion';
import React from 'react';

type ColorLegendAnimationWrapperProps = {
  children: React.ReactNode;
};

const ColorLegendAnimationWrapper = ({
  children,
}: ColorLegendAnimationWrapperProps) => (
  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
    {children}
  </motion.div>
);

export default ColorLegendAnimationWrapper;
