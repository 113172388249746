import styled from '@emotion/styled';
import { WHITE } from '../defaultColors';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

type ColorBoxProps = {
  color: string;
  selected?: boolean;
};

const ColorBox = styled('div')<ColorBoxProps>(
  ({ theme }) => ({
    margin: 3,
    width: 24,
    height: 24,
    borderRadius: 4,
    boxSizing: 'border-box',
    display: 'inline-block',
    cursor: 'pointer',
    transition: theme.transitions.create('all'),
    ':hover': {
      transform: 'scale(1.1)',
    },
  }),
  ({ color }) => ({
    backgroundColor: color,
  }),
  ({ theme, color }) => {
    if (!theme.isDark && color === WHITE) {
      return {
        border: `1px solid ${theme.palette.divider}`,
      };
    }
  },
  ({ theme, color, selected }) => {
    if (theme.isDark && selected && color === WHITE) {
      return {
        border: `1px solid ${theme.palette.action.selected}`,
      };
    }

    if (selected) {
      return {
        border: `1px solid ${theme.palette.action.active}`,
      };
    }
  },
  addHighContrastStyles(({ theme }) => ({
    border: `2px solid ${theme.palette.divider}`,
  })),
);

export default ColorBox;
