import { useIntl } from 'react-intl';
import { TextField, TextFieldProps } from '@mui/material';

type AllowedEmailTextFieldProps = Omit<
  TextFieldProps,
  'variant' | 'placeholder' | 'size' | 'fullWidth' | 'style'
> & {
  asPrefixField?: boolean;
};

const AllowedEmailTextField = ({
  asPrefixField,
  ...props
}: AllowedEmailTextFieldProps) => {
  const intl = useIntl();

  return (
    <TextField
      variant="outlined"
      placeholder={intl.formatMessage({
        id: `platform_settings.login_settings.${
          asPrefixField ? 'email_prefix' : 'allowed_email'
        }`,
      })}
      size="small"
      fullWidth={!asPrefixField}
      style={{ maxWidth: asPrefixField ? 80 : undefined }}
      {...props}
    />
  );
};

export default AllowedEmailTextField;
