import device from 'current-device';
import MobileDatePicker from './MobileDatePicker';
import DesktopDatePicker from './DesktopDatePicker';
import moment, { Moment } from 'moment';

export type DatePickerProps = {
  value: Moment;
  onChange: (value: Moment) => void;
  onSave: (value: Moment) => void;
  shouldDisableDate?: (date: Moment) => boolean;
  clearable?: boolean;
  disabled?: boolean;
};

const EMPTY_MOMENT_VALUE = moment(null);

const DatePicker = (props: DatePickerProps) => {
  const isMobile = !device.desktop();

  const handleClear = (): void => {
    if (!props.clearable) {
      return;
    }

    props.onChange(EMPTY_MOMENT_VALUE);
    props.onSave(EMPTY_MOMENT_VALUE);
  };

  if (isMobile) {
    return <MobileDatePicker onClear={handleClear} {...props} />;
  }

  return <DesktopDatePicker onClear={handleClear} {...props} />;
};

export default DatePicker;
