import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import StyledOpenButton from './StyledOpenButton';
import LoadingIndicator from './LoadingIndicator';

type OpenButtonProps = {
  fillLevel: number;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void;
};

const OpenButton = ({ fillLevel, onClick }: OpenButtonProps) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setLoading(true);

    await onClick(e);

    setLoading(false);
  };

  return (
    <StyledOpenButton
      className="perspective-card__open-button"
      fillLevel={fillLevel}
      onClick={handleClick}
      disabled={loading}
    >
      {loading ? <LoadingIndicator /> : <ChevronRightIcon />}
    </StyledOpenButton>
  );
};

export default OpenButton;
