import HideOnLowWidth from '@/components/HideOnLowWidth';
import { Tooltip, IconButton, IconButtonProps } from '@mui/material';
import { FileCopyOutlined as CopyIcon } from '@mui/icons-material';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

type CopyButtonProps = Omit<IconButtonProps, 'size' | 'children'>;

const CopyButton = (props: CopyButtonProps) => (
  <HideOnLowWidth width={640}>
    <Tooltip
      title={
        <FormattedMessageWithChallengeNaming id="portfolio.challenge.copy.button_tooltip" />
      }
    >
      <IconButton size="large" {...props}>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  </HideOnLowWidth>
);

export default CopyButton;
