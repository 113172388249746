import { Button, ButtonProps } from '@mui/material';
import { ArrowBack as BackIcon } from '@mui/icons-material';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

type ClosePerspectiveButtonProps = Omit<
  ButtonProps,
  'startIcon' | 'color' | 'children'
>;

const StyledButton = styled(Button)({
  position: 'relative',
  top: 36.5,
  marginTop: -36.5,
  marginLeft: 8,
  marginRight: 8,
});

const ClosePerspectiveButton = (props: ClosePerspectiveButtonProps) => (
  <StyledButton startIcon={<BackIcon />} color="secondary" {...props}>
    <FormattedMessage id="perspectives.sublevel_tree.back_button" />
  </StyledButton>
);

export default ClosePerspectiveButton;
