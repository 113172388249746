import { Autocomplete, TextField } from '@mui/material';
import AutocompleteOptionsWrapper from './AutocompleteOptionsWrapper';
import AutocompleteOptionsListbox from './AutocompleteOptionsListBox';
import { useIntl } from 'react-intl';
import { SchoolSummary, HTMLDivProps } from '@/lib/types';

type SchoolAutocompleteProps = {
  schools: SchoolSummary[];
  value: SchoolSummary | null;
  onChange: (school: SchoolSummary) => void;
};

const Div = (props: HTMLDivProps) => <div {...props} />;

const sortSchools = (a: SchoolSummary, b: SchoolSummary): number => {
  return a.displayName.localeCompare(b.displayName);
};

const SchoolAutocomplete = ({
  schools,
  value,
  onChange,
}: SchoolAutocompleteProps) => {
  const intl = useIntl();

  return (
    <Autocomplete<SchoolSummary, false, true>
      id="school-autocomplete"
      value={value ?? undefined}
      isOptionEqualToValue={(option, value) =>
        option.reference === value.reference
      }
      options={schools.sort(sortSchools)}
      getOptionLabel={school => school.displayName}
      onChange={(_, newValue: SchoolSummary) => onChange(newValue)}
      renderInput={params => (
        <TextField
          {...params}
          variant="filled"
          label={intl.formatMessage({
            id: 'login.school_selector.autocomplete.label',
          })}
        />
      )}
      noOptionsText={intl.formatMessage({
        id: 'login.school_selector.autocomplete.options.none',
      })}
      PopperComponent={AutocompleteOptionsWrapper}
      PaperComponent={Div}
      ListboxComponent={AutocompleteOptionsListbox}
      disableClearable
      fullWidth
    />
  );
};

export default SchoolAutocomplete;
