import FeedbackListWrapper from './FeedbackListWrapper';
import FeedbackListTitle from './FeedbackListTitle';
import { FormattedMessage } from 'react-intl';
import FeedbackItem from './FeedbackItem';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import AddFeedback from '../AddFeedback';
import { CoachComment, HTMLDivProps } from '@/lib/types';

type FeedbackListProps = HTMLDivProps & {
  feedback: CoachComment[];
  mobileVariant?: boolean;
};

const FeedbackList = ({
  feedback,
  mobileVariant,
  ...props
}: FeedbackListProps) => (
  <FeedbackListWrapper mobileVariant={mobileVariant ?? false} {...props}>
    <FeedbackListTitle mobileVariant={mobileVariant ?? false}>
      <FormattedMessage id="portfolio.challenge.coach_feedback.title" />
    </FeedbackListTitle>
    {feedback.map(feedbackItem => (
      <FeedbackItem feedbackItem={feedbackItem} key={feedbackItem.id} />
    ))}
    <RestrictedToPortfolioMode mode={PortfolioMode.COACH}>
      <AddFeedback belowFeedbackList />
    </RestrictedToPortfolioMode>
  </FeedbackListWrapper>
);

export default FeedbackList;
