import ResponsiveMasonry from '@/components/ChallengePortfolio/NormalView/ChallengeBoard/KanbanPhaseColumn/ResponsiveMasonry';
import React from 'react';

type TaskWrapperProps = {
  children: NonNullable<React.ReactNode>;
};

const TaskWrapper = ({ children }: TaskWrapperProps) => (
  <ResponsiveMasonry widthDenominator={210} maxColumns={4}>
    {children}
  </ResponsiveMasonry>
);

export default TaskWrapper;
