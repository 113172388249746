import { useContext } from 'react';
import App from '@/lib/App';
import PortfolioTemplatesContext from '@/lib/contexts/PortfolioTemplatesContext';
import CollapsedPortfolioItemsContext from './CollapsedPortfolioItemsContext';
import PortfolioWrapper from './PortfolioWrapper';
import CollapseAllButton from './CollapseAllButton';
import Topic from './Topic';
import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import useUserCanViewItem from '@/lib/rubricsPortfolioUtils/useUserCanViewItem';

const Portfolio = () => {
  const aggressiveRubricCollapsingEnabled = App.instanceConfig.useSetting(
    'enableAggressiveRubricCollapsing',
  );

  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const userCanViewItem = useUserCanViewItem();

  const topics = Object.values(portfolioTemplates)
    .filter(userCanViewItem)
    .sort(sortByPosition);

  return (
    <CollapsedPortfolioItemsContext.AutomatedProvider>
      <PortfolioWrapper>
        {aggressiveRubricCollapsingEnabled && topics.length > 0 && (
          <CollapseAllButton />
        )}
        {topics.map((topic, index) => (
          <Topic topic={topic} isFirstTopic={index === 0} key={topic.id} />
        ))}
      </PortfolioWrapper>
    </CollapsedPortfolioItemsContext.AutomatedProvider>
  );
};

export default Portfolio;
