import { SWRConfiguration } from 'swr';
import { SetComplexImmerStateAction } from '../types';
import useSwrAsComplexState from './useSwrAsComplexState';

const DEFAULT_POLL_INTERVAL = 10_000; // 10 seconds

const usePolledSwrAsComplexState = <T>(
  url: string | null,
  pollInterval: number = DEFAULT_POLL_INTERVAL,
  swrConfig: Omit<SWRConfiguration, 'refreshInterval'> = {},
): [true, null, () => void] | [false, T, SetComplexImmerStateAction<T>] => {
  return useSwrAsComplexState<T>(url, {
    ...swrConfig,
    refreshInterval: pollInterval,
  });
};

export default usePolledSwrAsComplexState;
