import { useContext } from 'react';
import VisibleWeekRangeContext from '../../../VisibleWeekRangeContext';
import LinesWrapper from './WeekMarkingBackgroundLines/LinesWrapper';
import BackgroundLineWithSpacing from './WeekMarkingBackgroundLines/BackgroundLineWithSpacing';

const weekDays = [1, 2, 3, 4, 5, 6, 7];

const DayMarkingBackgroundLines = () => {
  const { visibleWeekRangeStartDate } = useContext(VisibleWeekRangeContext);

  return (
    <LinesWrapper key={visibleWeekRangeStartDate.toString()}>
      {weekDays.map(weekday => (
        <BackgroundLineWithSpacing key={weekday} />
      ))}
    </LinesWrapper>
  );
};
export default DayMarkingBackgroundLines;
