import HigherCardConnector from './HigherCardConnector';
import StraightCardConnector from './StraightCardConnector';
import LowerCardConnector from './LowerCardConnector';
import CardConnectorsWrapper from './CardConnectorsWrapper';

type CardConnectorsProps = {
  cardCount: number;
  parentCardIndex: number;
  previousColumnCardCount: number;
  highlightedConnector: number | null;
};

const computeDistanceFromCenter = (
  cardCount: number,
  index: number,
): number => {
  const centerIndex =
    cardCount % 2 === 0 ? cardCount / 2 - 0.5 : Math.floor(cardCount / 2);

  return index - centerIndex;
};

const CardConnectors = ({
  cardCount,
  parentCardIndex,
  previousColumnCardCount,
  highlightedConnector,
}: CardConnectorsProps) => {
  const originDistanceFromCenter = computeDistanceFromCenter(
    previousColumnCardCount,
    parentCardIndex,
  );

  return (
    <CardConnectorsWrapper>
      {new Array(cardCount).fill(null).map((_, index) => {
        const targetDistanceFromCenter = computeDistanceFromCenter(
          cardCount,
          index,
        );

        if (originDistanceFromCenter > targetDistanceFromCenter) {
          return (
            <HigherCardConnector
              origin={originDistanceFromCenter}
              target={targetDistanceFromCenter}
              highlighted={
                highlightedConnector === null || highlightedConnector === index
              }
              key={index}
            />
          );
        }

        if (originDistanceFromCenter === targetDistanceFromCenter) {
          return (
            <StraightCardConnector
              target={targetDistanceFromCenter}
              highlighted={
                highlightedConnector === null || highlightedConnector === index
              }
              key={index}
            />
          );
        }

        return (
          <LowerCardConnector
            origin={originDistanceFromCenter}
            target={targetDistanceFromCenter}
            highlighted={
              highlightedConnector === null || highlightedConnector === index
            }
            key={index}
          />
        );
      })}
    </CardConnectorsWrapper>
  );
};

export default CardConnectors;
