import styled from '@emotion/styled';
import ButtonBase from '@/components/ButtonBase';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import { ButtonBaseProps } from '@mui/material';

type StyledAddButtonProps = Omit<ButtonBaseProps, 'disabled'> & {
  disabled: boolean;
};

const StyledAddButton = styled(ButtonBase)<StyledAddButtonProps>(
  ({ theme, disabled }) => ({
    marginTop: 4,
    marginBottom: 4,
    color: theme.palette.text.primary,
    fontStyle: 'italic',
    opacity: disabled ? 0.3 : 0.5,
    cursor: disabled ? 'not-allowed' : 'pointer',
    transition: theme.transitions.create('all'),
    ':not(:disabled):hover': {
      opacity: 0.8,
    },
  }),
  addHighContrastStyles({
    opacity: 1,
    ':not(:disabled):hover': {
      opacity: 1,
      textDecoration: 'underline',
    },
  }),
);

export default StyledAddButton;
