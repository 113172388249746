import { useContext } from 'react';
import MoveModeContext from './MoveModeContext';
import { Button, ButtonProps } from '@mui/material';
import { Close as CloseIcon, OpenWith as MoveIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

type MoveModeButtonProps = Omit<
  ButtonProps,
  'variant' | 'startIcon' | 'color' | 'children'
>;

const MoveModeButton = (props: MoveModeButtonProps) => {
  const inMoveMode = useContext(MoveModeContext);

  return (
    <Button
      variant="outlined"
      startIcon={inMoveMode ? <CloseIcon /> : <MoveIcon />}
      color="secondary"
      {...props}
    >
      <FormattedMessage
        id={
          inMoveMode
            ? 'editor.model_editor.move_mode_button.leave_variant'
            : 'editor.model_editor.move_mode_button'
        }
      />
    </Button>
  );
};

export default MoveModeButton;
