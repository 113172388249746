import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import PlatformLoadingView from '@/components/PlatformLoadingView';
import PlatformType from '@/lib/constants/PlatformType.enum';

const ChallengeLoadingView = () => (
  <PlatformLoadingView
    platform={PlatformType.CHALLENGEMONITOR}
    loadingMessage={
      <FormattedMessageWithChallengeNaming id="open_challenge.loading_message.challenge" />
    }
  />
);

export default ChallengeLoadingView;
