import { useContext, useState } from 'react';
import FieldContext from '../../../FieldContext';
import CollapsibleActionMenu from '@/components/CollapsibleActionMenu';
import { ListItemIcon } from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import InfoEditor from './InfoEditor';
import FieldType from '@/lib/constants/challengeModel/FieldType.enum';

const fieldTypesWithBuiltInInfo = [FieldType.MANUAL_AGORA_WORLDS];

const EditFieldInfo = () => {
  const { field } = useContext(FieldContext);
  const [editPromptOpen, setEditPromptOpen] = useState(false);

  return (
    <>
      <CollapsibleActionMenu.Button
        onClick={() => setEditPromptOpen(true)}
        disabled={
          !field!.name || fieldTypesWithBuiltInInfo.includes(field!.type)
        }
      >
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <FormattedMessage id="editor.model_editor.actions.explanation" />
      </CollapsibleActionMenu.Button>
      <InfoEditor
        open={editPromptOpen && !!field!.name}
        onClose={() => setEditPromptOpen(false)}
      />
    </>
  );
};

export default EditFieldInfo;
