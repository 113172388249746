import { useContext, useState } from 'react';
import HeaderWrapper from './HeaderWrapper';
import FieldNameEditor from './FieldNameEditor';
import FieldName from './FieldName';
import FieldPositionMoving from './FieldPositionMoving';
import FieldActions from './FieldActions';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import AdditionalConfigStateContext from '../AdditionalConfigStateContext';
import SaveAdditionalConfigChangesButton from './SaveAdditionalConfigChangesButton';

type FieldHeaderProps = {
  allHighestPositions: Record<ContentGroupType, number>;
  lowestFieldPositionInPhaseBottomContent: number;
};

const FieldHeader = ({
  allHighestPositions,
  lowestFieldPositionInPhaseBottomContent,
}: FieldHeaderProps) => {
  const [, , additionalConfigHasChanged] = useContext(
    AdditionalConfigStateContext,
  );
  const [nameEditing, setNameEditing] = useState(false);

  if (nameEditing) {
    return (
      <HeaderWrapper shadeBackground>
        <FieldNameEditor onClose={() => setNameEditing(false)} />
      </HeaderWrapper>
    );
  }

  return (
    <HeaderWrapper>
      <FieldName onClick={() => setNameEditing(true)} />
      {additionalConfigHasChanged ? (
        <SaveAdditionalConfigChangesButton />
      ) : (
        <>
          <FieldPositionMoving
            allHighestPositions={allHighestPositions}
            lowestFieldPositionInPhaseBottomContent={
              lowestFieldPositionInPhaseBottomContent
            }
          />
          <FieldActions onNameEdit={() => setNameEditing(true)} />
        </>
      )}
    </HeaderWrapper>
  );
};

export default FieldHeader;
