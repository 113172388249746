import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import PhaseContentGroupContext from './PhaseContentGroupContext';
import PhaseLeftContent from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/Phase/PhaseLeftContent';
import PhaseRightContent from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/Phase/PhaseRightContent';
import React from 'react';

type PhaseContentGroupProps = {
  which: ContentGroupType;
  children: React.ReactNode;
};

type PhaseContentGroupComponentProps = {
  children: React.ReactNode;
};

const phaseContentGroupComponents = {
  [ContentGroupType.LEFT]: (props: PhaseContentGroupComponentProps) => (
    <PhaseLeftContent style={{ marginRight: 72 }} {...props} />
  ),
  [ContentGroupType.RIGHT]: (props: PhaseContentGroupComponentProps) => (
    <PhaseRightContent style={{ width: 360 }} {...props} />
  ),
  [ContentGroupType.BOTTOM]: 'footer',
};

const PhaseContentGroup = ({ which, children }: PhaseContentGroupProps) => {
  const PhaseContentGroupComponent = phaseContentGroupComponents[which];

  return (
    <PhaseContentGroupContext.Provider value={which}>
      <PhaseContentGroupComponent>{children}</PhaseContentGroupComponent>
    </PhaseContentGroupContext.Provider>
  );
};

export default PhaseContentGroup;
