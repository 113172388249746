import RowAttachmentTypeOption from '../RowAttachmentTypeOption';
import RowSelectWrapper from './RowSelectWrapper';
import RowSelect from './RowSelect';
import { FormHelperText } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import RowAttachmentType from '../../../RowAttachmentType.enum';

type ExistingRowOptionProps = {
  value: RowAttachmentType;
  selected: boolean;
  selectedRow: number | null;
  onChangeSelectedRow: (rowId: number) => void;
  hasError: boolean;
  disabled: boolean;
};

const ExistingRowOption = ({
  value,
  selected,
  selectedRow,
  onChangeSelectedRow,
  hasError,
  disabled,
}: ExistingRowOptionProps) => (
  <RowAttachmentTypeOption
    value={value}
    selected={selected}
    disabled={disabled}
    style={{ marginBottom: hasError ? 22 : undefined }}
  >
    <RowAttachmentTypeOption.LabelText>
      <FormattedMessage id="portfolio.challenge_creation_flow.row_attachment.type_options.existing_row.label" />
    </RowAttachmentTypeOption.LabelText>
    <RowSelectWrapper error={hasError}>
      <RowSelect
        value={selectedRow}
        onChange={e => onChangeSelectedRow(e.target.value as number)}
        disabled={!selected}
      />
      {hasError && (
        <FormHelperText style={{ marginBottom: -22 }}>
          <FormattedMessage id="portfolio.challenge_creation_flow.row_attachment.type_options.existing_row.row_select.errors.empty" />
        </FormHelperText>
      )}
    </RowSelectWrapper>
  </RowAttachmentTypeOption>
);

export default ExistingRowOption;
