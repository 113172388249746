import { useMediaQuery } from '@mui/material';
import React from 'react';

type HideOnLowWidthProps = {
  width: number;
  children: React.ReactNode;
};

const HideOnLowWidth = ({ width, children }: HideOnLowWidthProps) => {
  const shouldHide = useMediaQuery(`(max-width: ${width}px)`);

  return shouldHide ? null : children;
};

export default HideOnLowWidth;
