import mediaQuery from '@/lib/styling/mediaQuery';
import styled from '@emotion/styled';

const PerspectiveWrapper = styled('ul')({
  margin: 0,
  paddingTop: 24,
  paddingLeft: 16,
  paddingRight: 16,
  listStyle: 'none',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  [mediaQuery(900)]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [mediaQuery(768)]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [mediaQuery(540)]: {
    gridTemplateColumns: '1fr',
  },
});

export default PerspectiveWrapper;
