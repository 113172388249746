import {
  common,
  blue,
  orange,
  red,
  lightBlue,
  purple,
  indigo,
} from '@mui/material/colors';
import { darken } from '@mui/material';
import { PaletteOptions } from '@mui/material/styles';

const lightPalette = {
  isHighContrast: false,
  primary: blue,
  secondary: {
    main: common.black,
  },
  warning: {
    main: orange[700],
  },
  danger: red[500],
  background: {
    default: '#F6F7FB',
    shaded: '#EDEFF7',
    border: '#DCE0EF',
  },
  sliders: {
    student: lightBlue[300],
    coach: purple[500],
    both: indigo[800],
  },
  partFocusColor: orange[500],
  partFocusTextColor: common.white,
  increaseContrastWithBackground: (
    color: string,
    coefficient: number,
  ): string => {
    return darken(color, coefficient);
  },
} satisfies PaletteOptions;

export default lightPalette;
