import styled from '@emotion/styled';
import Dialog from '@/components/Dialog';
import { grey } from '@mui/material/colors';

const TaskGroupWrapper = styled(Dialog.Content)(({ theme }) => ({
  marginBottom: -4,
  paddingTop: 16,
  paddingBottom: 4,
  paddingRight: 16,
  h3: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 8,
    paddingRight: 8,
    display: 'flex',
    alignItems: 'center',
  },
  // Task cards
  '.MuiBadge-root button': {
    backgroundColor: theme.isDark ? grey[700] : undefined,
  },
}));

export default TaskGroupWrapper;
