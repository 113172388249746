import { IntlProvider } from 'react-intl';
import getLanguage from '@/lib/utils/getLanguage';
import translations from './translations';
import moment from 'moment';
import 'moment/dist/locale/nl';
import 'moment/dist/locale/fr';
import syncLanguageToHtmlElement from './syncLanguageToHtmlElement';
import React from 'react';

type LanguageProviderProps = {
  children: React.ReactNode;
};

const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const language = getLanguage();
  moment.locale(language);

  syncLanguageToHtmlElement(language);

  return (
    <IntlProvider locale={language} messages={translations[language]}>
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
