import styled from '@emotion/styled';
import stripProps from '@/lib/utils/stripProps';
import { IconButton, IconButtonProps } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';

type ClearButtonProps = IconButtonProps & {
  visible: boolean;
};

const StyledButton = styled(stripProps(IconButton, 'visible'))<
  ClearButtonProps
>(({ theme, visible }) => ({
  transform: visible ? 'scale(1)' : 'scale(0)',
  transition: theme.transitions.create('all'),
}));

// Must use type="button" to prevent this button from being triggered on enter
// by the search form
const ClearButton = (props: ClearButtonProps) => (
  <StyledButton type="button" edge="end" size="large" {...props}>
    <ClearIcon />
  </StyledButton>
);

export default ClearButton;
