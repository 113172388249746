import HeaderContentWrapper from './HeaderContentWrapper';
import PhaseName from './PhaseName';
import { useMediaQuery } from '@mui/material';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import getPhaseName from '@/lib/utils/getPhaseName';
import MobilePhaseHeader from './MobilePhaseHeader';
import React from 'react';

type KanbanPhasesHeaderProps = {
  inTaskBoard?: boolean;
  children?: React.ReactNode;
};

const KanbanPhasesHeader = ({
  inTaskBoard,
  children,
}: KanbanPhasesHeaderProps) => {
  const shouldUseMobileHeader = useMediaQuery('(max-width: 640px)');

  if (shouldUseMobileHeader) {
    return <MobilePhaseHeader inTaskBoard={inTaskBoard ?? false} />;
  }

  return (
    <HeaderContentWrapper>
      <PhaseName>{getPhaseName(KanbanPhase.TODO)}</PhaseName>
      <PhaseName>{getPhaseName(KanbanPhase.IN_PROGRESS)}</PhaseName>
      <PhaseName>{getPhaseName(KanbanPhase.DONE)}</PhaseName>
      {children}
    </HeaderContentWrapper>
  );
};

export default KanbanPhasesHeader;
