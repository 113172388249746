/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import VisibleWeekRangeContext from '../../../../../VisibleWeekRangeContext';
import moment from 'moment';
import getChallengePositioning from './getChallengePositioning';
import RowChallengesVisibilityStateContext from '../../../RowChallengesVisibilityStateContext';
import PlanningBar from './PlanningBar';
import { ChallengeSummary } from '@/lib/types';

type ChallengePlanningBarProps = {
  challenge: ChallengeSummary;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: (
    contentVisibilityCompensationDistance: number,
    contentMaxVisibleWidth: number,
  ) => React.ReactNode;
};

const ChallengePlanningBar = ({
  challenge,
  onClick,
  children,
}: ChallengePlanningBarProps) => {
  const {
    visibleWeekRangeStartDate,
    visibleWeekRangeEndDate,
    visibleWeeksCount,
  } = useContext(VisibleWeekRangeContext);

  const plannedStartDate = moment(challenge.plannedStartDate).startOf('day');
  const plannedEndDate = moment(challenge.plannedEndDate).startOf('day');
  const plannedDuration = plannedEndDate.diff(plannedStartDate, 'days') + 1;

  const {
    challengeDistanceFromLeft,
    challengeWidth,
    contentVisibilityCompensationDistance,
    contentMaxVisibleWidth,
  } = getChallengePositioning(
    plannedStartDate,
    plannedDuration,
    visibleWeekRangeStartDate,
    visibleWeeksCount,
  );

  const { registerChallengeVisibility } = useContext(
    RowChallengesVisibilityStateContext,
  );

  const visible =
    plannedStartDate.isBefore(visibleWeekRangeEndDate) &&
    plannedEndDate.isAfter(visibleWeekRangeStartDate);

  useEffect(() => {
    registerChallengeVisibility(challenge.id, visible);
  }, [challenge.id, visible]);

  return (
    <PlanningBar
      challengeDistanceFromLeft={challengeDistanceFromLeft}
      challengeWidth={challengeWidth}
      onClick={onClick}
      visible={visible}
      disabled={!visible}
    >
      {children(contentVisibilityCompensationDistance, contentMaxVisibleWidth)}
    </PlanningBar>
  );
};

export default ChallengePlanningBar;
