import StyledOpenButton, {
  StyledOpenButtonProps,
} from '../PerspectiveCard/OpenButton/StyledOpenButton';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';

type CloseButtonProps = Omit<
  StyledOpenButtonProps,
  'className' | 'fillLevel' | 'children'
> & {
  fillLevel: number;
};

const CloseButton = ({ fillLevel, ...props }: CloseButtonProps) => (
  <StyledOpenButton
    className="perspective-card__open-button"
    fillLevel={fillLevel}
    {...props}
  >
    <ChevronLeftIcon />
  </StyledOpenButton>
);

export default CloseButton;
