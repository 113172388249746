import { Refresh as RefreshIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';

const StyledRefreshButton = styled(Button)({
  marginRight: 12,
  ':last-of-type': {
    marginRight: 0,
  },
  [mediaQuery(400)]: {
    marginRight: 0,
    marginBottom: 12,
    display: 'flex',
    ':last-of-type': {
      marginBottom: 0,
    },
  },
});

const RefreshButton = () => {
  const refreshPage = () => window.location.reload();

  return (
    <StyledRefreshButton
      variant="contained"
      onClick={refreshPage}
      startIcon={<RefreshIcon />}
      disableElevation
    >
      <FormattedMessage id="error_boundary.fixes.refresh_button" />
    </StyledRefreshButton>
  );
};

export default RefreshButton;
