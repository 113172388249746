import PerspectiveCard from '@/components/perspectives/PerspectiveCard';
import SublevelsTree from './SublevelsTree';
import TreeColumn from './TreeColumn';
import TreeWrapper from './TreeWrapper';
import React, { useRef } from 'react';
import TreeWrapperScrollContext from './TreeWrapperScrollContext';
import ClosePerspectiveButton from './ClosePerspectiveButton';
import useShouldUseSmallScreenVersion from './useSmallScreenVersion';
import SmallScreenPerspectiveHeader from './SmallScreenPerspectiveHeader';
import { PerspectiveWithSublevelTree } from '@/lib/types';
import { HTMLDivProps } from '@/lib/types';

type PerspectiveWithSublevelsTreeProps = Omit<HTMLDivProps, 'children'> & {
  perspective: PerspectiveWithSublevelTree;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const PerspectiveWithSublevelsTree = ({
  perspective,
  onClose,
  ...props
}: PerspectiveWithSublevelsTreeProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const scrollWrapperToRight = () => {
    const wrapper = wrapperRef.current;

    if (wrapper === null) {
      return;
    }

    wrapper.scrollTo({
      left: wrapper.scrollWidth,
      behavior: 'smooth',
    });
  };

  const shouldUseSmallScreenVersion = useShouldUseSmallScreenVersion();

  return (
    <TreeWrapperScrollContext.Provider value={scrollWrapperToRight}>
      {shouldUseSmallScreenVersion && (
        <SmallScreenPerspectiveHeader
          perspective={perspective}
          onClose={onClose}
        />
      )}
      <TreeWrapper {...props} ref={wrapperRef}>
        {!shouldUseSmallScreenVersion && (
          <TreeColumn>
            <PerspectiveCard perspective={perspective} />
            <ClosePerspectiveButton onClick={onClose} />
          </TreeColumn>
        )}
        <SublevelsTree perspective={perspective} />
      </TreeWrapper>
    </TreeWrapperScrollContext.Provider>
  );
};

export default PerspectiveWithSublevelsTree;
