import StyledLogo from './StyledLogo';
import EgodactLogo from '@/components/EgodactLogo';
import { Link } from 'react-router-dom';

const StyledEgodactLogo = StyledLogo.withComponent(EgodactLogo);

const FallbackLogo = () => (
  <Link to="/">
    <StyledEgodactLogo />
  </Link>
);

export default FallbackLogo;
