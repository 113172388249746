import { OmitFramerProps, HTMLDivProps } from '@/lib/types';
import { motion } from 'framer-motion';

type RowHoverStateProviderProps = OmitFramerProps<HTMLDivProps>;

const RowHoverStateProvider = (props: RowHoverStateProviderProps) => (
  <motion.section initial="initial" whileHover="hover" {...props} />
);

export default RowHoverStateProvider;
