import { ForwardedRef, forwardRef } from 'react';
import Dialog from '@/components/Dialog';
import { Button, ButtonProps } from '@mui/material';
import DangerColorThemeProvider from '@/components/DangerColorThemeProvider';

type DangerousButtonProps = ButtonProps & {
  inDialog?: boolean;
};

const DangerousButton = forwardRef(
  (
    { inDialog, ...props }: DangerousButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const ButtonComponent = inDialog ? Dialog.Actions.Button : Button;

    return (
      <DangerColorThemeProvider>
        <ButtonComponent color="primary" ref={ref} {...props} />
      </DangerColorThemeProvider>
    );
  },
);

DangerousButton.displayName = 'DangerousButton';

export default DangerousButton;
