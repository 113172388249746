import React, { useContext, useState } from 'react';
import PhaseContext from '../../PhaseContext';
import FormWrapper from './FormWrapper';
import PhaseNameInput from './PhaseNameInput';
import CancelButton from '@/components/CancelButton';
import { IconButton } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import useApi from '@/lib/api/useApi';
import ModelsContext from '@/pages/EditorPage/ChallengeModelEditor/ModelsContext';
import ModelTypeContext from '../../../ModelTypeContext';
import { EditorSectionWithoutRelations } from '@/lib/types';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

type PhaseNameEditorProps = {
  onClose: () => void;
};

const PhaseNameEditor = ({ onClose }: PhaseNameEditorProps) => {
  const api = useApi();

  const modelType = useContext(ModelTypeContext);
  const [, setModels] = useContext(ModelsContext);

  const { phase } = useContext(PhaseContext);
  const [newPhaseName, setNewPhaseName, , reenableNewPhaseNameSync] =
    useComplexSwrState(phase.name);

  const [saving, setSaving] = useState(false);

  const saveNameChange = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();

    if (!newPhaseName.trim()) {
      return;
    }

    setSaving(true);

    const updatedPhase = await api.put<EditorSectionWithoutRelations>(
      `editor/challenge-model/sections/${phase.id}/name`,
      {
        name: newPhaseName.trim(),
      },
    );

    setModels(models => {
      models[modelType].sections[phase.id] = {
        ...models[modelType].sections[phase.id],
        ...updatedPhase,
      };
    });

    setSaving(false);
    onClose();

    reenableNewPhaseNameSync();
  };

  const cancelNameChange = () => {
    onClose();

    setNewPhaseName(phase.name);
    reenableNewPhaseNameSync();
  };

  const intl = useIntl();

  return (
    <FormWrapper className="phase-name-form" onSubmit={saveNameChange}>
      <PhaseNameInput
        placeholder={intl.formatMessage({
          id: 'editor.model_editor.phase_editor.edit_name.input_placeholder',
        })}
        value={newPhaseName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setNewPhaseName(e.target.value)
        }
        onEscPress={cancelNameChange}
        autoFocus
      />
      <CancelButton
        visible={newPhaseName !== phase.name}
        onClick={cancelNameChange}
        disabled={saving}
      />
      <IconButton
        edge="end"
        type="submit"
        disabled={!newPhaseName.trim() || saving}
      >
        <CheckIcon />
      </IconButton>
    </FormWrapper>
  );
};

export default PhaseNameEditor;
