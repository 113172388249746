import StyledFooter from './StyledFooter';
import FooterLogo from './FooterLogo';
import FooterLink from './FooterLink';
import Dash from './Dash';
import { FormattedMessage } from 'react-intl';
import useContentIsWide from '@/lib/utils/useContentIsWide';

const AppFooter = () => {
  const contentIsWide = useContentIsWide();

  return (
    <StyledFooter wideContent={contentIsWide}>
      <FooterLogo />
      <FooterLink href="https://egodact.com/privacy" target="_blank">
        <FormattedMessage id="footer.links.privacy" />
      </FooterLink>
      <Dash />
      <FooterLink href="https://egodact.com/open-source" target="_blank">
        <FormattedMessage id="footer.links.open_source" />
      </FooterLink>
    </StyledFooter>
  );
};

export default AppFooter;
