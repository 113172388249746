import styled from '@emotion/styled';
import FormattedText from '@/components/FormattedText';

const LevelDescription = styled(FormattedText)({
  marginTop: 0,
  marginBottom: 16,
  textAlign: 'center',
});

export default LevelDescription;
