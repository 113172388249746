import styled from '@emotion/styled';

type UserToolbarPlaceholderProps = {
  visible: boolean;
};

const UserToolbarPlaceholder = styled('div')<UserToolbarPlaceholderProps>(
  ({ theme, visible }) => ({
    height: 60,
    backgroundColor: theme.palette.background.shaded,
    borderRadius: 4,
    display: visible ? 'block' : 'none',
  }),
);

export default UserToolbarPlaceholder;
