import PageTitle from '@/components/PageTitle';
import UserManager from './UserManager';
import GroupManager from './GroupManager';
import GroupsContext from './GroupsContext';
import { Group } from '@/lib/types';
import dummyFunction from '@/lib/utils/dummyFunction';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

const AdminPage = () => {
  const [loading, groups, setGroups] =
    usePolledSwrAsComplexState<Group[]>('groups');

  return (
    <GroupsContext.Provider
      value={loading ? [true, null, dummyFunction] : [false, groups, setGroups]}
    >
      <PageTitle id="admin.page_title" />
      <UserManager />
      <GroupManager />
    </GroupsContext.Provider>
  );
};
export default AdminPage;
