import styled from '@emotion/styled';

const GapminderControlsWrapper = styled('header')({
  marginBottom: 14,
  height: 24,
  display: 'flex',
  alignItems: 'center',
});

export default GapminderControlsWrapper;
