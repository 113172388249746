import getLanguage, { SupportedLanguage } from '@/lib/utils/getLanguage';
import LanguageItem from './LanguageItem';

type LanguagePickerProps = {
  onCloseSubmenu: () => void;
};

const LanguagePicker = ({ onCloseSubmenu }: LanguagePickerProps) => {
  const selectedLanguage = getLanguage();

  const setSelectedLanguage = (language: SupportedLanguage): void => {
    onCloseSubmenu();

    // Give the submenu time to close
    setTimeout(() => {
      localStorage.setItem('language', language);
      window.location.reload();
    }, 400);
  };

  return (
    <>
      <LanguageItem
        language="nl"
        languageName="Nederlands"
        languageNameTranslated="Dutch"
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
      <LanguageItem
        language="en"
        languageName="English"
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
      <LanguageItem
        language="fr"
        languageName="Français"
        languageNameTranslated="French"
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
    </>
  );
};

export default LanguagePicker;
