import styled from '@emotion/styled';
import { common } from '@mui/material/colors';

type PreviewBoxProps = {
  preview: string;
};

const PreviewBox = styled('section')<PreviewBoxProps>(({ theme, preview }) => ({
  position: 'relative',
  padding: 15,
  height: 150,
  fontWeight: 500,
  backgroundPosition: 'center',
  backgroundSize: 200,
  backgroundImage: `url('${preview}')`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: common.white,
  border: `2px solid ${theme.palette.text.primary}`,
  borderRadius: 4,
  boxSizing: 'border-box',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: theme.transitions.create(['background']),
}));

export default PreviewBox;
