import { useState } from 'react';
import GroupSelectorWrapper from './GroupSelectorWrapper';
import GroupSelectorButton from './GroupSelectorButton';
import GroupSelectorGroupList from './GroupSelectorGroupList';
import { ClickAwayListener } from '@mui/material';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import useGlobalStaticApiResponse from '@/lib/api/useGlobalStaticApiResponse';
import { Group } from '@/lib/types';

type GroupSelectorProps = {
  selectedGroupId: number | null;
  onGroupChange: (groupId: number | null) => void;
};

const GroupSelector = ({
  selectedGroupId,
  onGroupChange,
}: GroupSelectorProps) => {
  const authenticatedUser = useAuthenticatedUser()!;
  const [loading, groups] = useGlobalStaticApiResponse<Group[]>('groups');
  const [isSelectingGroup, setIsSelectingGroup] = useState(false);

  if (
    loading ||
    authenticatedUser.managedGroups.length === 0 ||
    groups.length === 0
  ) {
    return null;
  }

  const changeGroup = (groupId: number | null): void => {
    setIsSelectingGroup(false);

    if (groupId !== selectedGroupId) {
      onGroupChange(groupId);
    }
  };

  const groupsKeyedById = groups.reduce((groupsSoFar, group) => {
    groupsSoFar[group.id] = group;

    return groupsSoFar;
  }, {} as Record<number, Group>);

  return (
    <ClickAwayListener onClickAway={() => setIsSelectingGroup(false)}>
      <GroupSelectorWrapper>
        <GroupSelectorButton
          selectedGroup={
            selectedGroupId === null ? null : groupsKeyedById[selectedGroupId]
          }
          isSelectingGroup={isSelectingGroup}
          onClick={() => setIsSelectingGroup(!isSelectingGroup)}
        />
        <GroupSelectorGroupList
          groups={groupsKeyedById}
          selectedGroupId={selectedGroupId}
          onGroupChange={changeGroup}
          open={isSelectingGroup}
        />
      </GroupSelectorWrapper>
    </ClickAwayListener>
  );
};

export default GroupSelector;
