import useColorState from '../useColorState';
import StyledForm from './StyledForm';
import ColorPreview from '../ColorPreview';
import ColorTextField from '../ColorTextField';
import { IconButton } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import React from 'react';

type AddColorFormProps = {
  onAdd: (color: string) => void;
};

const AddColorForm = ({ onAdd }: AddColorFormProps) => {
  const [color, setColor] = useColorState();
  const fullHexColor = `#${color}`;

  const handleAdd = (e: React.FormEvent): void => {
    e.preventDefault();

    if (color.length < 6) {
      return;
    }

    onAdd(fullHexColor);

    setColor('');
  };

  return (
    <StyledForm onSubmit={handleAdd}>
      <ColorPreview color={fullHexColor} />
      <ColorTextField
        value={color}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setColor(e.target.value)
        }
      />
      <IconButton onClick={handleAdd} disabled={color.length < 6}>
        <CheckIcon />
      </IconButton>
    </StyledForm>
  );
};

export default AddColorForm;
