import styled from '@emotion/styled';
import Phase from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/Phase';

const PhaseWithFirstPhaseNameFormMarginCorrection = styled(Phase)({
  ':first-of-type .phase-name-form': {
    marginTop: -24,
  },
});

export default PhaseWithFirstPhaseNameFormMarginCorrection;
