import React, { useState } from 'react';
import Dialog from '@/components/Dialog';
import PromptControlsWrapper from './PromptControlsWrapper';
import PromptGroupSelect from './PromptGroupSelect';
import PromptTextField from './PromptTextField';
import MinimumRolePicker from './MinimumRolePicker';
import Space from '@/components/Space';
import PortfolioItemWithGroupsPromptVariant from './PortfolioItemWithGroupsPromptVariant.enum';
import UserRole from '@/lib/constants/UserRole.enum';
import { MinimumRole } from '@/lib/types';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

export type PortfolioItemWithGroupsPromptProps = {
  variant: PortfolioItemWithGroupsPromptVariant;
  label: React.ReactNode;
  placeholder?: string;
  defaultValue?: string;
  selectedMinimumRole?: MinimumRole;
  selectedGroups?: number[];
  createsItem?: boolean;
  open: boolean;
  onOk: (
    name: string,
    selectedMinimumRole: MinimumRole,
    selectedGroups: number[],
  ) => void;
  onCancel: () => void;
  children?: React.ReactNode;
};

// TODO remove old translations for standard group checkboxes
// TODO better UX -- picker with custom groups option
const PortfolioItemWithGroupsPrompt = ({
  variant,
  label,
  placeholder,
  defaultValue,
  selectedMinimumRole: defaultSelectedMinimumRole,
  selectedGroups: defaultSelectedGroups,
  createsItem,
  open,
  onOk,
  onCancel,
  children,
  ...props
}: PortfolioItemWithGroupsPromptProps) => {
  const [input, setInput, , reenableInputSync] = useComplexSwrState(
    defaultValue ?? '',
  );
  const [
    selectedMinimumRole,
    setSelectedMinimumRole,
    ,
    reenableSelectedMinimumRoleSync,
  ] = useComplexSwrState<MinimumRole>(
    defaultSelectedMinimumRole ?? UserRole.STUDENT,
    {
      stringifyChangeCheck: true,
    },
  );
  const [selectedGroups, setSelectedGroups, , reenableSelectedGroupsSync] =
    useComplexSwrState(defaultSelectedGroups ?? [], {
      stringifyChangeCheck: true,
    });
  const [hasEmptyError, setHasEmptyError] = useState(false);

  const resetState = (): void => {
    setInput(defaultValue ?? '');
    setSelectedMinimumRole(defaultSelectedMinimumRole ?? UserRole.STUDENT);
    setSelectedGroups(defaultSelectedGroups ?? []);
    setHasEmptyError(false);
  };

  const handleOk = (): void => {
    if (input.trim().length === 0) {
      setHasEmptyError(true);
      return;
    }

    onOk(input.trim(), selectedMinimumRole, selectedGroups);

    if (createsItem) {
      resetState();
    }

    reenableInputSync();
    reenableSelectedMinimumRoleSync();
    reenableSelectedGroupsSync();
  };

  const handleCancel = (): void => {
    setHasEmptyError(false);
    onCancel();

    resetState();

    reenableInputSync();
    reenableSelectedMinimumRoleSync();
    reenableSelectedGroupsSync();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      onEnterClose={handleOk}
      {...props}
    >
      {children}
      <PromptControlsWrapper>
        <MinimumRolePicker
          variant={variant}
          selectedMinimumRole={selectedMinimumRole}
          setSelectedMinimumRole={setSelectedMinimumRole}
        />
        <Space height={20} />
        <PromptGroupSelect
          variant={variant}
          value={selectedGroups}
          onChange={(groups: number[]) => setSelectedGroups(groups)}
        />
        <PromptTextField
          label={label}
          value={input}
          onChange={e => setInput(e.target.value)}
          hasEmptyError={hasEmptyError}
        />
      </PromptControlsWrapper>
      <Dialog.Actions addTopMargin>
        <Dialog.Actions.CancelButton onClick={handleCancel} />
        {createsItem ? (
          <Dialog.Actions.CreateButton onClick={handleOk} />
        ) : (
          <Dialog.Actions.SaveButton onClick={handleOk} />
        )}
      </Dialog.Actions>
    </Dialog>
  );
};

export default PortfolioItemWithGroupsPrompt;
