import styled from '@emotion/styled';

const ContentWrapper = styled('section')({
  padding: '48px 24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export default ContentWrapper;
