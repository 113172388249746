import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const PhaseContentWrapper = styled('div')({
  display: 'flex',
  [mediaQuery(640)]: {
    flexDirection: 'column',
  },
});

export default PhaseContentWrapper;
