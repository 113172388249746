import { useContext } from 'react';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import useCreateChallenge from './useCreateChallenge';
import PhaseContext from '../../../PhaseContext';
import CreateItem from './CreateItem';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import RowContext from '@/components/ChallengePortfolio/NormalView/Rows/Row/RowContext';

const CreateChallenge = () => {
  const row = useContext(RowContext);
  const phase = useContext(PhaseContext);

  const createChallenge = useCreateChallenge();

  return (
    <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
      <CreateItem
        tooltip={
          <FormattedMessageWithChallengeNaming id="portfolio.create_challenge.tooltip" />
        }
        onSave={name => createChallenge(name, phase, row.id)}
      />
    </RestrictedToPortfolioMode>
  );
};

export default CreateChallenge;
