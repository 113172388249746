import { Moment } from 'moment';

// TODO are these date conversions still necessary? Note that we do need an
// immutable copy
const isEarlier = (dateOne: Moment, dateTwo: Moment): boolean => {
  const firstMoment = dateOne.clone().startOf('day');
  const secondMoment = dateTwo.clone().startOf('day');

  return firstMoment.isBefore(secondMoment);
};

export default isEarlier;
