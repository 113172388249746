import StyledWorld from './StyledWorld';
import StyledWorldImage from './StyledWorldImage';
import { AgoraWorld } from '../../worlds';
import { FormattedMessage } from 'react-intl';

type WorldLabelProps = {
  world: AgoraWorld;
};

const WorldLabel = ({ world }: WorldLabelProps) => (
  <StyledWorld>
    <StyledWorldImage world={world} />
    <FormattedMessage
      id={`portfolio.challenge.world_select.world_labels.${world}_world`}
    />
  </StyledWorld>
);

export default WorldLabel;
