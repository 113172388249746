import React, { useContext, useState } from 'react';
import ChallengeContext from '../../../ChallengeContext';
import { Checkbox, FormControlLabel } from '@mui/material';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import useApi from '@/lib/api/useApi';
import { ChallengeWithoutRelations } from '@/lib/types';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

const IsPublicToggle = () => {
  const api = useApi();

  const [challenge, setChallenge] = useContext(ChallengeContext);

  const [isPublic, setIsPublic, , reenableSetIsPublicSync] = useComplexSwrState(
    challenge.isPublic,
  );
  const [saving, setSaving] = useState(false);

  const handleToggle = async (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): Promise<void> => {
    setIsPublic(checked);

    setSaving(true);

    const updatedChallenge = await api.put<ChallengeWithoutRelations>(
      `challenge-portfolio/challenges/${challenge.id}/is-public`,
      {
        isPublic: e.target.checked,
      },
    );

    setChallenge(challenge => {
      challenge.isPublic = updatedChallenge.isPublic;
    });

    setSaving(false);

    reenableSetIsPublicSync();
  };

  return (
    <section>
      <FormControlLabel
        control={
          <Checkbox
            checked={isPublic}
            onChange={handleToggle}
            name="is-public"
            disabled={saving}
          />
        }
        label={
          <FormattedMessageWithChallengeNaming id="portfolio.challenge.link_sharing.is_public_toggle.label" />
        }
      />
    </section>
  );
};

export default IsPublicToggle;
