import FieldType from '@/lib/constants/challengeModel/FieldType.enum';
import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import useItemIsVisibleToPortfolioUser from '../useItemIsVisibleToPortfolioUser';
import { Field } from '@/lib/types';

const useSortedVisibleFields = (fields: Field[]): Field[] => {
  const itemIsVisibleToPortfolioUser = useItemIsVisibleToPortfolioUser();

  // TODO is this still necessary with new backend?
  const hasValidFieldType = (field: Field): boolean => {
    return !!FieldType[field.type];
  };

  return fields
    .sort(sortByPosition)
    .filter(hasValidFieldType)
    .filter(itemIsVisibleToPortfolioUser);
};

export default useSortedVisibleFields;
