import styled from '@emotion/styled';
import moment from 'moment';

type FeedbackItemDateProps = {
  createdAt: string;
};

const StyledDate = styled('h4')(({ theme }) => ({
  marginBottom: 0,
  color: theme.palette.text.secondary,
  fontWeight: 400,
}));

const FeedbackItemDate = ({ createdAt }: FeedbackItemDateProps) => (
  <StyledDate>{moment(createdAt).format('L')}</StyledDate>
);

export default FeedbackItemDate;
