import styled from '@emotion/styled';

const StyledToolbar = styled('header')(({ theme }) => ({
  padding: 4,
  borderBottom: `2px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
}));

export default StyledToolbar;
