import PlatformType from '@/lib/constants/PlatformType.enum';

const getOtherPlatformType = (
  currentPlatformType: PlatformType,
): PlatformType => {
  if (currentPlatformType === PlatformType.CHALLENGEMONITOR) {
    return PlatformType.PROGRESSMONITOR;
  }

  return PlatformType.CHALLENGEMONITOR;
};

export default getOtherPlatformType;
