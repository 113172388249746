import { useContext } from 'react';
import ChallengeContext from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeContext';
import TaskContext from '@/components/ChallengePortfolio/NormalView/Task/TaskView/TaskContext';

const useProofApiEndpoint = (): string => {
  const [challenge] = useContext(ChallengeContext);
  const [task] = useContext(TaskContext);

  if (challenge === null) {
    return 'portfolio/proof';
  }

  if (task === null) {
    return `challenge-portfolio/challenges/${challenge.id}/proof`;
  }

  return `challenge-portfolio/tasks/${task.id}/proof`;
};

export default useProofApiEndpoint;
