import styled from '@emotion/styled';

type FieldNameWrapperProps = {
  secondary?: boolean;
};

const FieldNameWrapper = styled('header')<FieldNameWrapperProps>(
  ({ theme, secondary }) => ({
    marginBottom: 8,
    color: secondary ? theme.palette.text.disabled : theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
  }),
);

export default FieldNameWrapper;
