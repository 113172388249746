import React, { createContext, useState, useEffect } from 'react';
import PlatformType from '@/lib/constants/PlatformType.enum';
import { ReactContextWithAutomatedProvider } from '../types';

const SelectedPlatformContext = createContext<
  [PlatformType, React.Dispatch<React.SetStateAction<PlatformType>>]
>([
  null as unknown as PlatformType,
  () => {},
]) as ReactContextWithAutomatedProvider<
  [PlatformType, React.Dispatch<React.SetStateAction<PlatformType>>]
>;

const SELECTED_PLATFORM_CACHE_KEY = 'selectedPlatform';
const DEFAULT_PLATFORM = PlatformType.CHALLENGEMONITOR;

const allowedPlatforms = Object.values(PlatformType) as string[];

type AutomatedSelectedPlatformContextProviderProps = {
  children: React.ReactNode;
};

const getPreselectedPlatform = (): PlatformType => {
  const preselectedPlatform = localStorage.getItem(SELECTED_PLATFORM_CACHE_KEY);

  if (
    preselectedPlatform === null ||
    !allowedPlatforms.includes(preselectedPlatform)
  ) {
    return DEFAULT_PLATFORM;
  }

  return preselectedPlatform as PlatformType;
};

const AutomatedSelectedPlatformContextProvider = ({
  children,
}: AutomatedSelectedPlatformContextProviderProps) => {
  const [selectedPlatform, setSelectedPlatform] = useState<PlatformType>(
    getPreselectedPlatform(),
  );

  useEffect(() => {
    localStorage.setItem('selectedPlatform', selectedPlatform);
  }, [selectedPlatform]);

  return (
    <SelectedPlatformContext.Provider
      value={[selectedPlatform, setSelectedPlatform]}
    >
      {children}
    </SelectedPlatformContext.Provider>
  );
};

SelectedPlatformContext.AutomatedProvider =
  AutomatedSelectedPlatformContextProvider;

export default SelectedPlatformContext;
