import { useState } from 'react';
import DangerousButton from '@/components/DangerousButton';
import ConfirmationPrompt from './ConfirmationPrompt';
import { FormattedMessage } from 'react-intl';

type SaveButtonWithConfirmationPromptProps = {
  onSave: () => void;
  disabled: boolean;
};

const SaveButtonWithConfirmationPrompt = ({
  onSave,
  disabled,
}: SaveButtonWithConfirmationPromptProps) => {
  const [confirmationPromptOpen, setConfirmationPromptOpen] = useState(false);

  return (
    <>
      <DangerousButton
        variant="contained"
        onClick={() => setConfirmationPromptOpen(true)}
        disabled={disabled}
        disableElevation
      >
        <FormattedMessage id="platform_settings.save" />
      </DangerousButton>
      <ConfirmationPrompt
        open={confirmationPromptOpen}
        onCancel={() => setConfirmationPromptOpen(false)}
        onConfirm={() => {
          setConfirmationPromptOpen(false);
          onSave();
        }}
      />
    </>
  );
};

export default SaveButtonWithConfirmationPrompt;
