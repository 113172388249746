import { useContext, useState } from 'react';
import DangerousIconButton from '@/components/DangerousIconButton';
import { Close as DeleteIcon } from '@mui/icons-material';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import GroupsContext from '../../../GroupsContext';
import { Group } from '@/lib/types';

type DeleteGroupProps = {
  group: Group;
};

const DeleteGroup = ({ group }: DeleteGroupProps) => {
  const api = useApi();

  const [, , setGroups] = useContext(GroupsContext);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteGroup = async (): Promise<void> => {
    setConfirmationDialogOpen(false);
    setDeleting(true);

    await api.delete(`groups/${group.id}`);

    setDeleting(false);

    setGroups(groups => {
      const groupIndex = groups.findIndex(element => element.id === group.id);

      groups.splice(groupIndex, 1);
    });
  };

  return (
    <>
      <DangerousIconButton
        size="large"
        onClick={() => setConfirmationDialogOpen(true)}
        disabled={deleting}
      >
        <DeleteIcon />
      </DangerousIconButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="group_manager.group.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="group_manager.group.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteGroup} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeleteGroup;
