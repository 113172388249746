import {
  Challenge,
  ChallengeSummary,
  PerspectiveSummary,
  TaskSummary,
} from '@/lib/types';
import { useMemo } from 'react';

const getTasksArray = (
  challenge: Challenge | ChallengeSummary,
): TaskSummary[] => {
  if (Array.isArray(challenge.tasks)) {
    return challenge.tasks;
  }

  return Object.values(challenge.tasks);
};

const getChallengeAttachedPerspectives = (
  challenge: Challenge | ChallengeSummary,
): PerspectiveSummary[] => {
  const allAttachedPerspectives = new Map<number, PerspectiveSummary>();

  for (const task of getTasksArray(challenge)) {
    for (const perspective of task.directlyAttachedPerspectives) {
      allAttachedPerspectives.set(perspective.id, perspective);
    }

    for (const perspective of task.indirectlyAttachedPerspectives) {
      allAttachedPerspectives.set(perspective.id, perspective);
    }
  }

  return Array.from(allAttachedPerspectives.values());
};

const useChallengeAttachedPerspectives = (
  challenge: Challenge | ChallengeSummary,
): PerspectiveSummary[] => {
  const attachedPerspectives = useMemo(
    () => getChallengeAttachedPerspectives(challenge),
    [challenge],
  );

  return attachedPerspectives;
};

export default useChallengeAttachedPerspectives;
