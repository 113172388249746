import styled from '@emotion/styled';

type NameWrapperProps = {
  contentVisibilityCompensationDistance: number;
  contentMaxVisibleWidth: number;
};

const NameWrapper = styled('section')<NameWrapperProps>(
  ({
    theme,
    contentVisibilityCompensationDistance,
    contentMaxVisibleWidth,
  }) => ({
    position: 'relative',
    left: `${contentVisibilityCompensationDistance}%`,
    paddingLeft: 6,
    width: `${contentMaxVisibleWidth}%`,
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    transition: theme.transitions.simple,
  }),
);

export default NameWrapper;
