import { useContext } from 'react';
import VisiblePhaseContext from '@/components/ChallengePortfolio/VisiblePhaseContext';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';

type PhaseSwitching = [KanbanPhase, () => void, () => void];

const usePhaseSwitching = (inTaskBoard: boolean): PhaseSwitching => {
  const {
    visibleChallengePhase,
    setVisibleChallengePhase,
    visibleTaskPhase,
    setVisibleTaskPhase,
  } = useContext(VisiblePhaseContext);

  const visiblePhase = inTaskBoard ? visibleTaskPhase : visibleChallengePhase;
  const setVisiblePhase = inTaskBoard
    ? setVisibleTaskPhase
    : setVisibleChallengePhase;

  const switchToPreviousPhase = (): void => {
    const previousPhase =
      visiblePhase === KanbanPhase.IN_PROGRESS
        ? KanbanPhase.TODO
        : KanbanPhase.IN_PROGRESS;
    setVisiblePhase(previousPhase);
  };

  const switchToNextPhase = (): void => {
    const nextPhase =
      visiblePhase === KanbanPhase.TODO
        ? KanbanPhase.IN_PROGRESS
        : KanbanPhase.DONE;
    setVisiblePhase(nextPhase);
  };

  return [visiblePhase, switchToPreviousPhase, switchToNextPhase];
};

export default usePhaseSwitching;
