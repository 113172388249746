import modeUrls from './modeUrls';
import modeRoleRestrictions from './modeRoleRestrictions';
import AppMode from '../constants/AppMode.enum';

// TODO whole mode system could probably reworked, maybe with some global
// context or state
// TODO use createBrowserRouter with its corresponding rootlayout stuff
// This would allow setting a direct context with the mode, so this can be
// simplified
// TODO but might not be possible cause mode needs to be globally known
// Maybe we can circumvent that by putting login screen on different route?
// (would then need global preselectedSchool consumer that only does anything
// when not yet set in localstorage -> maybe display notification "to switch
// school, sign out")
// And not found page as well?
// Alternatively we could just have global state jotais for
// isDisplayingChallengePortfolio
const getModeInfo = (modeConstant: AppMode) => ({
  url: modeUrls[modeConstant],
  roleRestrictions: modeRoleRestrictions[modeConstant],
});

export default getModeInfo;
