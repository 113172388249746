import { useContext } from 'react';
import CollapsedPortfolioItemsContext from './CollapsedPortfolioItemsContext';

const useCollapsedState = (
  itemId: number,
  uncollapsedByDefault: boolean,
): [boolean, () => void] => {
  const { isPortfolioItemCollapsed, togglePortfolioItemCollapsed } = useContext(
    CollapsedPortfolioItemsContext,
  );

  const collapsed = isPortfolioItemCollapsed(itemId, uncollapsedByDefault);

  const toggleCollapsed = (): void => {
    togglePortfolioItemCollapsed(itemId, uncollapsedByDefault);
  };

  return [collapsed, toggleCollapsed];
};

export default useCollapsedState;
