import { useContext, useState } from 'react';
import AddSubtopicButton from './AddSubtopicButton';
import PortfolioItemWithGroupsPrompt from '../PortfolioItemWithGroupsPrompt';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import { EditorSubtopicWithoutRelations, MinimumRole } from '@/lib/types';
import EditorPortfolioTemplatesContext from '../../EditorPortfolioTemplatesContext';
import PortfolioItemWithGroupsPromptVariant from '../PortfolioItemWithGroupsPrompt/PortfolioItemWithGroupsPromptVariant.enum';

type AddSubtopicProps = {
  topicId: number;
  topicMinimumRole: MinimumRole;
  highestSubtopicPosition: number;
};

const AddSubtopic = ({
  topicId,
  topicMinimumRole,
  highestSubtopicPosition,
}: AddSubtopicProps) => {
  const api = useApi();

  const [, setPortfolioTemplates] = useContext(EditorPortfolioTemplatesContext);

  const [addPromptOpen, setAddPromptOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveNewSubtopic = async (
    name: string,
    minimumRole: MinimumRole,
    groups: number[],
  ): Promise<void> => {
    setAddPromptOpen(false);
    setSaving(true);

    const subtopicData = {
      name,
      position: Math.ceil(highestSubtopicPosition) + 1,
      minimumRole,
      groups,
    };

    const subtopic = await api.post<EditorSubtopicWithoutRelations>(
      `editor/portfolio-templates/subtopics?topicId=${topicId}`,
      subtopicData,
    );

    setPortfolioTemplates(portfolioTemplates => {
      portfolioTemplates.subtopics[subtopic.id] = {
        ...subtopic,
        groups: groups.map(id => ({ id })),
        parts: [],
      };

      portfolioTemplates.topics[topicId].subtopics.push({ id: subtopic.id });
    });

    setSaving(false);
  };

  return (
    <>
      <AddSubtopicButton
        onClick={() => setAddPromptOpen(true)}
        disabled={saving}
      >
        <FormattedMessage id="portfolio_template_editor.add_subtopic.button" />
      </AddSubtopicButton>
      <PortfolioItemWithGroupsPrompt
        variant={PortfolioItemWithGroupsPromptVariant.SUBTOPIC}
        label={
          <FormattedMessage id="portfolio_template_editor.add_subtopic.input_label" />
        }
        open={addPromptOpen}
        selectedMinimumRole={topicMinimumRole}
        onOk={saveNewSubtopic}
        onCancel={() => setAddPromptOpen(false)}
        createsItem
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio_template_editor.add_subtopic.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio_template_editor.add_subtopic.text" />
          </Dialog.ContentText>
        </Dialog.Content>
      </PortfolioItemWithGroupsPrompt>
    </>
  );
};

export default AddSubtopic;
