import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const UsersWrapper = styled('div')({
  margin: -6,
  overflow: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  overflowY: 'hidden',
  [mediaQuery(640)]: {
    flexDirection: 'column',
  },
});

export default UsersWrapper;
