import React, { useState } from 'react';
import { useMediaQuery, List, Divider } from '@mui/material';
import ChallengeActionsButton from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/MobileChallengeActions/ChallengeActionsButton';
import EditButton from './ActionButtons/EditButton';
import CopyButton from './ActionButtons/CopyButton';
import DeleteButton from './ActionButtons/DeleteButton';
import LinkSharingButton from './ActionButtons/LinkSharingButton';
import CopyTask from '../CopyTask';
import TaskLinkSharing from '../TaskLinkSharing';
import DeleteTask from '../DeleteTask';
import MobileActionsDrawer from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader/MobileChallengeActions/MobileActionsDrawer';

type MobileTaskActionsProps = {
  onEdit: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const MobileTaskActions = ({ onEdit }: MobileTaskActionsProps) => {
  const [open, setOpen] = useState(false);
  const usesMobileChallengeActions = useMediaQuery('(max-width: 640px)');

  const actuallyOpen = open && usesMobileChallengeActions;

  return (
    <>
      <ChallengeActionsButton onClick={() => setOpen(true)} />
      <MobileActionsDrawer open={actuallyOpen} onClose={() => setOpen(false)}>
        <List>
          <EditButton onClick={onEdit} />
          <CopyTask ButtonComponent={CopyButton} />
          <TaskLinkSharing ButtonComponent={LinkSharingButton} />
        </List>
        <Divider />
        <List>
          <DeleteTask ButtonComponent={DeleteButton} />
        </List>
      </MobileActionsDrawer>
    </>
  );
};

export default MobileTaskActions;
