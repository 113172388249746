import styled from '@emotion/styled';
import ChallengeCard from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeCard';
import { useMemo } from 'react';
import { alpha } from '@mui/material';
import { common, grey } from '@mui/material/colors';
import { Challenge, ChallengeSummary } from '@/lib/types';
import dummyFunction from '@/lib/utils/dummyFunction';

type UnopenableChallengeCardProps = {
  challenge: Challenge;
};

const StyledChallengeCard = styled(ChallengeCard)(({ theme }) => ({
  padding: 16,
  backgroundColor: theme.isDark ? grey[700] : undefined,
  backgroundImage: 'none',
  ':not(:active):hover': {
    boxShadow: `0 2px 1px -1px ${alpha(common.black, 0.2)}, 0 1px 1px 0 ${alpha(
      common.black,
      0.14,
    )}, 0 1px 3px 0 ${alpha(common.black, 0.12)}`,
  },
  ':active': {
    transform: 'scale(1)',
  },
  // Challenge title
  h4: {
    marginBottom: 12,
    fontSize: 20,
    minHeight: 21,
    maxHeight: 61,
  },
}));

const UnopenableChallengeCard = ({
  challenge,
}: UnopenableChallengeCardProps) => {
  const challengeWithCoachCommentCount = useMemo(() => {
    // TODO return block can be replaced with direct arrow function return when
    // ts/compiler bug is fixed
    return {
      ...challenge,
      _count: {
        coachComments: 0,
      },
    } as ChallengeSummary;
  }, [challenge]);

  return (
    <StyledChallengeCard
      challenge={challengeWithCoachCommentCount}
      onClick={dummyFunction}
      withPhaseText={false}
      collapsed={false}
      dragging={false}
      disabled
    />
  );
};

export default UnopenableChallengeCard;
