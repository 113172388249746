import useProgressWithSelectedWeekSyncing from './useProgressWithSelectedWeekSyncing';
import StyledSlider from './StyledSlider';
import { weekNumber } from 'weeknumber';

type WeekSliderProps = {
  selectedYear: number;
  weeks: number[];
  selectedWeek: number;
  setSelectedWeek: (week: number) => void;
  disabled: boolean;
};

const WeekSlider = ({
  selectedYear,
  weeks,
  selectedWeek,
  setSelectedWeek,
  disabled,
}: WeekSliderProps) => {
  const [progress, setProgress] = useProgressWithSelectedWeekSyncing(
    selectedWeek,
    weeks,
  );

  // TODO OutOfBoundsError
  const getSelectedWeekFromProgress = (progress: number): number => {
    if (progress === 100) {
      return weeks[weeks.length - 1];
    }

    const selectedWeekIndex = Math.floor((progress / 100) * weeks.length);

    return weeks[selectedWeekIndex];
  };

  const ensureWeekIsNotInFuture = (week: number): number => {
    const now = new Date();
    const currentWeek = weekNumber(now) + 1;
    const currentYear = now.getFullYear();

    if (week > currentWeek && selectedYear === currentYear) {
      return currentWeek;
    }

    return week;
  };

  const updateSelectedWeek = (progress: number): void => {
    const newSelectedWeek = ensureWeekIsNotInFuture(
      getSelectedWeekFromProgress(progress),
    );

    setSelectedWeek(newSelectedWeek);
  };

  return (
    <StyledSlider
      value={progress}
      onChange={newProgress => setProgress(newProgress)}
      onChangeCommitted={progress => updateSelectedWeek(progress)}
      disabled={disabled}
    />
  );
};

export default WeekSlider;
