import UserWrapper from './UserWrapper';
import UserInfoWrapper from './UserInfoWrapper';
import LogEntryUserAvatar from './LogEntryUserAvatar';
import UserName from './UserName';
import LogEntryCreatedAt from './LogEntryCreatedAt';
import { LogEntry } from '@/lib/types';

type LogEntryUserProps = {
  logEntry: LogEntry;
};

const LogEntryUser = ({ logEntry }: LogEntryUserProps) => (
  <UserWrapper>
    {logEntry.author !== null && <LogEntryUserAvatar user={logEntry.author} />}
    <UserInfoWrapper>
      {logEntry.author !== null && <UserName name={logEntry.author.name} />}
      <LogEntryCreatedAt createdAt={logEntry.createdAt} />
    </UserInfoWrapper>
  </UserWrapper>
);

export default LogEntryUser;
