import { useContext } from 'react';
import SelectedPlatformContext from '@/lib/contexts/SelectedPlatformContext';
import SelectedUserContext from '@/lib/contexts/SelectedUserContext';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import PortfolioUserContext from '@/lib/contexts/PortfolioUserContext';
import PageTitle from '@/components/PageTitle';
import PlatformType from '@/lib/constants/PlatformType.enum';
import ChallengePortfolio from '@/components/ChallengePortfolio';
import RubricsPortfolio from '@/components/RubricsPortfolio';
import NotFoundTitle from '@/components/NotFoundTitle';
import { FormattedMessage } from 'react-intl';
import ShowPerspectiveCategorizationViewContext from '@/lib/contexts/ShowPerspectiveCategorizationViewContext';
import PerspectiveCategorizationView from '@/components/PerspectiveCategorizationView';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';

const CoachPage = () => {
  const [selectedPlatform] = useContext(SelectedPlatformContext);
  const [showPerspectiveCategorizationView] = useContext(
    ShowPerspectiveCategorizationViewContext,
  );

  const selectedUser = useContext(SelectedUserContext);

  return (
    <PortfolioModeContext.Provider value={PortfolioMode.COACH}>
      <PortfolioUserContext.Provider value={selectedUser}>
        <PageTitle id="coach.page_title" />
        {!selectedUser && (
          <NotFoundTitle>
            <FormattedMessage id="coach.no_user_selected" />
          </NotFoundTitle>
        )}
        {selectedUser && showPerspectiveCategorizationView && (
          <PerspectiveCategorizationView />
        )}
        {selectedUser &&
          !showPerspectiveCategorizationView &&
          selectedPlatform === PlatformType.CHALLENGEMONITOR && (
            <ChallengePortfolio />
          )}
        {selectedUser &&
          !showPerspectiveCategorizationView &&
          selectedPlatform === PlatformType.PROGRESSMONITOR && (
            <RubricsPortfolio />
          )}
      </PortfolioUserContext.Provider>
    </PortfolioModeContext.Provider>
  );
};

export default CoachPage;
