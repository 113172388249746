import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import React from 'react';

type StyledPhaseColumnProps = {
  hasItems?: boolean;
  inTaskBoard: boolean;
  collapsed: boolean;
  invisible?: boolean;
  children: React.ReactNode;
};

const StyledPhaseColumn = styled('section')<StyledPhaseColumnProps>(
  ({ theme }) => ({
    position: 'relative',
    paddingTop: 12,
    paddingLeft: 20,
    flex: 1,
    boxSizing: 'border-box',
    borderLeft: `2px solid ${theme.palette.background.border}`,
    borderRight: `2px solid ${theme.palette.background.border}`,
    transition: theme.transitions.create(['border']),
    display: 'flex',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    ':first-of-type': {
      border: 'none',
    },
    ':last-child': {
      border: 'none',
    },
    [mediaQuery(640)]: {
      paddingLeft: 8,
      width: '100%',
      border: 'none',
    },
  }),
  ({ hasItems, inTaskBoard, collapsed, invisible }) => ({
    paddingBottom: collapsed || hasItems ? 4 : 44,
    paddingRight: 12,
    minHeight: collapsed ? 44 : undefined,
    [mediaQuery(640)]: {
      // Un-setting the position is necessary for correct display of the create
      // task button
      position: inTaskBoard ? 'unset' : undefined,
      paddingRight: inTaskBoard ? 8 : 36,
      display: invisible ? 'none' : undefined,
    },
  }),
);

export default StyledPhaseColumn;
