import styled from '@emotion/styled';
import Dialog, { DialogProps } from '@/components/Dialog';

type DatePickerDialogProps = Omit<DialogProps, 'disableEnterClose'>;

const StyledDialog = styled(Dialog)({
  '.MuiDialog-container': {
    alignItems: 'center !important',
  },
  '.MuiDialog-paper': {
    maxWidth: 320,
  },
  '.MuiPickersLayout-root': {
    backgroundColor: 'transparent',
  },
});

const DatePickerDialog = (props: DatePickerDialogProps) => (
  <StyledDialog disableEnterClose {...props} />
);

export default DatePickerDialog;
