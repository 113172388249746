import { FormattedMessage } from 'react-intl';
import PlatformLoadingView from '@/components/PlatformLoadingView';
import PlatformType from '@/lib/constants/PlatformType.enum';

type PortfolioLoadingViewProps = {
  platform: PlatformType;
};

const PortfolioLoadingView = ({ platform }: PortfolioLoadingViewProps) => (
  <PlatformLoadingView
    platform={platform}
    loadingMessage={<FormattedMessage id="portfolio.loading_message" />}
  />
);

export default PortfolioLoadingView;
