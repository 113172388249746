import { calculateFillPercentage } from '../PerspectiveCard/fillHelpers';
import styled from '@emotion/styled';
import PerspectiveCardContent from '../PerspectiveCard/PerspectiveCardContent';
import stripProps from '@/lib/utils/stripProps';
import { HTMLDivProps } from '@/lib/types';

export type SublevelCardContentProps = HTMLDivProps & {
  fillLevel: number;
};

const SublevelCardContent = styled(
  stripProps(PerspectiveCardContent, 'fillLevel'),
)<SublevelCardContentProps>(({ theme, fillLevel }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  transform: 'none',
  padding: '20px 12px',
  width: '100%',
  height: `${100 - calculateFillPercentage(fillLevel)}%`,
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  transition: theme.transitions.create(['height', 'padding']),
}));

export default SublevelCardContent;
