import { ObjectWithPosition } from '@/lib/types';

const sortByPosition = (
  a: ObjectWithPosition,
  b: ObjectWithPosition,
): number => {
  return a.position - b.position;
};

export default sortByPosition;
