/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import useApiResponse from '@/lib/api/useApiResponse';
import LoginContentLoadingSpinner from '../LoginContentLoadingSpinner';
import usePreselectedSchool from './usePreselectedSchool';
import SchoolSelectorWrapper from './SchoolSelectorWrapper';
import Space from '@/components/Space';
import SchoolAutocomplete from './SchoolAutocomplete';
import { FormattedMessage } from 'react-intl';
import { SchoolSummary } from '@/lib/types';

type SchoolSelectorProps = {
  selectedSchool: SchoolSummary | null;
  onSelectSchool: (school: SchoolSummary) => void;
  hasHandledPreselectedSchool: boolean;
  setHasHandledPreselectedSchool: (
    hasHandledPreselectedSchool: boolean,
  ) => void;
  hidden: boolean;
};

const SchoolSelector = ({
  selectedSchool,
  onSelectSchool,
  hasHandledPreselectedSchool,
  setHasHandledPreselectedSchool,
  hidden,
}: SchoolSelectorProps) => {
  const [loading, schools] = useApiResponse<SchoolSummary[]>('schools');
  const preselectedSchool = usePreselectedSchool(schools);

  useEffect(() => {
    if (loading || hasHandledPreselectedSchool) {
      return;
    }

    setHasHandledPreselectedSchool(true);

    if (preselectedSchool !== null) {
      onSelectSchool(preselectedSchool);
    }
  }, [loading]);

  if (hidden) {
    return null;
  }

  return (
    <SchoolSelectorWrapper>
      {loading || !hasHandledPreselectedSchool ? (
        <LoginContentLoadingSpinner />
      ) : (
        <>
          <h1>
            <FormattedMessage id="login.school_selector.title" />
          </h1>
          <Space height={24} />
          <SchoolAutocomplete
            value={selectedSchool}
            onChange={(school: SchoolSummary) => onSelectSchool(school)}
            schools={schools}
          />
        </>
      )}
    </SchoolSelectorWrapper>
  );
};

export default SchoolSelector;
