import { useContext } from 'react';
import allowedEmailsText from './allowedEmailsText';
import SelectedSchoolContext from '../SelectedSchoolContext';
import FormattedHtmlMessage from '@/components/FormattedHtmlMessage';

type AllowedEmailsMessageProps = {
  translationsGroupId?: string;
};

const AllowedEmailsMessage = ({
  translationsGroupId,
}: AllowedEmailsMessageProps) => {
  const selectedSchool = useContext(SelectedSchoolContext);

  const allowedEmails = selectedSchool.instanceConfig.allowedEmails;
  const hasMultipleAllowedEmails = allowedEmails.length > 1;

  if (hasMultipleAllowedEmails) {
    return (
      <FormattedHtmlMessage
        id={`${translationsGroupId ?? 'login.allowed_emails_text'}.plural`}
        values={{
          allowedEmailsText: allowedEmailsText(allowedEmails),
        }}
      />
    );
  }

  return (
    <FormattedHtmlMessage
      id={`${translationsGroupId ?? 'login.allowed_emails_text'}.singular`}
      values={{
        allowedEmailText: allowedEmailsText(allowedEmails),
      }}
    />
  );
};

export default AllowedEmailsMessage;
