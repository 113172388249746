import { useContext } from 'react';
import AuthenticationStateContext from '../../components/App/AppSetup/AuthenticationState/AuthenticationStateContext';
import { AuthenticatedUser } from '@/lib/types';

const useAuthenticatedUser = (): AuthenticatedUser | null => {
  const [authenticatedUser] = useContext(AuthenticationStateContext);

  return authenticatedUser;
};

export default useAuthenticatedUser;
