import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const StyledDash = styled('span')(({ theme }) => ({
  marginLeft: 6,
  marginRight: 6,
  color: theme.palette.text.disabled,
  [mediaQuery(650)]: {
    display: 'none',
  },
}));

const Dash = () => <StyledDash>&mdash;</StyledDash>;

export default Dash;
