import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const InnerContentWrapper = styled('section')({
  marginBottom: -25,
  [mediaQuery(900)]: {
    overflowY: 'auto',
  },
});

export default InnerContentWrapper;
