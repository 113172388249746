import React, { useContext, useState } from 'react';
import CopyButton from './CopyButton';
import ChallengeContext from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeContext';
import useApi from '@/lib/api/useApi';
import useAddCopySuffix from '@/lib/utils/useAddCopySuffix';
import CloseTaskContext from '../CloseTaskContext';
import TaskContext from '../../TaskContext';
import { TaskSummary } from '@/lib/types';

type CopyTaskProps = {
  ButtonComponent?: React.ElementType;
};

const CopyTask = ({
  ButtonComponent: ButtonComponentOrUndefined,
}: CopyTaskProps) => {
  const api = useApi();

  const [, setChallenge] = useContext(ChallengeContext);
  const [task] = useContext(TaskContext);

  const [copying, setCopying] = useState(false);

  const addCopySuffix = useAddCopySuffix();
  const closeTask = useContext(CloseTaskContext);

  const copyTask = async (): Promise<void> => {
    setCopying(true);

    const taskCopy = await api.post<TaskSummary>(
      `challenge-portfolio/tasks/${task.id}/copy`,
      {
        name: addCopySuffix(task.name),
      },
    );

    setChallenge(challenge => {
      challenge.tasks[taskCopy.id] = taskCopy;
    });

    setCopying(false);
    closeTask();
  };

  const ButtonComponent = ButtonComponentOrUndefined ?? CopyButton;

  return <ButtonComponent onClick={copyTask} disabled={copying} />;
};

export default CopyTask;
