/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import ChallengeViewCardWithPageOverlay from './ChallengeViewCardWithPageOverlay';
import TaskBoard from '../../TaskBoard';
import ChallengeContentWrapper from './ChallengeContentWrapper';
import NewlySharedInfo from './NewlySharedInfo';
import ChallengeHeader from './ChallengeHeader';
import ChallengeInfo from './ChallengeInfo';
import MobilePhaseSelect from './MobilePhaseSelect';
import ChallengeFields from './ChallengeFields';
import ChallengeContext from './ChallengeContext';
import NewlySharedContext from '../../NewlySharedChallenges/NewlySharedContext';
import ChallengeContentLoadingSpinner from './ChallengeContentLoadingSpinner';
import useMarkCoachCommentsAsReadOnLoad from './useMarkCoachCommentsAsReadOnLoad';
import ChallengePerspectiveIndicators from './ChallengePerspectiveIndicators';
import { Challenge, ChallengeSummary } from '@/lib/types';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

type ChallengeViewProps = {
  challenge: ChallengeSummary;
  onClose: () => void;
};

// TODO verify users/invitees is synced to challengeportfolio
const ChallengeView = ({
  challenge: challengeSummary,
  onClose,
}: ChallengeViewProps) => {
  const [isNewlyShared] = useContext(NewlySharedContext);
  const [loading, challenge, setChallenge] =
    usePolledSwrAsComplexState<Challenge>(
      `challenge-portfolio/challenges/${challengeSummary.id}`,
    );

  const [taskBoardOpen, setTaskBoardOpen] = useState(false);

  useMarkCoachCommentsAsReadOnLoad(challengeSummary, loading);

  // TODO verify this works properly (for mobilephaseselect)
  useEffect(() => {
    if (loading) {
      return;
    }

    setChallenge(challenge => {
      challenge.phase = challengeSummary.phase;
    });
  }, [loading, challengeSummary.phase]);

  const closeChallengeOrTaskBoard = () => {
    if (taskBoardOpen) {
      setTaskBoardOpen(false);

      return;
    }

    onClose();
  };

  // TODO better typing on challengecontext: it should be clear that it could
  // be using the challengesummary as fallback
  return (
    <ChallengeViewCardWithPageOverlay
      taskBoardOpen={taskBoardOpen}
      onClose={closeChallengeOrTaskBoard}
    >
      <ChallengeContext.Provider
        value={[
          challenge ?? (challengeSummary as unknown as Challenge),
          setChallenge,
        ]}
      >
        {taskBoardOpen ? (
          <TaskBoard />
        ) : (
          <ChallengeContentWrapper>
            {isNewlyShared && <NewlySharedInfo />}
            <ChallengeHeader
              onTasksOpen={() => setTaskBoardOpen(true)}
              onCloseChallenge={onClose}
              loading={loading}
            />
            <ChallengeInfo />
            {loading ? (
              <ChallengeContentLoadingSpinner />
            ) : (
              <>
                <ChallengePerspectiveIndicators />
                <MobilePhaseSelect />
                <ChallengeFields />
              </>
            )}
          </ChallengeContentWrapper>
        )}
      </ChallengeContext.Provider>
    </ChallengeViewCardWithPageOverlay>
  );
};

export default ChallengeView;
