import React, { useContext } from 'react';
import KeyValueEditor from './utils/KeyValueEditor';
import AdditionalConfigStateContext from '../AdditionalConfigStateContext';

const LogEntryNameEditor = () => {
  const [additionalConfig, setAdditionalConfig] = useContext(
    AdditionalConfigStateContext,
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setAdditionalConfig(additionalConfig => {
      additionalConfig.logEntryName = e.target.value || undefined;
    });
  };

  return (
    <KeyValueEditor.Wrapper>
      <KeyValueEditor.Label id="editor.model_editor.field_editor.log_entry_name_editor.label" />
      <KeyValueEditor.TextField
        placeholderId="editor.model_editor.field_editor.log_entry_name_editor.input_placeholder"
        value={additionalConfig.logEntryName ?? ''}
        onChange={handleChange}
      />
    </KeyValueEditor.Wrapper>
  );
};

export default LogEntryNameEditor;
