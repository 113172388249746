import { User } from '@/lib/types';
import { useState, useEffect } from 'react';

export type SelectionCursor = {
  index: number | null;
  moveUp: () => void;
  moveDown: () => void;
  clear: () => void;
};

const useSelectionCursor = (selectableUsers: User[]): SelectionCursor => {
  const [selectionCursor, setSelectionCursor] = useState<number | null>(null);

  const moveSelectionCursorUp = () => {
    setSelectionCursor(selectionCursor => {
      const shouldMoveCursorToEnd =
        selectionCursor === null || selectionCursor === 0;

      if (shouldMoveCursorToEnd) {
        return selectableUsers.length - 1;
      }

      return selectionCursor - 1;
    });
  };

  const moveSelectionCursorDown = () => {
    setSelectionCursor(selectionCursor => {
      const shouldResetCursor =
        selectionCursor === null ||
        selectionCursor === selectableUsers.length - 1;

      if (shouldResetCursor) {
        return 0;
      }

      return selectionCursor + 1;
    });
  };

  const clearSelectionCursor = () => {
    setSelectionCursor(null);
  };

  const selectableUsersAsString = selectableUsers
    .map(({ email }) => email)
    .join(',');

  useEffect(() => {
    clearSelectionCursor();
  }, [selectableUsersAsString]);

  return {
    index: selectionCursor,
    moveUp: moveSelectionCursorUp,
    moveDown: moveSelectionCursorDown,
    clear: clearSelectionCursor,
  };
};

export default useSelectionCursor;
