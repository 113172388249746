import { useContext } from 'react';
import FieldRendererContext from '../../../FieldRendererContext';
import { RubricsPortfolio, SetComplexImmerStateAction } from '@/lib/types';

// Mimicking the rubrics portfolio is necessary to make challenge/task rubrics
// work
const useMimicRubricsPortfolio = (): [
  RubricsPortfolio,
  SetComplexImmerStateAction<RubricsPortfolio>,
] => {
  const { portfolioItem, setPortfolioItem } = useContext(FieldRendererContext);

  const rubricsPortfolio = portfolioItem.rubrics;

  const setRubricsPortfolio = (
    stateUpdateFunction: (oldState: RubricsPortfolio) => void,
  ) => {
    setPortfolioItem(portfolioItem => {
      stateUpdateFunction(portfolioItem.rubrics);
    });
  };

  return [rubricsPortfolio, setRubricsPortfolio];
};

export default useMimicRubricsPortfolio;
