import { useState } from 'react';
import PreconfiguredLevelDialog from './PreconfiguredLevelDialog';
import LevelDialog from '@/components/RubricsPortfolioComponents/LevelDialog';
import NoLevelsMessage from '@/components/RubricsPortfolioComponents/NoLevelsMessage';
import Level from '../Level';
import Slider from './Slider';
import PartSlider from './PartSlider';
import FocusButton from './FocusButton';
import StyledPart from '@/components/RubricsPortfolioComponents/StyledPart';
import FocusIndicator from './FocusIndicator';
import PartName from '@/components/RubricsPortfolioComponents/StyledPart/PartName';
import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import useProgress from './useProgress';
import useHasFocus from './useHasFocus';
import { Part as PartType } from '@/lib/types';

type PartProps = {
  part: Omit<PartType, 'groups'>;
  subtopicIcon?: string | null;
  noFocusButton?: boolean;
  mobileOutlined?: boolean;
};

const Part = ({
  part,
  subtopicIcon,
  noFocusButton,
  mobileOutlined,
}: PartProps) => {
  const [progress, setProgress] = useProgress(part.id);
  const [hasFocus, setHasFocus] = useHasFocus(part.id);

  const [levelDialogOpen, setLevelDialogOpen] = useState(false);

  const levels = part.levels.sort(sortByPosition);

  return (
    <StyledPart mobileOutlined={mobileOutlined}>
      <FocusIndicator hasFocus={hasFocus}>
        {!noFocusButton && (
          <FocusButton
            partId={part.id}
            hasFocus={hasFocus}
            setHasFocus={setHasFocus}
          />
        )}
        <PartName hasFocus={hasFocus} onClick={() => setLevelDialogOpen(true)}>
          {part.name}
        </PartName>
      </FocusIndicator>
      <PartSlider
        progress={progress}
        onClick={() => setLevelDialogOpen(true)}
      />
      <PreconfiguredLevelDialog
        part={part}
        subtopicIcon={subtopicIcon ?? null}
        levelCount={levels.length}
        open={levelDialogOpen}
        onClose={() => setLevelDialogOpen(false)}
      >
        <LevelDialog.LevelWrapper>
          {levels.length === 0 ? (
            <NoLevelsMessage />
          ) : (
            levels.map(level => <Level level={level} key={level.id} />)
          )}
        </LevelDialog.LevelWrapper>
        <Slider
          partId={part.id}
          progress={progress}
          setProgress={setProgress}
          levelCount={levels.length}
        />
      </PreconfiguredLevelDialog>
    </StyledPart>
  );
};

export default Part;
