import styled from '@emotion/styled';
import ActionMenuButton from '../ActionMenuButton';
import { alpha } from '@mui/material';

const StyledDeleteButton = styled(ActionMenuButton)(({ theme }) => ({
  color: theme.palette.danger,
  ':hover, :focus': {
    backgroundColor: alpha(theme.palette.danger, 0.08),
  },
}));

export default StyledDeleteButton;
