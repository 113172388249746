import Space from '@/components/Space';
import { IconButton, IconButtonProps } from '@mui/material';

type PhaseSelectIconButtonProps = Omit<IconButtonProps, 'size'> & {
  hidden: boolean;
};

const PhaseSelectIconButton = ({
  hidden: shouldDisplayPlaceholder,
  ...props
}: PhaseSelectIconButtonProps) => {
  if (shouldDisplayPlaceholder) {
    return <Space width={48} />;
  }

  return <IconButton size="large" {...props} />;
};

export default PhaseSelectIconButton;
