import styled from '@emotion/styled';
import { Button } from '@mui/material';

const StyledAddButton = styled(Button)({
  marginBottom: 6,
  height: 32,
  minHeight: 32,
  lineHeight: 1,
});

export default StyledAddButton;
