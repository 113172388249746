import styled from '@emotion/styled';
import firstChildSelector from '@/lib/styling/firstChildSelector';

const Separator = styled('hr')(({ theme }) => ({
  marginBottom: 22.5,
  border: 'none',
  borderTop: `2px dashed ${theme.palette.divider}`,
  [firstChildSelector]: {
    display: 'none',
  },
}));

export default Separator;
