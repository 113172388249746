import mediaQuery from '@/lib/styling/mediaQuery';
import styled from '@emotion/styled';

const PerspectiveWrapper = styled('ul')({
  marginTop: 0,
  marginBottom: -16,
  marginLeft: -8,
  marginRight: -8,
  padding: 0,
  width: 'calc(100% + 16px)',
  listStyle: 'none',
  boxSizing: 'border-box',
  display: 'flex',
  flexWrap: 'wrap',
  '> li': {
    minWidth: 240,
    maxWidth: 240,
  },
  [mediaQuery(2000)]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    '> li': {
      minWidth: 'unset',
      maxWidth: 'unset',
    },
  },
  [mediaQuery(1700)]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
  },
  [mediaQuery(1500)]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  [mediaQuery(1200)]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [mediaQuery(900)]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [mediaQuery(540)]: {
    gridTemplateColumns: '1fr',
  },
});

export default PerspectiveWrapper;
