import { useState } from 'react';

const useRowChallengesVisibilityState = (): [
  boolean,
  (challengeId: number, visible: boolean) => void,
] => {
  const [challengesVisibilityState, setChallengesVisibilityState] = useState<
    Record<number, boolean>
  >({});

  const registerChallengeVisibility = (
    challengeId: number,
    visible: boolean,
  ): void => {
    setChallengesVisibilityState(challengesVisibilityState => ({
      ...challengesVisibilityState,
      [challengeId]: visible,
    }));
  };

  const rowHasVisibleChallenges = Object.values(
    challengesVisibilityState,
  ).includes(true);

  return [rowHasVisibleChallenges, registerChallengeVisibility];
};

export default useRowChallengesVisibilityState;
