import { FormattedMessage } from 'react-intl';
import useNumberOfVisibleLevels from './useNumberOfVisibleLevels';
import formatVisibleLevelCountString from './formatVisibleLevelCountString';
import LevelSelectArrowsWrapper from './LevelSelectArrowsWrapper';
import SelectedLevelLabel from './SelectedLevelLabel';
import { IconButton } from '@mui/material';
import {
  ChevronLeft as LeftIcon,
  ChevronRight as RightIcon,
} from '@mui/icons-material';

type LevelSelectArrowsProps = {
  levelCount: number;
  visibleLevelIndex: number;
  onChangeVisibleLevelIndex: (index: number) => void;
};

const LevelSelectArrows = ({
  levelCount,
  visibleLevelIndex,
  onChangeVisibleLevelIndex,
}: LevelSelectArrowsProps) => {
  const numberOfVisibleLevels = useNumberOfVisibleLevels();

  if (levelCount === 0) {
    return null;
  }

  return (
    <LevelSelectArrowsWrapper>
      <IconButton
        size="large"
        onClick={() => onChangeVisibleLevelIndex(visibleLevelIndex - 1)}
        disabled={visibleLevelIndex === 0}
      >
        <LeftIcon />
      </IconButton>
      <SelectedLevelLabel>
        <FormattedMessage
          id="portfolio.level_select_arrows.label"
          values={{
            visibleLevelCount: formatVisibleLevelCountString(
              visibleLevelIndex,
              numberOfVisibleLevels,
            ),
            totalLevelCount: levelCount,
          }}
        />
      </SelectedLevelLabel>
      <IconButton
        size="large"
        onClick={() => onChangeVisibleLevelIndex(visibleLevelIndex + 1)}
        disabled={visibleLevelIndex + numberOfVisibleLevels === levelCount}
      >
        <RightIcon />
      </IconButton>
    </LevelSelectArrowsWrapper>
  );
};

export default LevelSelectArrows;
