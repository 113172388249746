/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react';
import useSliderState from './useSliderState';
import SliderRail from './SliderRail';
import ProgressBars from './ProgressBars';
import SlidableZone from './SlidableZone';

type RubricsSliderProps = {
  studentProgress: number;
  coachProgress: number;
  levelCount: number;
  onSlide: (progress: number) => void;
  onSlideStop: (progress: number) => void;
  readOnly: boolean;
  disabled: boolean;
};

// TODO verify for mobile devices!
const RubricsSlider = ({
  studentProgress,
  coachProgress,
  levelCount,
  onSlide,
  onSlideStop,
  readOnly,
  disabled,
}: RubricsSliderProps) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const {
    sliding,
    progress,
    handleMouseMove,
    handleMouseMoveActivate,
    handleMouseMoveDeactivate,
  } = useSliderState(readOnly || disabled, sliderRef);

  useEffect(() => {
    if (progress !== null) {
      const handleProgressChange = sliding ? onSlide : onSlideStop;
      handleProgressChange(progress);
    }
  }, [sliding, progress]);

  return (
    <SliderRail
      levelCount={levelCount}
      onMouseMoveActivate={handleMouseMoveActivate}
      onMouseMoveDeactivate={handleMouseMoveDeactivate}
      onMouseMove={handleMouseMove}
      readOnly={readOnly}
      disabled={disabled}
      ref={sliderRef}
    >
      <ProgressBars
        studentProgress={studentProgress}
        coachProgress={coachProgress}
        sliding={sliding}
      />
      {!readOnly && !disabled && <SlidableZone />}
    </SliderRail>
  );
};

export default RubricsSlider;
