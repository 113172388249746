import StyledHtml from './StyledHtml';
import escapeHtml from '../escapeHtml';

type HtmlRendererProps = {
  source: string;
  darkText?: boolean;
};

const HtmlRenderer = ({ source, darkText }: HtmlRendererProps) => (
  <StyledHtml
    className="html-field"
    dangerouslySetInnerHTML={{ __html: escapeHtml(source) }}
    darkText={darkText ?? false}
  />
);

export default HtmlRenderer;
