import { useContext } from 'react';
import RubricsPortfolioContext from '@/lib/contexts/RubricsPortfolioContext';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import { RubricProgressObject } from '@/lib/types';

const useProgress = (
  partId: number,
): [RubricProgressObject, (progress: number) => void] => {
  const [portfolio, setPortfolio] = useContext(RubricsPortfolioContext);
  const portfolioMode = useContext(PortfolioModeContext);

  // TODO this gives error, but portfolio.progress should be guaranteed. What?
  const progress = portfolio.progress[partId] ?? {};

  const updateProgress = (newProgress: number): void => {
    setPortfolio(portfolio => {
      if (typeof portfolio.progress[partId] === 'undefined') {
        portfolio.progress[partId] = {};
      }

      if (portfolioMode === PortfolioMode.COACH) {
        portfolio.progress[partId].coach = newProgress;
      } else {
        portfolio.progress[partId].student = newProgress;
      }
    });
  };

  return [progress, updateProgress];
};

export default useProgress;
