import React, { useContext, useState } from 'react';
import FieldContext from '../../FieldContext';
import FormWrapper from './FormWrapper';
import FieldNameInput from './FieldNameInput';
import CancelButton from '@/components/CancelButton';
import { IconButton } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import ModelTypeContext from '../../../../ModelTypeContext';
import ModelsContext from '../../../../../ModelsContext';
import useApi from '@/lib/api/useApi';
import { EditorFieldWithoutRelations } from '@/lib/types';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

type FieldNameEditorProps = {
  onClose: () => void;
};

const FieldNameEditor = ({ onClose }: FieldNameEditorProps) => {
  const api = useApi();

  const modelType = useContext(ModelTypeContext);
  const [, setModels] = useContext(ModelsContext);
  const { field } = useContext(FieldContext);

  const [newFieldName, setNewFieldName, , reenableNewFieldNameSync] =
    useComplexSwrState(field!.name ?? '');
  const [saving, setSaving] = useState(false);

  const saveNameChange = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();

    setSaving(true);

    const updatedField = await api.put<EditorFieldWithoutRelations>(
      `editor/challenge-model/fields/${field!.id}/name`,
      {
        name: newFieldName.trim(),
      },
    );

    setModels(models => {
      models[modelType].fields[field!.id] = {
        ...models[modelType].fields[field!.id],
        ...updatedField,
      };
    });

    setSaving(false);
    onClose();

    reenableNewFieldNameSync();
  };

  const cancelNameChange = (): void => {
    onClose();

    setNewFieldName(field!.name ?? '');
    reenableNewFieldNameSync();
  };

  const intl = useIntl();

  return (
    <FormWrapper onSubmit={saveNameChange}>
      <FieldNameInput
        placeholder={intl.formatMessage({
          id: 'editor.model_editor.field_editor.edit_name.input_placeholder',
        })}
        value={newFieldName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setNewFieldName(e.target.value)
        }
        onEscPress={cancelNameChange}
        autoFocus
      />
      <CancelButton
        visible={newFieldName !== (field!.name ?? '')}
        onClick={cancelNameChange}
        disabled={saving}
      />
      <IconButton edge="end" type="submit" disabled={saving}>
        <CheckIcon />
      </IconButton>
    </FormWrapper>
  );
};

export default FieldNameEditor;
