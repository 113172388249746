import styled from '@emotion/styled';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import { FormattedMessage } from 'react-intl';

const StyledIndicator = styled('div')(
  ({ theme }) => ({
    margin: -12,
    marginBottom: 10,
    padding: '4px 12px',
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
    textTransform: 'uppercase',
  }),
  addHighContrastStyles(({ theme }) => ({
    borderBottom: `2px solid ${theme.palette.divider}`,
  })),
);

const IsNewIndicator = () => (
  <StyledIndicator>
    <FormattedMessage id="portfolio.challenge.coach_feedback.feedback_item.new" />
  </StyledIndicator>
);

export default IsNewIndicator;
