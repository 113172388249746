import React, { useContext } from 'react';
import RowDeletionContext from '../RowDeletionProvider/RowDeletionContext';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import CollapsibleActionMenu from '@/components/CollapsibleActionMenu';
import RowActionButton from '../RowActionButton';
import EditRowNameButton from './EditRowNameButton';
import DeleteRow from '../DeleteRow';
import DeleteRowButton from './DeleteRowButton';
import { Divider } from '@mui/material';
import RowCollapseButton from './RowCollapseButton';
import { Row } from '@/lib/types';

type MobileRowActionsProps = {
  row: Row;
  setRowNameEditing: (rowNameEditing: boolean) => void;
  rowCollapsed: boolean;
  toggleRowCollapsed: () => void;
};

const openProps = { open: false };
const emptyProps = {};

const MobileRowActions = ({
  row,
  setRowNameEditing,
  rowCollapsed,
  toggleRowCollapsed,
}: MobileRowActionsProps) => {
  const { rowDeleting } = useContext(RowDeletionContext);

  const conditionalActionMenuProps = rowDeleting ? openProps : emptyProps;

  // The stopPropagation() call prevents row action menu clicks from propagating
  // to the row header, thereby inadvertently triggering row collapsing
  return (
    <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
      <CollapsibleActionMenu
        ButtonComponent={RowActionButton}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        {...conditionalActionMenuProps}
      >
        <EditRowNameButton onClick={() => setRowNameEditing(true)} />
        <DeleteRow row={row} ButtonComponent={DeleteRowButton} />
        <Divider component="li" />
        <RowCollapseButton
          rowCollapsed={rowCollapsed}
          onClick={toggleRowCollapsed}
        />
      </CollapsibleActionMenu>
    </RestrictedToPortfolioMode>
  );
};

export default MobileRowActions;
