import AuthorWrapper from './AuthorWrapper';
import FeedbackItemUserAvatar from './FeedbackItemUserAvatar';
import AuthorName from './AuthorName';
import { UserSummary } from '@/lib/types';

type FeedbackItemAuthorProps = {
  user: UserSummary;
};

const FeedbackItemAuthor = ({ user }: FeedbackItemAuthorProps) => (
  <AuthorWrapper>
    <FeedbackItemUserAvatar user={user} />
    <AuthorName>{user.name}</AuthorName>
  </AuthorWrapper>
);

export default FeedbackItemAuthor;
