import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

type StyledContentWrapperProps = {
  wideContent: boolean;
};

const StyledContentWrapper = styled('main')<StyledContentWrapperProps>(
  ({ theme }) => ({
    position: 'relative',
    paddingLeft: 148,
    paddingRight: 148,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('all'),
    [mediaQuery(1024)]: {
      paddingLeft: 72,
      paddingRight: 72,
    },
    [mediaQuery(850)]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  }),
  ({ wideContent }) => {
    if (wideContent) {
      return {
        padding: '0 !important',
      };
    }
  },
);

export default StyledContentWrapper;
