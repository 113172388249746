import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import egodactIconDefault from './egodact-icon-default.png';
import egodactIconDarkMode from './egodact-icon-dark-mode.png';

const StyledIcon = styled('img')({
  marginRight: 14,
  width: 32,
  height: 32,
});

const EgodactIcon = () => {
  const theme = useTheme();

  return (
    <StyledIcon
      src={theme.isDark ? egodactIconDarkMode : egodactIconDefault}
      alt=""
    />
  );
};

export default EgodactIcon;
