import { useState, useContext } from 'react';
import UserSelectFieldWrapper from '../../User/UserSelectFieldWrapper';
import RoleSelectWithPlaceholder from './RoleSelectWithPlaceholder';
import { MenuItem, IconButton } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import UsersContext from '../../UsersContext';
import UserRole from '@/lib/constants/UserRole.enum';

type MultipleUsersRoleSelectProps = {
  selectedUsersIds: number[];
};

const MultipleUsersRoleSelect = ({
  selectedUsersIds,
}: MultipleUsersRoleSelectProps) => {
  const api = useApi();

  const [, , setUsers] = useContext(UsersContext);

  const [selectedRole, setSelectedRole] = useState<UserRole | ''>('');
  const [saving, setSaving] = useState(false);

  const saveRoleChanges = async (): Promise<void> => {
    if (selectedRole === '') {
      return;
    }

    setSaving(true);

    await api.put('users/bulk/role', {
      userIds: selectedUsersIds,
      role: selectedRole,
    });

    setUsers(users => {
      for (const user of users) {
        if (selectedUsersIds.includes(user.id)) {
          user.role = selectedRole;
        }
      }
    });

    setSaving(false);
  };

  return (
    <UserSelectFieldWrapper>
      <RoleSelectWithPlaceholder
        value={selectedRole}
        onChange={e => setSelectedRole(e.target.value as UserRole | '')}
      >
        {Object.values(UserRole)
          .filter(role => role !== UserRole.SUPER_ADMIN)
          .map(role => (
            <MenuItem value={role} key={role}>
              <FormattedMessage id={`user_roles.${role.toLowerCase()}`} />
            </MenuItem>
          ))}
      </RoleSelectWithPlaceholder>
      <IconButton
        size="large"
        onClick={saveRoleChanges}
        disabled={selectedRole === '' || saving}
      >
        <CheckIcon />
      </IconButton>
    </UserSelectFieldWrapper>
  );
};

export default MultipleUsersRoleSelect;
