import StyledSupportButton from './StyledSupportButton';
import { HelpOutline as SupportIcon } from '@mui/icons-material';
import Space from '@/components/Space';
import { FormattedMessage } from 'react-intl';
import { FabProps } from '@mui/material';

type SupportButtonProps = Omit<FabProps, 'variant' | 'children'>;

const SupportButton = (props: SupportButtonProps) => (
  <StyledSupportButton variant="extended" {...props}>
    <SupportIcon />
    <Space width={12} />
    <FormattedMessage id="support.button" />
  </StyledSupportButton>
);

export default SupportButton;
