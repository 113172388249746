import { useState } from 'react';
import PhaseSelectWrapper from './PhaseSelectWrapper';
import PhaseSelectField from './PhaseSelectField';
import { MenuItem, IconButton } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import Space from '@/components/Space';
import getPhaseName from '@/lib/utils/getPhaseName';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

type InnerPhaseSelectProps = {
  originalPhase: KanbanPhase;
  onSave: (phase: KanbanPhase) => Promise<void>;
};

const InnerPhaseSelect = ({ originalPhase, onSave }: InnerPhaseSelectProps) => {
  const [selectedPhase, setSelectedPhase, , reenableSelectedPhaseSync] =
    useComplexSwrState(originalPhase);
  const [saving, setSaving] = useState(false);

  const handleSave = async (): Promise<void> => {
    if (selectedPhase === originalPhase) {
      return;
    }

    setSaving(true);

    await onSave(selectedPhase);

    setSaving(false);

    reenableSelectedPhaseSync();
  };

  return (
    <PhaseSelectWrapper>
      <PhaseSelectField
        value={selectedPhase}
        onChange={e => setSelectedPhase(e.target.value as KanbanPhase)}
      >
        {Object.values(KanbanPhase).map(phase => (
          <MenuItem value={phase} key={phase}>
            {getPhaseName(phase)}
          </MenuItem>
        ))}
      </PhaseSelectField>
      {selectedPhase !== originalPhase && (
        <>
          <Space width={6} />
          <IconButton onClick={handleSave} disabled={saving}>
            <CheckIcon />
          </IconButton>
        </>
      )}
    </PhaseSelectWrapper>
  );
};

export default InnerPhaseSelect;
