import { useState } from 'react';
import { Button } from '@mui/material';
import Space from '@/components/Space';
import SettingsDialog from './SettingsDialog';
import { FormattedMessage } from 'react-intl';

const ContentSettings = () => {
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setSettingsDialogOpen(true)}
      >
        <FormattedMessage id="platform_settings.content_settings.button" />
      </Button>
      <Space height={16} />
      <SettingsDialog
        open={settingsDialogOpen}
        onClose={() => setSettingsDialogOpen(false)}
      />
    </>
  );
};

export default ContentSettings;
