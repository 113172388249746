import StyledAddFeedbackButton, {
  StyledAddFeedbackButtonProps,
} from './StyledAddFeedbackButton';
import { AddComment as AddFeedbackIcon } from '@mui/icons-material';
import Space from '@/components/Space';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from '@mui/material';

type AddFeedbackButtonProps = Omit<
  StyledAddFeedbackButtonProps,
  'variant' | 'color' | 'className' | 'children'
>;

const AddFeedbackButton = (props: AddFeedbackButtonProps) => {
  const visible = useMediaQuery('(min-width: 1250px)');

  if (!visible) {
    return null;
  }

  return (
    <StyledAddFeedbackButton
      variant="extended"
      color="primary"
      className="add-feedback"
      {...props}
    >
      <AddFeedbackIcon />
      <Space width={8} />
      <FormattedMessage id="portfolio.challenge.coach_feedback.add.button" />
    </StyledAddFeedbackButton>
  );
};

export default AddFeedbackButton;
