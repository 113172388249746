import styled from '@emotion/styled';

const TitleWrapper = styled('header')({
  padding: 24,
  paddingBottom: 16,
  display: 'flex',
  alignItems: 'center',
});

export default TitleWrapper;
