import { useContext, useState } from 'react';
import Portfolio from './Portfolio';
import PortfolioTemplatesContext from '@/lib/contexts/PortfolioTemplatesContext';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import PortfolioIsReadOnlyContext from '@/lib/contexts/PortfolioIsReadOnlyContext';
import RubricsPortfolioContext from '@/lib/contexts/RubricsPortfolioContext';
import Space from '@/components/Space';
import PortfolioLoadingView from '@/components/PortfolioLoadingView';
import PlatformType from '@/lib/constants/PlatformType.enum';
import useLivePortfolio from './useLivePortfolio';
import GapminderLegend from './GapminderLegend';
import usePortfolioTemplates from './usePortfolioTemplates';
import { RubricsPortfolioSnapshot } from '@/lib/types';

const RubricsPortfolio = () => {
  const portfolioMode = useContext(PortfolioModeContext);
  const [
    portfolioTemplatesLoading,
    portfolioTemplates,
  ] = usePortfolioTemplates();

  const [
    livePortfolioLoading,
    livePortfolio,
    setLivePortfolio,
  ] = useLivePortfolio();
  const [
    selectedPortfolioSnapshot,
    setSelectedPortfolioSnapshot,
  ] = useState<RubricsPortfolioSnapshot | null>(null);

  const portfolioIsLive = selectedPortfolioSnapshot === null;

  const portfolioSnapshotContent = portfolioIsLive
    ? null
    : JSON.parse(selectedPortfolioSnapshot.snapshot);

  const portfolio = portfolioSnapshotContent ?? livePortfolio;

  return (
    <PortfolioTemplatesContext.Provider value={portfolioTemplates!}>
      {!portfolioTemplatesLoading && !livePortfolioLoading ? (
        <>
          <GapminderLegend
            selectedPortfolioSnapshot={selectedPortfolioSnapshot}
            setSelectedPortfolioSnapshot={setSelectedPortfolioSnapshot}
          />
          <PortfolioModeContext.Provider value={portfolioMode}>
            <PortfolioIsReadOnlyContext.Provider value={!portfolioIsLive}>
              <RubricsPortfolioContext.Provider
                value={[portfolio, setLivePortfolio]}
              >
                <Portfolio />
              </RubricsPortfolioContext.Provider>
            </PortfolioIsReadOnlyContext.Provider>
          </PortfolioModeContext.Provider>
        </>
      ) : (
        <PortfolioLoadingView platform={PlatformType.PROGRESSMONITOR} />
      )}
      <Space height={15} />
    </PortfolioTemplatesContext.Provider>
  );
};

export default RubricsPortfolio;
