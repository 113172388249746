import styled from '@emotion/styled';

const InfoWrapper = styled('section')({
  marginLeft: -12,
  marginRight: -12,
  marginBottom: -24,
  display: 'flex',
  flexWrap: 'wrap',
});

export default InfoWrapper;
