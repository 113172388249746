import InnerTabsWrapper from './InnerTabsWrapper';
import { Tabs, Tab } from '@mui/material';
import ModelType from '@/lib/constants/challengeModel/ModelType.enum';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import { FormattedMessage } from 'react-intl';

type SelectedModelTypeTabsProps = {
  selectedModelType: ModelType;
  setSelectedModelType: (selectedModelType: ModelType) => void;
};

const SelectedModelTypeTabs = ({
  selectedModelType,
  setSelectedModelType,
}: SelectedModelTypeTabsProps) => (
  <InnerTabsWrapper variant="outlined">
    <Tabs
      value={selectedModelType}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_, newSelectedModelType) =>
        setSelectedModelType(newSelectedModelType)
      }
    >
      <Tab
        label={
          <FormattedMessageWithChallengeNaming id="editor.model_editor.model_type_tabs.challenge" />
        }
        value={ModelType.CHALLENGE}
      />
      <Tab
        label={
          <FormattedMessage id="editor.model_editor.model_type_tabs.task" />
        }
        value={ModelType.TASK}
      />
    </Tabs>
  </InnerTabsWrapper>
);

export default SelectedModelTypeTabs;
