import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import PerspectivesLoadingView from '@/components/PerspectivesLoadingView';

const StyledPerspectivesLoadingView = styled(PerspectivesLoadingView)({
  paddingTop: 'calc(25vh + 60.5px)',
  paddingBottom: '25vh',
});

const LoadingView = () => (
  <StyledPerspectivesLoadingView
    loadingMessage={
      <FormattedMessage id="perspectives.attachment.loading_message" />
    }
  />
);

export default LoadingView;
