function ensureLinkIsFullUrl(url: null): null;
function ensureLinkIsFullUrl(url: string): string;
function ensureLinkIsFullUrl(url: string | null): string | null {
  if (url === null) {
    return null;
  }

  if (!url || url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }

  return `https://${url}`;
}

export default ensureLinkIsFullUrl;
