import { ForwardedRef, forwardRef } from 'react';
import { MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type NoneMenuItemProps = {
  value: '';
};

const NoneMenuItem = forwardRef(
  ({ value }: NoneMenuItemProps, ref: ForwardedRef<HTMLLIElement>) => (
    <MenuItem value={value} ref={ref}>
      <em>
        <FormattedMessage id="portfolio.challenge.select_field.options.none" />
      </em>
    </MenuItem>
  ),
);

NoneMenuItem.displayName = 'NoneMenuItem';

export default NoneMenuItem;
