import LoginScreenPageTitle from './LoginScreenPageTitle';
import BackgroundSlideshowPlaceholder from './BackgroundSlideshowPlaceholder';
import BackgroundSlideshow from './BackgroundSlideshow';
import LoginCard from './LoginCard';
import PushFooterBelowInitialViewport from './PushFooterBelowInitialViewport';
import SchoolSelector from './SchoolSelector';
import LoginHeader from './LoginHeader';
import LoginContent from './LoginContent';
import SelectedSchoolHeader from './SelectedSchoolHeader';
import useSelectedSchool from './useSelectedSchool';
import usePreselectedSchoolHandlingState from './usePreselectedSchoolHandlingState';

const LoginScreen = () => {
  const [selectedSchool, setSelectedSchool] = useSelectedSchool();
  const [
    hasHandledPreselectedSchool,
    setHasHandledPreselectedSchool,
  ] = usePreselectedSchoolHandlingState();

  return (
    <>
      <LoginScreenPageTitle />
      <BackgroundSlideshowPlaceholder />
      <BackgroundSlideshow />
      <LoginCard>
        <LoginHeader />
        <SchoolSelector
          selectedSchool={selectedSchool}
          onSelectSchool={school => setSelectedSchool(school)}
          hasHandledPreselectedSchool={hasHandledPreselectedSchool}
          setHasHandledPreselectedSchool={setHasHandledPreselectedSchool}
          hidden={selectedSchool !== null}
        />
        {selectedSchool !== null && (
          <>
            <SelectedSchoolHeader
              selectedSchool={selectedSchool}
              onGoBackToSchoolSelection={() => setSelectedSchool(null)}
            />
            <LoginContent selectedSchool={selectedSchool} />
          </>
        )}
      </LoginCard>
      <PushFooterBelowInitialViewport />
    </>
  );
};

export default LoginScreen;
