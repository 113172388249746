import { ObjectWithCreatedAt } from "@/lib/types";

const sortByCreatedAt = (
  a: ObjectWithCreatedAt,
  b: ObjectWithCreatedAt,
): number => {
  return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
};

export default sortByCreatedAt;
