import { alpha, lighten } from '@mui/material';
import {
  common,
  blue,
  grey,
  red,
  orange,
  lightBlue,
  purple,
  indigo,
} from '@mui/material/colors';
import { PaletteOptions } from '@mui/material/styles';

const darkPalette = {
  isHighContrast: false,
  mode: 'dark',
  primary: {
    main: blue[200],
  },
  secondary: {
    main: common.white,
  },
  error: {
    main: red[200],
  },
  warning: {
    main: orange[200],
  },
  danger: red[200],
  background: {
    default: '#303030',
    shaded: grey[900],
    border: grey[800],
    paper: grey[800],
  },
  sliders: {
    student: lightBlue[200],
    coach: purple[300],
    both: indigo[400],
  },
  partFocusColor: orange[200],
  partFocusTextColor: alpha(common.black, 0.87),
  increaseContrastWithBackground: (
    color: string,
    coefficient: number,
  ): string => {
    return lighten(color, coefficient);
  },
} satisfies PaletteOptions;

export default darkPalette;
