import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';

const MobileCoachFeedbackButton = styled(IconButton)({
  position: 'absolute',
  top: -12,
  right: 0,
  display: 'none',
  [mediaQuery(1250)]: {
    display: 'inline-flex',
  },
});

export default MobileCoachFeedbackButton;
