import { useContext } from 'react';
import ChallengePortfolioContext from '../../../ChallengePortfolioContext';
import useApi from '@/lib/api/useApi';
import { RowWithoutRelations } from '@/lib/types';

// TODO complex state updates should be tested properly in react 18 cause of
// automated batching (esp with realtime + state update concurrency)
const useCreateRow = (): ((name: string) => Promise<RowWithoutRelations>) => {
  const api = useApi();

  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);

  const createRow = async (name: string): Promise<RowWithoutRelations> => {
    const row = await api.post<RowWithoutRelations>(
      'challenge-portfolio/rows',
      {
        name,
      },
    );

    setChallengePortfolio(challengePortfolio => {
      challengePortfolio.rows[row.id] = {
        ...row,
        challenges: [],
      };
    });

    return row;
  };

  return createRow;
};

export default useCreateRow;
