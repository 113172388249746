import styled from '@emotion/styled';
import InputBase from '@/components/InputBase';

const StyledUserSearchInput = styled(InputBase)({
  padding: '10px 15px',
  paddingRight: 44,
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  borderRadius: 4,
  boxSizing: 'border-box',
});

export default StyledUserSearchInput;
