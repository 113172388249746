import styled from '@emotion/styled';
import { Button } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';

const StyledCollapseButton = styled(Button)({
  position: 'absolute',
  top: 24,
  right: 0,
  zIndex: 1,
  [mediaQuery(768)]: {
    display: 'none',
  },
});

export default StyledCollapseButton;
