import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const SettingsBoxWrapper = styled('article')(({ theme }) => ({
  maxWidth: '50%',
  flex: 1,
  boxSizing: 'border-box',
  transition: theme.transitions.create(['border']),
  ':first-of-type': {
    marginRight: 36,
    paddingRight: 36,
    borderRight: `2px solid ${theme.palette.background.border}`,
  },
  [mediaQuery(768)]: {
    minWidth: '100%',
    maxWidth: '100%',
    ':first-of-type': {
      marginRight: 0,
      marginBottom: 24,
      paddingRight: 0,
      paddingBottom: 24,
      borderRight: 0,
      borderBottom: `2px solid ${theme.palette.background.border}`,
    },
  },
}));

export default SettingsBoxWrapper;
