import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import { HTMLDivProps } from '@/lib/types';

type ErrorScreenWrapperProps = HTMLDivProps & {
  visible: boolean;
};

const ErrorScreenWrapper = styled('section')<ErrorScreenWrapperProps>(
  ({ visible }) => ({
    paddingLeft: 36,
    height: visible ? 'auto' : 0,
    flex: 1,
    maxWidth: '50%',
    boxSizing: 'border-box',
    [mediaQuery(768)]: {
      paddingLeft: 24,
    },
    [mediaQuery(450)]: {
      paddingLeft: 16,
    },
  }),
);

export default ErrorScreenWrapper;
