import App from '@/lib/App';
import StyledProgressBar from './StyledProgressBar';
import { HTMLDivProps } from '@/lib/types';

type ProgressBarProps = Omit<HTMLDivProps, 'ref'> & {
  progress: number;
  otherProgress: number;
  color: string;
  noTransition: boolean;
};

const ProgressBar = ({
  progress,
  otherProgress,
  ...props
}: ProgressBarProps) => {
  const useReversedSliders =
    App.instanceConfig.useSetting('useReversedSliders');
  const progressEqual = progress === otherProgress;

  return (
    <StyledProgressBar
      progress={progress}
      progressEqual={progressEqual}
      reversed={useReversedSliders}
      onTop={progress <= otherProgress}
      {...props}
    />
  );
};

export default ProgressBar;
