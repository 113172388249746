import ErrorText from './ErrorText';
import AllowedEmailsMessage from '../AllowedEmailsMessage';
import { FormattedMessage } from 'react-intl';

const EmailNotAllowedErrorScreenContent = () => (
  <>
    <h2>
      <FormattedMessage id="login.error_screen.email_not_allowed.title" />
    </h2>
    <ErrorText>
      <AllowedEmailsMessage translationsGroupId="login.error_screen.email_not_allowed.allowed_emails_text" />
    </ErrorText>
  </>
);

export default EmailNotAllowedErrorScreenContent;
