import styled from '@emotion/styled';
import getResponsiveStyles from './getResponsiveStyles';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

type StyledSliderRailProps = {
  levelCount: number;
  readOnly: boolean;
  disabled: boolean;
};

const StyledSliderRail = styled('div')<StyledSliderRailProps>(
  ({ theme, levelCount, readOnly, disabled }) => ({
    position: 'relative',
    marginBottom: 50,
    height: 14,
    backgroundColor: theme.palette.action.selected,
    boxSizing: 'border-box',
    transition: theme.transitions.create('all'),
    borderRadius: 4,
    cursor: readOnly || disabled ? 'auto' : 'pointer',
    opacity: disabled ? 0.7 : 1,
    ...getResponsiveStyles(levelCount),
  }),
  addHighContrastStyles(({ theme }) => ({
    height: 16,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
  })),
);

export default StyledSliderRail;
