import React, { useContext, useState } from 'react';
import GroupFormWrapper from './GroupFormWrapper';
import { TextField, IconButton } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import GroupsContext from '../../GroupsContext';
import { Group } from '@/lib/types';

const NewGroupForm = () => {
  const api = useApi();

  const [, , setGroups] = useContext(GroupsContext);

  const [groupName, setGroupName] = useState('');
  const [saving, setSaving] = useState(false);

  const submitForm = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    setSaving(true);

    const group = await api.post<Group>('groups', {
      name: groupName.trim(),
    });

    setGroups(groups => {
      groups.push(group);
    });

    setSaving(false);

    setGroupName('');
  };

  return (
    <GroupFormWrapper>
      <TextField
        variant="standard"
        label={<FormattedMessage id="group_manager.group_form.input_label" />}
        value={groupName}
        onChange={e => setGroupName(e.target.value)}
        fullWidth
      />
      <IconButton
        type="submit"
        size="large"
        onClick={submitForm}
        disabled={groupName.trim().length === 0 || saving}
      >
        <CheckIcon />
      </IconButton>
    </GroupFormWrapper>
  );
};

export default NewGroupForm;
