import styled from '@emotion/styled';
import { Fab, FabProps } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';
import isPropValid from '@emotion/is-prop-valid';

type FloatingUserSwitchArrowProps = FabProps & {
  right?: boolean;
};

const FloatingUserSwitchArrow = styled(Fab, {
  shouldForwardProp: isPropValid,
})<FloatingUserSwitchArrowProps>(({ right }) => ({
  position: 'fixed',
  top: '50%',
  left: right ? 'unset' : 30,
  right: right ? 30 : 'unset',
  transform: 'translateY(-50%)',
  [mediaQuery(1024)]: {
    display: 'none',
  },
}));

export default FloatingUserSwitchArrow;
