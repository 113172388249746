import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const UserInfoWrapper = styled('section')({
  [mediaQuery(700)]: {
    flexBasis: '100%',
    textAlign: 'center',
  },
});

export default UserInfoWrapper;
