import styled from '@emotion/styled';

const FixSmallScreenSublevelTreeHeaderSize = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  '.sublevel-tree__small-screen-header': {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

export default FixSmallScreenSublevelTreeHeaderSize;
