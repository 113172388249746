import { School } from '@/lib/types';
import { createContext } from 'react';

const SchoolContext = createContext<[boolean, School]>([
  true,
  // TODO
  (null as unknown) as School,
]);

export default SchoolContext;
