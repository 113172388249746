import DangerColorThemeProvider from '@/components/DangerColorThemeProvider';
import { CircularProgress, CircularProgressProps } from '@mui/material';

type DangerColorCircularProgressProps = Omit<
  CircularProgressProps,
  'color' | 'size'
>;

const DangerColorCircularProgress = (
  props: DangerColorCircularProgressProps,
) => (
  <DangerColorThemeProvider>
    <CircularProgress color="primary" size={40} {...props} />
  </DangerColorThemeProvider>
);

export default DangerColorCircularProgress;
