import { ButtonProps, useMediaQuery } from '@mui/material';
import StyledButton from './StyledButton';
import React from 'react';

export type DialogButtonProps = Omit<ButtonProps, 'children'> & {
  children?: React.ReactNode;
};

const DialogButton = (props: DialogButtonProps) => {
  const shouldUseMobileVariant = useMediaQuery('(max-width: 640px)');

  return (
    <StyledButton
      variant="contained"
      fullWidth={shouldUseMobileVariant}
      disableElevation
      {...props}
    />
  );
};

export default DialogButton;
