import StyledPageTitle from './StyledPageTitle';
import { FormattedMessage } from 'react-intl';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';

const WelcomeMessage = () => {
  const authenticatedUser = useAuthenticatedUser()!;

  return (
    <StyledPageTitle>
      <FormattedMessage
        id="header.welcome_message"
        values={{
          firstName: authenticatedUser.firstName,
        }}
      />
    </StyledPageTitle>
  );
};

export default WelcomeMessage;
