import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import { createContext } from 'react';

// TODO should be nullable, because it is for newly shared challenges (not
// their tasks though)
const PhaseContext = createContext<KanbanPhase>(
  (null as unknown) as KanbanPhase,
);

export default PhaseContext;
