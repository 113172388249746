import { common } from '@mui/material/colors';
import { ThemeOptions, alpha } from '@mui/material';
import { Palette } from '@mui/material/styles';

const getHighContrastModeThemeAdjustments = (
  palette: Palette,
): ThemeOptions => {
  if (!palette.isHighContrast) {
    return {};
  }

  const highContrastStyleOverrides = {
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: `2px solid ${palette.divider}`,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: palette.background.default,
          backgroundColor: palette.primary.main,
          border: `2px solid ${palette.background.default}`,
        },
        arrow: {
          color: palette.divider,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&::before': {
            borderColor: palette.divider,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          border: `1px solid ${palette.divider}`,
          borderBottom: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: palette.divider,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: palette.text.primary,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderColor: palette.divider,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover, &:focus': {
            textDecoration: 'underline !important',
          },
          '&$disabled': {
            display: 'none',
          },
          '&$selected': {
            backgroundColor: palette.background.default,
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: `2px solid ${palette.divider}`,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderLeft: `2px solid ${palette.divider}`,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: palette.text.primary,
        },
        wave: {
          '&::after': {
            background: `linear-gradient(to right, transparent, ${alpha(
              palette.background.default,
              0.4,
            )}, transparent)`,
          },
        },
      },
    },
    MuiIconButton: {
      // TODO
      styleOverrides: {
        root: {
          '&$disabled': {
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
          },
        },
      },
    },
  };

  if (palette.mode !== 'dark') {
    return {
      components: {
        ...highContrastStyleOverrides,
        MuiIconButton: {
          styleOverrides: {
            root: {
              color: common.black,
            },
          },
        },
      },
    };
  }

  return {
    components: highContrastStyleOverrides,
  };
};

export default getHighContrastModeThemeAdjustments;
