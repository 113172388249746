import device from 'current-device';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import PhaseSelect from './PhaseSelect';

// TODO verify this works properly for newly shared challenges
// This is necessary because dragging cards doesn't work on mobile devices
const MobilePhaseSelect = () => {
  if (device.desktop()) {
    return null;
  }

  return (
    <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
      <PhaseSelect />
    </RestrictedToPortfolioMode>
  );
};

export default MobilePhaseSelect;
