import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

const MoveButton = styled(IconButton)({
  padding: 6,
  width: 36,
  height: 36,
  '.MuiSvgIcon-root': {
    width: 16,
    height: 16,
  },
});

export default MoveButton;
