import useApi from '@/lib/api/useApi';
import { useState } from 'react';
import PerspectiveCategorization from './PerspectiveCategorization';
import FullscreenPerspectiveWithSublevelsTree from './FullscreenPerspectiveWithSublevelsTree';
import PerspectivePortfolioContext from '@/components/perspectives/PerspectivePortfolioContext';
import PerspectiveSublevelPortfolioContext from '@/components/perspectives/PerspectiveSublevelPortfolioContext';
import useBuildPerspectiveSublevelAttachmentMap from '@/components/perspectives/PerspectiveWithSublevelsTree/useBuildPerspectiveSublevelAttachmentMap';
import useGlobalStaticApiResponse from '@/lib/api/useGlobalStaticApiResponse';
import { FormattedMessage } from 'react-intl';
import usePerspectiveEndpointsWithCoachScoping from '@/components/perspectives/usePerspectiveEndpointsWithCoachScoping';
import FixSmallScreenSublevelTreeHeaderSize from './FixSmallScreenSublevelTreeHeaderSize';
import { Updater } from 'use-immer';
import {
  PerspectivePortfolio,
  PerspectiveWithSublevelTree,
  PerspectiveWithSublevelsInfo,
  RawPerspectiveSublevelPortfolio,
} from '@/lib/types';
import PerspectiveCategory from '@/lib/constants/PerspectiveCategory.enum';
import PerspectivesLoadingView from '@/components/PerspectivesLoadingView';
import useComplexState from '@/lib/utils/useComplexState';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

const PerspectiveCategorizationView = () => {
  const perspectiveEndpoints = usePerspectiveEndpointsWithCoachScoping();

  const [perspectivesLoading, perspectives] = useGlobalStaticApiResponse<
    PerspectiveWithSublevelsInfo[]
  >(perspectiveEndpoints.perspectives);
  const [
    perspectiveCategoriesLoading,
    perspectiveCategories,
    setPerspectiveCategories,
  ] = usePolledSwrAsComplexState<Record<number, PerspectiveCategory>>(
    perspectiveEndpoints.perspectiveCategories,
  );
  const [
    perspectivePortfolioLoading,
    perspectivePortfolio,
    setPerspectivePortfolio,
  ] = usePolledSwrAsComplexState<PerspectivePortfolio>(
    perspectiveEndpoints.perspectivePortfolio,
  );

  const api = useApi();
  const [openPerspective, setOpenPerspective] =
    useState<PerspectiveWithSublevelTree | null>(null);
  const [perspectiveSublevelPortfolio, setPerspectiveSublevelPortfolio] =
    useComplexState<RawPerspectiveSublevelPortfolio | null>(null);

  const perspectiveSublevelPortfolioAsMap =
    useBuildPerspectiveSublevelAttachmentMap(
      openPerspective,
      perspectiveSublevelPortfolio,
    );

  if (
    perspectivesLoading ||
    perspectiveCategoriesLoading ||
    perspectivePortfolioLoading
  ) {
    return (
      <PerspectivesLoadingView
        loadingMessage={
          <FormattedMessage id="perspectives.categorization.loading_message" />
        }
      />
    );
  }

  const handleUpdatePerspectiveCategory = (
    perspectiveId: number,
    category: PerspectiveCategory,
  ): void => {
    setPerspectiveCategories(perspectiveCategories => {
      perspectiveCategories[perspectiveId] = category;
    });
  };

  const handleOpenPerspective = async (
    perspectiveId: number,
  ): Promise<void> => {
    const [perspective, perspectiveSublevelPortfolio] = await Promise.all([
      api.getGlobalStatic<PerspectiveWithSublevelTree>(
        perspectiveEndpoints.specificPerspective(perspectiveId),
      ),
      api.get<RawPerspectiveSublevelPortfolio>(
        perspectiveEndpoints.perspectiveSpecificPortfolio(perspectiveId),
      ),
    ]);

    setPerspectiveSublevelPortfolio(perspectiveSublevelPortfolio);
    setOpenPerspective(perspective);
  };

  return (
    <PerspectivePortfolioContext.Provider
      value={[perspectivePortfolio, setPerspectivePortfolio]}
    >
      {openPerspective !== null ? (
        <PerspectiveSublevelPortfolioContext.Provider
          value={[
            perspectiveSublevelPortfolioAsMap,
            setPerspectiveSublevelPortfolio as Updater<RawPerspectiveSublevelPortfolio>,
          ]}
        >
          <FixSmallScreenSublevelTreeHeaderSize>
            <FullscreenPerspectiveWithSublevelsTree
              perspective={openPerspective}
              onClose={() => setOpenPerspective(null)}
            />
          </FixSmallScreenSublevelTreeHeaderSize>
        </PerspectiveSublevelPortfolioContext.Provider>
      ) : (
        <PerspectiveCategorization
          perspectives={perspectives}
          perspectiveCategories={perspectiveCategories}
          onUpdatePerspectiveCategory={handleUpdatePerspectiveCategory}
          onOpenPerspective={handleOpenPerspective}
        />
      )}
    </PerspectivePortfolioContext.Provider>
  );
};

export default PerspectiveCategorizationView;
