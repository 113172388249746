import styled from '@emotion/styled';
import stripProps from '@/lib/utils/stripProps';
import React from 'react';

type ActionsWrapperProps = {
  primaryButtons: boolean;
  disabled: boolean;
  children: React.ReactNode;
};

const ActionsWrapper = styled(
  stripProps('footer', 'primaryButtons', 'disabled'),
)<ActionsWrapperProps>(({ theme, primaryButtons, disabled }) => {
  if (primaryButtons && !disabled) {
    return {
      '.MuiIconButton-root': {
        color: theme.palette.text.primary,
      },
    };
  }
});

export default ActionsWrapper;
