/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import RowDeletionContext from '../../RowDeletionContext';
import useInterval from 'use-interval';
import DangerColorCircularProgress from './DangerColorCircularProgress';

type DeletionCountdownIndicatorProps = {
  deletionProgress: number;
  setDeletionProgress: (deletionProgress: number) => void;
};

const DeletionCountdownIndicator = ({
  deletionProgress,
  setDeletionProgress,
}: DeletionCountdownIndicatorProps) => {
  const { deleteRow, rowDeleting } = useContext(RowDeletionContext);

  useInterval(() => {
    if (rowDeleting && deletionProgress < 110) {
      setDeletionProgress(deletionProgress + 5);
    }
  }, 500);

  useEffect(() => {
    if (!rowDeleting) {
      setDeletionProgress(0);
    }
  }, [rowDeleting]);

  const [rowDeletionFinished, setRowDeletionFinished] = useState(false);

  if (!rowDeletionFinished && rowDeleting && deletionProgress === 110) {
    deleteRow();
    setRowDeletionFinished(true);
  }

  return (
    <DangerColorCircularProgress
      variant="determinate"
      value={Math.min(100, deletionProgress)}
    />
  );
};

export default DeletionCountdownIndicator;
