import { useContext } from 'react';
import PhaseContext from '../../../PhaseContext';
import PhaseContentGroupContext from '../../../PhaseContentGroupContext';
import FieldContext from '../../FieldContext';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';

const usePossibleMovesState = (): [boolean, boolean] => {
  const { previousPhase, nextPhase } = useContext(PhaseContext);
  const currentPhaseContentGroup = useContext(PhaseContentGroupContext);
  const { previousField, nextField } = useContext(FieldContext);

  const moveUpImpossible =
    currentPhaseContentGroup !== ContentGroupType.BOTTOM &&
    !previousPhase &&
    !previousField;

  const moveDownImpossible =
    currentPhaseContentGroup === ContentGroupType.BOTTOM &&
    !nextPhase &&
    !nextField;

  const canMoveUp = !moveUpImpossible;
  const canMoveDown = !moveDownImpossible;

  return [canMoveUp, canMoveDown];
};

export default usePossibleMovesState;
