import StyledInfoField from './StyledInfoField';
import StyledWorldImage from './StyledWorldImage';
import WorldName from './WorldName';
import WorldInfo from './WorldInfo';
import { AgoraWorld } from '../../worlds';
import { FormattedMessage } from 'react-intl';

type InfoFieldProps = {
  world: AgoraWorld;
};

const InfoField = ({ world }: InfoFieldProps) => (
  <StyledInfoField>
    <StyledWorldImage world={world} />
    <WorldName>
      <FormattedMessage
        id={`portfolio.challenge.world_select.world_labels.${world}_world`}
      />
    </WorldName>
    <WorldInfo>
      <FormattedMessage
        id={`portfolio.challenge.world_select.world_info.${world}_world`}
      />
    </WorldInfo>
  </StyledInfoField>
);

export default InfoField;
