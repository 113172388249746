import { useContext } from 'react';
import SelectedSchoolContext from '@/components/App/AppSetup/SelectedSchool/SelectedSchoolContext';
import { InstanceConfig } from '@/lib/types';
import IdentityConnectionProvider from '@/lib/constants/IdentityConnectionProvider.enum';

const defaultInstanceConfig = {
  allowedEmails: [],
  authProvider: IdentityConnectionProvider.GOOGLE,
  enablePerspectiveModule: false,
  useReversedSliders: true,
  enableUserSwitcherArrows: false,
  useProfilePictures: true,
  enableAggressiveRubricCollapsing: false,
  customChallengeNaming: {
    enabled: false,
  },
} satisfies InstanceConfig;

const useSettings = (): InstanceConfig => {
  const [selectedSchool] = useContext(SelectedSchoolContext);

  return selectedSchool?.instanceConfig ?? defaultInstanceConfig;
};

export default useSettings;
