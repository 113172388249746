import { useContext, useState } from 'react';
import PortfolioItemWithGroupsPrompt from '../PortfolioItemWithGroupsPrompt';
import Dialog from '@/components/Dialog';
import EditButton from '../PortfolioTemplatesEditorActionButtons/EditButton';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import {
  EditorSubtopic,
  EditorSubtopicWithoutRelations,
  MinimumRole,
} from '@/lib/types';
import EditorPortfolioTemplatesContext from '../../EditorPortfolioTemplatesContext';
import PortfolioItemWithGroupsPromptVariant from '../PortfolioItemWithGroupsPrompt/PortfolioItemWithGroupsPromptVariant.enum';

type EditSubtopicProps = {
  subtopic: EditorSubtopic;
};

const EditSubtopic = ({ subtopic }: EditSubtopicProps) => {
  const api = useApi();

  const [, setPortfolioTemplates] = useContext(EditorPortfolioTemplatesContext);

  const [editPromptOpen, setEditPromptOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveEdits = async (
    name: string,
    minimumRole: MinimumRole,
    groups: number[],
  ): Promise<void> => {
    setEditPromptOpen(false);
    setSaving(true);

    const subtopicData = {
      name,
      position: subtopic.position,
      minimumRole,
      groups,
    };

    const updatedSubtopic = await api.put<EditorSubtopicWithoutRelations>(
      `editor/portfolio-templates/subtopics/${subtopic.id}`,
      subtopicData,
    );

    setPortfolioTemplates(portfolioTemplates => {
      portfolioTemplates.subtopics[subtopic.id] = {
        ...portfolioTemplates.subtopics[subtopic.id],
        ...updatedSubtopic,
        groups: groups.map(id => ({ id })),
      };
    });

    setSaving(false);
  };

  return (
    <>
      <EditButton onClick={() => setEditPromptOpen(true)} disabled={saving} />
      <PortfolioItemWithGroupsPrompt
        variant={PortfolioItemWithGroupsPromptVariant.SUBTOPIC}
        label={
          <FormattedMessage id="portfolio_template_editor.subtopic.edit.input_label" />
        }
        open={editPromptOpen}
        defaultValue={subtopic.name}
        selectedMinimumRole={subtopic.minimumRole}
        selectedGroups={subtopic.groups.map(({ id }) => id)}
        onOk={saveEdits}
        onCancel={() => setEditPromptOpen(false)}
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio_template_editor.subtopic.edit.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio_template_editor.subtopic.edit.text" />
          </Dialog.ContentText>
        </Dialog.Content>
      </PortfolioItemWithGroupsPrompt>
    </>
  );
};

export default EditSubtopic;
