import styled from '@emotion/styled';

const ActionsWrapper = styled('footer')({
  marginTop: -10,
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 10,
  display: 'flex',
  justifyContent: 'flex-end',
});

export default ActionsWrapper;
