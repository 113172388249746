import PageOverlay from './PageOverlay';
import CloseButton from './CloseButton';
import React from 'react';

type ClosablePageOverlayProps = {
  taskBoardOpen: boolean;
  onClose: (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>,
  ) => void;
};

const ClosablePageOverlay = ({
  taskBoardOpen,
  onClose,
}: ClosablePageOverlayProps) => (
  <PageOverlay onClick={onClose}>
    <CloseButton taskBoardOpen={taskBoardOpen} onClick={onClose} />
  </PageOverlay>
);

export default ClosablePageOverlay;
