import styled from '@emotion/styled';

type StyledSubtopicProps = {
  editor?: boolean;
  dragging?: boolean;
};

const StyledSubtopic = styled('div')<StyledSubtopicProps>(
  {
    marginBottom: 42,
    width: '100%',
    ':last-of-type': {
      marginBottom: 0,
    },
  },
  ({ theme, editor }) => {
    if (editor) {
      return {
        transition: theme.transitions.create('all'),
        cursor: 'move',
      };
    }
  },
  ({ editor, dragging }) => {
    if (editor && dragging) {
      return {
        opacity: 0.5,
      };
    }
  },
);

export default StyledSubtopic;
