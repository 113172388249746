import { useContext } from 'react';
import VisibleWeekRangeContext from '../../VisibleWeekRangeContext';
import ControlsWrapper from './ControlsWrapper';
import { Tooltip, IconButton } from '@mui/material';
import {
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon,
} from '@mui/icons-material';
import YearIndicator from './YearIndicator';
import { FormattedMessage } from 'react-intl';

const VisibleWeekRangeControls = () => {
  const {
    visibleWeeksCount,
    shiftVisibleWeekRangeToLeft,
    shiftVisibleWeekRangeToRight,
  } = useContext(VisibleWeekRangeContext);

  const usePluralTooltips = visibleWeeksCount > 1;

  return (
    <ControlsWrapper>
      <Tooltip
        title={
          <FormattedMessage
            id={`portfolio.challenge_planning_view.header.visible_week_range_controls.view_previous.${
              usePluralTooltips ? 'plural' : 'singular'
            }`}
          />
        }
      >
        <IconButton onClick={shiftVisibleWeekRangeToLeft}>
          <ArrowLeftIcon />
        </IconButton>
      </Tooltip>
      <YearIndicator />
      <Tooltip
        title={
          <FormattedMessage
            id={`portfolio.challenge_planning_view.header.visible_week_range_controls.view_next.${
              usePluralTooltips ? 'plural' : 'singular'
            }`}
          />
        }
      >
        <IconButton onClick={shiftVisibleWeekRangeToRight}>
          <ArrowRightIcon />
        </IconButton>
      </Tooltip>
    </ControlsWrapper>
  );
};

export default VisibleWeekRangeControls;
