import { TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import dummyFunction from '@/lib/utils/dummyFunction';
import React from 'react';

type LinkTextFieldProps = {
  link: string;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const LinkTextField = ({ link, onClick }: LinkTextFieldProps) => (
  <TextField
    id="shareable-link"
    variant="filled"
    label={
      <FormattedMessage id="portfolio.challenge.link_sharing.input_label" />
    }
    InputProps={{
      readOnly: true,
    }}
    value={link}
    onChange={dummyFunction}
    onClick={onClick}
    fullWidth
    autoFocus
  />
);

export default LinkTextField;
