import styled from '@emotion/styled';
import { common } from '@mui/material/colors';

const UploadLabel = styled('h4')(({ theme }) => ({
  position: 'relative',
  margin: 0,
  padding: theme.palette.isHighContrast ? 6 : undefined,
  color: common.white,
  backgroundColor: theme.palette.isHighContrast ? common.black : undefined,
  fontWeight: 400,
  textAlign: 'center',
  fontFamily: 'CircularStd',
  zIndex: 1,
}));

export default UploadLabel;
