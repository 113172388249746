import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useUrlQueryParam = (key: string): string | null => {
  const { search } = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  return queryParams.get(key);
};

export default useUrlQueryParam;
