import { useState } from 'react';
import GlobalStateProvider from '@/components/App/GlobalStateProvider';
import AppSetup from '@/components/App/AppSetup';
import AppWrapper from '@/components/App/AppWrapper';
import PageTitleTemplate from '@/components/App/PageTitleTemplate';
import OutdatedBrowserDetection from '@/components/App/OutdatedBrowserDetection';
import AppHeader from '@/components/App/AppHeader';
import AppContentWrapper from '@/components/App/AppContentWrapper';
import SupportSystem from '@/components/App/SupportSystem';
import AppFooter from '@/components/App/AppFooter';
import AppContentRenderer from './AppContentRenderer';
import { User } from './lib/types';

const App = () => {
  const [challengeSearchQuery, setChallengeSearchQuery] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  return (
    <GlobalStateProvider
      challengeSearchQuery={challengeSearchQuery}
      selectedUser={selectedUser}
    >
      <AppSetup>
        <AppWrapper>
          <PageTitleTemplate />
          <OutdatedBrowserDetection />
          <AppHeader
            setChallengeSearchQuery={setChallengeSearchQuery}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
          <SupportSystem />
          <AppContentWrapper>
            <AppContentRenderer />
          </AppContentWrapper>
          <AppFooter />
        </AppWrapper>
      </AppSetup>
    </GlobalStateProvider>
  );
};

export default App;
