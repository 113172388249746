import { useContext, useState } from 'react';
import { useMediaQuery, List, Divider } from '@mui/material';
import ChallengeActionsButton from './ChallengeActionsButton';
import EditButton from './ActionButtons/EditButton';
import CopyButton from './ActionButtons/CopyButton';
import LinkSharingButton from './ActionButtons/LinkSharingButton';
import DeleteButton from './ActionButtons/DeleteButton';
import CopyChallenge from '../CopyChallenge';
import ChallengeLinkSharing from '../ChallengeLinkSharing';
import DeleteChallenge from '../DeleteChallenge';
import RowContext from '@/components/ChallengePortfolio/NormalView/Rows/Row/RowContext';
import MobileActionsDrawer from './MobileActionsDrawer';

type MobileChallengeActionsProps = {
  onEdit: () => void;
};

// TODO rename onEdit to onNameEdit (and editbutton to editnamebutton?
// maybe also in normal challengeheader? also for taskheader/MobileTaskActions)
const MobileChallengeActions = ({ onEdit }: MobileChallengeActionsProps) => {
  const row = useContext(RowContext);

  const [open, setOpen] = useState(false);
  const usesMobileChallengeActions = useMediaQuery('(max-width: 640px)');

  const actuallyOpen = open && usesMobileChallengeActions;

  return (
    <>
      <ChallengeActionsButton onClick={() => setOpen(true)} />
      <MobileActionsDrawer open={actuallyOpen} onClose={() => setOpen(false)}>
        <List>
          <EditButton onClick={onEdit} />
          {row && <CopyChallenge ButtonComponent={CopyButton} />}
          <ChallengeLinkSharing ButtonComponent={LinkSharingButton} />
        </List>
        <Divider />
        <List>
          <DeleteChallenge ButtonComponent={DeleteButton} />
        </List>
      </MobileActionsDrawer>
    </>
  );
};

export default MobileChallengeActions;
