import './fonts/circular-std.css';
import 'react-app-polyfill/stable';
import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import App from './App';
import './css/base-styles.css';
import * as Sentry from '@sentry/react';
import ApiAuthLocalStorage from './lib/api/ApiAuthLocalStorage';
import './polyfill';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: import.meta.env.MODE === 'production' ? 0.1 : 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/egodact\.com/],
});

ApiAuthLocalStorage.polluteLocalStorage();

const root = createRoot(document.getElementById('app')!);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
