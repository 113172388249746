import styled from '@emotion/styled';
import DangerousIconButton from '@/components/DangerousIconButton';
import { Delete as DeleteIcon } from '@mui/icons-material';
import mediaQuery from '@/lib/styling/mediaQuery';
import { IconButtonProps } from '@mui/material';

type DeleteButtonProps = Omit<
  IconButtonProps,
  'size' | 'disabled' | 'children'
> & {
  disabled?: boolean;
};

const StyledDeleteButton = styled(DangerousIconButton)({
  [mediaQuery(640)]: {
    display: 'none',
  },
});

const DeleteButton = ({ disabled, ...props }: DeleteButtonProps) => (
  <StyledDeleteButton size="large" disabled={disabled} {...props}>
    <DeleteIcon />
  </StyledDeleteButton>
);

export default DeleteButton;
