import LoginScreen from './LoginScreen';
import NotFoundPage from '@/pages/NotFoundPage';
import UserRole from '@/lib/constants/UserRole.enum';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import React from 'react';

type RequireAuthenticationProps = {
  restrictedToRoles?: UserRole[];
  children: React.ReactNode;
};

const RequireAuthentication = ({
  children,
  restrictedToRoles: allowedRoles,
}: RequireAuthenticationProps) => {
  const authenticatedUser = useAuthenticatedUser();

  if (authenticatedUser === null) {
    return <LoginScreen />;
  }

  if (typeof allowedRoles === 'undefined') {
    return children;
  }

  const hasAccess = allowedRoles!.includes(authenticatedUser?.role);

  if (!hasAccess) {
    return <NotFoundPage />;
  }

  return children;
};

export default RequireAuthentication;
