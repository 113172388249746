import React, { useContext, useState } from 'react';
import useApi from '@/lib/api/useApi';
import { Checkbox, FormControlLabel } from '@mui/material';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import TaskContext from '../../../TaskContext';
import { TaskWithoutRelations } from '@/lib/types';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

const IsPublicToggle = () => {
  const api = useApi();

  const [task, setTask] = useContext(TaskContext);

  const [isPublic, setIsPublic, , reenableIsPublicSync] = useComplexSwrState(
    task.isPublic,
  );
  const [saving, setSaving] = useState(false);

  const handleToggle = async (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): Promise<void> => {
    setIsPublic(checked);

    setSaving(true);

    const updatedTask = await api.put<TaskWithoutRelations>(
      `challenge-portfolio/tasks/${task.id}/is-public`,
      {
        isPublic: checked,
      },
    );

    setTask(task => {
      task.isPublic = updatedTask.isPublic;
    });

    setSaving(false);

    reenableIsPublicSync();
  };

  return (
    <section>
      <FormControlLabel
        control={
          <Checkbox
            checked={isPublic}
            onChange={handleToggle}
            name="is-public"
            disabled={saving}
          />
        }
        label={
          <FormattedMessageWithChallengeNaming id="portfolio.challenge.link_sharing.is_public_toggle.label.as_task" />
        }
      />
    </section>
  );
};

export default IsPublicToggle;
