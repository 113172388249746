import DialogContentWrapper from '../DialogContentWrapper';
import Dialog from '@/components/Dialog';
import SupportTilesWrapper from './SupportTilesWrapper';
import SupportType from '../SupportType.enum';
import SupportTile from './SupportTile';
import SupportTileTitle from './SupportTile/SupportTileTitle';
import SupportTileIcon from './SupportTile/SupportTileIcon';
import {
  ContactSupportOutlined as ContactIcon,
  CommentOutlined as FeedbackIcon,
  ReportProblemOutlined as ReportProblemIcon,
} from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

type SupportTypeSelectorProps = {
  onSelectSupportType: (supportType: SupportType) => void;
};

const SupportTypeSelector = ({
  onSelectSupportType,
}: SupportTypeSelectorProps) => (
  <DialogContentWrapper>
    <Dialog.Title>
      <FormattedMessage id="support.dialog.support_type_selector.title" />
    </Dialog.Title>
    <Dialog.Content>
      <SupportTilesWrapper>
        <SupportTile onClick={() => onSelectSupportType(SupportType.CONTACT)}>
          <SupportTileIcon icon={ContactIcon} />
          <SupportTileTitle>
            <FormattedMessage id="support.dialog.support_type_selector.contact_title" />
          </SupportTileTitle>
        </SupportTile>
        <SupportTile onClick={() => onSelectSupportType(SupportType.FEEDBACK)}>
          <SupportTileIcon icon={FeedbackIcon} />
          <SupportTileTitle>
            <FormattedMessage id="support.dialog.support_type_selector.feedback_title" />
          </SupportTileTitle>
        </SupportTile>
        <SupportTile
          onClick={() => onSelectSupportType(SupportType.ISSUE_REPORTING)}
        >
          <SupportTileIcon icon={ReportProblemIcon} />
          <SupportTileTitle>
            <FormattedMessage id="support.dialog.support_type_selector.issue_reporting_title" />
          </SupportTileTitle>
        </SupportTile>
      </SupportTilesWrapper>
    </Dialog.Content>
  </DialogContentWrapper>
);

export default SupportTypeSelector;
