import styled from '@emotion/styled';

type MenuContentPanelProps = {
  invisible?: boolean;
};

const MenuContentPanel = styled('section')<MenuContentPanelProps>(
  {
    width: '50%',
  },
  ({ invisible }) => {
    if (invisible) {
      return {
        height: 0,
        overflowY: 'hidden',
      };
    }
  },
);

export default MenuContentPanel;
