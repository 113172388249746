import NameWrapper from './NameWrapper';
import OverflowIndicator from './OverflowIndicator';
import NameOverflowWrapper from './NameOverflowWrapper';
import React from 'react';

type ChallengeNameProps = {
  contentVisibilityCompensationDistance: number;
  contentMaxVisibleWidth: number;
  children: React.ReactNode;
};

const ChallengeName = ({
  contentVisibilityCompensationDistance,
  contentMaxVisibleWidth,
  children,
}: ChallengeNameProps) => {
  const challengeOverflowsOnLeft = contentVisibilityCompensationDistance > 0;
  const challengeOverflowsOnRight =
    contentMaxVisibleWidth < 100 - contentVisibilityCompensationDistance;

  return (
    <NameWrapper
      contentVisibilityCompensationDistance={
        contentVisibilityCompensationDistance
      }
      contentMaxVisibleWidth={contentMaxVisibleWidth}
    >
      {challengeOverflowsOnLeft && <OverflowIndicator side="left" />}
      <NameOverflowWrapper>{children}</NameOverflowWrapper>
      {challengeOverflowsOnRight && <OverflowIndicator side="right" />}
    </NameWrapper>
  );
};

export default ChallengeName;
