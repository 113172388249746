import styled from '@emotion/styled';
import { Button, ButtonProps } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';
import { grey } from '@mui/material/colors';

const StyledButton = styled(Button)<ButtonProps>(
  {
    marginLeft: 8,
    ':first-of-type': {
      marginLeft: 0,
    },
    [mediaQuery(640)]: {
      marginBottom: 12,
      marginLeft: 0,
      lineHeight: '36px',
      ':last-of-type': {
        marginBottom: 0,
      },
    },
  },
  ({ variant, color, theme }) => {
    if (variant !== 'contained' || typeof color !== 'undefined') {
      return;
    }

    return {
      color: theme.palette.getContrastText(grey[300]),
      backgroundColor: grey[300],
      ':hover': {
        backgroundColor: grey[400],
      },
    };
  },
);

export default StyledButton;
