import StyledPhaseText from './StyledPhaseText';
import getPhaseName from '@/lib/utils/getPhaseName';
import { ChallengeSummary } from '@/lib/types';

type PhaseTextProps = {
  challenge: ChallengeSummary;
};

const PhaseText = ({ challenge }: PhaseTextProps) => (
  <StyledPhaseText challengeColor={challenge.color}>
    {getPhaseName(challenge.phase)}
  </StyledPhaseText>
);

export default PhaseText;
