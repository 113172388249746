import { useState } from 'react';
import PillButtons from '../PillButtons';
import Dialog from '@/components/Dialog';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import useProofApiEndpoint from './useProofApiEndpoint';
import { ProofObject } from '@/lib/types';

type DeleteProofProps = {
  proofLinkId: number;
  setProof: (proofUpdateFunction: (proof: ProofObject) => void) => void;
};

const DeleteProof = ({ proofLinkId, setProof }: DeleteProofProps) => {
  const api = useApi();
  const proofApiEndpoint = useProofApiEndpoint();

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteProof = async () => {
    setConfirmationDialogOpen(false);

    setDeleting(true);

    await api.delete(`${proofApiEndpoint}/${proofLinkId}`);

    setProof(proof => {
      delete proof[proofLinkId];
    });

    setDeleting(false);
  };

  return (
    <>
      <PillButtons.Button
        onClick={() => setConfirmationDialogOpen(true)}
        disabled={deleting}
        rightHalf
        dangerous
      >
        <DeleteIcon />
      </PillButtons.Button>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.level.proof.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio.level.proof.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteProof} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeleteProof;
