import styled from '@emotion/styled';
import stripProps from '@/lib/utils/stripProps';
import { CircularProgress, CircularProgressProps } from '@mui/material';

type LoadingSpinnerProps = CircularProgressProps & {
  centered?: boolean;
  margin?: number | string;
};

const StyledCircularProgress = styled(
  stripProps(
    CircularProgress,
    'centered',
    'margin',
    'marginTop',
    'marginBottom',
  ),
)<CircularProgressProps & LoadingSpinnerProps>(
  ({ theme, margin }) => ({
    marginTop: margin ?? 0,
    marginBottom: margin ?? 0,
    transition: theme.transitions.create('all'),
  }),
  ({ centered }) => {
    if (centered) {
      return {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
      };
    }
  },
);

const LoadingSpinner = (props: LoadingSpinnerProps) => (
  <StyledCircularProgress
    size={24}
    color="secondary"
    disableShrink
    {...props}
  />
);

export default LoadingSpinner;
