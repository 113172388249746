import { Skeleton } from '@mui/material';
import Space from '@/components/Space';

const SelectLoadingPlaceholder = () => (
  <>
    <Space height={16} />
    <Skeleton variant="rectangular" animation="wave" width={206} height={24} />
    <Space height={16} />
  </>
);

export default SelectLoadingPlaceholder;
