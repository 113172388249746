import React, { useContext } from 'react';
import SelectedPlatformContext from '@/lib/contexts/SelectedPlatformContext';
import usePortfolioUser from './usePortfolioUser';
import PlatformType from '@/lib/constants/PlatformType.enum';
import ShowPerspectiveCategorizationViewContext from '@/lib/contexts/ShowPerspectiveCategorizationViewContext';

type EnsureChallengePortfolioControlsDesiredProps = {
  children: React.ReactNode;
};

const EnsureChallengePortfolioControlsDesired = ({
  children,
}: EnsureChallengePortfolioControlsDesiredProps) => {
  const [selectedPlatform] = useContext(SelectedPlatformContext);

  const portfolioUser = usePortfolioUser();
  const isDisplayingPortfolio = !!portfolioUser;

  const [showPerspectiveCategorizationView] = useContext(
    ShowPerspectiveCategorizationViewContext,
  );

  // TODO
  const controlsRelevant = true; //!!portfolioUser?.rows;

  if (
    selectedPlatform === PlatformType.CHALLENGEMONITOR &&
    isDisplayingPortfolio &&
    !showPerspectiveCategorizationView &&
    controlsRelevant
  ) {
    return children;
  }

  return null;
};

export default EnsureChallengePortfolioControlsDesired;
