import { useState } from 'react';
import SupportButton from './SupportButton';
import SupportDialog from './SupportDialog';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';

const SupportSystem = () => {
  const authenticatedUser = useAuthenticatedUser();
  const [supportDialogOpen, setSupportDialogOpen] = useState(false);

  if (authenticatedUser === null) {
    return null;
  }

  return (
    <>
      <SupportButton onClick={() => setSupportDialogOpen(true)} />
      <SupportDialog
        open={supportDialogOpen}
        onClose={() => setSupportDialogOpen(false)}
      />
    </>
  );
};

export default SupportSystem;
