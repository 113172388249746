import styled from '@emotion/styled';
import UserAvatar from '@/components/UserAvatar';

const FeedbackItemUserAvatar = styled(UserAvatar)({
  '&&&': {
    marginRight: 12,
    width: 24,
    height: 24,
    fontSize: 13,
  },
});

export default FeedbackItemUserAvatar;
