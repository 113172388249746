import DangerousButton from '@/components/DangerousButton';
import { ButtonProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const DangerousDialogSaveButton = (props: ButtonProps) => (
  <DangerousButton inDialog {...props}>
    <FormattedMessage id="dialogs.actions.save" />
  </DangerousButton>
);

export default DangerousDialogSaveButton;
