import styled from '@emotion/styled';

type ContentWrapperProps = {
  hidden?: boolean;
};

const ContentWrapper = styled('div')<ContentWrapperProps>(({ hidden }) => ({
  width: '100%',
  height: '100%',
  fontFamily: 'CircularStd',
  display: hidden ? 'none' : 'flex',
  flexDirection: 'column',
}));

export default ContentWrapper;
