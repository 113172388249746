import styled from '@emotion/styled';
import { Checkbox } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';

const UserSelectCheckbox = styled(Checkbox)({
  [mediaQuery(850)]: {
    display: 'none',
  },
});

export default UserSelectCheckbox;
