import styled from '@emotion/styled';

type StyledFieldNameProps = {
  bold?: boolean;
};

const StyledFieldName = styled('h4')<StyledFieldNameProps>(({ bold }) => ({
  marginBottom: 0,
  fontWeight: bold ? 500 : 400,
}));

export default StyledFieldName;
