import StyledAddFeedbackButton from './StyledAddFeedbackButton';
import { AddCommentOutlined as AddFeedbackIcon } from '@mui/icons-material';
import { ButtonProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type CustomAddFeedbackButtonProps = Omit<
  ButtonProps,
  'variant' | 'startIcon' | 'children'
> & {
  belowFeedbackList: boolean;
};

const CustomAddFeedbackButton = ({
  belowFeedbackList,
  ...props
}: CustomAddFeedbackButtonProps) => (
  <StyledAddFeedbackButton
    variant="outlined"
    startIcon={<AddFeedbackIcon />}
    {...props}
  >
    <FormattedMessage id="portfolio.challenge.coach_feedback.add.button.mobile" />
  </StyledAddFeedbackButton>
);

export default CustomAddFeedbackButton;
