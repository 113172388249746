import { HTMLImgProps } from '@/lib/types';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

type ButtonIconProps = Omit<HTMLImgProps, 'alt'>;

const StyledIcon = styled('img')({
  marginRight: 12,
  width: 24,
  height: 24,
});

const ButtonIcon = (props: ButtonIconProps) => {
  const intl = useIntl();

  return (
    <StyledIcon
      alt={intl.formatMessage({
        id: 'portfolio.challenge.link_button.icon_alt',
      })}
      {...props}
    />
  );
};

export default ButtonIcon;
