import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import { Error as ErrorIcon } from '@mui/icons-material';
import Space from '@/components/Space';
import { FormattedMessage } from 'react-intl';

const StyledTitle = styled('h1')({
  marginTop: 0,
  marginBottom: 18,
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(400)]: {
    fontSize: 28,
  },
});

const ErrorTitle = () => (
  <StyledTitle>
    <ErrorIcon color="error" fontSize="large" />
    <Space width={12} />
    <FormattedMessage id="error_boundary.title" />
  </StyledTitle>
);

export default ErrorTitle;
