import useIsDisplayingNotFoundPage from './useIsDisplayingNotFoundPage';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';

const useIsDisplayingLoginScreen = (): boolean => {
  const authenticatedUser = useAuthenticatedUser()
  const isDisplayingNotFoundPage = useIsDisplayingNotFoundPage();

  return authenticatedUser === null && !isDisplayingNotFoundPage;
};

export default useIsDisplayingLoginScreen;
