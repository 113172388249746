import styled from '@emotion/styled';
import { Select } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';
import { SelectProps } from '@/lib/types';

type StyledGroupSelectProps = SelectProps<number[]>;

const StyledGroupSelect = styled(Select)<StyledGroupSelectProps>({
  width: 180,
  [mediaQuery(1200)]: {
    display: 'none',
  },
}) as unknown as typeof Select<number[]>;

export default StyledGroupSelect;
