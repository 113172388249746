/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

const asValidPercentage = (number: number): number => {
  return Math.max(Math.min(number, 100), 0);
};

const useProgressWithSelectedWeekSyncing = (
  selectedWeek: number,
  weeks: number[],
): [number, React.Dispatch<React.SetStateAction<number>>] => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const progressFromSelectedWeek =
      ((weeks.indexOf(selectedWeek) + 1) / weeks.length) * 100;

    setProgress(asValidPercentage(progressFromSelectedWeek));
  }, [selectedWeek, setProgress, weeks.toString()]);

  return [progress, setProgress];
};

export default useProgressWithSelectedWeekSyncing;
