import LoadingContentWrapper from './LoadingContentWrapper';
import LoadingSpinner from '@/components/LoadingSpinner';
import Space from '@/components/Space';
import React from 'react';
import { HTMLSectionProps } from '@/lib/types';

type LoadingSpinnerWithTextProps = HTMLSectionProps & {
  children: React.ReactNode;
};

const LoadingSpinnerWithText = ({
  children,
  ...props
}: LoadingSpinnerWithTextProps) => (
  <LoadingContentWrapper {...props}>
    <LoadingSpinner color="primary" />
    <Space width={18} />
    <h3>{children}</h3>
  </LoadingContentWrapper>
);

export default LoadingSpinnerWithText;
