import { useContext } from 'react';
import FieldRendererContext from '../../../FieldRendererContext';
import StyledText from './ChallengeNotSharedText/StyledText';
import { FormattedMessage } from 'react-intl';
import { Task } from '@/lib/types';

const AnonymizedAssigneesManager = () => {
  const { portfolioItem: task } = useContext(FieldRendererContext) as {
    portfolioItem: Task;
  };

  if (task.assigneesCount === 0) {
    return (
      <StyledText>
        <FormattedMessage id="portfolio.task.assignment.anonymized.no_assignees" />
      </StyledText>
    );
  }

  return (
    <StyledText>
      <FormattedMessage
        id={`portfolio.task.assignment.anonymized.assignees`}
        values={{
          taskAssigneesCount: task.assigneesCount,
        }}
      />
    </StyledText>
  );
};

export default AnonymizedAssigneesManager;
