import { Theme } from '@emotion/react';

const computeLevelTitleColor = (theme: Theme): string => {
  return theme.palette.increaseContrastWithBackground(
    theme.palette.primary.main,
    0.2,
  );
};

export default computeLevelTitleColor;
