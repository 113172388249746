// TODO is this really still necessary? And can EDITORS be removed?
// TODO should be removed in future
enum Group {
  STUDENTS = 'students',
  COACHES = 'coaches',
  EDITORS = 'editors',
  DRAFTS = 'drafts',
  ALL = 'all',
}

export default Group;
