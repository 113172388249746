/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import useApi from '@/lib/api/useApi';
import SelectedSchoolLocalStorage from './SelectedSchoolLocalStorage';
import { School } from '@/lib/types';

const useSelectedSchoolState = (): [
  boolean,
  School | null,
  () => Promise<void>,
] => {
  const api = useApi();

  const [loading, setLoading] = useState(true);
  const [selectedSchool, setSelectedSchool] = useState<School | null>(null);

  const refreshSelectedSchoolState = async (): Promise<void> => {
    const selectedSchoolReference = SelectedSchoolLocalStorage.getSelectedSchool();

    if (!selectedSchoolReference) {
      setSelectedSchool(null);
      setLoading(false);

      return;
    }

    try {
      const selectedSchool = await api.get<School>(
        `schools/${selectedSchoolReference}`,
      );

      setSelectedSchool(selectedSchool);
    } catch {
      setSelectedSchool(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshSelectedSchoolState();
  }, []);

  return [loading, selectedSchool, refreshSelectedSchoolState];
};

export default useSelectedSchoolState;
