import styled from '@emotion/styled';
import stripProps from '@/lib/utils/stripProps';
import { Chip, ChipProps, lighten } from '@mui/material';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

const ChipAsLi = (props: ChipProps<'li'>) => <Chip component="li" {...props} />;

type PerspectiveChipProps = ChipProps<'li'> & {
  perspectiveColor: string;
};

const PerspectiveChip = styled(stripProps(ChipAsLi, 'perspectiveColor'))<
  PerspectiveChipProps
>(
  ({ theme, perspectiveColor }) => {
    const chipColor = theme.isDark
      ? lighten(perspectiveColor, 0.6)
      : perspectiveColor;

    return {
      marginRight: 8,
      marginBottom: 8,
      color: theme.palette.getContrastText(chipColor),
      backgroundColor: chipColor,
      ':last-child': {
        marginRight: 0,
      },
    };
  },
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.divider),
    backgroundColor: theme.palette.divider,
  })),
);

export default PerspectiveChip;
