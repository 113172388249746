import {
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon,
} from '@mui/icons-material';
import styled from '@emotion/styled';
import stripProps from '@/lib/utils/stripProps';

type UserSwitchArrowIconProps = {
  floating: boolean;
  left?: boolean;
  right?: boolean;
};

type ArrowIconProps = {
  floating: boolean;
};

const StyledArrowLeftIcon = styled(
  stripProps(ArrowLeftIcon, 'floating'),
)<ArrowIconProps>(({ theme, floating }) => {
  if (!floating) {
    return {
      width: 40,
      height: 40,
      fill: theme.palette.text.primary,
    };
  }
});

const StyledArrowRightIcon = styled(
  stripProps(ArrowRightIcon, 'floating'),
)<ArrowIconProps>(({ theme, floating }) => {
  if (!floating) {
    return {
      width: 40,
      height: 40,
      fill: theme.palette.text.primary,
    };
  }
});

const UserSwitchArrowIcon = ({
  floating,
  left,
  right,
}: UserSwitchArrowIconProps) => {
  if (left) {
    return <StyledArrowLeftIcon floating={floating} />;
  }

  if (right) {
    return <StyledArrowRightIcon floating={floating} />;
  }

  return null;
};

export default UserSwitchArrowIcon;
