import ModelType from '@/lib/constants/challengeModel/ModelType.enum';
import { createContext } from 'react';
import { ChallengeModels } from '../types';

// TODO this should be integrated properly and optimally to prevent unnecessary
// reloads, whilst accounting for coach-mode differences
const ModelsContext = createContext<ChallengeModels>({
  [ModelType.CHALLENGE]: null,
  [ModelType.TASK]: null,
});

export default ModelsContext;
