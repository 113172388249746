import styled from '@emotion/styled';
import { Avatar, AvatarProps } from '@mui/material';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import useProfilePicture from '@/components/UserAvatar/useProfilePicture';
import { UserSummary } from '@/lib/types';

type AutomatedAvatarProps = Omit<AvatarProps, 'src' | 'children'> & {
  user: UserSummary;
};

const StyledAvatar = styled(Avatar)(
  addHighContrastStyles(({ theme }) => ({
    border: `1px solid ${theme.palette.background.default}`,
  })),
);

const AutomatedAvatar = ({ user, ...props }: AutomatedAvatarProps) => {
  const profilePicture = useProfilePicture(user);

  return (
    <StyledAvatar src={profilePicture} {...props}>
      {user.name.charAt(0)}
    </StyledAvatar>
  );
};

export default AutomatedAvatar;
