import ReleaseWrapper from './ReleaseWrapper';
import ReleaseTitle from './ReleaseTitle';
import ReleaseSummary from './ReleaseSummary';
import ViewReleaseButton from './ViewReleaseButton';
import { FormattedMessage } from 'react-intl';

type ReleaseProps = {
  release: {
    title: string;
    summary: string;
    link: string;
  };
};

const Release = ({ release }: ReleaseProps) => (
  <ReleaseWrapper>
    <ReleaseTitle>{release.title}</ReleaseTitle>
    <ReleaseSummary>{release.summary}</ReleaseSummary>
    <ViewReleaseButton component="a" href={release.link} target="_blank">
      <FormattedMessage id="header.options_menu.releases_overview.release.view_button" />
    </ViewReleaseButton>
  </ReleaseWrapper>
);

export default Release;
