import { useContext, useState } from 'react';
import AddPartButton from './AddPartButton';
import PartPromptWithoutGroups from './PartPromptWithoutGroups';
import PartPromptWithGroups from './PartPromptWithGroups';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import useEditorPartsApiEndpoint from '../EditorPart/useEditorPartsApiEndpoint';
import UserRole from '@/lib/constants/UserRole.enum';
import EditorPortfolioTemplatesContext from '../../EditorPortfolioTemplatesContext';
import { EditorPartWithoutRelations, MinimumRole } from '@/lib/types';

type AddPartProps = {
  subtopicId: number;
  subtopicMinimumRole: UserRole.STUDENT | UserRole.COACH | UserRole.EDITOR;
  highestPartPosition: number;
  noParentAdd?: boolean;
  noGroups?: boolean;
};

const AddPart = ({
  subtopicId,
  subtopicMinimumRole,
  highestPartPosition,
  noParentAdd,
  noGroups,
}: AddPartProps) => {
  const api = useApi();
  const editorPartsApiEndpoint = useEditorPartsApiEndpoint();

  const [, setPortfolioTemplates] = useContext(EditorPortfolioTemplatesContext);

  const [addPromptOpen, setAddPromptOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveNewPart = async (
    name: string,
    minimumRole: MinimumRole = UserRole.STUDENT,
    groups: number[] = [],
  ): Promise<void> => {
    setAddPromptOpen(false);
    setSaving(true);

    const partData = {
      name,
      position: Math.ceil(highestPartPosition) + 1,
      minimumRole,
      groups,
    };

    const part = await api.post<EditorPartWithoutRelations>(
      `${editorPartsApiEndpoint}?subtopicId=${subtopicId}`,
      partData,
    );

    setPortfolioTemplates(portfolioTemplates => {
      portfolioTemplates.parts[part.id] = {
        ...part,
        groups: groups.map(id => ({ id })),
        levels: [],
      };

      if (!noParentAdd) {
        portfolioTemplates.subtopics[subtopicId].parts.push({ id: part.id });
      }
    });

    setSaving(false);
  };

  const PartPrompt = noGroups ? PartPromptWithoutGroups : PartPromptWithGroups;

  return (
    <>
      <AddPartButton
        color="secondary"
        onClick={() => setAddPromptOpen(true)}
        disabled={saving}
      >
        <FormattedMessage id="portfolio_template_editor.add_part.button" />
      </AddPartButton>
      <PartPrompt
        open={addPromptOpen}
        selectedMinimumRole={subtopicMinimumRole}
        onOk={saveNewPart}
        onCancel={() => setAddPromptOpen(false)}
      />
    </>
  );
};

export default AddPart;
