import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import FieldRendererContext from '../../../FieldRendererContext';

type FieldTypeValidator = (value: unknown) => boolean;

const useCurrentFieldValue = <T>(
  defaultFieldValue: T,
  validator?: FieldTypeValidator,
): T => {
  const { portfolioItem } = useContext(FieldRendererContext);
  const field = useContext(FieldContext);

  const fieldValue =
    portfolioItem.fieldValues[field.id]?.typeSpecificValues?.[field.type]
      ?.value;

  // fieldValue can be `null` when working with data imported from legacy
  // instances
  if (typeof fieldValue === 'undefined' || fieldValue === null) {
    return defaultFieldValue;
  }

  const parsedValue = JSON.parse(fieldValue);

  if (typeof validator !== 'undefined' && !validator(parsedValue)) {
    return defaultFieldValue;
  }

  return parsedValue as T;
};

export default useCurrentFieldValue;
