import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import { HTMLFormProps } from '@/lib/types';

type WrapperFormProps = HTMLFormProps & {
  fullWidth: boolean;
};

const WrapperForm = styled('form')<WrapperFormProps>(({ fullWidth }) => ({
  flex: fullWidth ? 1 : undefined,
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(560)]: {
    flex: 1,
  },
}));

export default WrapperForm;
