import { FormControlLabel, Radio } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Theme from '@/lib/constants/Theme.enum';

type ThemeOptionProps = {
  value: Theme | 'APPEARANCE_DEVICE_THEME';
};

const ThemeOption = ({ value }: ThemeOptionProps) => (
  <FormControlLabel
    control={<Radio />}
    value={value}
    label={
      <FormattedMessage
        id={`header.options_menu.display_settings.appearance.options.${value}`}
      />
    }
  />
);

export default ThemeOption;
