import { AdditionalFieldConfig } from '@/lib/types';
import dummyFunction from '@/lib/utils/dummyFunction';
import { createContext } from 'react';
import { Updater } from 'use-immer';

const AdditionalConfigStateContext = createContext<
  [AdditionalFieldConfig, Updater<AdditionalFieldConfig>, boolean, () => void]
>([{}, dummyFunction, false, dummyFunction]);

export default AdditionalConfigStateContext;
