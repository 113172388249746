import mediaQuery from '@/lib/styling/mediaQuery';
import styled from '@emotion/styled';

const CenterChallengeWrapper = styled('article')({
  marginBottom: 16,
  padding: '16px 24px',
  gridRowStart: 3,
  gridRowEnd: 5,
  gridColumnStart: 2,
  gridColumnEnd: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [mediaQuery(900)]: {
    display: 'none',
  },
});

export default CenterChallengeWrapper;
