import styled from '@emotion/styled';

const ChallengePlanningWrapper = styled('section')({
  position: 'relative',
  paddingTop: 14,
  paddingBottom: 12,
  flex: 1,
});

export default ChallengePlanningWrapper;
