import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import PhaseContentGroupContext from './PhaseContentGroupContext';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import React from 'react';
import { HTMLDivProps } from '@/lib/types';

type PhaseRightContentProps = HTMLDivProps & {
  children: React.ReactNode;
};

const StyledRightContent = styled('aside')({
  width: 148,
  [mediaQuery(640)]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: 36,
      marginBottom: 0,
      flex: 1,
    },
    '& > .mobile-full-width': {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
});

const PhaseRightContent = (props: PhaseRightContentProps) => (
  <PhaseContentGroupContext.Provider value={ContentGroupType.RIGHT}>
    <StyledRightContent {...props} />
  </PhaseContentGroupContext.Provider>
);

export default PhaseRightContent;
