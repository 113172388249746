import mediaQuery from '@/lib/styling/mediaQuery';
import styled from '@emotion/styled';

const SectionTitle = styled('h1')({
  paddingTop: 24,
  paddingLeft: 24,
  paddingRight: 24,
  fontSize: 20,
  lineHeight: 1,
  [mediaQuery(640)]: {
    paddingLeft: 12,
    paddingRight: 12,
  },
});

export default SectionTitle;
