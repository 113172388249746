import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const LoadingIndicator = () => <StyledCircularProgress size={18} />;

export default LoadingIndicator;
