import { useContext } from 'react';
import ModelTypeContext from '../../../../../ModelTypeContext';
import PhaseContext from '../../../../PhaseContext';
import FieldContext from '../../../FieldContext';
import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import ModelsContext from '../../../../../../ModelsContext';
import useApi from '@/lib/api/useApi';
import { EditorFieldWithoutRelations } from '@/lib/types';

const useCurrentFieldPhaseMoving = (): [
  () => Promise<void>,
  () => Promise<void>,
] => {
  const api = useApi();

  const modelType = useContext(ModelTypeContext);
  const [models, setModels] = useContext(ModelsContext);

  const allFields = models[modelType].fields;

  const { previousPhase, phase, nextPhase } = useContext(PhaseContext);
  const { field } = useContext(FieldContext);

  const updatePhaseInModelsState = (
    newPhaseId: number,
    updatedField: EditorFieldWithoutRelations,
  ): void => {
    setModels(models => {
      const model = models[modelType];

      model.fields[field!.id] = {
        ...model.fields[field!.id],
        ...updatedField,
      };

      const oldPhase = model.sections[phase.id];

      oldPhase.fields.splice(
        oldPhase.fields.findIndex(({ id }) => id === field!.id),
        1,
      );

      model.sections[newPhaseId].fields.push({ id: field!.id });
    });
  };

  const moveCurrentFieldToPreviousPhase = async (): Promise<void> => {
    if (previousPhase === null) {
      return;
    }

    const previousPhaseFields = previousPhase.fields
      .map(({ id }) => allFields[id])
      .filter(({ contentGroup }) => contentGroup === ContentGroupType.BOTTOM)
      .sort(sortByPosition);
    const previousPhaseFieldsHighestPosition =
      previousPhaseFields.reverse()[0]?.position ?? 0;

    const newFieldPosition = previousPhaseFieldsHighestPosition + 1;

    const updatedField = await api.post<EditorFieldWithoutRelations>(
      `editor/challenge-model/fields/${field!.id}/position/set-section`,
      {
        sectionId: previousPhase.id,
        contentGroup: ContentGroupType.BOTTOM,
        position: newFieldPosition,
      },
    );

    updatePhaseInModelsState(previousPhase.id, updatedField);
  };

  const moveCurrentFieldToNextPhase = async (): Promise<void> => {
    if (nextPhase === null) {
      return;
    }

    const nextPhaseFields = nextPhase.fields
      .map(({ id }) => allFields[id])
      .filter(({ contentGroup }) => contentGroup === ContentGroupType.LEFT)
      .sort(sortByPosition);
    const nextPhaseFieldsLowestPosition = nextPhaseFields[0]?.position ?? 2;

    const newFieldPosition = nextPhaseFieldsLowestPosition / 2;

    const updatedField = await api.post<EditorFieldWithoutRelations>(
      `editor/challenge-model/fields/${field!.id}/position/set-section`,
      {
        sectionId: nextPhase.id,
        contentGroup: ContentGroupType.LEFT,
        position: newFieldPosition,
      },
    );

    updatePhaseInModelsState(nextPhase.id, updatedField);
  };

  return [moveCurrentFieldToPreviousPhase, moveCurrentFieldToNextPhase];
};

export default useCurrentFieldPhaseMoving;
