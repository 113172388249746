export type AgoraWorld =
  | 'artistic'
  | 'scientific'
  | 'social_ethic'
  | 'societal'
  | 'spiritual';

const worlds = [
  'artistic',
  'scientific',
  'social_ethic',
  'societal',
  'spiritual',
] as const;

export default worlds;
