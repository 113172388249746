import { useTheme } from '@emotion/react';
import { PaletteOptions, darken } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React from 'react';

type DangerColorThemeProviderProps = {
  children: React.ReactNode;
};

const DangerColorThemeProvider = (props: DangerColorThemeProviderProps) => {
  const theme = useTheme();
  const forceDangerColorTheme = theme.extend({
    palette: {
      primary: {
        main: theme.palette.danger,
        dark: darken(theme.palette.danger, 0.25),
      },
    } as PaletteOptions,
  });

  return <MuiThemeProvider theme={forceDangerColorTheme} {...props} />;
};

export default DangerColorThemeProvider;
