import { useIntl } from 'react-intl';
import {
  TextField,
  InputAdornment,
  IconButton,
  TextFieldProps,
} from '@mui/material';
import Space from '@/components/Space';
import ClearButton from './ClearButton';
import { Search as SearchIcon } from '@mui/icons-material';

type UserSearchTextFieldProps = Omit<TextFieldProps, 'value'> & {
  value: string;
  onClearSearch: () => void;
  onSearchClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const UserSearchTextField = ({
  value,
  onClearSearch,
  onSearchClick,
  ...props
}: UserSearchTextFieldProps) => {
  const intl = useIntl();

  return (
    <TextField
      size="small"
      variant="outlined"
      placeholder={intl.formatMessage({
        id: 'user_manager.user_search.input_label',
      })}
      value={value}
      InputProps={{
        inputProps: {
          style: {
            textOverflow: 'ellipsis',
          },
        },
        endAdornment: (
          <InputAdornment position="end">
            <Space width={-8} />
            <ClearButton
              visible={!!value}
              disabled={!value}
              onClick={onClearSearch}
            />
            <IconButton
              type="submit"
              size="large"
              onClick={onSearchClick}
              disabled={!value}
            >
              <SearchIcon />
            </IconButton>
            <Space width={-14} />
          </InputAdornment>
        ),
      }}
      style={{
        overflow: 'hidden',
      }}
      fullWidth
      {...props}
    />
  );
};

export default UserSearchTextField;
