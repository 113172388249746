import { useContext } from 'react';
import isEarlier from './utils/isEarlier';
import InnerDateField from '../DateField/InnerDateField';
import convertToTimestamp from './utils/convertToTimestamp';
import useSavePortfolioItemDate from './utils/useSavePortfolioItemDate';
import FieldRendererContext from '../../../FieldRendererContext';
import moment from 'moment';

const EndDate = () => {
  const { portfolioItem, portfolioItemApiEndpoint } = useContext(
    FieldRendererContext,
  );

  const savePortfolioItemDate = useSavePortfolioItemDate(
    `${portfolioItemApiEndpoint}/dates/end-date`,
  );

  return (
    <InnerDateField
      initialValue={convertToTimestamp(portfolioItem.endDate)}
      onSave={savePortfolioItemDate}
      shouldDisableDate={date =>
        !!portfolioItem.startDate &&
        isEarlier(date, moment(portfolioItem.startDate))
      }
    />
  );
};

export default EndDate;
