import { useContext, useState } from 'react';
import Dialog from '@/components/Dialog';
import DeleteButton from '../PortfolioTemplatesEditorActionButtons/DeleteButton';
import { FormattedMessage } from 'react-intl';
import useApi from '@/lib/api/useApi';
import { EditorSubtopic } from '@/lib/types';
import EditorPortfolioTemplatesContext from '../../EditorPortfolioTemplatesContext';

type DeleteSubtopicProps = {
  subtopic: EditorSubtopic;
};

const DeleteSubtopic = ({ subtopic }: DeleteSubtopicProps) => {
  const api = useApi();

  const [, setPortfolioTemplates] = useContext(EditorPortfolioTemplatesContext);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteSubtopic = async (): Promise<void> => {
    setConfirmationDialogOpen(false);
    setDeleting(true);

    await api.delete(`editor/portfolio-templates/subtopics/${subtopic.id}`);

    setPortfolioTemplates(portfolioTemplates => {
      delete portfolioTemplates.subtopics[subtopic.id];
    });

    setDeleting(false);
  };

  return (
    <>
      <DeleteButton
        onClick={() => setConfirmationDialogOpen(true)}
        disabled={deleting}
      />
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio_template_editor.subtopic.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio_template_editor.subtopic.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteSubtopic} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeleteSubtopic;
