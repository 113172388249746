import KanbanPhase from '@/lib/constants/KanbanPhase.enum';

const phaseNames = {
  [KanbanPhase.TODO]: 'To do',
  [KanbanPhase.IN_PROGRESS]: 'In progress',
  [KanbanPhase.DONE]: 'Done',
} as const;

const phaseName = (phase: KanbanPhase): string => phaseNames[phase];

export default phaseName;
