import styled from '@emotion/styled';
import { HTMLButtonProps } from '@/lib/types';

const ButtonBase = styled('button')<HTMLButtonProps>(({ disabled }) => ({
  padding: 0,
  textAlign: 'left',
  font: 'inherit',
  color: 'inherit',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: disabled ? 'default' : 'pointer',
  appearance: 'none',
}));

export default ButtonBase;
