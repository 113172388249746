import { useContext, useState } from 'react';
import FieldContext from '../../FieldContext';
import CustomFieldName from './CustomFieldName';
import InfoDialog from './InfoDialog';
import WorldSelectField from './WorldSelectField';
import worlds, { AgoraWorld } from './worlds';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useCurrentFieldValue from '../utils/useCurrentFieldValue';
import useSetCurrentFieldValue from '../utils/useSetCurrentFieldValue';
import WorldSelectWrapper from './WorldSelectWrapper';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

const WorldSelect = () => {
  const field = useContext(FieldContext);

  const currentFieldValue = useCurrentFieldValue<AgoraWorld[]>(
    [],
    Array.isArray,
  );
  const [setCurrentFieldValue, saving] =
    useSetCurrentFieldValue<AgoraWorld[]>();

  const [selectedWorlds, setSelectedWorlds, , reenableSelectedWorldsSync] =
    useComplexSwrState(currentFieldValue, {
      stringifyChangeCheck: true,
    });
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const saveSelectedWorldChanges = async () => {
    await setCurrentFieldValue(selectedWorlds);

    reenableSelectedWorldsSync();
  };

  return (
    <WorldSelectWrapper>
      {field.name && (
        <CustomFieldName onInfoButtonClick={() => setInfoDialogOpen(true)} />
      )}
      <WorldSelectField
        placeholder={
          <FormattedMessage id="portfolio.challenge.world_select.placeholder" />
        }
        value={selectedWorlds}
        onChange={(e: SelectChangeEvent<AgoraWorld[]>) =>
          setSelectedWorlds(e.target.value as AgoraWorld[])
        }
        MenuProps={{
          TransitionProps: {
            onExit: saveSelectedWorldChanges,
          },
        }}
        disabled={saving}
      >
        {worlds.map(world => (
          <MenuItem value={world} key={world}>
            <FormattedMessage
              id={`portfolio.challenge.world_select.world_labels.${world}_world`}
            />
          </MenuItem>
        ))}
      </WorldSelectField>
      <InfoDialog
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
      />
    </WorldSelectWrapper>
  );
};

export default WorldSelect;
