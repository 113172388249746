import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const LegendOuterWrapper = styled('header')({
  marginTop: 24,
  paddingTop: 14,
  paddingBottom: 14,
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(600)]: {
    paddingBottom: 0,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

export default LegendOuterWrapper;
