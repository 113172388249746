import { useContext, useState } from 'react';
import FieldContext from '../FieldContext';
import useSelectableFieldTypes from './useSelectableFieldTypes';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import ModelTypeContext from '../../../ModelTypeContext';
import ModelsContext from '../../../../ModelsContext';
import useApi from '@/lib/api/useApi';
import { EditorFieldWithoutRelations } from '@/lib/types';
import FieldType from '@/lib/constants/challengeModel/FieldType.enum';

const FieldTypeSelect = () => {
  const api = useApi();

  const modelType = useContext(ModelTypeContext);
  const [, setModels] = useContext(ModelsContext);
  const { field } = useContext(FieldContext);

  const [saving, setSaving] = useState(false);

  const handleChange = async (
    e: SelectChangeEvent<FieldType>,
  ): Promise<void> => {
    setSaving(true);

    const updatedField = await api.put<EditorFieldWithoutRelations>(
      `editor/challenge-model/fields/${field!.id}/type`,
      {
        type: e.target.value as FieldType,
      },
    );

    setModels(models => {
      models[modelType].fields[field!.id] = {
        ...models[modelType].fields[field!.id],
        ...updatedField,
      };
    });

    setSaving(false);
  };

  const selectableFieldType = useSelectableFieldTypes();

  return (
    <Select
      variant="standard"
      value={field!.type}
      onChange={handleChange}
      disabled={saving}
      fullWidth
    >
      {selectableFieldType.map(fieldType => (
        <MenuItem value={fieldType} key={fieldType}>
          <FormattedMessageWithChallengeNaming
            id={`editor.model_editor.field_editor.type_select.types.${fieldType}`}
          />
        </MenuItem>
      ))}
    </Select>
  );
};

export default FieldTypeSelect;
