import React, { useContext } from 'react';
import InnerUnreadCoachCommentsBadge from '../../Challenge/ChallengeCard/UnreadCoachCommentsBadge/InnerUnreadCoachCommentsBadge';
import ChallengeContext from '../../Challenge/ChallengeView/ChallengeContext';
import useIsPortfolioReadOnly from '../../useIsPortfolioReadOnly';
import { TaskSummary } from '@/lib/types';

type UnreadCoachCommentsBadgeProps = {
  task: TaskSummary;
  children: React.ReactNode;
};

const UnreadCoachCommentsBadge = ({
  task,
  children,
}: UnreadCoachCommentsBadgeProps) => {
  const portfolioIsReadOnly = useIsPortfolioReadOnly();
  const [, setChallenge] = useContext(ChallengeContext);

  const unreadCoachCommentsCount = task._count.coachComments;

  const markCoachCommentsAsRead = () => {
    if (unreadCoachCommentsCount === 0 || portfolioIsReadOnly) {
      return;
    }

    setChallenge(challenge => {
      challenge.tasks[task.id]._count.coachComments = 0;
    });
  };

  return (
    <InnerUnreadCoachCommentsBadge
      unreadCoachCommentsCount={unreadCoachCommentsCount}
      onClick={markCoachCommentsAsRead}
    >
      {children}
    </InnerUnreadCoachCommentsBadge>
  );
};

export default UnreadCoachCommentsBadge;
