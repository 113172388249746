import { useState } from 'react';
import device from 'current-device';
import Space from '@/components/Space';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import RowPicker from './RowPicker';
import setBodyOverflow, { Overflow } from '@/lib/styling/setBodyOverflow';

// This is necessary because dragging cards doesn't quite work on mobile
// devices
const MobileRowPicker = () => {
  const [pickerOpen, setPickerOpen] = useState(false);

  if (device.desktop()) {
    return null;
  }

  return (
    <>
      <Button variant="outlined" onClick={() => setPickerOpen(true)} fullWidth>
        <FormattedMessage id="portfolio.challenge.newly_shared.row_picker.button" />
      </Button>
      <Space height={36} />
      <RowPicker
        open={pickerOpen}
        onClose={() => {
          setPickerOpen(false);
          setBodyOverflow(Overflow.HIDDEN);
        }}
      />
    </>
  );
};

export default MobileRowPicker;
