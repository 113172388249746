import ReportTitle from './ReportTitle';
import { Button } from '@mui/material';
import {
  EmailOutlined as EmailIcon,
  ErrorOutline as IssueIcon,
} from '@mui/icons-material';
import Space from '@/components/Space';
import ReportViaSupportSystem from './ReportViaSupportSystem';
import { FormattedMessage } from 'react-intl';

type ErrorReportingProps = {
  error: string;
  errorComponentStack: string;
};

const ErrorReporting = ({
  error,
  errorComponentStack,
}: ErrorReportingProps) => (
  <>
    <ReportTitle>
      <FormattedMessage id="error_boundary.report.title" />
    </ReportTitle>
    <Button
      variant="outlined"
      color="secondary"
      startIcon={<EmailIcon />}
      component="a"
      href={`mailto:bugs@egodact.com?subject=${error}&body=${errorComponentStack}`}
    >
      <FormattedMessage id="error_boundary.report.email_button" />
    </Button>
    <Space height={12} />
    <Button
      variant="outlined"
      color="secondary"
      startIcon={<IssueIcon />}
      component="a"
      href={`https://github.com/egodact/egodact-issues/issues/new?title=${error}&body=\`${errorComponentStack}\``}
      target="_blank"
    >
      <FormattedMessage id="error_boundary.report.github_issue_button" />
    </Button>
    <ReportViaSupportSystem />
  </>
);

export default ErrorReporting;
