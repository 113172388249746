import React, { useState } from 'react';
import { MoreVert as MoreIcon } from '@mui/icons-material';
import { Menu, MenuProps } from '@mui/material';
import { IconButton } from '@mui/material';
import ActionMenuButton from './ActionMenuButton';
import ActionMenuDeleteButton from './ActionMenuDeleteButton';

type CollapsibleActionMenuProps = Omit<
  MenuProps,
  'open' | 'onClick' | 'onClose' | 'anchorEl' | 'keepMounted'
> & {
  open?: boolean;
  ButtonComponent?: React.ElementType;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

// TODO remove other props?
const CollapsibleActionMenu = ({
  open,
  ButtonComponent: ButtonComponentOrUndefined,
  onClick,
  ...props
}: CollapsibleActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const menuOpen = !!anchorEl;

  const closeMenu = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(null);

    if (typeof onClick !== 'undefined') {
      onClick(event);
    }
  };

  const ButtonComponent = ButtonComponentOrUndefined ?? IconButton;

  return (
    <>
      <ButtonComponent
        edge="end"
        onClick={(e: React.SyntheticEvent<HTMLButtonElement>) =>
          setAnchorEl(e.currentTarget)
        }
      >
        <MoreIcon />
      </ButtonComponent>
      <Menu
        open={typeof open !== 'undefined' ? open : menuOpen}
        onClick={closeMenu}
        onClose={closeMenu}
        anchorEl={anchorEl}
        keepMounted
        {...props}
      />
    </>
  );
};

CollapsibleActionMenu.Button = ActionMenuButton;
CollapsibleActionMenu.DeleteButton = ActionMenuDeleteButton;

export default CollapsibleActionMenu;
