import styled from '@emotion/styled';
import ColorBox from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/FieldRenderer/TypeSpecificFieldRenderers/ColorPicker/PickerContent/ColorBoxes/ColorBox';
import Space from '@/components/Space';

type ColorPreviewProps = {
  color: string;
};

const PreviewColorBox = styled(ColorBox)({
  margin: 0,
  marginRight: 18,
  minWidth: 24,
  cursor: 'default',
  ':hover': {
    transform: 'none',
  },
});

const ColorPreview = ({ color }: ColorPreviewProps) => {
  if (color.length !== 7) {
    return <Space width={42} />;
  }

  return <PreviewColorBox color={color} />;
};

export default ColorPreview;
