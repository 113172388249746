import Dialog, { DialogProps } from '@/components/Dialog';
import LinkField from './LinkField';
import IsPublicToggle from './IsPublicToggle';
import Space from '@/components/Space';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';

type LinkSharingDialogProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
};

const LinkSharingDialog = ({ onClose, ...props }: LinkSharingDialogProps) => (
  <Dialog onClose={onClose} onEnterClose={onClose} {...props}>
    <Dialog.Title>
      <FormattedMessageWithChallengeNaming id="portfolio.challenge.link_sharing.title" />
    </Dialog.Title>
    <Dialog.Content>
      <LinkField />
      <Space height={6} />
      <IsPublicToggle />
    </Dialog.Content>
    <Dialog.Actions>
      <Dialog.Actions.DoneButton onClick={onClose} />
    </Dialog.Actions>
  </Dialog>
);

export default LinkSharingDialog;
