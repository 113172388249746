import LinkButtonConfigFieldEditor from './LinkButtonConfigFieldEditor';

const LinkButtonEditor = () => (
  <section>
    <LinkButtonConfigFieldEditor id="label" />
    <LinkButtonConfigFieldEditor id="link" />
    <LinkButtonConfigFieldEditor id="iconUrl" />
  </section>
);

export default LinkButtonEditor;
