import moment, { Moment } from 'moment';
import StyledWeekLabel from './StyledWeekLabel';
import YearIndicator from './YearIndicator';
import { FormattedMessage } from 'react-intl';

type WeekLabelProps = {
  weekStartDate: Moment;
};

const WeekLabel = ({ weekStartDate }: WeekLabelProps) => {
  const weekNumber = weekStartDate.isoWeek();
  const weekYear = weekStartDate.clone().endOf('isoWeek').year();

  const currentWeekNumber = moment().isoWeek();
  const currentYear = moment().year();

  const representedWeekIsCurrentWeek =
    weekNumber === currentWeekNumber && weekYear === currentYear;

  return (
    <StyledWeekLabel highlighted={representedWeekIsCurrentWeek}>
      <FormattedMessage
        id="portfolio.challenge_planning_view.header.week_marker.week_label"
        values={{
          weekNumber,
        }}
      />
      {weekNumber === 1 && <YearIndicator year={weekYear} />}
    </StyledWeekLabel>
  );
};

export default WeekLabel;
