import { useState } from 'react';
import GroupWrapper from './GroupWrapper';
import GroupNameEditor from './GroupNameEditor';
import GroupName from './GroupName';
import EditButton from './EditButton';
import DeleteGroup from './DeleteGroup';
import GroupMembersText from './GroupMembersText';
import { Group as GroupType } from '@/lib/types';

type GroupProps = {
  group: GroupType;
};

const Group = ({ group }: GroupProps) => {
  const [nameEditing, setNameEditing] = useState(false);

  return (
    <GroupWrapper>
      {nameEditing ? (
        <GroupNameEditor group={group} onClose={() => setNameEditing(false)} />
      ) : (
        <>
          <GroupName>{group.name}</GroupName>
          <GroupMembersText memberCount={group.memberCount} />
          <EditButton onClick={() => setNameEditing(true)} />
        </>
      )}
      <DeleteGroup group={group} />
    </GroupWrapper>
  );
};

export default Group;
