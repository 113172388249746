import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const SupportTilesWrapper = styled('section')({
  marginLeft: -9,
  marginRight: -9,
  width: 'calc(100% + 18px)',
  display: 'flex',
  [mediaQuery(460)]: {
    flexDirection: 'column',
  },
});

export default SupportTilesWrapper;
