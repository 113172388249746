import App from '@/lib/App';
import StyledIcon from './StyledIcon';
import IconVariant from '../IconVariant.enum';
import studentIcon from './slider-icons/student.svg';
import coachIcon from './slider-icons/coach.svg';

type SliderIconProps = {
  variant: IconVariant;
  sliding: boolean;
};

const SliderIcon = ({ variant, sliding }: SliderIconProps) => {
  const reversed = App.instanceConfig.useSetting('useReversedSliders');

  return (
    <StyledIcon
      src={variant === IconVariant.STUDENT ? studentIcon : coachIcon}
      noTransition={sliding}
      reversed={reversed}
      style={{
        top: variant === IconVariant.COACH ? 7 : undefined,
        bottom: variant === IconVariant.STUDENT ? 7 : undefined,
      }}
      draggable={false}
    />
  );
};

export default SliderIcon;
