import React, { useContext, useState } from 'react';
import TitleField from '../EditorLevel/LevelEditor/TitleField';
import DescriptionField from '../EditorLevel/LevelEditor/DescriptionField';
import LevelEditorActions from '../EditorLevel/LevelEditor/LevelEditorActions';
import useApi from '@/lib/api/useApi';
import EditorPortfolioTemplatesContext from '../../EditorPortfolioTemplatesContext';
import { EditorLevelWithoutRelations } from '@/lib/types';

type LevelFormProps = {
  partId: number;
  highestLevelPosition: number;
  onClose: () => void;
};

const LevelForm = ({
  partId,
  highestLevelPosition,
  onClose,
}: LevelFormProps) => {
  const api = useApi();

  const [, setPortfolioTemplates] = useContext(EditorPortfolioTemplatesContext);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [showEmptyError, setShowEmptyError] = useState(false);
  const [saving, setSaving] = useState(false);

  const resetAndClose = (): void => {
    setTitle('');
    setDescription('');
    setShowEmptyError(false);
    onClose();
  };

  const saveNewLevel = async (): Promise<void> => {
    if (description.trim().length === 0) {
      setShowEmptyError(true);
      return;
    }

    setSaving(true);

    const levelData = {
      title,
      description,
      position: Math.ceil(highestLevelPosition) + 1,
    };

    const level = await api.post<EditorLevelWithoutRelations>(
      `editor/portfolio-templates/levels?partId=${partId}`,
      levelData,
    );

    setPortfolioTemplates(portfolioTemplates => {
      portfolioTemplates.levels[level.id] = {
        ...level,
      };

      portfolioTemplates.parts[partId].levels.push({ id: level.id });
    });

    setSaving(false);
    resetAndClose();
  };

  return (
    <>
      <TitleField
        value={title}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setTitle(e.target.value)
        }
      />
      <DescriptionField
        value={description}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setDescription(e.target.value)
        }
        showEmptyError={showEmptyError}
        autoFocus
      />
      <LevelEditorActions
        onClose={resetAndClose}
        onSave={saveNewLevel}
        disabled={saving}
        primaryButtons
      />
    </>
  );
};

export default LevelForm;
