import { useState } from 'react';
import { useTheme, ClassNames } from '@emotion/react';
import { Slider, SliderProps } from '@mui/material';

type StyledSliderProps = Omit<SliderProps, 'onChange' | 'onChangeCommitted'> & {
  onChange: (newValue: number) => void;
  onChangeCommitted: (value: number) => void;
  disabled: boolean;
};

const StyledSlider = ({
  onChange,
  onChangeCommitted,
  disabled,
  ...props
}: StyledSliderProps) => {
  const theme = useTheme();
  const [hasTransition, setHasTransition] = useState(true);

  return (
    <ClassNames>
      {({ css }) => (
        <Slider
          classes={{
            root: css({
              opacity: disabled ? 0.7 : 1,
              transition: theme.transitions.create('all'),
            }),
            rail: css({
              height: 18,
              backgroundColor: theme.palette.action.selected,
              opacity: 1,
              borderRadius: 9,
            }),
            track: css({
              height: 18,
              backgroundColor: theme.palette.primary.light,
              border: 'none',
              borderRadius: 9,
              transition: hasTransition
                ? theme.transitions.create('all')
                : undefined,
            }),
            thumb: css({
              // These !important overrides are necessary to ensure proper
              // thumb display when slider is disabled
              width: '28px !important',
              height: '28px !important',
              backgroundColor: theme.palette.primary.dark,
              border: theme.palette.isHighContrast
                ? `2px solid ${theme.palette.background.default}`
                : undefined,
              transition: hasTransition
                ? theme.transitions.create('all')
                : undefined,
            }),
          }}
          onChange={(_, newValue) => {
            setHasTransition(false);
            onChange(newValue as number);
          }}
          onChangeCommitted={(_, value) => {
            onChangeCommitted(value as number);
            setHasTransition(true);
          }}
          disabled={disabled}
          {...props}
        />
      )}
    </ClassNames>
  );
};

export default StyledSlider;
