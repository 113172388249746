import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const PhasesWrapper = styled('section')(({ theme }) => ({
  borderTop: `2px solid ${theme.palette.background.border}`,
  borderBottom: `2px solid ${theme.palette.background.border}`,
  transition: theme.transitions.create(['border']),
  display: 'flex',
  [mediaQuery(640)]: {
    display: 'block',
  },
}));

export default PhasesWrapper;
