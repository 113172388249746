import CollapsibleActionMenu from '@/components/CollapsibleActionMenu';
import EditPhaseNameButton from './EditPhaseNameButton';
import PhaseGroups from './PhaseGroups';
import DeletePhase from './DeletePhase';
import React from 'react';

type PhaseActionsProps = {
  onNameEdit: (event: React.MouseEvent<HTMLLIElement>) => void;
};

const PhaseActions = ({ onNameEdit }: PhaseActionsProps) => (
  <CollapsibleActionMenu>
    <EditPhaseNameButton onClick={onNameEdit} />
    <PhaseGroups />
    <DeletePhase />
  </CollapsibleActionMenu>
);

export default PhaseActions;
