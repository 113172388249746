import { useState } from 'react';
import App from '@/lib/App';
import modesForRole from './modesForRole';
import AppMode from '@/lib/constants/AppMode.enum';
import UserRole from '@/lib/constants/UserRole.enum';
import { ClickAwayListener } from '@mui/material';
import EnsureProperModeSwitchAlignment from './EnsureProperModeSwitchAlignment';
import StyledSwitch from './StyledSwitch';
import DropdownButton from './DropdownButton';
import Mode from './Mode';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';

const ModeSwitch = () => {
  const authenticatedUser = useAuthenticatedUser()!;
  const [open, setOpen] = useState(false);

  const shownModes = modesForRole[authenticatedUser.role];
  const selectedMode = App.navigation.useSelectedMode();

  const replacedByCoachModeButton =
    selectedMode === AppMode.PORTFOLIO &&
    authenticatedUser.role === UserRole.COACH;

  if (!shownModes || !selectedMode || replacedByCoachModeButton) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <EnsureProperModeSwitchAlignment>
        <StyledSwitch>
          <DropdownButton open={open} onClick={() => setOpen(!open)} />
          {shownModes.map(mode => (
            <Mode
              mode={mode}
              selected={mode === selectedMode}
              modeSwitchOpen={open}
              onClick={() => setOpen(!open)}
              key={mode}
            />
          ))}
        </StyledSwitch>
      </EnsureProperModeSwitchAlignment>
    </ClickAwayListener>
  );
};

export default ModeSwitch;
