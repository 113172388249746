import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

type GroupMembersTextProps = {
  memberCount: number;
};

const StyledText = styled('div')(({ theme }) => ({
  marginLeft: 6,
  marginRight: 'auto',
  color: theme.palette.text.secondary,
}));

const GroupMembersText = ({ memberCount }: GroupMembersTextProps) => (
  <StyledText>
    {memberCount === 1 ? (
      <FormattedMessage id="group_manager.group.member_count_singular" />
    ) : (
      <FormattedMessage
        id="group_manager.group.member_count_plural"
        values={{
          memberCount,
        }}
      />
    )}
  </StyledText>
);

export default GroupMembersText;
