import ErrorsWrapper from './ErrorsWrapper';
import ApiError from './ApiError';

const MAX_VISIBLE_ERRORS = 4;

type ApiErrorsProps = {
  errors: unknown[];
};

const ApiErrors = ({ errors }: ApiErrorsProps) => (
  <ErrorsWrapper>
    {errors.map((error, i) => (
      <ApiError
        error={error}
        hidden={errors.length - i > MAX_VISIBLE_ERRORS}
        key={i}
      />
    ))}
  </ErrorsWrapper>
);

export default ApiErrors;
