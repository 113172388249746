import styled from '@emotion/styled';
import { IconButton, IconButtonProps } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';
import { MoreVert as MoreIcon } from '@mui/icons-material';

type ChallengeActionsButtonProps = Omit<IconButtonProps, 'children'>;

const StyledButton = styled(IconButton)({
  marginLeft: 'auto',
  display: 'none',
  [mediaQuery(640)]: {
    display: 'inline-flex',
  },
});

const ChallengeActionsButton = (props: ChallengeActionsButtonProps) => (
  <StyledButton {...props}>
    <MoreIcon />
  </StyledButton>
);

export default ChallengeActionsButton;
