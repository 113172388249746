import UserRole from '@/lib/constants/UserRole.enum';
import useIsDisplayingNotFoundPage from './useIsDisplayingNotFoundPage';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';

const useShouldDisplayAdminControls = (): boolean => {
  const authenticatedUser = useAuthenticatedUser();
  const userIsAdminLike =
    authenticatedUser !== null && authenticatedUser.role !== UserRole.STUDENT;

  const isDisplayingNotFoundPage = useIsDisplayingNotFoundPage();

  return userIsAdminLike && !isDisplayingNotFoundPage;
};

export default useShouldDisplayAdminControls;
