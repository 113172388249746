import { useContext, useState } from 'react';
import PhaseContentGroupContext from './PhaseContentGroupContext';
import ContentGroupType from '@/lib/constants/challengeModel/ContentGroupType.enum';
import FieldType from '@/lib/constants/challengeModel/FieldType.enum';
import { Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import Space from '@/components/Space';
import Prompt from '@/components/Prompt';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import PhaseContext from './PhaseContext';
import ModelsContext from '../../ModelsContext';
import ModelTypeContext from '../ModelTypeContext';
import useApi from '@/lib/api/useApi';
import { EditorFieldWithoutRelations } from '@/lib/types';

type AddModelFieldProps = {
  highestFieldPosition: number;
};

const AddModelField = ({ highestFieldPosition }: AddModelFieldProps) => {
  const api = useApi();

  const modelType = useContext(ModelTypeContext);
  const [, setModels] = useContext(ModelsContext);
  const { phase } = useContext(PhaseContext);
  const phaseContentGroup = useContext(PhaseContentGroupContext);

  const [addPromptOpen, setAddPromptOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveNewField = async (name: string): Promise<void> => {
    setAddPromptOpen(false);
    setSaving(true);

    const defaultFieldType =
      phaseContentGroup === ContentGroupType.RIGHT
        ? FieldType.DATE
        : FieldType.TEXT;

    const fieldData = {
      name,
      type: defaultFieldType,
      position: highestFieldPosition + 1,
      info: '',
      additionalConfig: JSON.stringify({}),
      contentGroup: phaseContentGroup,
    };

    const field = await api.post<EditorFieldWithoutRelations>(
      `editor/challenge-model/fields?sectionId=${phase.id}`,
      fieldData,
    );

    setModels(models => {
      const model = models[modelType];

      model.fields[field.id] = {
        ...field,
        groups: [],
        parts: [],
      };

      model.sections[phase.id].fields.push({ id: field.id });
    });

    setSaving(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setAddPromptOpen(true)}
        startIcon={<AddIcon />}
        disabled={saving}
        fullWidth
      >
        <FormattedMessage id="editor.model_editor.add_field.button" />
      </Button>
      <Space height={18} />
      <Prompt
        open={addPromptOpen}
        onCancel={() => setAddPromptOpen(false)}
        onOk={saveNewField}
        OkButton={Dialog.Actions.CreateButton}
        emptyError="prompt.errors.name_empty"
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.add_field.title" />
        </Dialog.Title>
        <Prompt.TextField
          label={<FormattedMessage id="editor.model_editor.add_field.label" />}
        />
      </Prompt>
    </>
  );
};

export default AddModelField;
