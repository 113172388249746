import styled from '@emotion/styled';
import { IconButton, IconButtonProps } from '@mui/material';
import { ArrowBack as BackIcon } from '@mui/icons-material';

const StyledIconButton = styled(IconButton)({
  marginTop: -12,
  marginBottom: -12,
});

const BackButton = (props: Omit<IconButtonProps, 'children'>) => (
  <StyledIconButton size="large" edge="start" {...props}>
    <BackIcon />
  </StyledIconButton>
);

export default BackButton;
