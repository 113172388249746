import React from 'react';

type WeekMapperProps = {
  weekCount: number;
  children: (numberOfWeeksFromRangeStartDate: number) => React.ReactNode;
};

const WeekMapper = ({ weekCount, children }: WeekMapperProps) =>
  new Array(weekCount)
    .fill(null)
    .map((_, numberOfWeeksFromRangeStartDate) =>
      children(numberOfWeeksFromRangeStartDate),
    );

export default WeekMapper;
