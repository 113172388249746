import React, { useContext } from 'react';
import KeyValueEditor from '../utils/KeyValueEditor';
import AdditionalConfigStateContext from '../../AdditionalConfigStateContext';

type LinkButtonConfigFieldEditorProps = {
  id: 'label' | 'link' | 'iconUrl';
};

const LinkButtonConfigFieldEditor = ({
  id,
}: LinkButtonConfigFieldEditorProps) => {
  const [additionalConfig, setAdditionalConfig] = useContext(
    AdditionalConfigStateContext,
  );

  const value = additionalConfig.linkButton?.[id] ?? '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setAdditionalConfig(additionalConfig => {
      if (!additionalConfig.linkButton) {
        additionalConfig.linkButton = {};
      }

      additionalConfig.linkButton[id] = e.target.value;
    });
  };

  return (
    <KeyValueEditor.Wrapper>
      <KeyValueEditor.Label
        id={`editor.model_editor.field_editor.link_button_editor.config_fields.${id}.label`}
      />
      <KeyValueEditor.TextField
        placeholderId={`editor.model_editor.field_editor.link_button_editor.config_fields.${id}.input_placeholder`}
        value={value}
        onChange={handleChange}
      />
    </KeyValueEditor.Wrapper>
  );
};

export default LinkButtonConfigFieldEditor;
