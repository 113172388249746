import { useContext } from 'react';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import ChallengeContext from '../../ChallengeContext';
import useApi from '@/lib/api/useApi';
import InnerPhaseSelect from './InnerPhaseSelect';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import { ChallengeWithoutRelations } from '@/lib/types';
import NewlySharedContext from '@/components/ChallengePortfolio/NormalView/NewlySharedChallenges/NewlySharedContext';

const PhaseSelect = () => {
  const api = useApi();

  const [isNewlyShared, , setNewlySharedChallenges] =
    useContext(NewlySharedContext);
  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const [challenge, setChallenge] = useContext(ChallengeContext);

  const savePhaseChange = async (phase: KanbanPhase): Promise<void> => {
    const updatedChallenge = await api.put<ChallengeWithoutRelations>(
      `challenge-portfolio/challenges/${challenge.id}/phase`,
      {
        phase,
      },
    );

    setChallenge(challenge => {
      challenge.phase = updatedChallenge.phase;
    });

    if (isNewlyShared) {
      setNewlySharedChallenges(newlySharedChallenges => {
        newlySharedChallenges[challenge.id].phase = phase;
      });
    } else {
      setChallengePortfolio(challengePortfolio => {
        challengePortfolio.challenges[challenge.id].phase = phase;
      });
    }
  };

  return (
    <InnerPhaseSelect
      originalPhase={challenge.phase}
      onSave={savePhaseChange}
    />
  );
};

export default PhaseSelect;
