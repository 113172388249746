import { useState } from 'react';
import ChallengeViewCardWithPageOverlay from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeViewCardWithPageOverlay';
import TaskBoard from '@/components/ChallengePortfolio/NormalView/TaskBoard';
import ChallengeContentWrapper from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeContentWrapper';
import ChallengeHeader from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeHeader';
import ChallengeInfo from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeInfo';
import MobilePhaseSelect from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/MobilePhaseSelect';
import ChallengeContext from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeContext';
import ChallengeFields from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeFields';
import { Challenge, SetComplexImmerStateAction } from '@/lib/types';

type CustomChallengeViewProps = {
  challenge: Challenge;
  setChallenge: SetComplexImmerStateAction<Challenge>;
  onClose: () => void;
};

const CustomChallengeView = ({
  challenge,
  setChallenge,
  onClose,
}: CustomChallengeViewProps) => {
  const [taskBoardOpen, setTaskBoardOpen] = useState(false);

  const closeChallengeOrTaskBoard = () =>
    taskBoardOpen ? setTaskBoardOpen(false) : onClose();

  return (
    <ChallengeViewCardWithPageOverlay
      taskBoardOpen={taskBoardOpen}
      onClose={closeChallengeOrTaskBoard}
    >
      <ChallengeContext.Provider value={[challenge, setChallenge]}>
        {taskBoardOpen ? (
          <TaskBoard />
        ) : (
          <ChallengeContentWrapper>
            <ChallengeHeader
              onTasksOpen={() => setTaskBoardOpen(true)}
              onCloseChallenge={onClose}
            />
            <ChallengeInfo />
            <MobilePhaseSelect />
            <ChallengeFields />
          </ChallengeContentWrapper>
        )}
      </ChallengeContext.Provider>
    </ChallengeViewCardWithPageOverlay>
  );
};

export default CustomChallengeView;
