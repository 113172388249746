import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import React from 'react';

const configuration = {
  auth: {
    clientId: import.meta.env.VITE_MICROSOFT_OAUTH_CLIENT_ID,
    redirectUri: '/oauth_redirect.html',
  },
} satisfies Configuration;

const publicClientApplication = new PublicClientApplication(configuration);

type MicrosoftAuthConfigProviderProps = {
  children: React.ReactNode;
};

const MicrosoftAuthConfigProvider = ({
  children,
}: MicrosoftAuthConfigProviderProps) => (
  <MsalProvider instance={publicClientApplication}>{children}</MsalProvider>
);

export default MicrosoftAuthConfigProvider;
