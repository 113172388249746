import { useContext } from 'react';
import useApi from '@/lib/api/useApi';
import InnerPhaseSelect from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/MobilePhaseSelect/PhaseSelect/InnerPhaseSelect';
import TaskContext from '../TaskContext';
import ChallengeContext from '@/components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeContext';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import { TaskWithoutRelations } from '@/lib/types';

const PhaseSelect = () => {
  const api = useApi();

  const [, setChallenge] = useContext(ChallengeContext);
  const [task, setTask] = useContext(TaskContext);

  const savePhaseChange = async (phase: KanbanPhase) => {
    const updatedTask = await api.put<TaskWithoutRelations>(
      `challenge-portfolio/tasks/${task.id}/phase`,
      {
        phase,
      },
    );

    setTask(task => {
      task.phase = updatedTask.phase;
    });

    setChallenge(challenge => {
      challenge.tasks[task.id].phase = phase;
    });
  };

  return (
    <InnerPhaseSelect originalPhase={task.phase} onSave={savePhaseChange} />
  );
};

export default PhaseSelect;
