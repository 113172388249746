import React, { createContext, useContext } from 'react';
import App from '@/lib/App';
import {
  EditorPortfolioTemplates,
  ReactContextWithAutomatedProvider,
} from '@/lib/types';
import EditorPortfolioTemplatesContext from '../EditorPortfolioTemplatesContext';

const UseAggressiveRubricCollapsingContext = createContext(
  false,
) as ReactContextWithAutomatedProvider<boolean>;

const shouldUseAggressiveRubricCollapsingForPerformance = (
  portfolioTemplates: EditorPortfolioTemplates,
): boolean => {
  const totalItemCount =
    Object.keys(portfolioTemplates.topics).length +
    Object.keys(portfolioTemplates.subtopics).length +
    Object.keys(portfolioTemplates.parts).length;

  return totalItemCount > 100;
};

type AutomatedUseAggressiveRubricCollapsingContextProviderProps = {
  children: React.ReactNode;
};

const AutomatedUseAggressiveRubricCollapsingContextProvider = ({
  children,
}: AutomatedUseAggressiveRubricCollapsingContextProviderProps) => {
  const aggressiveRubricCollapsingContentSettingEnabled =
    App.instanceConfig.useSetting('enableAggressiveRubricCollapsing');

  const [portfolioTemplates] = useContext(EditorPortfolioTemplatesContext);

  return (
    <UseAggressiveRubricCollapsingContext.Provider
      value={
        aggressiveRubricCollapsingContentSettingEnabled ||
        shouldUseAggressiveRubricCollapsingForPerformance(portfolioTemplates)
      }
    >
      {children}
    </UseAggressiveRubricCollapsingContext.Provider>
  );
};

UseAggressiveRubricCollapsingContext.AutomatedProvider =
  AutomatedUseAggressiveRubricCollapsingContextProvider;

export default UseAggressiveRubricCollapsingContext;
