import { Chip, ChipProps } from '@mui/material';
import styled from '@emotion/styled'

type StyledChipProps = Omit<ChipProps, 'color' | 'style'>;

const InnerStyledChip = styled(Chip)({
  marginRight: 6,
  marginBottom: 6,
})

const StyledChip = (props: StyledChipProps) => (
  <InnerStyledChip color="primary" {...props} />
);

export default StyledChip;
