import InnerUserAvatar from './InnerUserAvatar';
import { UserSummary } from '@/lib/types';

type UserAvatarProps = {
  user: UserSummary;
};

const UserAvatar = ({ user }: UserAvatarProps) => (
  <InnerUserAvatar user={user} size={24} />
);

export default UserAvatar;
