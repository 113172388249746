import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const TitleSubtopicIcon = styled('img')({
  marginRight: 16,
  width: 40,
  height: 40,
  [mediaQuery(560)]: {
    display: 'none',
  },
});

export default TitleSubtopicIcon;
