import { useContext } from 'react';
import PortfolioUserContext from '@/lib/contexts/PortfolioUserContext';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import { RubricsPortfolio, SetComplexImmerStateAction } from '@/lib/types';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

const useLivePortfolio = ():
  | [true, null, () => void]
  | [false, RubricsPortfolio, SetComplexImmerStateAction<RubricsPortfolio>] => {
  const portfolioMode = useContext(PortfolioModeContext);
  const portfolioUser = useContext(PortfolioUserContext);

  const portfolioUrl =
    portfolioMode === PortfolioMode.COACH
      ? `portfolio/coach?userEmail=${portfolioUser!.email}`
      : 'portfolio';

  const [loading, portfolio, setPortfolio] =
    usePolledSwrAsComplexState<RubricsPortfolio>(portfolioUrl);

  if (loading) {
    return [true, portfolio, setPortfolio];
  }

  return [false, portfolio, setPortfolio];
};

export default useLivePortfolio;
