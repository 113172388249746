import styled from '@emotion/styled';

const SettingsBoxTitle = styled('h3')(({ theme }) => ({
  marginBottom: 18,
  color: theme.palette.primary.main,
  fontWeight: 500,
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'center',
}));

export default SettingsBoxTitle;
