import HideOnLowWidth from '@/components/HideOnLowWidth';
import { IconButton, IconButtonProps } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

type EditButtonProps = Omit<IconButtonProps, 'size' | 'children'>;

const EditButton = (props: EditButtonProps) => (
  <HideOnLowWidth width={640}>
    <IconButton size="large" {...props}>
      <EditIcon />
    </IconButton>
  </HideOnLowWidth>
);

export default EditButton;
