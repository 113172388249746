import styled from '@emotion/styled';

const NotFoundTitle = styled('h2')(({ theme }) => ({
  padding: 24,
  flex: 1,
  color: theme.palette.text.secondary,
  transition: theme.transitions.create(['color']),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default NotFoundTitle;
