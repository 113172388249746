import styled from '@emotion/styled';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

type StyledChallengeNameProps = {
  accountForBadge: boolean;
};

const StyledChallengeName = styled('h4')<StyledChallengeNameProps>(
  ({ accountForBadge }) => ({
    marginBottom: 6,
    paddingRight: accountForBadge ? 8 : 0,
    minHeight: 15,
    maxHeight: 43,
    fontWeight: 400,
    wordWrap: 'break-word',
    overflow: 'hidden',
  }),
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    outline: `2px solid ${theme.palette.background.paper}`,
  })),
);

export default StyledChallengeName;
