import { useContext } from 'react';
import InnerDateField from '../DateField/InnerDateField';
import isLater from './utils/isLater';
import convertToTimestamp from './utils/convertToTimestamp';
import useSavePortfolioItemDate from './utils/useSavePortfolioItemDate';
import FieldRendererContext from '../../../FieldRendererContext';
import moment from 'moment';

const PlannedStartDate = () => {
  const { portfolioItem, portfolioItemApiEndpoint } = useContext(
    FieldRendererContext,
  );

  const savePortfolioItemDate = useSavePortfolioItemDate(
    `${portfolioItemApiEndpoint}/dates/planned-start-date`,
  );

  return (
    <InnerDateField
      initialValue={convertToTimestamp(portfolioItem.plannedStartDate)}
      onSave={savePortfolioItemDate}
      shouldDisableDate={date =>
        !!portfolioItem.plannedEndDate &&
        isLater(date, moment(portfolioItem.plannedEndDate))
      }
      clearable
    />
  );
};

export default PlannedStartDate;
