import CollapsibleActionMenu from '@/components/CollapsibleActionMenu';
import { ListItemIcon } from '@mui/material';
import { Group as GroupIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { ActionMenuButtonProps } from '@/components/CollapsibleActionMenu/ActionMenuButton';

type GroupsManagerButtonProps = Omit<ActionMenuButtonProps, 'children'>;

const GroupsManagerButton = (props: GroupsManagerButtonProps) => (
  <CollapsibleActionMenu.Button {...props}>
    <ListItemIcon>
      <GroupIcon />
    </ListItemIcon>
    <FormattedMessage id="editor.model_editor.actions.manage_groups" />
  </CollapsibleActionMenu.Button>
);

export default GroupsManagerButton;
