import styled from '@emotion/styled';
import { alpha } from '@mui/material';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

type StyledHtmlProps = {
  darkText: boolean;
};

const StyledHtml = styled('div')<StyledHtmlProps>(
  ({ theme, darkText }) => ({
    paddingTop: 12,
    color: darkText
      ? theme.palette.text.primary
      : alpha(theme.palette.text.primary, 0.7),
    wordBreak: 'break-word',
    p: {
      marginTop: 0,
      marginBottom: 0,
    },
  }),
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.text.primary,
  })),
);

export default StyledHtml;
