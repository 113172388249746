import { useContext, useMemo } from 'react';
import PerspectiveSublevelPortfolioContext from '../PerspectiveSublevelPortfolioContext';
import { buildAttachmentInfo } from '../PerspectiveCard/usePerspectiveAttachmentInfo';
import { AttachmentInfo, PerspectiveSublevel } from '@/lib/types';

const usePerspectiveSublevelAttachmentInfo = (
  sublevel: PerspectiveSublevel,
  nestingIndex: number,
): AttachmentInfo => {
  const [perspectiveSublevelPortfolio] = useContext(
    PerspectiveSublevelPortfolioContext,
  );

  const unprocessedAttachmentInfo =
    perspectiveSublevelPortfolio.get(sublevel.id) ?? null;

  const attachmentInfo = useMemo(
    () => buildAttachmentInfo(unprocessedAttachmentInfo, nestingIndex),
    [unprocessedAttachmentInfo, nestingIndex],
  );

  return attachmentInfo;
};

export default usePerspectiveSublevelAttachmentInfo;
