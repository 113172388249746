import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';
import isPropValid from '@emotion/is-prop-valid';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';
import React from 'react';

type ContentWrapperProps = {
  inRightPhaseContentGroup: boolean;
  children: React.ReactNode;
};

const belowButtonStyles = {
  top: 45,
  right: '50%',
  transform: 'translateX(50%)',
};

const ContentWrapper = styled(Paper, {
  shouldForwardProp: isPropValid,
})<ContentWrapperProps>(
  ({ theme }) => ({
    position: 'absolute',
    padding: 16,
    backgroundColor: theme.palette.increaseContrastWithBackground(
      theme.palette.background.paper,
      0.1,
    ),
    boxShadow: theme.shadows[12],
    zIndex: 1,
    ...belowButtonStyles,
  }),
  ({ inRightPhaseContentGroup }) => {
    if (inRightPhaseContentGroup) {
      return {
        top: 0,
        right: 164,
        transform: 'none',
        [mediaQuery(640)]: belowButtonStyles,
      };
    }
  },
  addBorderInHighContrastMode,
);

export default ContentWrapper;
