import React, { useContext, useState } from 'react';
import OptionFormWrapper from './OptionFormWrapper';
import { TextField, IconButton } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import AdditionalConfigStateContext from '../../../AdditionalConfigStateContext';
import { v4 as generateUuid } from 'uuid';

const AddOptionForm = () => {
  const [, setAdditionalConfig] = useContext(AdditionalConfigStateContext);
  const [newOption, setNewOption] = useState('');

  const saveNewOption = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (newOption.trim().length === 0) {
      return;
    }

    setNewOption('');

    setAdditionalConfig(additionalConfig => {
      if (typeof additionalConfig.options === 'undefined') {
        additionalConfig.options = {};
      }

      additionalConfig.options[generateUuid()] = {
        name: newOption.trim(),
      };
    });
  };

  const intl = useIntl();

  return (
    <OptionFormWrapper onSubmit={saveNewOption}>
      <TextField
        variant="standard"
        placeholder={intl.formatMessage({
          id:
            'editor.model_editor.field_editor.select_editor.add_option.input_placeholder',
        })}
        value={newOption}
        onChange={e => setNewOption(e.target.value)}
        fullWidth
      />
      <IconButton type="submit" disabled={!newOption.trim()}>
        <CheckIcon />
      </IconButton>
    </OptionFormWrapper>
  );
};

export default AddOptionForm;
