import { useContext } from 'react';
import FieldRendererContext from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/FieldRendererContext';
import useApi from '@/lib/api/useApi';
import {
  SetComplexImmerStateAction,
  Task,
  TaskSummary,
  UserSummary,
} from '@/lib/types';

const useRemoveAssignee = (): ((user: UserSummary) => Promise<void>) => {
  const api = useApi();

  const {
    setPortfolioItem: setTask,
    setPortfolioItemSummary: setTaskSummary,
    portfolioItemApiEndpoint: taskApiEndpoint,
  } = useContext(FieldRendererContext) as {
    setPortfolioItem: SetComplexImmerStateAction<Task>;
    setPortfolioItemSummary: SetComplexImmerStateAction<TaskSummary>;
    portfolioItemApiEndpoint: string;
  };

  const removeAssignee = async (user: UserSummary): Promise<void> => {
    await api.post(`${taskApiEndpoint}/assignees/remove`, {
      email: user.email,
    });

    setTask(task => {
      task.assigneesCount--;
      task.assignees.splice(
        task.assignees.findIndex(assignee => assignee.email === user.email),
        1,
      );
    });

    setTaskSummary(taskSummary => {
      taskSummary.assignees.splice(
        taskSummary.assignees.findIndex(
          assignee => assignee.email === user.email,
        ),
        1,
      );
    });
  };

  return removeAssignee;
};

export default useRemoveAssignee;
