import { useContext, useState } from 'react';
import FeedbackItemActionButton from './FeedbackItemActionButton';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import FieldRendererContext from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/FieldRendererContext';
import useApi from '@/lib/api/useApi';
import { CoachComment } from '@/lib/types';

type DeleteFeedbackItemProps = {
  feedbackItem: CoachComment;
};

const DeleteFeedbackItem = ({ feedbackItem }: DeleteFeedbackItemProps) => {
  const api = useApi();

  const { setPortfolioItem, portfolioItemApiEndpoint } =
    useContext(FieldRendererContext);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteFeedbackItem = async (): Promise<void> => {
    setConfirmationDialogOpen(false);

    setDeleting(true);

    await api.delete(
      `${portfolioItemApiEndpoint}/coach-comments/${feedbackItem.id}`,
    );

    setPortfolioItem(portfolioItem => {
      delete portfolioItem.coachComments[feedbackItem.id];
    });

    setDeleting(false);
  };

  return (
    <>
      <FeedbackItemActionButton
        onClick={() => setConfirmationDialogOpen(true)}
        edge="end"
        disabled={deleting}
        dangerous
      >
        <DeleteIcon fontSize="small" />
      </FeedbackItemActionButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.challenge.coach_feedback.feedback_item.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio.challenge.coach_feedback.feedback_item.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteFeedbackItem} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeleteFeedbackItem;
