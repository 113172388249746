import useKeydownListener from '@/lib/utils/useKeydownListener';

type ArrowKeyListenersConfig = {
  onLeftArrowPress: (event: KeyboardEvent) => void;
  onRightArrowPress: (event: KeyboardEvent) => void;
};

const useArrowKeyListeners = ({
  onLeftArrowPress,
  onRightArrowPress,
}: ArrowKeyListenersConfig): void => {
  useKeydownListener(document, (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      onLeftArrowPress(event);
    }

    if (event.key === 'ArrowRight') {
      onRightArrowPress(event);
    }
  });
};

export default useArrowKeyListeners;
