import StyledGroupSelectorButton from './StyledGroupSelectorButton';
import GroupSelectorButtonLabel from './GroupSelectorButtonLabel';
import GroupSelectorButtonDropdownArrow from './GroupSelectorButtonDropdownArrow';
import { FormattedMessage } from 'react-intl';
import { Group } from '@/lib/types';
import { ButtonProps } from '@mui/material';

type GroupSelectorButtonProps = ButtonProps & {
  selectedGroup: Group | null;
  isSelectingGroup: boolean;
};

const GroupSelectorButton = ({
  selectedGroup,
  isSelectingGroup,
  ...props
}: GroupSelectorButtonProps) => (
  <StyledGroupSelectorButton hasSelectedGroup={!!selectedGroup} {...props}>
    <GroupSelectorButtonLabel>
      {selectedGroup?.name ?? (
        <FormattedMessage id="user_switcher.group_selector.button.placeholder" />
      )}
    </GroupSelectorButtonLabel>
    <GroupSelectorButtonDropdownArrow rotated={isSelectingGroup} />
  </StyledGroupSelectorButton>
);

export default GroupSelectorButton;
