import styled from '@emotion/styled';
import ButtonBase from '@/components/ButtonBase';
import mediaQuery from '@/lib/styling/mediaQuery';

type StyledGroupProps = {
  selected: boolean;
};

const StyledGroup = styled(ButtonBase)<StyledGroupProps>(
  ({ theme }) => ({
    margin: 18,
    color: theme.palette.text.secondary,
    fontFamily: 'CircularStd',
    ':hover, :focus': {
      color: theme.palette.text.primary,
    },
    [mediaQuery(640)]: {
      margin: 0,
      paddingTop: 9,
      paddingBottom: 9,
    },
  }),
  ({ theme, selected }) => {
    if (selected) {
      return {
        fontWeight: 500,
        color: theme.palette.text.primary,
      };
    }
  },
);

export default StyledGroup;
