import PerspectiveIcon from '@/components/perspectives/PerspectiveIcon';
import PerspectiveIndicatorWrapper from './PerspectiveIndicatorWrapper';
import PerspectiveChip from './PerspectiveChip';
import ManagePerspectivesButton from './ManagePerspectivesButton';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import { PerspectiveSummary } from '@/lib/types';
import React from 'react';

type PerspectiveIndicatorsProps = {
  perspectives: PerspectiveSummary[];
  onManage?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
};

const PerspectiveIndicators = ({
  perspectives,
  onManage,
  loading,
}: PerspectiveIndicatorsProps) => (
  <PerspectiveIndicatorWrapper>
    {perspectives.map(perspective => (
      <PerspectiveChip
        label={perspective.name}
        perspectiveColor={perspective.color}
        avatar={
          perspective.icon === null ? undefined : (
            <PerspectiveIcon src={perspective.icon} size={32} />
          )
        }
        key={perspective.id}
      />
    ))}
    <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
      {onManage && (
        <ManagePerspectivesButton
          onClick={onManage}
          loading={loading ?? false}
        />
      )}
    </RestrictedToPortfolioMode>
  </PerspectiveIndicatorWrapper>
);

export default PerspectiveIndicators;
