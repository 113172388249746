import { useContext } from 'react';
import VisibleWeekRangeContext from '../../../VisibleWeekRangeContext';
import WeekMarkingBackgroundLines from './WeekMarkingBackgroundLines';
import DayMarkingBackgroundLines from './DayMarkingBackgroundLines';
import HistoryIndicatingShade from './HistoryIndicatingShade';
import Challenge from './Challenge';
import { Row } from '@/lib/types';

type ChallengePlanningProps = {
  row: Row;
};

const ChallengePlanning = ({ row }: ChallengePlanningProps) => {
  const { isZoomedInOnWeek } = useContext(VisibleWeekRangeContext);

  return (
    <section>
      <WeekMarkingBackgroundLines />
      {isZoomedInOnWeek && <DayMarkingBackgroundLines />}
      <HistoryIndicatingShade />
      {row.challenges.map(challengeId => (
        <Challenge challengeId={challengeId} key={challengeId} />
      ))}
    </section>
  );
};

export default ChallengePlanning;
