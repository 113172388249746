import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const RowName = styled('h4')({
  fontWeight: 400,
  [mediaQuery(700)]: {
    fontSize: 18,
  },
});

export default RowName;
