import styled from '@emotion/styled';
import ButtonBase from '@/components/ButtonBase';
import { ButtonBaseProps, darken } from '@mui/material';

type PlanningBarProps = ButtonBaseProps & {
  challengeDistanceFromLeft: number;
  challengeWidth: number;
  visible: boolean;
};

const PlanningBar = styled(ButtonBase)<PlanningBarProps>(
  ({ theme, challengeDistanceFromLeft, challengeWidth, visible }) => ({
    position: 'relative',
    left: `${challengeDistanceFromLeft}%`,
    marginBottom: visible ? 2 : 0,
    marginLeft: visible ? 1 : 0,
    marginRight: visible ? 1 : 0,
    width: `calc(${challengeWidth}% - 2px)`,
    height: visible ? 24 : 0,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    display: 'flex',
    boxSizing: 'border-box',
    transition: theme.transitions.simple,
    transitionProperty:
      'left, margin, width, height, background-color, transform, opacity',
    opacity: visible ? 1 : 0,
    ':hover, :focus': {
      backgroundColor: darken(theme.palette.primary.main, 0.15),
    },
    ':active': {
      backgroundColor: darken(theme.palette.primary.main, 0.3),
    },
    ':hover .challenge-preview-card, :focus .challenge-preview-card': {
      transform: 'translateX(calc(-100% - 12px))',
      padding: 12,
      height: 'auto',
      opacity: 1,
      zIndex: 2,
    },
  }),
);

export default PlanningBar;
