import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';

const StyledHeader = styled('header')(
  ({ theme }) => ({
    padding: '4px 36px',
    fontSize: 15,
    fontFamily: 'CircularStd',
    backgroundColor: theme.palette.background.shaded,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [mediaQuery(768)]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    [mediaQuery(450)]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  }),
  addHighContrastStyles(({ theme }) => ({
    borderBottom: `2px solid ${theme.palette.divider}`,
  })),
);

export default StyledHeader;
