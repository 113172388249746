import matchesSearch from '@/lib/utils/matchesSearch';
import sortByRole from './sortByRole';
import { SetComplexImmerStateAction, UserWithId } from '@/lib/types';
import usePolledSwrAsComplexState from '@/lib/api/usePolledSwrAsComplexState';

const useUsers = (
  searchQuery: string | null,
):
  | [true, [], [], () => void]
  | [
      false,
      UserWithId[],
      UserWithId[],
      SetComplexImmerStateAction<UserWithId[]>,
    ] => {
  const [loading, users, setUsers] =
    usePolledSwrAsComplexState<UserWithId[]>('users');

  if (loading || !users) {
    return [loading, [], [], () => {}];
  }

  const sortedUsers = [...users].sort(sortByRole);
  const usersWithSearchMatching = sortedUsers.filter(user =>
    matchesSearch(user.name, searchQuery ?? ''),
  );

  return [loading, usersWithSearchMatching, sortedUsers, setUsers];
};

export default useUsers;
