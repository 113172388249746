import { useState } from 'react';
import useTaskDrag from './useTaskDrag';
import TaskCard from './TaskCard';
import TaskView from './TaskView';
import setBodyOverflow, { Overflow } from '@/lib/styling/setBodyOverflow';
import { TaskSummary } from '@/lib/types';

type TaskProps = {
  task: TaskSummary;
  disableDrag?: boolean;
};

const Task = ({ task, disableDrag }: TaskProps) => {
  const [isDragging, attachDragHandlingRef] = useTaskDrag(
    task,
    disableDrag ?? false,
  );
  const [open, setOpen] = useState(false);

  return (
    <>
      <TaskCard
        task={task}
        dragging={isDragging}
        onClick={() => {
          setOpen(true);
          setBodyOverflow(Overflow.HIDDEN);
        }}
        ref={attachDragHandlingRef}
      />
      {open && <TaskView task={task} onClose={() => setOpen(false)} />}
    </>
  );
};

export default Task;
