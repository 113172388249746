import HideOnLowWidth from '@/components/HideOnLowWidth';
import { Tooltip, IconButton, IconButtonProps } from '@mui/material';
import { FileCopyOutlined as CopyIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

type CopyButtonProps = Omit<IconButtonProps, 'children'>;

const CopyButton = (props: CopyButtonProps) => (
  <HideOnLowWidth width={640}>
    <Tooltip
      title={<FormattedMessage id="portfolio.task.copy.button_tooltip" />}
    >
      <IconButton {...props}>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  </HideOnLowWidth>
);

export default CopyButton;
