import styled from '@emotion/styled';

const PerspectiveIndicatorWrapper = styled('ul')(({ theme }) => ({
  margin: 0,
  marginTop: -28,
  // Add additional space when MobilePhaseSelect is visible
  marginBottom: theme.isMobile ? 24 : 8,
  padding: 0,
  paddingTop: 16,
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
}));

export default PerspectiveIndicatorWrapper;
