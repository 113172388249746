import ButtonsWrapper from './ButtonsWrapper';
import PillButton from './PillButton';

// TODO why don't we just use TS namespaces for this stuff?
const PillButtons = {
  Wrapper: ButtonsWrapper,
  Button: PillButton,
} as const;

export default PillButtons;
