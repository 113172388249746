import SelectFieldPlaceholder from '@/components/SelectFieldPlaceholder';
import WorldLabel from './WorldLabel';
import { Select, SelectProps } from '@mui/material';
import useIsPortfolioReadOnly from '@/components/ChallengePortfolio/NormalView/useIsPortfolioReadOnly';
import React from 'react';
import { AgoraWorld } from '../worlds';

type WorldSelectFieldProps = Omit<
  SelectProps<AgoraWorld[]>,
  | 'variant'
  | 'placeholder'
  | 'disabled'
  | 'renderValue'
  | 'style'
  | 'displayEmpty'
  | 'multiple'
> & {
  placeholder: React.ReactNode;
  disabled: boolean;
};

// TODO remove inline styles
const selectStyles = {
  marginBottom: 12,
  width: 320,
  maxWidth: '100%',
};

function WorldSelectField({
  placeholder,
  disabled,
  ...props
}: WorldSelectFieldProps) {
  const renderSelectedWorldsOrPlaceholder = (
    selectedWorlds: AgoraWorld[],
  ): React.ReactNode => {
    if (selectedWorlds.length === 0) {
      return <SelectFieldPlaceholder>{placeholder}</SelectFieldPlaceholder>;
    }

    return selectedWorlds.map(world => (
      <WorldLabel world={world} key={world} />
    ));
  };

  const portfolioIsReadOnly = useIsPortfolioReadOnly();

  return (
    <Select<AgoraWorld[]>
      variant="standard"
      disabled={portfolioIsReadOnly || disabled}
      renderValue={renderSelectedWorldsOrPlaceholder}
      style={selectStyles}
      displayEmpty
      multiple
      {...props}
    />
  );
}

export default WorldSelectField;
