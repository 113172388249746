import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import mediaQuery from '@/lib/styling/mediaQuery';
import { OmitFramerProps, HTMLDivProps } from '@/lib/types';

type ChallengeContentWrapperProps = OmitFramerProps<HTMLDivProps>;

const StyledContentWrapper = styled(motion.section)({
  padding: '24px 48px',
  flex: 4,
  overflowY: 'auto',
  [mediaQuery(560)]: {
    padding: 24,
  },
});

const ChallengeContentWrapper = (props: ChallengeContentWrapperProps) => (
  <StyledContentWrapper
    initial={{
      y: 10,
      opacity: 0,
    }}
    animate={{
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.1,
        delay: 0.15,
      },
    }}
    {...props}
  />
);

export default ChallengeContentWrapper;
