import { ConnectDropTarget, useDrop } from 'react-dnd';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import { Row } from '@/lib/types';
import { ChallengeDragItem } from '../../Challenge/useChallengeDrag';
import DragType from '../../DragType.enum';

export type ChallengeDropResult =
  | Readonly<{
      shouldDrop: false;
      newPhase: null;
      newRow: null;
    }>
  | Readonly<{
      shouldDrop: true;
      newPhase: KanbanPhase;
      newRow: Row;
    }>;

const useChallengePhaseDrop = (
  phase: KanbanPhase,
  row: Row,
): ConnectDropTarget => {
  const [, attachDropHandlingRef] = useDrop({
    accept: DragType.CHALLENGE,
    drop: (draggedItem: ChallengeDragItem): ChallengeDropResult => {
      const { phase: oldPhase, row: oldRow } = draggedItem;

      if (row.id === oldRow?.id && phase === oldPhase) {
        return {
          shouldDrop: false,
          newPhase: null,
          newRow: null,
        };
      }

      return {
        shouldDrop: true,
        newPhase: phase,
        newRow: row,
      };
    },
  });

  return attachDropHandlingRef;
};

export default useChallengePhaseDrop;
