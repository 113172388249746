import { useContext, useState } from 'react';
import FieldName from '../utils/FieldName';
import PickColorButton from './PickColorButton';
import { ClickAwayListener } from '@mui/material';
import PickerContent from './PickerContent';
import ColorPreview from './ColorPreview';
import { FormattedMessage } from 'react-intl';
import usePortfolioItemColor from './usePortfolioItemColor';
import useApi from '@/lib/api/useApi';
import FieldRendererContext from '../../../FieldRendererContext';
import useIsPortfolioReadOnly from '@/components/ChallengePortfolio/NormalView/useIsPortfolioReadOnly';
import { ChallengeWithoutRelations, TaskWithoutRelations } from '@/lib/types';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

const ColorPicker = () => {
  const api = useApi();

  const {
    setPortfolioItem,
    setPortfolioItemSummary,
    portfolioItemApiEndpoint,
  } = useContext(FieldRendererContext);

  const portfolioIsReadOnly = useIsPortfolioReadOnly();

  const portfolioItemColor = usePortfolioItemColor();
  const [selectedColor, setSelectedColor, , reenableSelectedColorSync] =
    useComplexSwrState(portfolioItemColor);

  const [pickerOpen, setPickerOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleSave = async (): Promise<void> => {
    if (!pickerOpen) {
      return;
    }

    setPickerOpen(false);

    if (portfolioItemColor === selectedColor) {
      return;
    }

    setSaving(true);

    const updatedPortfolioItem = await api.put<
      ChallengeWithoutRelations | TaskWithoutRelations
    >(`${portfolioItemApiEndpoint}/color`, {
      color: selectedColor,
    });

    setPortfolioItem(portfolioItem => {
      portfolioItem.color = updatedPortfolioItem.color;
    });

    setPortfolioItemSummary(portfolioItemSummary => {
      portfolioItemSummary.color = updatedPortfolioItem.color;
    });

    setSaving(false);

    reenableSelectedColorSync();
  };

  const handleButtonClick = (): void => {
    if (!pickerOpen) {
      setPickerOpen(true);

      return;
    }

    handleSave();
  };

  return (
    <section>
      <FieldName />
      <ClickAwayListener onClickAway={handleSave}>
        <div style={{ position: 'relative' }}>
          <PickColorButton
            color="secondary"
            onClick={handleButtonClick}
            disabled={portfolioIsReadOnly || saving}
          >
            <ColorPreview color={selectedColor} />
            <FormattedMessage id="portfolio.challenge.color_picker.button" />
          </PickColorButton>
          {pickerOpen && (
            <PickerContent
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
          )}
        </div>
      </ClickAwayListener>
    </section>
  );
};

export default ColorPicker;
