import styled from '@emotion/styled';

const ColorBoxesWrapper = styled('section')({
  margin: -3,
  width: 300,
  display: 'flex',
  flexWrap: 'wrap',
});

export default ColorBoxesWrapper;
