import styled from '@emotion/styled';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';

type ColorCircleProps = {
  color: string;
};

const ColorCircle = styled('span')<ColorCircleProps>(
  ({ color }) => ({
    marginRight: 7,
    width: 20,
    height: 20,
    backgroundColor: color,
    display: 'inline-block',
    borderRadius: '100%',
  }),
  addBorderInHighContrastMode,
);

export default ColorCircle;
