import React, { ForwardedRef, forwardRef } from 'react';
import StyledSliderRail from './StyledSliderRail';

// TODO better name & move?
export type MouseMoveEvent =
  | React.MouseEvent<HTMLDivElement>
  | React.TouchEvent<HTMLDivElement>;

type SliderRailProps = {
  levelCount: number;
  onMouseMoveActivate: (event: MouseMoveEvent) => void;
  onMouseMoveDeactivate: (event: MouseMoveEvent) => void;
  onMouseMove: (event: MouseMoveEvent) => void;
  // TODO should be readonly (lowercase)
  readOnly: boolean;
  disabled: boolean;
  children: React.ReactNode;
};

const SliderRail = forwardRef(
  (
    {
      onMouseMoveActivate,
      onMouseMoveDeactivate,
      onMouseMove,
      ...props
    }: SliderRailProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <StyledSliderRail
      onMouseDown={onMouseMoveActivate}
      onMouseUp={onMouseMoveDeactivate}
      onMouseLeave={onMouseMoveDeactivate}
      onMouseMove={onMouseMove}
      onTouchStart={onMouseMoveActivate}
      onTouchEnd={onMouseMoveDeactivate}
      onTouchCancel={onMouseMoveDeactivate}
      onTouchMove={onMouseMove}
      ref={ref}
      {...props}
    />
  ),
);

SliderRail.displayName = 'SliderRail';

export default SliderRail;
