import styled from '@emotion/styled';
import { Button } from '@mui/material';

const StyledAddFeedbackButton = styled(Button)({
  marginBottom: 24,
  marginLeft: 24,
  marginRight: 24,
  width: 'calc(100% - 48px)',
});

export default StyledAddFeedbackButton;
