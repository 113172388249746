import { ForwardedRef, forwardRef } from 'react';
import CollapsibleActionMenu from '@/components/CollapsibleActionMenu';
import { ListItemIcon } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { ActionMenuButtonProps } from '@/components/CollapsibleActionMenu/ActionMenuButton';

type EditRowNameButtonProps = Omit<ActionMenuButtonProps, 'children' | 'ref'>;

const EditRowNameButton = forwardRef(
  (props: EditRowNameButtonProps, ref: ForwardedRef<HTMLLIElement>) => (
    <CollapsibleActionMenu.Button {...props} ref={ref}>
      <ListItemIcon>
        <EditIcon />
      </ListItemIcon>
      <FormattedMessage id="portfolio.row.mobile_row_actions.edit_button" />
    </CollapsibleActionMenu.Button>
  ),
);

EditRowNameButton.displayName = 'EditRowNameButton';

export default EditRowNameButton;
