import { ForwardedRef, forwardRef } from 'react';
import useChallengeSearchQueryMatching from './useChallengeSearchQueryMatching';
import StyledChallengeCard, {
  StyledChallengeCardProps,
} from './StyledChallengeCard';

type ChallengeCardWithSearchMatchingProps = Omit<
  StyledChallengeCardProps,
  'highlighted' | 'faded'
> & {
  challengeName: string;
};

const ChallengeCardWithSearchMatching = forwardRef(
  (
    { challengeName, ...props }: ChallengeCardWithSearchMatchingProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const [faded, highlighted] = useChallengeSearchQueryMatching(challengeName);

    return (
      <StyledChallengeCard
        highlighted={highlighted}
        faded={faded}
        ref={ref}
        {...props}
      />
    );
  },
);

ChallengeCardWithSearchMatching.displayName = 'ChallengeCardWithSearchMatching';

export default ChallengeCardWithSearchMatching;
