import { useContext } from 'react';
import NoChallengesMessage from './NoChallengesMessage';
import Row from './Row';
import ChallengePortfolioContext from '@/components/ChallengePortfolio/ChallengePortfolioContext';
import { IdPositionTuple } from '@/lib/types';

type RowsProps = {
  rowIds: IdPositionTuple[];
};

const Rows = ({ rowIds }: RowsProps) => {
  const [challengePortfolio] = useContext(ChallengePortfolioContext);

  if (rowIds.length === 0) {
    return <NoChallengesMessage />;
  }

  return rowIds.map(([rowId], i) => {
    const previousRowId = rowIds[i - 1]?.[0] ?? null;
    const nextRowId = rowIds[i + 1]?.[0] ?? null;

    return (
      <Row
        row={challengePortfolio.rows[rowId]}
        previousRow={challengePortfolio.rows[previousRowId] ?? null}
        nextRow={challengePortfolio.rows[nextRowId] ?? null}
        key={rowId}
      />
    );
  });
};

export default Rows;
