import IndicatorWrapper from './IndicatorWrapper';
import {
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon,
} from '@mui/icons-material';

type OverflowIndicatorProps = {
  side: 'left' | 'right';
};

const OverflowIndicator = ({ side }: OverflowIndicatorProps) => (
  <IndicatorWrapper side={side}>
    {side === 'left' ? <ArrowLeftIcon /> : <ArrowRightIcon />}
  </IndicatorWrapper>
);

export default OverflowIndicator;
