import styled from '@emotion/styled';

const ToolbarItemsSeparator = styled('span')(({ theme }) => ({
  marginTop: 6,
  marginBottom: 6,
  marginLeft: 16,
  marginRight: 16,
  width: 2,
  height: 20,
  backgroundColor: theme.palette.divider,
}));

export default ToolbarItemsSeparator;
