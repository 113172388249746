import styled from '@emotion/styled';
import { Delete as DeleteIcon } from '@mui/icons-material';

// TODO make delete required in styled DeleteButton components?
type StyledDeleteIconProps = {
  disabled?: boolean;
};

const StyledDeleteIcon = styled(DeleteIcon)<StyledDeleteIconProps>(
  ({ theme, disabled }) => {
    if (!disabled) {
      return {
        color: theme.palette.danger,
      };
    }
  },
);

export default StyledDeleteIcon;
