import React, { useContext } from 'react';
import FieldContext from '../../../FieldContext';
import FieldNameWrapper from '../../utils/FieldName/FieldNameWrapper';
import StyledFieldName from '../../utils/FieldName/StyledFieldName';
import FieldInfo from '../../utils/FieldName/FieldInfo';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import IconButtonWithMarginCompensation from '../IconButtonWithMarginCompensation';
import { Edit as EditIcon } from '@mui/icons-material';
import Space from '@/components/Space';
import HtmlRenderer from '../../utils/HtmlRenderer';
import AddButton from './AddButton';
import useCurrentFieldValue from '../../utils/useCurrentFieldValue';

type ViewTextProps = {
  onEdit: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const ViewText = ({ onEdit }: ViewTextProps) => {
  const field = useContext(FieldContext);
  const text = useCurrentFieldValue<string>('');

  return (
    <section>
      <FieldNameWrapper>
        <StyledFieldName>{field.name}</StyledFieldName>
        {field.info && <FieldInfo />}
        {text && (
          <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
            <IconButtonWithMarginCompensation onClick={onEdit}>
              <EditIcon />
            </IconButtonWithMarginCompensation>
          </RestrictedToPortfolioMode>
        )}
      </FieldNameWrapper>
      {text && <Space height={-16} />}
      {text ? (
        <HtmlRenderer source={text.toString()} />
      ) : (
        <AddButton onClick={onEdit} />
      )}
    </section>
  );
};

export default ViewText;
