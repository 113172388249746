import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import styled from '@emotion/styled';

const StyledIndicator = styled('li')(
  ({ theme }) => ({
    marginRight: 4,
    marginBottom: 4,
    width: 28,
    height: 28,
    backgroundColor: theme.palette.action.selected,
    borderRadius: '50%',
    boxSizing: 'border-box',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    ':last-child': {
      marginRight: 0,
    },
  }),
  addHighContrastStyles(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
  })),
);

export default StyledIndicator;
