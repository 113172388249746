import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const EnsureProperModeSwitchAlignment = styled('div')({
  height: 48,
  [mediaQuery(700)]: {
    width: '100%',
  },
});

export default EnsureProperModeSwitchAlignment;
