import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import RowDeletionProvider from './RowDeletionProvider';
import RowHoverStateProvider from './RowHoverStateProvider';
import ChallengeBoard from '../../ChallengeBoard';
import CollapseButton from './CollapseButton';
import DeleteRow from './DeleteRow';
import useRowMoveHandlers from './useRowMoveHandlers';
import useCollapsedState from './useCollapsedState';
import RowHeader from './RowHeader';
import device from 'current-device';
import EditableRowName from './EditableRowName';
import RowMoveButtons from './RowMoveButtons';
import MobileRowActions from './MobileRowActions';
import RowContext from './RowContext';
import { Row as RowType } from '@/lib/types';

type RowProps = {
  row: RowType;
  previousRow: RowType | null;
  nextRow: RowType | null;
};

const Row = ({ row, previousRow, nextRow }: RowProps) => {
  const [moveUp, moveDown] = useRowMoveHandlers(row, previousRow, nextRow);

  const [collapsed, toggleCollapsed] = useCollapsedState(row.id);
  const [nameEditing, setNameEditing] = useState(false);

  const useMobileVariant = useMediaQuery('(max-width: 560px)');

  return (
    <RowContext.Provider value={row}>
      <RowDeletionProvider
        rowId={row.id}
        onDeletionStart={() => collapsed && toggleCollapsed()}
      >
        <RowHoverStateProvider>
          <RowHeader
            collapsed={collapsed}
            hasDarkBackground={nameEditing}
            onClick={
              !device.desktop() && !nameEditing ? toggleCollapsed : undefined
            }
          >
            <EditableRowName
              row={row}
              editing={nameEditing}
              setEditing={setNameEditing}
            />
            {!nameEditing && !useMobileVariant && (
              <>
                <DeleteRow row={row} />
                <CollapseButton
                  collapsed={collapsed}
                  onClick={toggleCollapsed}
                />
                <RowMoveButtons
                  onMoveUp={moveUp}
                  onMoveDown={moveDown}
                  canMoveUp={!!previousRow}
                  canMoveDown={!!nextRow}
                />
              </>
            )}
            {!nameEditing && useMobileVariant && (
              <MobileRowActions
                row={row}
                setRowNameEditing={setNameEditing}
                rowCollapsed={collapsed}
                toggleRowCollapsed={toggleCollapsed}
              />
            )}
          </RowHeader>
          <ChallengeBoard collapsed={collapsed} />
        </RowHoverStateProvider>
      </RowDeletionProvider>
    </RowContext.Provider>
  );
};

export default Row;
