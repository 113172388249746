import useSortedVisibleFields from './useSortedVisibleFields';
import FieldContext from './FieldContext';
import FieldWrapper from './FieldWrapper';
import IndividualFieldRenderer from './IndividualFieldRenderer';
import { AdditionalFieldConfig, Field } from '@/lib/types';

type FieldRendererProps = {
  fields: Field[];
  noCoachFeedback?: boolean;
};

const FieldRenderer = ({ fields, noCoachFeedback }: FieldRendererProps) => {
  const sortedVisibleFields = useSortedVisibleFields(fields);

  return sortedVisibleFields.map(field => (
    <FieldContext.Provider
      value={{
        ...field,
        additionalConfig: JSON.parse(
          field.additionalConfig as string,
        ) as AdditionalFieldConfig,
      }}
      key={field.id}
    >
      <FieldWrapper noCoachFeedback={noCoachFeedback ?? false}>
        <IndividualFieldRenderer />
      </FieldWrapper>
    </FieldContext.Provider>
  ));
};

export default FieldRenderer;
