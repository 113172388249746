import styled from '@emotion/styled';
import { TextField, TextFieldProps } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';

export type ResponsiveTextFieldProps = Omit<TextFieldProps, 'error'> & {
  error: boolean;
};

const ResponsiveTextField = styled(TextField)<ResponsiveTextFieldProps>(
  ({ error }) => ({
    marginBottom: error ? -22 : undefined,
    [mediaQuery(420)]: {
      width: '100%',
    },
  }),
);

export default ResponsiveTextField;
