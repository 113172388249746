import { useState, useContext } from 'react';
import VisiblePortfolioTemplatesGroupContext from '../VisiblePortfolioTemplatesGroupContext';
import PortfolioItemWithGroupsPrompt from './PortfolioItemWithGroupsPrompt';
import Dialog from '@/components/Dialog';
import Group from '@/lib/constants/Group.enum';
import Space from '@/components/Space';
import AddItemButton from './AddItemButton';
import { FormattedMessage } from 'react-intl';
import UserRole from '@/lib/constants/UserRole.enum';
import useApi from '@/lib/api/useApi';
import { EditorTopicWithoutRelations, MinimumRole } from '@/lib/types';
import EditorPortfolioTemplatesContext from '../EditorPortfolioTemplatesContext';
import PortfolioItemWithGroupsPromptVariant from './PortfolioItemWithGroupsPrompt/PortfolioItemWithGroupsPromptVariant.enum';

type AddTopicProps = {
  highestTopicPosition: number;
};

const getSelectedMinimumRoleFromVisibleGroup = (
  visibleGroup: Group,
): MinimumRole => {
  switch (visibleGroup) {
    case Group.EDITORS:
    case Group.DRAFTS: {
      return UserRole.EDITOR;
    }
    case Group.COACHES: {
      return UserRole.COACH;
    }
    default: {
      return UserRole.STUDENT;
    }
  }
};

const AddTopic = ({ highestTopicPosition }: AddTopicProps) => {
  const api = useApi();

  const [, setPortfolioTemplates] = useContext(EditorPortfolioTemplatesContext);
  const visibleGroup = useContext(VisiblePortfolioTemplatesGroupContext);

  const [addPromptOpen, setAddPromptOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveNewTopic = async (
    name: string,
    minimumRole: MinimumRole,
    groups: number[],
  ): Promise<void> => {
    setAddPromptOpen(false);
    setSaving(true);

    const topicData = {
      name,
      position: Math.ceil(highestTopicPosition) + 1,
      minimumRole,
      groups,
    };

    const topic = await api.post<EditorTopicWithoutRelations>(
      'editor/portfolio-templates/topics',
      topicData,
    );

    setPortfolioTemplates(portfolioTemplates => {
      portfolioTemplates.topics[topic.id] = {
        ...topic,
        groups: groups.map(id => ({ id })),
        subtopics: [],
      };
    });

    setSaving(false);
  };

  return (
    <>
      <Space height={36} />
      <AddItemButton
        variant="contained"
        onClick={() => setAddPromptOpen(true)}
        disabled={saving}
        disableElevation
      >
        <FormattedMessage id="portfolio_template_editor.add_topic.button" />
      </AddItemButton>
      <PortfolioItemWithGroupsPrompt
        variant={PortfolioItemWithGroupsPromptVariant.TOPIC}
        label={
          <FormattedMessage id="portfolio_template_editor.add_topic.input_label" />
        }
        open={addPromptOpen}
        selectedMinimumRole={getSelectedMinimumRoleFromVisibleGroup(
          visibleGroup,
        )}
        onOk={saveNewTopic}
        onCancel={() => setAddPromptOpen(false)}
        createsItem
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio_template_editor.add_topic.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio_template_editor.add_topic.text" />
          </Dialog.ContentText>
        </Dialog.Content>
      </PortfolioItemWithGroupsPrompt>
    </>
  );
};

export default AddTopic;
