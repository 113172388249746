import styled from '@emotion/styled';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import PerspectivesIcon from '@/components/PerspectivesIcon';
import { FormattedMessage } from 'react-intl';
import React from 'react';

type ManagePerspectivesButtonProps = Omit<
  LoadingButtonProps,
  'startIcon' | 'loadingPosition' | 'loading' | 'children'
> & {
  loading: boolean;
  children?: React.ReactNode;
};

const StyledButton = styled(LoadingButton)({
  marginBottom: 8,
  height: 32,
});

const ManagePerspectivesButton = ({
  loading,
  children,
  ...props
}: ManagePerspectivesButtonProps) => (
  <StyledButton
    startIcon={<PerspectivesIcon size={18} />}
    loadingPosition="start"
    loading={loading}
    {...props}
  >
    {children ?? (
      <FormattedMessage id="perspectives.attachment.manage_attachment_button" />
    )}
  </StyledButton>
);

export default ManagePerspectivesButton;
