import ChallengeSearchQueryContext from '@/components/ChallengeSearchQueryContext';
import SelectedUserContext from '@/lib/contexts/SelectedUserContext';
import { User } from '@/lib/types';
import React from 'react';

type GlobalStateProviderProps = {
  challengeSearchQuery: string;
  selectedUser: User | null;
  children: React.ReactNode;
};

const GlobalStateProvider = ({
  challengeSearchQuery,
  selectedUser,
  children,
}: GlobalStateProviderProps) => (
  <ChallengeSearchQueryContext.Provider value={challengeSearchQuery}>
    <SelectedUserContext.Provider value={selectedUser}>
      {children}
    </SelectedUserContext.Provider>
  </ChallengeSearchQueryContext.Provider>
);

export default GlobalStateProvider;
