import { useState, useContext } from 'react';
import { IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import StyledLevel from '@/components/RubricsPortfolioComponents/StyledLevel';
import LevelTitle from '@/components/RubricsPortfolioComponents/StyledLevel/LevelTitle';
import LevelDescription from '@/components/RubricsPortfolioComponents/StyledLevel/LevelDescription';
import LevelEditor from './LevelEditor';
import DeleteLevel from './DeleteLevel';
import useMakeDraggable from '../useMakeDraggable';
import useApi from '@/lib/api/useApi';
import { EditorLevel as EditorLevelType } from '@/lib/types';
import EditorPortfolioTemplatesContext from '../../EditorPortfolioTemplatesContext';
import PortfolioDragType from '../PortfolioDragType.enum';

type EditorLevelProps = {
  level: EditorLevelType;
  previousLevel: EditorLevelType | null;
  nextLevel: EditorLevelType | null;
  highestPosition: number;
};

const EditorLevel = ({
  level,
  previousLevel,
  nextLevel,
  highestPosition,
}: EditorLevelProps) => {
  const api = useApi();

  const [, setPortfolioTemplates] = useContext(EditorPortfolioTemplatesContext);

  const [isDragging, dragRef] = useMakeDraggable(
    PortfolioDragType.LEVEL,
    level,
    previousLevel,
    nextLevel,
    highestPosition,
    (levelId: number, position: number): void => {
      setPortfolioTemplates(portfolioTemplates => {
        portfolioTemplates.levels[levelId].position = position;
      });

      api.put(`editor/portfolio-templates/levels/${levelId}/position`, {
        position,
      });
    },
  );
  const [levelEditorOpen, setLevelEditorOpen] = useState(false);

  if (levelEditorOpen) {
    return (
      <LevelEditor level={level} onClose={() => setLevelEditorOpen(false)} />
    );
  }

  return (
    <StyledLevel editor dragging={isDragging} ref={dragRef}>
      {level.title && <LevelTitle>{level.title}</LevelTitle>}
      <LevelDescription>{level.description}</LevelDescription>
      <div>
        <IconButton onClick={() => setLevelEditorOpen(true)}>
          <EditIcon />
        </IconButton>
        <DeleteLevel level={level} />
      </div>
    </StyledLevel>
  );
};

export default EditorLevel;
