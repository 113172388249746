import useFormatMessageWithChallengeNaming from '@/lib/utils/useFormatMessageWithChallengeNaming';
import { Helmet } from 'react-helmet';

type PublicPortfolioItemPageTitleProps = {
  translationId: string;
};

const PublicPortfolioItemPageTitle = ({
  translationId,
}: PublicPortfolioItemPageTitleProps) => {
  const formatMessageWithChallengeNaming = useFormatMessageWithChallengeNaming();

  return (
    <Helmet
      title={formatMessageWithChallengeNaming({
        id: translationId,
      })}
    />
  );
};

export default PublicPortfolioItemPageTitle;
