import { Moment } from 'moment';

const isLater = (dateOne: Moment, dateTwo: Moment): boolean => {
  const firstMoment = dateOne.clone().startOf('day');
  const secondMoment = dateTwo.clone().startOf('day');

  return firstMoment.isAfter(secondMoment);
};

export default isLater;
