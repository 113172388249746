import useHighContrastModeEnabled from '@/lib/utils/useHighContrastModeEnabled';
import { FormControlLabel, Switch } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const HighContrastSwitch = () => {
  const [
    highContrastModeEnabled,
    setHighContrastModeEnabled,
  ] = useHighContrastModeEnabled();

  return (
    <FormControlLabel
      control={
        <Switch
          checked={highContrastModeEnabled}
          onChange={(_, checked: boolean) =>
            setHighContrastModeEnabled(checked)
          }
        />
      }
      label={
        <FormattedMessage id="header.options_menu.display_settings.enable_high_contrast_mode" />
      }
    />
  );
};

export default HighContrastSwitch;
