import styled from '@emotion/styled';
import React from 'react';

type TopicContentProps = {
  collapsed: boolean;
  children: React.ReactNode;
};

const StyledContent = styled('section')({
  position: 'relative',
});

const TopicContent = ({ collapsed, children }: TopicContentProps) => {
  if (collapsed) {
    return null;
  }

  return <StyledContent>{children}</StyledContent>;
};

export default TopicContent;
