import { EditorField } from '@/lib/types';
import sortByPosition from '@/lib/utils/sorting/sortByPosition';
import getHighestPositionFromSortedItems from '@/pages/EditorPage/getHighestPositionFromSortedItems';

const getHighestFieldPosition = (fields: EditorField[]): number => {
  const sortedFields = fields.sort(sortByPosition);

  return getHighestPositionFromSortedItems(sortedFields);
};

export default getHighestFieldPosition;
