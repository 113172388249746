import styled from '@emotion/styled';
import { MenuItem, MenuItemProps } from '@mui/material';

export type ActionMenuButtonProps = MenuItemProps;

const ActionMenuButton = styled(MenuItem)<ActionMenuButtonProps>({
  paddingTop: 10,
  paddingBottom: 10,
});

export default ActionMenuButton;
