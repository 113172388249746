import styled from '@emotion/styled';

const TreeWrapper = styled('section')({
  padding: 24,
  display: 'flex',
  boxSizing: 'border-box',
  overflowX: 'auto',
});

export default TreeWrapper;
