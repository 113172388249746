import mediaQuery from '@/lib/styling/mediaQuery';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import React from 'react';

type ScreenWrapperProps = {
  children: React.ReactNode;
};

const StyledWrapper = styled(motion.main)(({ theme }) => ({
  paddingTop: 24,
  paddingBottom: 24,
  paddingLeft: 148,
  paddingRight: 148,
  height: 'calc(100vh - 80px)',
  boxSizing: 'border-box',
  transition: theme.transitions.create('all'),
  [mediaQuery(1024)]: {
    paddingLeft: 72,
    paddingRight: 72,
  },
  [mediaQuery(850)]: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

const ScreenWrapper = ({ children }: ScreenWrapperProps) => (
  <StyledWrapper
    initial={{
      scale: 0.99,
      opacity: 0,
    }}
    animate={{
      scale: 1,
      opacity: 1,
    }}
    transition={{
      duration: 0.4,
    }}
  >
    {children}
  </StyledWrapper>
);

export default ScreenWrapper;
