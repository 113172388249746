import styled from '@emotion/styled';
import InnerContentWrapper from '@/components/RubricsPortfolioComponents/LevelDialog/InnerContentWrapper';
import mediaQuery from '@/lib/styling/mediaQuery';
import { buildWidthString as buildLevelWidthString } from '@/components/RubricsPortfolioComponents/StyledLevel';

type CustomInnerContentWrapperProps = {
  visibleLevelIndex: number;
};

const buildNegativeMarginString = (
  visibleLevelIndex: number,
  levelsVisibleWithoutScrolling: number,
): string => {
  return `calc(-${visibleLevelIndex} * (${buildLevelWidthString(
    levelsVisibleWithoutScrolling,
  )} + 25px))`;
};

const CustomInnerContentWrapper = styled(InnerContentWrapper)<
  CustomInnerContentWrapperProps
>(
  ({ theme }) => ({
    [mediaQuery(900)]: {
      overflow: 'visible',
      transition: theme.transitions.create('all'),
    },
  }),
  ({ visibleLevelIndex }) => ({
    [mediaQuery(900)]: {
      marginLeft: buildNegativeMarginString(visibleLevelIndex, 3),
    },
    [mediaQuery(768)]: {
      marginLeft: buildNegativeMarginString(visibleLevelIndex, 2),
    },
    [mediaQuery(500)]: {
      marginLeft: buildNegativeMarginString(visibleLevelIndex, 1),
    },
  }),
);

export default CustomInnerContentWrapper;
