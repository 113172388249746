import App from '@/lib/App';
import { useLocation } from 'react-router-dom';
import modeUrls from '@/lib/mode/modeUrls';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';

const removeTrailingSlash = (url: string): string => {
  if (url === '/') {
    return url;
  }

  if (!url.endsWith('/')) {
    return url;
  }

  return url.slice(0, url.length - 1);
};

// TODO ideally this would just depend on react-router state
const useIsDisplayingNotFoundPage = (): boolean => {
  const authenticatedUser = useAuthenticatedUser();
  const selectedMode = App.navigation.useSelectedMode();

  const location = useLocation();
  const modePages = Object.values(modeUrls);
  const onModePage = (modePages as string[]).includes(
    removeTrailingSlash(location.pathname),
  );

  if (!onModePage) {
    return true;
  }

  // TODO onModePage check is redundant
  return authenticatedUser !== null && onModePage && !selectedMode;
};

export default useIsDisplayingNotFoundPage;
