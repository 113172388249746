import { useContext } from 'react';
import FieldContext from '@/pages/EditorPage/ChallengeModelEditor/ModelEditorForModelType/ModelPhaseEditor/ModelFieldEditor/FieldContext';
import PortfolioDragType from '../PortfolioDragType.enum';

// This scopes drag keys per field so editors can't drag parts between fields
// in the challenge/task model editor
const usePartDragKey = (): string => {
  const { field } = useContext(FieldContext);

  if (field === null) {
    return PortfolioDragType.PART;
  }

  return `${PortfolioDragType.PART}:${field.id}`;
};

export default usePartDragKey;
