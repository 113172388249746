import { useContext, useState } from 'react';
import StyledLevel from '@/components/RubricsPortfolioComponents/StyledLevel';
import TitleField from './TitleField';
import DescriptionField from './DescriptionField';
import LevelEditorActions from './LevelEditorActions';
import useApi from '@/lib/api/useApi';
import { EditorLevel, EditorLevelWithoutRelations } from '@/lib/types';
import EditorPortfolioTemplatesContext from '../../../EditorPortfolioTemplatesContext';
import useComplexSwrState from '@/lib/utils/useComplexSwrState';

type LevelEditorProps = {
  level: EditorLevel;
  onClose: () => void;
};

const LevelEditor = ({ level, onClose }: LevelEditorProps) => {
  const api = useApi();

  const [, setPortfolioTemplates] = useContext(EditorPortfolioTemplatesContext);

  const [title, setTitle, , reenableTitleSync] = useComplexSwrState(
    level.title ?? '',
  );
  const [description, setDescription, , reenableDescriptionSync] =
    useComplexSwrState(level.description);

  const [showEmptyError, setShowEmptyError] = useState(false);
  const [saving, setSaving] = useState(false);

  const hasChanged =
    title.trim() !== (level.title ?? '') ||
    description.trim() !== (level.description ?? '');

  const saveEdits = async (): Promise<void> => {
    if (description.trim().length === 0) {
      setShowEmptyError(true);
      return;
    }

    setShowEmptyError(false);

    if (!hasChanged) {
      onClose();

      return;
    }

    setSaving(true);

    const levelData = {
      title: title.trim(),
      description: description.trim(),
      position: level.position,
    };

    const updatedLevel = await api.put<EditorLevelWithoutRelations>(
      `editor/portfolio-templates/levels/${level.id}`,
      levelData,
    );

    setPortfolioTemplates(portfolioTemplates => {
      portfolioTemplates.levels[level.id] = {
        ...portfolioTemplates.levels[level.id],
        ...updatedLevel,
      };
    });

    setSaving(false);

    onClose();

    reenableTitleSync();
    reenableDescriptionSync();
  };

  const resetAndClose = () => {
    setTitle(level.title ?? '');
    setDescription(level.description);
    setShowEmptyError(false);

    onClose();

    reenableTitleSync();
    reenableDescriptionSync();
  };

  return (
    <StyledLevel>
      <TitleField value={title} onChange={e => setTitle(e.target.value)} />
      <DescriptionField
        value={description}
        onChange={e => setDescription(e.target.value)}
        showEmptyError={showEmptyError}
        autoFocus
      />
      <LevelEditorActions
        onClose={resetAndClose}
        onSave={saveEdits}
        disabled={saving}
      />
    </StyledLevel>
  );
};

export default LevelEditor;
