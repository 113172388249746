import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';

type UserNameProps = {
  name: string;
};

const StyledName = styled('h4')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
}));

const UserName = ({ name }: UserNameProps) => {
  const shouldOnlyShowFirstName = useMediaQuery('(max-width: 450px)');

  const firstName = name.split(' ')[0];

  return <StyledName>{shouldOnlyShowFirstName ? firstName : name}</StyledName>;
};

export default UserName;
