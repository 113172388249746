import React, { useRef } from 'react';
import useElementIsInViewport from '@/lib/utils/useElementIsInViewport';
import Space from '@/components/Space';
import GapminderPlaceholder from './GapminderPlaceholder';
import GapminderCard from './GapminderCard';
import GapminderControlsWrapper from './GapminderControlsWrapper';
import CloseButton from './CloseButton';
import YearSelect from './YearSelect';
import PlayButton from './PlayButton';
import WeekSlider from './WeekSlider';
import DateText from './DateText';
import { RubricsPortfolioSnapshot } from '@/lib/types';

type InnerGapminderProps = {
  years: number[];
  selectedYear: number;
  setSelectedYear: (year: number) => void;
  weeks: number[];
  selectedWeek: number;
  setSelectedWeek: (week: number) => void;
  selectedPortfolioSnapshot: RubricsPortfolioSnapshot | null;
  onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  loading: boolean;
};

const InnerGapminder = ({
  years,
  selectedYear,
  setSelectedYear,
  weeks,
  selectedWeek,
  setSelectedWeek,
  selectedPortfolioSnapshot,
  onClose,
  loading,
}: InnerGapminderProps) => {
  const nonFixedGapminderStartRef = useRef<HTMLDivElement>(null);
  const nonFixedGapminderInViewport = useElementIsInViewport(
    nonFixedGapminderStartRef,
  );
  const isGapminderFixed = !nonFixedGapminderInViewport;

  return (
    <>
      <Space height={24} />
      <div ref={nonFixedGapminderStartRef} />
      <GapminderPlaceholder visible={isGapminderFixed} />
      <GapminderCard fixed={isGapminderFixed}>
        <GapminderControlsWrapper>
          <CloseButton onClick={onClose} />
          <YearSelect
            years={years}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            disabled={loading}
          />
          <PlayButton
            weeks={weeks}
            selectedWeek={selectedWeek}
            setSelectedWeek={setSelectedWeek}
            disabled={loading}
          />
        </GapminderControlsWrapper>
        <WeekSlider
          selectedYear={selectedYear}
          weeks={weeks}
          selectedWeek={selectedWeek}
          setSelectedWeek={setSelectedWeek}
          disabled={loading}
        />
        <DateText
          selectedPortfolioSnapshot={selectedPortfolioSnapshot}
          loading={loading}
        />
      </GapminderCard>
    </>
  );
};

export default InnerGapminder;
