import styled from '@emotion/styled';
import firstChildSelector from '@/lib/styling/firstChildSelector';
import mediaQuery from '@/lib/styling/mediaQuery';

const StyledFieldWrapper = styled('div')({
  position: 'relative',
  marginBottom: 22,
  display: 'flex',
  ':last-child': {
    marginBottom: 0,
  },
  [`& > ${firstChildSelector}`]: {
    flex: 1,
  },
  '& .add-feedback': {
    position: 'absolute',
    marginTop: 0,
    opacity: 0,
  },
  ':hover .add-feedback, .add-feedback:focus': {
    position: 'relative',
    marginTop: 12,
    opacity: 1,
  },
  ':empty': {
    display: 'none',
  },
  [mediaQuery(640)]: {
    marginBottom: 34,
  },
});

export default StyledFieldWrapper;
