import styled from '@emotion/styled';
import { Paper, PaperProps } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';
import addHighContrastStyles from '@/lib/styling/addHighContrastStyles';
import isPropValid from '@emotion/is-prop-valid';

const SubtopicPartWrapper = styled(Paper, {
  shouldForwardProp: isPropValid,
})<PaperProps>(
  {
    padding: '12px 24px',
    borderRadius: 8,
    [mediaQuery(768)]: {
      padding: 0,
      background: 'none',
      borderRadius: 0,
      boxShadow: 'none',
      overflow: 'visible',
    },
  },
  addHighContrastStyles(({ theme }) => ({
    border: `2px solid ${theme.palette.divider}`,
    [mediaQuery(768)]: {
      border: 'none',
    },
  })),
);

export default SubtopicPartWrapper;
