import { useContext, useState } from 'react';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import PortfolioIsReadOnlyContext from '@/lib/contexts/PortfolioIsReadOnlyContext';
import StyledFocusIcon from './StyledFocusIcon';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import StyledFocusButton from './StyledFocusButton';
import useApi from '@/lib/api/useApi';

type FocusButtonProps = {
  partId: number;
  hasFocus: boolean;
  setHasFocus: (hasFocus: boolean) => void;
};

const FocusButton = ({ partId, hasFocus, setHasFocus }: FocusButtonProps) => {
  const api = useApi();

  const portfolioIsReadOnly = useContext(PortfolioIsReadOnlyContext);
  const portfolioMode = useContext(PortfolioModeContext);

  const [saving, setSaving] = useState(false);

  const toggleFocus = async (): Promise<void> => {
    if (portfolioIsReadOnly || portfolioMode !== PortfolioMode.STUDENT) {
      return;
    }

    const hadFocus = hasFocus;

    // TODO define type as state and use state update fn param? Safer!
    setHasFocus(!hadFocus);

    setSaving(true);

    await api.post(`portfolio/focus/${partId}`, {
      focused: !hadFocus,
    });

    setSaving(false);
  };

  return (
    <StyledFocusButton
      onClick={toggleFocus}
      faded={saving}
      disabled={
        portfolioIsReadOnly || portfolioMode === PortfolioMode.COACH || saving
      }
    >
      <StyledFocusIcon hasFocus={hasFocus} />
    </StyledFocusButton>
  );
};

export default FocusButton;
