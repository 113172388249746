import { useContext } from 'react';
import PortfolioModeContext from '@/lib/contexts/PortfolioModeContext';
import StyledLevel from '@/components/RubricsPortfolioComponents/StyledLevel';
import LevelTitle from '@/components/RubricsPortfolioComponents/StyledLevel/LevelTitle';
import LevelDescription from '@/components/RubricsPortfolioComponents/StyledLevel/LevelDescription';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import ProofWrapper from './ProofWrapper';
import LevelProof from './LevelProof';
import LevelProofReadOnly from './LevelProofReadOnly';
import PortfolioIsReadOnlyContext from '@/lib/contexts/PortfolioIsReadOnlyContext';
import { Level as LevelType } from '@/lib/types';

type LevelProps = {
  level: LevelType;
};

const Level = ({ level }: LevelProps) => {
  const portfolioIsReadOnly = useContext(PortfolioIsReadOnlyContext);
  const portfolioMode = useContext(PortfolioModeContext);

  return (
    <StyledLevel>
      {level.title && <LevelTitle>{level.title}</LevelTitle>}
      <LevelDescription>{level.description}</LevelDescription>
      <ProofWrapper>
        {portfolioIsReadOnly || portfolioMode === PortfolioMode.COACH ? (
          <LevelProofReadOnly levelId={level.id} />
        ) : (
          <LevelProof levelId={level.id} />
        )}
      </ProofWrapper>
    </StyledLevel>
  );
};

export default Level;
