import { useContext } from 'react';
import App from '@/lib/App';
import SelectedPlatformContext from '@/lib/contexts/SelectedPlatformContext';
import PlatformType from '@/lib/constants/PlatformType.enum';
import getOtherPlatformType from './getOtherPlatformType';
import modesWithPlatformDifferences from '@/lib/utils/modesWithPlatformDifferences';
import ShowPerspectiveCategorizationViewContext from '@/lib/contexts/ShowPerspectiveCategorizationViewContext';
import PlatformSwitchButton from './PlatformSwitchButton';

const PlatformSwitch = () => {
  const [selectedPlatform, setSelectedPlatform] = useContext(
    SelectedPlatformContext,
  );

  const [showPerspectiveCategorizationView] = useContext(
    ShowPerspectiveCategorizationViewContext,
  );

  const validPlatformType = Object.values(PlatformType);
  const selectedMode = App.navigation.useSelectedMode();

  if (
    selectedPlatform === null ||
    selectedMode === null ||
    !validPlatformType.includes(selectedPlatform) ||
    !modesWithPlatformDifferences.includes(selectedMode) ||
    showPerspectiveCategorizationView
  ) {
    return null;
  }

  const otherPlatformType = getOtherPlatformType(selectedPlatform);

  return (
    <PlatformSwitchButton
      targetPlatformType={otherPlatformType}
      onClick={() => setSelectedPlatform(otherPlatformType)}
    />
  );
};

export default PlatformSwitch;
