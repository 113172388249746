import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

const UserSearchWrapper = styled('form')(({ theme }) => ({
  marginLeft: 'auto',
  width: 432,
  transition: theme.transitions.create('all'),
  [mediaQuery(1200)]: {
    width: 228,
  },
  [mediaQuery(768)]: {
    marginTop: 12,
    width: '100%',
  },
}));

export default UserSearchWrapper;
