import { useState } from 'react';
import StyledLogEntry from './StyledLogEntry';
import HtmlRenderer from '../../utils/HtmlRenderer';
import LogEntryHeader from './LogEntryHeader';
import LogEntryUser from './LogEntryUser';
import LogEntryActionsWrapper from './LogEntryActionsWrapper';
import EditButton from './EditButton';
import DeleteLogEntry from './DeleteLogEntry';
import LogEntryDivider from './LogEntryDivider';
import LogEntryEditor from './LogEntryEditor';
import { LogEntry as LogEntryType } from '@/lib/types';

type LogEntryProps = {
  logEntry: LogEntryType;
  isLast: boolean;
};

const LogEntry = ({ logEntry, isLast }: LogEntryProps) => {
  const [editing, setEditing] = useState(false);

  return (
    <StyledLogEntry className="log-entry">
      <LogEntryHeader>
        <LogEntryUser logEntry={logEntry} />
        {!editing && (
          <LogEntryActionsWrapper>
            <EditButton onClick={() => setEditing(true)} />
            <DeleteLogEntry logEntry={logEntry} />
          </LogEntryActionsWrapper>
        )}
      </LogEntryHeader>
      {editing ? (
        <LogEntryEditor logEntry={logEntry} onClose={() => setEditing(false)} />
      ) : (
        <HtmlRenderer source={logEntry.content} darkText />
      )}
      {!isLast && <LogEntryDivider />}
    </StyledLogEntry>
  );
};

export default LogEntry;
