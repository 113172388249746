import FieldType from '@/lib/constants/challengeModel/FieldType.enum';
import SelectEditor from './TypeSpecificEditors/SelectEditor';
import LogEntryNameEditor from './TypeSpecificEditors/LogEntryNameEditor';
import LinkButtonEditor from './TypeSpecificEditors/LinkButtonEditor';
import RubricsEditor from './TypeSpecificEditors/RubricsEditor';
import ColorsEditor from './TypeSpecificEditors/ColorsEditor';
import React from 'react';

const typeSpecificEditors = ({
  [FieldType.SELECT]: SelectEditor,
  [FieldType.LOGS]: LogEntryNameEditor,
  [FieldType.LINK_BUTTON]: LinkButtonEditor,
  [FieldType.RUBRICS]: RubricsEditor,
  [FieldType.MANUAL_COLOR_PICKER]: ColorsEditor,
} as unknown) as Record<FieldType, React.ElementType | null>;

const getTypeSpecificEditor = (type: FieldType): React.ReactNode => {
  const Editor = typeSpecificEditors[type];

  if (!Editor) {
    return null;
  }

  return <Editor />;
};

export default getTypeSpecificEditor;
