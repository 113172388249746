import useApi from '@/lib/api/useApi';
import { useContext } from 'react';
import ChallengePortfolioContext from '../../../../ChallengePortfolioContext';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import KanbanPhase from '@/lib/constants/KanbanPhase.enum';
import { ChallengeWithoutRelations } from '@/lib/types';

const useCreateChallenge = (): ((
  name: string,
  phase: KanbanPhase,
  rowId: number,
) => Promise<void>) => {
  const api = useApi();

  const [, setChallengePortfolio] = useContext(ChallengePortfolioContext);
  const authenticatedUser = useAuthenticatedUser()!;

  const createChallenge = async (
    name: string,
    phase: KanbanPhase,
    rowId: number,
  ): Promise<void> => {
    const challenge = await api.post<ChallengeWithoutRelations>(
      `challenge-portfolio/challenges?rowId=${rowId}`,
      {
        name,
        phase,
      },
    );

    setChallengePortfolio(challengePortfolio => {
      challengePortfolio.challenges[challenge.id] = {
        ...challenge,
        users: [authenticatedUser],
        invitees: [],
        tasks: [],
        _count: {
          coachComments: 0,
        },
      };

      challengePortfolio.rows[rowId].challenges.push(challenge.id);

      return challengePortfolio;
    });
  };

  return createChallenge;
};

export default useCreateChallenge;
