import styled from '@emotion/styled';

const IndicatorWrapper = styled('ul')({
  margin: 0,
  marginBottom: 4,
  padding: 0,
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
});

export default IndicatorWrapper;
