import useSWR, { SWRConfiguration } from 'swr';
import useApi from './useApi';

// TODO: swr implementation should be done in full, ie proper error handling,
// mutate handling, response type schemes etc
const useSwr = <T>(
  key: string | null,
  options?: SWRConfiguration,
): [true, null] | [false, T] => {
  const api = useApi();
  const { isLoading, data, error } = useSWR<T, unknown>(key, api.call, options);

  if (isLoading || typeof data === 'undefined' || !!error) {
    return [true, null];
  }

  return [false, data];
};

export default useSwr;
