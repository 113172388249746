import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

const ClearButton = styled(IconButton)({
  marginTop: 24,
  marginBottom: 'auto',
  marginLeft: 6,
});

export default ClearButton;
