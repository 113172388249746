import { AuthenticatedUser, User } from '@/lib/types';
import sortByName from '@/lib/utils/sorting/sortByName';

const getSortedVisibleUsers = (
  users: Record<number, User>,
  selectedGroupId: number | null,
  authenticatedUser: AuthenticatedUser,
): User[] => {
  const visibleUsers = Object.values(users)
    .filter(({ email }) => email !== authenticatedUser.email)
    .sort(sortByName);

  if (selectedGroupId === null) {
    return visibleUsers;
  }

  return visibleUsers.filter(user => user.groups.includes(selectedGroupId));
};

export default getSortedVisibleUsers;
