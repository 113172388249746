import styled from '@emotion/styled';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import { HTMLDivProps } from '@/lib/types';

const StyledWrapper = styled('div')({
  marginTop: -12,
  marginBottom: -12,
  marginLeft: 'auto',
  display: 'flex',
});

const LogEntryActionsWrapper = (props: HTMLDivProps) => (
  <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
    <StyledWrapper {...props} />
  </RestrictedToPortfolioMode>
);

export default LogEntryActionsWrapper;
