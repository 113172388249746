import React, { useState, useContext } from 'react';
import RestrictedToPortfolioMode from '@/components/RestrictedToPortfolioMode';
import PortfolioMode from '@/lib/constants/PortfolioMode.enum';
import RowDeletionContext from '../RowDeletionProvider/RowDeletionContext';
import DeleteRowButton from './DeleteRowButton';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from '@/components/FormattedMessageWithChallengeNaming';
import { Row } from '@/lib/types';

type DeleteRowProps = {
  row: Row;
  ButtonComponent?: React.ElementType;
};

const DeleteRow = ({
  row,
  ButtonComponent: ButtonComponentOrUndefined,
}: DeleteRowProps) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const { deleteRow, startRowDeletion } = useContext(RowDeletionContext);

  const rowHasNoChallenges = row.challenges.length === 0;

  const triggerRowDeletion = (): void => {
    setConfirmationDialogOpen(false);

    if (rowHasNoChallenges) {
      deleteRow();
      return;
    }

    startRowDeletion();
  };

  const ButtonComponent = ButtonComponentOrUndefined ?? DeleteRowButton;

  return (
    <RestrictedToPortfolioMode mode={PortfolioMode.STUDENT}>
      <ButtonComponent onClick={() => setConfirmationDialogOpen(true)} />
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.row.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessageWithChallengeNaming
              id={
                rowHasNoChallenges
                  ? 'portfolio.row.delete.text.empty_row'
                  : 'portfolio.row.delete.text'
              }
            />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={triggerRowDeletion} />
        </Dialog.Actions>
      </Dialog>
    </RestrictedToPortfolioMode>
  );
};

export default DeleteRow;
