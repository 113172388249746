import Prompt, { PromptProps } from '@/components/Prompt';
import Dialog from '@/components/Dialog';
import { FormattedMessage } from 'react-intl';
import { MinimumRole } from '@/lib/types';

type PartPromptWithoutGroupsProps = Omit<
  PromptProps,
  'emptyError' | 'persistInput' | 'children'
> & {
  selectedMinimumRole: MinimumRole;
  selectedGroups: number[];
};

const PartPromptWithoutGroups = ({
  selectedMinimumRole,
  selectedGroups,
  ...props
}: PartPromptWithoutGroupsProps) => (
  <Prompt emptyError="prompt.errors.name_empty" persistInput {...props}>
    <Dialog.Title>
      <FormattedMessage id="portfolio_template_editor.part.edit.title" />
    </Dialog.Title>
    <Dialog.Content>
      <Dialog.ContentText>
        <FormattedMessage id="portfolio_template_editor.part.edit.text_no_groups" />
      </Dialog.ContentText>
    </Dialog.Content>
    <Prompt.TextField
      label={
        <FormattedMessage id="portfolio_template_editor.part.edit.input_label" />
      }
    />
  </Prompt>
);

export default PartPromptWithoutGroups;
