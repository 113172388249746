import styled from '@emotion/styled';
import { ArrowDropDown as DropdownArrowIcon } from '@mui/icons-material';
import isPropValid from '@emotion/is-prop-valid';

type GroupSelectorButtonDropdownArrowProps = {
  rotated: boolean;
};

const GroupSelectorButtonDropdownArrow = styled(DropdownArrowIcon, {
  shouldForwardProp: isPropValid,
})<GroupSelectorButtonDropdownArrowProps>(({ theme, rotated }) => ({
  marginLeft: 'auto',
  minWidth: 24,
  minHeight: 24,
  color: theme.palette.action.active,
  transition: theme.transitions.create('all'),
  transform: rotated ? 'rotate(180deg)' : 'none',
}));

export default GroupSelectorButtonDropdownArrow;
