import { PerspectiveSummary, TaskSummary } from '@/lib/types';
import { useMemo } from 'react';

const getTaskAttachedPerspectives = (
  task: TaskSummary,
): PerspectiveSummary[] => {
  const allAttachedPerspectives = new Map<number, PerspectiveSummary>();

  for (const perspective of task.directlyAttachedPerspectives) {
    allAttachedPerspectives.set(perspective.id, perspective);
  }

  for (const perspective of task.indirectlyAttachedPerspectives) {
    allAttachedPerspectives.set(perspective.id, perspective);
  }

  return Array.from(allAttachedPerspectives.values());
};

const useTaskAttachedPerspectives = (
  task: TaskSummary,
): PerspectiveSummary[] => {
  const attachedPerspectives = useMemo(
    () => getTaskAttachedPerspectives(task),
    [task],
  );

  return attachedPerspectives;
};

export default useTaskAttachedPerspectives;
