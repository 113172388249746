import styled from '@emotion/styled';
import { Dialog, DialogProps } from '@mui/material';
import mediaQuery from '@/lib/styling/mediaQuery';
import stripProps from '@/lib/utils/stripProps';

type DialogWithDangerousHandlingProps = DialogProps & {
  dangerous?: boolean;
};

const DialogWithDangerousHandling = styled(
  stripProps(Dialog, 'dangerous') as typeof Dialog,
)<DialogWithDangerousHandlingProps>(
  {
    '.MuiDialog-container': {
      [mediaQuery(640)]: {
        alignItems: 'flex-end !important',
      },
    },
  },
  ({ theme, dangerous }) => {
    if (theme.palette.isHighContrast || !dangerous) {
      return null;
    }

    return {
      '.MuiPaper-root': {
        borderLeft: `4px solid transparent`,
        borderLeftColor: theme.palette.error.main,
        [mediaQuery(640)]: {
          borderLeft: 'none',
        },
      },
    };
  },
);

export default DialogWithDangerousHandling;
