import useColorState from '../../useColorState';
import StyledForm from './StyledForm';
import ColorPreview from '../../ColorPreview';
import ColorTextField from '../../ColorTextField';
import { IconButton } from '@mui/material';
import { Close as CancelIcon, Check as CheckIcon } from '@mui/icons-material';
import React from 'react';

type ColorFormProps = {
  color: string;
  onCancel: () => void;
  onSave: (color: string) => void;
};

const ColorForm = ({
  color: originalColor,
  onCancel,
  onSave,
}: ColorFormProps) => {
  const [color, setColor] = useColorState(originalColor);
  const fullHexColor = `#${color}`;

  const handleCancel = (): void => {
    setColor(originalColor);

    onCancel();
  };

  const handleSave = (e: React.FormEvent): void => {
    e.preventDefault();

    if (color.length < 6) {
      return;
    }

    onSave(fullHexColor);
  };

  return (
    <StyledForm onSubmit={handleSave}>
      <ColorPreview color={fullHexColor} />
      <ColorTextField
        value={color}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setColor(e.target.value)
        }
      />
      <IconButton onClick={handleCancel}>
        <CancelIcon />
      </IconButton>
      <IconButton disabled={color.length < 6} onClick={handleSave}>
        <CheckIcon />
      </IconButton>
    </StyledForm>
  );
};

export default ColorForm;
