import { useContext } from 'react';
import CollapsedRowsContext from '../../CollapsedRowsContext';

const useCollapsedState = (rowId: number): [boolean, () => void] => {
  const { isRowCollapsed, toggleRowCollapsed } = useContext(
    CollapsedRowsContext,
  );

  const collapsed = isRowCollapsed(rowId);

  const toggleCollapsed = (): void => {
    toggleRowCollapsed(rowId);
  };

  return [collapsed, toggleCollapsed];
};

export default useCollapsedState;
