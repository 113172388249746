import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';
import { alpha } from '@mui/material';
import { common } from '@mui/material/colors';
import addBorderInHighContrastMode from '@/lib/styling/addBorderInHighContrastMode';

type UserToolbarWrapperProps = {
  fixed: boolean;
};

const UserToolbarWrapper = styled('header')<UserToolbarWrapperProps>(
  ({ theme }) => ({
    marginBottom: 18,
    paddingTop: 6,
    paddingBottom: 6,
    width: '100%',
    backgroundColor: theme.palette.background.shaded,
    borderRadius: 4,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    transition: theme.transitions.create(['margin', 'color']),
    [mediaQuery(850)]: {
      display: 'none',
    },
  }),
  ({ theme, fixed }) => {
    if (fixed) {
      return {
        position: 'fixed',
        top: 0,
        left: 148,
        marginTop: 24,
        width: 'calc(100% - 296px)',
        backgroundColor: alpha(theme.palette.background.paper, 0.8),
        backdropFilter: 'blur(30px) saturate(180%)',
        boxShadow: `0 6px 54px ${alpha(common.black, 0.34)}`,
        zIndex: 1,
        [mediaQuery(1024)]: {
          left: 72,
          width: 'calc(100% - 144px)',
        },
        [mediaQuery(850)]: {
          left: 24,
          width: 'calc(100% - 48px)',
        },
        [mediaQuery(480)]: {
          marginTop: 0,
          left: 0,
          width: '100%',
          borderRadius: 0,
        },
      };
    }
  },
  addBorderInHighContrastMode,
);

export default UserToolbarWrapper;
