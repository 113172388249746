import { useState, useEffect, useContext } from 'react';
import useInterval from 'use-interval';
import DeleteButton from './DeleteButton';
import CancelDeletionButtonWrapper from './CancelDeletionButtonWrapper';
import DeletionProgressIndicator from './DeletionProgressIndicator';
import CancelDeletionButton from './CancelDeletionButton';
import useApi from '@/lib/api/useApi';
import FieldRendererContext from '@/components/ChallengePortfolio/NormalView/PortfolioItemFields/FieldRendererContext';
import { LogEntry } from '@/lib/types';

type DeleteLogEntryProps = {
  logEntry: LogEntry;
};

const DeleteLogEntry = ({ logEntry }: DeleteLogEntryProps) => {
  const api = useApi();

  const { setPortfolioItem, portfolioItemApiEndpoint } =
    useContext(FieldRendererContext);

  const [deleting, setDeleting] = useState(false);
  const [deletionProgress, setDeletionProgress] = useState(0);
  const [persisting, setPersisting] = useState(false);

  useInterval(() => {
    if (deleting && deletionProgress < 110) {
      setDeletionProgress(deletionProgress + 10);
    }
  }, 500);

  useEffect(() => {
    if (!deleting) {
      setDeletionProgress(0);
    }
  }, [deleting]);

  const deleteLogEntry = async (): Promise<void> => {
    setPersisting(true);
    setDeleting(false);

    await api.delete(`${portfolioItemApiEndpoint}/log-entries/${logEntry.id}`);

    setPortfolioItem(portfolioItem => {
      delete portfolioItem.logEntries[logEntry.id];
    });

    setPersisting(false);
  };

  if (!persisting && deleting && deletionProgress === 110) {
    deleteLogEntry();
  }

  if (!deleting) {
    return (
      <DeleteButton onClick={() => setDeleting(true)} disabled={persisting} />
    );
  }

  return (
    <CancelDeletionButtonWrapper>
      <DeletionProgressIndicator deletionProgress={deletionProgress} />
      <CancelDeletionButton onClick={() => setDeleting(false)} />
    </CancelDeletionButtonWrapper>
  );
};

export default DeleteLogEntry;
