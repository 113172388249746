import Dialog from '@/components/Dialog';
import { FormControl } from '@mui/material';
import SectionTitle from './SectionTitle';
import RowAttachmentTypeOptions from './RowAttachmentTypeOptions';
import styled from '@emotion/styled';
import React from 'react';

type ChallengeRowAttachmentProps = {
  children: React.ReactNode;
};

const StyledDialogContent = styled(Dialog.Content)({
  overflowY: 'visible',
});

// TODO just use fullWidth?
const StyledFormControl = styled(FormControl)({
  width: '100%',
});

const ChallengeRowAttachment = ({ children }: ChallengeRowAttachmentProps) => (
  <StyledDialogContent>
    <StyledFormControl>{children}</StyledFormControl>
  </StyledDialogContent>
);

ChallengeRowAttachment.Title = SectionTitle;
ChallengeRowAttachment.TypeOptions = RowAttachmentTypeOptions;

export default ChallengeRowAttachment;
