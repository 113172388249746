import React, { useState } from 'react';
import Dialog from '@/components/Dialog';
import StepsWrapper from './StepsWrapper';
import SupportTypeSelector from './SupportTypeSelector';
import SupportContentRenderer from './SupportContentRenderer';
import SupportDialogActions from './SupportDialogActions';
import BackButton from './BackButton';
import DoneButton from './DoneButton';
import SupportType from './SupportType.enum';

type SupportDialogProps = {
  open: boolean;
  onClose: () => void;
};

// TODO don't use inline styles
const dontShowScrollbarWhenAnimatingToNextStepStyles = {
  overflowX: 'hidden',
} satisfies React.CSSProperties;

const SupportDialog = ({ open, onClose }: SupportDialogProps) => {
  const [hasMovedToSecondStep, setHasMovedToSecondStep] = useState(false);
  const [selectedSupportType, setSelectedSupportType] =
    useState<SupportType | null>(null);
  const [customDialogActions, setCustomDialogActions] =
    useState<React.ReactNode | null>(null);

  const goBack = () => {
    setHasMovedToSecondStep(false);
    setSelectedSupportType(null);
    setCustomDialogActions(null);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onEnterClose={onClose}
      disableEnterClose={selectedSupportType === SupportType.FEEDBACK}
      PaperProps={{
        style: dontShowScrollbarWhenAnimatingToNextStepStyles,
      }}
    >
      <StepsWrapper hasMovedToSecondStep={hasMovedToSecondStep}>
        <SupportTypeSelector
          onSelectSupportType={(supportType: SupportType): void => {
            setSelectedSupportType(supportType);
            setHasMovedToSecondStep(true);
          }}
        />
        <SupportContentRenderer
          selectedSupportType={selectedSupportType}
          setDialogActions={setCustomDialogActions}
          onGoBack={goBack}
          onDialogClose={onClose}
        />
      </StepsWrapper>
      <SupportDialogActions>
        {hasMovedToSecondStep && <BackButton onClick={goBack} />}
        {customDialogActions ?? <DoneButton onClick={onClose} />}
      </SupportDialogActions>
    </Dialog>
  );
};

export default SupportDialog;
