import { ObjectWithPosition } from "@/lib/types";

const getHighestPositionFromSortedItems = (
  sortedItems: ObjectWithPosition[],
): number => {
  if (sortedItems.length === 0) {
    return 0;
  }

  const lastItem = sortedItems[sortedItems.length - 1];

  return lastItem.position;
};

export default getHighestPositionFromSortedItems;
