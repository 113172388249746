import { useState } from 'react';
import { DatePickerToolbar, StaticDatePicker } from '@mui/x-date-pickers';
import DateDisplay from '../DateDisplay';
import Dialog from '@/components/Dialog';
import Space from '@/components/Space';
import { FormattedMessage } from 'react-intl';
import DatePickerDialog from './DatePickerDialog';
import { Moment } from 'moment';
import { DatePickerProps } from '..';

const DummyComponent = () => null;

type MobileDatePickerProps = DatePickerProps & {
  onClear: () => void;
};

const MobileDatePicker = ({
  value,
  onChange,
  onSave,
  onClear,
  shouldDisableDate,
  clearable,
  disabled,
}: MobileDatePickerProps) => {
  const [open, setOpen] = useState(false);
  const isEmpty = !value.isValid();

  const handleClear = (): void => {
    onClear();
    setOpen(false);
  };

  const handleSave = (): void => {
    onSave(value);
    setOpen(false);
  };

  return (
    <>
      <DateDisplay
        date={value}
        onOpen={() => setOpen(true)}
        disabled={disabled}
      />
      <DatePickerDialog open={open} onClose={() => setOpen(false)}>
        <StaticDatePicker<Moment>
          value={value}
          onChange={(value: Moment | null): void => {
            if (value !== null) {
              onChange(value);
            }
          }}
          shouldDisableDate={shouldDisableDate}
          slots={{
            // Override built-in action bar; we want to use our own
            actionBar: DummyComponent,
            toolbar: isEmpty ? DummyComponent : DatePickerToolbar,
          }}
          disabled={disabled}
        />
        <Dialog.Actions>
          {clearable && !isEmpty && (
            <>
              <Dialog.Actions.Button onClick={handleClear} disabled={disabled}>
                <FormattedMessage id="dialogs.actions.clear" />
              </Dialog.Actions.Button>
              <Space width="auto" />
            </>
          )}
          <Dialog.Actions.SaveButton onClick={handleSave} disabled={disabled} />
        </Dialog.Actions>
      </DatePickerDialog>
    </>
  );
};

export default MobileDatePicker;
