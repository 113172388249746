import React, { createContext } from 'react';
import { ReactContextWithAutomatedProvider } from '../types';
import useComplexState from '../utils/useComplexState';

// TODO whole focus fighting stuff should be reworked
type DialogManagerState = {
  isAnyDialogOpen: boolean;
  markDialogAsOpen: (dialogKey: string) => void;
  markDialogAsClosed: (dialogKey: string) => void;
};

const DialogManagerContext = createContext<DialogManagerState>({
  isAnyDialogOpen: false,
  markDialogAsOpen: () => {},
  markDialogAsClosed: () => {},
}) as ReactContextWithAutomatedProvider<DialogManagerState>;

type AutomatedDialogManagerContextProviderProps = {
  children: React.ReactNode;
};

const AutomatedDialogManagerContextProvider = ({
  children,
}: AutomatedDialogManagerContextProviderProps) => {
  const [openDialogs, setOpenDialogs] = useComplexState<Record<string, true>>(
    {},
  );
  const isAnyDialogOpen = Object.keys(openDialogs).length > 0;

  const markDialogAsOpen = (dialogKey: string): void => {
    setOpenDialogs(openDialogs => {
      openDialogs[dialogKey] = true;
    });
  };

  const markDialogAsClosed = (dialogKey: string): void => {
    setOpenDialogs(openDialogs => {
      delete openDialogs[dialogKey];
    });
  };

  return (
    <DialogManagerContext.Provider
      value={{
        isAnyDialogOpen,
        markDialogAsOpen,
        markDialogAsClosed,
      }}
    >
      {children}
    </DialogManagerContext.Provider>
  );
};

DialogManagerContext.AutomatedProvider = AutomatedDialogManagerContextProvider;

export default DialogManagerContext;
