import styled from '@emotion/styled';

const UserEmail = styled('p')(({ theme }) => ({
  margin: 0,
  color: theme.palette.text.secondary,
  fontSize: 14,
  fontFamily: 'CircularStd',
}));

export default UserEmail;
