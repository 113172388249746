import { Group } from '@/lib/types';
import sortByName from '@/lib/utils/sorting/sortByName';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';

const useSelectableGroups = (groups: Record<number, Group>): Group[] => {
  const authenticatedUser = useAuthenticatedUser()!;

  return authenticatedUser.managedGroups.map(id => groups[id]).sort(sortByName);
};

export default useSelectableGroups;
