import styled from '@emotion/styled';

type HiddenIndicatorsCountProps = {
  count: number;
};

const StyledCount = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const HiddenIndicatorsCount = ({ count }: HiddenIndicatorsCountProps) => (
  <StyledCount>+{count}</StyledCount>
);

export default HiddenIndicatorsCount;
