import styled from '@emotion/styled';
import UserAvatar from '@/components/UserAvatar';
import mediaQuery from '@/lib/styling/mediaQuery';

const StyledUserAvatar = styled(UserAvatar)({
  [mediaQuery(700)]: {
    marginRight: 0,
    marginBottom: 12,
  },
});

export default StyledUserAvatar;
