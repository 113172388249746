import HeaderActionIconButton from '../HeaderActionIconButton';
import UserAvatar from '@/components/UserAvatar';
import useAuthenticatedUser from '@/lib/auth/useAuthenticatedUser';
import { IconButtonProps } from '@mui/material';

const UserAvatarMenuButton = (props: IconButtonProps) => {
  const authenticatedUser = useAuthenticatedUser()!;

  return (
    <HeaderActionIconButton {...props}>
      <UserAvatar user={authenticatedUser} size={32} marginRight={0} />
    </HeaderActionIconButton>
  );
};

export default UserAvatarMenuButton;
